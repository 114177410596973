import React, { memo, useState, useEffect } from 'react';
import ResultsFooter from '../../UserDashboard/Partials/ResultsFooter';
import ResultsHeader from '../../UserDashboard/Partials/ResultsHeader';
import { Option } from '../../../models/common';
import { useDispatch, useSelector } from 'react-redux';
import { archiveUser, getAllUsers } from '../../../redux/actions';
import { AppState } from '../../../redux/reducers';
import * as S from './Styles/UsersPanel.style';

const sortByOptions: any[] = [
  { value: { sortByTarget: 'createdAt', sortByDirection: 'ASC' }, label: 'Joined at' },
  { value: { sortByTarget: 'name', sortByDirection: 'ASC' }, label: 'Name' },
  { value: { sortByTarget: 'surname', sortByDirection: 'ASC' }, label: 'Surname' },
  { value: { sortByTarget: 'email', sortByDirection: 'ASC' }, label: 'Email' },
];

const UsersPanel = () => {
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = useState({
    value: { sortByTarget: 'deadline', sortByDirection: 'ASC' },
    label: 'Joined at',
  });
  const [itemsPerPage, setItemsPerPage] = useState({ value: '30', label: '30' });
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');
  const [refresh, setRefresh] = useState(true);
  const { count, rows } = useSelector((state: AppState) => state.admin.users);
  const doSorting = (option: Option) => {
    setRefresh(true);
    setSortBy(option);
  };

  const handleSelectItemsPerPage = (option: Option) => {
    setRefresh(true);
    setItemsPerPage(option);
  };

  useEffect(() => {
    if (refresh) {
      dispatch(
        getAllUsers({
          query,
          sortBy: sortBy.value.sortByTarget,
          limit: +itemsPerPage.value,
          offset: page,
        })
      );
      setRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, itemsPerPage, page, query, sortBy, refresh]);

  return (
    <S.Wrapper>
      <S.Head>Users Panel</S.Head>
      <S.SubHeader>Found {count} users.</S.SubHeader>

      <ResultsHeader
        {...{ page, setPage, sortBy, query, setQuery, pagesNo: 1, setRefresh }}
        setSortBy={doSorting}
        sortByOptions={sortByOptions}
      />
      <S.Table>
        <thead>
          <tr>
            <S.TableHeadCell>ID</S.TableHeadCell>
            <S.TableHeadCell>Name</S.TableHeadCell>
            <S.TableHeadCell>Surname</S.TableHeadCell>
            <S.TableHeadCell>Nick</S.TableHeadCell>
            <S.TableHeadCell>Actions</S.TableHeadCell>
          </tr>
        </thead>
        {rows.map(({ id, name, surname, nickname, isArchived = false }: any, index) => (
          <S.TableRow key={id} count={index} isArchived={isArchived}>
            <S.TableCell>{id}</S.TableCell>
            <S.TableCell>{name}</S.TableCell>
            <S.TableCell>{surname}</S.TableCell>
            <S.TableCell>{nickname}</S.TableCell>
            <S.TableCell>
              <S.PlainButton
                onClick={() => dispatch(archiveUser({ userId: id, isSetArchived: !isArchived }))}
              >
                {isArchived ? <S.ResurrectIcon /> : <S.RemoveIcon />}
              </S.PlainButton>
            </S.TableCell>
          </S.TableRow>
        ))}
      </S.Table>
      <ResultsFooter
        {...{ itemsPerPage, handleSelectItemsPerPage, page, setPage, pagesNo: 1, setRefresh }}
      />
    </S.Wrapper>
  );
};

export default memo(UsersPanel);
