import React, { memo } from 'react';
import Selector, {
  SELECTOR_TYPE,
  SELECTOR_BORDER_RADIUS_OPTIONS,
} from '../../../CreateProduct/Inputs/Selector';
import { Option } from '../../../../models/common';
import * as S from '../FiltersPanel.style';

interface SelectProps {
  handleOptionSelectLocation: (e: Option) => void;
  handleOptionSelectDistance: (e: Option) => void;
  selectedLocation: any;
  selectedDistance: any;
  optionsLocation: Option[];
  optionsDistance: Option[];
  textAlign?: string;
}

const LocationInput = ({
  handleOptionSelectLocation,
  handleOptionSelectDistance,
  selectedLocation,
  selectedDistance,
  optionsLocation,
  optionsDistance,
}: SelectProps) => (
  <S.LocationInputWrapper>
    <Selector
      options={optionsLocation}
      selected={selectedLocation}
      handleOptionSelect={handleOptionSelectLocation}
      type={SELECTOR_TYPE.WIDE}
      // selectorAlignment={SELECTOR_BORDER_RADIUS_OPTIONS.LEFT}
      selectorAlignment={SELECTOR_BORDER_RADIUS_OPTIONS.CENTER}
    />
    {/* <Selector
        options={optionsDistance}
        selected={selectedDistance}
        handleOptionSelect={handleOptionSelectDistance}
        type={SELECTOR_TYPE.NARROW}
      /> */}
  </S.LocationInputWrapper>
);

export default memo(LocationInput);
