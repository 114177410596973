import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { HELP_CENTER } from './Legal/Legal';
import * as S from '../UserDashboard/Menu/Menu.styles';

interface HelpCenterMenuProps {
  selected: HELP_CENTER;
  setSelected: (value: HELP_CENTER) => void;
}

const HelpCenterMenu = ({ selected, setSelected }: HelpCenterMenuProps) => (
  <S.Wrapper isHelpCenter={true}>
    <S.MenuContainer>
      <S.Section>
        <S.SectionHeader>Legal</S.SectionHeader>
        <S.MenuItem
          isActive={selected === HELP_CENTER.MARKETPLACE_AGREEMENTS}
          onClick={() => setSelected(HELP_CENTER.MARKETPLACE_AGREEMENTS)}
        >
          <Link to="/help-center/legal/agreements">Marketplace agreements</Link>
        </S.MenuItem>
        <S.MenuItem
          isActive={selected === HELP_CENTER.PRIVACY}
          onClick={() => setSelected(HELP_CENTER.PRIVACY)}
        >
          <Link to="/help-center/legal/privacy">Privacy</Link>
        </S.MenuItem>
        <S.MenuItem
          isActive={selected === HELP_CENTER.TERMS_OF_SERVICE}
          onClick={() => setSelected(HELP_CENTER.TERMS_OF_SERVICE)}
        >
          <Link to="/help-center/legal/tos">Terms of service</Link>
        </S.MenuItem>
      </S.Section>
    </S.MenuContainer>
  </S.Wrapper>
);

export default memo(HelpCenterMenu);
