import React, { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SideBanner from './SideBanner/SideBanner';
import { getUserProfile } from '../../redux/actions';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Market from './Market/Market';
import Reviews from './Reviews/Reviews';
import Workshop from './Workshop/Workshop';
import PhotosPage from './Photos/PhotosPage';
import { USER_PROFILE_SECTION } from '../../models/user';
import About from './About/About';
import { useUserProfile } from '../../redux/reducers/userProfiles';
import SendEmailModal from '../Modals/SendEmailModal/SendEmailModal';
import ModalWrapper from '../Modals/ModalWrapper';
import SuccessfulEmailSent from '../Modals/SuccessfulEmailSent/SuccessfulEmailSent';
import * as S from './ProfilePage.style';
import { MODAL_NAME } from '../../redux/reducers/utils/models';

interface ProfilePageProps {
  selectedSection: USER_PROFILE_SECTION;
}

const ProfilePage = ({ selectedSection }: ProfilePageProps) => {
  const dispatch = useDispatch();
   const navigate = useNavigate();
  const params: any = useParams();
  const { userId } = params;
  const { name, nickname } = useUserProfile(userId);
  const isMarketSelected = selectedSection === USER_PROFILE_SECTION.MARKET;
  const isWorkshopSelected = selectedSection === USER_PROFILE_SECTION.WORKSHOP;
  const isPhotosSelected = selectedSection === USER_PROFILE_SECTION.PHOTOS;
  const isAboutSelected = selectedSection === USER_PROFILE_SECTION.ABOUT;
  const isReviewsSelected = selectedSection === USER_PROFILE_SECTION.REVIEWS;

  useEffect(() => {
    dispatch(
      getUserProfile({
        flag: selectedSection,
        id: userId,
      })
    );
  }, [dispatch, selectedSection, userId]);

  const goBack = () => navigate(-1)

  const visibleName = nickname ? nickname : name;

  return (
    <S.Wrapper>
      <SuccessfulEmailSent />
      <ModalWrapper title={MODAL_NAME.SEND_EMAIL} child={<SendEmailModal />} />
      <S.FlexWrapper>
        <S.Splint>
          <S.LeftSideWrapper>
            <S.Back onClick={goBack}>
              <S.ArrowLeft />
              <S.BackText>Back to print request</S.BackText>
            </S.Back>
            <SideBanner />
          </S.LeftSideWrapper>
        </S.Splint>
        <S.BodyWrapper>
          <S.TopWrapper>
            <S.Title>
              <S.BoldTitle>User</S.BoldTitle>
              <S.TitleText>profile –</S.TitleText>
            </S.Title>
            <S.WelcomeText>
              {visibleName && (
                <>
                  Hi, I’m <S.Name>{visibleName}</S.Name>
                </>
              )}
            </S.WelcomeText>
          </S.TopWrapper>
          <S.MenuWrapper isAboutSelected={isAboutSelected}>
            <S.MenuItem isActive={isAboutSelected}>
              <Link to={`/profile/${userId}/about`}>About</Link>
            </S.MenuItem>
            <S.MenuItem isActive={isPhotosSelected}>
              <Link to={`/profile/${userId}/photos`}>Galleries</Link>
            </S.MenuItem>
            <S.MenuItem isActive={isWorkshopSelected}>
              <Link to={`/profile/${userId}/workshop`}>Workshop</Link>
            </S.MenuItem>
            <S.MenuItem isActive={isMarketSelected}>
              <Link to={`/profile/${userId}/market`}>Market</Link>
            </S.MenuItem>
            <S.MenuItem isActive={isReviewsSelected}>
              <Link to={`/profile/${userId}/reviews`}>Reviews</Link>
            </S.MenuItem>
          </S.MenuWrapper>
          <S.SectionWrapper>
            {isAboutSelected && <About />}
            {isMarketSelected && <Market />}
            {isWorkshopSelected && <Workshop />}
            {isPhotosSelected && <PhotosPage />}
            {isReviewsSelected && <Reviews />}
          </S.SectionWrapper>
        </S.BodyWrapper>
      </S.FlexWrapper>
    </S.Wrapper>
  );
};

export default memo(ProfilePage);
