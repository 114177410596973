export const GET_SIMPLE_SEARCH_ITEMS = 'GET_SIMPLE_SEARCH_ITEMS';
export const GET_SIMPLE_SEARCH_ITEMS_SUCCESS = 'GET_SIMPLE_SEARCH_ITEMS_SUCCESS';
export const GET_SIMPLE_SEARCH_ITEMS_FAILURE = 'GET_SIMPLE_SEARCH_ITEMS_FAILURE';

export const GET_ADVANCED_SEARCH_DESIGN_REQUESTS = 'GET_ADVANCED_SEARCH_DESIGN_REQUESTS';
export const GET_ADVANCED_SEARCH_DESIGN_REQUESTS_SUCCESS =
  'GET_ADVANCED_SEARCH_DESIGN_REQUESTS_SUCCESS';
export const GET_ADVANCED_SEARCH_DESIGN_REQUESTS_FAILURE =
  'GET_ADVANCED_SEARCH_DESIGN_REQUESTS_FAILURE';

export const GET_ADVANCED_SEARCH_PRINT_REQUESTS = 'GET_ADVANCED_SEARCH_PRINT_REQUESTS';
export const GET_ADVANCED_SEARCH_PRINT_REQUESTS_SUCCESS =
  'GET_ADVANCED_SEARCH_PRINT_REQUESTS_SUCCESS';
export const GET_ADVANCED_SEARCH_PRINT_REQUESTS_FAILURE =
  'GET_ADVANCED_SEARCH_PRINT_REQUESTS_FAILURE';

export const GET_ADVANCED_SEARCH_DESIGNS_FOR_SELL = 'GET_ADVANCED_SEARCH_DESIGNS_FOR_SELL';
export const GET_ADVANCED_SEARCH_DESIGNS_FOR_SELL_SUCCESS =
  'GET_ADVANCED_SEARCH_DESIGNS_FOR_SELL_SUCCESS';
export const GET_ADVANCED_SEARCH_DESIGNS_FOR_SELL_FAILURE =
  'GET_ADVANCED_SEARCH_DESIGNS_FOR_SELL_FAILURE';

export const GET_ADVANCED_SEARCH_OBJECTS_FOR_SELL = 'GET_ADVANCED_SEARCH_OBJECTS_FOR_SELL';
export const GET_ADVANCED_SEARCH_OBJECTS_FOR_SELL_SUCCESS =
  'GET_ADVANCED_SEARCH_OBJECTS_FOR_SELL_SUCCESS';
export const GET_ADVANCED_SEARCH_OBJECTS_FOR_SELL_FAILURE =
  'GET_ADVANCED_SEARCH_OBJECTS_FOR_SELL_FAILURE';
