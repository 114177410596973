import { PRODUCT_TYPE } from './productType';

export enum NOTIFICATION_TYPES {
  ALERT = 'ALERT',
  WARNING = 'Information',
  SUCCESS = 'SUCCESS',
}

export enum NOTIFICATION_POINTER {
  PR = 'PR',
  OFS = 'OFS',
  DFS = 'DFS',
  DR = 'DR',
  PR_OFFER = 'PR_OFFER',
  DR_OFFER = 'DR_OFFER',
  DFS_PURCHASE = 'DFS_PURCHASE',
  OFS_PURCHASE = 'OFS_PURCHASE',
  RATING = 'RATING',
  COMMENT = 'COMMENT',
  PR_PACKAGES = 'PR_PACKAGES',
  PURCHASE_PACKAGES = 'PR_PACKAGES',
}

// export interface Notification {
//   id: string;
//   reason: NOTIFICATION_TYPES;
//   createdAt: string;
//   isRead: boolean;
//   body: {
//     message: string;
//   };
// }

export interface Notification {
  id: string;
  reason: NOTIFICATION_TYPES;
  itemPointer: NOTIFICATION_POINTER;
  createdAt: Date;
  isRead: boolean;
  link: string;
  printRequestId: string;
  objectForSellId: string;
  designForSellId: string;
  designRequestId: string;
  product: {
    productType: PRODUCT_TYPE;
    id: string;
    title: string;
    user: {
      id: string;
    };
    images: [
      {
        id: string;
        src: string;
      }
    ];
  };
  user?: {
    id: string;
    avatar: string;
    name: string;
    nickname?: string;
  };
  body: {
    message: string;
  };
}
