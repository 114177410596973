import React, { memo } from 'react';
import { ObjectForSell } from '../../../models/market';
import { useParams } from 'react-router';
import moment from 'moment';
import { SimplePrintRequest } from '../../../models/printRequest';
import { useUserProfile } from '../../../redux/reducers/userProfiles';
import { getSizeIcon, titleGenerator } from '../../../helpers/functions';
import darkLogo from '../../../images/logo_dark.png';
import * as S from './Market.styles';
import Emptymarket from '../EmptyPanels/EmptyMarket/Emptymarket';
import FormatPrice from '../../Common/FormatPrice/FormatPrice';

interface RenderItemProps {
  itemProps: SimplePrintRequest | ObjectForSell;
  sizeSymbol: string;
}

const renderItem = (
  {
    itemProps: { id, title, price, deadline, quantity, images, currency },
    sizeSymbol,
  }: RenderItemProps,
  itemType: string
) => {
  const now = deadline ? moment(deadline).startOf('day') : '';
  const deadlineDays = now ? now.diff(moment().startOf('day'), 'days') : 0;
  const isExpired = deadlineDays < 0;
  const dayDaysText = deadlineDays === 1 ? 'day' : 'days';
  const deadlineText = isExpired
    ? 'Expired'
    : ` ${deadlineDays && deadlineDays >= 1 ? `${deadlineDays} ${dayDaysText}` : 'today'}`;
  const { src } = images && images.length > 0 ? images[0] : { src: darkLogo };
  return (
    <S.Item key={id} to={`/${itemType}/${titleGenerator(title)}${id}`}>
      <S.ItemImage src={src} />
      <S.ItemDetails>
        <S.ItemTitleWrapper>
          <S.ItemTitle>{title}</S.ItemTitle>
          {getSizeIcon(sizeSymbol)}
        </S.ItemTitleWrapper>
        {currency && (
          <S.ItemPrice>
            <FormatPrice price={price} productCurrency={currency} />
          </S.ItemPrice>
        )}
        <S.ItemCopiesLeft>{quantity} copies left</S.ItemCopiesLeft>
        <S.ItemDaysLeft>{deadlineText}</S.ItemDaysLeft>
      </S.ItemDetails>
    </S.Item>
  );
};

const Market = () => {
  const params: any = useParams();
  const { userId } = params;
  const {
    market: { printRequests, printedObjects, objectDesigns, designsForSell },
  } = useUserProfile(userId);
  const isPrintRequestsEmpty = printRequests.length === 0;
  const isObjectsEmpty = printedObjects.length === 0;
  const isDesgins = objectDesigns.length === 0;
  const isDesignsFS = designsForSell.length === 0;
  return (
    <S.MarketWrapper>
      <S.MarketCategory>
        <S.CategoryTitleWrapper>
          <S.CategoryTitleBold>Print</S.CategoryTitleBold>
          <S.CategoryTitle>requests</S.CategoryTitle>
        </S.CategoryTitleWrapper>
        {isPrintRequestsEmpty ? (
          <Emptymarket />
        ) : (
          <S.SliderWrapper>
            <S.SliderRow>
              {printRequests.map((item: SimplePrintRequest) => {
                const {
                  size: { symbol },
                } = item;
                return renderItem({ itemProps: item, sizeSymbol: symbol }, 'print-request');
              })}
              <S.MockItem />
            </S.SliderRow>
          </S.SliderWrapper>
        )}
      </S.MarketCategory>
      <S.MarketCategory>
        <S.CategoryTitleWrapper>
          <S.CategoryTitleBold>Printed objects</S.CategoryTitleBold>
          <S.CategoryTitle>for sale</S.CategoryTitle>
        </S.CategoryTitleWrapper>
        {isObjectsEmpty ? (
          <Emptymarket />
        ) : (
          <S.SliderWrapper>
            <S.SliderRow>
              {printedObjects.map((item: SimplePrintRequest) => {
                const {
                  size: { symbol },
                } = item;
                return renderItem({ itemProps: item, sizeSymbol: symbol }, 'object-for-sell');
              })}
              <S.MockItem />
            </S.SliderRow>
          </S.SliderWrapper>
        )}
      </S.MarketCategory>
      <S.MarketCategory>
        <S.CategoryTitleWrapper>
          <S.CategoryTitleBold>Design</S.CategoryTitleBold>
          <S.CategoryTitle>requests</S.CategoryTitle>
        </S.CategoryTitleWrapper>
        {isDesgins ? (
          <Emptymarket />
        ) : (
          <S.SliderWrapper>
            <S.SliderRow>
              {objectDesigns.map((item: SimplePrintRequest) => {
                const {
                  size: { symbol },
                } = item;
                return renderItem({ itemProps: item, sizeSymbol: symbol }, 'design-request');
              })}
              <S.MockItem />
            </S.SliderRow>
          </S.SliderWrapper>
        )}
      </S.MarketCategory>
      <S.MarketCategory>
        <S.CategoryTitleWrapper>
          <S.CategoryTitleBold>Designs</S.CategoryTitleBold>
          <S.CategoryTitle>for sale</S.CategoryTitle>
        </S.CategoryTitleWrapper>
        {isDesignsFS ? (
          <Emptymarket />
        ) : (
          <S.SliderWrapper>
            <S.SliderRow>
              {designsForSell.map((item: SimplePrintRequest) => {
                const {
                  size: { symbol },
                } = item;
                return renderItem({ itemProps: item, sizeSymbol: symbol }, 'design-for-sell');
              })}
              <S.MockItem />
            </S.SliderRow>
          </S.SliderWrapper>
        )}
      </S.MarketCategory>
    </S.MarketWrapper>
  );
};

export default memo(Market);
