import React, { memo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { confirmAccountDelete } from '../../../api/common-api';

const AccountDeleteConfirmation = () => {
  const { code } = useParams() as { code: string };
  const navigate = useNavigate();

  useEffect(() => {
    confirmAccountDelete(code);
    navigate('/');
  });
  return <></>;
};

export default memo(AccountDeleteConfirmation);
