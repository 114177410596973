import React, { memo } from 'react';
import SliderSlice from '../../../Common/SliderSlice/SliderSlice';
import Slider from '../../../Common/Slider/Slider';
import { SLIDER_TYPE } from '../../constant';
import CircularIndeterminateLoader from '../../../Common/Loader/CircularIndeterminateLoader';
import { useSelector } from 'react-redux';
import { PRODUCT_TYPE } from '../../../../utils/productType';
import {
  isLoadingShortItems,
  shortObjectsForSell,
} from '../../../../redux/reducers/search/selectors';

const PrintedObjectsSlider = () => {
  const isLoading = useSelector(isLoadingShortItems);
  const rows = useSelector(shortObjectsForSell);
  return rows.length > 0 ? (
    <SliderSlice
      title="Printed"
      title2=" objects"
      title3="printed objects"
      link="/search/objects-for-sell/"
    >
      {isLoading ? (
        <CircularIndeterminateLoader />
      ) : (
        <Slider
          sliderType={SLIDER_TYPE.COMPACT}
          items={rows}
          productType={PRODUCT_TYPE.OBJECT_FOR_SELL}
        />
      )}
    </SliderSlice>
  ) : null;
};

export default memo(PrintedObjectsSlider);
