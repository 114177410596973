import styled from 'styled-components';
import { MdClose, MdPerson } from 'react-icons/md';
import themes from '../../Assets/colorThemes';

export const Wrapper = styled.div<{ maxWidth?: string; maxHeight?: string }>`
  background-color: ${themes.default.white};
  box-shadow: 0px 3px 6px ${themes.default.transparentGray29};
  border-radius: 8px;
  width: 100%;
  max-width: ${(props: any) => (props.maxWidth ? props.maxWidth : '560px')};
  max-height: ${(props: any) => (props.maxHeight ? props.maxHeight : '610px')};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ExternalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 66px;
  border-bottom: 1px solid ${themes.default.grayDb};
`;

export const Title = styled.div`
  margin: 24px 8px 0 18px;
  font-weight: 500;
  font-size: 25px;
  color: ${themes.default.gray4d};
`;

export const Close = styled(MdClose)`
  font-size: 28px;
  margin-top: 23px;
  margin-right: 12px;
  color: ${themes.default.gray4d};
  cursor: pointer;
  height: 28px;
  width: 28px;
`;

export const LetterCounter = styled.div`
  word-spacing: 0.15px;
  text-align: right;
  flex-grow: 1;
  color: ${themes.default.gray4d};
`;

export const BodyWrapper = styled.div`
  padding: 24px 16px 16px 16px;
  color: ${themes.default.gray4d};
  box-sizing: border-box;
  padding: 20px 20px 0;
  overflow: auto;
  flex-grow: 1;
`;

export const BottomWrapper = styled.div`
  border-top: 1px solid ${themes.default.grayDb};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  height: 89px;
  padding: 0px 18px;
  border-top: 1px solid ${themes.default.grayF4};
`;

export const BasicButton = styled.button`
  font-size: 14px;
  letter-spacing: 0.3px;
  font-weight: 600;
  border-radius: 3px;
  cursor: pointer;
  width: 200px;
  height: 48px;
`;

export const DeleteButton = styled(BasicButton)`
  background-color: rgb(219, 0, 0, 0.3);
  color: ${themes.default.red};
  border: 0;
  margin-right: auto;
  width: 100px;
`;

export const CloseButton = styled(BasicButton)`
  color: ${themes.default.gray4d};
  background-color: transparent;
  border: 0;
`;

export const NextButton = styled(BasicButton)`
  background-color: ${themes.default.blue};
  border-radius: 4px;
  border: none;
  color: ${themes.default.white};
  margin-left: 15px;
  :disabled {
    color: ${themes.default.grayA6};
    background-color: ${themes.default.grayFc};
    border: 1px solid ${themes.default.grayE3};
    box-shadow: none;
    cursor: auto;
  }
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${themes.default.grayE2};
  margin: 0 0 16px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 719px) {
    justify-content: space-evenly;
  }
`;

export const RightRow = styled(Row)`
  justify-content: space-between;
`;

export const ContactWrapper = styled.div`
  padding: 10px 14px;
  background-color: ${themes.default.grayF5};
  border-radius: 8px;
  max-width: 316px;
  width: 100%;
  box-sizing: border-box;
  height: 80px;
  margin: 0 4px;
  margin-bottom: 8px;
`;

export const OwnEmailWrapper = styled(ContactWrapper)``;

export const ContactLabel = styled.div`
  font-size: 11px;
  letter-spacing: 0.15px;
  color: ${themes.default.grayBlue};
  margin-bottom: 4px;
`;

export const ContactHeaderRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

export const ContactName = styled.div`
  text-align: left;
  letter-spacing: 1.4px;
  color: ${themes.default.grey33};
  font-weight: 600;
`;

export const ContactEmail = styled(ContactName)`
  font-size: 10px;
  margin-left: 38px;
`;

export const Person = styled(MdPerson)`
  width: 24px;
  height: 24px;
  background-color: ${themes.default.gray9a};
  color: ${themes.default.white};
  border-radius: 100%;
  padding: 2px;
  margin-right: 10px;
`;

export const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  margin-right: 10px;
`;

export const Label = styled.div`
  font-weight: 700;
  font-size: 15px;
  padding-bottom: 4px;
  color: ${themes.default.grey33};
`;

export const ErrorMessage = styled.div`
  height: 20px;
  width: 185px;
  color: ${themes.default.red};
  bottom: 20px;
`;
