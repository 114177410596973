import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { ADMIN_BOARD } from '../AdminBoard';
import * as S from '../../UserDashboard/Menu/Menu.styles';

interface MenuProps {
  selected: ADMIN_BOARD;
  setSelected: (selected: ADMIN_BOARD) => void;
}

const Menu = ({ selected, setSelected }: MenuProps) => (
  <S.Wrapper>
    <S.Header>Admin board</S.Header>
    <S.Breaker />
    <S.MenuContainer>
      <S.Section>
        <S.SectionHeader>Printing</S.SectionHeader>
        <S.MenuItem
          isActive={selected === ADMIN_BOARD.USERS}
          onClick={() => setSelected(ADMIN_BOARD.USERS)}
        >
          <Link to="/admin/users">Users</Link>
        </S.MenuItem>
        <S.MenuItem isActive={selected === ADMIN_BOARD.OTHER}>
          <Link to="/admin/other">Other</Link>
        </S.MenuItem>
      </S.Section>
      <S.MockSection />
    </S.MenuContainer>
  </S.Wrapper>
);

export default memo(Menu);
