import React, { memo } from 'react';
import { PRODUCT_STATUS } from '../../../../models/common';
import {
  offersAmountText,
  productDashboardDetailsPath,
  PRODUCT_TYPE,
} from '../../../../utils/productType';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLoggedInUser } from '../../../../redux/reducers/auth/selectors';
import { toggleModal } from '../../../../redux/reducers/utils/action';
import { MODAL_NAME } from '../../../../redux/reducers/utils/models';
import { getIsFinalized } from '../../../../utils/status';
import * as S from '../ActionButton.style';

interface IPRDetailsOwnerActionButton {
  id: string;
  productType: PRODUCT_TYPE;
  productStatus: PRODUCT_STATUS;
  offersAmmount: number;
}

const PRDetailsOwnerActionButton = ({
  id,
  productType,
  productStatus,
  offersAmmount,
}: IPRDetailsOwnerActionButton) => {
   const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: userId } = useSelector(useLoggedInUser);
  const hasOffers = offersAmmount ? offersAmmount > 0 : false;
  const isFinalized = getIsFinalized(productStatus);
  const typeText = productType === PRODUCT_TYPE.PRINT_REQUEST ? 'print' : 'design'

  const handleRedirection = () => {
    if (!userId) {
      dispatch(toggleModal(MODAL_NAME.LOGIN));
      return;
    }
    navigate(`${productDashboardDetailsPath[productType]}${id}`)
  }
    if (hasOffers && !isFinalized) {
      return (
        <S.Wrapper>
          <S.OffersAmount>
            {offersAmmount} {offersAmountText[productType]}
          </S.OffersAmount>
          <S.OfferPrintingButton
            disabled={isFinalized}
            onClick={handleRedirection}
          >
            Finalize your {typeText} request
          </S.OfferPrintingButton>
        </S.Wrapper>
      );
    }
    return (
      <S.PrintingOfferedWrapper>
        <S.CheckTag />
        <S.PrintingOfferedText>
          <S.Underline
            onClick={handleRedirection}
          >
            Show {typeText} details
          </S.Underline>
        </S.PrintingOfferedText>
      </S.PrintingOfferedWrapper>
    );
};

export default memo(PRDetailsOwnerActionButton);
