import React, { memo } from 'react';
import * as S from './DeliveryAddressSelector.style';

interface AddressInputProps {
  onChange?: any;
  label: string;
  value: string;
  isShort?: boolean;
  disabled?: boolean;
  isTall?: boolean;
}
const AddressInput = ({
  onChange,
  label,
  value,
  isShort = false,
  disabled = false,
  isTall = false,
}: AddressInputProps) => (
  <S.AddressInputWrapper isShort={isShort}>
    <S.AddressInputLabel>{label}</S.AddressInputLabel>
    <S.AddressInput
      disabled={disabled}
      value={value}
      required
      onChange={(e: any) => onChange(e.target.value)}
      isTall={isTall}
    />
  </S.AddressInputWrapper>
);

export default memo(AddressInput);
