import React, { memo, useState } from 'react';
import Content from '../Common/Content';
import TableOfContents from '../Common/TableOfContents';
import TosContent from "./tos2.json"
import EnTosContent from "./tos.en.json"
import NlTosContent from './tos.nl.json'
import * as S from './Tos.styles';

const Tos = () => {
  const ToCPl = TosContent.tos;
  const ToCEn = EnTosContent.tos
  const ToCNl = NlTosContent.tos
  const PL = ToCPl
  const EN = ToCEn
  const NL = ToCNl
  const [usedParsedData, setIsUsedParsedData] = useState(PL)
  return (
    <S.ExternalWrapper>
      <S.Wrapper>
        <S.HeaderContainer>
          <S.Header>Terms of Service</S.Header>
          {/* <button onClick={() => handleChangeContent()}>przycisk</button> */}
        </S.HeaderContainer>
        <S.LastUpdateContainer>
          <S.LastUpdate>
            Last update: <S.BoldedDate>1 Oct 2021</S.BoldedDate>
          </S.LastUpdate>
          <S.ButtonContainer>
          <S.StyledButton onClick={() => setIsUsedParsedData(PL)}>PL</S.StyledButton>
          <S.StyledButton onClick={() => setIsUsedParsedData(EN)}>EN</S.StyledButton>
          <S.StyledButton onClick={() => setIsUsedParsedData(NL)}>NL</S.StyledButton>
          </S.ButtonContainer>
        </S.LastUpdateContainer>
        <TableOfContents headers={usedParsedData} />
        <Content paragraphs={usedParsedData} />
      </S.Wrapper>
    </S.ExternalWrapper>
  );
};

export default memo(Tos);
