import React from 'react';
import { PRODUCT_TYPE } from '../utils/productType';
import * as S from './functions.styles';
import { ExchangeRate } from '../redux/reducers/utils/models';

export const titleGenerator = (rawName: string) => {
  const removedSpaces = rawName.replace(/[ ]+/g, '-');
  const encodedTitle = encodeURIComponent(removedSpaces);
  const trimmed = encodedTitle.length > 30 ? encodedTitle.slice(0, 30) : encodedTitle;
  return trimmed;
};

const getSizeColor = (size: string) => {
  switch (size) {
    case 'XS': {
      return '#158466';
    }
    case 'S': {
      return '#66CE6E';
    }
    case 'M': {
      return '#FFD501';
    }
    case 'L': {
      return '#FF8A01';
    }
    case 'XL': {
      return '#FF4B01';
    }
    default:
      return '#9a9a9a';
  }
};

export const getSizeIcon = (size: string, styles?: { width?: string; fontSize?: string }) => {
  const color = getSizeColor(size);
  return (
    <S.SizeWrapper width={styles && styles.width} color={color}>
      <S.Size size={styles && styles.fontSize} isBlack={size === 'M'}>
        {size}
      </S.Size>
    </S.SizeWrapper>
  );
};

export const generateLink = (itemType: PRODUCT_TYPE, title: string, id: string) => {
  switch (itemType) {
    case PRODUCT_TYPE.PRINT_REQUEST: {
      return `/print-request/${id}`;
      // return `/print-request/${titleGenerator(title)}${id}`;
    }
    case PRODUCT_TYPE.DESIGN_FOR_SELL: {
      return `/design-for-sell/${id}`;
      //return `/design-for-sell/${titleGenerator(title)}${id}`;
    }
    case PRODUCT_TYPE.DESIGN_REQUEST: {
      return `/design-request/${id}`;
      //return `/design-request/${titleGenerator(title)}${id}`;
    }
    case PRODUCT_TYPE.OBJECT_FOR_SELL: {
      return `/object-for-sell/${id}`;
      // return `/object-for-sell/${titleGenerator(title)}${id}`;
    }
  }
};

export enum ACCEPTED_CURRENCY {
  PLN = 'PLN',
  USD = 'USD',
  EUR = 'EUR',
}

export const CURRENCY_SYMBOL = {
  [ACCEPTED_CURRENCY.EUR]: '€',
  [ACCEPTED_CURRENCY.PLN]: 'PLN',
  [ACCEPTED_CURRENCY.USD]: '$',
};

interface FormatCurrency {
  currency: string;
  symbol: string;
}

export const formatPrice = (
  price: number,
  productCurrency: FormatCurrency,
  userCurrency: string,
  rates: ExchangeRate[]
) => {
  const { currency, symbol } = productCurrency;
  return `${symbol}${price}`;
  // const exchangeRate = rates.find(
  //   (rate: ExchangeRate) => rate.base === currency && rate.currency === userCurrency
  // );
  // if (!exchangeRate || !userCurrency) {
  //   if (symbol === '$' || symbol === '€') {
  //     return `${symbol}${price}`;
  //   }
  //   return `${price}${symbol}`;
  // }
  // const { rate } = exchangeRate;
  // if (rate === 0) {
  //   if (symbol === '$' || symbol === '€') {
  //     return `${symbol}${price}`;
  //   }
  //   return `${price}${symbol}`;
  // }
  // const processedSymbol = CURRENCY_SYMBOL[userCurrency as ACCEPTED_CURRENCY];
  // const processedPrice = Math.floor(price * rate);
  // if (userCurrency === 'USD' || userCurrency === 'EUR') {
  //   return `${processedSymbol}${processedPrice}`;
  // }
  // return `${processedPrice}${processedSymbol}`;
};

export const upperCaseFirstLetter = (text: string) => {
  if (text.length === 0) {
    return text;
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
};
