import React, { memo } from 'react';
import { UserAddress } from '../../../models/user';
import { useUserProfile } from '../../../redux/reducers/userProfiles';
import { useParams } from 'react-router';
import { useLoggedInUser } from '../../../redux/reducers/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleModal } from '../../../redux/reducers/utils/action';
import { MODAL_NAME } from '../../../redux/reducers/utils/models';
import * as S from './SideBanner.styles';

const SideBanner = () => {
  const params: any = useParams();
  const { userId } = params;
  const dispatch = useDispatch();
   const navigate = useNavigate();
  const { name, nickname, rating, addresses, avatar, ratingCount } = useUserProfile(userId);
  const { city, country } = addresses.find((address: UserAddress) => address.isDefault) || {};
  const { id: loggedInUserId } = useSelector(useLoggedInUser);
  const starToggler = (value: number) => {
    return rating < value ? <S.ShadowStart /> : <S.FullStart />;
  };
  const userName = nickname && nickname.length > 0 ? nickname : name;
  const handleSendQuestion = () => {
    if (loggedInUserId && loggedInUserId !== userId) {
      return dispatch(toggleModal(MODAL_NAME.SEND_EMAIL));
    }
    navigate('/settings/personal-information');
  };
  return (
    <S.Wrapper>
      {avatar ? <S.Avatar src={avatar} /> : <S.Person />}
      <S.NickName>{userName}</S.NickName>
      <S.Rating>
        <S.StarsWrapper>
          {starToggler(1)} {starToggler(2)} {starToggler(3)} {starToggler(4)} {starToggler(5)}
        </S.StarsWrapper>
        ({ratingCount})
      </S.Rating>
      {((city && city.length > 1) || (country && country.length > 1)) && (
        <S.Origin>
          {city}
          <b>{country && country.length > 1 && `, ${country}`}</b>
        </S.Origin>
      )}
      {/* {createdAt && createdAt.length > 1 && <S.Joined>{createdAt}</S.Joined>} */}
      <S.QuestionButton
        disabled={!loggedInUserId}
        isEditStyle={loggedInUserId === userId}
        onClick={() => handleSendQuestion()}
      >
        {loggedInUserId === userId ? 'Edit profile' : 'Send question'}
      </S.QuestionButton>
    </S.Wrapper>
  );
};

export default memo(SideBanner);
