import React, { memo, useState } from 'react';
import Content from '../Common/Content';
import TableOfContents from '../Common/TableOfContents';
import MAPlcontent from './marketplace-agreement.json'
import MAEncontent from './marketplace-agreement.en.json'
import MANlcontent from './marketplace-agreement.nl.json'
import * as S from './MarketplaceAgreements.styles';

const MarketplaceAgreements = () => {
  const MAPlContent = MAPlcontent.marketplace
  const MAEnContent = MAEncontent.marketplace
  const MANlContent = MANlcontent.marketplace
  const PL = MAPlContent;
  const EN = MAEnContent;
  const NL = MANlContent;
  const [usedParsedData, setIsUsedParsedData] = useState(PL);
  return (
    <S.ExternalWrapper>
      <S.Wrapper>
        <S.HeaderContainer>
          <S.Header>Marketplace agreements</S.Header>
          {/* <button onClick={() => handleChangeContent()}>przycisk</button> */}
        </S.HeaderContainer>
        <S.LastUpdateContainer>
          <S.LastUpdate>
            Last update: <S.BoldedDate>1 Oct 2021</S.BoldedDate>
          </S.LastUpdate>
          <S.ButtonContainer>
            <S.StyledButton onClick={() => setIsUsedParsedData(PL)}>PL</S.StyledButton>
            <S.StyledButton onClick={() => setIsUsedParsedData(EN)}>EN</S.StyledButton>
            <S.StyledButton onClick={() => setIsUsedParsedData(NL)}>NL</S.StyledButton>
          </S.ButtonContainer>
        </S.LastUpdateContainer>
        <TableOfContents headers={usedParsedData} />
        <Content paragraphs={usedParsedData} />
      </S.Wrapper>
    </S.ExternalWrapper>
  );
};

export default memo(MarketplaceAgreements);
