import React, { memo, useState } from 'react';
import PageWithMenuWrapper from '../../Common/PageWithMenuWrapper/PageWithMenuWrapper';
import HelpCenter from '../HelpCenter';
import HelpCenterMenu from '../HelpCenterMenu';
import MarketplaceAgreements from '../Marketplace Agreements/MarketplaceAgreements';
import Privacy from '../Privacy/Privacy';
import Tos from '../Tos/Tos';

export enum HELP_CENTER {
  MARKETPLACE_AGREEMENTS,
  PRIVACY,
  TERMS_OF_SERVICE,
  MAIN,
}

const Legal = ({ selectedSection }: { selectedSection: HELP_CENTER }) => {
  const [selected, setSelected] = useState(selectedSection);

  return (
    <div>
      <PageWithMenuWrapper
        isHelpCenter={true}
        menu={<HelpCenterMenu selected={selected} setSelected={setSelected} />}
        body={
          <div>
            {selectedSection === HELP_CENTER.MAIN && <HelpCenter />}
            {selectedSection === HELP_CENTER.MARKETPLACE_AGREEMENTS && <MarketplaceAgreements />}
            {selectedSection === HELP_CENTER.PRIVACY && <Privacy />}
            {selectedSection === HELP_CENTER.TERMS_OF_SERVICE && <Tos />}
          </div>
        }
      />
    </div>
  );
};

export default memo(Legal);
