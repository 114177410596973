import { MdKeyboardArrowLeft } from 'react-icons/md';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logoFull from '../../../images/logo_full.svg';
import themes from '../../Assets/colorThemes';

export const ExternalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  max-width: 448px;
  width: 100%;
  background-color: ${themes.default.white};
  box-shadow: 0px 3px 6px ${themes.default.transparentGray29};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 790px;
  height: 100%;
  position: relative;
`;

export const LogoWrapper = styled.div<{ isWide: boolean }>`
  width: 100%;
  height: auto;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;
  height: ${(props: any) => (props.isWide ? '186px' : '66px')};
  min-height: ${(props: any) => (props.isWide ? '186px' : '66px')};
  transition: 250ms;
`;

export const Logo = styled.img.attrs({ src: logoFull })`
  margin: 24px;
  height: 138px;
  width: 138px;
`;

export const BodyWrapper = styled.div`
  padding: 16px 0;
  box-sizing: border-box;
  border: 1px solid ${themes.default.grayEd};
  border-left: 0px;
  border-right: 0px;
  flex-grow: 1;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: ${themes.default.white};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${themes.default.grayBf};
  }
`;

export const Title = styled.h1`
  text-align: left;
  letter-spacing: 0px;
  color: ${themes.default.grey33};
  font-size: 24px;
  margin-bottom: 14px;
  font-weight: 500;
  box-sizing: order-box;
  padding: 0 32px;
`;

export const Text = styled.p`
  font-size: 16px;
  text-align: left;
  letter-spacing: 0px;
  color: ${themes.default.grey33};
  margin-bottom: 0;
  width: 100%;
  padding: 0 32px 28px;
  box-sizing: border-box;
`;

export const OptionsWrapper = styled.div<{ isPresent: boolean }>`
  border-top: 1px solid ${themes.default.grayEd};
  padding: 32px 32px 0;
  width: 100%;
  display: ${(props: any) => (props.isPresent ? 'flex' : 'none')};
  flex-direction: column;
  transition: 250ms;
  box-sizing: border-box;
`;

export const OptionsTitle = styled.h3`
  text-align: left;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0px;
  color: ${themes.default.grayD4};
  margin 0 0 20px;
  padding: 0;
`;

export const OptionWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  width: 100%;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export const OptionPropsWrapper = styled.div`
  margin-top: 3px;
`;

export const OptionTitle = styled.h4`
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.16px;
  color: ${themes.default.grey33};
  margin: 0 0 4px;
  padding: 0;
`;

export const OptionDescription = styled.p`
  text-align: left;
  font-size: 12px;
  letter-spacing: 0.16px;
  color: ${themes.default.grey33};
  margin: 0;
`;

export const ButtonsWrapper = styled.div<{ isWide: boolean }>`
  width: 100%;
  padding: 30px 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  height: ${(props: any) => (props.isWide ? '172px' : '124px')};
  transition: 250px;
`;

export const AgreeButton = styled.button<{ disabled?: boolean; isWide: boolean }>`
  background-color: ${themes.default.blue};
  color: ${themes.default.white};
  width: ${(props: any) => (props.isWide ? '100%' : '180px')};
  height: 48px;
  border-radius: 4px;
  letter-spacing: 0.14px;
  text-align: center;
  font-size: 14px;
  border: none;
  font-weight: 800;
  cursor: ${(props: any) => (props.disabled ? 'auto' : 'pointer')};
  margin-bottom: 16px;
  transition: 250ms;
`;

export const MoreButton = styled(AgreeButton)`
  color: ${themes.default.blue};
  background-color: rgb(0, 119, 223, 0.3);
  margin-bottom: 0;
  background-color: ${(props: any) => (props.isWide ? 'rgb(0, 119, 223, 0.3)' : 'transparent')};
  ${(props: any) => !props.isWide && 'margin-right: 12px;'}
`;

export const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${themes.default.blue};
`;

export const BackButton = styled.button`
  border: none;
  background-color: #f8f8f8;
  border-radius: 100%;
  width: 28px;
  height: 28px;
  opacity: ${({ disabled }) => (disabled ? 0 : 1)};
  transition: opacity 250ms;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 22px;
  left: 5%;
`;

export const ArrowLeft = styled(MdKeyboardArrowLeft)`
  width: 24px;
  height: 24px;
  color: #333333;
  margin: auto;
`;
