import styled from 'styled-components';
import PNG from '../../../images/partnersLogo/partnersLogoExporter';

export const Wrapper = styled.div`
  margin: 0 auto 58px auto;
  max-width: 1296px;
  padding: 0 16px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto 80px;
`;

export const Header = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 21px;
`;

export const LogosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const SimpleLogoContainer = styled.div`
  display: flex;
  /* max-width: 200px; */
  width: 33%;
  float: left;
`;

export const SpaghettiContainer = styled.div`
  display: flex;
  /* max-width: 300px; */
    width: 33%;
`;

export const TevoLogo = styled.img.attrs({ src: PNG.tevo, alt: 'Tevo logo' })`
  width: 182px;
  height: 97px;
`;

export const FillamentumLogo = styled.img.attrs({ src: PNG.filamentum, alt: 'Fillamentum logo' })`
  width: 191px;
  height: 67px;
`;

export const ThingiverseLogo = styled.img.attrs({ src: PNG.thingiverse, alt: 'Thingiverse logo' })`
  width: 226px;
  height: 41px;
  margin: 12px 0;
`;

export const PrintingComLogo = styled.img.attrs({
  src: PNG.printingCom,
  alt: '3D printing.com logo',
})`
  width: 196px;
  height: 46px;
`;

export const FabLabLogo = styled.img.attrs({ src: PNG.fabLab, alt: 'Krakow Fab lab logo' })`
  width: 256px;
  height: 97px;
`;

export const TmcLogo = styled.img.attrs({ src: PNG.tmc, alt: 'tmc logo' })`
  width: auto;
  height: auto;
  object-fit: contain;
`;
export const TheSpaghettiDetective = styled.img.attrs({
  src: PNG.theSpaghettiDetective,
  alt: 'spaghetti detective logo',
})`
  width: 100%;
  height: 97px;
  object-fit: contain;
`;
export const Fiberlogy = styled.img.attrs({ src: PNG.fiberlogy, alt: 'fiberlogy logo' })`
  width: 100%;
  height: 82px;
  object-fit: contain;
`;

export const ByFlow = styled.img.attrs({ src: PNG.byFlow, alt: 'fiberlogy logo' })`
  width: auto;
  height: 70px;
  object-fit: contain;
`;
