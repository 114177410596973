import styled from 'styled-components';
import { Map } from 'react-leaflet';
import { BsChevronLeft } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';

export const Wrapper = styled.div`
  width: 92vw;
  height: 94vh;
  padding: 16px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
`;

export const TopWrapper = styled.div`
  box-sizing: border-box;
  height: 86px;
  max-width: 1694px;
  width: 100%;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Back = styled.div`
  color: #4D4D4D;
  cursor: pointer;
  letter-spacing: 0.24px;
  font-size: 15px;
  font-weight: 700;
  display: flex;
`;

export const BackToPrintRequest = styled.div`
  padding-top: 10px;
  margin-left: -4px;
  font-size: 14px;
`;

export const BackIcon = styled(BsChevronLeft)`
  font-size: 24px;
  position: relative;
  top: 6px;
  left: -7px;
`;

export const CloseWrapper = styled.div`
  box-sizing: border-box;
  background-color: #f7f7f7;
  border-radius: 100%;
  padding: 4px;
  cursor: pointer;
  height: 42px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Close = styled(MdClose)`
  height: 31px;
  width: 31px;
  color: #333333;
  background-color: #f7f7f7;
  border-radius: 100%;
  cursor: pointer;
`;

// max-height: 78vh;
export const StyledMap = styled(Map)`
  width: 100%;
  height: calc(100% - 86px);
  border-radius: 8px;
  background-color: #e2e2e2;
  margin-bottom: 8px;
`;
