import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import OfferAddedModal from '../Modals/OfferAddedModal/OfferAddedModal';
import { useLoggedInUser } from '../../redux/reducers/auth/selectors';
import { OfferSimpleProps } from '../../models/offer';
import PhotosSlider from './PhotosSlider/PhotosSlider';
import Slider from '../Common/Slider/Slider';
import { SLIDER_TYPE } from '../Dashboard/constant';
import { getSizeIcon } from '../../helpers/functions';
import { HashLink } from 'react-router-hash-link';
import Linkify from 'react-linkify';
import { linkDecorator } from '../../utils/linkDecorator';
import Discussion from './Discussion';
import { ImageProps } from '../../models/common';
import {
  PRODUCT_TYPE,
  isProductForPurchase,
  productTypeName,
  productDeadlineInfoText,
} from '../../utils/productType';
import CreatedProductModal from '../Modals/CreatedProductModal/CreatedProductModal';
import {
  viewedDesignRequest,
  isLoadingViewedDesignRequest,
  featuredDesignRequests,
  usersDesingRequests,
} from '../../redux/reducers/designRequest/selectors';
import { prepareDesignRequstDetails } from '../../redux/reducers/designRequest/actions';
import Loader from 'react-spinners/ClipLoader';
import {
  previewDesignRequest,
  useProductPhotosViewerModalState,
} from '../../redux/reducers/utils/selectors';
import OfferSimple from './OfferSimple/OfferSimple';
import FormatPrice from '../Common/FormatPrice/FormatPrice';
import ModalWrapper from '../Modals/ModalWrapper';
import { MODAL_NAME } from '../../redux/reducers/utils/models';
import ProductsViewer from '../Common/PhotosViewer/ProductsViewer';
import { setModalProduct, toggleModal } from '../../redux/reducers/utils/action';
import Deadline from './Deadline/Deadline';
import OfferableProductDetailsOwnerActions from './ActionButton/OfferableProductDetailsActions/OfferableProductDetailsOwnerActions';
import OfferableProductDetailsOffererActions from './ActionButton/OfferableProductDetailsActions/OfferableProductDetailsOffererActions';
import * as S from './ProductDetails.style';

const DesignRequestDetails = () => {
  const dispatch = useDispatch();
  const params: any = useParams();
   const navigate = useNavigate();
  const { hash } = useLocation();
  const { designRequestId }: any = params;
  const isPreview = designRequestId === 'preview';
  const isLoading = useSelector(isLoadingViewedDesignRequest);
  const designRequest = useSelector(viewedDesignRequest);
  const designPreview = useSelector(previewDesignRequest);
  const usedDesign = isPreview ? designPreview : designRequest;
  const [selectedPhoto, setSelectedPhoto] = useState(0);
  const {
    id: productId,
    comments = [],
    title,
    price,
    description,
    height,
    width,
    depth,
    size: { symbol },
    currency,
    images = [],
    status,
    fileFormat,
    user: { id: ownerId, name, nickname, avatar },
    offers,
    offerLicense: { title: licenseTitle, description: licenseDescription, shortDescription },
    deadline,
  } = usedDesign;
  const { id: userId } = useSelector(useLoggedInUser);
  const isOwner = userId === ownerId;
  const isShowOwnerActionsActive = !isPreview && isOwner;
  const isShowOffererActionsActive = !isPreview && !isOwner;
  const featuredDesigns = useSelector(featuredDesignRequests);
  const usersDesigns = useSelector(usersDesingRequests);
  const searchLink = '/search/design-requests/';
  const isProperId = !isPreview ? designRequestId === productId : true;
  const offersAmmount = offers ? offers.length : 0;
  const hasPlacedOffer =
    userId &&
    offers &&
    offers.find((offer: OfferSimpleProps) => offer.userId === userId && !offer.isCanceled)
      ? true
      : false;
  const budgetOrPriceText = isProductForPurchase[PRODUCT_TYPE.DESIGN_REQUEST] ? 'Price' : 'Budget';
  const isProductPhotosViewerActive = useSelector(useProductPhotosViewerModalState);

  useEffect(() => {
    if (isProductPhotosViewerActive) {
      dispatch(toggleModal(MODAL_NAME.PRODUCT_PHOTOS_VIEWER));
    }
    if (!isPreview) {
      dispatch(prepareDesignRequstDetails(designRequestId));
    }
  }, [designRequestId, dispatch, isPreview]);

  const userName = nickname && nickname.length > 0 ? nickname : name;
  const productDeadlineTag = productDeadlineInfoText[PRODUCT_TYPE.DESIGN_REQUEST];
  const deadlineData = { deadline, productDeadlineTag };
  const displayLicense = () => {
    if (licenseTitle) {
      return (
        <S.DescriptionWrapper>
          <S.SectionTitle>License details</S.SectionTitle>
          <S.LicenseDetails>
            <S.LicenseTitle>{licenseTitle}</S.LicenseTitle>
            <S.LicenseDetail>{shortDescription}</S.LicenseDetail>
          </S.LicenseDetails>
          <S.Description>
          {/* TODO */}
            {/* <Linkify componentDecorator={linkDecorator}>{licenseDescription}</Linkify> */}
          </S.Description>
        </S.DescriptionWrapper>
      );
    }
  };

  const goBack = () => navigate(-1)

  const HashOffset = ({ hashVal, offset }: { hashVal: string; offset: number }) => (
    <div style={{ position: 'relative' }}>
      <span id={hashVal} style={{ position: 'absolute', top: `${offset}px` }} />
    </div>
  );

  const displaySoftwareSpecification = () => {
    return (
      <S.PrintingSpecificationWrapper>
        <S.Row>
          <S.SubsectionTitle>Printing</S.SubsectionTitle>
        </S.Row>
        <S.Row>
          <S.QualityName>File Extension</S.QualityName>
          <S.Value>{fileFormat}</S.Value>
        </S.Row>
      </S.PrintingSpecificationWrapper>
    );
  };

  const selectedImage = images.find(({ order }: ImageProps) => order === selectedPhoto);

  const openImageView = () => {
    dispatch(setModalProduct(usedDesign));
    dispatch(toggleModal(MODAL_NAME.PRODUCT_PHOTOS_VIEWER));
  };
  const displayImage = () => {
    if (selectedImage) {
      const { src } = selectedImage;
      return (
        <S.ImageWrapper>
          <S.PictureWrapper>
            <S.Picture onClick={() => openImageView()} src={src} />
          </S.PictureWrapper>
          <PhotosSlider photos={images} selectPhoto={setSelectedPhoto} />
        </S.ImageWrapper>
      );
    }
  };

  const displayMobileImage = () => {
    if (selectedImage) {
      const { src } = selectedImage;
      return (
        <S.MobileImageWrapper>
          <S.PictureWrapper>
            <S.Picture onClick={() => openImageView()} src={src} />
          </S.PictureWrapper>
          <PhotosSlider photos={images} selectPhoto={setSelectedPhoto} />
        </S.MobileImageWrapper>
      );
    }
  };

  if (isLoading || (!isPreview && !productId) || !isProperId) {
    return (
      <S.LoaderWrapper>
        <Loader />
      </S.LoaderWrapper>
    );
  }
  const handleBackToEdit = () => {
    navigate('/design-request/new');
  };

  const displayOffers = () => {
    //Ramzes - later => move to own component
    const isOffersEmpty = offers.length === 0;
    return (
      <>
        <S.LineBlock>
          <HashOffset hashVal="offers-hash" offset={-150} />
          <S.SectionTitle>Offers</S.SectionTitle>
          <S.OffersCount>
            ( {offers.length} {offers.length > 1 || offers.length === 0 ? 'offers' : 'offer'} )
          </S.OffersCount>
        </S.LineBlock>
        <S.OffersWrapper>
          {isOffersEmpty ? (
            <S.EmptyOffersWrapper>
              <S.ImagesWrapper>
                <S.EmptyOfferConstruct />
                <S.EmptyOfferImage />
                <S.EmptyOfferImage />
                <S.EmptyOfferImage />
                <S.EmptyOfferImage />
              </S.ImagesWrapper>
              <S.EmptyOfferTextWrapper>
                <S.EmptyOfferTopText>There is no offer yet</S.EmptyOfferTopText>
                <S.EmptyOfferBottomText>
                  Be the first one - <u>offer printing service now</u>
                </S.EmptyOfferBottomText>
              </S.EmptyOfferTextWrapper>
            </S.EmptyOffersWrapper>
          ) : (
            offers.map((offer: OfferSimpleProps) => <OfferSimple key={offer.id} {...offer} />)
          )}
        </S.OffersWrapper>
      </>
    );
  };

  return (
    <>
      <ModalWrapper
        title={MODAL_NAME.PRODUCT_PHOTOS_VIEWER}
        child={
          <ProductsViewer
            currentPhotoIndex={selectedPhoto}
            setParentImageIndex={setSelectedPhoto}
          />
        }
      />
      <S.Wrapper>
        {isPreview && (
          <S.PreviewWrapper>
            <S.PreviewLeft>
              <S.TopTitle>New design request -</S.TopTitle>
              <S.BottomTitle>Preview</S.BottomTitle>
            </S.PreviewLeft>
            <S.PreviewRight>
              <S.previewInfo>The request is not published yet</S.previewInfo>
              <S.BackToEditing onClick={handleBackToEdit}>Back to editing</S.BackToEditing>
              <S.Publish>Publish</S.Publish>
            </S.PreviewRight>
          </S.PreviewWrapper>
        )}
        <OfferAddedModal />
        <CreatedProductModal productType={PRODUCT_TYPE.DESIGN_REQUEST} />
        <S.Back>
          <S.ArrowLeft onClick={goBack} /> <S.BackText onClick={goBack}>Back</S.BackText>
        </S.Back>
        <S.PrintWrapper>
          <S.RightColumn>
            <S.AbstractWrapper>
              <S.SmallTitle>
                <b>Design</b> request ––
              </S.SmallTitle>
              <S.Title>
                {title} <S.ItemThumbnailHotStatus isFeatured={false}>New</S.ItemThumbnailHotStatus>
              </S.Title>
              {displayMobileImage()}
              <S.Author to={`/profile/${ownerId}`}>
                <S.CreatedBy>Created by:</S.CreatedBy>
                {avatar ? <S.Avatar src={avatar} /> : <S.Person />}
                <S.Name>{userName}</S.Name>
              </S.Author>
              <S.BudgetWrapper>
                <S.Tag>{budgetOrPriceText}</S.Tag>
                <S.BudgetValuesWrapper>
                  <S.Price>
                    <FormatPrice price={price} productCurrency={currency} />
                  </S.Price>
                </S.BudgetValuesWrapper>
              </S.BudgetWrapper>
              <Deadline {...deadlineData} />
              <S.OfferPrintingWrapper>
                {isShowOwnerActionsActive && (
                  <OfferableProductDetailsOwnerActions
                    id={productId}
                    productType={PRODUCT_TYPE.DESIGN_REQUEST}
                    productStatus={status}
                    offersAmmount={offersAmmount}
                  />
                )}
                {isShowOffererActionsActive && (
                  <OfferableProductDetailsOffererActions
                    id={productId}
                    productType={PRODUCT_TYPE.DESIGN_REQUEST}
                    productStatus={status}
                    offersAmmount={offersAmmount}
                    hasPlacedOffer={hasPlacedOffer}
                  />
                )}
              </S.OfferPrintingWrapper>
            </S.AbstractWrapper>
          </S.RightColumn>
          <S.LeftColumn>
            {displayImage()}
            <S.MenuWrapper>
              <HashLink smooth to="#description-hash">
                <S.MenuItem isActive={hash === '#description-hash' || !hash}>
                  Description
                </S.MenuItem>
              </HashLink>
              <HashLink smooth to="#specification-hash">
                <S.MenuItem isActive={hash === '#specification-hash'}>Specification</S.MenuItem>
              </HashLink>
              {/* <HashLink smooth to="#location-hash">
                <S.MenuItem isActive={hash === '#location-hash'}>Location</S.MenuItem>
              </HashLink> */}
              <HashLink smooth to="#offers-hash">
                <S.MenuItem isActive={hash === '#offers-hash'}>Offers</S.MenuItem>
              </HashLink>
              <HashLink smooth to="#discussion-hash">
                <S.MenuItem isActive={hash === '#discussion-hash'}>Discussion</S.MenuItem>
              </HashLink>
            </S.MenuWrapper>
            <S.DescriptionWrapper>
              <HashOffset hashVal="description-hash" offset={-120} />
              <S.SectionTitle>Description</S.SectionTitle>
              <S.Description>
                {/* TODO */}
                {/* <Linkify componentDecorator={linkDecorator}>{description}</Linkify> */}
              </S.Description>
            </S.DescriptionWrapper>
            {displayLicense()}
            <S.SpecificationWrapper>
              <HashOffset hashVal="specification-hash" offset={-120} />
              <S.SectionTitle>Specification</S.SectionTitle>
              <S.DimensionsWrapper>
                <S.Row>
                  <S.SubsectionTitle>Size</S.SubsectionTitle>
                  {getSizeIcon(symbol)}
                </S.Row>
                <S.Row>
                  <S.QualityName>Width</S.QualityName>
                  <S.Value>{width} mm</S.Value>
                </S.Row>
                <S.Row>
                  <S.QualityName>Depth</S.QualityName>
                  <S.Value>{depth} mm</S.Value>
                </S.Row>
                <S.Row>
                  <S.QualityName>Height</S.QualityName>
                  <S.Value>{height} mm</S.Value>
                </S.Row>
              </S.DimensionsWrapper>
              {displaySoftwareSpecification()}
            </S.SpecificationWrapper>
            {displayOffers()}
            <>
              <HashOffset hashVal="discussion-hash" offset={-120} />
              <Discussion
                comments={comments}
                productId={designRequestId}
                productType={PRODUCT_TYPE.DESIGN_REQUEST}
              />
            </>
          </S.LeftColumn>
        </S.PrintWrapper>
        <S.SlidersWrapper>
          {usersDesigns.length > 0 && (
            <S.SliderWrapper>
              <S.SliderTitle to={`/profile/${ownerId}/market`}>
                <S.SliderUsersName>Other {userName}'s</S.SliderUsersName>{' '}
                {productTypeName[PRODUCT_TYPE.DESIGN_REQUEST]} ({usersDesigns.length})
              </S.SliderTitle>
              <Slider
                productType={PRODUCT_TYPE.DESIGN_REQUEST}
                items={usersDesigns}
                sliderType={SLIDER_TYPE.COMPACT}
              />
            </S.SliderWrapper>
          )}
          {featuredDesigns.length > 0 && (
            <S.SliderWrapper>
              <S.SliderTitle to={searchLink}>
                <S.B>Featured</S.B> {PRODUCT_TYPE.DESIGN_REQUEST}
              </S.SliderTitle>
              <Slider
                productType={PRODUCT_TYPE.DESIGN_REQUEST}
                items={featuredDesigns}
                sliderType={SLIDER_TYPE.COMPACT}
              />
            </S.SliderWrapper>
          )}
        </S.SlidersWrapper>
      </S.Wrapper>
    </>
  );
};

export default memo(DesignRequestDetails);
