import React, { memo } from 'react';
import * as S from './Menu.styles';

const Menu = ({
  edit,
  remove,
  styles,
  title = 'Gallery',
}: {
  title?: string;
  styles?: string;
  edit?: (e: any) => void;
  remove?: (e: any) => void;
}) => (
  <S.MenuWrapper styles={styles}>
    <S.Title>{title}</S.Title>
    <S.MenuOption onClick={edit}>Edit</S.MenuOption>
    <S.MenuOption onClick={remove}>Remove</S.MenuOption>
  </S.MenuWrapper>
);

export default memo(Menu);
