import React from 'react';
import { useDispatch } from 'react-redux';
import * as S from './DeliveryAddressSelector.style';
import { toggleModal } from '../../../redux/reducers/utils/action';
import { MODAL_NAME } from '../../../redux/reducers/utils/models';

export default () => {
  const dispatch = useDispatch();
  return (
    <S.AddressFormWrapper isSelectable>
      <S.AddressOptionRadioWrapper>
        <S.AddressOptionRadio>
          <S.RadioButton isActive={false}></S.RadioButton>
        </S.AddressOptionRadio>
      </S.AddressOptionRadioWrapper>
      <S.AddressOptionDetails>
        <S.AddressBodyWrapper>
          <S.NoDefaultMessage>Default address is not set up</S.NoDefaultMessage>
          <S.NoDefaultLink onClick={() => dispatch(toggleModal(MODAL_NAME.DEFAULT_ADDRESS))}>
            Add your default delivery address
          </S.NoDefaultLink>
        </S.AddressBodyWrapper>
        <S.AddressAddressDefault>Default delivery address</S.AddressAddressDefault>
      </S.AddressOptionDetails>
    </S.AddressFormWrapper>
  );
};
