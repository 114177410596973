export const REFRESH_REQUEST_SUCCESS = 'REFRESH_REQUEST_SUCCESS';
export const CREATE_REQUEST_SUCCESS = 'CREATE_REQUEST_SUCCESS';
export const FETCH_OWN_OFFERS_SUCCESS = 'FETCH_OWN_OFFERS_SUCCESS';
export const FETCH_OWN_PRINT_REQUESTS_SUCCESS = 'FETCH_OWN_PRINT_REQUESTS_SUCCESS';
export const FETCH_PRINT_REQUESTS_REQUEST = 'FETCH_PRINT_REQUESTS_REQUEST';
export const GALLERY_ADDED = 'GALLERY_ADDED';
export const GALLERY_ADDED_SUCCESS = 'GALLERY_ADDED_SUCCESS';
export const GALLERY_UPDATED_SUCCESS = 'GALLERY_UPDATED_SUCCESS';
export const GALLERY_DELETED_SUCCESS = 'GALLERY_DELETED_SUCCESS';
export const GET_GALLERY_DATA = 'GET_GALLERY_DATA';
export const GET_PRINT_REQUEST_DETAILS = 'GET_PRINT_REQUEST_DETAILS';
export const GET_PRINT_REQUEST_DETAILS_SUCCESS = 'GET_PRINT_REQUEST_DETAILS_SUCCESS';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const RESET_PICK_A_WINNER = 'RESET_PICK_A_WINNER';
export const SET_FEATURED_ITEMS = 'SET_FEATURED_ITEMS';
export const SET_FETCHING_STATUS = 'SET_FETCHING_STATUS';
export const SET_GALLERIES = 'SET_GALLERIES';
export const SET_GALLERY_ID_FOR_EDIT = 'SET_GALLERY_ID_FOR_EDIT';
export const SET_LASTLY_VISITED = 'SET_LASTLY_VISITED';
export const SET_LASTLY_SEARCHED = 'SET_LASTLY_SEARCHED';
export const SET_LOADING_RESULTS = 'SET_LOADING_RESULTS';
export const SET_PICK_A_WINNER_STEP = 'SET_PICK_A_WINNER_STEP';
export const SET_SELECTED_GALLERY_ID = 'SET_SELECTED_GALLERY_ID';
export const SET_SELECTED_PHOTO_ID = 'SET_SELECTED_PHOTO_ID';
export const SET_SIMPLE_SEARCH_PARAMS = 'SET_SIMPLE_SEARCH_PARAMS';
export const SET_SIMPLE_SEARCH_RESULTS = 'SET_SIMPLE_SEARCH_RESULTS';
export const SET_UPDATED_OFFERS = 'SET_UPDATED_OFFERS';
export const SET_USERS_QUERIES = 'SET_USERS_QUERIES';
export const SET_WINNER_MESSAGE = 'SET_WINNER_MESSAGE';
export const SET_WINNER_OFFER_ID = 'SET_WINNER_OFFER_ID';
export const TOGGLE_ITEM_TO_SEARCH = 'TOGGLE_ITEM_TO_SEARCH';
export const SET_MODAL_VISIBILITY = 'SET_MODAL_VISIBILITY';
export const CLOSE_MODALS = 'CLOSE_MODALS';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const TOGGLE_PHOTO_VIEW = 'TOGGLE_PHOTO_VIEW';
export const SET_ADVANCED_PRINTS_SEARCH_SUCCESS = 'SET_ADVANCED_PRINTS_SEARCH_SUCCESS';
export const SET_SUBMITTING_PRINT_REQUEST = 'SET_SUBMITTING_PRINT_REQUEST';
export const FETCH_OWN_DESIGN_REQUESTS_SUCCESS = 'FETCH_OWN_DESIGN_REQUESTS_SUCCESS';
export const FETCH_OWN_DESIGNS_FOR_SELL = 'FETCH_OWN_DESIGNS_FOR_SELL';
export const FETCH_OWN_OBJECTS_FOR_SELL = 'FETCH_OWN_OBJECTS_FOR_SELL';
export const SET_NEW_ADDRESS_ERROR = 'SET_NEW_ADDRESS_ERROR';
export const SET_DELIVERY_OPTIONS_ERROR = 'SET_DELIVERY_OPTIONS_ERROR';
export const SET_LOADING_CREATE_NEW_ADDRESS = 'SET_LOADING_CREATE_NEW_ADDRESS';
export const GET_DESIGN_REQUEST_DETAILS_SUCCESS = 'GET_DESIGN_REQUEST_DETAILS_SUCCESS';
export const GET_DESIGN_REQUEST_DETAILS = 'GET_DESIGN_REQUEST_DETAILS';
export const SET_SUBMITTING_DESIGN_REQUEST = 'SET_SUBMITTING_DESIGN_REQUEST';
export const GET_DESIGN_FOR_SELL_DETAILS_SUCCESS = 'GET_DESIGN_FOR_SELL_DETAILS_SUCCESS';
export const GET_DESIGN_FOR_SELL_DETAILS = 'GET_DESIGN_FOR_SELL_DETAILS';
export const SET_SUBMITTING_FOR_SELL = 'SET_SUBMITTING_FOR_SELL_REQUEST';
export const GET_OBJECT_FOR_SELL_DETAILS_SUCCESS = 'GET_OBJECT_FOR_SELL_DETAILS_SUCCESS';
export const GET_OBJECT_FOR_SELL_DETAILS = 'GET_OBJECT_FOR_SELL_DETAILS';
export const SET_SUBMITTING_OBJECT_FOR_SELL = 'SET_SUBMITTING_OBJECT_FOR_SELL';
export const SET_PRODUCT_CREATION_ERROR_MESSAGE = 'SET_PRODUCT_CREATION_ERROR_MESSAGE';
export const SET_WORKSHOP_DEVICES = 'SET_WORKSHOP_DEVICES';
export const SET_SELECTED_DEVICE_ID = 'SET_SELECTED_DEVICE_ID';
export const ADD_CONTRACTOR_REVIEW = 'ADD_CONTRACTOR_REVIEW';
export const ARCHIVE_PRODUCT_SUCCESS = 'ARCHIVE_PRODUCT_SUCCESS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ADVANCED_SEARCH_RESULTS_START = 'GET_ADVANCED_SEARCH_RESULTS_START';
export const GET_ADVANCED_SEARCH_RESULTS_SUCCESS = 'GET_ADVANCED_SEARCH_RESULTS_SUCCESS';
export const GET_ADVANCED_SEARCH_RESULTS_FAILURE = 'GET_ADVANCED_SEARCH_RESULTS_FAILURE';
export const CHECK_OFFER_REVIEW_SUCCESS = 'CHECK_OFFER_REVIEW_SUCCESS';
export const GET_MY_SHOPPING_SUCCESS = 'GET_MY_SHOPPING_SUCCESS';
export const BUY_PRODUCT_SUCCESS = 'BUY_PRODUCT_SUCCESS';
export const UPDATE_USER_ARCHIVE_STATUS_SUCCESS = 'UPDATE_USER_ARCHIVE_STATUS_SUCCESS';
export const EDIT_PRODUCT_SUCESS = 'EDIT_PRODUCT_SUCESS';
export const ACCEPT_COOKIES = 'ACCEPT_COOKIES';
export const SET_COMMENTS = 'SET_COMMENTS';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const SET_COMMENT = 'SET_COMMENT';
export const SET_LIKE = 'SET_LIKE';
export const SET_LOADING = 'SET_LOADING';
export const ADD_PACKAGE = 'ADD_PACKAGE';
export const ADD_PACKAGE_SUCCESS = 'ADD_PACKAGE_SUCCESS';
export const ADD_PACKAGE_FAIL = 'ADD_PACKAGE_FAIL';
export const UPDATE_PACKAGE = 'UPDATE_PACKAGE';
export const UPDATE_PACKAGE_SUCCESS = 'UPDATE_PACKAGE_SUCCESS';
export const UPDATE_PACKAGE_FAIL = 'UPDATE_PACKAGE_FAIL';
export const GET_PAYOUTS_START = 'GET_PAYOUTS_START';
export const GET_PAYOUTS_SUCCESS = 'GET_PAYOUTS_SUCCESS';
export const GET_PAYOUTS_FAILURE = 'GET_PAYOUTS_FAILURE';
export const GET_PAYMENTS_START = 'GET_PAYMENTS_START';
export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';
export const GET_PAYMENTS_FAILURE = 'GET_PAYMENTS_FAILURE';
export const GET_USER_DATA_START = 'GET_USER_DATA_START';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE';
export const FINALIZE_OFFER = 'FINALIZE_OFFER';
export const FINALIZE_OFFER_FAIL = 'FINALIZE_OFFER_FAIL';
export const FINALIZE_OFFER_SUCCESS = 'FINALIZE_OFFER_SUCCESS';
export const SET_NETWORK_ACTION_STATE = 'SET_NETWORK_ACTION_STATE';
export const GET_PURCHASE_INFO = 'GET_PURCHASE_INFO';
export const GET_PURCHASE_INFO_SUCCESS = 'GET_PURCHASE_INFO_SUCCESS';
export const GET_PURCHASE_INFO_FAILURE = 'GET_PURCHASE_INFO_FAILURE';
export const CLOSE_PRODUCT_SELLING = 'CLOSE_PRODUCT_SELLING';
export const CLOSE_PRODUCT_SELLING_SUCCESS = 'CLOSE_PRODUCT_SELLING_SUCCESS';
export const CLOSE_PRODUCT_SELLING_FAILURE = 'CLOSE_PRODUCT_SELLING_FAILURE';
export const ADD_USER_PRINTER = 'ADD_USER_PRINTER';
export const ADD_USER_PRINTER_SUCCESS = 'ADD_USER_PRINTER_SUCCESS';
export const ADD_USER_PRINTER_FAILURE = 'ADD_USER_PRINTER_FAILURE';
export const CLOSE_DFS_SELLING = 'CLOSE_PRODUCT_SELLING';
export const CLOSE_DFS_SELLING_SUCCESS = 'CLOSE_DFS_SELLING_SUCCESS';
export const CLOSE_DFS_SELLING_FAIL = 'CLOSE_DFS_SELLING_FAIL';
export const OPEN_DFS_SELLING = 'OPEN_DFS_SELLING';
export const OPEN_DFS_SELLING_SUCCESS = 'OPEN_DFS_SELLING_SUCCESS';
export const OPEN_DFS_SELLING_FAIL = 'OPEN_DFS_SELLING_FAIL';
export const CLOSE_OFS_SELLING = 'CLOSE_OFS_SELLING';
export const CLOSE_OFS_SELLING_SUCCESS = 'CLOSE_OFS_SELLING_SUCCESS';
export const CLOSE_OFS_SELLING_FAIL = 'CLOSE_OFS_SELLING_FAIL';
export const OPEN_OFS_SELLING = 'OPEN_OFS_SELLING';
export const OPEN_OFS_SELLING_SUCCESS = 'OPEN_OFS_SELLING_SUCCESS';
export const OPEN_OFS_SELLING_FAIL = 'OPEN_OFS_SELLING_FAIL';
export const CANCEL_DR = 'CANCEL_DR';
export const CANCEL_DR_SUCCESS = 'CANCEL_DR_SUCCESS';
export const CANCEL_DR_FAIL = 'CANCEL_DR_FAIL';
export const CANCEL_PR = 'CANCEL_PR';
export const CANCEL_PR_SUCCESS = 'CANCEL_PR_SUCCESS';
export const CANCEL_PR_FAIL = 'CANCEL_PR_FAIL';
