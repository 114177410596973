import { PRODUCT_TYPE } from "../../../utils/productType";

const printRequestRequirements : string[] = [
  '- Name',
  '- Surname',
  '- Phone',
  '- Email',
  '- At least one address',
  '- Banking information (payment section)' 
];
const designRequestRequirements: string[] = [
  '- Name',
  '- Surname',
  '- Phone',
  '- Email',
  '- Banking information (payment section)'
];
const objectForSellRequirements: string[] = [
  '- Name',
  '- Surname',
  '- Phone',
  '- Email',
  '- Banking information (payment section)'
];
const designForSellRequirements: string[] = [
  '- Name',
  '- Surname',
  '- Phone',
  '- Email',
  '- Banking information (payment section)'
];

export const fillRequirements = {
  [PRODUCT_TYPE.PRINT_REQUEST]: printRequestRequirements,
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: objectForSellRequirements,
  [PRODUCT_TYPE.DESIGN_REQUEST]: designRequestRequirements,
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: designForSellRequirements,
};

