import React, { useState, useRef, memo } from 'react';
import { InputProps } from '../types';
import { isFileValid } from './utils';
import * as S from '../CreateProduct.styles';

interface DropzoneProps extends InputProps{
  isUploadHidden?: boolean
}

const Dropzone = ({
  setFiles,
  label,
  isOptional = false,
  uploadType,
  showSublabel = false,
  showThumbnails = false,
  isWorkshop = false,
  maxItems = 10,
  isUploadHidden
}: DropzoneProps) => {
  const [data, setData] = useState<any[]>([]);
  const usedData = data.slice(0, maxItems);
  const [err, setErr] = useState('');
  if (err) {
    console.log(err);
  }

  const onDrop = (e: any) => {
    e.preventDefault();
    handleUpload(e.dataTransfer.files);
  };

  const onDragOver = (e: any) => e.preventDefault();

  const removeItem = (e: any, itemName: string, itemSize: number) => {
    const newData = data.filter(({ name, size }: any) => name !== itemName && size !== itemSize);
    setData(newData);
    setFiles(newData);
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const handleUpload = async (files: any) => {
    const { length } = files;
    if (length === 0) {
      return false;
    }
    setErr('');
    setData([]);

    const newDataArray: Array<any> = [];

    [...files].map((file: any) => {
      const { size, type } = file;
      const { errorMessage } = isFileValid({ uploadType, type, size });
      setErr(errorMessage);
      if (!errorMessage) {
        newDataArray.push(file);
      }
      return null;
    });
    const filesToUpload = [...data, ...newDataArray].slice(0, maxItems);
    setFiles(filesToUpload);
    setData(filesToUpload);
  };

  const renderFilledDropzone = (showThumbnails: boolean, uploadType: string) => (
    <S.DropzoneWrapper isFilled isThumbnail isWorkshop={isWorkshop}>
      <S.ImagesWrapper>
        {showThumbnails ? (
          <>
            {usedData.map((item: any, index: number) => (
              <S.ImageWrapper key={index} isWorkshop={isWorkshop}>
                <S.Image src={URL.createObjectURL(item)} isWorkshop={isWorkshop} />
                <S.RemoveImageWrapper onClick={(e) => removeItem(e, item.name, item.size)}>
                  <S.RemoveImage />
                </S.RemoveImageWrapper>
              </S.ImageWrapper>
            ))}
            {maxItems > usedData.length && (
              <S.DropBoxWrapper
                onDrop={(e) => onDrop(e)}
                onDragOver={(e) => onDragOver(e)}
                isWorkshop={isWorkshop}
              >
                <S.DropBoxAddButton onClick={(e: any) => handleInputFileClick(e)}>
                  <S.DropBoxPlus />
                </S.DropBoxAddButton>
                <S.DropzoneInfo>Add {uploadType}</S.DropzoneInfo>
              </S.DropBoxWrapper>
            )}
          </>
        ) : (
          <>
            {usedData.map((item: any, index: number) => (
              <S.DropzoneFileWrapper key={index}>
                <S.DropzoneFileName>{item.name}</S.DropzoneFileName>
                <S.RemoveFile onClick={(e) => removeItem(e, item.name, item.size)} />
              </S.DropzoneFileWrapper>
            ))}
          </>
        )}
      </S.ImagesWrapper>
    </S.DropzoneWrapper>
  );

  const renderEmptyDropzone = (uploadType: string) => (
    <S.DropzoneWrapper isWorkshop={isWorkshop}>
      {uploadType === 'file' && (
        <S.FilesWrapper onClick={(e: any) => handleInputFileClick(e)}>
          <S.DropzoneInfo tabIndex={0}>Select {uploadType} to upload</S.DropzoneInfo>
          <S.DropzoneText>or</S.DropzoneText>
          <S.DropzoneText>Drag and drop here</S.DropzoneText>
        </S.FilesWrapper>
      )}
      {uploadType !== 'file' && (
        <S.DropBoxWrapper
          onClick={(e: any) => handleInputFileClick(e)}
          onDrop={(e) => onDrop(e)}
          onDragOver={(e) => onDragOver(e)}
          isWorkshop={isWorkshop}
        >
          <S.DropBoxAddButton>
            <S.DropBoxPlus />
          </S.DropBoxAddButton>
          <S.DropzoneInfo>Add {uploadType}</S.DropzoneInfo>
        </S.DropBoxWrapper>
      )}
    </S.DropzoneWrapper>
  );
  const inputFile = useRef(null);
  const handleInputFileClick = (e: any) => {
    //@ts-ignore
    inputFile.current.value = null;
    //@ts-ignore
    inputFile.current.click();
  };
  return (
    <S.InputWrapper onDrop={(e) => onDrop(e)} onDragOver={(e) => onDragOver(e)}>
      <input
        required={!isOptional}
        type="file"
        ref={inputFile}
        multiple
        style={{ display: 'none' }}
        onChange={(e) => handleUpload(e.target.files)}
      />
      <S.LabelWrapper>
        <S.Label>{label}</S.Label>
        {isOptional && <S.OptionalLabel>(optional)</S.OptionalLabel>}
      </S.LabelWrapper>
      {usedData.length > 0
        ? renderFilledDropzone(showThumbnails, uploadType)
        : renderEmptyDropzone(uploadType)}
      {showSublabel && (
        <S.DropzoneSublabels>
          <S.DropzoneSublabel>
            • First image will be used as a miniature for this request.
          </S.DropzoneSublabel>
          <S.DropzoneSublabel>• Graphic files are accepted only eg. JPEG, PNG.</S.DropzoneSublabel>
          <S.DropzoneSublabel>• Maximum size per image is 2 MB.</S.DropzoneSublabel>
        </S.DropzoneSublabels>
      )}
      <S.UploadFilesWrapper>
        <S.SetFiles onClick={(e: any) => handleInputFileClick(e)}>Select file to upload</S.SetFiles>
        {!isUploadHidden && <S.Upload onClick={(e: any) => handleInputFileClick(e)}>Upload loaded files</S.Upload>}
      </S.UploadFilesWrapper>
    </S.InputWrapper>
  );
};

export default memo(Dropzone);
