import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input, { INPUT_TYPE } from '../../CreateProduct/Inputs/Input';
import { useLoggedInUser } from '../../../redux/reducers/auth/selectors';
import { useParams } from 'react-router-dom';
import { useUserProfile } from '../../../redux/reducers/userProfiles';
import TextArea from '../../CreateProduct/Inputs/TextArea';
import { toggleModal } from '../../../redux/reducers/utils/action';
import { MODAL_NAME } from '../../../redux/reducers/utils/models';
import * as S from '../ChangeEmailModal/ChangeEmailModal.styles';

const SendEmailModal = () => {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(toggleModal(MODAL_NAME.SEND_EMAIL));
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const { name, surname, email, avatar } = useSelector(useLoggedInUser);

  const params: any = useParams();
  const { userId } = params;
  const { name: receiverName, surname: receiverSurname, email: receiverEmail } = useUserProfile(
    userId
  );

  const encryptMail = (email: string) => {
    if (email) {
      let encrypted = '';
      for (let index = 0; index < email.length; index++) {
        const element = email[index];
        if (element === '@' || element === '.') {
          encrypted += element;
        } else {
          encrypted += '*';
        }
      }
      return encrypted;
    }
  };

  const encryptedRecipientEmail = encryptMail(receiverEmail);
  return (
    <S.ExternalWrapper>
      <S.Wrapper maxWidth="700px">
        <S.TopWrapper>
          <S.Title>Send email</S.Title>
          <S.Close onClick={handleClose} />
        </S.TopWrapper>
        <S.BodyWrapper>
          <S.Row>
            <S.ContactWrapper>
              <S.ContactLabel>Send to:</S.ContactLabel>
              <S.ContactHeaderRow>
                {avatar ? <S.Avatar src={avatar} /> : <S.Person />}
                <S.ContactName>
                  {receiverName} {receiverSurname}
                </S.ContactName>
              </S.ContactHeaderRow>
              <S.ContactEmail>{encryptedRecipientEmail}</S.ContactEmail>
            </S.ContactWrapper>

            <S.OwnEmailWrapper>
              <S.ContactLabel>From:</S.ContactLabel>
              <S.ContactHeaderRow>
                {avatar ? <S.Avatar src={avatar} /> : <S.Person />}
                <S.ContactName>
                  {name} {surname}
                </S.ContactName>
              </S.ContactHeaderRow>
              <S.ContactEmail>{email}</S.ContactEmail>
            </S.OwnEmailWrapper>
          </S.Row>
          <Input
            value={subject}
            label={'Title'}
            handleInputChange={(val: any) => setSubject(val)}
            type={INPUT_TYPE.LONG}
            required={true}
            valueType="text"
          />
          <TextArea
            styles="height: 208px;"
            label={'Message'}
            value={message}
            handleInputChange={setMessage}
          />
        </S.BodyWrapper>
        <S.BottomWrapper>
          <S.CloseButton onClick={handleClose}>Cancel</S.CloseButton>
          <S.NextButton
            disabled={subject.length === 0 || message.length === 0}
            onClick={() => dispatch(toggleModal(MODAL_NAME.SEND_EMAIL))}
          >
            Send message
          </S.NextButton>
        </S.BottomWrapper>
      </S.Wrapper>
    </S.ExternalWrapper>
  );
};

export default memo(SendEmailModal);
