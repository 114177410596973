import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import * as S from './HeaderMainMenu.styles';
import { isModalOpen } from '../../../../redux/reducers/utils/selectors';
import { MODAL_NAME } from '../../../../redux/reducers/utils/models';

const HeaderMainMenu = () => {
  const isOpen = useSelector(isModalOpen(MODAL_NAME.HEADER_MAIN_MENU));

  if (!isOpen) {
    return null;
  }

  return (
    <S.Wrapper>
      <S.BodyWrapper>
        <S.Column>
          <S.Header>Printing</S.Header>
          <S.Option to={'/search/print-requests/'}>Printing requests</S.Option>
          <S.DisabledOption>Printers for hire</S.DisabledOption>
          <S.Option to={'/search/objects-for-sell/'}>Ready objects for sale</S.Option>
        </S.Column>
        <S.Column>
          <S.Header>Design</S.Header>
          <S.Option to={'/search/design-requests/'}>Design requests</S.Option>
          <S.DisabledOption>Designers for hire</S.DisabledOption>
          <S.Option to={'/search/designs-for-sell/'}>Ready designs for sale</S.Option>
        </S.Column>
      </S.BodyWrapper>
    </S.Wrapper>
  );
};

export default memo(HeaderMainMenu);
