import React, { memo } from 'react';
import { Option } from '../../../models/common';
import * as S from '../CreateProduct.styles';

interface SelectorProps {
  handleOptionSelect?: (e: Option) => void;
  selected: any;
  options: Option[];
  type?: SELECTOR_TYPE;
  selectorAlignment?: SELECTOR_BORDER_RADIUS_OPTIONS;
  value?: any;
  required?: boolean;
  isSearchable?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

export enum SELECTOR_TYPE {
  SHORT,
  MEDIUM,
  LONG,
  WIDE,
  NARROW,
  MODERATE,
  TINY,
  USER_DASHBOARD,
}

export enum SELECTOR_BORDER_RADIUS_OPTIONS {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  CENTER = 'CENTER',
}

export enum SELECTOR_POSITIONS {
  ACTIVE = 'ACTIVE',
  PASSIVE = 'PASSIVE',
}

export const selectorBorders = {
  [SELECTOR_BORDER_RADIUS_OPTIONS.RIGHT]: {
    [SELECTOR_POSITIONS.ACTIVE]: '0 4px 0 0',
    [SELECTOR_POSITIONS.PASSIVE]: '0 4px 4px 0',
  },
  [SELECTOR_BORDER_RADIUS_OPTIONS.LEFT]: {
    [SELECTOR_POSITIONS.ACTIVE]: '4px 0 0 0',
    [SELECTOR_POSITIONS.PASSIVE]: '4px 0 0 4px',
  },
  [SELECTOR_BORDER_RADIUS_OPTIONS.CENTER]: {
    [SELECTOR_POSITIONS.ACTIVE]: '4px 4px 0 0 ',
    [SELECTOR_POSITIONS.PASSIVE]: '4px 4px 4px 4px',
  },
};

const getSelectorWidth = (type: SELECTOR_TYPE) => {
  switch (type) {
    case SELECTOR_TYPE.SHORT:
      return '107px';
    case SELECTOR_TYPE.MEDIUM:
      return '303px';
    case SELECTOR_TYPE.LONG:
      return '100%';
    case SELECTOR_TYPE.WIDE:
      return '160px';
    case SELECTOR_TYPE.NARROW:
      return '108px';
    case SELECTOR_TYPE.MODERATE:
      return '246px';
    case SELECTOR_TYPE.TINY:
      return '96px';
    case SELECTOR_TYPE.USER_DASHBOARD:
      return '245px';
  }
};
const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    border: 'none',
    width: '100%',
    boxShadow: state.isFocused ? '0 0 2px 1px #0077df' : 'none',
    cursor: 'pointer',
  }),
  menuPortal: (provided: any, state: any) => ({ ...provided, zIndex: 9999 }),
  option: (provided: any, state: any) => ({ ...provided, cursor: 'pointer' }),
  placeholder: (provided: any, state: any) => ({ ...provided, color: '#000' }),
};

const Selector = ({
  handleOptionSelect,
  options,
  type = SELECTOR_TYPE.SHORT,
  selectorAlignment = SELECTOR_BORDER_RADIUS_OPTIONS.CENTER,
  value,
  required = false,
  isSearchable = true,
  disabled = false,
  selected,
  placeholder,
}: SelectorProps) => (
  <S.Selector
    menuPortalTarget={document.body}
    isDisabled={disabled}
    isSearchable={isSearchable}
    width={getSelectorWidth(type)}
    selectorAlignment={selectorAlignment}
    styles={customStyles}
    options={options}
    onChange={handleOptionSelect}
    value={value}
    required={required}
    placeholder={placeholder}
  />
);

export default memo(Selector);
