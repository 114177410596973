export default {
  default: {
    black0: '#000',
    blackBlue: '#00071a',
    blue: '#0077df',
    bluishGray: '#dfebf6',
    grayBlue: '#456784',
    grayA3: '#a3a3a3',
    gray4a: '#4a4a4a',
    gray4d: '#4d4d4d',
    gray9a: '#9a9a9a',
    grayA6: '#a6a6a6',
    grayBf: '#bfbfbf',
    grayD: '#ddd',
    grayD4: '#d4d4d4',
    grayD2: '#d2d2d2',
    grayDb: '#dbdbdb',
    grayDf: '#dfdfdf',
    grayE: '#eee',
    grayEd: '#ededed',
    grayE2: '#e2e2e2',
    grayE3: '#e3e3e3',
    grayEf: '#efefef',
    grayFa: '#fafafa',
    grayFc: '#fcfcfc',
    grayF8: '#f8f8f8',
    grayF4: '#f4f4f4',
    grayF5: '#f5f5f5',
    green: 'green',
    leaf56: '#1eaf56',
    grey33: '#333',
    grey5f: '#5f5f5f',
    grey70: '#707070',
    grey98: '#989898',
    grayA9a1: '#a9a1a1',
    red: 'red',
    selenide: '#66ce6e',
    transparentGray: '#0000000f',
    transparentGray29: '#00000029',
    white: '#fff',
    yellow: '#FFD501',
  },
};
