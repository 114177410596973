export const maxInputValue = 999999;
export const descriptionCharactersLimit = 1000;
export const maxImagesLimit = 5;
export const maxRequestNameLength = 100;
export const maxGalleryNameLength = 70;
export const maxContactFieldLength = 120;
export const maxDimensionValue = 99999;
export const maxDescriptionLength = 1000;
export const maxPerimetersValue = 99;
export const maxPhoneInputValue = 999999999999;
export const paymentFee = 0.1;
export const daysLimit = 500;
export const maxDeadline = 90;
export const perimetersLimit = 99;
export const defaultStepLimit = '.01';
export const defaultCurrencyLabel = 'EUR';
export const maxShortDescriptionLength = 100;
