import styled from 'styled-components';
import { IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';
import themes from '../Assets/colorThemes';

export const DashboardWrapper = styled.div``;

export const DashboardNavigation = styled.div`
  background-color: ${themes.default.grayEf};
  height: 323px;
  box-shadow: inset 0 7px 9px -8px ${themes.default.grayD4};
  display: flex;
  justify-content: center;
`;

export const DashboardIm = styled.div`
  height: 625px;
  box-shadow: inset 0 7px 9px -8px ${themes.default.grayD4};
`;

export const DashboardColumn = styled.div`
  height: 100%;
  width: 66.5%;
  margin-top: 47px;
`;

export const WelcomeMessage = styled.div``;

export const MessageTop = styled.div`
  font-size: 1.4rem;
  letter-spacing: 0.034em;
`;

export const MessageBottom = styled.div`
  font-size: 0.8rem;
  letter-spacing: 0.00625em;
  font-weight: 300;
  padding-top: 10.5px;
`;

export const Navigation = styled.div`
  margin-top: 7.5%;
`;

export const NavigationInfo = styled.div`
  height: 35px;
  font-size: 20px;
  letter-spacing: 0px;
  font-weight: 500;
  white-space: nowrap;
  font-size: 24px;
  padding-bottom: 8px;
  @media (max-width: 646px) {
    margin-top: 18px;
  }
`;

export const InfoBold = styled.span`
  font-weight: 900;
`;

export const NavigationRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const NavigationColumn = styled.div``;

export const NavigationItem = styled.div<navigationItemProps>`
  display: flex;
  flex-direction: column;
  width: 304px;
  min-width: 258px;
  margin-bottom: 12px;
  @media (max-width: 646px) {
    display: ${(props: any) => (props.simple ? 'none' : 'block')};
  }
`;

interface navigationItemProps {
  simple?: boolean;
}

export const NavigationBox = styled.div<navigationItemProps>`
  display: flex;
  flex-direction: column;
  height: 326px;
  box-shadow: ${(props: navigationItemProps) =>
    props.simple ? '' : `1px 4px 6px ${themes.default.transparentGray29}`};
  background-color: ${(props: navigationItemProps) =>
    props.simple ? 'transparent' : `${themes.default.white}`};
  border-radius: 4px;
  ${(props: navigationItemProps) =>
    props.simple ? '' : `border: 1px solid ${themes.default.grayE2}`};
`;

export const NavigationTitleContainer = styled.div<navigationItemProps>`
  position: relative;
  height: 156px;
  background-color: ${(props: navigationItemProps) => (props.simple ? '' : themes.default.grayF5)};
  border-radius: 4px 4px 0px 0px;
  ${(props: navigationItemProps) =>
    props.simple ? '' : `border: 1px solid ${themes.default.grayE2}`};
`;

export const NavigationTitle = styled.div`
  position: absolute;
  margin: 1rem 1.2rem;
  bottom: 0px;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.06em;
  width: 85%;
`;

export const NavigationOptions = styled.div<navigationItemProps>`
  height: 85px;
  ${(props: navigationItemProps) =>
    props.simple
      ? `border-top: 1px solid ${themes.default.grayEd}`
      : `border: 1px solid ${themes.default.grayF5}`};
`;

export const SimpleNavigationOptions = styled.div`
  border-top: 1px solid ${themes.default.grayE2};
  height: 85px;
`;

export const NavigationContainer = styled.div`
  position: relative;
  margin: 15px 19px;
`;

export const OptionQuestion = styled.div<navigationItemProps>`
  font-size: ${(props: navigationItemProps) => (props.simple ? '16px' : '14px')};
  letter-spacing: ${(props: navigationItemProps) => (props.simple ? '0.16px;' : '0.14px')};
  font-weight: ${(props: navigationItemProps) => (props.simple ? '400' : '600')};
  text-align: ${(props: navigationItemProps) => (props.simple ? 'right' : 'left')};
`;

export const OptionLink = styled(Link)`
  font-size: 14px;
  margin: 0.7rem 0.2rem;
  position: absolute;
  right: 0px;
  text-decoration: underline;
  cursor: pointer;
`;

export const Fade = styled.span`
  font-weight: 300;
  float: right;
`;

export const ArrowForward = styled(IoIosArrowForward)`
  width: 30px;
  height: 30px;
  color: ${themes.default.grey70};
`;

export const Spacer = styled.div`
  height: 62px;
  width: 100%;
`;
