import React from 'react';
import girlPNG from '../../Assets/girl.png';
import handsPNG from '../../Assets/hands.png';
import useMediaQuery from '../../../utils/useMediaQuery';
import * as S from './MainSegment.styles';

const MainSegment = () => {
  const isDesktop = useMediaQuery('(min-width: 1400px)');
  return (
    <S.Wrapper>
      {isDesktop && <S.PurpleConstuct />}
      {isDesktop && <S.OrangeConstuct />}
      {isDesktop && <S.BlueYellowConstuct />}
      <S.UpperWrapper>
        {isDesktop && (
          <S.ImageContainer>
            <S.Image src={girlPNG} />
          </S.ImageContainer>
        )}
        <S.HeaderWrapper>
          <S.Header>
            A single <S.Bolded>platform for</S.Bolded>
            <br />
            all <S.Bolded> 3D printing people.</S.Bolded>
          </S.Header>
          <S.Subheader>Find a 3D printer, find a designer, accelerate your project!</S.Subheader>
        </S.HeaderWrapper>
        {isDesktop && (
          <S.SecondaryImageContainer>
            <S.Image src={handsPNG} />
          </S.SecondaryImageContainer>
        )}
      </S.UpperWrapper>
      <S.BottomWrapper></S.BottomWrapper>
    </S.Wrapper>
  );
};

export default MainSegment;
