import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import App from './App';
import { checkLoggedIn } from './api/common-api';
import './index.css';
// import SimpleAuth from './components/SimpleAuth/SimpleAuth';
// require('dotenv').config();

const rootEl = document.getElementById('root');

const renderApp = (preloadedState: any) => {
  const store = configureStore(preloadedState);
  render(
    //@ts-ignore
    <Provider store={store}>
      <React.Suspense fallback={<div />}>
        {/* <SimpleAuth> */}
        <App />
        {/* </SimpleAuth> */}
      </React.Suspense>
    </Provider>,
    rootEl
  );
};

(async () => renderApp(await checkLoggedIn()))();
