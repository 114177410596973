import React, { memo, useState } from 'react';
import { ImageProps } from '../../../models/common';
import { useSelector, useDispatch } from 'react-redux';
import { modalProduct } from '../../../redux/reducers/utils/selectors';
import { toggleModal } from '../../../redux/reducers/utils/action';
import { MODAL_NAME } from '../../../redux/reducers/utils/models';
import * as S from './PhotosViewer.style';

interface IProductsViewer {
  currentPhotoIndex?: number;
  setParentImageIndex: (index: number) => void;
}

const ProductsViewer = ({ currentPhotoIndex, setParentImageIndex }: IProductsViewer) => {
  const dispatch = useDispatch();
  const product = useSelector(modalProduct);
  const { id, images, title } = product;

  const handleClose = () => {
    dispatch(toggleModal(MODAL_NAME.PRODUCT_PHOTOS_VIEWER));
  };

  if (!product || !id || !images || images.length < 1) {
    handleClose();
  }
  const showArrows = images.length > 1;
  const startingIndex = currentPhotoIndex ? currentPhotoIndex : 0;
  const initialImage = images[startingIndex];
  const { src: initialSrc } = initialImage;
  const [photoId, setPhotoId] = useState(initialImage);

  const selectedPhoto: ImageProps =
    images.find((image: ImageProps) => image.id === photoId) || initialImage;
  const selectedPhotoSource = selectedPhoto ? selectedPhoto.src : initialSrc;
  const isFillUpRequired = images.length % 3 === 2;
  const currentIndex = images.findIndex((image: ImageProps) => image.id === photoId);

  const nextPhoto = () => {
    if (currentIndex < images.length - 1) {
      setPhotoId(images[currentIndex + 1].id);
      return;
    }
    setPhotoId(images[0].id);
  };

  const previousPhoto = () => {
    if (currentIndex === 0) {
      const length = images.length;
      setPhotoId(images[length - 1].id);
      return;
    }
    setPhotoId(images[currentIndex - 1].id);
  };

  const handleSetPhotoId = (id: string, index: number) => {
    setParentImageIndex(index);
    setPhotoId(id);
  };

  return (
    <S.ExternalWrapper>
      <S.Wrapper>
        <S.TopWrapper />
        <S.BodyWrapper>
          <S.MenuWrapper>
            <S.CloseWrapper onClick={handleClose}>
              <S.Close />
            </S.CloseWrapper>
            <S.Title>{title}</S.Title>
            <S.MiniaturesWrapper>
              {images.map(({ id, src }: ImageProps, index: number) => (
                <S.Miniature
                  key={id}
                  isSelected={photoId === id}
                  source={src}
                  onClick={() => handleSetPhotoId(id, index)}
                />
              ))}
              {isFillUpRequired && <S.MockPhoto />}
            </S.MiniaturesWrapper>
          </S.MenuWrapper>
          <S.EmptyColumn />
          <S.SelectedPhotoWrapper>
            {showArrows && (
              <S.NavigateLeft onClick={previousPhoto}>
                <S.LeftIcon />
              </S.NavigateLeft>
            )}
            <S.SelectedPhoto source={selectedPhotoSource} />
            {showArrows && (
              <S.NavigateRight>
                <S.RightIcon onClick={nextPhoto} />
              </S.NavigateRight>
            )}
          </S.SelectedPhotoWrapper>
        </S.BodyWrapper>
      </S.Wrapper>
    </S.ExternalWrapper>
  );
};

export default memo(ProductsViewer);
