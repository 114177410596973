import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as S from './OfferAddedModal.style';
import { isModalOpen, modalProductType } from '../../../redux/reducers/utils/selectors';
import { MODAL_NAME } from '../../../redux/reducers/utils/models';
import { toggleModal } from '../../../redux/reducers/utils/action';
import { PRODUCT_TYPE, isProductForPurchase } from '../../../utils/productType';

const OfferAddedModal = () => {
  const dispatch = useDispatch();
  const productType = useSelector(modalProductType);
  const isOpen = useSelector(isModalOpen(MODAL_NAME.OFFER_ADDED));
  if (isProductForPurchase[productType]) {
    return <></>;
  }
  const isPrint = productType === PRODUCT_TYPE.PRINT_REQUEST;
  const text = isPrint ? 'printing' : 'design';
  return (
    <S.Wrapper open={isOpen}>
      <S.MessageWrapper>
        <S.Close onClick={() => dispatch(toggleModal(MODAL_NAME.OFFER_ADDED))} />
        <S.MessageText>
          Your <S.Bolded>{text} offer has been created</S.Bolded>—you can
          <S.Underline to={`/dashboard/${text}-offers`}>
            manage offer in your dashboard.
          </S.Underline>
        </S.MessageText>
      </S.MessageWrapper>
    </S.Wrapper>
  );
};

export default OfferAddedModal;
