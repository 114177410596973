import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { RiErrorWarningLine } from 'react-icons/ri';
import styled from 'styled-components';

export const ExternalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  height: 100%;
`;

export const Wrapper = styled.div`
  max-height: 755px;
  max-width: 484px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 16px;
  display: flex;
  flex-direction: column;
`;

export const TopWrapper = styled.div`
  display: flex;
  padding: 23px 13px 11px 20px;
  border-bottom: 1px solid #dbdbdb;
  box-sizing: border-box;
`;

export const Title = styled.div`
  display: flex;
  font-size: 25px;
`;

export const BoldTitle = styled.div`
  font-weight: 800;
  margin-right: 5px;
  letter-spacing: -0.3px;
  color: #4D4D4D;
`;

export const FormWrapper = styled.form`
  flex-grow: 1;
  letter-spacing: 0.7px;
  padding: 34px 20px 0;
  box-sizing: border-box;
  overflow: auto;
  flex-grow: 1;
  overflow: auto;
`;

export const Label = styled.div<{ isError?: boolean }>`
  min-height: 17px;
  min-width: 1px;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 700;
  color: #4D4D4D;
  ${(props: any) =>
    props.isError
      ? `
  color: #db0000
  `
      : `
  color: #4D4D4D
  `};
`;

export const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-wrap: wrap;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 17px;
`;

export const ShortInputWrapper = styled(InputWrapper)`
  width: 47.5%;
  @media (max-width: 500px) {
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const InputFieldWrapper = styled.div<{ isError?: boolean }>`
  height: 41px;
  background: #ffffff;
  border-radius: 4px;
  ${(props: any) => (props.isError ? 'border: 1px solid #db0000;' : 'border: 1px solid #333;')};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`;

export const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 16px;
  color: #333;
  padding: 0 18px;
  border-radius: 4px;
  :focus {
    outline: none;
  }
`;

export const WarningIcon = styled(RiErrorWarningLine)`
  margin-right: 10px;
  height: 75%;
  width: auto;
  color: #db0000;
`;

export const SubLabel = styled.div`
  color: #9a9a9a;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.25px;
`;

export const LoginButton = styled.button`
  background-color: #1d77df;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  width: 100%;
  height: 48px;
  margin-top: 6px;
  font-size: 14px;
  letter-spacing: 0.6px;
  margin-bottom: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 16px;
`;

export const LoginButtonIcon = styled(LoginButton)`
  margin-bottom: 24px;
`;

export const Forgot = styled.div`
  font-size: 14px;
  text-decoration: underline;
  color: #4D4D4D;
  margin-bottom: 28px;
  cursor: pointer;
`;

export const LoginOptions = styled.div`
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  padding-top: 16px;
  padding-bottom: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ExternalLoginWrapper = styled.div`
  width: 100%;
`;

export const Or = styled.div`
  position: absolute;
  font-size: 14px;
  top: -10px;
  display: flex;
  justify-content: center;
  background-color: white;
  width: 40px;
  color: #bfbfbf;
  font-weight: 700;
`;

export const BottomWrapper = styled.div`
  display: flex;
  padding: 25px 20px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #4D4D4D;
`;

export const Join = styled.div`
  display: flex;
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer;
`;

export const errorMessage = styled.div``;

export const BoldJoin = styled.div`
  font-weight: 600;
  margin-right: 5px;
`;

export const iconLarge = {
  width: '32px',
  height: '32px',
  marginRight: '10px',
  color: '#9A9A9A',
};

export const iconSmall = {
  width: '26px',
  height: '26px',
  marginRight: '10px',
  color: '#9A9A9A',
};

export const FormContainer = styled.div`
  /* margin: 1.5rem 1rem 0 1rem; */
  padding: 21px;
  height: 34.5rem;
  border-bottom: 1px solid #dbdbdb;
`;

export const LineBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

export const FormLabel = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 0.2rem;
  letter-spacing: 0.14px;
`;

export const InputSmall = styled.input`
  height: 2.5rem;
  width: 47.5%;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  font-size: 14px;
  padding-left: 16px;
  margin-bottom: 5px;
  box-sizing: border-box;
  padding: 0 18px;
  border-radius: 4px;
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px #0077df;
  }
`;

export const Input2 = styled.input`
  height: 2.5rem;
  width: 100%;
  margin-bottom: 0.5rem;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  font-size: 14px;
  padding-left: 16px;
  margin-bottom: 15px;
  box-sizing: border-box;
  padding: 0 18px;
  border-radius: 4px;
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px #0077df;
  }
`;

export const At = styled.div`
  margin-top: 4px;
  font-size: 0.75rem;
  color: #9c9c9c;
  letter-spacing: 0.12px;
`;

export const CheckContainer = styled.div`
  color: #4D4D4D;
  display: flex;
  align-items: center;
  height: 30px;
  margin-top: 12px;
`;

export const CheckBox = styled.button<{ checkFailed: boolean; isChecked: boolean }>`
  border: none;
  height: 24px;
  width: 24px;
  padding: 0;
  background-color: ${(props: any) => (props.isChecked ? '#1D77DF' : '#fff')};
  border-radius: 4px;
  min-width: 18px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${(props: { checkFailed: boolean }) =>
    props.checkFailed
      ? 'border: 1px solid #db0000; background: white;'
      : 'border: 1px solid #9a9a9a;'}
`;

export const CheckBoxInfo = styled.div<{ checkFailed: boolean }>`
  font-size: 14px;
  ${(props: { checkFailed: boolean }) =>
    props.checkFailed ? 'color: #db0000; font-size: 13px;' : ''}
`;

export const Underline = styled.span`
  margin: 0 5px;
  text-decoration: underline;
`;

export const Or2 = styled.div`
  font-size: 8px;
  height: 0px;
  margin: 20px 0 0.875rem 0;
  border: 0.25px solid #dbdbdb;
`;

export const ContinueButton = styled.button`
  margin: 0.875rem 0;
  height: 3rem;
  width: 98%;
  background-color: #e3e3e3;
  font-size: 0.875rem;
  font-weight: 700;
  box-shadow: 0 3px 6px #00000029;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  letter-spacing: 0.14px;
  color: #4D4D4D;
`;

export const SignupButton = styled.input`
  margin: 1.3rem 0;
  height: 3rem;
  width: 98%;
  background-color: #e3e3e3;
  font-size: 0.875rem;
  font-weight: 700;
  box-shadow: 0 3px 6px #00000029;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  letter-spacing: 0.14px;
  color: #4D4D4D;
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px #0077df;
  }
`;

export const ErrorMessage = styled.div`
  color: #db0000;
  text-align: left;
  height: 20px;
  min-width: 4px;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.25px;
  display: flex;
  align-items: center;
`;

export const Icon = `
  min-width: 24px;
  height: 24px;
  color: #333333;
  cursor: pointer;
`;

export const Eye = styled(BsEye)`
  ${Icon}
`;

export const EyeBlind = styled(BsEyeSlash)`
  ${Icon}
`;

export const PlainButton = styled.button`
  background-color: transparent;
  border: none;
  :focus {
    outline: none;
  }
`;

export const Terms = styled.span`
  cursor: pointer;
`