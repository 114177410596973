import styled from 'styled-components';
import { Link } from 'react-router-dom';
import themes from '../../../Assets/colorThemes';

export const Wrapper = styled.div`
  width: 100%;
  height: 300px;
  background-color: ${themes.default.white};
  position: fixed;
  top: 70px;
  z-index: 10;
  box-shadow: 0px 3px 15px ${themes.default.transparentGray};
  @media (max-width: 1175px) {
    top: 90px;
  }
  @media (max-width: 745px) {
    top: 150px;
  }
`;

export const BodyWrapper = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1296px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  padding: 66px 16px;
`;

export const Column = styled.div`
  max-width: 170px;
  width: 100%;
  min-width: 140px;
  margin: 0 5px;
  height: 160px;
`;

export const Header = styled.h1`
  padding: 0;
  margin: 0 0 32px 0;
  font-weight: 300;
  font-size: 28px;
  letter-spacing: 0.28px;
  color: ${themes.default.black0};
`;

export const DisabledOption = styled.div`
  height: 40px;
  font-size: 16px;
  letter-spacing: 0px;
  color: ${themes.default.grey33};
  opacity: 0.3;
`;

export const Option = styled(Link)`
  font-size: 16px;
  letter-spacing: 0px;
  color: ${themes.default.grey33};
  display: block;
  height: 40px;
`;
