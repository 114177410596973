import React, { memo } from 'react';
import * as S from '../FiltersPanel.style';

interface Checkbox {
  isSelected: boolean;
  toggle: (value: boolean) => void;
  description: string;
  resultsNo?: number;
}

const CustomCheckbox = ({ isSelected, toggle, description, resultsNo }: Checkbox) => (
  <S.CheckContainer>
    <S.CheckOptionWrapper>
      <S.CheckBox isSelected={isSelected} onClick={() => toggle(!isSelected)}>
        {isSelected && <S.Check />}
      </S.CheckBox>
      <S.CheckBoxInfo>{description}</S.CheckBoxInfo>
    </S.CheckOptionWrapper>
    {resultsNo && <S.CheckboxResults>{resultsNo}</S.CheckboxResults>}
  </S.CheckContainer>
);

export default memo(CustomCheckbox);
