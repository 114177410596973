import { AnyAction } from 'redux';
import { SET_OFFERS, SET_SELECTED_OFFER, SET_OFFER_COMMENT } from './actionTypes';
import { OfferSimpleProps } from '../../../models/offer';
import { OFFER_STATUS } from '../../../models/common';

export interface OffersState {
  offers: OfferSimpleProps[];
  selectedOffer: OfferSimpleProps;
  comment: string;
}

export const emptyOffer = {
  id: '',
  message: '',
  createdAt: '',
  updatedAt: '',
  isAccepted: false,
  isRejected: false,
  isCanceled: false,
  user: {
    name: '',
    surname: '',
    avatar: '',
    city: '',
    country: '',
    id: '',
    rating: 0,
    addresses: [],
    nickname: '',
  },
  userId: '',
  responseMessage: '',
  review: null,
  status: OFFER_STATUS.WAITING_FOR_APPROVAL,
};

const initialState: OffersState = {
  offers: [],
  selectedOffer: emptyOffer,
  comment: '',
};

export default function (state = initialState, action: AnyAction): OffersState {
  switch (action.type) {
    case SET_OFFERS: {
      return {
        ...state,
        offers: action.offers,
      };
    }
    case SET_SELECTED_OFFER: {
      return {
        ...state,
        selectedOffer: action.offer,
      };
    }
    case SET_OFFER_COMMENT: {
      return {
        ...state,
        comment: action.comment,
      };
    }
    default:
      return state;
  }
}
