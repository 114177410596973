import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

export const Wrapper = styled.div`
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 14px 14px 20px 14px;
  margin-left: 4px;
  max-width: 304px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    max-width: 100%;
    margin-left: 0;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 22px;
`;

export const Title = styled.div`
  letter-spacing: 1.6px;
  color: #9a9a9a;
  font-weight: 800;
  color: #9a9a9a;
  position: relative;
  left: -3px;
`;

export const RemoveFilters = styled.button`
  font-size: 14px;
  color: #4D4D4D;
  letter-spacing: 0.14px;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

export const SelectedFiltersWrapper = styled.div`
  margin-bottom: 20px;
  @media (max-width: 800px) {
    width: 316px;
  }
`;

export const SelectedFilter = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: #eef5fd;
  margin-bottom: 8px;
  font-size: 14px;
  padding: 7px 10px;
  box-sizing: border-box;
  width: fit-content;
  line-height: 20px;
`;

export const SelectedFilterName = styled.div`
  margin-right: 4px;
`;

export const SelectedFilterContent = styled.div`
  font-weight: 700;
`;

export const Remove = styled(MdClose)`
  color: #0077df;
  padding-left: 16px;
  cursor: pointer;
  min-height: 20px;
  min-width: 20px;
`;

export const FormWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  left: -2px;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    max-width: calc(100% - 328px);
    margin-left: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  @media (max-width: 624px) {
    max-width: 100%;
  }
`;

export const Column = styled.div`
  width: 228px;
`;

export const Label = styled.label`
  letter-spacing: 0.14px;
  color: #4D4D4D;
  font-size: 14px;
  font-weight: 700;
`;

export const LineBlock = styled.div`
  display: flex;
  align-items: baseline;
  padding-top: 2px;
  font-size: 14px;
  margin-bottom: 19px;
`;

export const SmallInput = styled.input`
  width: 90px;
  height: 41px;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  padding: 12px 14px;
  box-sizing: border-box;
  font-size: 15px;
  letter-spacing: 0.14px;
  color: #000000;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px #0077df;
  }
`;

export const MediumInput = styled(SmallInput)`
  width: 104px;
`;

export const Span = styled.span`
  margin: 0 8px;
`;

export const LocationInputWrapper = styled.div`
  padding-top: 3px;
  display: flex;
  margin-bottom: 8px;
`;

export const DisplayMap = styled.div`
  text-decoration: underline;
  margin: 0 0 14px 196px;
  font-size: 14px;
  color: #4D4D4D;
  letter-spacing: 0.14px;
`;

export const CheckContainer = styled.div`
  color: #4D4D4D;
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 2px;
  justify-content: space-between;
`;

export const CheckOptionWrapper = styled.div`
  display: flex;
`;

export const CheckBox = styled.button<{ isSelected: boolean }>`
  border: 1px solid #9a9a9a;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${(props: { isSelected: boolean }) =>
    props.isSelected ? '#e2e2e2' : 'transparent'};
`;

export const CheckBoxInfo = styled.div`
  font-size: 14px;
`;

export const Check = styled.div`
  background-color: #9a9a9a;
  border-radius: 100%;
  width: 10px;
  height: 10px;
`;

export const CheckboxResults = styled.div`
  color: #9a9a9a;
  font-size: 14px;
`;

export const SizeWrapper = styled.div`
  padding-top: 5px;
  margin-bottom: 4px;
`;

export const Separator = styled.hr`
  width: 272px;
  margin: 0 auto 6px auto;
  border: 1px solid #e2e2e2;
  border-bottom: 0px;
  padding: 0;
  @media (max-width: 1286px) {
    width: 98%;
  }
`;

export const MoreFilters = styled.div`
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
  letter-spacing: 0.14px;
  color: #4D4D4D;
  font-weight: 500;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
`;

export const SubmitButton = styled.button`
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #bfbfbf;
  margin: 10px auto;
  cursor: pointer;
`;

export const MobileRow = styled.div`
  @media (max-width: 1286px) {
    display: flex;
    flex-wrap: wrap;
  }
  @media (max-width: 624px) {
    display: block;
  }
`;
