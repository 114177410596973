import React, { memo, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { MdClose } from 'react-icons/md';
import { register } from '../../../redux/reducers/auth/actions';
import ExternalLogin from '../Login/ExternalLogin';
import { useSignUpError } from '../../../redux/reducers/auth/selectors';
import * as S from './SignUp.style';
import { checkMessageError } from '../../../utils/checkMessageError';
import { toggleModal } from '../../../redux/reducers/utils/action';
import { MODAL_NAME } from '../../../redux/reducers/utils/models';
// import { history } from '../../../App';

const SignUp = () => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAgreementAccepted, toggleAgreementAccepted] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [didTermsFail, setTermsFail] = useState(false);
  const dispatch = useDispatch();
  const { isError, message } = useSelector(useSignUpError);
  const isEmailInvalid = checkMessageError('email', message) || checkMessageError('Email', message);
  const isPasswordInvalid = checkMessageError('Password', message);
  const isPasswordError = isError && isPasswordInvalid;
  const isEmailError = isError && isEmailInvalid;
  const isNameError = isError && !name && !isPasswordError && !isEmailError;
  const handleAgreementClick = (isAgreementAccepted: boolean) => {
    setTermsFail(false);
    toggleAgreementAccepted(!isAgreementAccepted);
  };

  const handleRegister = () => {
    if (!isAgreementAccepted) {
      setTermsFail(true);
      return;
    }
    dispatch(register({ name, surname, email, password }));
  };

  const clearErrorState = () => {
    if (isError) {
      dispatch({ type: 'REGISTER_IN_USE_ERROR', value: false });
    }
  };

  const handleKeyPress = (e: any, isCheckbox: boolean) => {
    if (isCheckbox) {
      return handleAgreementClick(isAgreementAccepted);
    }
    const pressedEnter = e.keyCode === 13;
    if (pressedEnter) {
      handleRegister();
    }
  };

  const handleDisplayLogin = () => {
    dispatch(toggleModal(MODAL_NAME.LOGIN));
  };

  const handleRedirectToPrivacy = () => {
    dispatch(toggleModal(MODAL_NAME.SIGNUP));

    // TODO
    // history('/help-center/legal/privacy');
  };
  const handleRedirectToTerms = () => {
    dispatch(toggleModal(MODAL_NAME.SIGNUP));
    // TODO
    // history('/help-center/legal/tos');
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  return (
    <S.ExternalWrapper>
      <S.Wrapper>
        <S.TopWrapper>
          <S.Title>
            <S.BoldTitle>Sign up</S.BoldTitle>to i-3D.io
          </S.Title>
          <MdClose
            style={{ color: '#333333', marginLeft: 'auto', cursor: 'pointer' }}
            size={28}
            onClick={() => {
              dispatch(toggleModal(MODAL_NAME.SIGNUP));
              clearErrorState();
            }}
          />
        </S.TopWrapper>
        <S.FormWrapper autoComplete="on" onSubmit={(e) => handleSubmit(e)}>
          <S.InputRow>
            <S.ShortInputWrapper>
              <S.Label isError={isNameError}>Name</S.Label>
              <S.InputFieldWrapper isError={isNameError}>
                <S.Input
                  placeholder="First name"
                  name="name"
                  onChange={(e: any) => [setName(e.target.value), clearErrorState()]}
                  value={name}
                  required
                  onKeyDown={(e: any) => handleKeyPress(e, false)}
                />
                {isNameError && <S.WarningIcon />}
              </S.InputFieldWrapper>
              {isNameError && <S.ErrorMessage>{message}</S.ErrorMessage>}
            </S.ShortInputWrapper>
            <S.ShortInputWrapper>
              <S.Label />
              <S.InputFieldWrapper>
                <S.Input
                  placeholder="Surname (optional)"
                  name="surname"
                  onChange={(e: any) => [setSurname(e.target.value), clearErrorState()]}
                  value={surname}
                  required
                  onKeyDown={(e: any) => handleKeyPress(e, false)}
                />
              </S.InputFieldWrapper>
            </S.ShortInputWrapper>
          </S.InputRow>
          <S.InputWrapper>
            <S.Label isError={isEmailError}>Email address</S.Label>
            <S.InputFieldWrapper isError={isEmailError}>
              <S.Input
                type="email"
                name="email"
                placeholder="you@company.com"
                onChange={(e: any) => [setEmail(e.target.value), clearErrorState()]}
                value={email}
                required
                onKeyDown={(e: any) => handleKeyPress(e, false)}
              />
              {isEmailError && <S.WarningIcon />}
            </S.InputFieldWrapper>
            {isEmailError && <S.ErrorMessage>{message}</S.ErrorMessage>}
          </S.InputWrapper>
          <S.InputWrapper>
            <S.Label isError={isPasswordError}>Password</S.Label>
            <S.InputFieldWrapper isError={isPasswordError}>
              <S.Input
                type={isPasswordVisible ? 'text' : 'password'}
                name="password"
                onChange={(e: any) => [setPassword(e.target.value), clearErrorState()]}
                value={password}
                required
                onKeyDown={(e: any) => handleKeyPress(e, false)}
              />
              <S.PlainButton type="button" onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                {isPasswordVisible ? <S.EyeBlind /> : <S.Eye />}
              </S.PlainButton>
              {isPasswordError && <S.WarningIcon />}
            </S.InputFieldWrapper>
            <S.SubLabel>At least 12 signs</S.SubLabel>
            {isPasswordError && <S.ErrorMessage>{message}</S.ErrorMessage>}
            <S.CheckContainer>
              <S.CheckBox
                type="button"
                onClick={() => handleAgreementClick(isAgreementAccepted)}
                checkFailed={didTermsFail}
                isChecked={isAgreementAccepted}
              >
                {isAgreementAccepted && <FaCheck style={{ color: '#fff', width: '15px' }} />}
              </S.CheckBox>
              <S.CheckBoxInfo checkFailed={didTermsFail}>
                I agree i-3D.io
                <S.Terms onClick={handleRedirectToTerms}>
                  <u>Terms of service</u>
                </S.Terms>
                and
                <S.Terms onClick={handleRedirectToPrivacy}>
                  <u>Privacy notice</u>
                </S.Terms>
                .
                <br />
                {didTermsFail && 'This field is required.'}
              </S.CheckBoxInfo>
            </S.CheckContainer>
          </S.InputWrapper>
          <S.LoginButton type={'submit'} onClick={() => handleRegister()}>
            Sign up with email
          </S.LoginButton>
          <S.LoginOptions>
            <S.Or>or</S.Or>
            <S.ExternalLoginWrapper>
              <ExternalLogin action={'sign-up'} />
            </S.ExternalLoginWrapper>
          </S.LoginOptions>
        </S.FormWrapper>
        <S.BottomWrapper>
          Already have an account?
          <S.Join onClick={handleDisplayLogin}>
            <S.BoldJoin>Log in</S.BoldJoin>here.
          </S.Join>
        </S.BottomWrapper>
      </S.Wrapper>
    </S.ExternalWrapper>
  );
};

export default memo(SignUp);
