import styled from 'styled-components';
import emptyStatesSVGs from '../../../Assets/EmptyStates/emptyStates';

export const EmptyWorkshopContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;

export const EmptyWorkshopConstructor = styled.img.attrs({
  src: emptyStatesSVGs.photosMiddleConstruct,
})`
  position: absolute;
  z-index: -1;
  right: 43%;
  top: 15%;
  transform: rotate(-7deg);
`;

export const EmptyWorkshopPanel = styled.div`
  display: flex;
  width: 50%;
  margin-bottom: 20px;
`;

export const EmptyWorkshopBox = styled.div`
  margin-right: 20px;
  width: 88px;
  height: 88px;
  border: 1px solid #e1e2e1;
  box-sizing: border-box;
`;

export const EmptyWorkshopText = styled.div``;

export const EmptyTextBar = styled.div<{ isShort: boolean }>`
  height: 18px;
  width: ${(props: any) => (props.isShort ? '77px' : '180px')};
  background-color: #f2f2f2;
  border-radius: 3px;
  margin: 8px 0;
`;
