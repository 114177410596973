import React, { memo } from 'react';
import { FaCheck } from 'react-icons/fa';
import { UserAddress } from '../../../models/user';
// import { FaTimes } from 'react-icons/fa';
// import { useDispatch } from 'react-redux';
// import { archiveAddress } from '../../../redux/actions';
import * as S from './DeliveryAddressSelector.style';

interface AddressOptionProps {
  isActive?: boolean;
  onClick: () => void;
  address: UserAddress;
}

const AddressOption = ({
  isActive = false,
  onClick,
  address: {
    // id,
    name,
    surname,
    address,
    city,
    country,
    region,
    zipCode,
    isDefault,
  },
}: AddressOptionProps) => {
  // const dispatch = useDispatch();
  // const handleDelete = () => {
  //   dispatch(archiveAddress(id));
  // };

  return (
    <S.AddressOptionWrapper isActive={isActive} onClick={onClick}>
      <S.AddressOptionRadioWrapper>
        <S.AddressOptionRadio>
          <S.RadioButton isActive={isActive}>
            {isActive && <FaCheck style={{ color: '#fff' }} />}
          </S.RadioButton>
        </S.AddressOptionRadio>
      </S.AddressOptionRadioWrapper>
      <S.AddressDetailsWrapper>
        <S.AddressDetails>
          <S.AddressAddressName>
            {name} {surname}
          </S.AddressAddressName>
          <S.AddressAddressLine>{address}</S.AddressAddressLine>
          <S.AddressAddressLine>
            {zipCode}
            {city.length > 1 && `, ${city}`}
            {region.length > 1 && `, ${region}`}
            {country.length > 1 && `, ${country}`}
          </S.AddressAddressLine>
        </S.AddressDetails>
        {isDefault && <S.AddressAddressDefault>Default delivery address</S.AddressAddressDefault>}
      </S.AddressDetailsWrapper>
      {/* <S.AddressOptionRadioWrapper>
        <S.AddressOptionRadio>
          <S.DeleteButton>
            <FaTimes
              style={{ color: '#4f4f4f', cursor: 'pointer', zIndex: 2 }}
              onClick={handleDelete}
            />
          </S.DeleteButton>
        </S.AddressOptionRadio>
      </S.AddressOptionRadioWrapper> */}
    </S.AddressOptionWrapper>
  );
};

export default memo(AddressOption);
