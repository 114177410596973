import { AppState } from '..';
import { EmptyObjectForSell } from './models';

export const purchaseState = (state: AppState) => state.objectForSell;

export const userState = (state: AppState) => state.user;

export const isLoadingUserObjectsForSell = (state: AppState) =>
  purchaseState(state).isLoadingUserObjectsForSell;

export const isLoadingFeaturedObjectsForSell = (state: AppState) =>
  purchaseState(state).isLoadingFeaturedObjectsForSell;

export const isLoadingViewedObjectForSell = (state: AppState) =>
  purchaseState(state).isLoadingViewedObjectForSell;

export const usersObjectsForSell = (state: AppState) => purchaseState(state).userObjectsForSell;

export const featuredObjectsForSell = (state: AppState) =>
  purchaseState(state).featuredObjectsForSell;

export const viewedObjectForSell = (state: AppState) =>
  purchaseState(state).viewedObjectForSell || EmptyObjectForSell;

export const isSubmitting = (state: AppState) => purchaseState(state).isSubmitting;

export const errorMessage = (state: AppState) => purchaseState(state).errorMessage;

export const dashboardObjects = (state: AppState) => userState(state).objectsForSell;
