import React from 'react';
import * as S from './EmptyWorkshop.style';

export default () => {
  return (
    <S.EmptyWorkshopContainer>
      <S.EmptyWorkshopConstructor />
      <S.EmptyWorkshopPanel>
        <S.EmptyWorkshopBox />
        <S.EmptyWorkshopText>
          <S.EmptyTextBar isShort={false} />
          <S.EmptyTextBar isShort={true} />
        </S.EmptyWorkshopText>
      </S.EmptyWorkshopPanel>
      <S.EmptyWorkshopPanel>
        <S.EmptyWorkshopBox />
        <S.EmptyWorkshopText>
          <S.EmptyTextBar isShort={false} />
          <S.EmptyTextBar isShort={true} />
        </S.EmptyWorkshopText>
      </S.EmptyWorkshopPanel>
      <S.EmptyWorkshopPanel>
        <S.EmptyWorkshopBox />
        <S.EmptyWorkshopText>
          <S.EmptyTextBar isShort={false} />
          <S.EmptyTextBar isShort={true} />
        </S.EmptyWorkshopText>
      </S.EmptyWorkshopPanel>
      <S.EmptyWorkshopPanel>
        <S.EmptyWorkshopBox />
        <S.EmptyWorkshopText>
          <S.EmptyTextBar isShort={false} />
          <S.EmptyTextBar isShort={true} />
        </S.EmptyWorkshopText>
      </S.EmptyWorkshopPanel>
    </S.EmptyWorkshopContainer>
  );
};
