import React from 'react';
import { Notification } from '../../../../utils/notificationTypes';
import moment from 'moment';
import * as S from './NotificationsMenu.styles';

export default ({
  notification: {
    isRead,
    createdAt,
    body: { message },
  },
  markAsRead,
}: {
  notification: Notification;
  markAsRead: () => void;
}) => {
  return (
    <S.NotificationWrapper onClick={markAsRead}>
      <S.DataWrapper>
        <S.Header>{message}</S.Header>
        <S.NotificationTime>
          <S.TimeValue>{moment(createdAt).fromNow()}</S.TimeValue>
        </S.NotificationTime>
      </S.DataWrapper>
      <S.ReadIcon isRead={isRead} />
    </S.NotificationWrapper>
  );
};
