import React, { memo } from 'react';
import * as S from './FiltersPanel.style';

export enum FILTERS_TYPE {
  BUDGET = 'Budget',
  DEADLINE = 'Deadline',
  LOCATION = 'Location',
  SIZE = 'Size',
  RESOLUTION = 'Resolution',
}

export interface Filter {
  type: FILTERS_TYPE;
  values: (string | number)[];
  min?: number;
  max?: number;
  place?: string;
  distance?: string;
}

export enum EXTREMES {
  MIN = 'Min',
  MAX = 'Max',
}

interface ChipInterface extends Filter {
  remove: () => void;
}

const globalCurrency = '$';
const globalMeasurementUnit = 'mm';

const formatFilterTypeOutput = ({ type, values, min, max, place, distance }: Filter) => {
  switch (type) {
    case FILTERS_TYPE.BUDGET: {
      if (min && max) {
        return `${globalCurrency}${min} - ${max}`;
        // return `${min} - ${max}€`
      } else if (min && !max) {
        return `${globalCurrency}${min} (min)`;
        // return `${min}€ (min)`
      } else if (!min && max) {
        return `${globalCurrency}${max} (max)`;
        // return `${max}€ (max)`
      }
      break;
    }
    case FILTERS_TYPE.RESOLUTION:
      return `${values.join()}${globalMeasurementUnit}`;
    case FILTERS_TYPE.DEADLINE:
      return `${values[0]} day(s)`;
    case FILTERS_TYPE.LOCATION:
      if (place) {
        if (distance) {
          return `${place} +${distance}`;
        }
        return `${place}`;
      }
      break;
    default:
      return values.join(', ');
  }
};

const Chip = ({
  type,
  values,
  min = 0,
  max = 0,
  place = '',
  distance = '',
  remove,
}: ChipInterface) => (
  <S.SelectedFilter>
    <S.SelectedFilterName>{type}:</S.SelectedFilterName>
    <S.SelectedFilterContent>
      {formatFilterTypeOutput({ type, values, min, max, place, distance })}
    </S.SelectedFilterContent>
    <S.Remove onClick={() => remove()} />
  </S.SelectedFilter>
);

export default memo(Chip);
