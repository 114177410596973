import React, { memo, useEffect, useState } from 'react';
import Result from './Result/Result';
import Selector, {
  SELECTOR_TYPE,
  SELECTOR_BORDER_RADIUS_OPTIONS,
} from '../../CreateProduct/Inputs/Selector';
import { Option } from '../../../models/common';
import CircularIndeterminateLoader from '../../Common/Loader/CircularIndeterminateLoader';
import { PRODUCT_TYPE } from '../../../utils/productType';
import * as S from './Results.style';
import { PrintRequest } from '../../../redux/reducers/printRequest/models';

export interface PaginationOptions {
  isFetchingPrintRequests: boolean;
  options: Option[];
  perPageOptions: Option[];
  itemsPerPage: Option;
  sortBy: Option;
  page: number;
  rows: any[];
  count: number;
  setPage: (value: number) => void;
  setSortBy: (value: Option) => void;
  setPerPage: (value: Option) => void;
  setRefresh: (state: boolean) => void;
}

const Results = ({
  productType,
  pagination: {
    options,
    perPageOptions,
    itemsPerPage,
    page,
    sortBy,
    rows,
    count,
    isFetchingPrintRequests,
    setSortBy,
    setPage,
    setPerPage,
    setRefresh,
  },
}: {
  productType: PRODUCT_TYPE;
  pagination: PaginationOptions;
}) => {
  const handleSetPage = (value: number) => {
    if (value <= pagesNo && value > 0) {
      setPage(value - 1);
    }
  };

  const incrementPage = () => {
    if (pagesNo - 1 >= page + 1) {
      setPage(page + 1);
    }
  };

  const decrementPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handlePerPageChange = (option: Option) => {
    setPerPage(option);
    setPage(0);
  };

  const [pagesNo, setPagesNo] = useState(1);
  useEffect(() => {
    const countedPagesNo = Math.round(count / +itemsPerPage.value);
    setPagesNo(countedPagesNo > 0 ? countedPagesNo : 1);
  }, [count, itemsPerPage.value]);
  return (
    <S.Wrapper>
      <S.TopPaginationWrapper>
        <S.Row>
          <S.Label>Sort by</S.Label>
          <S.SelectSortBy>
            <Selector
              placeholder={sortBy.label}
              options={options}
              selected={sortBy.label}
              handleOptionSelect={setSortBy}
              selectorAlignment={SELECTOR_BORDER_RADIUS_OPTIONS.CENTER}
              type={SELECTOR_TYPE.MODERATE}
            />
          </S.SelectSortBy>
        </S.Row>
        <S.PaginationWrapper>
          <S.ArrowLeft onClick={decrementPage} />
          <S.PageInput
            type="number"
            step={1}
            min={0}
            value={page + 1}
            onChange={(e: any) => handleSetPage(e.target.value)}
          />
          <S.PerPage>/ {pagesNo}</S.PerPage>
          <S.ArrowRight onClick={incrementPage} />
        </S.PaginationWrapper>
      </S.TopPaginationWrapper>
      <S.ResultsWrapper>
        {isFetchingPrintRequests ? (
          <CircularIndeterminateLoader />
        ) : (
          rows.map((pr: PrintRequest) => (
            <Result key={pr.id} productType={productType} request={pr} />
          ))
        )}
      </S.ResultsWrapper>
      <S.BottomPaginationWrapper>
        <S.Row>
          <S.Label>Items per page</S.Label>
          <S.SelectPerPage>
            <Selector
              placeholder={itemsPerPage.label}
              options={perPageOptions}
              selected={itemsPerPage.label}
              handleOptionSelect={(option: Option) => handlePerPageChange(option)}
              selectorAlignment={SELECTOR_BORDER_RADIUS_OPTIONS.CENTER}
              type={SELECTOR_TYPE.TINY}
            />
          </S.SelectPerPage>
        </S.Row>
        <S.PaginationWrapper>
          <S.ArrowLeft onClick={decrementPage} />
          <S.ArrowLabel onClick={decrementPage}>Previous</S.ArrowLabel>
          <S.PageInput
            type="number"
            step={1}
            min={0}
            value={page + 1}
            onChange={(e: any) => handleSetPage(e.target.value)}
          />
          <S.PerPage>/ {pagesNo}</S.PerPage>
          <S.ArrowLabel onClick={incrementPage}>Next</S.ArrowLabel>
          <S.ArrowRight onClick={incrementPage} />
        </S.PaginationWrapper>
      </S.BottomPaginationWrapper>
    </S.Wrapper>
  );
};

export default memo(Results);
