import { AnyAction } from 'redux';
import {
  USER_LOGGED_IN,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_SUCCESS,
  UPDATE_USER_DATA_SUCCESS,
  CREATE_USER_DELIVERY_ADDRESS_SUCCESS,
  SET_SELECTED_DELIVERY_ADDRESS,
  REGISTER_IN_USE_ERROR,
  LOGIN_ERROR,
  SET_SELECTED_DELIVERY_TYPES,
  UPDATE_USER_GENERAL_PREFERENCES,
  SET_DELIVERY_OPTIONS_ERROR,
  SET_LOADING_UPDATE_DELIVERY_OPTIONS,
  SET_IS_CHANGING_USER_DATA,
  SET_DELIVERY_OPTIONS,
  SET_MAP_VISIBILITY,
  SET_USER_ADDRESSES,
  GET_CURRENCY_SUCCESS,
  SIMPLE_AUTH_SUCCESS,
  GET_USER_NOTIFICATIONS,
  GET_USER_NOTIFICATIONS_SUCCESS,
  GET_USER_NOTIFICATIONS_FAIL,
  SET_USER_DATA_LOADING_STATE,
  UPLOAD_USER_AVATAR_SUCCESS,
  SET_IS_ERROR,
  ADD_USER_PRINTER_ERROR,
  SET_IS_ADDING_NEW_ADDRESS,
  CHANGE_USER_EMAIL,
  CHANGE_USER_EMAIL_SUCCESS,
  CHANGE_USER_EMAIL_FAILURE,
  CHANGE_USER_PASSWORD,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_FAILURE,
} from './actionTypes';
import { User, UserAddress } from '../../../models/user';

export interface AuthorizationError {
  isError: boolean;
  message: string;
}

interface ExtendedUser extends User {
  currencyRates: any[];
}

export interface AuthState {
  isLoggedIn: boolean;
  user: ExtendedUser;
  registerError: AuthorizationError;
  loginError: AuthorizationError;
  isUpdatingDeliveryOptions: boolean;
  deliveryOptionErrorMessage: string;
  isChangingUserData: boolean;
  simpleAuthorisationCompleted: boolean;
  isLoadingData: boolean;
  isError: boolean;
  isLoadingNotifications: boolean;
  postError: AuthorizationError;
  isAddingNewAddress: boolean;
  isChangingEmail: boolean;
  isChangingPassword: boolean;
}

export const emptyUser: ExtendedUser = {
  id: '',
  avatar: '',
  name: '',
  surname: '',
  nickname: '',
  ratingCount: 0,
  about: '',
  rating: 0,
  addresses: [],
  isAdmin: false,
  email: '',
  phone: '',
  deliveryOptions: [],
  selectedDeliveryTypes: [],
  language: {},
  timeZone: '',
  currency: { currency: '' },
  isMapDisplayed: true,
  notifications: [],
  iban: '',
  swift: '',
  paymentAddress: '',
  paymentCountry: '',
  paymentCity: '',
  paymentZipCode: '',
  paymentRegion: '',
  accountOwner: '',
  currencyRates: [],
  role: '',
};

export const initialErrorState = {
  isError: false,
  message: '',
};

const initialState: AuthState = {
  isLoggedIn: false,
  user: emptyUser,
  registerError: initialErrorState,
  loginError: initialErrorState,
  isUpdatingDeliveryOptions: false,
  deliveryOptionErrorMessage: '',
  isChangingUserData: false,
  simpleAuthorisationCompleted: false,
  isLoadingData: false,
  isError: false,
  isLoadingNotifications: false,
  postError: initialErrorState,
  isAddingNewAddress: false,
  isChangingEmail: false,
  isChangingPassword: false,
};

export default function (state = initialState, action: AnyAction): AuthState {
  switch (action.type) {
    case SET_USER_DATA_LOADING_STATE: {
      return {
        ...state,
        isLoadingData: action.isLoading,
      };
    }
    case GET_USER_NOTIFICATIONS: {
      return {
        ...state,
        isLoadingNotifications: true,
      };
    }
    case GET_USER_NOTIFICATIONS_SUCCESS: {
      const { notifications } = action;
      return {
        ...state,
        user: { ...state.user, notifications },
        isLoadingNotifications: false,
      };
    }
    case GET_USER_NOTIFICATIONS_FAIL: {
      return {
        ...state,
        isLoadingNotifications: false,
      };
    }
    case GET_CURRENCY_SUCCESS: {
      return {
        ...state,
        user: { ...emptyUser, ...state.user, currencyRates: action.data },
      };
    }
    case SET_DELIVERY_OPTIONS_ERROR: {
      return {
        ...state,
        deliveryOptionErrorMessage: action.errorMessage,
        user: { ...emptyUser, ...state.user },
      };
    }
    case SET_MAP_VISIBILITY: {
      return {
        ...state,
        user: { ...emptyUser, ...state.user, isMapDisplayed: action.isDisplayed },
      };
    }
    case SET_LOADING_UPDATE_DELIVERY_OPTIONS: {
      return {
        ...state,
        user: { ...emptyUser, ...state.user },
        isUpdatingDeliveryOptions: action.isLoading,
      };
    }
    case SET_IS_CHANGING_USER_DATA: {
      return {
        ...state,
        user: { ...emptyUser, ...state.user },
        isChangingUserData: action.isChanging,
      };
    }
    case USER_LOGGED_IN: {
      return {
        ...state,
        user: { ...emptyUser, ...state.user },
        isLoggedIn: action.value,
      };
    }
    case REGISTER_USER_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true,
        user: { ...emptyUser, ...emptyUser, ...action.data.user },
      };
    }
    case LOGIN_USER_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true,
        user: { ...emptyUser, ...action.data.user },
      };
    }
    case UPDATE_USER_DATA_SUCCESS: {
      return {
        ...state,
        user: { ...emptyUser, ...action.data.user },
      };
    }
    case UPDATE_USER_GENERAL_PREFERENCES: {
      return {
        ...state,
        user: { ...emptyUser, ...action.data.user },
      };
    }
    case LOGOUT_USER_SUCCESS: {
      return initialState;
    }
    case CREATE_USER_DELIVERY_ADDRESS_SUCCESS: {
      return {
        ...state,
        user: {
          ...emptyUser,
          ...state.user,
          addresses: action.data,
        },
      };
    }
    case SET_SELECTED_DELIVERY_ADDRESS: {
      const selectedId = action.id;
      let addresses = [...state.user.addresses];
      addresses = addresses.map((address: UserAddress) => {
        address.isSelected = address.id === selectedId;
        return address;
      });
      return {
        ...state,
        user: {
          ...emptyUser,
          ...state.user,
          addresses,
        },
      };
    }
    case SET_SELECTED_DELIVERY_TYPES: {
      return {
        ...state,
        user: {
          ...emptyUser,
          ...state.user,
          selectedDeliveryTypes: action.data,
        },
      };
    }
    case SET_DELIVERY_OPTIONS: {
      return {
        ...state,
        user: {
          ...emptyUser,
          ...state.user,
          deliveryOptions: action.data,
        },
        isUpdatingDeliveryOptions: false,
      };
    }
    case SET_USER_ADDRESSES: {
      return {
        ...state,
        user: {
          ...emptyUser,
          ...state.user,
          addresses: action.data,
        },
      };
    }
    case REGISTER_IN_USE_ERROR: {
      return {
        ...state,
        user: { ...emptyUser, ...state.user },
        registerError: {
          isError: action.value,
          message: action.data,
        },
      };
    }
    case LOGIN_ERROR: {
      return {
        ...state,
        user: { ...emptyUser, ...state.user },
        loginError: {
          isError: action.value,
          message: action.data,
        },
      };
    }
    case ADD_USER_PRINTER_ERROR: {
      return {
        ...state,
        postError: {
          isError: action.value,
          message: action.data,
        },
      };
    }
    case SIMPLE_AUTH_SUCCESS: {
      return {
        ...state,
        simpleAuthorisationCompleted: true,
      };
    }
    case UPLOAD_USER_AVATAR_SUCCESS: {
      return {
        ...state,
        user: { ...emptyUser, ...state.user, avatar: action.data },
      };
    }
    case SET_IS_ERROR: {
      return {
        ...state,
        user: { ...emptyUser, ...state.user },
        isError: action.data,
      };
    }
    case SET_IS_ADDING_NEW_ADDRESS: {
      const { isAddingNewAddress } = action;
      return {
        ...state,
        isAddingNewAddress,
      };
    }
    case CHANGE_USER_EMAIL: {
      return {
        ...state,
        isChangingEmail: true,
      };
    }
    case CHANGE_USER_EMAIL_SUCCESS: {
      return {
        ...state,
        isChangingEmail: false,
      };
    }
    case CHANGE_USER_EMAIL_FAILURE: {
      return {
        ...state,
        isChangingEmail: false,
      };
    }
    case CHANGE_USER_PASSWORD: {
      return {
        ...state,
        isChangingPassword: true,
      };
    }
    case CHANGE_USER_PASSWORD_SUCCESS: {
      return {
        ...state,
        isChangingPassword: false,
      };
    }
    case CHANGE_USER_PASSWORD_FAILURE: {
      return {
        ...state,
        isChangingPassword: false,
      };
    }
    default:
      return state;
  }
}
