import React, { memo } from 'react';
import { PRODUCT_STATUS } from '../../../../models/common';
import {
  BuyOrOfferButtonText,
  productDetailsPath,
  PRODUCT_TYPE,
} from '../../../../utils/productType';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLoggedInUser } from '../../../../redux/reducers/auth/selectors';
import { toggleModal } from '../../../../redux/reducers/utils/action';
import { MODAL_NAME } from '../../../../redux/reducers/utils/models';
import { getIsFinalized, getIsSoldOut } from '../../../../utils/status';
import * as S from '../ActionButton.style';

interface IPurchasableProductDetailsBuyerActions {
  id: string;
  productStatus: PRODUCT_STATUS;
  productType: PRODUCT_TYPE;
  quantity?: number;
  bought?: boolean;
}

const PurchasableProductDetailsBuyerActions = ({
  id,
  productStatus,
  productType,
  quantity,
  bought = false,
}: IPurchasableProductDetailsBuyerActions) => {
   const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: userId } = useSelector(useLoggedInUser);
  const isQuantityCorrect = quantity ? quantity > 0 : false;
  const isFinalized = getIsFinalized(productStatus);
  const isSoldOut = getIsSoldOut(productStatus);
  const purchasePath = `${productDetailsPath[productType]}/${id}/purchase`;

  const handleProductAction = () => {
    if (!userId) {
      dispatch(toggleModal(MODAL_NAME.LOGIN));
      return;
    }
    if (isFinalized) {
      return;
    }
    return navigate(purchasePath);
  };

  if (isSoldOut) {
    return (
      <S.Wrapper>
        <S.OfferPrintingButton disabled={true}>Sold Out</S.OfferPrintingButton>
      </S.Wrapper>
    );
  }
  if (quantity) {
    return (
      <S.Wrapper>
        <S.OfferPrintingButton disabled={!isQuantityCorrect} onClick={handleProductAction}>
          {BuyOrOfferButtonText[productType]}
        </S.OfferPrintingButton>
      </S.Wrapper>
    );
  }
  return (
    <S.OfferPrintingButton disabled={bought} onClick={handleProductAction}>
      {isFinalized ? 'Printing finalized' : BuyOrOfferButtonText[productType]}
    </S.OfferPrintingButton>
  );
};

export default memo(PurchasableProductDetailsBuyerActions);
