import styled from 'styled-components';
import U from '../../../Assets/commonCSS';
import themes from '../../../Assets/colorThemes';

export const CreateMenuWrapper = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  position: absolute;
  flex-direction: column;
  top: 100%;
  height: auto;
  background: ${themes.default.white} 0% 0% no-repeat padding-box;
  border: 1px solid ${themes.default.grayBf};
  border-radius: 4px;
  opacity: 1;
  white-space: nowrap;
  width: auto;
  padding: 12px 0;
  letter-spacing: 1px;
  z-index: ${U.zIndex2};
  background-color: ${themes.default.white};
`;

export const Title = styled.span`
  text-transform: uppercase;
  font-size: 0.625rem;
  letter-spacing: 0.1rem;
  font-weight: 600;
  color: ${themes.default.gray4a};
  margin: 0;
  padding: 0 24px 8px;
`;

export const MenuOption = styled.button`
  border: none;
  background-color: transparent;
  text-align: left;
  width: 150px;
  box-sizing: border-box;
  height: 42px;
  margin: 2px 0;
  font-size: 14px;
  padding: 12px 0 12px 24px;
  letter-spacing: 0;
  font-weight: 500;
  cursor: pointer;
  :hover {
    background-color: ${themes.default.grayEf};
  }
`;
