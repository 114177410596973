import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as S from '../CreatedProductModal/CreatedProductModal.style';
import { isModalOpen } from '../../../redux/reducers/utils/selectors';
import { MODAL_NAME } from '../../../redux/reducers/utils/models';
import { toggleModal } from '../../../redux/reducers/utils/action';

const SuccessfulEmailSent = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(isModalOpen(MODAL_NAME.EMAIL_SENT));
  return (
    <S.Wrapper open={isOpen}>
      <S.MessageWrapper
        width="316px"
        onClick={() => {
          dispatch(toggleModal(MODAL_NAME.EMAIL_SENT));
        }}
      >
        <S.MessageText>Your e-mail has been sent.</S.MessageText>
      </S.MessageWrapper>
    </S.Wrapper>
  );
};

export default SuccessfulEmailSent;
