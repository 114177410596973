import React from 'react';
import Modal from '@material-ui/core/Modal';
import DeliveryAddressSelector from '../Common/DeliveryAddressSelector/DeliveryAddressSelector';
import { useSelector, useDispatch } from 'react-redux';
import { isModalOpen } from '../../redux/reducers/utils/selectors';
import { MODAL_NAME } from '../../redux/reducers/utils/models';
import { clearAddressModals } from '../../redux/reducers/utils/action';
import U from '../Assets/commonCSS';
import * as S from '../Modals/ModalWrapper.styles';

const DeliveryAddressModal = () => {
  const dispatch = useDispatch();
  const isDefaultAddressOpen = useSelector(isModalOpen(MODAL_NAME.DEFAULT_ADDRESS));
  const isDeliveryAddressOpen = useSelector(isModalOpen(MODAL_NAME.DELIVERY_ADDRESS));
  if (!isDefaultAddressOpen && !isDeliveryAddressOpen) {
    return null;
  }
  const handleClose = () => {
    dispatch(clearAddressModals());
  };

  return (
    <Modal
      style={U.modalStyleProps()}
      open={isDefaultAddressOpen || isDeliveryAddressOpen}
      onBackdropClick={handleClose}
    >
      <S.Wrapper>
        <DeliveryAddressSelector isDefault={isDefaultAddressOpen} />
      </S.Wrapper>
    </Modal>
  );
};

export default DeliveryAddressModal;
