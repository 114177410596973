import { AppState } from '..';
import { EmptyPrintRequest } from './models';

export const printRequestState = (state: AppState) => state.printRequest;

export const isLoadingUserPrintRequests = (state: AppState) =>
  printRequestState(state).isLoadingUserPrintRequests;

export const isLoadingFeaturedPrintRequests = (state: AppState) =>
  printRequestState(state).isLoadingFeaturedPrintRequests;

export const isLoadingViewedPrintRequest = (state: AppState) =>
  printRequestState(state).isLoadingViewedPrintRequest;

export const isLoadingSearchedPrintRequests = (state: AppState) =>
  printRequestState(state).isLoadingSearchedPrintRequests;

export const usersPrintRequests = (state: AppState) => printRequestState(state).userPrintRequests;

export const featuredPrintRequests = (state: AppState) =>
  printRequestState(state).featuredPrintRequests;

export const viewedPrintRequest = (state: AppState) =>
  printRequestState(state).viewedPrintRequest || EmptyPrintRequest;

export const searchedPrintRequests = (state: AppState) =>
  printRequestState(state).searchedPrintRequests;

export const isSubmitting = (state: AppState) => printRequestState(state).isSubmitting;

export const errorMessage = (state: AppState) => printRequestState(state).errorMessage;

export const arePrintFilesUploading = (state: AppState) => printRequestState(state).isUploadingFiles