import React, { memo } from 'react';
import imgLeft from '../../../../images/about-us-img-left.png'
import imgRight from '../../../../images/about-us-img-right.svg'
import * as S from './HeaderSection.styles';

const BottomSectionData = [
  {
    header: 'Request 3D printed objects and 3D models',
    text:
      'Our service allows you to request any 3D print-outs, ready-to-print models and even entire 3D print projects. easy way! Order your parts form expert users around the globe.',
  },
  {
    header: 'Sell your 3D prints and designs',
    text:
      'I-3d.io is a global market where you simply earn money on your 3D printing skills. Sell your 3D prints, models and projects by posting them on our marketplace.',
  },
  {
    header: 'Respond to global 3D printing needs and sell your 3D printing services',
    text:
      'Respond to 3D printing requests matching your skills and equipment. Search among doznes of printing request and offer your service locally or even globally!',
  },
  {
    header: 'Enjoy and challenge yourself',
    text:
      'Participate in events and test your 3D printing skill in challanges, follow our social media and inspire others with your work. Our creative content is always inspired by our users., so join us and let’s have fun together.',
  },
];

const getBottomData = BottomSectionData.map((s, index) => {
  return (
    <S.BottomBodyWrapper key={index}>
      <S.BottomBodyHeader>{s.header}</S.BottomBodyHeader>
      <S.BottomBodyText>{s.text}</S.BottomBodyText>
    </S.BottomBodyWrapper>
  );
});

const HeaderSection = () => {
  return (
    <S.Wrapper>
        <S.AbsoluteImageLeft src={imgLeft}/>
        <S.AbsoluteImageRight src={imgRight}/>
      <S.TopWrapper>
        <S.HeaderWrapper>
          <S.Header>About us</S.Header>
        </S.HeaderWrapper>
        <S.SubHeaderWrapper>
          <S.SubHeader>Single platform for all 3D printing people.</S.SubHeader>
        </S.SubHeaderWrapper>
      </S.TopWrapper>
      <S.BottomWrapper>
        <S.BottomHeaderWrapper>
          <S.BottomHeader>One platform for all 3D printing needs</S.BottomHeader>
        </S.BottomHeaderWrapper>
        <S.BottomDataWrapper> {getBottomData}</S.BottomDataWrapper>
      </S.BottomWrapper>
    </S.Wrapper>
  );
};

export default memo(HeaderSection);
