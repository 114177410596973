import React, { useState, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedDeliveryTypes,
  getUsersDeliveryTypes,
} from '../../../redux/reducers/auth/actions';
import { DeliveryOptionProps } from '../../../models/user';
import moment from 'moment';
import { toggleModal } from '../../../redux/reducers/utils/action';
import { MODAL_NAME } from '../../../redux/reducers/utils/models';
import { useLoggedInUser } from '../../../redux/reducers/auth/selectors';
import * as S from '../CreateProduct.styles';
import FormatPrice from '../../Common/FormatPrice/FormatPrice';

const DeliveryOptions = ({
  displaySelect = true,
  useOtherDeliveries = false,
  otherDeliveries = [],
  isEditVisible = true,
}: {
  displaySelect?: boolean;
  useOtherDeliveries?: boolean;
  otherDeliveries?: any[];
  isEditVisible?: boolean;
}) => {
  const dispatch = useDispatch();
  const { deliveryOptions } = useSelector(useLoggedInUser);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [openOption, setOpenOption] = useState<string>('');
  const handleSetOpenOption = (id: string) => {
    if (openOption === id) {
      return setOpenOption('');
    }
    return setOpenOption(id);
  };

  const handleSelectOption = (optionId: string) => {
    if (selectedOptions.includes(optionId)) {
      const updatedOptions = selectedOptions.filter((option: string) => option !== optionId);
      dispatch(setSelectedDeliveryTypes(updatedOptions));
      return setSelectedOptions(updatedOptions);
    }
    dispatch(setSelectedDeliveryTypes([...selectedOptions, optionId]));
    return setSelectedOptions([...selectedOptions, optionId]);
  };

  useEffect(() => {
    if (deliveryOptions.length === 0) {
      dispatch(getUsersDeliveryTypes());
    }
  }, [dispatch, deliveryOptions.length]);
  const usedOptions = useOtherDeliveries ? otherDeliveries : deliveryOptions;
  return (
    <S.DeliveryTypeWrapper>
      <S.DeliveryHeaderRow>
        {isEditVisible && (
          <>
            <S.DeliveryHeader>Available delivery options</S.DeliveryHeader>
            <S.DeliveryOpenModal onClick={() => dispatch(toggleModal(MODAL_NAME.DELIVERY_OPTIONS))}>
              Edit delivery types
            </S.DeliveryOpenModal>
          </>
        )}
      </S.DeliveryHeaderRow>
      <S.DeliveryTableWrapper>
        <S.DeliveryTableHeaderWrapper>
          <S.DeliveryTableHeaderOne>Delivery type</S.DeliveryTableHeaderOne>
          <S.DeliveryTableHeaderTwo>Price</S.DeliveryTableHeaderTwo>
        </S.DeliveryTableHeaderWrapper>
        {usedOptions &&
          usedOptions.length > 0 &&
          usedOptions
            .sort((a, b) => {
              const dateA = moment(a.createdAt),
                dateB = moment(b.createdAt);
              return dateA.diff(dateB);
            })
            .map(({ id, name, price, description }: DeliveryOptionProps) => (
              <S.DeliveryDoubleRow key={id}>
                <S.DeliveryTableRowWrapper isDisplayed key={id}>
                  {/* <S.DeliveryArrowWrapper  > */}
                  <S.ArrowWrapper onClick={() => handleSetOpenOption(id)}>
                    <S.DeliveryArrow isRotated={openOption === id}/>
                  </S.ArrowWrapper>
                  {/* </S.DeliveryArrowWrapper> */}
                  <S.DeliveryTypeName>{name}</S.DeliveryTypeName>
                  <S.DeliveryTypePrice>
                    <FormatPrice price={price} productCurrency={{ symbol: '€', currency: 'EUR' }} />
                  </S.DeliveryTypePrice>
                  {displaySelect && (
                    <S.DeliveryTypeCheckBox
                      onClick={() => handleSelectOption(id)}
                      isSelected={selectedOptions.includes(id)}
                    >
                      {selectedOptions.includes(id) && <S.Tick />}
                    </S.DeliveryTypeCheckBox>
                  )}
                </S.DeliveryTableRowWrapper>
                <S.DeliveryTableRowWrapper isDisplayed={openOption === id}>
                  <S.DeliveryTypeDescription>{description}</S.DeliveryTypeDescription>
                </S.DeliveryTableRowWrapper>
              </S.DeliveryDoubleRow>
            ))}
      </S.DeliveryTableWrapper>
    </S.DeliveryTypeWrapper>
  );
};

export default memo(DeliveryOptions);
