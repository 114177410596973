import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import SliderSlice from '../../../Common/SliderSlice/SliderSlice';
import CircularIndeterminateLoader from '../../../Common/Loader/CircularIndeterminateLoader';
import { SLIDER_TYPE } from '../../constant';
import { PRODUCT_TYPE } from '../../../../utils/productType';
import SliderItem from '../../../Common/Slider/SliderItem';
import {
  isLoadingShortItems,
  shortDesignsForSell,
} from '../../../../redux/reducers/search/selectors';
import Slider from '../../../Common/Slider/Slider';

const DesignRequestsSlider = () => {
  const isLoading = useSelector(isLoadingShortItems);
  const rows = useSelector(shortDesignsForSell);
  let slides: any[] = [];
  for (let index = 0; index < rows.length; index++) {
    const item = rows[index];
    slides.push(
      <SliderItem
        key={index}
        type={SLIDER_TYPE.WIDE}
        product={item}
        productType={PRODUCT_TYPE.DESIGN_FOR_SELL}
      />
    );
  }

  if (rows && rows.length === 0) {
    return null;
  }

  return (
    <SliderSlice
      title="Designs"
      title2=" for sale"
      title3="designs for sale"
      link="/search/designs-for-sell/"
    >
      {isLoading ? (
        <CircularIndeterminateLoader />
      ) : (
        <Slider
          sliderType={SLIDER_TYPE.COMPACT}
          items={rows}
          productType={PRODUCT_TYPE.DESIGN_FOR_SELL}
        />
      )}
    </SliderSlice>
  );
};

export default memo(DesignRequestsSlider);
