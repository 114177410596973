import React from 'react';
import ContinueWithFacebookButton from './ContinueWithFacebookButton';
import ContinueWithGoogleButton from './ContinueWithGoogleButton';

const ExternalLogin = ({ action }: { action: string }) => (
  <>
    <ContinueWithFacebookButton />
    <ContinueWithGoogleButton action={action} />
  </>
);

export default ExternalLogin;
