import React, { memo, useState } from 'react';
import { GalleryWithPhotos } from '../../../../models/photos';
import GalleryIcon from '../../Photos/GalleryIcon/GalleryIcon';
import * as S from './GalleriesSlider.style';

interface GalleriesSliderProps {
  galleries: GalleryWithPhotos[];
  userId: string;
}

const GalleriesSlider = ({ galleries, userId }: GalleriesSliderProps) => {
  const photoWidth = 220;
  const [current, setCurrent] = useState(0);
  const [translate, setTranslate] = useState(0);

  const modifier = 4 - (galleries.length % 4);

  const previous = () => {
    if (current > 0) {
      setCurrent(current - 1);
      setTranslate((current - 1) * photoWidth);
    } else {
      setCurrent(galleries.length - modifier);
      setTranslate((galleries.length - modifier) * photoWidth);
    }
  };

  const next = () => {
    if (current === galleries.length - modifier) {
      setCurrent(0);
      setTranslate(0);
    } else {
      setCurrent(current + 1);
      setTranslate((current + 1) * photoWidth);
    }
  };

  return (
    <S.Wrapper>
      {galleries.length > 4 && (
        <S.ArrowsWrapper>
          <S.SlideLeft>
            <S.ArrowBack onClick={previous} />
          </S.SlideLeft>
          <S.SlideRight>
            <S.ArrowForward onClick={next} />
          </S.SlideRight>
        </S.ArrowsWrapper>
      )}
      <S.SliderWrapper>
        <S.ContentWrapper translate={translate} width={photoWidth * galleries.length}>
          {galleries.map((item: GalleryWithPhotos) => (
            <GalleryIcon {...item} userId={userId} key={item.id} />
          ))}
        </S.ContentWrapper>
      </S.SliderWrapper>
    </S.Wrapper>
  );
};

export default memo(GalleriesSlider);
