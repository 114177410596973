import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Profile } from '../../../images/profile.svg';
import SearchBox from '../../SearchSimple/SearchBox/SearchBox';
import CreateMenu from './CreateMenu/CreateMenu';
import UserMenu from './UserMenu/UserMenu';
import logo from '../../../images/logo.svg';
import NotificationsMenu from './NotificationsMenu/NotificationsMenu';
import _get from 'lodash/get';
import { toggleModal } from '../../../redux/reducers/utils/action';
import { MODAL_NAME } from '../../../redux/reducers/utils/models';
import { getShortItems } from '../../../redux/reducers/search/actions';
import { SimpleSearchFlag } from '../../../models/common';
import { useLoggedInUser } from '../../../redux/reducers/auth/selectors';
import * as S from './Header.style';

const HeaderMobile = () => {
  const dispatch = useDispatch();
  const { avatar, notifications } = useSelector(useLoggedInUser);
  // TODO: requires fix be should return notifications {rows, count}
  const [count, setCount] = useState(_get(notifications, 'count', 0));
  const { pathname } = useLocation();
  const [query, setQuery] = useState('');

  useEffect(() => setCount(_get(notifications, 'count', 0)), [notifications]);

  if (pathname.includes('/gallery')) {
    return null;
  }

  const refreshItems = () => {
    setQuery('');
    if (pathname === '/') {
      dispatch(getShortItems({ flag: SimpleSearchFlag.ALL }));
    }
  };

  return (
    <S.HeaderMobileWrapper>
      <S.HeaderMobileRow>
        <Link onClick={refreshItems} style={{ height: '100%' }} to="/">
          <S.MobileImage src={logo} />
        </Link>
        <S.MenuWrapper>
          <S.Button onClick={() => dispatch(toggleModal(MODAL_NAME.CREATE_MENU))}>Create</S.Button>
          <CreateMenu />
        </S.MenuWrapper>
        <S.MenuWrapper>
          <S.MobileIcon>
            <S.Icon
              onClick={() => {
                dispatch(toggleModal(MODAL_NAME.NOTIFICATIONS));
              }}
            >
              {count > 0 && (
                <S.DotWrapper>
                  <S.RedDot>
                    <S.NotificationNumber>{count}</S.NotificationNumber>
                  </S.RedDot>
                </S.DotWrapper>
              )}
              <S.NotificationsIcon />
            </S.Icon>
            <NotificationsMenu />
            <S.Icon>
              {avatar ? (
                <S.Avatar
                  src={avatar}
                  onClick={() => dispatch(toggleModal(MODAL_NAME.USER_MENU))}
                />
              ) : (
                <Profile
                  className="Icon"
                  onClick={() => dispatch(toggleModal(MODAL_NAME.USER_MENU))}
                />
              )}
            </S.Icon>
          </S.MobileIcon>
          <UserMenu />
        </S.MenuWrapper>
      </S.HeaderMobileRow>
      <SearchBox query={query} setQuery={setQuery} />
      <S.HeaderMobileRow>
        <S.Explore onClick={() => dispatch(toggleModal(MODAL_NAME.HEADER_MAIN_MENU))}>
          Explore the 3D Market
        </S.Explore>
      </S.HeaderMobileRow>
    </S.HeaderMobileWrapper>
  );
};

export default memo(HeaderMobile);
