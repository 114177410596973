import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
import * as S from './CircularIndeterminateLoader.styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      margin: theme.spacing(2),
    },
  })
);

function CircularIndeterminateLoader() {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <S.Wrapper>
      <CircularProgress className={classes.progress} />
    </S.Wrapper>
  );
}

export default CircularIndeterminateLoader;
