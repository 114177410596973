import styled from 'styled-components';

export const DeadlineWrapper = styled.div`
  padding-left: 19px;
  margin-bottom: 24px;
  height: 33px;
`;

export const Tag = styled.div`
  font-size: 11px;
  letter-spacing: 0.15px;
  color: #4D4D4D;
`;

export const Deadline = styled.div`
  font-size: 16px;
  color: #707070;
`;
