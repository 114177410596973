import React, { memo, useState } from 'react';
import HelpCenterMenu from './HelpCenterMenu';
import { HELP_CENTER } from './Legal/Legal';
import * as S from './HelpCenter.styles';

const HelpCenter = () => {
  const [selected, setSelected] = useState(HELP_CENTER.MAIN);
  return (
    <S.ExternalWrapper>
      <S.Wrapper>
        <S.HeaderContainer>
          <S.Header>How can we help you?</S.Header>
          <S.SubHeader>Here you can find all helpful information.</S.SubHeader>
        </S.HeaderContainer>
        <HelpCenterMenu selected={selected} setSelected={setSelected} />
      </S.Wrapper>
    </S.ExternalWrapper>
  );
};

export default memo(HelpCenter);
