import styled from 'styled-components';
import emptyStatesSVGs from '../../../Assets/EmptyStates/emptyStates';

export const EmptyMarketContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

export const LeftMarketConstruct = styled.img.attrs({ src: emptyStatesSVGs.marketLeftConstruct })`
  position: absolute;
  left: 12%;
  bottom: 35%;
`;

export const RightMarketConstruct = styled.img.attrs({ src: emptyStatesSVGs.marketRightConstruct })`
  position: absolute;
  right: 17%;
  top: 0%;
  z-index: -1;
`;

export const EmptyShortBox = styled.div`
  width: 196px;
  height: 196px;
  border: 1px solid #e1e2e1;
  box-sizing: border-box;
`;

export const EmptyProductColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
export const EmptyTextBar = styled.div<{ isShort: boolean }>`
  height: 18px;
  width: ${(props: any) => (props.isShort ? '77px' : '180px')};
  background-color: #f2f2f2;
  border-radius: 3px;
  margin: 8px 0;
`;
