import React from 'react';
import * as S from '../CreateProduct.styles';

interface InputProps {
  label?: string;
  sublabel?: string;
  children?: any;
  max?: number;
  handleInputChange?: (e: any) => void;
  type?: INPUT_TYPE;
  optional?: boolean;
  unit?: string;
  textAlign?: string;
  value: any;
  valueType?: string;
  required?: boolean;
  isDisabled?: boolean;
  maxInputValue?: number;
  wrapperStyles?: string;
  stepValue?: string;
  placeholder?: string;
  minInputValue?: string;
}

export enum INPUT_TYPE {
  SHORT,
  MODAL_SHORT,
  MEDIUM,
  LONG,
}

const getSelectorWidth = (type: INPUT_TYPE) => {
  switch (type) {
    case INPUT_TYPE.SHORT:
      return '107px';
    case INPUT_TYPE.MEDIUM:
      return '196px';
    case INPUT_TYPE.MODAL_SHORT:
      return '415px';
    case INPUT_TYPE.LONG:
      return '100%';
  }
};

const Input = ({
  value,
  children,
  label,
  sublabel,
  handleInputChange,
  max = 100,
  type = INPUT_TYPE.SHORT,
  optional = false,
  unit,
  valueType = 'text',
  textAlign = 'left',
  required = false,
  isDisabled = false,
  maxInputValue,
  wrapperStyles,
  stepValue = '1',
  placeholder,
  minInputValue = '0',
}: InputProps) => (
  <S.InputWrapper styles={wrapperStyles}>
    {label && (
      <S.LabelWrapper>
        <S.Label>{label}</S.Label>
        {optional && <S.OptionalLabel>(optional)</S.OptionalLabel>}
      </S.LabelWrapper>
    )}
    <S.InputWithSetter>
      <S.Input
        max={maxInputValue}
        type={valueType}
        min={minInputValue}
        step={stepValue}
        value={value}
        onChange={(e: any) => handleInputChange && handleInputChange(e.target.value)}
        maxLength={max}
        width={getSelectorWidth(type)}
        textAlign={textAlign}
        required={required}
        disabled={isDisabled}
        placeholder={placeholder}
      />
      {unit && <S.InputUnit>{unit}</S.InputUnit>}
    </S.InputWithSetter>
    {sublabel && (
      <S.SubLabelWrapper>
        <S.SubLabel>{sublabel}</S.SubLabel>
        {children}
      </S.SubLabelWrapper>
    )}
  </S.InputWrapper>
);

export default Input;
