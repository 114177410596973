import styled from 'styled-components';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const getWrapperStyle = () => {
  const basicStyle = {
    cursor: 'pointer',
    boxSizing: 'border-box',
    width: '100%',
    height: '86px',
    borderRadius: '3px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    margin: 'auto 0 0',
    zIndex: 1,
  } as CSSProperties;

  return basicStyle;
};

export const Title = styled.div`
  height: 43px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.14px;
  color: #4D4D4D;
  opacity: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

export const CreatedAt = styled.div`
  height: 22px;
  font-size: 12px;
  letter-spacing: 0.12px;
  font-weight: 500;
  color: #9a9a9a;
  opacity: 1;
`;

export const TextWrapper = styled.div`
  background-color: rgb(999, 999, 999, 0.7);
  width: 100%;
  padding: 8px 10px;
  box-sizing: border-box;
  z-index: 1;
`;

export const MenuButton = styled(BsThreeDotsVertical)`
  border-radius: 100%;
  background-color: #fff;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  padding: 2px;
  color: #4D4D4D;
  cursor: pointer;
  margin: 10px 10px auto auto;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
`;

export const Container = styled.div`
  position: relative;
  width: 192px;
  height: 276px;
  display: flex;
  flex-direction: column;
  margin: 0 8px 8px;
`;

export const BackgroundPhoto = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  object-fit: contain;
  object-position: center;
`;
