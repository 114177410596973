import greenArrow from './green_arrow.svg';
import emptyAvatar from './emptyAvatar.svg';
import notificationsBell from './notificationsBell.svg';
import greenTick from './green_tick.svg';
import PurpleConstruct from './construct-purple.svg';
import OrangeConstruct from './construct-orange.svg';
import BlueYellowConstruct from './construct-blue-yellow.svg';

export default {
  greenArrow,
  emptyAvatar,
  notificationsBell,
  greenTick,
  PurpleConstruct,
  OrangeConstruct,
  BlueYellowConstruct,
};
