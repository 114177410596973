import styled from 'styled-components';
import { Link } from 'react-router-dom';
import U from '../../Assets/commonCSS';
import { MdClose } from 'react-icons/md';

export const Wrapper = styled.div<{ open: boolean }>`
  display: ${(props: any) => (props.open ? 'flex' : 'none')};
  background-color: transparent;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  position: absolute;
  height: 100%;
  box-sizing: border-box;
  pointer-events: none;
`;

export const Underline = styled(Link)`
  color: #efefef;
  display: inline;
  text-decoration: underline;
  cursor: pointer;
  margin: 0 4px;
  pointer-events: auto;
`;

export const MessageWrapper = styled.div<{ width?: string }>`
  z-index: ${U.zIndexMenu};
  margin: 0 12px;
  box-sizing: border-box;
  padding: 8px;
  max-width: 720px;
  width: ${(props: any) => (props.width ? props.width : '100%')};
  height: 80px;
  background-color: #0077df;
  border: 1px solid #707070;
  border-radius: 4px;
  padding: auto;
  position: relative;
  bottom: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
`;

export const MessageText = styled.div`
  color: #efefef;
  font-size: 16px;
`;

export const Bolded = styled.span`
  font-weight: 700;
`;

export const Close = styled(MdClose)`
  font-size: 28px;
  color: #ffffff;
  cursor: pointer;
  height: 28px;
  position: absolute;
  right: 5px;
  top: 5px;
`;
