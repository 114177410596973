import styled from 'styled-components';

export const Wrapper = styled.div``;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  letter-spacing: 0.14px;
  margin-bottom: 16px;
`;

export const Header = styled.div`
  font-size: 24px;
  font-weight: 800;
`;

export const ReviewsCount = styled.div`
  font-size: 14px;
  color: #9a9a9a;
  margin-left: 19px;
  font-weight: 500;
  padding-top: 1px;
`;

export const ReviewsWrapper = styled.div`
  max-width: 736px;
  width: 100%;
  margin-bottom: 34px;
`;

export const B = styled.span`
  font-weight: 800;
`;

export const ShowMore = styled.div`
  width: 100%;
  text-align: end;
  font-size: 15px;
  letter-spacing: 0.14px;
  color: #4D4D4D;
`;
