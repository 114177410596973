import styled from 'styled-components';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import U from '../../../Assets/commonCSS';

export const Wrapper = styled.div``;

export const ArrowsWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
`;

const SlideGeneric = `
width: 41px;
height: 41px;
box-shadow: 0px 3px 4px #00000029;
border: 1px solid #BFBFBF;
background-color: #fff;
border-radius: 100%;
cursor: pointer;
z-index: ${U.zIndex1};
position: relative;
box-shadow: 0px 3px 4px #00000029;
border: 1px solid #BFBFBF;
display: flex;
justify-content: center;
align-items: center;
top: 116px;
`;

export const SlideLeft = styled.div`
  ${SlideGeneric}
  right: 21px;
`;

export const SlideRight = styled.div`
  ${SlideGeneric}
  left: 778px;
`;

export const ArrowBack = styled(IoIosArrowBack)`
  width: 30px;
  height: 30px;
`;

export const ArrowForward = styled(IoIosArrowForward)`
  width: 30px;
  height: 30px;
`;

export const SliderWrapper = styled.div`
  display: flex;
  margin: auto;
  position: relative;
  justify-content: space-between;
  overflow: hidden;
`;

export const ContentWrapper = styled.div<{ translate: number; width: number }>`
  display: flex;
  transform: translateX(-${(props: any) => props.translate}px);
  transition: transform ease-out 0.45s;
  height: 100%;
  width: ${(props: any) => props.width}px;
` as any;
