import { AnyAction } from 'redux';
import { TransactionPaymentProps } from '../../components/UserSettings/PaymentsPage/TransactionPayments';
import { TransactionPayoutProps } from '../../components/UserSettings/PaymentsPage/TransactionPayout';
import { Notification } from '../../utils/notificationTypes';
import { PRODUCT_TYPE } from '../../utils/productType';
import {
  FETCH_OWN_PRINT_REQUESTS_SUCCESS,
  SET_USERS_QUERIES,
  FETCH_OWN_OFFERS_SUCCESS,
  FETCH_OWN_DESIGN_REQUESTS_SUCCESS,
  FETCH_OWN_DESIGNS_FOR_SELL,
  FETCH_OWN_OBJECTS_FOR_SELL,
  REFRESH_REQUEST_SUCCESS,
  ARCHIVE_PRODUCT_SUCCESS,
  GET_MY_SHOPPING_SUCCESS,
  BUY_PRODUCT_SUCCESS,
  GET_PAYMENTS_START,
  GET_PAYMENTS_SUCCESS,
  GET_PAYMENTS_FAILURE,
  GET_PAYOUTS_START,
  GET_PAYOUTS_SUCCESS,
  GET_PAYOUTS_FAILURE,
  CLOSE_DFS_SELLING,
  CLOSE_DFS_SELLING_SUCCESS,
  CLOSE_DFS_SELLING_FAIL,
  OPEN_DFS_SELLING,
  OPEN_DFS_SELLING_SUCCESS,
  OPEN_DFS_SELLING_FAIL,
  CLOSE_OFS_SELLING,
  CLOSE_OFS_SELLING_SUCCESS,
  CLOSE_OFS_SELLING_FAIL,
  OPEN_OFS_SELLING,
  OPEN_OFS_SELLING_SUCCESS,
  OPEN_OFS_SELLING_FAIL,
  CANCEL_DR,
  CANCEL_DR_SUCCESS,
  CANCEL_DR_FAIL,
  CANCEL_PR,
  CANCEL_PR_SUCCESS,
  CANCEL_PR_FAIL,
} from '../actionTypes';
import { LOGOUT_USER_SUCCESS, SET_USER_DATA_LOADING_STATE } from './auth/actionTypes';

interface Product {
  count: number;
  rows: any[];
}
export interface UserState {
  printRequests: Product;
  oldQueries: string[];
  offers: Product;
  designRequests: Product;
  designsForSell: Product;
  objectsForSell: Product;
  notifications: Notification[];
  isLoadingData: boolean;
  myShopping: Product;
  payouts: TransactionPayoutProps[];
  payments: TransactionPaymentProps[];
}

const initialState: UserState = {
  printRequests: {
    count: 0,
    rows: [],
  },
  oldQueries: [],
  offers: {
    count: 0,
    rows: [],
  },
  designRequests: {
    count: 0,
    rows: [],
  },
  designsForSell: {
    count: 0,
    rows: [],
  },
  objectsForSell: {
    count: 0,
    rows: [],
  },
  notifications: [],
  isLoadingData: false,
  myShopping: {
    count: 0,
    rows: [],
  },
  payouts: [],
  payments: [],
};

export default function (state = initialState, action: AnyAction) {
  switch (action.type) {
    case ARCHIVE_PRODUCT_SUCCESS: {
      const { productId } = action.data;
      const productType: PRODUCT_TYPE = action.data.productType;
      const otherPrintRequests = state.printRequests.rows.filter(
        (item: any) => item.id !== productId
      );
      const setUpdated = () => {
        if (PRODUCT_TYPE.PRINT_REQUEST === productType) {
          return {
            printRequests: { ...state.printRequests, rows: otherPrintRequests },
          };
        }
        if (PRODUCT_TYPE.OBJECT_FOR_SELL === productType) {
          const otherObjectsForSell = state.objectsForSell.rows.filter(
            (item: any) => item.id !== productId
          );
          return {
            objectsForSell: { ...state.objectsForSell, rows: otherObjectsForSell },
          };
        }
        if (PRODUCT_TYPE.DESIGN_REQUEST === productType) {
          const otherDesignRequests = state.designRequests.rows.filter(
            (item: any) => item.id !== productId
          );
          return {
            designRequests: { ...state.designRequests, rows: otherDesignRequests },
          };
        }
        if (PRODUCT_TYPE.DESIGN_FOR_SELL === productType) {
          const otherDesignsForSell = state.designsForSell.rows.filter(
            (item: any) => item.id !== productId
          );
          return {
            designsForSell: { ...state.designsForSell, rows: otherDesignsForSell },
          };
        }
      };
      return {
        ...state,
        ...setUpdated(),
      };
    }
    case REFRESH_REQUEST_SUCCESS: {
      const product = action.data.product;
      const productType: PRODUCT_TYPE = action.data.productType;
      const otherPrintRequests = state.printRequests.rows.filter(
        (item: any) => item.id !== product.id
      );
      const setUpdated = () => {
        if (PRODUCT_TYPE.PRINT_REQUEST === productType) {
          return {
            printRequests: { ...state.printRequests, rows: [...otherPrintRequests, product] },
          };
        }
        if (PRODUCT_TYPE.OBJECT_FOR_SELL === productType) {
          const otherObjectsForSell = state.objectsForSell.rows.filter(
            (item: any) => item.id !== product.id
          );
          return {
            objectsForSell: { ...state.objectsForSell, rows: [...otherObjectsForSell, product] },
          };
        }
        if (PRODUCT_TYPE.DESIGN_REQUEST === productType) {
          const otherDesignRequests = state.designRequests.rows.filter(
            (item: any) => item.id !== product.id
          );
          return {
            designRequests: { ...state.designRequests, rows: [...otherDesignRequests, product] },
          };
        }
        if (PRODUCT_TYPE.DESIGN_FOR_SELL === productType) {
          const otherDesignsForSell = state.designsForSell.rows.filter(
            (item: any) => item.id !== product.id
          );
          return {
            designsForSell: { ...state.designsForSell, rows: [...otherDesignsForSell, product] },
          };
        }
      };
      return {
        ...state,
        ...setUpdated(),
      };
    }
    case LOGOUT_USER_SUCCESS: {
      return initialState;
    }
    case FETCH_OWN_PRINT_REQUESTS_SUCCESS: {
      const rows = action.data;
      const count = rows ? rows.length : 0;
      return {
        ...state,
        printRequests: { rows, count },
      };
    }
    case SET_USERS_QUERIES: {
      return {
        ...state,
        oldQueries: action.data,
      };
    }
    case FETCH_OWN_OFFERS_SUCCESS: {
      const rows = action.data;
      const count = rows ? rows.length : 0;
      return {
        ...state,
        offers: { rows, count },
      };
    }
    case FETCH_OWN_DESIGN_REQUESTS_SUCCESS: {
      const rows = action.data;
      const count = rows ? rows.length : 0;
      return {
        ...state,
        designRequests: { rows, count },
      };
    }
    case FETCH_OWN_DESIGNS_FOR_SELL: {
      const rows = action.data;
      const count = rows ? rows.length : 0;
      return {
        ...state,
        designsForSell: { rows, count },
      };
    }
    case FETCH_OWN_OBJECTS_FOR_SELL: {
      const rows = action.data;
      const count = rows ? rows.length : 0;
      return {
        ...state,
        objectsForSell: { rows, count },
      };
    }
    case SET_USER_DATA_LOADING_STATE: {
      return {
        ...state,
        isLoadingData: action.isLoading,
      };
    }
    case GET_MY_SHOPPING_SUCCESS: {
      return {
        ...state,
        myShopping: action.data,
      };
    }
    case BUY_PRODUCT_SUCCESS: {
      return {
        ...state,
        myShopping: action.data,
      };
    }
    case GET_PAYMENTS_START: {
      return {
        ...state,
        isLoadingData: true,
      };
    }
    case GET_PAYMENTS_SUCCESS: {
      return {
        ...state,
        payments: action.data,
        isLoadingData: false,
      };
    }
    case GET_PAYMENTS_FAILURE: {
      return {
        ...state,
        isLoadingData: false,
      };
    }
    case GET_PAYOUTS_START: {
      return {
        ...state,
        isLoadingData: true,
      };
    }
    case GET_PAYOUTS_SUCCESS: {
      return {
        ...state,
        payouts: action.data,
        isLoadingData: false,
      };
    }
    case GET_PAYOUTS_FAILURE: {
      return {
        ...state,
        isLoadingData: false,
      };
    }
    case CLOSE_DFS_SELLING: {
      return {
        ...state,
        isLoadingData: true,
      };
    }
    case CLOSE_DFS_SELLING_SUCCESS: {
      const { id } = action.data;
      const {
        designsForSell: { rows, count },
      } = state;
      const filteredDesigns = rows.filter((row: any) => row.id !== id);
      const designRows = [...filteredDesigns, action.data];
      return {
        ...state,
        designsForSell: { count, rows: designRows },
        isLoadingData: false,
      };
    }
    case CLOSE_DFS_SELLING_FAIL: {
      return {
        ...state,
        isLoadingData: false,
      };
    }
    case OPEN_DFS_SELLING: {
      return {
        ...state,
        isLoadingData: true,
      };
    }
    case OPEN_DFS_SELLING_SUCCESS: {
      const { id } = action.data;
      const {
        designsForSell: { rows, count },
      } = state;
      const filteredDesigns = rows.filter((row: any) => row.id !== id);
      const designRows = [...filteredDesigns, action.data];
      return {
        ...state,
        designsForSell: { count, rows: designRows },
        isLoadingData: false,
      };
    }
    case OPEN_DFS_SELLING_FAIL: {
      return {
        ...state,
        isLoadingData: false,
      };
    }
    case CLOSE_OFS_SELLING: {
      return {
        ...state,
        isLoadingData: true,
      };
    }
    case CLOSE_OFS_SELLING_SUCCESS: {
      const { id } = action.data;
      const {
        objectsForSell: { rows, count },
      } = state;
      const filteredObjects = rows.filter((row: any) => row.id !== id);
      const objectRows = [...filteredObjects, action.data];
      return {
        ...state,
        objectsForSell: { count, rows: objectRows },
        isLoadingData: false,
      };
    }
    case CLOSE_OFS_SELLING_FAIL: {
      return {
        ...state,
        isLoadingData: false,
      };
    }
    case OPEN_OFS_SELLING: {
      return {
        ...state,
        isLoadingData: true,
      };
    }
    case OPEN_OFS_SELLING_SUCCESS: {
      const { id } = action.data;
      const {
        objectsForSell: { rows, count },
      } = state;
      const filteredObjects = rows.filter((row: any) => row.id !== id);
      const objectRows = [...filteredObjects, action.data];
      return {
        ...state,
        objectsForSell: { count, rows: objectRows },
        isLoadingData: false,
      };
    }
    case OPEN_OFS_SELLING_FAIL: {
      return {
        ...state,
        isLoadingData: false,
      };
    }
    case CANCEL_DR: {
      return {
        ...state,
        isLoadingData: true,
      };
    }
    case CANCEL_DR_SUCCESS: {
      const { id } = action.data;
      const {
        designRequests: { rows, count },
      } = state;
      const filtered = rows.filter((row: any) => row.id !== id);
      return {
        ...state,
        designRequests: { count, rows: filtered },
        isLoadingData: false,
      };
    }
    case CANCEL_DR_FAIL: {
      return {
        ...state,
        isLoadingData: false,
      };
    }
    case CANCEL_PR: {
      return {
        ...state,
        isLoadingData: true,
      };
    }
    case CANCEL_PR_SUCCESS: {
      const { id } = action.data;
      const {
        printRequests: { rows, count },
      } = state;
      const filtered = rows.filter((row: any) => row.id !== id);
      return {
        ...state,
        printRequests: { count, rows: filtered },
        isLoadingData: false,
      };
    }
    case CANCEL_PR_FAIL: {
      return {
        ...state,
        isLoadingData: false,
      };
    }
    default:
      return state;
  }
}
