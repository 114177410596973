import styled, { css } from 'styled-components';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { MdCheck, MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
import {
  SELECTOR_TYPE,
  SELECTOR_BORDER_RADIUS_OPTIONS,
  SELECTOR_POSITIONS,
  selectorBorders,
} from './Inputs/Selector';
import CreatableSelect from 'react-select/creatable';
import U from '../Assets/commonCSS';
import themes from '../Assets/colorThemes';
import { MdClose } from 'react-icons/md';

const IconSize = '24px';

export const Wrapper = styled.div`
  width: 83.25%;
  height: auto;
  display: flex;
  flex-direction: row;
  margin: auto;
  flex: 1 0 auto;
  margin-top: 70px;
  margin-left: 16.75%;
  @media (max-width: 1175px) {
    margin-top: 90px;
  }
  @media (max-width: 1096px) {
    width: 100%;
    margin: 110px auto 30px;
  }
  @media (max-width: 745px) {
    width: 100%;
    margin: 150px 0 30px;
  }
`;

export const ScrollToTopWrapper = styled.button`
  border: none;
  background-color: #fff;
  width: 44px;
  height: 44px;
  z-index: ${U.zIndex2};
  border-radius: 50%;
  box-shadow: 0px 3px 4px ${themes.default.transparentGray29};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ScrollHorizontalLine = styled.div`
  position: relative;
  width: 50%;
  height: 1px;
  border-top: 1px solid;
  top: 35%;
  left: 25%;
`;

export const ScrollSemiArrow = styled.div`
  border-top: 2px solid #000000;
  border-right: 2px solid #000000;
  width: 18px;
  height: 18px;
  transform: rotate(316deg);
  cursor: pointer;
  margin-top: 10px;
`;

export const BackgroundFiller = styled.div`
  position: fixed;
  width: 16.75%;
  left: 0;
  top: 41px;
  height: 100%;
  z-index: ${U.zIndex_1};
  @media (max-width: 1096px) {
    display: none;
  }
`;

export const Stepper = styled.div`
  min-width: 230px;
  width: 304px;
  padding-top: 41px;
  top: 108px;
  @media (max-width: 1096px) {
    display: none;
  }
`;

export const StepperDetails = styled.div`
  position: fixed;
`;

export const StepperHeader = styled.div``;

export const StepperStepDescription = styled.div`
  font-size: 11px;
  max-width: 227px;
  min-height: 42px;
`;

export const StepperStepTitle = styled.div<{ isActive?: boolean }>`
  font-size: 14px;
  font-weight: ${(props: any) => (props.isActive ? '700' : '500')};
`;

export const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  left: -29px;
  position: relative;
`;

export const ProgressLineWrapper = styled.div<{ isLast?: boolean }>`
  background-color: ${themes.default.blue};
  width: 4px;
  margin: 0 24px 0 10px;
  height: ${(props: any) => (props.isLast ? '100px' : '135px')};
`;

export const ProgressDot = styled.div<{ offset: string; isActive: boolean }>`
  width: ${(props: any) => (props.isActive ? '18px' : '10px')};
  height: ${(props: any) => (props.isActive ? '18px' : '10px')};
  left: ${(props: any) => (props.isActive ? '3px' : '7px')};
  position: absolute;
  border-radius: 50%;
  background-color: ${(props: any) =>
    props.isActive ? themes.default.blue : themes.default.grayE2};
  top: ${(props: any) => props.offset};
  z-index: ${(props: any) => (props.isActive ? 1 : 0)};
`;
export const ProgressLine = styled.div<{ height: string; offset: string; isActive: boolean }>`
  background-color: ${(props: any) =>
    props.isActive ? themes.default.blue : themes.default.grayE2};
  position: absolute;
  width: 4px;
  height: ${(props: any) => props.height};
  top: ${(props: any) => props.offset};
  z-index: ${(props: any) => (props.isActive ? 1 : 0)};
`;

export const StepperStep = styled.div`
  padding-bottom: 24px;
`;

export const CreatorColumn = styled.div`
  max-width: 672px;
  @media (max-width: 1096px) {
    margin: 0 auto;
  }
`;

export const CreatorWrapper = styled.form`
  padding: 0 20px 20px;
  width: 100%;
  font-size: 14px;
  overflow: auto;
  box-sizing: border-box;
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 40px;
  font-weight: 600;
`;

export const StepWrapper = styled.div<{ isFirst?: boolean }>`
  margin-bottom: 22px;
  background-color: ${themes.default.white};
  padding: ${(props: any) => (props.isFirst ? '47px 22px 0px' : '32px 22px 0px')};
  box-sizing: border-box;
`;

export const Header = styled.div``;
export const SubHeader = styled.div`
  font-size: 24px;
  font-weight: 900;
`;
export const StepInnerWrapper = styled.div`
  padding-bottom: 63px;
  white-space: pre-wrap;
`;
export const StepTitle = styled.div`
  margin-top: 25px;
  font-size: 26px;
  border-bottom: 2px solid;
  padding-bottom: 8px;
  margin-bottom: 7px;
`;
export const StepDescription = styled.div`
  font-size: 15px;
  max-width: 325px;
  line-height: 1.3;
  letter-spacing: 0.32px;
  margin-bottom: 15px;
`;
export const StepInfo = styled.div`
  background-color: ${themes.default.grayFa};
  max-width: 303px;
  width: 100%;
  min-height: 53px;
  margin-left: auto;
  padding: 10px 54px 8px 10px;
  box-sizing: border-box;
  font-size: 12px;
  color: ${themes.default.blue};
  display: flex;
  border-radius: 4px;
`;

export const StepHints = styled.div`
  border-bottom: 1px solid;
  padding-bottom: 10px;
`;

export const InputWrapper = styled.div<{ styles?: string }>`
  padding-bottom: 19px;
  z-index: ${U.zIndex2};
  ${(props: any) => props.styles}
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  font-weight: 700;
  font-size: 15px;
  padding: 0 0 4px 1px;
`;

export const OptionalLabel = styled.div`
  font-size: 12px;
  color: ${themes.default.grayBf};
  padding-bottom: 4px;
  margin-left: 6px;
`;

export const Input = styled.input<{ textAlign?: string; width?: string }>`
  width: ${(props: any) => props.width};
  text-align: ${(props: any) => (props.textAlign ? props.textAlign : 'left')};
  border: 1px solid ${themes.default.grayBf};
  border-radius: 4px;
  box-sizing: border-box;
  height: 40px;
  padding-bottom: 8px;
  letter-spacing: 0.72px;
  padding: 0 14px;
  font-size: 14px;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px ${themes.default.blue};
  }
`;

export const ShortInput = styled(Input)`
  width: 303px;
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px ${themes.default.blue};
  }
`;

export const QuantitySetters = styled.div`
  display: flex;
  margin-left: 10px;
`;

export const InputWithSetter = styled.div`
  display: flex;
  align-items: center;
`;

const IconStyling = css`
  height: 100%;
  height: ${IconSize};
  border: 1px solid ${themes.default.gray9a};
  border-radius: 100%;
  width: ${IconSize};
  background-color: ${themes.default.grayEf};
  box-sizing: border-box;
  padding: 2px;
  color: ${themes.default.grey5f};
`;
export const Increment = styled(AiOutlinePlus)`
  ${IconStyling}
  margin-left: 8px;
  cursor: pointer;
`;

export const Decrement = styled(AiOutlineMinus)`
  ${IconStyling}
  cursor: pointer;
`;

export const SubLabelWrapper = styled.div`
  display: flex;
  font-size: 12px;
  letter-spacing: 0.05px;
  margin-top: 8px;
`;

export const SubLabel = styled.div`
  font-size: 12px;
  letter-spacing: 0.07px;
  display: flex;
  font-weight: 600;
`;

export const LetterCounter = styled.div`
  word-spacing: 0.15px;
  text-align: right;
  flex-grow: 1;
`;

export const SelectorInputWrapper = styled.div<{ type: SELECTOR_TYPE }>`
  max-width: ${(props: any) => (props.type === SELECTOR_TYPE.SHORT ? '303px' : '100%')};
  width: 100%;
  display: flex;
  @media (max-width: 1096px) {
    width: 100%;
  }
`;

export const SelectorInput = styled(Input)`
  border-radius: 4px 0 0 4px;
  border-right: 0;
  padding: 0 14px;
  width: 50%;
  text-align: right;
  @media (max-width: 1096px) {
    flex-grow: 53;
  }
`;

interface SelectorProps {
  width: string;
  isActive: boolean;
  selectorAlignment: SELECTOR_BORDER_RADIUS_OPTIONS;
}

export const Selector = styled(CreatableSelect)<SelectorProps>`
  width: ${(props: any) => props.width};
  cursor: pointer;
  border-radius: ${(props: SelectorProps) =>
    selectorBorders[props.selectorAlignment][
      props.isActive ? SELECTOR_POSITIONS.ACTIVE : SELECTOR_POSITIONS.PASSIVE
    ]};
  border: 1px solid ${themes.default.grayBf};
  ${(props: SelectorProps) =>
    props.selectorAlignment === SELECTOR_BORDER_RADIUS_OPTIONS.LEFT ? `border-right: 0px;` : ''}
  box-sizing: border-box;
  height: 40px;
  letter-spacing: 0.72px;
  font-size: 14px;
  position: relative;
  display: flex;
  @media (max-width: 1096px) {
    min-width: 74px;
    width: auto;
    flex-grow: 53;
  }
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px ${themes.default.blue};
  }
`;

export const SelectedOption = styled.div`
  width: 100%;
  align-items: center;
  height: 100%;
  display: flex;
  padding-left: 14px;
`;

export const DropdownArrow = styled(MdKeyboardArrowDown)`
  height: 100%;
  width: 51px;
  padding-top: 5px;
  height: auto;
  padding-right: 2px;
`;

export const Options = styled.div<{ isActive?: boolean }>`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  position: absolute;
  width: 100%;
  margin-top: 39px;
  background-color: ${themes.default.white};
  z-index: ${U.zIndexMenu};
`;

export const Option = styled.div`
  height: 35px;
  border: 1px solid ${themes.default.grayBf};
  border-top: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const OptionInput = styled.input`
  width: 100%;
  height: 90%;
  border: none;
  text-align: center;
  margin: 1px;
  color: inherit;
  letter-spacing: 0.72px;
  font-size: 14px;
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px ${themes.default.blue};
  }
`;

export const TextArea = styled.textarea<{ styles?: string }>`
  height: 223px;
  background: ${themes.default.white};
  border: 1px solid ${themes.default.grayBf};
  border-radius: 4px;
  width: 100%;
  resize: none;
  box-sizing: border-box;
  padding: 14px;
  font-size: 14px;
  font-family: inherit;
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px ${themes.default.blue};
  }
  ${(props: any) => props.styles}
`;

export const Counter = styled.div`
  text-align: right;
  font-size: 12px;
  letter-spacing: 0.15px;
  color: ${themes.default.grey33};
  width: 100%;
  margin-top: 10px;
`;

export const DateInputWrapper = styled(InputWrapper)`
  width: 302px;
  @media (max-width: 1096px) {
    padding-bottom: 32px;
  }
  @media (max-width: 500px) {
    padding-bottom: 32px;
    width: 100%;
  }
`;

export const DateInput = styled.input`
  border: 1px solid ${themes.default.grayBf};
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  padding-bottom: 8px;
  letter-spacing: 0.72px;
  padding: 0 14px;
  font-size: 14px;
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px ${themes.default.blue};
  }
`;

export const DateSubLabel = styled.div`
  margin-top: 8px;
  width: 100%;
  justify-content: flex-end;
  font-size: 12px;
  display: flex;
`;

export const AmountOfDays = styled.div`
  font-weight: 700;
  margin-right: 5px;
`;

export const DropzoneWrapper = styled.div<{
  isFilled?: boolean;
  isThumbnail?: boolean;
  isWorkshop?: boolean;
}>`
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: ${(props: any) => (props.isThumbnail ? 'auto' : '198px')};
  font-size: 15px;
  letter-spacing: 0.35px;
  min-width: 231px;
  ${(props: any) =>
    props.isWorkshop
      ? ` 
    `
      : `
    border-top: 1px solid ${themes.default.grayBf}; 
    border-bottom: 1px solid ${themes.default.grayBf};
    background-color: ${themes.default.grayFa};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${props.isThumbnail ? '10px 20px' : ''};
    `};
  max-height: 70vh;
`;

export const DropzoneWrapperWithSlider = styled.div<{ isFilled?: boolean; isThumbnail?: boolean }>`
  height: ${(props: any) => (props.isThumbnail ? 'auto' : '229px')};
  background-color: ${themes.default.grayFa};
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
  border-top: 1px solid ${themes.default.grayBf};
  border-bottom: 1px solid ${themes.default.grayBf};
  font-size: 15px;
  letter-spacing: 0.35px;
  overflow: scroll;
  min-width: 231px;
  padding: ${(props: any) => (props.isThumbnail ? '10px 20px' : '')};
  align-items: ${(props: any) => (props.isFilled ? 'baseline' : '')};
  cursor: pointer;
`;

export const DropzoneInfo = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

export const DropzoneText = styled.div`
  padding-top: 8px;
  align-self: center;
  cursor: pointer;
`;

export const DropzoneSublabels = styled.div`
  font-size: 12px;
`;

export const DropzoneSublabel = styled.div`
  margin-top: 6px;
`;

export const SubStepWrapper = styled.div`
  padding-bottom: 20px;
  @media (max-width: 1096px) {
    padding-bottom: 32px;
  }
`;

export const SubStepHeader = styled.div`
  font-size: 19px;
  padding-bottom: 15px;
  font-weight: 600;
  letter-spacing: 0.25px;
`;

export const InputUnit = styled.div<{ styles?: string }>`
  padding-left: 10px;
  ${(props: any) => props.styles}
`;

export const SeparatorWrapper = styled.div`
  width: 303px;
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 19px;
`;
export const SeparatorLine = styled.div`
  border: solid 0.5px;
  width: 100%;
`;
export const SeparatorText = styled.div`
  text-align: center;
  position: absolute;
  background: ${themes.default.white};
  padding: 0 10px;
`;

export const VolumeCounterWrapper = styled.div`
  border-top: 2px solid ${themes.default.grayBf};
  background-color: ${themes.default.grayFa};
  width: 336px;
  margin-top: 15px;
  min-height: 120px;
`;

export const MockWrapper = styled.div``;

export const VolumeVolume = styled.div`
  border-top: 1px solid ${themes.default.grayBf};
  border-bottom: 1px solid ${themes.default.grayBf};
  font-size: 14px;
  font-weight: 600;
  padding: 11px 14px;
  text-align: end;
`;
export const VolumeQualificationWrapper = styled.div`
  padding: 20px 0;
  font-size: 14px;
  display: flex;
`;
export const VolumeIcon = styled.div`
  width: 37px;
  height: 36px;
  padding: 0 14px 0 15px;
`;
export const VolumeInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const VolumeQualificationText = styled.div`
  letter-spacing: 0.75px;
  padding-bottom: 3px;
`;
export const VolumeQualificationValue = styled.div`
  font-weight: 700;
  letter-spacing: 0.5px;
`;

export const DeliveryAddressWrapper = styled.div`
  width: 100%;
`;

export const DeliveryDetails = styled.div`
  padding: 18px 15px;
  margin-bottom: 14px;
`;

export const DeliveryAddressName = styled.div`
  font-weight: 600;
  padding-bottom: 7px;
  word-break: break-word;
`;

export const DeliveryAddressLine = styled.div`
  padding-bottom: 5px;
  word-break: break-word;
`;

export const DeliveryAddressDefault = styled.div`
  position: absolute;
  bottom: 0px;
  padding: 6px 15px;
  font-size: 11px;
  border-top: 1px solid ${themes.default.grayDf};
  width: 100%;
  box-sizing: border-box;
  font-weight: 600;
`;

export const DeliveryAddressSelected = styled.div`
  width: 100%;
  min-height: 204px;
  border: 1px solid ${themes.default.grayBf};
  background: ${themes.default.grayFa};
  font-size: 16px;
  border-radius: 4px;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  line-height: 20px;
  position: relative;
  margin-bottom: 5px;
`;

export const NoneDeliveryAddressSelected = styled(DeliveryAddressSelected)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeliveryAddressChange = styled.button`
  border: none;
  background-color: transparent;
  font-weight: 500;
  text-align: right;
  letter-spacing: 0.5px;
  cursor: pointer;
  color: ${themes.default.blue};
  margin-top: 4px;
`;

export const ImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  min-width: 231px;
  overflow: auto;
`;

export const ImageWrapper = styled.div<{ isWorkshop?: boolean }>`
  position: relative;
  display: flex;
  width: 46%;
  border: 1px solid ${themes.default.grayBf};
  border-radius: 4px;
  margin: ${(props: any) => (props.isWorkshop ? '0 8px 8px' : '0 8px 8px')};
`;

export const Image = styled.img<{ isWorkshop?: boolean }>`
  max-width: 100%;
  object-fit: cover;
  width: 200px;
  margin: ${(props: any) => (props.isWorkshop ? `0;` : `0 16px;`)};
  max-width: ${(props: any) => (props.isWorkshop ? `` : `416px;`)};
  height: ${(props: any) => (props.isWorkshop ? `196px;` : `200px;`)};
`;

export const RemoveImageWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 21px;
  width: 21px;
  background-color: ${themes.default.grayA9a1};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RemoveFile = styled(MdClose)`
  color: ${themes.default.grayA9a1};
  min-width: 15px;
  width: 15px;
  height: 15px;
`;

export const RemoveImage = styled(MdClose)`
  color: black;
  min-width: 15px;
  width: 15px;
  height: 15px;
`;

export const DropzoneFileWrapper = styled.div`
  display: flex;
`;

export const DropzoneFileName = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  background-color: ${themes.default.gray9a};
  color: ${themes.default.white};
  border-radius: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  font-weight: 900;
`;

export const AttachementInfo = styled.div`
  margin: -7px 0 8px 0px;
  font-size: 14px;
  color: ${themes.default.gray9a};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const TimeRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
  cursor: pointer;
  height: 40px;
`;

export const Radio = styled.div`
  border: 1px solid ${themes.default.gray9a};
  width: 13px;
  height: 13px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RadioBullet = styled.div<{ isActive?: boolean }>`
  background-color: ${(props: any) => (props.isActive ? themes.default.gray9a : 'transparent')};
  width: 7px;
  height: 7px;
  border-radius: 100%;
`;

export const RadioLabel = styled(InputUnit)``;

export const DropBoxWrapper = styled.div<{ isWorkshop?: boolean }>`
  min-width: 196px;
  height: 196px;
  border: 1px solid ${themes.default.grayDf};
  border-radius: 3px;
  background-color: ${themes.default.grayF8};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props: any) =>
    props.isWorkshop
      ? ` 
    margin: 0 auto 0 0;
    `
      : `
    margin: 0 auto;
    `};
  :hover {
    outline: none;
    box-shadow: 0 0 2px 1px ${themes.default.blue};
  }
`;

export const DropBoxAddButton = styled.button.attrs({ type: 'button' })`
  border: none;
  width: 42px;
  height: 42px;
  border-radius: none;
  background-color: ${themes.default.bluishGray};
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px ${themes.default.blue};
  }
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  border-radius: 100%;
`;

export const DropBoxPlus = styled(AiOutlinePlus)`
  width: 20px;
  height: 20px;
`;

export const ErrorMessage = styled.div`
  letter-spacing: 0.14px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: ${themes.default.red};
  border: 1px solid ${themes.default.red};
  border-radius: 4px;
  padding: 8px 16px;
  margin: 0 auto;
`;

export const MobileWrapper = styled.div`
  width: 100%;
`;

export const SubmitButton = styled.button`
  background-color: ${themes.default.blue};
  color: ${themes.default.white};
  width: 196px;
  height: 48px;
  border-radius: 4px;
  letter-spacing: 0.14px;
  text-align: center;
  font-size: 14px;
  border: none;
  font-weight: 800;
  cursor: ${(props: any) => (props.disabled ? 'auto' : 'pointer')};
`;

export const PreviewButton = styled(SubmitButton)`
  color: ${themes.default.blue};
  background-color: rgb(0, 119, 223, 0.3);
  margin-right: 20px;
  @media (max-width: 510px) {
    margin-right: 0;
    margin-bottom: 12px;
  }
`;

export const FlexRight = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 510px) {
    justify-content: space-around;
  }
`;

export const DeliveryTypeWrapper = styled.div`
  margin-top: 40px;
`;

export const DeliveryHeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid ${themes.default.grayEf};
  margin-bottom: 10px;
`;

export const DeliveryHeader = styled.div`
  letter-spacing: 0.14px;
  color: ${themes.default.grey33};
  font-size: 14px;
  font-weight: 500;
`;

export const DeliveryOpenModal = styled(DeliveryHeader)`
  cursor: pointer;
  color: ${themes.default.blue};
`;

export const DeliveryTableWrapper = styled.div``;

export const DeliveryTableHeaderWrapper = styled.div`
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${themes.default.grey33};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DeliveryTableHeaderOne = styled.div`
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 1.2px;
  color: ${themes.default.grey33};
  text-transform: uppercase;
  width: fit-content;
  padding-left: 44px;
`;

export const DeliveryTableHeaderTwo = styled(DeliveryTableHeaderOne)``;

export const DeliveryTableRowWrapper = styled.div<{ isDisplayed: boolean }>`
  align-items: center;
  transition: transform ease-out 0.3s;
  display: ${(props: any) => (props.isDisplayed ? 'flex' : 'none')};
  padding: 6px 8px;
`;

export const DeliveryArrow = styled(MdKeyboardArrowRight)<{isRotated?: boolean}>`
  width: 24px;
  height: 24px;
  transition: all 0.2s;
  transform: ${({isRotated}) => isRotated ? 'rotate(90deg)' : 'rotate(0deg)'};
  cursor: pointer;
`;

export const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeliveryTypeName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 326px;
  width: 100%;
  margin-right: auto;
  font-size: 16px;
  letter-spacing: 0px;
  color: ${themes.default.grey33};
  margin-left: 12px;
`;

export const DeliveryTypePrice = styled(DeliveryTypeName)`
  width: 100px;
  text-align: end;
  margin-right: 0;
`;

export const DeliveryTypeCheckBox = styled.div<{ isSelected: boolean }>`
  min-width: 20px;
  height: 20px;
  border: 1px solid ${themes.default.gray9a};
  border-radius: 4px;
  background-color: ${(props: any) =>
    props.isSelected ? themes.default.blue : themes.default.grayE2};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Tick = styled(MdCheck)`
  color: ${themes.default.white};
  width: 20px;
  height: 20px;
`;

export const DeliveryTypeDescription = styled.div`
  font-size: 12px;
  letter-spacing: 0.12px;
  color: ${themes.default.grey33};
  width: 100%;
  padding-left: 30px;
  box-sizing: border-box;
  min-height: 34px;
  padding-top: 6px;
`;

export const DeliveryDoubleRow = styled.div`
  width: 100%;
  border-bottom: 1px solid ${themes.default.grayEf};
  padding-bottom: 4px;
`;

export const FilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PlainButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const EmptyDeliveryAddress = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const BackTopWrapper = styled.div`
  padding: 0 15px;
  width: 170px;
  height: 65px;
  background-color: #000000;
  border-radius: 32px;
  display: flex;
  position: fixed;
  right: 20px;
  bottom: 5%;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
`;

export const BackText = styled.div`
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
`;

export const TextAreaDescription = styled.div`
  font-size: 11px;
  color: #333;
  text-align: left;
  margin-top: 10px;
  width: 470px;
`;

export const TextAreaUtilsWrapper = styled.div`
  display: flex;
`;

export const UploadFilesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const SetFiles = styled.div`
  text-decoration: underline;
  color: #333333;
  font-size: 14px;
  font-family: Inter;
  cursor: pointer;
`;

export const Upload = styled(SetFiles)`
  align-self: flex-end;
`;
