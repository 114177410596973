import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addUserPrinter } from '../../../redux/reducers/auth/actions';
import Dropzone from '../../CreateProduct/Inputs/Dropzone';
import Input, { INPUT_TYPE } from '../../CreateProduct/Inputs/Input';
import Selector, { SELECTOR_TYPE } from '../../CreateProduct/Inputs/Selector';
import { Option } from '../../../models/common';
import {
  isPrinterLoading,
  useLoggedInUser,
  usePostError,
} from '../../../redux/reducers/auth/selectors';
import { useUserProfile } from '../../../redux/reducers/userProfiles';
import * as S from './DeviceModal.style';
import { allTechnologies, utilsSelectedDevice } from '../../../redux/reducers/utils/selectors';
import { setSelectedDevice, toggleModal } from '../../../redux/reducers/utils/action';
import { MODAL_NAME } from '../../../redux/reducers/utils/models';
import { editWorkshopDevice } from '../../../redux/actions';
import Loader from 'react-spinners/ClipLoader';

type File = {
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
};

const equipmentTypes = [
  { value: true, label: 'Printer' },
  { value: false, label: 'Other' },
];

const DeviceModal = () => {
  const dispatch = useDispatch();
  const { id: userId } = useSelector(useLoggedInUser);
  const { isError, message } = useSelector(usePostError);
  const technologies = useSelector(allTechnologies);
  const isLoading = useSelector(isPrinterLoading);
  const {
    workshop: { printers, other },
  } = useUserProfile(userId);
  const { id: selectedDeviceId, isPrinter: isSelectedDeviceAPrinter } = useSelector(
    utilsSelectedDevice
  );
  const workshopSection = isSelectedDeviceAPrinter ? printers : other;
  const selectedDevice = workshopSection.find((device: any) => device.id === selectedDeviceId);
  const deviceName = selectedDevice ? selectedDevice.name : '';
  const devicePrinterType = selectedDevice ? (selectedDevice.printerType as string) : '';
  const devicePrinterTypeId = selectedDevice ? (selectedDevice.id as string) : '';
  const [name, setName] = useState(deviceName);
  const [equipmentType, setEquipmentType] = useState<Option>({
    value: true,
    label: 'Printer',
  });
  const [otherType, setOtherType] = useState('');
  const [printerType, setPrinterType] = useState<Option>({
    value: devicePrinterTypeId,
    label: devicePrinterType,
  });
  const title = selectedDeviceId ? 'Edit Equipment' : 'New Equipment';
  const isPrinter = equipmentType.value;
  const handleClose = () => {
    setName('');
    setPrinterType({ value: '', label: '' });
    setEquipmentType({ value: true, label: 'Printer' });
    setImagesData([]);
    dispatch(setSelectedDevice('', true));
    dispatch(toggleModal(MODAL_NAME.EDIT_DEVICE));
  };

  const handleApply = () => {
    const formData = new FormData();
    imagesData.forEach((file: File) => {
      formData.append(file.webkitRelativePath, (file as unknown) as Blob);
    });
    if (selectedDeviceId) {
      formData.append(
        'printRequestData',
        JSON.stringify({
          id: selectedDeviceId,
          name,
          printerType: isPrinter ? printerType.label : otherType,
          isPrinter,
        })
      );
      //@ts-ignore
      formData.append('file', imagesData);
      dispatch(editWorkshopDevice(formData));
      handleClose();
      return;
    }
    formData.append(
      'printRequestData',
      JSON.stringify({
        name,
        isPrinter: isPrinter,
        printerType: isPrinter ? printerType.label : otherType,
      })
    );
    //@ts-ignore
    formData.append('file', imagesData);
    dispatch(addUserPrinter(formData));
  };
  const [imagesData, setImagesData] = useState<File[]>([]);

  const handleSetImages = (files: File[]) => {
    setImagesData(files);
  };

  const printingTechnologyOptions: Option[] = technologies.map(({ id, name }: any) => ({
    value: id,
    label: name,
  }));

  const printerTypeLabel = isPrinter ? printerType.label : '';
  return (
    <S.ExternalWrapper>
      <S.Wrapper>
        <S.TopWrapper>
          <S.Title>{title}</S.Title>
          <S.Close onClick={handleClose} />
        </S.TopWrapper>
        <S.BodyWrapper>
          <Dropzone
            label={'Image'}
            uploadType={'image or photo'}
            setFiles={handleSetImages}
            showThumbnails
            maxItems={1}
            isOptional
            isWorkshop={true}
          />
          <Input
            value={name}
            label={'Name'}
            handleInputChange={(val: any) => setName(val)}
            type={INPUT_TYPE.LONG}
          />
          <S.InputWrapper>
            <S.Label>Equipment type</S.Label>
            <Selector
              selected={equipmentType.label}
              options={equipmentTypes}
              handleOptionSelect={(e: Option) => setEquipmentType(e)}
              type={SELECTOR_TYPE.MODERATE}
              placeholder={equipmentType.label}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            {isPrinter ? (
              <>
                <S.Label>
                  Printer type <S.Red>*</S.Red>
                </S.Label>
                <Selector
                  required
                  selected={printerTypeLabel}
                  options={printingTechnologyOptions}
                  handleOptionSelect={(e: Option) => setPrinterType(e)}
                  type={SELECTOR_TYPE.LONG}
                />
              </>
            ) : (
              <Input
                value={otherType}
                valueType="text"
                label={'Device'}
                handleInputChange={(e: string) => setOtherType(e)}
                type={INPUT_TYPE.LONG}
              />
            )}
          </S.InputWrapper>
          {<S.ErrorMessage>{isError && message}</S.ErrorMessage>}
        </S.BodyWrapper>
        <S.BottomWrapper>
          <S.CloseButton onClick={handleClose}>Cancel</S.CloseButton>
          <S.NextButton disabled={isLoading} onClick={handleApply}>
            {isLoading ? <Loader /> : 'Add equipment'}
          </S.NextButton>
        </S.BottomWrapper>
      </S.Wrapper>
    </S.ExternalWrapper>
  );
};

export default memo(DeviceModal);
