import styled from 'styled-components';
import U from '../../Assets/commonCSS';
import themes from '../../Assets/colorThemes';

export const Wrapper = styled.div<{ isHelpCenter?: boolean }>`
  display: flex;
  padding-top: 32px;
  background-color: ${({ isHelpCenter }) => (isHelpCenter ? 'white' : themes.default.grayEf)};
  min-height: 97vh;
  @media (max-width: 1096px) {
    flex-direction: column;
  }
  @media (max-width: 1175px) {
    margin-top: 70px;
  }
  @media (max-width: 745px) {
    margin-top: 100px;
  }
`;

export const LeftFill = styled.div`
  background-color: ${themes.default.grayEf};
  flex-grow: 1;
  z-index: ${U.zIndex_2};
  @media (max-width: 1096px) {
    display: none;
  }
`;

export const RightFill = styled.div`
  background-color: transparent;
  flex-grow: 1;
  z-index: ${U.zIndex_2};
  @media (max-width: 1096px) {
    display: none;
  }
`;

export const InnerWrapper = styled.div`
  max-width: 1276px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  height: auto;
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const MenuWrapper = styled.div`
  width: 34%;
  height: 100%;
  @media (max-width: 1096px) {
    width: 100%;
  }
`;

export const InnerMenuWrapper = styled.div`
  max-width: 326px;
  height: 100%;
  margin-left: auto;
  width: 100%;
  @media (max-width: 1096px) {
    max-width: 100%;
  }
`;

export const BodyWrapper = styled.div`
  width: 66%;
  height: 100%;
  @media (max-width: 1096px) {
    width: 100%;
  }
`;
