import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

export const ExternalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  :focus {
    outline: none;
  }
`;

export const Wrapper = styled.div`
  max-width: 448px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  max-height: 712px;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 16px;
  :focus {
    outline: none;
  }
`;

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 66px;
  border-bottom: 1px solid #dbdbdb;
`;

export const Title = styled.div`
  margin: 24px 8px 0 18px;
  font-weight: 500;
  font-size: 25px;
  color: #4D4D4D;
`;

export const RedTitle = styled(Title)`
  color: #db0000;
`;

export const Close = styled(MdClose)`
  font-size: 28px;
  margin-top: 23px;
  margin-right: 12px;
  color: #4D4D4D;
  cursor: pointer;
  height: 28px;
  width: 28px;
`;

export const LetterCounter = styled.div`
  word-spacing: 0.15px;
  text-align: right;
  flex-grow: 1;
  color: #4D4D4D;
`;

export const BodyWrapper = styled.div`
  color: #4D4D4D;
  box-sizing: border-box;
  padding: 30px 16px 20px;
  flex-grow: 1;
`;

export const Description = styled.div`
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #333333;
  box-sizing: border-box;
`;

export const SoloDescription = styled.div`
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #333333;
  box-sizing: border-box;
  padding: 0 0 20px;
`;

export const BottomWrapper = styled.div`
  border-top: 1px solid #dbdbdb;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: none;
  height: 89px;
  padding: 0px 18px;
  border-top: 1px solid #f4f4f4;
`;

export const BasicButton = styled.button`
  font-size: 14px;
  letter-spacing: 0.3px;
  font-weight: 600;
  border-radius: 3px;
  cursor: pointer;
  width: 200px;
  height: 48px;
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px #0077df;
  }
`;

export const CloseButton = styled(BasicButton)`
  color: #4D4D4D;
  background-color: transparent;
  border: 0;
  height: 48px;
  width: 101px;
`;

export const NextButton = styled(BasicButton)`
  background-color: #0077df;
  border-radius: 4px;
  border: none;
  color: #0077df;
  margin-left: 15px;
  height: 48px;
  width: 200px;
  left: 15px;
  color: #ffffff;
`;

export const CoolButton = styled(NextButton)`
  width: 100%;
  margin: 0;
`;

export const InputWrapper = styled.div`
  padding-bottom: 19px;
  min-height: 82px;
  box-sizing: border-box;
`;

export const Label = styled.div`
  font-weight: 700;
  font-size: 15px;
  padding-bottom: 4px;
`;

export const DeleteAccountBottomWrapper = styled(BottomWrapper)`
  flex-direction: column;
  padding: 20px 16px;
`;

export const DeleteAccountButton = styled.button`
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 4px;
  background-color: rgb(0, 119, 223, 0.3);
  color: #0077df;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.14px;
  cursor: pointer;
  :focus {
    outline: none;
  }
`;

export const Red = styled.span`
  color: red;
`;

export const ErrorMessage = styled.div`
  color: #db0000;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  height: 20px;
  min-width: 4px;
`;
