import * as api from '../../../api/common-api';
import {
  ADD_FREE_OFS,
  ADD_FREE_OFS_FIALURE,
  ADD_FREE_OFS_SUCCESS,
  ADD_OFS_PACKAGE,
  ADD_OFS_PACKAGE_FAIL,
  ADD_OFS_PACKAGE_SUCCESS,
  CREATE_OBJECT_FOR_SELL,
  GET_FEATURED_OBJECTS_FOR_SELL,
  GET_FEATURED_OBJECTS_FOR_SELL_FAILURE,
  GET_FEATURED_OBJECTS_FOR_SELL_SUCCESS,
  GET_USER_OBJECTS_FOR_SELL,
  GET_USER_OBJECTS_FOR_SELL_FAILURE,
  GET_USER_OBJECTS_FOR_SELL_SUCCESS,
  GET_VIEWED_OBJECT_FOR_SELL,
  GET_VIEWED_OBJECT_FOR_SELL_FAILURE,
  GET_VIEWED_OBJECT_FOR_SELL_SUCCESS,
  PREPARE_OBJECT_FOR_SELL_DETAILS,
  PREPARE_OBJECT_FOR_SELL_DETAILS_SUCCESS,
  REMOVE_OFS_COMMENT,
  REMOVE_OFS_COMMENT_FAILURE,
  REMOVE_OFS_COMMENT_SUCCESS,
  SET_OBJECT_FOR_SELL_SUBMIT_ERROR,
  SET_SUBMITTING_OBJECT_FOR_SELL,
  UPDATE_OFS_PACKAGES,
  UPDATE_OFS_PACKAGES_SUCCESS,
} from './actionTypes';
import { history } from '../../../App';
import _get from 'lodash/get';
import { toggleModal } from '../utils/action';
import { MODAL_NAME } from '../utils/models';
import { PACKAGE_STATUS } from '../../../models/package';

export function getUserObjectsForSell(userId: string, productId: string) {
  return function (dispatch: any) {
    dispatch({ type: GET_USER_OBJECTS_FOR_SELL });
    api.getUsersObjectsForSell(userId, productId).then(
      (response: any) => {
        dispatch({ type: GET_USER_OBJECTS_FOR_SELL_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: GET_USER_OBJECTS_FOR_SELL_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
}

export function getFeaturedObjectsForSell(productId: string) {
  return function (dispatch: any) {
    dispatch({ type: GET_FEATURED_OBJECTS_FOR_SELL });
    api.getFeaturedObjectsForSell(productId).then(
      (response: any) => {
        dispatch({ type: GET_FEATURED_OBJECTS_FOR_SELL_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: GET_FEATURED_OBJECTS_FOR_SELL_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
}

export function getViewedObjectForSell(productId: string) {
  return function (dispatch: any) {
    dispatch({ type: GET_VIEWED_OBJECT_FOR_SELL });
    api.getViewedObjectForSell(productId).then(
      (response: any) => {
        dispatch({ type: GET_VIEWED_OBJECT_FOR_SELL_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: GET_VIEWED_OBJECT_FOR_SELL_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
}
export const setSubmittingObjectForSell = (isSubmitting: boolean) => ({
  type: SET_SUBMITTING_OBJECT_FOR_SELL,
  isSubmitting,
});

export const setObjectForSellSubmitErrorMessage = (errorMessage: string) => ({
  type: SET_OBJECT_FOR_SELL_SUBMIT_ERROR,
  errorMessage,
});

export function createObjectForSell(data: any) {
  return function (dispatch: any) {
    return api.createObjectForSell(data).then(
      (response: any) => {
        dispatch({ type: CREATE_OBJECT_FOR_SELL, data: response });
        // navigate(`/object-for-sell/${response.result.id}`);
        // TODO
        dispatch(toggleModal(MODAL_NAME.CREATE_PRODUCT));
        dispatch(setSubmittingObjectForSell(false));
      },
      (error: any) => {
        const errorMessage = _get(error, 'response.data', 'Unexpected error');
        if (typeof errorMessage === 'string') {
          dispatch(setObjectForSellSubmitErrorMessage(errorMessage));
        } else {
          dispatch(setObjectForSellSubmitErrorMessage('Unexpected error'));
        }
        dispatch(setSubmittingObjectForSell(false));
        console.log('An error occurred.', error);
      }
    );
  };
}

export function prepareObjectForSellDetails(productId: string) {
  return async function (dispatch: any) {
    dispatch({ type: PREPARE_OBJECT_FOR_SELL_DETAILS });
    const viewedObjectForSell = await api.getViewedObjectForSell(productId);
    const {
      user: { id },
    } = viewedObjectForSell;
    const featuredObjectsForSell = await api.getFeaturedObjectsForSell(productId);
    const userObjectsForSell = await api.getUsersObjectsForSell(id, productId);

    const data = {
      viewedObjectForSell,
      featuredObjectsForSell,
      userObjectsForSell,
    };
    dispatch({ type: PREPARE_OBJECT_FOR_SELL_DETAILS_SUCCESS, data });
  };
}

export const addObjectPackage = (params: { trackingNo: string; purchaseId: string }) => {
  return function (dispatch: any) {
    dispatch({ type: ADD_OFS_PACKAGE });
    api.addObjectPackage(params).then(
      (response: any) =>
        dispatch({
          type: ADD_OFS_PACKAGE_SUCCESS,
          packages: response,
        }),
      (error: any) => {
        dispatch({ type: ADD_OFS_PACKAGE_FAIL });
        console.log('error has occured', error);
      }
    );
  };
};

export const updateObjectPackages = (
  params: { packageId: string; trackingNo?: string; status?: PACKAGE_STATUS }[]
) => {
  return function (dispatch: any) {
    dispatch({ type: UPDATE_OFS_PACKAGES });
    api.updatePackages(params).then(
      (response: any) => dispatch({ type: UPDATE_OFS_PACKAGES_SUCCESS, packages: response }),
      (error: any) => {
        dispatch({ type: UPDATE_OFS_PACKAGES_SUCCESS });
        console.log('error has occured', error);
      }
    );
  };
};

export function removeOFSComment(productId: string, commentId: string) {
  return function (dispatch: any) {
    dispatch({ type: REMOVE_OFS_COMMENT });
    api.removeComment(commentId).then(
      (response: any) => {
        dispatch({ type: REMOVE_OFS_COMMENT_SUCCESS, commentId, productId });
      },
      (error: any) => {
        dispatch({ type: REMOVE_OFS_COMMENT_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
}

export function addFreeObjectforSell(id: string, quantity: string, addressId: string, deliveryTypeId: string) {
  return function (dispatch: any) {
    dispatch({ type: ADD_FREE_OFS });
    api.addFreeObjectforSell( id,quantity,addressId, deliveryTypeId).then(
      (response: any) => {
        dispatch({ type: ADD_FREE_OFS_SUCCESS, data: response });
        // navigate('');
        // TODO
      },
      (error: any) => {
        dispatch({ type: ADD_FREE_OFS_FIALURE });
        console.log('Error has occured', error);
      }
    );
  };
}

export function hasObjectPendingPayment(id: string, setter: React.Dispatch<React.SetStateAction<boolean>> ) {
  return function (dispatch: any) {
    api.hasPendingPayment( id).then(
      (response: any) => {
        setter(response)
      },
      (error: any) => {
        console.log('Error has occured', error);
      }
    );
  };
}