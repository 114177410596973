import { AnyAction } from 'redux';
import { UserProfile } from '../../models/market';
import {
  SET_SELECTED_PHOTO_ID,
  GET_USER_DATA_SUCCESS,
  ADD_USER_PRINTER_SUCCESS,
  GET_GALLERY_DATA,
  GALLERY_ADDED,
  GALLERY_ADDED_SUCCESS,
  GALLERY_UPDATED_SUCCESS,
  GALLERY_DELETED_SUCCESS,
  SET_GALLERIES,
  SET_WORKSHOP_DEVICES,
  GET_USER_DATA_START,
  GET_USER_DATA_FAILURE,
  ADD_USER_PRINTER_FAILURE,
  ADD_USER_PRINTER,
} from '../actionTypes';
import { useSelector } from 'react-redux';
import { AppState } from '.';
import { USER_PROFILE_SECTION } from '../../models/user';
import moment from 'moment';
import { Gallery, GalleryWithPhotos, Photo } from '../../models/photos';
import { ADD_USER_PRINTER_ERROR } from './auth/actionTypes';

export interface UserProfilesState {
  users: { [key: string]: UserProfile };
  isLoadingGallery: boolean;
  isLoadingUserData: boolean;
  isLoadingPrinter: boolean;
}

const initialState = {
  users: {},
  isLoadingGallery: false,
  isLoadingUserData: false,
  isLoadingPrinter: false,
};

const emptyUserData = {
  name: '',
  surname: '',
  nickname: '',
  rating: 0,
  userId: '',
  addresses: [],
  isAdmin: false,
  offers: [],
  about: '',
  market: {
    printRequests: [],
    printedObjects: [],
    objectDesigns: [],
    designsForSell: [],
  },
  reviews: [],
  photos: {
    galleries: [],
  },
  workshop: {
    printers: [],
    other: [],
  },
};

export const useUserProfile = (userId: string) => {
  return useSelector((state: AppState) => state.userProfiles.users[userId] || emptyUserData);
};

export const useLoadingUserProfileData = () => {
  return useSelector((state: AppState) => state.userProfiles.isLoadingUserData || false);
};

export default function (state = initialState, action: AnyAction): UserProfilesState {
  switch (action.type) {
    case GALLERY_ADDED: {
      return {
        ...state,
        isLoadingGallery: true,
      };
    }
    case GALLERY_ADDED_SUCCESS: {
      const { data, userId } = action.data;
      // @ts-ignore
      return {
        ...state,
        isLoadingGallery: false,
        users: {
          ...state.users,
          [userId]: {
            // @ts-ignore
            ...state.users[userId],
            photos: { galleries: data },
          },
        },
      };
    }
    case GALLERY_UPDATED_SUCCESS: {
      const { galleries, userId } = action.data;
      const sortedGalleries = galleries.sort((a: Gallery, b: Gallery) => {
        const dateA = moment(a.createdAt),
          dateB = moment(b.createdAt);
        return dateA.diff(dateB);
      });
      return {
        ...state,
        users: {
          ...state.users,
          [userId]: {
            // @ts-ignore
            ...state.users[userId],
            photos: { galleries: sortedGalleries },
          },
        },
      };
    }
    case GALLERY_DELETED_SUCCESS: {
      const { galleries, userId } = action.data;
      const sortedGalleries = galleries.sort((a: Gallery, b: Gallery) => {
        const dateA = moment(a.createdAt),
          dateB = moment(b.createdAt);
        return dateA.diff(dateB);
      });
      return {
        ...state,
        users: {
          ...state.users,
          [userId]: {
            // @ts-ignore
            ...state.users[userId],
            photos: { galleries: sortedGalleries },
          },
        },
      };
    }
    case GET_GALLERY_DATA: {
      const { userId } = action.data;
      return {
        ...state,
        // @ts-ignore
        users: {
          ...state.users,
          [userId]: {
            // @ts-ignore
            ...state.users[userId],
            photos: {
              // @ts-ignore
              ...state.users[userId].photos,
            },
          },
        },
      };
    }
    case SET_GALLERIES: {
      const { userId } = action.data;
      const galleries = action.data.galleries ? action.data.galleries : [];
      const sortedGalleries = reasignPhotosToGallery(galleries);
      return {
        ...state,
        // @ts-ignore
        users: {
          ...state.users,
          [userId]: {
            // @ts-ignore
            ...state.users[userId],
            galleries: sortedGalleries,
          },
        },
      };
    }
    case SET_WORKSHOP_DEVICES: {
      const { userId, workshop } = action.data;
      return {
        ...state,
        // @ts-ignore
        users: {
          ...state.users,
          [userId]: {
            // @ts-ignore
            ...state.users[userId],
            workshop,
          },
        },
      };
    }
    case ADD_USER_PRINTER: {
      return {
        ...state,
        isLoadingPrinter: true,
      };
    }
    case ADD_USER_PRINTER_SUCCESS: {
      const { userId, workshop } = action.data;
      let data = {
        ...emptyUserData,
        //@ts-ignore
        ...state.users[userId],
      };
      data = {
        ...data,
        workshop,
      };
      return {
        ...state,
        isLoadingPrinter: false,
        users: {
          [userId]: {
            ...data,
          },
        },
      };
    }
    case ADD_USER_PRINTER_FAILURE: {
      return {
        ...state,
        isLoadingPrinter: false,
      };
    }
    case SET_SELECTED_PHOTO_ID: {
      const { userId } = action.data;
      return {
        ...state,
        users: {
          ...state.users,
          [userId]: {
            // @ts-ignore
            ...state.users[userId],
            photos: {
              // @ts-ignore
              ...state.users[userId].photos,
            },
          },
        },
      };
    }
    case GET_USER_DATA_SUCCESS: {
      const { userId, flag } = action.data;
      let temporaryState = {
        ...emptyUserData,
        //@ts-ignore
        ...state.users[userId],
      };
      if (flag === 'workshop') {
        temporaryState = {
          ...temporaryState,
          workshop: action.data.workshop,
        };
      } else if (flag === USER_PROFILE_SECTION.OFFERS) {
        temporaryState = {
          ...temporaryState,
          offers: [...action.data.offers],
        };
      } else if (flag === USER_PROFILE_SECTION.MARKET) {
        temporaryState = {
          ...temporaryState,
          market: {
            printRequests: action.data.printRequests,
            printedObjects: action.data.objectsForSell,
            objectDesigns: action.data.designRequests,
            designsForSell: action.data.designsForSell,
          },
        };
      } else if (flag === USER_PROFILE_SECTION.PHOTOS) {
        const galleries = action.data.galleries ? action.data.galleries : [];
        const sortedGalleries = reasignPhotosToGallery(galleries);
        temporaryState = {
          ...temporaryState,
          photos: {
            galleries: sortedGalleries,
          },
        };
      } else if (flag === USER_PROFILE_SECTION.ABOUT) {
        const galleries = action.data.galleries ? action.data.galleries : [];
        const sortedGalleries = reasignPhotosToGallery(galleries);
        temporaryState = {
          ...temporaryState,
          ...action.data,
          photos: {
            galleries: sortedGalleries,
          },
          market: {
            printRequests: action.data.printRequests,
            printedObjects: action.data.objectsForSell,
            objectDesigns: action.data.designRequests,
            designsForSell: action.data.designsForSell,
          },
          workshop: action.data.workshop,
        };
      } else if (flag === USER_PROFILE_SECTION.REVIEWS) {
        temporaryState = {
          ...temporaryState,
          reviews: action.data.reviews,
        };
      }
      return {
        ...state,
        isLoadingUserData: false,
        users: {
          [userId]: {
            ...temporaryState,
          },
        },
      };
    }
    case GET_USER_DATA_START: {
      return {
        ...state,
        isLoadingUserData: true,
      };
    }
    case GET_USER_DATA_FAILURE: {
      return {
        ...state,
        isLoadingUserData: false,
      };
    }
    case ADD_USER_PRINTER_ERROR: {
      return {
        ...state,
        isLoadingPrinter: false,
      };
    }
    default:
      return state;
  }
}

const SortGalleries = (galleries: GalleryWithPhotos[]) => {
  const sortedGalleries = galleries.sort((a: Gallery, b: Gallery) => {
    const dateA = moment(a.createdAt),
      dateB = moment(b.createdAt);
    return dateA.diff(dateB);
  });
  return sortedGalleries;
};

const reasignPhotosToGallery = (galleries: GalleryWithPhotos[]) => {
  let reasignedGalleries: GalleryWithPhotos[] = [];
  galleries.map((gallery: GalleryWithPhotos) => {
    const galleryPhotos = sortPhotos(gallery);
    const reasignedGallery = { ...gallery, photos: galleryPhotos };
    reasignedGalleries.push(reasignedGallery);
  });
  const sortGalleries = SortGalleries(reasignedGalleries);
  return sortGalleries;
};

const sortPhotos = (gallery: GalleryWithPhotos) => {
  const { photos } = gallery;
  photos.sort((a: Photo, b: Photo) => {
    const dateA = moment(a.createdAt),
      dateB = moment(b.createdAt);
    return dateA.diff(dateB);
  });
  return photos;
};
