import fabLab from './fabLab.png';
import filamentum from './filamentum.png';
import printingCom from './printingCom.png';
import tevo from './tevo.png';
import thingiverse from './thingiverse.png';
import fiberlogy from './fiberlogy.jpg';
import theSpaghettiDetective from './the-spaghetti-detective.png';
import tmc from './TMC_LOGO.png';
import byFlow from './byFlow.png'

export default {
  fabLab,
  filamentum,
  printingCom,
  tevo,
  thingiverse,
  fiberlogy,
  theSpaghettiDetective,
  tmc,
  byFlow
};
