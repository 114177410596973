import styled from 'styled-components';
import { SLIDER_TYPE } from '../../Dashboard/constant';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import U from '../../Assets/commonCSS';

const SliderTopNormal = {
  [SLIDER_TYPE.FEATURED]: 127,
  [SLIDER_TYPE.COMPACT]: 78,
  [SLIDER_TYPE.WIDE]: 80,
  [SLIDER_TYPE.MARKET]: 78,
};

const SliderTopMobile = {
  [SLIDER_TYPE.FEATURED]: 11,
  [SLIDER_TYPE.COMPACT]: 7,
  [SLIDER_TYPE.WIDE]: 21,
  [SLIDER_TYPE.MARKET]: 7,
};

export const SLIDER_WIDTH_NORMAL = {
  [SLIDER_TYPE.FEATURED]: 297,
  [SLIDER_TYPE.COMPACT]: 194,
  [SLIDER_TYPE.WIDE]: 416,
  [SLIDER_TYPE.MARKET]: 194,
};

export const SLIDER_WIDTH_MOBILE = {
  [SLIDER_TYPE.FEATURED]: 20,
  [SLIDER_TYPE.COMPACT]: 15.4,
  [SLIDER_TYPE.WIDE]: 30,
  [SLIDER_TYPE.MARKET]: 15.4,
};

const ImgSizesNormal = {
  [SLIDER_TYPE.FEATURED]: `width: 295px; height: 286px;`,
  [SLIDER_TYPE.COMPACT]: `width: 195px; height: 195px;`,
  [SLIDER_TYPE.WIDE]: `width: auto; height: 210px;`, ///was 478px
  [SLIDER_TYPE.MARKET]: `width: 195px; height: 195px;`,
};

const ImgSizesMobile = {
  [SLIDER_TYPE.FEATURED]: `width: 20vw; height: 20vw;`,
  [SLIDER_TYPE.COMPACT]: `width: 15.3vw; height: 15.3vw;`,
  [SLIDER_TYPE.WIDE]: `width: 30vw; height: 11vw;`,
  [SLIDER_TYPE.MARKET]: `width: 15.3vw; height: 15.3vw;`,
};

export interface sliderItemProps {
  isFeatured?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
`;

const SlideGeneric = `
width: 41px;
height: 41px;
box-shadow: 0px 3px 4px #00000029;
border: 1px solid #BFBFBF;
background-color: #fff;
border-radius: 100%;
cursor: pointer;
z-index: ${U.zIndex1};
position: relative;
box-shadow: 0px 3px 4px #00000029;
border: 1px solid #BFBFBF;
display: flex;
justify-content: center;
align-items: center;
min-width: 41px;
`;

interface ISlide {
  sliderType: SLIDER_TYPE;
}
export const SlideLeft = styled.div<ISlide>`
  ${SlideGeneric}
  top: ${(props: ISlide) => SliderTopNormal[props.sliderType]}px;
  left: 3px;
  position: absolute;
  @media(max-width: 1306px) {
    top: ${(props: ISlide) => SliderTopMobile[props.sliderType]}vw;
  }
  `;

export const SlideRight = styled.div<ISlide>`
  ${SlideGeneric}
  top: ${(props: ISlide) => SliderTopNormal[props.sliderType]}px;
  left: -19px;
  position: absolute;
  @media(max-width: 1306px) {
    top: ${(props: ISlide) => SliderTopMobile[props.sliderType]}vw;
  }
`;

export const ArrowExternalWrapper = styled.div`
  min-width: 25px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const ArrowBack = styled(IoIosArrowBack)`
  min-width: 30px;
  height: 30px;
`;

export const ArrowForward = styled(IoIosArrowForward)`
  min-width: 30px;
  height: 30px;
`;

export const SliderWrapper = styled.div`
  display: flex;
  margin: auto;
  position: relative;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
`;

interface IContentWrapper {
  translate: number;
  width: number;
}
export const ContentWrapper = styled.div<IContentWrapper>`
  display: flex;
  transform: translateX(-${(props: IContentWrapper) => props.translate}px);
  transition: transform ease-out 0.45s;
  height: 100%;
  width: ${(props: any) => props.width}vw;
  @media (max-width: 1306px) {
    transform: translateX(-${(props: IContentWrapper) => props.translate}vw);
  }
` as any;

export const ItemThumbnailImage = styled.img`
  border-radius: 3px;
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

export const ItemThumbnailHotStatus = styled.div<sliderItemProps>`
  visibility: ${(props: sliderItemProps) => (props.isFeatured ? 'visible' : 'hidden')};
  font-size: 10px;
  background-color: #4d4d4d;
  color: white;
  letter-spacing: 0.75px;
  color: #e2e2e2;
  text-transform: uppercase;
  position: absolute;
  right: 10px;
  top: 16px;
  font-weight: 600;
  border-radius: 13px;
  width: 48px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IItemWrapper {
  type: SLIDER_TYPE;
}
export const ItemWrapper = styled.div<IItemWrapper>`
  min-width: ${(props: IItemWrapper) => SLIDER_WIDTH_NORMAL[props.type]}px;
  width: ${(props: IItemWrapper) => SLIDER_WIDTH_NORMAL[props.type]}px;
  margin-right: ${(props: IItemWrapper) => (props.type === SLIDER_TYPE.WIDE ? 0 : 10)}px;
  margin-bottom: 20px;
  box-sizing: border-box;
  overflow: hidden;
  @media (max-width: 1306px) {
    margin-right: ${(props: IItemWrapper) => (props.type === SLIDER_TYPE.WIDE ? 0 : 0.5)}vw;
    min-width: ${(props: IItemWrapper) => SLIDER_WIDTH_MOBILE[props.type]}vw;
    width: ${(props: IItemWrapper) => SLIDER_WIDTH_MOBILE[props.type]}vw;
  }
`;

export const EmptyItem = styled.div<IItemWrapper>`
  width: ${(props: IItemWrapper) => SLIDER_WIDTH_NORMAL[props.type]}px;
  height: 100%;
  margin-right: 20px;
`;

export const ItemThumbnailWrapper = styled.div<IItemWrapper>`
  position: relative;
  ${(props: IItemWrapper) => ImgSizesNormal[props.type]}
  overflow: hidden;
  margin-bottom: 8px;
  @media (max-width: 1306px) {
    ${(props: IItemWrapper) => ImgSizesMobile[props.type]}
  }
`;

export const ItemTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: -1;
`;

export const ItemTitle = styled.div`
  height: 38px;
  letter-spacing: 0.14px;
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 20px;
  width: 174px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

export const ItemSize = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #9a9a9a;
  min-width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 0 4px;
`;

export const ItemInternalSize = styled.div`
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
`;

export const ItemPricingWrapper = styled.div`
  display: flex;
  justify-content: baseline;
  align-items: flex-end;
  margin-bottom: 6px;
  flex-wrap: wrap;
`;
export const ItemPricingCost = styled.div`
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.16px;
  color: #4d4d4d;
`;

export const ItemPricingFor = styled.div`
  margin: 0 4px;
  font-size: 12px;
  letter-spacing: 0.12px;
  color: #4d4d4d;
`;

export const ItemPricingCopies = styled.div`
  font-size: 12px;
  letter-spacing: 0.12px;
  font-weight: 700;
  color: #4d4d4d;
`;

export const ItemDaysLeft = styled.div`
  color: #9a9a9a;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.12px;
`;

export const ItemDescription = styled.div``;

export const SimpleDescContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SimpleDesc = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin-bottom: 8px;
`;
