import { AnyAction } from 'redux';
import { Comment } from '../../../models/common';
import { updateCommentsAfterDelete } from '../../../utils/updateCommentsAfterDelete';
import { updateProductCommentsAfterLike } from '../../../utils/updateProductCommentsAfterLike';
import {
  GET_FEATURED_DESIGNS_FOR_SELL,
  GET_FEATURED_DESIGNS_FOR_SELL_FAILURE,
  GET_FEATURED_DESIGNS_FOR_SELL_SUCCESS,
  GET_USER_DESIGNS_FOR_SELL,
  GET_USER_DESIGNS_FOR_SELL_FAILURE,
  GET_USER_DESIGNS_FOR_SELL_SUCCESS,
  GET_VIEWED_DESIGN_FOR_SELL,
  GET_VIEWED_DESIGN_FOR_SELL_FAILURE,
  GET_VIEWED_DESIGN_FOR_SELL_SUCCESS,
  SET_DESIGN_FOR_SELL_SUBMIT_ERROR,
  SET_SUBMITTING_DESIGN_FOR_SELL,
  PREPARE_DESIGN_FOR_SELL_DETAILS_SUCCESS,
  SET_DFS_COMMENTS,
  SET_DFS_LIKE,
  ADD_FREE_DFS,
  ADD_FREE_DFS_SUCCESS,
  ADD_FREE_DFS_FAILURE,
  REMOVE_DFS_COMMENT_SUCCESS,
} from './actionTypes';
import { DesignForSell } from './models';

export interface DesignsForSellState {
  userDesignsForSell: DesignForSell[];
  isLoadingUserDesignsForSell: boolean;
  featuredDesignsForSell: DesignForSell[];
  isLoadingFeaturedDesignsForSell: boolean;
  viewedDesignForSell: DesignForSell | null;
  isLoadingViewedDesigForSell: boolean;
  isSubmitting: boolean;
  errorMessage: string;
  isAddingFreeDFS: boolean;
}

const initialState: DesignsForSellState = {
  userDesignsForSell: [],
  isLoadingUserDesignsForSell: false,
  featuredDesignsForSell: [],
  isLoadingFeaturedDesignsForSell: false,
  viewedDesignForSell: null,
  isLoadingViewedDesigForSell: false,
  isSubmitting: false,
  errorMessage: '',
  isAddingFreeDFS: false,
};

export default function (state = initialState, action: AnyAction): DesignsForSellState {
  switch (action.type) {
    case GET_USER_DESIGNS_FOR_SELL: {
      return {
        ...state,
        isLoadingUserDesignsForSell: true,
      };
    }
    case GET_USER_DESIGNS_FOR_SELL_SUCCESS: {
      return {
        ...state,
        isLoadingUserDesignsForSell: false,
        userDesignsForSell: action.data,
      };
    }
    case GET_USER_DESIGNS_FOR_SELL_FAILURE: {
      return {
        ...state,
        isLoadingUserDesignsForSell: false,
      };
    }
    case GET_FEATURED_DESIGNS_FOR_SELL: {
      return {
        ...state,
        isLoadingFeaturedDesignsForSell: true,
      };
    }
    case GET_FEATURED_DESIGNS_FOR_SELL_SUCCESS: {
      return {
        ...state,
        isLoadingFeaturedDesignsForSell: false,
        featuredDesignsForSell: action.data,
      };
    }
    case GET_FEATURED_DESIGNS_FOR_SELL_FAILURE: {
      return {
        ...state,
        isLoadingFeaturedDesignsForSell: false,
      };
    }
    case GET_VIEWED_DESIGN_FOR_SELL: {
      return {
        ...state,
        isLoadingViewedDesigForSell: true,
      };
    }
    case GET_VIEWED_DESIGN_FOR_SELL_SUCCESS: {
      return {
        ...state,
        isLoadingViewedDesigForSell: false,
        viewedDesignForSell: action.data,
      };
    }
    case GET_VIEWED_DESIGN_FOR_SELL_FAILURE: {
      return {
        ...state,
        isLoadingViewedDesigForSell: false,
      };
    }
    case SET_SUBMITTING_DESIGN_FOR_SELL: {
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };
    }
    case SET_DESIGN_FOR_SELL_SUBMIT_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    }
    case PREPARE_DESIGN_FOR_SELL_DETAILS_SUCCESS: {
      return {
        ...state,
        ...action.data,
      };
    }
    case SET_DFS_COMMENTS: {
      const { comments } = action;
      const { viewedDesignForSell } = state;
      if (!viewedDesignForSell) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        viewedDesignForSell: { ...viewedDesignForSell, comments },
      };
    }
    case SET_DFS_LIKE: {
      const { viewedDesignForSell } = state;
      if (!viewedDesignForSell) {
        return {
          ...state,
        };
      }
      const { comments } = viewedDesignForSell;
      const { like } = action;
      const { commentId } = like;
      const maybeComment = comments.find((comment: Comment) => comment.id === commentId);
      if (!maybeComment) {
        return {
          ...state,
        };
      }
      const updatedComments = updateProductCommentsAfterLike(comments, maybeComment, like);
      return {
        ...state,
        viewedDesignForSell: { ...viewedDesignForSell, comments: updatedComments },
      };
    }
    case ADD_FREE_DFS: {
      return {
        ...state,
        isAddingFreeDFS: true,
      };
    }
    case ADD_FREE_DFS_SUCCESS: {
      return {
        ...state,
        isAddingFreeDFS: false,
      };
    }
    case ADD_FREE_DFS_FAILURE: {
      return {
        ...state,
        isAddingFreeDFS: false,
      };
    }
    case REMOVE_DFS_COMMENT_SUCCESS: {
      const { viewedDesignForSell } = state;
      if (!viewedDesignForSell) {
        return {
          ...state,
        };
      }
      const { comments } = viewedDesignForSell;
      const { commentId } = action;
      const updatedComments = updateCommentsAfterDelete(comments, commentId);
      return {
        ...state,
        viewedDesignForSell: { ...viewedDesignForSell, comments: updatedComments },
      };
    }
    default:
      return state;
  }
}
