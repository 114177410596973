import * as api from '../../../api/common-api';
import {
  CREATE_PRINT_REQUEST_SUCCESS,
  GET_USER_PRINT_REQUESTS,
  GET_USER_PRINT_REQUESTS_FAILURE,
  GET_USER_PRINT_REQUESTS_SUCCESS,
  SET_PRINT_REQUEST_SUBMIT_ERROR,
  SET_SUBMITTING_PRINT_REQUEST,
  PREPARE_PRINT_REQUEST_DETAILS,
  PREPARE_PRINT_REQUEST_DETAILS_SUCCESS,
  GET_FEATURED_PRINT_REQUESTS,
  GET_FEATURED_PRINT_REQUESTS_SUCCESS,
  GET_FEATURED_PRINT_REQUESTS_FAILURE,
  GET_VIEWED_PRINT_REQUEST,
  GET_VIEWED_PRINT_REQUEST_SUCCESS,
  GET_VIEWED_PRINT_REQUEST_FAILURE,
  ADD_PR_PACKAGE,
  ADD_PR_PACKAGE_SUCCESS,
  ADD_PR_PACKAGE_FAIL,
  UPDATE_PR_PACKAGES,
  UPDATE_PR_PACKAGES_SUCCESS,
  UPDATE_PR_PACKAGES_FAIL,
  SET_UPDATED_PR_OFFERS,
  SET_UPDATED_PR_OFFERS_SUCCESS,
  SET_UPDATED_PR_OFFERS_FAILURE,
  REMOVE_PR_COMMENT,
  REMOVE_PR_COMMENT_SUCCESS,
  REMOVE_PR_COMMENT_FAILURE,
  UPLOAD_PR_FILES,
  UPLOAD_PR_FILES_SUCCESS,
  UPLOAD_PR_FILES_FAILURE,
  ADD_FREE_PR,
  ADD_FREE_PR_SUCCESS,
  ADD_FREE_PR_FAILURE,
} from './actionTypes';
import { history } from '../../../App';
import _get from 'lodash/get';
import { toggleModal } from '../utils/action';
import { MODAL_NAME } from '../utils/models';
import { PACKAGE_STATUS } from '../../../models/package';

export function getUserPrintRequests(userId: string, productId: string) {
  return function (dispatch: any) {
    dispatch({ type: GET_USER_PRINT_REQUESTS });
    api.getUsersPrintRequests(userId, productId).then(
      (response: any) => {
        dispatch({ type: GET_USER_PRINT_REQUESTS_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: GET_USER_PRINT_REQUESTS_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
}

export function getFeaturedPrintRequests(productId: string) {
  return function (dispatch: any) {
    dispatch({ type: GET_FEATURED_PRINT_REQUESTS });
    api.getFeaturedPrintRequests(productId).then(
      (response: any) => {
        dispatch({ type: GET_FEATURED_PRINT_REQUESTS_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: GET_FEATURED_PRINT_REQUESTS_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
}

export function getViewedPrintRequest(productId: string) {
  return function (dispatch: any) {
    dispatch({ type: GET_VIEWED_PRINT_REQUEST });
    api.getViewedPrintRequest(productId).then(
      (response: any) => {
        dispatch({ type: GET_VIEWED_PRINT_REQUEST_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: GET_VIEWED_PRINT_REQUEST_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
}
export const setSubmittingPrintRequest = (isSubmitting: boolean) => ({
  type: SET_SUBMITTING_PRINT_REQUEST,
  isSubmitting,
});

export const setPrintRequestSubmitErrorMessage = (errorMessage: string) => ({
  type: SET_PRINT_REQUEST_SUBMIT_ERROR,
  errorMessage,
});

export function createPrintRequest(data: any) {
  return function (dispatch: any) {
    return api.NEWCreatePrintRequest(data).then(
      (response: any) => {
        dispatch({ type: CREATE_PRINT_REQUEST_SUCCESS, data: response });
        // navigate(`/print-request/${response.result.id}`);
        history.push(`/print-request/${response.result.id}`);
        dispatch(toggleModal(MODAL_NAME.CREATE_PRODUCT));
      },
      (error: any) => {
        const errorMessage = _get(error, 'response.data', 'Unexpected error');
        if (typeof errorMessage === 'string') {
          dispatch(setPrintRequestSubmitErrorMessage(errorMessage));
        } else {
          dispatch(setPrintRequestSubmitErrorMessage('Unexpected error'));
        }
        dispatch(setSubmittingPrintRequest(false));
        console.log('An error occurred.', error);
      }
    );
  };
}

export function preparePrintRequestDetails(productId: string) {
  return async function (dispatch: any) {
    dispatch({ type: PREPARE_PRINT_REQUEST_DETAILS });
    const viewedPrintRequest = await api.getViewedPrintRequest(productId);
    const {
      user: { id },
    } = viewedPrintRequest;
    const featuredPrintRequests = await api.getFeaturedPrintRequests(productId);
    const userPrintRequests = await api.getUsersPrintRequests(id, productId);

    const data = {
      viewedPrintRequest,
      featuredPrintRequests,
      userPrintRequests,
    };
    dispatch({ type: PREPARE_PRINT_REQUEST_DETAILS_SUCCESS, data });
  };
}

export const addPrintPackage = (params: { trackingNo: string; printRequestId: string }) => {
  return function (dispatch: any) {
    dispatch({ type: ADD_PR_PACKAGE });
    api.addPrintPackage(params).then(
      (response: any) =>
        dispatch({
          type: ADD_PR_PACKAGE_SUCCESS,
          packages: response,
        }),
      (error: any) => {
        dispatch({ type: ADD_PR_PACKAGE_FAIL });
        console.log('error has occured', error);
      }
    );
  };
};

export const updatePrintPackages = (
  params: { packageId: string; trackingNo?: string; status?: PACKAGE_STATUS }[]
) => {
  return function (dispatch: any) {
    dispatch({ type: UPDATE_PR_PACKAGES });
    api.updatePackages(params).then(
      (response: any) => dispatch({ type: UPDATE_PR_PACKAGES_SUCCESS, packages: response }),
      (error: any) => {
        dispatch({ type: UPDATE_PR_PACKAGES_FAIL });
        console.log('error has occured', error);
      }
    );
  };
};

export function pickPROffer(offerId: string, responseMessage?: string) {
  return function (dispatch: any) {
    dispatch({ type: SET_UPDATED_PR_OFFERS });
    api.pickOffer({ offerId, responseMessage }).then(
      (response: any) => {
        dispatch({ type: SET_UPDATED_PR_OFFERS_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: SET_UPDATED_PR_OFFERS_FAILURE });
        console.log('An error occurred.', error);
      }
    );
  };
}

export function removePRComment(productId: string, commentId: string) {
  return function (dispatch: any) {
    dispatch({ type: REMOVE_PR_COMMENT });
    api.removeComment(commentId).then(
      (response: any) => {
        dispatch({ type: REMOVE_PR_COMMENT_SUCCESS, commentId, productId });
      },
      (error: any) => {
        dispatch({ type: REMOVE_PR_COMMENT_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
}

export function uploadPRFiles(uploadData: any) {
  return function (dispatch: any) {
    dispatch({ type: UPLOAD_PR_FILES });
    api.uploadPRFiles(uploadData).then(
      (response: any) => {
        dispatch({ type: UPLOAD_PR_FILES_SUCCESS, files: response });
      },
      (error: any) => {
        dispatch({ type: UPLOAD_PR_FILES_FAILURE });
        console.log('An error occurred.', error);
      }
    );
  };
}

export function addFreePrintRequest(id: string) {
  return function (dispatch: any) {
    dispatch({ type: ADD_FREE_PR });
    api.addFreePrintRequest(id).then(
      (response: any) => {
        dispatch({ type: ADD_FREE_PR_SUCCESS, data: response });
        // navigate('');
        history.push('/');
      },
      (error: any) => {
        dispatch({ type: ADD_FREE_PR_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
}