import styled from 'styled-components';
import themes from '../../Assets/colorThemes';

export const Wrapper = styled.div<{ simpleSearch?: boolean }>`
  max-width: 1296px;
  padding: 0 16px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto 40px;
  margin-top: ${(props: any) => (props.simpleSearch ? '16px' : '60px')};
`;

export const Title = styled.div<{ simpleSearch?: boolean }>`
  font-size: ${(props: any) => (props.simpleSearch ? '16px' : '24px')};
  margin-bottom: 0.4rem;
  font-weight: 500;
`;

export const SubTitle = styled.div`
  font-size: 14px;
  margin-bottom: 0.4rem;
  font-weight: 500;
  letter-spacing: 0.14px;
  color: ${themes.default.gray4d};
`;

export const Title2 = styled.div<{ simpleSearch?: boolean }>`
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: ${(props: any) => (props.simpleSearch ? '14px' : '')};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Bold = styled.span`
  font-weight: 800;
`;
