import moment from 'moment';
import React, { memo } from 'react';
import * as S from './Deadline.style';

interface DeadlineProps {
  deadline?: string;
  productDeadlineTag: string;
}

const Deadline = ({ deadline, productDeadlineTag }: DeadlineProps) => {
  const now = deadline ? moment(deadline).startOf('day') : '';
  const deadlineDays = now ? now.diff(moment().startOf('day'), 'days') : 0;
  const isExpired = deadlineDays < 0;
  const dayDaysText = deadlineDays === 1 ? 'day' : 'days';
  const deadlineText = isExpired
    ? 'Expired'
    : ` ${deadlineDays && deadlineDays >= 1 ? `${deadlineDays} ${dayDaysText}` : 'today'}`;
  return (
    <S.DeadlineWrapper>
      <S.Tag>{productDeadlineTag}</S.Tag>
      <S.Deadline>
        {deadlineText} ({moment(deadline).format('DD MMM YYYY')})
      </S.Deadline>
    </S.DeadlineWrapper>
  );
};

export default memo(Deadline);
