import { ValidFormProps, ValidFormResponse, IMAGE_EXTENSIONS } from '../types';

export const isFileValid = ({ uploadType, type, size }: ValidFormProps): ValidFormResponse => {
  let result = { errorMessage: '' };
  if (uploadType !== 'file' && !IMAGE_EXTENSIONS.includes(type)) {
    result.errorMessage = 'File format must be either png, jpg, webp or jpeg';
  }
  if (uploadType !== 'file' && size / 1024 / 1024 > 5) {
    result.errorMessage = 'File size exceeded the limit of 5MB';
  }
  return result;
};

export const getFormattedNumber = (val: string) => {
  // const is0Front = val && val.length > 1 && val[0] === '0';
  // const clearedVal = is0Front ? val.substring(1) : val;
  // console.log({clearedVal})
  // const splitByComma = clearedVal.split(',');
  // if (splitByComma.length === 2) {
  //   return `${splitByComma[0]},${splitByComma[1].slice(0, 2)}`;
  // }
  // const splitByDot = clearedVal.split('.');
  // if (splitByDot.length === 2) {
  //   return `${splitByDot[0]}.${splitByDot[1].slice(0, 2)}`;
  // }
  // console.log({val})
  return val;
};
