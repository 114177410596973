import React, { memo } from 'react';
import * as S from './WhatIsSection.styles';

const WhatIsData = [
  {
    header: 'Prototyping for everyone',
    body:
      'Our platform enables access to fast and reliable prototyping services for any project you are working on. Describe your needs and budget., post it at i-3d marketplace. and select the best offer. Selected user will execute your request.',
  },
  {
    header: 'Customized parts',
    body:
      'Order customized parts with defined parameters. Users with appropriete equipment and skills will reply to your request. Choose the best offer and have it done.',
  },
  {
    header: 'Network of skilled experts',
    body:
      'The idea of i-3d is to create global netrowk of skilled 3d printing enthusiasts who want to benefit of their skills, time and knowldege and engagement in 3d primtimg.. by enabling services on their own.',
  },
  {
    header: 'Environment and care',
    body:
      'We believe that 3d printing can help in balaced resource usage and it’s importrant technology in health, medical and envirionmental sector, we thus supprt nature protection ideas.',
  },
  {
    header: 'Unique B2B perspectives',
    body:
      'Flexibility of our platform is a feature that lets us create individual solutions for business and industrial sector as well. We are higly open for collaborations. and partnerships. If you we think we can work together, you are probably right.',
  },
  {
    header: 'Creative entertaiment',
    body:
      'Because 3D printing is always fun, we will organise challenges and events for our community where they can test and provve their skills...and who knows...maybe get some prize :)',
  },
  {
    header: 'Decentralized manufacturing around the globe',
    body:
      'Global network of 3d available 3d printers brings new fabrication opportunities and possibilities especially at distant or poor regions. Decentralized manufacturing is also a future of CO2 footprint reduction.',
  },
  {
    header: 'Monetize your skills',
    body:
      'i-3d allows to monetize your 3d printing passion in very easy way. Find request that fit your capabilities, deliver it and make money on 3d printing service without making your own comapny or simply sell your 3D projects.',
  },
];

const Data = () => WhatIsData.map((w, index) => {
  return (
    <S.TextContainer key={index}>
      <S.LittleHeader>{w.header}</S.LittleHeader>
      <S.Body>{w.body}</S.Body>
    </S.TextContainer>
  );
});

const WhatIsSection = () => {
  return (
    <S.ExternalWrapper>
      <S.Wrapper>
        <S.HeaderWrapper>
          <S.Header>What i-3d is all about?</S.Header>
        </S.HeaderWrapper>
        <S.Container>
        {Data()}
        </S.Container>
      </S.Wrapper>
    </S.ExternalWrapper>
  );
};

export default memo(WhatIsSection);
