import styled from 'styled-components';
import { BsChevronLeft } from 'react-icons/bs';
import { TiArrowForward } from 'react-icons/ti';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 80px;
  max-width: 1296px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 1175px) {
    padding-top: 130px;
  }
  @media (max-width: 745px) {
    padding-top: 210px;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

export const Splint = styled.div`
  width: 400px;
  box-sizing: border-box;
  @media (max-width: 1266px) {
    width: 100%;
    padding: 0 16px;
  }
`;

export const LeftSideWrapper = styled.div`
  position: sticky;
  top: 80px;
  @media (max-width: 1266px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Back = styled.div`
  grid-area: 'back';
  letter-spacing: 0.14px;
  font-size: 15px;
  font-weight: 700;
  color: #4D4D4D;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
  width: 100%;
`;

export const ArrowLeft = styled(BsChevronLeft)`
  opacity: 0.8;
  font-size: 24px;
  position: relative;
  left: -8px;
`;

export const BackText = styled.div`
  left: -4px;
  position: relative;
  font-size: 14px;
  cursor: pointer;
`;

export const BodyWrapper = styled.div`
  width: 830px;
  min-height: 100vh;
  padding-top: 48px;
  box-sizing: border-box;
  @media (max-width: 1266px) {
    width: 100%;
    padding: 0 16px;
  }
`;

export const TopWrapper = styled.div`
  border-bottom: 1px solid #efefef;
  width: 100%;
  height: 130px;
  @media (max-width: 500px) {
    margin-top: 12px;
  }
`;

export const Title = styled.div`
  font-size: 14px;
  margin-bottom: 2px;
  letter-spacing: 0.4px;
  display: flex;
`;

export const BoldTitle = styled.div`
  font-weight: 800;
  margin-right: 3px;
  letter-spacing: 0px;
`;

export const TitleText = styled.div``;

export const WelcomeText = styled.div`
  font-size: 33px;
  margin-bottom: 47px;
  display: flex;
  text-overflow: ellipsis;
  max-width: 845px;
  overflow: hidden;
  height: 40px;
`;

export const Name = styled.div`
  margin-left: 8px;
  font-weight: 800;
  letter-spacing: 0px;
  max-width: 742px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ReportUser = styled.div`
  font-size: 15px;
  letter-spacing: 0.2px;
  width: 99%;
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
`;

export const ReportArrow = styled(TiArrowForward)`
  font-size: 15px;
  margin-right: 2px;
  opacity: 0.8;
`;

export const MenuWrapper = styled.div<{ isAboutSelected?: boolean }>`
  display: flex;
  align-items: baseline;
  width: 100%;
  margin-bottom: ${(props: any) => (props.isAboutSelected ? 16 : 66)}px;
  flex-wrap: wrap;
`;

export const MenuItem = styled.div<{ isActive?: boolean }>`
  font-size: 14px;
  letter-spacing: 0.35px;
  color: #4D4D4D;
  padding-top: 10px;
  margin-right: 34px;
  margin-bottom: 6px;
  ${(props: any) =>
    props.isActive ? `border-top: 6px solid #0077df; font-weight: 800` : `font-weight: 500;`};
`;

export const SectionWrapper = styled.div``;
