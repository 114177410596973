import { Link } from 'react-router-dom';
import styled from 'styled-components';
import themes from '../Assets/colorThemes';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  @media (max-width: 1020px) {
    flex-direction: column-reverse;
    padding-top: 124px;
  }
  @media (max-width: 745px) {
    padding-top: 224px;
  }
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 464px;
  flex-grow: 1;
  max-height: 500px;
  height: 100%;
  @media (max-width: 1020px) {
    padding: 0 0 24px;
  }
`;

export const Header = styled.h1`
  letter-spacing: 1.57px;
  color: ${themes.default.blackBlue};
  letter-spacing: 1.57px;
  font-family: Inter;
  opacity: 1;
  font-weight: 400;
  margin: 0 0 24px;
  font-size: 45px;
  @media (max-width: 1020px) {
    text-align: center;
  }
`;

export const B = styled.span`
  font-weight: 900;
`;

export const Description = styled.p`
  text-align: left;
  font-family: Inter;
  font-size: 18px;
  letter-spacing: 0.18px;
  color: ${themes.default.blackBlue};
  margin: 0 0 64px;
  width: 100%;
  @media (max-width: 1020px) {
    text-align: center;
    margin: 0 0 32px;
  }
`;

export const StyledLink = styled(Link)`
  background-color: rgb(0, 119, 223, 20%);
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid ${themes.default.blue};
  color: ${themes.default.blue};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font: Inter;
  font-size: 14px;
  letter-spacing: 0.14px;
  color: ${themes.default.blue};
  max-width: 310px;
  width: 100%;
  height: 48px;
  margin: 0 auto 0 0;
  font-weight: 700;
  @media (max-width: 1020px) {
    margin: 0 auto;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  max-width: 806px;
  flex-grow: 2;
  height: 100%;
`;

export const Image = styled.img`
  max-width: 806px;
  height: auto;
  object-fit: cover;
  @media (max-width: 1020px) {
    max-width: 100%;
    max-height: 40vh;
  }
`;
