import { AppState } from '..';
import { IModalState, MODAL_NAME } from './models';

export const utilsState = (state: AppState) => state.utils;

export const allSettings = (state: AppState) => utilsState(state).settings;

export const allSizes = (state: AppState) => allSettings(state).sizes;

export const allCurrencies = (state: AppState) => allSettings(state).currency;

export const allUnits = (state: AppState) => allSettings(state).units;

export const allTechnologies = (state: AppState) => allSettings(state).technologies;

export const allExchangeRates = (state: AppState) => allSettings(state).exchangeRates;

export const isModalOpen = (name: string) => (state: AppState) => {
  const maybeState = utilsState(state).modalsActiveStates.find(
    (modalState: IModalState) => modalState.name === name
  );
  if (!maybeState || !maybeState.isOpen) {
    return false;
  }
  return maybeState.isOpen;
};

export const modalsProperties = (state: AppState) => utilsState(state).modalsProperties;

export const modalProductType = (state: AppState) => modalsProperties(state).productType;

export const modalElementId = (state: AppState) => modalsProperties(state).elementId;

export const modalGalleryId = (state: AppState) => modalsProperties(state).galleryId;

export const isNetworkActionLoading = (state: AppState) => utilsState(state).isNetworkActionLoading;

export const isProductPurchased = (state: AppState) => utilsState(state).isProductPurchased;

export const isLoadingUtilsState = (state: AppState) => utilsState(state).isLoadingUtilsState;

export const selectedWinningOffer = (state: AppState) => utilsState(state).selectedWinningOffer;

export const selectedWinningOfferId = (state: AppState) => selectedWinningOffer(state).id;

export const selectedWinningOfferComment = (state: AppState) => selectedWinningOffer(state).comment;

export const selectedWinningOfferStep = (state: AppState) => selectedWinningOffer(state).step;

export const galleryForEditId = (state: AppState) => utilsState(state).galleryIdForEdit;

export const utilsSelectedDevice = (state: AppState) => utilsState(state).selectedDevice;

export const modalOffers = (state: AppState) => modalsProperties(state).viewedProduct.offers || [];

export const modalProduct = (state: AppState) => modalsProperties(state).viewedProduct;

export const globalLicenses = (state: AppState) => allSettings(state).licenses;

export const selectedLicenseIds = (state: AppState) => utilsState(state).selectedLicenseIds;

export const isAddingLicense = (state: AppState) => modalsProperties(state).isAddingLicense;

export const isDesignLicenseBought = (state: AppState) => utilsState(state).isLicenseBought;

export const previewPrintRequest = (state: AppState) => utilsState(state).previewPrintRequest;

export const previewDesignForSell = (state: AppState) => utilsState(state).previewDesignForSell;

export const previewObjectForSell = (state: AppState) => utilsState(state).previewObjectForSell;

export const previewDesignRequest = (state: AppState) => utilsState(state).previewDesignRequest;

export const selectedViewedDesignLicense = (state: AppState) =>
  utilsState(state).selectedViewedDesignLicense;

export const moreActionsId = (state: AppState) => modalsProperties(state).moreActionsId;

export const designOfferMoreActionsId = (state: AppState) =>
  modalsProperties(state).designOfferMoreActionsId;

export const isEditingLicense = (state: AppState) => modalsProperties(state).isEditingLicense;

export const useLicenseDescription = (state: AppState) =>
  modalsProperties(state).licenseDescription;

export const useAddLicenseErrorMessage = (state: AppState) =>
  modalsProperties(state).addLicenseErrorMessage;

export const useFillProfileModalState = (state: AppState) => {
  const modalState = utilsState(state).modalsActiveStates.find(
    (modal: IModalState) => modal.name === MODAL_NAME.FILL_PROFILE
  );
  return modalState ? modalState.isOpen : false;
};

export const useProductPhotosViewerModalState = (state: AppState) => {
  const modalState = utilsState(state).modalsActiveStates.find(
    (modal: IModalState) => modal.name === MODAL_NAME.PRODUCT_PHOTOS_VIEWER
  );
  return modalState ? modalState.isOpen : false;
};

export const useCreateProductType = (state: AppState) => utilsState(state).createProductType;
