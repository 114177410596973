import React, { memo } from 'react';
import logo from '../../../images/logo-transparent.svg';
import fb from '../../../images/Facebook.svg';
import twitter from '../../../images/Twitter.svg';
import instagram from '../../../images/Instagram.svg';
import v from '../../../images/Vimeo.svg';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useLoggedInUser } from '../../../redux/reducers/auth/selectors';
import * as S from './Footer.style';

const Footer = () => {
  const { currency } = useSelector(useLoggedInUser);
  const currencyName = get(currency, 'currency', 'Euro');
  const currencySymbol = get(currency, 'symbol', '€');
  const { pathname } = useLocation();

  /* don't show footer on gallery viewers */
  const matchedProfileGallery = pathname.match('/profile/.{36}/gallery/.{36}');
  if (matchedProfileGallery) {
    return null;
  }
  return (
    <S.FooterContainer>
      <S.InnerWrapper>
        <S.LeftContainer>
          <S.TopWrapper>
            <S.Link>
              <S.Link_title>i-3D</S.Link_title>
              <S.LinkList>
                <S.StyledUi>
                  <S.FooterOption to="/about-us">About us</S.FooterOption>
                  {/* <S.FooterOption to="">Blog</S.FooterOption>
    //           <S.FooterOption to="">Team</S.FooterOption>
    //           <S.FooterOption to="">Contact</S.FooterOption> */}
                </S.StyledUi>
              </S.LinkList>
            </S.Link>
            <S.Link>
              <S.Link_title>HELP</S.Link_title>
              <S.LinkList>
                <S.StyledUi>
                  <S.FooterOption to="/help-center">All topics</S.FooterOption>
                  <S.FooterOption to="">FAQ</S.FooterOption>
                  {/* <S.FooterOption to="">Site map</S.FooterOption>
    //           <S.FooterOption to="">Knowledge database</S.FooterOption> */}
                </S.StyledUi>
              </S.LinkList>
            </S.Link>
            <S.Link>
              <S.Link_title>LEGAL</S.Link_title>
              <S.LinkList>
                <S.StyledUi>
                  <S.FooterOption to="/help-center/legal/privacy">Privacy</S.FooterOption>
                  <S.FooterOption to="/help-center/legal/tos">Terms of use</S.FooterOption>
                  <S.FooterOption to="/help-center/legal/agreements">
                    Marketplace agreements
                  </S.FooterOption>
                </S.StyledUi>
              </S.LinkList>
            </S.Link>
            <S.Link>
              <S.Link_title>FOLLOW US</S.Link_title>
              <S.LinkList>
                <S.StyledUi>
                  <S.LinkFollowUs>
                    <S.Icon>
                      <img src={fb} alt="Facebook" className="icon" />
                    </S.Icon>
                    <S.Icon>
                      <img src={twitter} alt="Twitter" className="icon" />
                    </S.Icon>
                    <S.Icon>
                      <img src={instagram} alt="Instagram" className="icon" />
                    </S.Icon>
                    <S.Icon>
                      <img src={v} alt="v" className="icon" />
                    </S.Icon>
                  </S.LinkFollowUs>
                </S.StyledUi>
              </S.LinkList>
            </S.Link>
          </S.TopWrapper>
          <S.BottomWrapper>
            {/* {currency && ( */}
            <S.Euro>
              <button className="Euro_Button">
                {currencyName}-{currencySymbol}
              </button>
            </S.Euro>
            {/* )} */}
            <S.Copyright>
              <span className="bolded">© i-3D.Io</span> <span className="spaceless">––––––––</span>{' '}
              All rights reserved, 2020.
            </S.Copyright>
          </S.BottomWrapper>
        </S.LeftContainer>
        <S.RightContainer>
          <S.Logo>
            <img src={logo} style={{ margin: '8px 16px' }} alt="Logo" className="logo_dark" />
          </S.Logo>
        </S.RightContainer>
      </S.InnerWrapper>
    </S.FooterContainer>
  );
};

export default memo(Footer);
