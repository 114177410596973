import styled from 'styled-components';
import U from '../../../../Assets/commonCSS';
import themes from '../../../../Assets/colorThemes';

export const MenuWrapper = styled.div<{ styles?: string }>`
  position: relative;
  width: 181px;
  top: 40px;
  left: -12px;
  z-index: ${U.zIndex1};
  box-shadow: 1px 6px 16px ${themes.default.transparentGray};
  border: 1px solid ${themes.default.grayBf};
  border-radius: 4px;
  background: ${themes.default.white};
  box-sizing: border-box;
  ${(props: any) => props.styles}
`;

export const Title = styled.div`
  letter-spacing: 1px;
  color: ${themes.default.gray9a};
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  height: auto;
  width: 100;
  width: 100%;
  padding: 12px 24px;
  box-sizing: border-box;
`;

export const MenuOption = styled.div`
  font-size: 14px;
  width: 100%;
  padding: 12px 24px;
  box-sizing: border-box;
  &:hover {
    background-color: ${themes.default.grayEf};
  }
  cursor: pointer;
`;
