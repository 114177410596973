import { Notification, NOTIFICATION_POINTER } from './notificationTypes';

export const getNotificationDestination = (notification: Notification, userId: string) => {
  const { itemPointer } = notification;
  switch (itemPointer) {
    case NOTIFICATION_POINTER.PR: {
      const { printRequestId } = notification;
      return `/dashboard/print-request/:${printRequestId}?`;
    }
    case NOTIFICATION_POINTER.OFS: {
      const { objectForSellId } = notification;
      return `/dashboard/object-for-sell/:${objectForSellId}?`;
    }
    case NOTIFICATION_POINTER.DFS: {
      const { designForSellId } = notification;
      return `/dashboard/design-for-sell/:${designForSellId}?`;
    }
    case NOTIFICATION_POINTER.DR: {
      const { designRequestId } = notification;
      return `/dashboard/design-request/:${designRequestId}?`;
    }
    case NOTIFICATION_POINTER.PR_OFFER: {
      return ''; //`/dashboard/my-printing-offer/:${id}?`
    }
    case NOTIFICATION_POINTER.DR_OFFER: {
      return ''; //`/dashboard/my-design-offer/:${id}?`
    }
    case NOTIFICATION_POINTER.DFS_PURCHASE: {
      return ''; //`/dashboard/purchase/design-for-sell/:${id}?`
    }
    case NOTIFICATION_POINTER.OFS_PURCHASE: {
      return ''; //`/dashboard/purchase/object-for-sell/:${id}?`
    }
    case NOTIFICATION_POINTER.RATING: {
      return `/profile/:${userId}`;
    }
    case NOTIFICATION_POINTER.COMMENT: {
      return ``;
    }
    case NOTIFICATION_POINTER.PR_PACKAGES: {
      return ''; //`/dashboard/my-printing-offer/:${id}?`
    }
    case NOTIFICATION_POINTER.PURCHASE_PACKAGES: {
      return ''; //`/dashboard/purchase/object-for-sell/:${id}?`
    }
  }
};
