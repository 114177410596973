import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';

export const ToCWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  padding: 48px 30px;
`;

export const ToCHeader = styled.div`
  display: flex;
  color: #4D4D4D;
  font-size: 18px;
  font-family: Inter;
  font-weight: 600;
  padding-bottom: 32px;
`;

export const ToCContainer = styled.ul``;

export const ListItem = styled.li<{ hasItems?: boolean }>`
  display: flex;
  flex-direction: column;
  font-family: Inter;
  font-size: 16px;
  color: #333333;
  font-weight: 500;
  padding-bottom: ${({ hasItems }) => (hasItems ? '20px' : '0')};
`;

export const ListItemContainer = styled.div`
  display: flex;
  padding-bottom: 4px;
`;

export const SubListItem = styled.li`
  display: flex;
  flex-direction: column;
  font-family: Inter;
  font-size: 16px;
  color: #333333;
  padding-left: 30px;
`;

export const SubSubListItem = styled.li`
  display: flex;
  flex-direction: column;
  font-family: Inter;
  font-size: 16px;
  color: #333333;
  padding-left: 30px;
`;

export const TableNumber = styled.span`
  display: flex;
  min-width: 65px;
  color: #95989a;
  font-size: 16px;
  font-family: Inter;
`;
export const SubTableNumber = styled.span`
  display: flex;
  min-width: 60px;
  color: #95989a;
  font-size: 16px;
  font-family: Inter;
`;

export const ToCTitle = styled(HashLink)`
  display: flex;
  text-decoration: underline;
  font-weight: 500;
  &:hover {
    color: blue;
  }
`;

export const ToCSubtitle = styled(HashLink)`
  display: flex;
  text-decoration: underline;
  font-weight: 400;
  &:hover {
    color: blue;
  }
`;

export const StyledUl = styled.ul`
  margin: 0;
`;
