export const GET_USER_OBJECTS_FOR_SELL = 'GET_USER_OBJECTS_FOR_SELL';
export const GET_USER_OBJECTS_FOR_SELL_SUCCESS = 'GET_USER_OBJECTS_FOR_SELL_SUCCESS';
export const GET_USER_OBJECTS_FOR_SELL_FAILURE = 'GET_USER_OBJECTS_FOR_SELL_FAILURE';
export const GET_FEATURED_OBJECTS_FOR_SELL = 'GET_FEATURED_OBJECTS_FOR_SELL';
export const GET_FEATURED_OBJECTS_FOR_SELL_SUCCESS = 'GET_FEATURED_OBJECTS_FOR_SELL_SUCCESS';
export const GET_FEATURED_OBJECTS_FOR_SELL_FAILURE = 'GET_FEATURED_OBJECTS_FOR_SELL_FAILURE';
export const GET_VIEWED_OBJECT_FOR_SELL = 'GET_VIEWED_OBJECT_FOR_SELL';
export const GET_VIEWED_OBJECT_FOR_SELL_SUCCESS = 'GET_VIEWED_OBJECT_FOR_SELL_SUCCESS';
export const GET_VIEWED_OBJECT_FOR_SELL_FAILURE = 'GET_VIEWED_OBJECT_FOR_SELL_FAILURE';
export const SET_SUBMITTING_OBJECT_FOR_SELL = 'SET_SUBMITTING_OBJECT_FOR_SELL';
export const SET_OBJECT_FOR_SELL_SUBMIT_ERROR = 'SET_DESIGN_REQUEST_SUBMIT_ERROR';
export const CREATE_OBJECT_FOR_SELL = 'CREATE_OBJECT_FOR_SELL';
export const CREATE_OBJECT_FOR_SELL_SUCCESS = 'CREATE_OBJECT_FOR_SELL_SUCCESS';
export const CREATE_OBJECT_FOR_SELL_FAILURE = 'CREATE_OBJECT_FOR_SELL_FAILURE';
export const PREPARE_OBJECT_FOR_SELL_DETAILS = 'PREPARE_OBJECT_FOR_SELL_DETAILS';
export const PREPARE_OBJECT_FOR_SELL_DETAILS_SUCCESS = 'PREPARE_OBJECT_FOR_SELL_DETAILS_SUCCESS';
export const SET_OFS_COMMENTS = 'SET_OFS_COMMENTS';
export const ADD_OFS_PACKAGE = 'ADD_OFS_PACKAGE';
export const ADD_OFS_PACKAGE_SUCCESS = 'ADD_OFS_PACKAGE_SUCCESS';
export const ADD_OFS_PACKAGE_FAIL = 'ADD_OFS_PACKAGE_FAIL';
export const UPDATE_OFS_PACKAGES = 'UPDATE_OFS_PACKAGES';
export const UPDATE_OFS_PACKAGES_SUCCESS = 'UPDATE_OFS_PACKAGES_SUCCESS';
export const UPDATE_OFS_PACKAGES_FAIL = 'UPDATE_OFS_PACKAGES_FAIL';
export const SET_OFS_LIKE = 'SET_OFS_LIKE';
export const REMOVE_OFS_COMMENT = 'REMOVE_OFS_COMMENT';
export const REMOVE_OFS_COMMENT_SUCCESS = 'REMOVE_OFS_COMMENT_SUCCESS';
export const REMOVE_OFS_COMMENT_FAILURE = 'REMOVE_OFS_COMMENT_FAILURE';
export const ADD_FREE_OFS = 'ADD_FREE_OFS'
export const ADD_FREE_OFS_SUCCESS = 'ADD_FREE_OFS_SUCCESS'
export const ADD_FREE_OFS_FIALURE = 'ADD_FREE_OFS_FIALURE'