import styled from 'styled-components';
import SVG from '../../Assets/SVG/svg-exporter';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 180px;
  z-index: 7;
  background: #a7e1f8;
  width: 100%;
  align-items: center;
  @media (min-width: 745px) {
    margin-top: 108px;
  }
  @media (min-width: 1176px) {
    margin-top: 70px;
  }
  position: relative;
`;

export const UpperWrapper = styled.div`
display: flex;
flex-direction: column;
z-index: 5;
padding: 16px;
position: relative;
@media (min-width: 745px){
  min-height: 200px;
  padding: 30px 45px;
}
@media (min-width: 1176px){
  min-height: 275px;
  padding: 45px 180px
  width: 60%;
}
@media (min-width: 1176px){
}`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #00071a;
  font-family: Inter;
  max-width: 530px;
`;

export const Header = styled.div`
  font-size: 30px;
  padding-bottom: 16px;
  font-weight: 500;
  @media (min-width: 375px) {
    font-size: 36px;
  }
  @media (min-width: 745px) {
    font-size: 46px;
  }
`;

export const Bolded = styled.span`
  font-weight: 800;
`;

export const Subheader = styled.div`
  font-size: 16px;
  font-weight: 500;
  @media (min-width: 745px) {
    font-size: 18px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  position: absolute;
  max-width: 180px;
  z-index: -1;
  right: 5px;
  top: -38px;
  @media (min-width: 745px) {
    top: auto;
    right: 150px;
  }
  @media (min-width: 1176px) {
    max-width: 330px;
    top: 0;
    left: 30%;
  }
`;

export const SecondaryImageContainer = styled.div`
  display: flex;
  position: absolute;
  max-width: 535px;
  top: 32px;
  right: 0;
  @media (min-width: 1600px) {
    max-width: 616px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const BottomWrapper = styled.div`
  background: black;
  width: 100%;
  height: 50px;
  @media (min-width: 745px) {
    min-height: 70px;
  }
  @media (min-width: 1176px) {
    min-height: 120px;
  }
  @media (min-width: 1450px) {
    min-height: 200px;
  }
`;

export const PurpleConstuct = styled.img.attrs({ src: SVG.PurpleConstruct })`
  position: absolute;
  z-index: 6;
  top: -15px;
  left: 50%;
`;

export const OrangeConstuct = styled.img.attrs({ src: SVG.OrangeConstruct })`
  position: absolute;
  z-index: 6;
  bottom: -10%;
  right: 10%;
`;

export const BlueYellowConstuct = styled.img.attrs({ src: SVG.BlueYellowConstruct })`
  position: absolute;
  z-index: 6;
  left: 20%;
  bottom: 30%;
`;
