import React, { memo } from 'react';
import { FaCheck } from 'react-icons/fa';
import AddressInput from './AddressInput';
import * as S from './DeliveryAddressSelector.style';

interface AddressFormProps {
  isSelectable: boolean;
  isActive?: boolean;
  onClick: () => void;
  setAddress: (e: string) => void;
  setSurname: (e: string) => void;
  setName: (e: string) => void;
  setZipCode: (e: string) => void;
  setCountry: (e: string) => void;
  setCity: (e: string) => void;
  setRegion: (e: string) => void;
  name: string;
  surname: string;
  address: string;
  city: string;
  zipCode: string;
  country: string;
  region: string;
}

const AddressForm = ({
  isActive = false,
  isSelectable,
  onClick,
  setAddress,
  setSurname,
  setName,
  setCountry,
  setCity,
  setRegion,
  setZipCode,
  name,
  surname,
  address,
  city,
  zipCode,
  country,
  region,
}: AddressFormProps) => (
  <S.AddressFormWrapper isSelectable={isSelectable} isActive={isActive} onClick={onClick}>
    {isSelectable && (
      <S.AddressOptionRadioWrapper>
        <S.AddressOptionRadio>
          <S.RadioButton isActive={isActive}>
            {isActive && <FaCheck style={{ color: '#fff' }} />}
          </S.RadioButton>
        </S.AddressOptionRadio>
      </S.AddressOptionRadioWrapper>
    )}
    <S.AddressOptionDetails>
      <S.AddressBodyWrapper>
        <S.AddressRow>
          <AddressInput
            isShort
            label="First name"
            onChange={(e: string) => setName(e)}
            value={name}
          />
          <AddressInput
            isShort
            label="Surname"
            onChange={(e: string) => setSurname(e)}
            value={surname}
          />
        </S.AddressRow>
        <S.AddressRow>
          <AddressInput
            label="Address"
            onChange={(e: string) => setAddress(e)}
            value={address}
            isShort
            isTall
          />
        </S.AddressRow>
        <S.AddressRow>
          <AddressInput isShort label="City" onChange={(e: string) => setCity(e)} value={city} />
          <AddressInput
            isShort
            label="Zip-code"
            onChange={(e: string) => setZipCode(e)}
            value={zipCode}
          />
        </S.AddressRow>
        <S.AddressRow>
          <AddressInput
            label="Country"
            onChange={(e: string) => setCountry(e)}
            value={country}
            isShort
          />
        </S.AddressRow>
        <S.AddressRow>
          <AddressInput
            label="Region/State/Voivodeship"
            onChange={(e: string) => setRegion(e)}
            value={region}
            isShort
          />
        </S.AddressRow>
      </S.AddressBodyWrapper>
    </S.AddressOptionDetails>
  </S.AddressFormWrapper>
);

export default memo(AddressForm);
