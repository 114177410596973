import React, { memo } from 'react';
import { useParams } from 'react-router';
import { useUserProfile } from '../../../redux/reducers/userProfiles';
import EmptyWorkshop from '../EmptyPanels/EmptyWorkshop/EmptyWorkshop';
import DevicesMapper from './Printers/DevicesMapper';
import * as S from './Workshop.styles';

const Workshop = () => {
  const params: any = useParams();
  const { userId } = params;
  const {
    workshop: { printers, other },
  } = useUserProfile(userId);
  const isPrintersEmpty = printers && printers.length === 0;
  const isOtherEmpty = other && other.length === 0;

  return (
    <S.WorkshopWrapper>
      <S.WorkshopCategory>
        <S.CategoryTitle>3D Printers</S.CategoryTitle>
        {isPrintersEmpty ? <EmptyWorkshop /> : <DevicesMapper devices={printers} />}
      </S.WorkshopCategory>
      <S.WorkshopCategory>
        <S.CategoryTitle>Other</S.CategoryTitle>
        {isOtherEmpty ? <EmptyWorkshop /> : <DevicesMapper devices={other} />}
      </S.WorkshopCategory>
    </S.WorkshopWrapper>
  );
};
export default memo(Workshop);
