import { AnyAction } from 'redux';
import { Comment } from '../../../models/common';
import { updateCommentsAfterDelete } from '../../../utils/updateCommentsAfterDelete';
import { updateProductCommentsAfterLike } from '../../../utils/updateProductCommentsAfterLike';
import {
  GET_FEATURED_OBJECTS_FOR_SELL,
  GET_FEATURED_OBJECTS_FOR_SELL_FAILURE,
  GET_FEATURED_OBJECTS_FOR_SELL_SUCCESS,
  GET_USER_OBJECTS_FOR_SELL,
  GET_USER_OBJECTS_FOR_SELL_FAILURE,
  GET_USER_OBJECTS_FOR_SELL_SUCCESS,
  GET_VIEWED_OBJECT_FOR_SELL,
  GET_VIEWED_OBJECT_FOR_SELL_FAILURE,
  GET_VIEWED_OBJECT_FOR_SELL_SUCCESS,
  PREPARE_OBJECT_FOR_SELL_DETAILS_SUCCESS,
  REMOVE_OFS_COMMENT_SUCCESS,
  SET_OBJECT_FOR_SELL_SUBMIT_ERROR,
  SET_OFS_COMMENTS,
  SET_OFS_LIKE,
  SET_SUBMITTING_OBJECT_FOR_SELL,
} from './actionTypes';
import { ObjectForSell } from './models';

export interface ObjectsForSellState {
  userObjectsForSell: ObjectForSell[];
  isLoadingUserObjectsForSell: boolean;
  featuredObjectsForSell: ObjectForSell[];
  isLoadingFeaturedObjectsForSell: boolean;
  viewedObjectForSell: ObjectForSell | null;
  isLoadingViewedObjectForSell: boolean;
  isSubmitting: boolean;
  errorMessage: string;
}

const initialState: ObjectsForSellState = {
  userObjectsForSell: [],
  isLoadingUserObjectsForSell: false,
  featuredObjectsForSell: [],
  isLoadingFeaturedObjectsForSell: false,
  viewedObjectForSell: null,
  isLoadingViewedObjectForSell: false,
  isSubmitting: false,
  errorMessage: '',
};

export default function (state = initialState, action: AnyAction): ObjectsForSellState {
  switch (action.type) {
    case GET_USER_OBJECTS_FOR_SELL: {
      return {
        ...state,
        isLoadingUserObjectsForSell: true,
      };
    }
    case GET_USER_OBJECTS_FOR_SELL_SUCCESS: {
      return {
        ...state,
        isLoadingUserObjectsForSell: false,
        userObjectsForSell: action.data,
      };
    }
    case GET_USER_OBJECTS_FOR_SELL_FAILURE: {
      return {
        ...state,
        isLoadingUserObjectsForSell: false,
      };
    }
    case GET_FEATURED_OBJECTS_FOR_SELL: {
      return {
        ...state,
        isLoadingFeaturedObjectsForSell: true,
      };
    }
    case GET_FEATURED_OBJECTS_FOR_SELL_SUCCESS: {
      return {
        ...state,
        isLoadingFeaturedObjectsForSell: false,
        featuredObjectsForSell: action.data,
      };
    }
    case GET_FEATURED_OBJECTS_FOR_SELL_FAILURE: {
      return {
        ...state,
        isLoadingFeaturedObjectsForSell: false,
      };
    }
    case GET_VIEWED_OBJECT_FOR_SELL: {
      return {
        ...state,
        isLoadingViewedObjectForSell: true,
      };
    }
    case GET_VIEWED_OBJECT_FOR_SELL_SUCCESS: {
      return {
        ...state,
        isLoadingViewedObjectForSell: false,
        viewedObjectForSell: action.data,
      };
    }
    case GET_VIEWED_OBJECT_FOR_SELL_FAILURE: {
      return {
        ...state,
        isLoadingViewedObjectForSell: false,
      };
    }
    case SET_SUBMITTING_OBJECT_FOR_SELL: {
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };
    }
    case SET_OBJECT_FOR_SELL_SUBMIT_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    }
    case PREPARE_OBJECT_FOR_SELL_DETAILS_SUCCESS: {
      return {
        ...state,
        ...action.data,
      };
    }
    case SET_OFS_COMMENTS: {
      const { comments } = action;
      const { viewedObjectForSell } = state;
      if (!viewedObjectForSell) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        viewedObjectForSell: { ...viewedObjectForSell, comments },
      };
    }
    case SET_OFS_LIKE: {
      const { viewedObjectForSell } = state;
      if (!viewedObjectForSell) {
        return {
          ...state,
        };
      }
      const { comments } = viewedObjectForSell;
      const { like } = action;
      const { commentId } = like;
      const maybeComment = comments.find((comment: Comment) => comment.id === commentId);
      if (!maybeComment) {
        return {
          ...state,
        };
      }
      const updatedComments = updateProductCommentsAfterLike(comments, maybeComment, like);
      return {
        ...state,
        viewedObjectForSell: { ...viewedObjectForSell, comments: updatedComments },
      };
    }
    case REMOVE_OFS_COMMENT_SUCCESS: {
      const { viewedObjectForSell } = state;
      if (!viewedObjectForSell) {
        return {
          ...state,
        };
      }
      const { comments } = viewedObjectForSell;
      const { commentId } = action;
      const updatedComments = updateCommentsAfterDelete(comments, commentId);
      return {
        ...state,
        viewedObjectForSell: { ...viewedObjectForSell, comments: updatedComments },
      };
    }
    default:
      return state;
  }
}
