import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addComment } from '../../redux/actions';
import { AppState } from '../../redux/reducers';
import { useSelector } from 'react-redux';
import { PRODUCT_TYPE } from '../../utils/productType';
import Comment from './Comment/Comment';
import * as S from './ProductDetails.style';
import { toggleModal } from '../../redux/reducers/utils/action';
import { MODAL_NAME } from '../../redux/reducers/utils/models';
import { Comment as IComment } from '../../models/common';

export default ({
  comments,
  productId,
  productType,
}: {
  comments: IComment[];
  productId: string;
  productType: PRODUCT_TYPE;
}) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const isLoggedIn = useSelector((state: AppState) => state.auth.isLoggedIn);
  const isDiscussionEmpty = comments.length === 0;
  const handleAddComment = () => {
    dispatch(
      addComment({
        payload: {
          message: message,
          productId,
          productType,
        },
        productId,
        productType,
      })
    );
    setMessage('');
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (!isLoggedIn) {
        dispatch(toggleModal(MODAL_NAME.LOGIN));
        return setMessage('');
      }
      handleAddComment();
    }
  };

  return (
    <S.CommentsWrapper id={'discussion'}>
      <S.SectionTitle>Discussion</S.SectionTitle>
      <S.JoinDiscussionWrapper>
        <S.BigPerson />
        <S.LeaveMessage
          onKeyPress={handleKeyPress}
          type="text"
          value={message}
          onChange={(e: React.ChangeEvent<{ value: string }>) => setMessage(e.target.value)}
          placeholder={'Join the discussion'}
        />
      </S.JoinDiscussionWrapper>
      {isDiscussionEmpty ? (
        <S.EmptyDiscussionWrapper>
          <S.BubblesWrapper>
            <S.Bubbles />
          </S.BubblesWrapper>
          <S.TextWrapper>
            <S.TopText>Discussion hasn't been started yet.</S.TopText>
            <S.BottomText>Start the discussion by your own.</S.BottomText>
          </S.TextWrapper>
        </S.EmptyDiscussionWrapper>
      ) : (
        <S.Comments>
          {comments.map((comment: IComment) => (
            <Comment key={comment.id} {...{ comment, productId, productType }} />
          ))}
        </S.Comments>
      )}
    </S.CommentsWrapper>
  );
};
