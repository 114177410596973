import React, { memo } from 'react';
import { Routes, Route } from 'react-router-dom';
import HelpCenter from '../components/HelpCenter/HelpCenter';
import Legal from '../components/HelpCenter/Legal/Legal';
import { HELP_CENTER } from '../components/HelpCenter/Legal/Legal';

const HelpCenterRoutes = () => (
  <>
    <Routes>
      <Route path="/help-center" element={<HelpCenter />} />
      <Route
        path="/help-center/legal/tos"
        element={<Legal selectedSection={HELP_CENTER.TERMS_OF_SERVICE} />}
      />
      <Route
        path="/help-center/legal/agreements"
        element={<Legal selectedSection={HELP_CENTER.MARKETPLACE_AGREEMENTS} />}
      />
      <Route
        path="/help-center/legal/privacy"
        element={<Legal selectedSection={HELP_CENTER.PRIVACY} />}
      />
    </Routes>
  </>
);

export default memo(HelpCenterRoutes);
