import React, { memo } from 'react';
import { Option } from '../../../models/common';
import Selector, {
  SELECTOR_BORDER_RADIUS_OPTIONS,
  SELECTOR_TYPE,
} from '../../CreateProduct/Inputs/Selector';
import * as S from './Partials.style';

const perPageOptions: Option[] = [
  { value: '30', label: '30' },
  { value: '60', label: '60' },
  { value: '90', label: '90' },
];

interface ResultsFooterProps {
  page: number;
  itemsPerPage: Option;
  pagesNo: number;
  setPage: (val: number) => void;
  handleSelectItemsPerPage: (option: Option) => void;
  setRefresh: (state: boolean) => void;
}

const ResultsFooter = ({
  page,
  itemsPerPage,
  setPage,
  handleSelectItemsPerPage,
  pagesNo,
  setRefresh,
}: ResultsFooterProps) => {
  const incrementPage = () => {
    if (pagesNo - 1 >= page + 1) {
      setPage(page + 1);
      setRefresh(true);
    }
  };
  const decrementPage = () => {
    if (page > 0) {
      setPage(page - 1);
      setRefresh(true);
    }
  };

  const handlePageInput = (event: React.ChangeEvent<{ value: string }>) => {
    const newPage = +event.target.value;
    if (newPage - 1 <= pagesNo && newPage - 1 >= 0) {
      setPage(newPage - 1);
      setRefresh(true);
    }
  };
  return (
    <S.BottomPaginationWrapper>
      <S.Row>
        <S.Label>Items per page</S.Label>
        <S.SelectPerPage>
          <Selector
            placeholder={itemsPerPage.label}
            options={perPageOptions}
            selected={itemsPerPage.label}
            handleOptionSelect={handleSelectItemsPerPage}
            selectorAlignment={SELECTOR_BORDER_RADIUS_OPTIONS.CENTER}
            type={SELECTOR_TYPE.TINY}
          />
        </S.SelectPerPage>
      </S.Row>
      <S.PaginationWrapper>
        <S.ArrowLeft onClick={decrementPage} />
        <S.ArrowLabel onClick={decrementPage}>Previous</S.ArrowLabel>
        <S.PageInput value={page + 1} onChange={handlePageInput} />
        <S.PerPage>/ {pagesNo === 0 ? 1 : pagesNo}</S.PerPage>
        <S.ArrowLabel onClick={incrementPage}>Next</S.ArrowLabel>
        <S.ArrowRight onClick={incrementPage} />
      </S.PaginationWrapper>
    </S.BottomPaginationWrapper>
  );
};

export default memo(ResultsFooter);
