export enum STEPS {
  GENERAL_INFORMATION,
  OBJECT_SPECIFICATION,
  DESIGN_AND_DELIVERY,
  SUMMARY,
}

export enum TITLE {
  PRINT_REQUEST = 'printing request',
  OBJECT_FOR_SELL = 'object for sale',
  DESIGN_REQUEST = 'design request',
  DESIGN_FOR_SELL = 'design for sale',
}

export type File = {
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
  src: string;
};

export interface InputProps {
  label: string;
  isOptional?: boolean;
  uploadType: string;
  showSublabel?: boolean;
  showThumbnails?: boolean;
  setFiles: any;
  maxItems?: number;
  isWorkshop?: boolean;
}

export const IMAGE_EXTENSIONS = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];

export interface ValidFormProps {
  uploadType: string;
  type: string;
  size: number;
}

export interface ValidFormResponse {
  errorMessage: string;
}
