import { Comment, PRODUCT_STATUS, ImageProps } from '../../../models/common';
import { Size, Unit, EmptySize, EmptyUnit } from '../../../models/units';
import { User, EmptyUser, Currency, EmptyCurrency } from '../../../models/user';
import { Purchase } from '../purchase/models';

export interface License {
  id: string;
  title: string;
  currency: Currency;
  // isCommercialAllowed: boolean;
  // isModifyAllowed: boolean;
  // printingLimit: number;
  shortDescription: string;
  description: string;
  price: number;
}

export interface OfferLicense {
  id: string;
  title: string;
  currency: Currency;
  // isCommercialAllowed: boolean;
  // isModifyAllowed: boolean;
  // printingLimit: number;
  shortDescription: string;
  description: string;
  price: number;
}

export interface DesignLicense {
  designId: string;
  licenseId: string;
  license: License;
  offerLicense: OfferLicense
}

export interface DesignForSell {
  id: string;
  images: ImageProps[];
  notifications: any[];
  attachment: string[];
  title: string;
  isPaid: boolean;
  deadline: string;
  description: string;
  status: PRODUCT_STATUS;
  height: number;
  width: number;
  depth: number;
  positionX: string;
  positionY: string;
  volume: number;
  email: string;
  phone: string;
  distance: string;
  isArchived: boolean;
  fileFormat: string;
  software: string;
  licenses: DesignLicense[];
  user: User;
  size: Size;
  unit: Unit;
  comments: Comment[];
  purchases: Purchase[];
  createdAt: string;
}


export const EmptyLicense: License = {
  id: '',
  title: '',
  // isCommercialAllowed: false,
  // isModifyAllowed: false,
  // printingLimit: 0,
  shortDescription: '',
  description: '',
  price: 0,
  currency: EmptyCurrency,
};

export const EmptyDesignLicense: DesignLicense = {
  designId: '',
  licenseId: '',
  license: EmptyLicense,
  offerLicense: EmptyLicense,
};

export const EmptyDesignForSell: DesignForSell = {
  id: '',
  images: [],
  notifications: [],
  attachment: [],
  title: '',
  isPaid: false,
  deadline: '',
  description: '',
  status: PRODUCT_STATUS.WAITING_FOR_FINALIZATION,
  height: 0,
  width: 0,
  depth: 0,
  positionX: '',
  positionY: '',
  volume: 0,
  email: '',
  phone: '',
  distance: '',
  isArchived: false,
  fileFormat: '',
  software: '',
  user: EmptyUser,
  size: EmptySize,
  unit: EmptyUnit,
  comments: [],
  licenses: [],
  purchases: [],
  createdAt: '',
};
