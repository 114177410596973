import styled from 'styled-components';

export const ExternalWrapper = styled.div<{ maxWidth?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Wrapper = styled.div<{ maxWidth?: string }>`
  max-width: ${(props: any) => (props.maxWidth ? props.maxWidth : '875px')};
  width: 100%;
  max-height: 877px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #4D4D4D;
  border: 1px solid #dbdbdb;
  display: flex;
  flex-direction: column;
`;

export const TopWrapper = styled.div`
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  align-items: baseline;
  height: 32px;
  border-bottom: 1px solid #dbdbdb;
  padding: 18px 0 5px 20px;
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.5px;
`;

export const OptionsWrapper = styled.div`
  padding: 20px 15px 5px;
  flex-grow: 1;
  overflow: auto;
`;

export const AddressOptionWrapper = styled.div<{ isActive?: boolean }>`
  height: 150px;
  border: ${(props: any) => (props.isActive ? '2px solid #0077DF' : '2px solid #EFEFEF')};
  border-radius: 4px;
  margin-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  cursor: pointer;
`;

export const AddressFormWrapper = styled.div<{ isActive?: boolean; isSelectable: boolean }>`
  ${(props: any) =>
    props.isSelectable &&
    `
  border: ${props.isActive ? '2px solid #0077DF' : '2px solid #EFEFEF'};
  border-radius: 4px;
  margin-bottom: 20px;
  `}
  box-sizing: border-box;
  display: flex;
  cursor: ${(props: any) => (props.isActive === undefined ? 'default' : 'pointer')};
`;
export const AddressOptionRadioWrapper = styled.div`
  background-color: #efefef;
  min-width: 54px;
  border-radius: 4px 0 0 4px;
  display: flex;
  justify-content: center;
`;

export const AddressOptionRadio = styled.div``;

export const AddressDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const AddressDetails = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 17px 16px;
  flex-grow: 1;
`;
export const AddressAddressName = styled.div`
  font-weight: 700;
  letter-spacing: 0.4px;
  padding-bottom: 7px;
`;
export const AddressAddressLine = styled.div`
  font-size: 17px;
  padding-bottom: 7px;
`;

export const AddressAddressDefault = styled.div`
  align-items: flex-end;
  font-size: 11px;
  padding: 9px 16px;
  font-weight: 500;
  border-top: 1px solid #e2e2e2;
`;

export const AddressRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-wrap: wrap;
  }
`;

export const AddressInput = styled.input<{ isTall?: boolean }>`
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  width: 100%;
  height: ${(props: any) => (props.isTall ? '91px' : '40px')};
  box-sizing: border-box;
  margin-right: 15px;
  margin-bottom: 20px;
  padding: 0 14px;
  font-size: 14px;
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px #0077df;
  }
  :disabled {
    background-color: #fff;
  }
`;

export const AddressOptionDetails = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const AddressBodyWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 12px 24px;
`;

export const AddressInputWrapper = styled.div<{ isShort?: boolean }>`
  width: ${(props: any) => (props.isShort ? '49%' : '100%')};
`;

export const AddressInputLabel = styled.div`
  padding-bottom: 3px;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.4px;
`;

export const AddressTextArea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  height: 90px;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  resize: none;
  margin-top: 3px;
  font-size: 15px;
  font-family: inherit;
  color: #212121;
  padding: 12px 14px;
  margin-bottom: 20px;
  box-sizing: border-box;
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px #0077df;
  }
`;

export const RadioButton = styled.div<{ isActive?: boolean }>`
  background-color: ${(props: any) => (props.isActive ? '#0077DF' : '#fff')};
  color: ${(props: any) => (props.isActive ? '#fff' : '#A9A9A9')};
  border: 2px solid ${(props: any) => (props.isActive ? '#0077DF' : '#A9A9A9')};
  margin-top: 20px;
  width: ${(props: any) => (props.isActive ? '24px' : '16px')};
  height: ${(props: any) => (props.isActive ? '24px' : '16px')};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #dbdbdb;
  padding: 15px;
`;

export const CancelButton = styled.button`
  max-width: 101px;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  font-weight: 700;
  align-items: center;
  cursor: pointer;
  margin-right: 30px;
  border: none;
  font-size: 14px;
  background-color: transparent;
`;

export const ApplyButton = styled.button`
  background-color: #0077df;
  box-shadow: 0px 3px 6px #00000029;
  color: #fff;
  border: none;
  border-radius: 4px;
  max-width: 200px;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  font-weight: 700;
  align-items: center;
  cursor: pointer;
  :disabled {
    color: #a6a6a6;
    background-color: #f0f0f0;
    border: 1px solid #e3e3e3;
    box-shadow: none;
    cursor: auto;
  }
`;

export const DeleteButton = styled.div`
  background: #ffffff;
  border: 1px solid #9a9a9a;
  margin-top: 20px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

//

export const NoDefaultMessage = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.35px;
  color: #333333;
  width: 100%;
  margin: 36px 0 4px;
`;

export const NoDefaultLink = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.35px;
  color: #333333;
  width: 100%;
  cursor: pointer;
  margin-bottom: 36px;
  text-decoration: underline;
`;
