import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdClose } from 'react-icons/md';
import { login } from '../../../redux/reducers/auth/actions';
import ExternalLogin from './ExternalLogin';
import { useLoginError } from '../../../redux/reducers/auth/selectors';
import * as S from './Login.style';
import { toggleModal } from '../../../redux/reducers/utils/action';
import { MODAL_NAME } from '../../../redux/reducers/utils/models';

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { isError, message } = useSelector(useLoginError);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleLogin = () => {
    dispatch(login({ email, password }));
  };

  const handleKeyPress = (e: any) => {
    const pressedEnter = e.keyCode === 13;
    if (pressedEnter) {
      handleLogin();
    }
  };

  const handleClose = () => {
    if (isError) {
      dispatch({ type: 'LOGIN_ERROR', value: false });
    }
    dispatch(toggleModal(MODAL_NAME.LOGIN));
  };

  const handleDisplaySignUp = () => {
    dispatch(toggleModal(MODAL_NAME.SIGNUP));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  return (
    <S.ExternalWrapper>
      <S.Wrapper>
        <S.TopWrapper>
          <S.Title>
            <S.BoldTitle>Log in sds</S.BoldTitle>to i-3D.io
          </S.Title>
          <MdClose
            style={{ color: '#333333', marginLeft: 'auto', cursor: 'pointer' }}
            size={28}
            onClick={handleClose}
          />
        </S.TopWrapper>
        <S.FormWrapper autoComplete="on" onSubmit={(e) => handleSubmit(e)}>
          <S.Label isError={isError}>Email address</S.Label>
          <S.InputFieldWrapper isError={isError}>
            <S.Input
              type="email"
              name="email"
              placeholder="you@company.com"
              onChange={(e: any) => setEmail(e.target.value)}
              onKeyDown={(e: any) => handleKeyPress(e)}
              value={email}
              required
            />
            {isError && <S.WarningIcon />}
          </S.InputFieldWrapper>
          <S.Label isError={isError}>Password</S.Label>
          <S.InputFieldWrapper isError={isError}>
            <S.Input
              type={isPasswordVisible ? 'text' : 'password'}
              name="password"
              onChange={(e: any) => setPassword(e.target.value)}
              onKeyDown={(e: any) => handleKeyPress(e)}
              value={password}
              required
            />
            <S.PlainButton type="button" onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
              {isPasswordVisible ? <S.EyeBlind /> : <S.Eye />}
            </S.PlainButton>
            {isError && <S.WarningIcon />}
          </S.InputFieldWrapper>
          {isError && <S.ErrorMessage>{message}</S.ErrorMessage>}
          <S.LoginButton onClick={() => handleLogin()}>Log in with email</S.LoginButton>
          <S.Forgot>Forgot your password?</S.Forgot>
          <S.LoginOptions>
            <S.Or>or</S.Or>
            <S.ExternalLoginWrapper>
              <ExternalLogin action={'login'} />
            </S.ExternalLoginWrapper>
          </S.LoginOptions>
        </S.FormWrapper>
        <S.BottomWrapper>
          Don't have an account?
          <S.Join onClick={handleDisplaySignUp}>
            <S.BoldJoin>Join us</S.BoldJoin>in a few seconds.
          </S.Join>
        </S.BottomWrapper>
      </S.Wrapper>
    </S.ExternalWrapper>
  );
};

export default memo(Login);
