import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import SearchBox from '../../SearchSimple/SearchBox/SearchBox';
import CreateMenu from './CreateMenu/CreateMenu';
import UserMenu from './UserMenu/UserMenu';
import NotificationsMenu from './NotificationsMenu/NotificationsMenu';
import logo from '../../../images/logo.svg';
import earlyAccess from '../../../images/early-access-badge.svg';
import helpCenterLogo from '../../../images/logo-help-center.svg';
import Profile from '../../../images/profile.svg';
import * as S from './Header.style';
import { MODAL_NAME } from '../../../redux/reducers/utils/models';
import { isModalOpen } from '../../../redux/reducers/utils/selectors';
import { toggleModal } from '../../../redux/reducers/utils/action';
import { getShortItems } from '../../../redux/reducers/search/actions';
import { SimpleSearchFlag } from '../../../models/common';
import {
  isUserLoggedIn,
  loggedUser,
  userNotifications,
} from '../../../redux/reducers/auth/selectors';

const Header = () => {
  const dispatch = useDispatch();
  const isPreviewOpen = useSelector(isModalOpen(MODAL_NAME.PREVIEW));
  const user = useSelector(loggedUser);
  const isLoggedIn = useSelector(isUserLoggedIn);
  const notifications = useSelector(userNotifications);
  const { pathname } = useLocation();
  const unreadNotifications = notifications.filter((notification: any) => !notification.isRead);
  const count = unreadNotifications.length;
  const userAvatar = isLoggedIn && user && user.avatar ? user.avatar : Profile;
  const isAvatarSet = userAvatar !== Profile;
  const [query, setQuery] = useState('');

  if (pathname.includes('/gallery') || isPreviewOpen) {
    return null;
  }

  const refreshItems = () => {
    setQuery('');
    if (pathname === '/') {
      dispatch(getShortItems({ flag: SimpleSearchFlag.ALL }));
    }
  };

  const handleNotifications = () => {
    if (!isLoggedIn) {
      dispatch(toggleModal(MODAL_NAME.LOGIN));
      return;
    }
    dispatch(toggleModal(MODAL_NAME.NOTIFICATIONS));
    return;
  };

  const isHelpPage = pathname.includes('/help-center');
  const logoSrc = !isHelpPage ? logo : helpCenterLogo;

  return (
    <S.HeaderWrapper>
      <S.HeaderContainer>
        <Link onClick={refreshItems} style={{ height: '100%' }} to="/">
          <S.ImgContainer>
          <S.Image src={logoSrc} alt="Logo" />
          <S.AbsoluteImage src={earlyAccess}/>
          </S.ImgContainer>
        </Link>
        {isHelpPage && (
          <Link to="/help-center" style={{ width: '100%' }}>
            <S.HelpCenter>Help Center</S.HelpCenter>
          </Link>
        )}
        <S.ControlsWrapper>
          {!isHelpPage && (
            <S.SearchBoxWrapper>
              <SearchBox query={query} setQuery={setQuery} />
            </S.SearchBoxWrapper>
          )}
          {!isHelpPage && (
            <S.Explore onClick={() => dispatch(toggleModal(MODAL_NAME.HEADER_MAIN_MENU))}>
              Explore the 3D Market
            </S.Explore>
          )}
          <S.CreateAndIconsWrapper>
            {!isHelpPage && (
              <S.Button onClick={() => dispatch(toggleModal(MODAL_NAME.CREATE_MENU))}>
                Create
              </S.Button>
            )}
            <CreateMenu />
            <S.Icons>
              <S.MenuWrapper>
                <S.PlainButton isMarginNeeded onClick={() => handleNotifications()}>
                  {!isHelpPage && (
                    <S.Icon>
                      {count > 0 && (
                        <S.DotWrapper>
                          <S.RedDot>
                            <S.NotificationNumber>{count}</S.NotificationNumber>
                          </S.RedDot>
                        </S.DotWrapper>
                      )}
                      <S.NotificationsIcon />
                    </S.Icon>
                  )}
                </S.PlainButton>
                <NotificationsMenu />
              </S.MenuWrapper>
              <S.MenuWrapper>
                <S.PlainButton onClick={() => dispatch(toggleModal(MODAL_NAME.USER_MENU))}>
                  <S.Icon>{isAvatarSet ? <S.Avatar src={userAvatar} /> : <S.ProfileIcon />}</S.Icon>
                </S.PlainButton>
                <UserMenu />
              </S.MenuWrapper>
            </S.Icons>
          </S.CreateAndIconsWrapper>
        </S.ControlsWrapper>
      </S.HeaderContainer>
    </S.HeaderWrapper>
  );
};

export default memo(Header);
