import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 0;
  width: 95%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: #4D4D4D;
  margin-bottom: 16px;
`;

export const GalleriesWrapper = styled.div`
  max-width: 845px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

export const MockGallery = styled.div`
  width: 196px;
  height: 276px;
  background-color: transparent;
  margin-bottom: 20px;
`;
