import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import * as S from './CookiesModal.styles';
import { toggleModal } from '../../../redux/reducers/utils/action';
import { MODAL_NAME } from '../../../redux/reducers/utils/models';

export default memo(() => {
  const cookies = new Cookies();
  const cookiePreferences = cookies.get('cookiePreferences');
  const defaultCookies = {
    necessary: true,
    analytics: false,
    marketing: false,
  };
  const { necessary, analytics, marketing } = cookiePreferences
    ? JSON.parse(cookiePreferences)
    : defaultCookies;
  const [isBasicView, setIsBasicView] = useState(true);
  const [isNecessary, setIsNecessary] = useState(necessary);
  const [isAnalytics, setIsAnalytics] = useState(analytics);
  const [isMarketing, setIsMarketing] = useState(marketing);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(toggleModal(MODAL_NAME.COOKIES));
  const handleAccept = () => {
    const stringPreferences = JSON.stringify({
      necessary: isNecessary,
      analytics: isAnalytics,
      marketing: isMarketing,
    });
    cookies.set('cookiePreferences', stringPreferences);
    handleClose();
  };
  return (
    <S.ExternalWrapper>
      <S.Wrapper>
        <S.BackButton onClick={() => setIsBasicView(true)} disabled={isBasicView}>
          <S.ArrowLeft />
        </S.BackButton>
        <S.LogoWrapper isWide={isBasicView}>
          <S.Logo />
        </S.LogoWrapper>
        <S.BodyWrapper>
          <S.Title>
            Our use of <strong>cookies</strong> and other technologies
          </S.Title>
          <S.Text>
            I-3D uses cookies and other data to deliver, maintain and improve our services and ads.
            If you agree, we’ll collect, store and use data necessary to make i-3D work properly,
            understand how the service is used to make it better and better, and personalise content
            and ads. We also have partners that measure how our service is used. Click ‘Menage other
            options’ to review your options, or visit our{' '}
            <S.StyledLink to="/">privacy policy</S.StyledLink> or privacy settings at any time.
          </S.Text>
          <S.OptionsWrapper isPresent={!isBasicView}>
            <S.OptionsTitle>You can choose how personal data is used</S.OptionsTitle>
            <S.OptionWrapper>
              <S.Checkbox
                checked={isNecessary}
                disabled
                onClick={() => setIsNecessary(!isNecessary)}
              />
              <S.OptionPropsWrapper>
                <S.OptionTitle>Necessary</S.OptionTitle>
                <S.OptionDescription>Make i3D able to work properly</S.OptionDescription>
              </S.OptionPropsWrapper>
            </S.OptionWrapper>
            <S.OptionWrapper>
              <S.Checkbox checked={isAnalytics} onClick={() => setIsAnalytics(!isAnalytics)} />
              <S.OptionPropsWrapper>
                <S.OptionTitle>Analytics</S.OptionTitle>
                <S.OptionDescription>Allow us to make i3D better and better</S.OptionDescription>
              </S.OptionPropsWrapper>
            </S.OptionWrapper>
            <S.OptionWrapper>
              <S.Checkbox checked={isMarketing} onClick={() => setIsMarketing(!isMarketing)} />
              <S.OptionPropsWrapper>
                <S.OptionTitle>Marketing</S.OptionTitle>
                <S.OptionDescription>Make ads more interesting for you</S.OptionDescription>
              </S.OptionPropsWrapper>
            </S.OptionWrapper>
          </S.OptionsWrapper>
        </S.BodyWrapper>
        <S.ButtonsWrapper isWide={isBasicView}>
          <S.AgreeButton isWide={isBasicView} onClick={handleAccept}>
            {isBasicView ? 'I agree' : 'Save'}
          </S.AgreeButton>
          <S.MoreButton isWide={isBasicView} onClick={() => setIsBasicView(!isBasicView)}>
            {isBasicView ? 'Menage other options' : 'Cancel'}
          </S.MoreButton>
        </S.ButtonsWrapper>
      </S.Wrapper>
    </S.ExternalWrapper>
  );
});
