import { AnyAction } from 'redux';
import { Comment } from '../../../models/common';
import { updateProductCommentsAfterLike } from '../../../utils/updateProductCommentsAfterLike';
import { updateCommentsAfterDelete } from '../../../utils/updateCommentsAfterDelete';
import {
  GET_FEATURED_PRINT_REQUESTS,
  GET_FEATURED_PRINT_REQUESTS_FAILURE,
  GET_FEATURED_PRINT_REQUESTS_SUCCESS,
  GET_USER_PRINT_REQUESTS,
  GET_USER_PRINT_REQUESTS_FAILURE,
  GET_USER_PRINT_REQUESTS_SUCCESS,
  GET_VIEWED_PRINT_REQUEST,
  GET_VIEWED_PRINT_REQUEST_FAILURE,
  GET_VIEWED_PRINT_REQUEST_SUCCESS,
  SET_PRINT_REQUEST_SUBMIT_ERROR,
  SET_SUBMITTING_PRINT_REQUEST,
  GET_SEARCHED_PRINT_REQUEST,
  GET_SEARCHED_PRINT_REQUEST_SUCCESS,
  GET_SEARCHED_PRINT_REQUEST_FAILURE,
  PREPARE_PRINT_REQUEST_DETAILS_SUCCESS,
  SET_PR_COMMENTS,
  SET_PR_OFFERS,
  ADD_PR_PACKAGE,
  ADD_PR_PACKAGE_SUCCESS,
  ADD_PR_PACKAGE_FAIL,
  UPDATE_PR_PACKAGES_SUCCESS,
  SET_UPDATED_PR_OFFERS_SUCCESS,
  SET_UPDATED_PR_OFFERS_FAILURE,
  SET_UPDATED_PR_OFFERS,
  SET_PR_LIKE,
  REMOVE_PR_COMMENT_SUCCESS,
  UPLOAD_PR_FILES,
  UPLOAD_PR_FILES_SUCCESS,
  UPLOAD_PR_FILES_FAILURE,
  ADD_FREE_PR,
  ADD_FREE_PR_SUCCESS,
  ADD_FREE_PR_FAILURE,
} from './actionTypes';
import { PrintRequest } from './models';

export interface PrintRequestsState {
  userPrintRequests: PrintRequest[];
  isLoadingUserPrintRequests: boolean;
  featuredPrintRequests: PrintRequest[];
  isLoadingFeaturedPrintRequests: boolean;
  viewedPrintRequest: PrintRequest | null;
  isLoadingViewedPrintRequest: boolean;
  isSubmitting: boolean;
  errorMessage: string;
  searchedPrintRequests: PrintRequest[];
  isLoadingSearchedPrintRequests: boolean;
  isUploadingFiles: boolean;
  isAddingFreePR: boolean;
}

const initialState: PrintRequestsState = {
  userPrintRequests: [],
  isLoadingUserPrintRequests: false,
  featuredPrintRequests: [],
  isLoadingFeaturedPrintRequests: false,
  viewedPrintRequest: null,
  isLoadingViewedPrintRequest: false,
  isSubmitting: false,
  errorMessage: '',
  searchedPrintRequests: [],
  isLoadingSearchedPrintRequests: false,
  isUploadingFiles: false,
  isAddingFreePR: false
};

export default function (state = initialState, action: AnyAction): PrintRequestsState {
  switch (action.type) {
    case GET_USER_PRINT_REQUESTS: {
      return {
        ...state,
        isLoadingUserPrintRequests: true,
      };
    }
    case GET_USER_PRINT_REQUESTS_SUCCESS: {
      return {
        ...state,
        isLoadingUserPrintRequests: false,
        userPrintRequests: action.data,
      };
    }
    case GET_USER_PRINT_REQUESTS_FAILURE: {
      return {
        ...state,
        isLoadingUserPrintRequests: false,
      };
    }
    case GET_FEATURED_PRINT_REQUESTS: {
      return {
        ...state,
        isLoadingFeaturedPrintRequests: true,
      };
    }
    case GET_FEATURED_PRINT_REQUESTS_SUCCESS: {
      return {
        ...state,
        isLoadingFeaturedPrintRequests: false,
        featuredPrintRequests: action.data,
      };
    }
    case GET_FEATURED_PRINT_REQUESTS_FAILURE: {
      return {
        ...state,
        isLoadingFeaturedPrintRequests: false,
      };
    }
    case GET_VIEWED_PRINT_REQUEST: {
      return {
        ...state,
        isLoadingViewedPrintRequest: true,
      };
    }
    case GET_VIEWED_PRINT_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoadingViewedPrintRequest: false,
        viewedPrintRequest: action.data,
      };
    }
    case GET_VIEWED_PRINT_REQUEST_FAILURE: {
      return {
        ...state,
        isLoadingViewedPrintRequest: false,
      };
    }
    case GET_SEARCHED_PRINT_REQUEST: {
      return {
        ...state,
        isLoadingSearchedPrintRequests: true,
      };
    }
    case GET_SEARCHED_PRINT_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoadingSearchedPrintRequests: false,
        searchedPrintRequests: action.data,
      };
    }
    case GET_SEARCHED_PRINT_REQUEST_FAILURE: {
      return {
        ...state,
        isLoadingSearchedPrintRequests: false,
      };
    }
    case SET_SUBMITTING_PRINT_REQUEST: {
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };
    }
    case SET_PRINT_REQUEST_SUBMIT_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    }
    case PREPARE_PRINT_REQUEST_DETAILS_SUCCESS: {
      return {
        ...state,
        ...action.data,
      };
    }
    case SET_PR_COMMENTS: {
      const { comments } = action;
      const { viewedPrintRequest } = state;
      if (!viewedPrintRequest) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        viewedPrintRequest: { ...viewedPrintRequest, comments },
      };
    }
    case SET_PR_OFFERS: {
      const { offers } = action;
      const { viewedPrintRequest } = state;
      if (!viewedPrintRequest) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        viewedPrintRequest: { ...viewedPrintRequest, offers },
      };
    }
    case ADD_PR_PACKAGE: {
      return {
        ...state,
      };
    }
    case ADD_PR_PACKAGE_SUCCESS: {
      const { packages } = action;
      const { viewedPrintRequest } = state;
      if (!viewedPrintRequest) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        viewedPrintRequest: { ...viewedPrintRequest, packages },
      };
    }
    case ADD_PR_PACKAGE_FAIL: {
      return {
        ...state,
      };
    }
    case UPDATE_PR_PACKAGES_SUCCESS: {
      const { packages } = action;
      const { viewedPrintRequest } = state;
      if (!viewedPrintRequest) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        viewedPrintRequest: { ...viewedPrintRequest, packages },
      };
    }
    case SET_UPDATED_PR_OFFERS: {
      return {
        ...state,
        isLoadingViewedPrintRequest: true,
      };
    }
    case SET_UPDATED_PR_OFFERS_SUCCESS: {
      const { offers, status } = action.data;
      const { viewedPrintRequest } = state;
      if (!viewedPrintRequest) {
        return { ...state };
      }
      return {
        ...state,
        isLoadingViewedPrintRequest: false,
        viewedPrintRequest: { ...viewedPrintRequest, offers, status },
      };
    }
    case SET_UPDATED_PR_OFFERS_FAILURE: {
      return {
        ...state,
        isLoadingViewedPrintRequest: false,
      };
    }
    case SET_PR_LIKE: {
      const { viewedPrintRequest } = state;
      if (!viewedPrintRequest) {
        return {
          ...state,
        };
      }
      const { comments } = viewedPrintRequest;
      const { like } = action;
      const { commentId } = like;
      const maybeComment = comments.find((comment: Comment) => comment.id === commentId);
      if (!maybeComment) {
        return {
          ...state,
        };
      }
      const updatedComments = updateProductCommentsAfterLike(comments, maybeComment, like);
      return {
        ...state,
        viewedPrintRequest: { ...viewedPrintRequest, comments: updatedComments },
      };
    }
    case REMOVE_PR_COMMENT_SUCCESS: {
      const { viewedPrintRequest } = state;
      if (!viewedPrintRequest) {
        return {
          ...state,
        };
      }
      const { comments } = viewedPrintRequest;
      const { commentId } = action;
      const updatedComments = updateCommentsAfterDelete(comments, commentId);
      return {
        ...state,
        viewedPrintRequest: { ...viewedPrintRequest, comments: updatedComments },
      };
    }
    case UPLOAD_PR_FILES: {
      return {
        ...state,
        isUploadingFiles: true,
      };
    }
    case UPLOAD_PR_FILES_SUCCESS: {
      const {files} = action;
      const { viewedPrintRequest } = state;
      if (!viewedPrintRequest) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        isUploadingFiles: false,
        viewedPrintRequest: { ...viewedPrintRequest, files },
      };
    }
    case UPLOAD_PR_FILES_FAILURE: {
      return {
        ...state,
        isUploadingFiles: false,
      };
    }
    case ADD_FREE_PR: {
      return {
        ...state,
        isAddingFreePR: true,
      };
    }
    case ADD_FREE_PR_SUCCESS: {
      return {
        ...state,
        isAddingFreePR: false,
      };
    }
    case ADD_FREE_PR_FAILURE: {
      return {
        ...state,
        isAddingFreePR: false,
      };
    }
    default:
      return state;
  }
}
