import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PrintRequestsSlider from './Sliders/PrintRequestsSlider/PrintRequestsSlider';
import PrintedObjectsSlider from './Sliders/ObjectsForSellSlider/PrintedObjectsSlider';
import DesignRequestsSlider from './Sliders/DesignsSlider/DesignRequestsSlider';
// import NavigationBanner from './NavigationBanner/NavigationBanner';
import MainSegment from './MainSegment/MainSegment';
import Partners from './Partners/Partners';
import DesignsForSellSlider from './Sliders/DesignsSlider/DesignsForSellSlider';
import { getShortItems } from '../../redux/reducers/search/actions';
import { SimpleSearchFlag } from '../../models/common';

const Dashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getShortItems({ flag: SimpleSearchFlag.ALL }));
  }, [dispatch]);

  return (
    <div>
      {/* <NavigationBanner /> */}
      <MainSegment />
      <PrintRequestsSlider />
      <PrintedObjectsSlider />
      <DesignRequestsSlider />
      <DesignsForSellSlider />
      <Partners isHeaderShown/>
    </div>
  );
};

export default memo(Dashboard);
