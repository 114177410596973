import { AppState } from '..';
import { EmptyDesignRequest } from './models';
import { EmptyLicense } from '../designForSell/models';

export const designRequestState = (state: AppState) => state.designRequest;

export const isLoadingUserDesignRequests = (state: AppState) =>
  designRequestState(state).isLoadingUserDesignRequests;

export const isLoadingFeaturedDesignRequests = (state: AppState) =>
  designRequestState(state).isLoadingFeaturedDesignRequests;

export const isLoadingViewedDesignRequest = (state: AppState) =>
  designRequestState(state).isLoadingViewedDesignRequest;

export const usersDesingRequests = (state: AppState) =>
  designRequestState(state).userDesignRequests;

export const featuredDesignRequests = (state: AppState) =>
  designRequestState(state).featuredDesignRequests;

export const viewedDesignRequest = (state: AppState) =>
  designRequestState(state).viewedDesignRequest || EmptyDesignRequest;

export const isSubmitting = (state: AppState) => designRequestState(state).isSubmitting;

export const errorMessage = (state: AppState) => designRequestState(state).errorMessage;

export const designOffers = (state: AppState) => viewedDesignRequest(state).offers;

export const designLicense = (state: AppState) =>
  viewedDesignRequest(state).license || EmptyLicense;

  export const areDesignFilesUploading = (state: AppState) => designRequestState(state).isUploadingFiles;
