import React, { useEffect, memo } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdminBoard, { ADMIN_BOARD } from './components/Admin/AdminBoard';
import AdminRoute from './utils/adminRoute';
import AdvancedSearchPage from './components/AdvancedSearchPage/AdvancedSearchPage';
import Dashboard from './components/Dashboard/Dashboard';
import DeliveryAddressModal from './components/Modals/DeliveryAddressModal';
import DeviceModal from './components/Modals/DeviceModal/DeviceModal';
import Footer from './components/Common/Footer/Footer';
import Header from './components/Common/Header/Header';
import HeaderMainMenu from './components/Common/Header/HeaderMainMenu/HeaderMainMenu';
import HeaderMobile from './components/Common/Header/HeaderMobile';
import PhotosViewer from './components/Common/PhotosViewer/PhotosViewer';
import PrintingOfferConfirmation from './components/PrintingOfferConfirmation/PrintingOfferConfirmation';
import DesignOfferConfirmation from './components/PrintingOfferConfirmation/DesignOfferConfirmation';
import ProfilePage from './components/Profile/ProfilePage';
import ScrollToTop from './components/Common/ScrollTop/ScrollTop';
import SearchSimple from './components/SearchSimple/SearchSimple';
import UserDashboard, { USER_DASHBOARD_SECTION } from './components/UserDashboard/UserDashboard';
import UserSettings, { USER_SETTINGS } from './components/UserSettings/UserSettings';
import { createBrowserHistory } from 'history';
import { getSettings, getUserNotifications } from './redux/reducers/auth/actions';
import { PRODUCT_TYPE } from './utils/productType';
// import { ProtectedRoute } from './utils/route';
import { Elements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { USER_PROFILE_SECTION } from './models/user';
import ModalWrapper from './components/Modals/ModalWrapper';
import Login from './components/Common/Login/Login';
import SignUp from './components/Common/SignUp/SignUp';
import FillProfile from './components/Common/FillProfile/FillProfile';
import CookiesModal from './components/Modals/CookiesModal/CookiesModal';
import Page404 from './components/Page404/Page404';
import './App.css';
import PurchaseProduct from './components/PurchaseProduct/PurchaseProduct';
import Cookies from 'universal-cookie';
import CreateDesignRequest from './components/CreateProduct/CreateDesignRequest';
import CreateDesignForSell from './components/CreateProduct/CreateDesignForSell';
import { toggleModal, closeModals } from './redux/reducers/utils/action';
import { MODAL_NAME } from './redux/reducers/utils/models';
import DesignRequestDetails from './components/ProductDetails/DesignRequestDetails';
import DesignForSellDetails from './components/ProductDetails/DesignForSellDetails';
import ObjectForSellDetails from './components/ProductDetails/ObjectForSellDetails';
import CreatePrintRequest from './components/CreateProduct/CreatePrintRequest';
import PrintRequestDetails from './components/ProductDetails/PrintRequestDetails';
import CreateObjectForSell from './components/CreateProduct/CreateObjectForSell';
import HelpCenterRoutes from './Routes/HelpCenterRoutes';
import ConfirmAccountPage from './components/ConfirmAccountPage/ConfirmAccountPage';
import ChangeUserEmailConfirmation from './components/UserSettings/EmailConfirmation/ChangeUserEmailConfirmation';
import ChangeUserPasswordConfirmation from './components/UserSettings/ChangeUserPasswordConfirmation/ChangeUserPasswordConfirmation';
import AccountDeleteConfirmation from './components/UserSettings/AccountDeleteConfirmation/AccountDeleteConfirmation';
import { loadStripe } from '@stripe/stripe-js';
import { isUserLoggedIn } from './redux/reducers/auth/selectors';
import AboutUs from './components/AboutUs/AboutUs';
import ProtectedRoute from './utils/route';

export const history = createBrowserHistory();
const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
const App = () => {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const cookiesPreferences = cookies.get('cookiePreferences');
  useEffect(() => {
    if (!cookiesPreferences) {
      dispatch(toggleModal(MODAL_NAME.COOKIES));
    }
  }, [cookiesPreferences, dispatch]);
  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);
  history.listen(() => {
    dispatch(closeModals());
  });
  const isLoggedIn = useSelector(isUserLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      const getNotification = () => dispatch(getUserNotifications({}));
      getNotification();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getSettings());
      const intervalId = setInterval(() => {
        dispatch(getUserNotifications({}));
      }, 30000);

      return () => clearInterval(intervalId);
    }
  }, [isLoggedIn]);

  return (
    <Router>
      <ScrollToTop />
      <ModalWrapper title={MODAL_NAME.LOGIN} child={<Login />} />
      <ModalWrapper title={MODAL_NAME.SIGNUP} child={<SignUp />} />
      <ModalWrapper title={MODAL_NAME.FILL_PROFILE} child={<FillProfile />} />
      <ModalWrapper title={MODAL_NAME.DEVICE_OPERATION} child={<DeviceModal />} />
      <ModalWrapper title={MODAL_NAME.COOKIES} child={<CookiesModal />} />
      <DeliveryAddressModal />
      <Header />
      <HeaderMobile />
      <HeaderMainMenu />
      <DeliveryAddressModal />
      <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <div onClick={() => dispatch(closeModals())} style={{ minHeight: '100vh' }}>
          <Elements stripe={stripePromise}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/admin" element={<AdminBoard />} />
              <Route path="/confirmation/:token" element={<ConfirmAccountPage />} />
              {/* <AdminRoute
                path="/admin/users"
                element={<AdminBoard selectedSection={ADMIN_BOARD.USERS} />}
              />
              <AdminRoute
                path="/admin/other"
                element={<AdminBoard selectedSection={ADMIN_BOARD.OTHER} />}
              /> */}
              {/* <ProtectedRoute path="/print-request/new" element={<CreatePrintRequest />} />
              <ProtectedRoute path="/object/new" element={<CreateObjectForSell />} />
              <ProtectedRoute path="/design/new" element={<CreateDesignForSell />} />
              <ProtectedRoute path="/design-request/new" element={<CreateDesignRequest />} />
              <ProtectedRoute
                path="/print-request/:printId/offer"
                element={<PrintingOfferConfirmation />}
              />
              <ProtectedRoute
                path="/design-request/:printId/offer"
                element={<DesignOfferConfirmation />}
              />
              <ProtectedRoute
                path="/dashboard/printing-requests"
                element={
                  <UserDashboard selectedSection={USER_DASHBOARD_SECTION.PRINTING_REQUESTS} />
                }
              />
              <ProtectedRoute
                path="/object-for-sell/:printId/purchase"
                element={<PurchaseProduct productType={PRODUCT_TYPE.OBJECT_FOR_SELL} />}
              />
              <ProtectedRoute
                path="/design-for-sell/:printId/purchase"
                element={<PurchaseProduct productType={PRODUCT_TYPE.DESIGN_FOR_SELL} />}
              />
              <ProtectedRoute
                path="/dashboard/printing-offers"
                element={<UserDashboard selectedSection={USER_DASHBOARD_SECTION.PRINTING_OFFERS} />}
              />
              <ProtectedRoute
                path="/dashboard/print-request/:id?"
                element={
                  <UserDashboard
                    selectedSection={USER_DASHBOARD_SECTION.PRINTING_REQUEST_DETAILS}
                  />
                }
              />
              <ProtectedRoute
                path="/dashboard/design-request/:id?"
                element={
                  <UserDashboard selectedSection={USER_DASHBOARD_SECTION.DESIGN_REQUEST_DETAILS} />
                }
              />
              <ProtectedRoute
                path="/dashboard/object-for-sell/:id?"
                element={
                  <UserDashboard selectedSection={USER_DASHBOARD_SECTION.OBJECT_FOR_SELL_DETAILS} />
                }
              />
              <ProtectedRoute
                path="/dashboard/design-for-sell/:id?"
                element={
                  <UserDashboard selectedSection={USER_DASHBOARD_SECTION.DESIGN_FOR_SELL_DETAILS} />
                }
              />
              <ProtectedRoute
                path="/dashboard/purchase/design-for-sell/:id?"
                element={
                  <UserDashboard selectedSection={USER_DASHBOARD_SECTION.PURCHASE_DESIGN_DETAILS} />
                }
              />
              <ProtectedRoute
                path="/dashboard/purchase/object-for-sell/:id?"
                element={
                  <UserDashboard
                    selectedSection={USER_DASHBOARD_SECTION.PURCHASE_OBJECT_FOR_SELL_DETAILS}
                  />
                }
              />
              <ProtectedRoute
                path="/dashboard/my-printing-offer/:id?"
                element={<UserDashboard selectedSection={USER_DASHBOARD_SECTION.OFFER_DETAILS} />}
              />
              <ProtectedRoute
                path="/dashboard/my-design-offer/:id?"
                element={
                  <UserDashboard selectedSection={USER_DASHBOARD_SECTION.DESIGN_OFFER_DETAILS} />
                }
              />
              <ProtectedRoute
                path="/dashboard/design-requests"
                element={<UserDashboard selectedSection={USER_DASHBOARD_SECTION.DESIGN_REQUESTS} />}
              />
              <ProtectedRoute
                path="/dashboard/design-offers"
                element={<UserDashboard selectedSection={USER_DASHBOARD_SECTION.DESIGN_OFFERS} />}
              />
              <ProtectedRoute
                path="/dashboard/my-selling-objects"
                element={
                  <UserDashboard selectedSection={USER_DASHBOARD_SECTION.MY_SELLING_OBJECTS} />
                }
              />
              <ProtectedRoute
                path="/dashboard/my-shopping-objects"
                element={
                  <UserDashboard selectedSection={USER_DASHBOARD_SECTION.MY_SHOPPING_OBJECTS} />
                }
              />
              <ProtectedRoute
                path="/dashboard/my-selling-designs"
                element={
                  <UserDashboard selectedSection={USER_DASHBOARD_SECTION.MY_SELLING_DESIGNS} />
                }
              />
              <ProtectedRoute
                path="/dashboard/my-shopping-designs"
                element={
                  <UserDashboard selectedSection={USER_DASHBOARD_SECTION.MY_SHOPPING_DESIGNS} />
                }
              /> */}
              <Route
                path="/profile/:userId"
                element={<ProfilePage selectedSection={USER_PROFILE_SECTION.ABOUT} />}
              />
              <Route
                path="/profile/:userId/about"
                element={<ProfilePage selectedSection={USER_PROFILE_SECTION.ABOUT} />}
              />
              <Route
                path="/profile/:userId/market"
                element={<ProfilePage selectedSection={USER_PROFILE_SECTION.MARKET} />}
              />
              <Route
                path="/profile/:userId/photos"
                element={<ProfilePage selectedSection={USER_PROFILE_SECTION.PHOTOS} />}
              />
              <Route path="/profile/:userId/gallery/:galleryId" element={<PhotosViewer />} />
              <Route
                path="/profile/:userId/reviews"
                element={<ProfilePage selectedSection={USER_PROFILE_SECTION.REVIEWS} />}
              />
              <Route
                path="/profile/:userId/workshop"
                element={<ProfilePage selectedSection={USER_PROFILE_SECTION.WORKSHOP} />}
              />
              <Route path="/about-us" element={<AboutUs />} />
              {/* <ProtectedRoute
                path="/settings/personal-information"
                element={<UserSettings selectedSection={USER_SETTINGS.PERSONAL_INFORMATION} />}
              />
              <ProtectedRoute
                path="/settings/photos"
                element={<UserSettings selectedSection={USER_SETTINGS.PHOTOS} />}
              />
              <ProtectedRoute
                path="/settings/workshop"
                element={<UserSettings selectedSection={USER_SETTINGS.WORKSHOP} />}
              />
              <ProtectedRoute
                path="/settings/general-preferences"
                element={<UserSettings selectedSection={USER_SETTINGS.GENERAL_PREFERENCES} />}
              />
              <ProtectedRoute
                path="/settings/security"
                element={<UserSettings selectedSection={USER_SETTINGS.LOGIN_AND_SECURITY} />}
              />
              <ProtectedRoute
                path="/settings/payments"
                element={<UserSettings selectedSection={USER_SETTINGS.PAYMENTS} />}
              /> */}
              <Route path="/print-request/:printRequestId?" element={<PrintRequestDetails />} />
              <Route
                path="/print-request/:printRequestId/:added"
                element={<PrintRequestDetails />}
              />
              <Route path="/design-request/:designRequestId?" element={<DesignRequestDetails />} />
              <Route
                path="/design-request/:designRequestId/:added"
                element={<DesignRequestDetails />}
              />
              <Route path="/design-for-sell/:designForSellId?" element={<DesignForSellDetails />} />
              <Route
                path="/design-for-sell/:designForSellId/:added"
                element={<DesignForSellDetails />}
              />
              <Route path="/object-for-sell/:objectForSellId?" element={<ObjectForSellDetails />} />
              <Route
                path="/object-for-sell/:objectForSellId/:added"
                element={<ObjectForSellDetails />}
              />
              <Route path="/search" element={<SearchSimple />} />
              <Route
                path="/search/print-requests/:query?"
                element={<AdvancedSearchPage itemsToSearch={PRODUCT_TYPE.PRINT_REQUEST} />}
              />
              <Route
                path="/search/objects-for-sell/:query?"
                element={<AdvancedSearchPage itemsToSearch={PRODUCT_TYPE.OBJECT_FOR_SELL} />}
              />
              <Route
                path="/search/design-requests/:query?"
                element={<AdvancedSearchPage itemsToSearch={PRODUCT_TYPE.DESIGN_REQUEST} />}
              />
              <Route
                path="/search/designs-for-sell/:query?"
                element={<AdvancedSearchPage itemsToSearch={PRODUCT_TYPE.DESIGN_FOR_SELL} />}
              />
              <Route path="/email-confirmation/:code?" element={<ChangeUserEmailConfirmation />} />
              <Route
                path="/password-confirmation/:code?"
                element={<ChangeUserPasswordConfirmation />}
              />
              <Route
                path="/account-delete-confirmation/:code?"
                element={<AccountDeleteConfirmation />}
              />
              <Route path="/search" element={<SearchSimple />} />
              <Route path="/help-center" element={<HelpCenterRoutes />} />
              <Route element={<Page404 />} />
            </Routes>
          </Elements>
        </div>
      </div>
      <Footer />
    </Router>
  );
};

export default memo(App);
