import React from 'react';
import * as S from './EmptyGalleries.style';

export default () => {
  return (
    <S.EmptyGalleriesContainer>
      <S.LeftGalleryConstruct />
      <S.MiddleGalleryConstruct />
      <S.RightGalleryConstruct />
      <S.EmptyLongBox />
      <S.EmptyLongBox />
      <S.EmptyLongBox />
      <S.EmptyLongBox />
    </S.EmptyGalleriesContainer>
  );
};
