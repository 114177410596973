import styled from 'styled-components';
import { MdSearch } from 'react-icons/md';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

export const SortWrapper = styled.div`
  height: 100%;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.45px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const Label = styled.span`
  margin-right: 9px;
  font-size: 14px;
  letter-spacing: 0.14px;
  color: #4D4D4D;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  left: 16px;
`;

export const ArrowLeft = styled(IoIosArrowBack)`
  height: 26px;
  width: 26px;
  cursor: pointer;
  opacity: 0.7;
`;

export const ArrowRight = styled(IoIosArrowForward)`
  height: 39px;
  width: 39px;
  cursor: pointer;
  opacity: 0.7;
`;

export const ArrowLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.14px;
  color: #000000;
  padding-left: 8px;
`;

export const PageInput = styled.input`
  box-sizing: border-box;
  width: 48px;
  height: 40px;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  padding: 12px 14px;
  font-size: 14px;
  margin: 0 8px;
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px #0077df;
  }
`;

export const PerPage = styled.div`
  font-size: 14px;
  margin-right: 2px;
`;

export const BottomPaginationWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  max-width: 970px;
  width: 100%;
  box-sizing: border-box;
  font-size: 9px;
  align-items: center;
  color: #4D4D4D;
  padding: 16px 5px 16px 10px;
  border-top: 1px solid #efefef;
  justify-content: space-between;
  @media (max-width: 508px) {
    justify-content: space-evenly;
  }
`;

export const SelectPerPage = styled.div`
  box-sizing: border-box;
  width: 96px;
  height: 41px;
`;

export const InputWrapper = styled.div`
  box-sizing: border-box;
  width: 304px;
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  @media (max-width: 508px) {
    margin-bottom: 12px;
  }
`;

export const MagnifyingGlass = styled(MdSearch)`
  width: 28px;
  height: 28px;
  padding-right: 5px;
  cursor: pointer;
`;

export const Search = styled.input`
  height: 100%;
  border: none;
  width: 304px;
  font-size: 15px;
  padding: 0 14px;
  margin-right: 10px;
  letter-spacing: 0.14px;
  border-radius: 4px;
  box-sizing: border-box;
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px #0077df;
  }
`;

export const SearchWrapper = styled.div`
  margin: 16px 0 14px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  @media (max-width: 508px) {
    justify-content: space-evenly;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 508px) {
    margin-bottom: 12px;
  }
`;

export const EmptySpaceWrapper = styled.div`
  box-sizing: border-box;
  width: 304px;
  height: 40px;
  @media (max-width: 508px) {
  }
`;
