import styled from 'styled-components';
import themes from '../../../Assets/colorThemes';
import { MdPerson, MdStar } from 'react-icons/md';

export const Wrapper = styled.div`
  width: 706px;
  border-radius: 3px;
  border: 1px solid ${themes.default.grayEf};
  margin-bottom: 20px;
  padding: 16px 14px;
  display: flex;
  user-select: text;
`;

export const Person = styled(MdPerson)`
  background-color: ${themes.default.gray9a};
  color: ${themes.default.white};
  border-radius: 100%;
  width: 20px;
  padding: 1px 2px 3px 2px;
  height: 20px;
  margin-right: 9px;
  margin-top: 2px;
  cursor: pointer;
`;

export const Avatar = styled.img`
  border-radius: 100%;
  width: 20px;
  height: 20px;
  margin-right: 9px;
  margin-top: 2px;
  cursor: pointer;
`;

export const BodyWrapper = styled.div`
  flex-grow: 1;
`;

export const ReviewDataWrapper = styled.div`
  padding: 1px;
  margin-bottom: 12px;
`;

export const LineBlock = styled.div`
  display: flex;
  align-items: baseline;
  padding-top: 1px;
`;

export const Author = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-right: 13px;
  min-width: 94px;
  height: 21px;
  cursor: pointer;
`;

export const ReviewType = styled.div`
  letter-spacing: 0.12px;
  color: ${themes.default.gray9a};
  font-size: 12px;
  font-weight: 500;
`;

export const StarsWrapper = styled.div`
  margin: 2px 0 1px -2px;
`;

export const FullStar = styled(MdStar)`
  color: ${themes.default.yellow};
`;

export const ShadowStar = styled(FullStar)`
  color: ${themes.default.grayBf};
`;

export const CreatedAt = styled.div`
  font-size: 12px;
  letter-spacing: -0.4px;
  word-spacing: 2px;
`;

export const ReviewText = styled.div`
  white-space: pre-line;
  width: 650px;
  letter-spacing: 0.14px;
  color: ${themes.default.gray4d};
  font-size: 14px;
  background-color: transparent;
  border: none;
  overflow: auto;
  resize: none;
  font-family: inherit;
  text-align: justify;
  line-height: 20px;
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px ${themes.default.blue};
  }
`;
