import styled from 'styled-components';
import themes from '../../Assets/colorThemes';
import emptyStatesSVGs from '../../Assets/EmptyStates/emptyStates';

export const Wrapper = styled.div``;

export const DescriptionWrapper = styled.div`
  padding: 20px 0;
  border-bottom: ${themes.default.grayEf} solid 1px;
`;

export const Description = styled.div.attrs({ target: '_blank' })`
  white-space: pre-line;
  font-size: 16px;
  color: ${themes.default.gray4d};
  max-width: 520px;
  width: 100%;
  overflow: auto;
  overflow-wrap: break-word;
  resize: none;
  background-color: ${themes.default.white};
  line-height: 1.5;
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: ${themes.default.white};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${themes.default.grayBf};
  }
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px ${themes.default.blue};
  }
  a {
    text-decoration: underline;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 16px;
`;

export const Header = styled.div`
  height: 29px;
  font-size: 24px;
  font-weight: 900;
  color: ${themes.default.gray4d};
  margin-bottom: 10px;
`;

export const SecondaryHeader = styled.div<{ isClickable?: boolean }>`
  height: 17px;
  font-size: 14px;
  letter-spacing: 0.14px;
  color: ${themes.default.gray4d};
  margin: 0 0 16px auto;
  ${(props: any) => (props.isClickable ? 'cursor: pointer;' : '')}
`;

export const AddressLine = styled(SecondaryHeader)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ShadowHeader = styled(SecondaryHeader)`
  color: ${themes.default.gray9a};
  margin-left: 8px;
`;

export const MarketHeader = styled.div`
  font-size: 16px;
  height: 20px;
  text-align: left;
  color: ${themes.default.gray4d};
`;

export const Bold = styled.span`
  font-weight: 900;
`;

export const PhotosWrapper = styled(DescriptionWrapper)`
  box-sizing: border-box;
  padding: 11px 0 20px;
`;

export const MarketWrapper = styled(PhotosWrapper)``;

export const LocationWrapper = styled(PhotosWrapper)``;

export const WorkshopWrapper = styled(PhotosWrapper)``;

export const ReviewsWrapper = styled(PhotosWrapper)``;

export const SliderWrapper = styled.div`
  margin-bottom: 20px;
`;

export const MapWrapper = styled.div`
  max-width: 844px;
  width: 100%;
  height: 475px;
  background-color: ${themes.default.grayE2};
  margin-bottom: 18px;
  overflow: hidden;
`;

export const FullMap = styled.div`
  text-align: right;
  text-decoration: underline;
  font-size: 14px;
  width: 100%;
  letter-spacing: 0.14px;
  color: ${themes.default.gray4d};
  cursor: pointer;
  overflow: hidden;
`;

export const NoResultsWrapper = styled.div`
  height: 276px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoResults = styled.div`
  margin: auto;
  font-size: 20px;
  font-weight: 500;
  color: ${themes.default.gray4d};
`;

export const EmptyAbout = styled.img.attrs({ src: emptyStatesSVGs.about })``;
