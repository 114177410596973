import React, { memo, ReactNode } from 'react';
import * as S from './PageWithMenuWrapper.styles';

const PageWithMenuWrapper = ({
  menu,
  body,
  isHelpCenter,
}: {
  menu: ReactNode;
  body: ReactNode;
  isHelpCenter?: boolean;
}) => (
  <S.Wrapper isHelpCenter={isHelpCenter}>
    <S.MenuWrapper>
      <S.InnerMenuWrapper>{menu}</S.InnerMenuWrapper>
    </S.MenuWrapper>
    <S.BodyWrapper>{body}</S.BodyWrapper>
  </S.Wrapper>
);

export default memo(PageWithMenuWrapper);
