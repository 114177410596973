import styled from 'styled-components';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import U from '../../Assets/commonCSS';

export const ExternalWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;
`;

export const Wrapper = styled.div`
  /* height: 100%;
  width: 100%; */
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  /* max-width: 95vw; */
`;

export const TopWrapper = styled.div`
  box-sizing: border-box;
  height: 86px;
  margin: 0 auto;
  display: flex;
  padding: 86px 16px 0;
  justify-content: space-between;
`;

export const Back = styled.button`
  color: #4D4D4D;
  cursor: pointer;
  letter-spacing: 0.24px;
  font-size: 15px;
  font-weight: 700;
  display: flex;
  border: none;
  background-color: transparent;
`;

export const BackToGalleries = styled.div`
  padding-top: 10px;
  margin-left: -4px;
  font-size: 14px;
`;

export const BackIcon = styled(BsChevronLeft)`
  font-size: 24px;
  position: relative;
  top: 6px;
  left: -7px;
`;

export const CloseWrapper = styled.button`
  align-self: flex-end;
  box-sizing: border-box;
  background-color: #f7f7f7;
  border-radius: 100%;
  border: none;
  padding: 4px;
  cursor: pointer;
  height: 42px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Close = styled(MdClose)`
  height: 31px;
  width: 31px;
  color: #333333;
  background-color: #f7f7f7;
  border-radius: 100%;
  cursor: pointer;
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
  margin-bottom: 32px;
  height: 100%;
  @media (max-width: 800px) {
    justify-content: space-evenly;
  }
`;

export const SelectedPhotoWrapper = styled.div`
  display: flex;
  flex: 12;
  height: 100%;
  width: 90vw;
  height: 80vh;
  margin-right: auto;
  position: relative;
  cursor: pointer;
`;

export const SelectedPhoto = styled.div<{ source: string }>`
  height: 100%;
  width: 100%;
  background-color: #e2e2e2;
  border-radius: 8px;
  position: relative;
  ${(props: { source: string }) => `
  background-image: url(${props.source});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  `}
`;

export const Navigation = styled.button`
  box-shadow: 0px 2px 4px #00000029;
  border: 1px solid #bfbfbf;
  background-color: white;
  position: absolute;
  height: 40px;
  width: 40px;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 100%;
  cursor: pointer;
`;

export const NavigateLeft = styled(Navigation)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 20px;
  z-index: ${U.zIndex1};
`;

export const LeftIcon = styled(BsChevronLeft)`
  color: #707070;
  min-width: 30px;
  height: auto;
  margin-right: 5px;
`;

export const NavigateRight = styled(Navigation)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: -20px;
  z-index: ${U.zIndex1};
`;

export const RightIcon = styled(BsChevronRight)`
  color: #707070;
  min-width: 30px;
  height: auto;
  margin-left: 3px;
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
`;

export const EmptyColumn = styled.div`
  flex: 0.5;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  color: #4D4D4D;
  padding-top: 41px;
  margin-bottom: 5px;
`;

export const CreatedAt = styled.div`
  font-size: 12px;
  letter-spacing: 0.12px;
  color: #9a9a9a;
  margin-bottom: 27px;
`;

export const MiniaturesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

interface MiniatureI {
  isSelected: boolean;
  source: string;
}

export const Miniature = styled.button<MiniatureI>`
  box-sizing: border-box;
  width: 88px;
  height: 88px;
  background-color: #e2e2e2;
  border-radius: 4px;
  margin-bottom: 20px;
  border: none;
  background-color: transparent;
  ${(props: MiniatureI) => `
  background-image: url(${props.source});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;`}
  ${(props: MiniatureI) =>
    props.isSelected ? `border: 3px solid #707070;` : 'cursor: pointer;'}
  cursor: pointer;
`;

export const MockPhoto = styled.div`
  width: 88px;
  height: 88px;
  background-color: #e2e2e2;
  background-color: transparent;
  margin-bottom: 20px;
  margin-bottom: 20px;
`;
