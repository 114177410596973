import React from 'react';
import * as S from '../CreateProduct.styles';

interface TextAreaProps {
  handleInputChange: (e: any) => void;
  label?: string;
  value: string;
  optional?: boolean;
  required?: boolean;
  max?: number;
  hasCounter?: boolean;
  styles?: string;
  description?: string;
}

const TextArea = ({
  handleInputChange,
  label,
  value,
  optional = false,
  required = false,
  hasCounter = false,
  max,
  styles,
  description,
}: TextAreaProps) =>  (
    <S.InputWrapper>
      {label && (
        <S.LabelWrapper>
          <S.Label>{label}</S.Label>
          {optional && <S.OptionalLabel>(optional)</S.OptionalLabel>}
        </S.LabelWrapper>
      )}
      <S.TextArea
        onChange={(e: any) => handleInputChange(e.target.value)}
        value={value}
        required={required}
        maxLength={max}
        styles={styles}
      ></S.TextArea>
      <S.TextAreaUtilsWrapper>
        {description && <S.TextAreaDescription>{description}</S.TextAreaDescription>}
        {hasCounter && (
          <S.Counter>
            {value.length} / {max}
          </S.Counter>
        )}
      </S.TextAreaUtilsWrapper>
    </S.InputWrapper>
  );

export default TextArea;
