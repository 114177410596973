import React, { memo } from 'react';
import { Device } from '../../../../models/market';
import moment from 'moment';
import * as PS from '../../../SearchSimple/SearchSimple.style';
import * as S from '../Workshop.styles';

const DevicesMapper = ({ devices }: { devices: Device[] }) => {
  if (devices.length === 0) {
    return (
      <PS.NoResultsWrapper>
        <PS.TextWrapper>
          <PS.NoResultsTitle>No printers/devices added yet.</PS.NoResultsTitle>
        </PS.TextWrapper>
      </PS.NoResultsWrapper>
    );
  }

  return (
    <S.ItemsWrapper>
      {devices.map(({ name, printerType, createdAt, image }: Device, index: number) => (
        <S.Item key={index}>
          <S.PrinterImage source={image} />
          <S.ItemDetailsWrapper>
            <S.ItemName>{name}</S.ItemName>
            {printerType && <S.ItemDetail>Printer type: {printerType}</S.ItemDetail>}
            <S.ItemDetail>Added: {moment(createdAt).format('Do MMM YYYY')}</S.ItemDetail>
          </S.ItemDetailsWrapper>
        </S.Item>
      ))}
    </S.ItemsWrapper>
  );
};

export default memo(DevicesMapper);
