import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './CreateMenu.styles';
import { isModalOpen } from '../../../../redux/reducers/utils/selectors';
import { MODAL_NAME } from '../../../../redux/reducers/utils/models';
import { toggleModal } from '../../../../redux/reducers/utils/action';

const PRINT_REQUEST_URL = '/print-request/new';
const DESIGN_URL = '/design/new';
const DESIGN_REQUEST_URL = '/design-request/new';
const OBJECTS_TO_SELL_URL = '/object/new';

const CreateMenu = () => {
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const isVisible = useSelector(isModalOpen(MODAL_NAME.CREATE_MENU));
   const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleOptionSelect = (redirectUrl: string) => {
    if (isLoggedIn) {
      dispatch(toggleModal(MODAL_NAME.CREATE_MENU));
      //TODO
      // history(redirectUrl);
    } else {
      dispatch(toggleModal(MODAL_NAME.LOGIN));
    }
  };

  return (
    <S.CreateMenuWrapper isVisible={isVisible}>
      <S.Title>Create</S.Title>
      <S.MenuOption onClick={() => handleOptionSelect(PRINT_REQUEST_URL)}>
        Print request
      </S.MenuOption>
      <S.MenuOption onClick={() => handleOptionSelect(DESIGN_REQUEST_URL)}>
        Design request
      </S.MenuOption>
      <S.MenuOption onClick={() => handleOptionSelect(DESIGN_URL)}>Design for sale</S.MenuOption>
      <S.MenuOption onClick={() => handleOptionSelect(OBJECTS_TO_SELL_URL)}>
        Object for sale
      </S.MenuOption>
    </S.CreateMenuWrapper>
  );
};

export default memo(CreateMenu);
