export const GET_USER_DESIGNS_FOR_SELL = 'GET_USER_DESIGNS_FOR_SELL';
export const GET_USER_DESIGNS_FOR_SELL_SUCCESS = 'GET_USER_DESIGNS_FOR_SELL_SUCCESS';
export const GET_USER_DESIGNS_FOR_SELL_FAILURE = 'GET_USER_DESIGNS_FOR_SELL_FAILURE';
export const GET_FEATURED_DESIGNS_FOR_SELL = 'GET_FEATURED_DESIGNS_FOR_SELL';
export const GET_FEATURED_DESIGNS_FOR_SELL_SUCCESS = 'GET_FEATURED_DESIGNS_FOR_SELL_SUCCESS';
export const GET_FEATURED_DESIGNS_FOR_SELL_FAILURE = 'GET_FEATURED_DESIGNS_FOR_SELL_FAILURE';
export const GET_VIEWED_DESIGN_FOR_SELL = 'GET_VIEWED_DESIGN_FOR_SELL';
export const GET_VIEWED_DESIGN_FOR_SELL_SUCCESS = 'GET_VIEWED_DESIGN_FOR_SELL_SUCCESS';
export const GET_VIEWED_DESIGN_FOR_SELL_FAILURE = 'GET_VIEWED_DESIGN_FOR_SELL_FAILURE';
export const SET_SUBMITTING_DESIGN_FOR_SELL = 'SET_SUBMITTING_DESIGN_FOR_SELL';
export const SET_DESIGN_FOR_SELL_SUBMIT_ERROR = 'SET_DESIGN_FOR_SELL_SUBMIT_ERROR';
export const CREATE_DESIGN_FOR_SELL = 'CREATE_DESIGN_FOR_SELL';
export const CREATE_DESIGN_FOR_SELL_SUCCESS = 'CREATE_DESIGN_FOR_SELL_SUCCESS';
export const CREATE_DESIGN_FOR_SELL_FAILURE = 'CREATE_DESIGN_FOR_SELL_FAILURE';
export const PREPARE_DESIGN_FOR_SELL_DETAILS = 'PREPARE_DESIGN_FOR_SELL_DETAILS';
export const PREPARE_DESIGN_FOR_SELL_DETAILS_SUCCESS = 'PREPARE_DESIGN_FOR_SELL_DETAILS_SUCCESS';
export const SET_DFS_COMMENTS = 'SET_DFS_COMMENTS';
export const SET_DFS_LIKE = 'SET_DFS_LIKE';
export const ADD_FREE_DFS = 'ADD_FREE_DFS';
export const ADD_FREE_DFS_SUCCESS = 'ADD_FREE_DFS_SUCCESS';
export const ADD_FREE_DFS_FAILURE = 'ADD_FREE_DFS_FAILURE';
export const REMOVE_DFS_COMMENT = 'REMOVE_DFS_COMMENT';
export const REMOVE_DFS_COMMENT_SUCCESS = 'REMOVE_DFS_COMMENT_SUCCESS';
export const REMOVE_DFS_COMMENT_FAILURE = 'REMOVE_DFS_COMMENT_FAILURE';
export const UPLOAD_DFS_FILES = 'REMOVE_DFS_COMMENT';
export const UPLOAD_DFS_FILES_SUCCESS = 'UPLOAD_DFS_FILES_SUCCESS';
export const UPLOAD_DFS_FILES_FAILURE = 'UPLOAD_DFS_FILES_FAILURE';