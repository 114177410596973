import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  font-family: Inter;
  width: 100%;
  max-width: 1296px;
  margin: 0 auto;
  padding: 0 32px;
  margin-bottom: 120px;
`;

export const HeaderWrapper = styled.div`
display: flex;
padding-bottom: 60px;
`

export const Header = styled.h2`
margin: 0;
font-size: 30px;
font-weight: 700;
`

export const PeopleWrapper = styled.div`
display: flex;
flex-direction: column;
`

export const ImportantPplWrapper = styled.div`
display: flex;
justify-content: space-around;
padding-bottom: 80px;
`

export const LessImportantPplWrapper = styled.div`
display: flex;
justify-content: space-around;
`
export const PersonWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`

export const ImportantPeopleImageContainer = styled.div`
display: flex;
border-radius: 50%;
border: 1px solid #96989a;
background-color: gray;
width: 226px;
height: 226px;
margin-bottom: 24px;
justify-content: center;
`

export const ImportantPeopleImage = styled.img`
object-fit: fill;
`

export const LessImportantPeopleImageContainer = styled.div`
display: flex;
border-radius: 50%;
border: 1px solid #96989a;
background-color: gray;
width: 160px;
height: 160px;
margin-bottom: 24px;
justify-content: center;
`

export const LessImportantPeopleImage = styled.img`
object-fit: fill;
`

export const PersonName = styled.span`
font-size: 24px;
font-weight: 700;
`

export const PersonRole = styled.span``