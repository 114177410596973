import styled from 'styled-components';
import { MdPerson, MdStar } from 'react-icons/md';

export const Wrapper = styled.div`
  width: 412px;
  height: 94px;
  border: 1px solid #efefef;
  border-radius: 4px;
  margin-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  padding: 18px 14px;
`;

export const Person = styled(MdPerson)`
  width: 20px;
  height: 20px;
  background-color: #9a9a9a;
  color: #fff;
  border-radius: 100%;
  padding: 2px;
  margin-right: 9px;
`;

export const Avatar = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin-right: 9px;
`;

export const DataWrapper = styled.div`
  padding-top: 2px;
`;

export const UserName = styled.div`
  color: #4D4D4D;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 2px;
  cursor: pointer;
`;

export const Rating = styled.div`
  display: flex;
  margin-bottom: 4px;
`;

export const Stars = styled.div`
  display: flex;
  margin-right: 4px;
`;

export const FullStar = styled(MdStar)``;

export const ShadowStar = styled(FullStar)`
  opacity: 0.2;
`;

export const RevsNo = styled.div`
  color: #4D4D4D;
  font-size: 12px;
`;

export const Location = styled.div`
  font-size: 12px;
`;
