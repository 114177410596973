import React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { confirmRegistration } from '../../api/common-api';
// import { history } from '../../App';

const ConfirmAccountPage = () => {
  const { token } = useParams() as { token: string };
  useEffect(() => {
    const handleConfirmRegistration = async () => {
      // TODO
      // confirmRegistration({ token }).finally(() => history('/'));
    };
    handleConfirmRegistration();
  }, [token]);

  return <div>Potwierdzamy Twoje konto</div>;
};

export default ConfirmAccountPage;
