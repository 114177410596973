import styled from 'styled-components';
import { MdClose, MdPerson } from 'react-icons/md';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
`;

export const Person = styled(MdPerson)`
  width: 20px;
  height: 20px;
  background-color: #9a9a9a;
  color: #fff;
  border-radius: 100%;
  padding: 2px;
  margin: 0 16px 0 4px;
`;

export const Avatar = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  object-fit: cover;
  margin: 0 16px 0 4px;
`;

export const BodyWrapper = styled.div`
  border-bottom: 1px solid #e2e2e2;
  width: 694px;
  height: 196px;
  margin-bottom: 24px;
`;

export const UserWrapper = styled(Link)`
  display: flex;
  margin-bottom: 20px;
`;

export const UserName = styled.div`
  height: 20px;
  margin-bottom: 4px;
  color: #4D4D4D;
  font-size: 16px;
  font-weight: 700;
`;

export const CreatedAt = styled.div`
  font-size: 12px;
  font-weight: 500;
  height: 15px;
`;

export const Message = styled.div`
  white-space: pre-line;
  font-family: inherit;
  font-size: 14px;
  word-spacing: 0.6px;
  color: #4D4D4D;
  height: 50px;
  width: 100%;
  height: 80px;
  border: none;
  overflow-wrap: break-word;
  overflow: auto;
  resize: none;
  padding-left: 40px;
  line-height: 20px;
  letter-spacing: 0.14px;
  color: #4D4D4D;
  background-color: transparent;
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #bfbfbf;
  }
  margin-bottom: 2px;
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px #0077df;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
`;

export const UpVotes = styled.div`
  width: 53px;
  height: 32px;
  margin-right: 16px;

  border: 1px solid #e2e2e2;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DownVotes = styled(UpVotes)``;

export const DeleteWrapper = styled(UpVotes)`
  width: 32px;
  height: 32px;
`;

export const Value = styled.div`
  color: #4D4D4D;
  font-size: 16px;
  font-weight: 700;
`;

export const ArrowDown = styled(IoIosArrowDown)`
  height: 20px;
  width: 22px;
  opacity: 0.7;
  margin-right: 2px;
  position: relative;
  cursor: pointer;
  left: -2px;
`;

export const Delete = styled(MdClose)`
  height: 22px;
  width: 22px;
  opacity: 0.7;
  cursor: pointer;
`;

export const ArrowUp = styled(IoIosArrowUp)`
  height: 20px;
  width: 22px;
  opacity: 0.7;
  cursor: pointer;
  margin-right: 2px;
`;

export const Report = styled.div`
  font-size: 12px;
  letter-spacing: 0.17px;
  color: #9a9a9a;
  cursor: pointer;
  text-decoration: underline;
  margin: 0 0 2px auto;
`;
