import styled from 'styled-components';
import themes from '../Assets/colorThemes';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themes.default.white};
  width: 100%;
  height: 100%;
  min-height: 89vh;
  box-sizing: border-box;
`;

export const BodyWrapper = styled.div`
  padding-top: 72px;
  width: 100%;
  background-color: ${themes.default.white};
`;
