import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { TileLayer, Circle } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import * as S from './MapEnlarged.style';
import { toggleModal } from '../../../redux/reducers/utils/action';
import { MODAL_NAME } from '../../../redux/reducers/utils/models';

export interface MapEnlargedProps {
  lat: number;
  lon: number;
}

const MapEnlarged = ({ lat, lon }: MapEnlargedProps) => {
  const dispatch = useDispatch();

  const close = () => {
    dispatch(toggleModal(MODAL_NAME.MAP_ENLARGED));
  };

  const coordinates: [number, number] = [lat, lon];
  const zoom = 12;


  return (
    <S.Wrapper>
      <S.TopWrapper> 
        <S.Back onClick={close}>
          <S.BackIcon />
          <S.BackToPrintRequest>Back to print request</S.BackToPrintRequest>
        </S.Back>
        <S.CloseWrapper onClick={close}>
          <S.Close />
        </S.CloseWrapper>
      </S.TopWrapper>
      <S.StyledMap center={coordinates} zoom={zoom}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Circle center={coordinates} fillColor="blue" radius={5000} />
      </S.StyledMap>
    </S.Wrapper>
  );
};

export default memo(MapEnlarged);
