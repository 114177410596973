import { AnyAction } from 'redux';
import {
  GET_DESIGN_REQUEST_DETAILS_SUCCESS,
  GET_DESIGN_REQUEST_DETAILS,
  SET_UPDATED_OFFERS,
  SET_FEATURED_ITEMS,
  SET_SUBMITTING_DESIGN_REQUEST,
  REMOVE_COMMENT,
  SET_COMMENT,
  SET_COMMENTS,
  SET_LIKE,
  ADD_PACKAGE_SUCCESS,
  UPDATE_PACKAGE,
  UPDATE_PACKAGE_SUCCESS,
  CHECK_OFFER_REVIEW_SUCCESS,
} from '../actionTypes';
import { useSelector } from 'react-redux';
import { AppState } from '.';
import { DesignRequestDetailed } from '../../models/designRequest';
import moment from 'moment';
import { CommentProps } from '../../components/ProductDetails/Comment/Comment';
import { PRODUCT_TYPE } from '../../utils/productType';
import { CommentLike, PRODUCT_STATUS } from '../../models/common';
import { Package } from '../../models/package';
import { LOGOUT_USER_SUCCESS } from './auth/actionTypes';

export interface DesignRequestState {
  designRequests: { [key: string]: DesignRequestDetailed };
  isLoading: boolean;
  isSubmitting: boolean;
  featuredItems: { count: number; rows: [] };
  isLoadingPackages: boolean;
  isReviewed: boolean;
}

export const DesignRequestDetailedEmpty: DesignRequestDetailed = {
  phone: '',
  email: '',
  id: '',
  title: '',
  price: 0,
  accepting: '',
  offersAmount: 0,
  description: '',
  status: PRODUCT_STATUS.WAITING_FOR_FINALIZATION,
  height: 0,
  width: 0,
  depth: 0,
  comments: [],
  size: {
    id: '',
    symbol: '',
  },
  currency: {
    id: '',
    symbol: '',
  },
  user: {
    id: '',
    avatar: '',
    name: '',
    rating: 0,
    nickname: '',
  },
  // comments: [],
  photos: [],
  images: [],
  fileExtension: {
    id: '',
    name: '',
  },
  software: '',
  fileFormat: '',
  // isAddingNewAddress: false,
  purchase: null,
  offers: [],
  productType: '',
};

const initialState: DesignRequestState = {
  designRequests: {},
  isLoading: true,
  isSubmitting: false,
  featuredItems: { count: 0, rows: [] },
  isLoadingPackages: false,
  isReviewed: false,
};

export const useDesignRequestDetails = (requestId: string) => {
  return useSelector((state: AppState) => {
    return {
      request: state.designRequests.designRequests[requestId] || DesignRequestDetailedEmpty,
      isLoading: state.designRequests.isLoading,
    };
  });
};

export const useDesignRequestIsLoading = () => {
  return useSelector((state: AppState) => state.designRequests.isLoading);
};

export default function (state = initialState, action: AnyAction): DesignRequestState {
  switch (action.type) {
    case LOGOUT_USER_SUCCESS: {
      return initialState;
    }
    case GET_DESIGN_REQUEST_DETAILS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SET_SUBMITTING_DESIGN_REQUEST: {
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };
    }
    case GET_DESIGN_REQUEST_DETAILS_SUCCESS: {
      const {
        request,
        request: { id },
      } = action.data;
      return {
        ...state,
        designRequests: {
          ...state.designRequests,
          [id]: request,
        },
        isLoading: false,
      };
    }
    case SET_UPDATED_OFFERS: {
      const offers = action.data;
      const designRequestId = offers[0].designRequestId;
      const newPrintRequest = {
        ...state.designRequests[designRequestId],
        offers,
      };
      return {
        ...state,
        designRequests: {
          ...state.designRequests,
          [designRequestId]: newPrintRequest,
        },
      };
    }
    case SET_FEATURED_ITEMS: {
      return {
        ...state,
        featuredItems: { ...action.data.designs },
      };
    }
    case SET_COMMENTS: {
      const { comments, productId, productType } = action.data;
      if (productType !== PRODUCT_TYPE.DESIGN_REQUEST) {
        return state;
      }
      return {
        ...state,
        designRequests: {
          ...state.designRequests,
          [productId]: { ...state.designRequests[productId], comments },
        },
      };
    }
    case SET_COMMENT: {
      const { comment, productId, productType } = action.data;

      if (productType !== PRODUCT_TYPE.DESIGN_REQUEST) {
        return state;
      }
      return {
        ...state,
        designRequests: {
          ...state.designRequests,
          [productId]: {
            ...state.designRequests[productId],
            comments: [...state.designRequests[productId].comments, comment],
          },
        },
      };
    }
    case REMOVE_COMMENT: {
      const { commentId, productId, productType } = action.data;
      if (productType !== PRODUCT_TYPE.DESIGN_REQUEST) {
        return state;
      }

      const { comments } = state.designRequests[productId];
      const filteredComments = comments.filter(({ id }: CommentProps) => id !== commentId);
      return {
        ...state,
        designRequests: {
          ...state.designRequests,
          [productId]: { ...state.designRequests[productId], comments: filteredComments },
        },
      };
    }
    case SET_LIKE: {
      const { like, productId, productType, userId } = action.data;
      const { commentId } = like;

      if (productType !== PRODUCT_TYPE.DESIGN_REQUEST) {
        return state;
      }
      const selectedProduct = state.designRequests[productId];
      const { comments } = selectedProduct;
      const comment = comments.find(({ id }: CommentProps) => id === commentId);
      const { likes } = comment;
      const filteredLikes = likes.filter(({ authorId }: CommentLike) => authorId !== userId);
      const filteredComments = comments.filter(({ id }: CommentProps) => id !== commentId);
      const refreshedComments = [
        ...filteredComments,
        { ...comment, likes: [...filteredLikes, like] },
      ].sort((a, b) => {
        const dateA = moment(a.createdAt),
          dateB = moment(b.createdAt);
        return dateB.diff(dateA);
      });

      const refreshedProduct = {
        [productId]: {
          ...selectedProduct,
          comments: refreshedComments,
        },
      };
      return {
        ...state,
        designRequests: {
          ...state.designRequests,
          ...refreshedProduct,
        },
      };
    }
    case ADD_PACKAGE_SUCCESS: {
      const { parcel, productId } = action.data;
      const maybeRequest = state.designRequests[productId];
      if (!maybeRequest) {
        return {
          ...state,
          isLoadingPackages: false,
        };
      }
      const { packages } = maybeRequest;
      const newPackages = [...(packages as Package[]), parcel];
      return {
        ...state,
        isLoadingPackages: false,
        designRequests: {
          ...state.designRequests,
          [productId]: { ...maybeRequest, packages: newPackages },
        },
      };
    }
    case UPDATE_PACKAGE: {
      return { ...state, isLoadingPackages: true };
    }
    case UPDATE_PACKAGE_SUCCESS: {
      const { parcels, productId } = action.data;
      const designRequest = state.designRequests[productId];
      return {
        ...state,
        isLoadingPackages: false,
        designRequests: {
          ...state.designRequests,
          [productId]: { ...designRequest, packages: parcels },
        },
      };
    }
    case CHECK_OFFER_REVIEW_SUCCESS: {
      if (action.productType !== PRODUCT_TYPE.DESIGN_REQUEST) {
        return state;
      }
      return {
        ...state,
        isReviewed: action.data,
      };
    }
    default:
      return state;
  }
}
