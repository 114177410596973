import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  font-family: Inter;
  width: 100%;
  max-width: 1296px;
  margin: 0 auto;
  padding: 32px;
  margin-bottom: 40px;
  overflow: hidden;
`;

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 580px;
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  padding-bottom: 32px;
`;

export const Header = styled.h2`
  margin: 0;
  font-size: 30px;
  font-weight: 700;
`;

export const SubHeaderWrapper = styled.div`
  display: flex;
  max-width: 460px;
`;

export const SubHeader = styled.span`
  font-size: 45px;
`;

export const BottomHeaderWrapper = styled.div`
  padding-bottom: 40px;
`;

export const BottomHeader = styled.h3`
  margin: 0;
  font-size: 30px;
`;
export const BottomDataWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const BottomBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 628px;
  width: 100%;
  padding: 0 20px 80px 0;
`;

export const BottomBodyHeader = styled.h4`
  margin: 0;
  padding-bottom: 16px;
  font-size: 24px;
  font-weight: 500;
`;

export const BottomBodyText = styled.span``;

export const AbsoluteImageLeft = styled.img`
position: absolute;
z-index: -5;
top: 0;
left: 224px;
max-height: 640px;
`

export const AbsoluteImageRight = styled.img`
position: absolute;
z-index: -4;
top: -652px;
left: 295px;
`
