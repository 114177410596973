import { AnyAction } from 'redux';
import { DesignRequest } from './models';
import {
  GET_FEATURED_DESIGN_REQUESTS,
  GET_FEATURED_DESIGN_REQUESTS_FAILURE,
  GET_FEATURED_DESIGN_REQUESTS_SUCCESS,
  GET_USER_DESIGN_REQUESTS,
  GET_USER_DESIGN_REQUESTS_FAILURE,
  GET_USER_DESIGN_REQUESTS_SUCCESS,
  GET_VIEWED_DESIGN_REQUEST,
  GET_VIEWED_DESIGN_REQUEST_FAILURE,
  GET_VIEWED_DESIGN_REQUEST_SUCCESS,
  SET_DESIGN_REQUEST_SUBMIT_ERROR,
  SET_SUBMITTING_DESIGN_REQUEST,
  PREPARE_DESIGN_REQUEST_DETAILS_SUCCESS,
  SET_DR_COMMENTS,
  SET_DR_OFFERS,
  SET_UPDATED_DR_OFFERS,
  SET_UPDATED_DR_OFFERS_SUCCESS,
  SET_UPDATED_DR_OFFERS_FAILURE,
  SET_DR_LIKE,
  REMOVE_DR_COMMENT_SUCCESS,
  UPLOAD_DR_FILES,
  UPLOAD_DR_FILES_SUCCESS,
  UPLOAD_DR_FILES_FAILURE,
  ADD_FREE_DR,
  ADD_FREE_DR_SUCCESS,
  ADD_FREE_DR_FAILURE,
} from './actionTypes';
import { Comment } from '../../../models/common';
import { updateProductCommentsAfterLike } from '../../../utils/updateProductCommentsAfterLike';
import { updateCommentsAfterDelete } from '../../../utils/updateCommentsAfterDelete';

export interface DesignRequestsState {
  userDesignRequests: DesignRequest[];
  isLoadingUserDesignRequests: boolean;
  featuredDesignRequests: DesignRequest[];
  isLoadingFeaturedDesignRequests: boolean;
  viewedDesignRequest: DesignRequest | null;
  isLoadingViewedDesignRequest: boolean;
  isSubmitting: boolean;
  errorMessage: string;
  isUploadingFiles: boolean;
  isAddingFreeDR: boolean;
}

const initialState: DesignRequestsState = {
  userDesignRequests: [],
  isLoadingUserDesignRequests: false,
  featuredDesignRequests: [],
  isLoadingFeaturedDesignRequests: false,
  viewedDesignRequest: null,
  isLoadingViewedDesignRequest: false,
  isSubmitting: false,
  errorMessage: '',
  isUploadingFiles: false,
  isAddingFreeDR:false,
};

export default function (state = initialState, action: AnyAction): DesignRequestsState {
  switch (action.type) {
    case GET_USER_DESIGN_REQUESTS: {
      return {
        ...state,
        isLoadingUserDesignRequests: true,
      };
    }
    case GET_USER_DESIGN_REQUESTS_SUCCESS: {
      return {
        ...state,
        isLoadingUserDesignRequests: false,
        userDesignRequests: action.data,
      };
    }
    case GET_USER_DESIGN_REQUESTS_FAILURE: {
      return {
        ...state,
        isLoadingUserDesignRequests: false,
      };
    }
    case GET_FEATURED_DESIGN_REQUESTS: {
      return {
        ...state,
        isLoadingFeaturedDesignRequests: true,
      };
    }
    case GET_FEATURED_DESIGN_REQUESTS_SUCCESS: {
      return {
        ...state,
        isLoadingFeaturedDesignRequests: false,
        featuredDesignRequests: action.data,
      };
    }
    case GET_FEATURED_DESIGN_REQUESTS_FAILURE: {
      return {
        ...state,
        isLoadingFeaturedDesignRequests: false,
      };
    }
    case GET_VIEWED_DESIGN_REQUEST: {
      return {
        ...state,
        isLoadingViewedDesignRequest: true,
      };
    }
    case GET_VIEWED_DESIGN_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoadingViewedDesignRequest: false,
        viewedDesignRequest: action.data,
      };
    }
    case GET_VIEWED_DESIGN_REQUEST_FAILURE: {
      return {
        ...state,
        isLoadingViewedDesignRequest: false,
      };
    }
    case SET_SUBMITTING_DESIGN_REQUEST: {
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };
    }
    case SET_DESIGN_REQUEST_SUBMIT_ERROR: {
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    }
    case PREPARE_DESIGN_REQUEST_DETAILS_SUCCESS: {
      return {
        ...state,
        ...action.data,
      };
    }
    case SET_DR_COMMENTS: {
      const { comments } = action;
      const { viewedDesignRequest } = state;
      if (!viewedDesignRequest) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        viewedDesignRequest: { ...viewedDesignRequest, comments },
      };
    }
    case SET_DR_OFFERS: {
      const { offers } = action;
      const { viewedDesignRequest } = state;
      if (!viewedDesignRequest) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        viewedDesignRequest: { ...viewedDesignRequest, offers },
      };
    }
    case SET_UPDATED_DR_OFFERS: {
      return {
        ...state,
        isLoadingViewedDesignRequest: true,
      };
    }
    case SET_UPDATED_DR_OFFERS_SUCCESS: {
      const { offers, status } = action.data;
      const { viewedDesignRequest } = state;
      if (!viewedDesignRequest) {
        return { ...state };
      }
      return {
        ...state,
        isLoadingViewedDesignRequest: false,
        viewedDesignRequest: { ...viewedDesignRequest, offers, status },
      };
    }
    case SET_UPDATED_DR_OFFERS_FAILURE: {
      return {
        ...state,
        isLoadingViewedDesignRequest: false,
      };
    }
    case SET_DR_LIKE: {
      const { viewedDesignRequest } = state;
      if (!viewedDesignRequest) {
        return {
          ...state,
        };
      }
      const { comments } = viewedDesignRequest;
      const { like } = action;
      const { commentId } = like;
      const maybeComment = comments.find((comment: Comment) => comment.id === commentId);
      if (!maybeComment) {
        return {
          ...state,
        };
      }
      const updatedComments = updateProductCommentsAfterLike(comments, maybeComment, like);
      return {
        ...state,
        viewedDesignRequest: { ...viewedDesignRequest, comments: updatedComments },
      };
    }
    case REMOVE_DR_COMMENT_SUCCESS: {
      const { viewedDesignRequest } = state;
      if (!viewedDesignRequest) {
        return {
          ...state,
        };
      }
      const { comments } = viewedDesignRequest;
      const { commentId } = action;
      const updatedComments = updateCommentsAfterDelete(comments, commentId);
      return {
        ...state,
        viewedDesignRequest: { ...viewedDesignRequest, comments: updatedComments },
      };
    }
    case UPLOAD_DR_FILES: {
      return {
        ...state,
        isUploadingFiles: true,
      };
    }
    case UPLOAD_DR_FILES_SUCCESS: {
      const {files} = action;
      const { viewedDesignRequest } = state;
      if (!viewedDesignRequest) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        isUploadingFiles: false,
        viewedDesignRequest: { ...viewedDesignRequest, files },
      };
    }
    case UPLOAD_DR_FILES_FAILURE: {
      return {
        ...state,
        isUploadingFiles: false,
      };
    }
    case ADD_FREE_DR: {
      return {
        ...state,
        isAddingFreeDR: true,
      };
    }
    case ADD_FREE_DR_SUCCESS: {
      return {
        ...state,
        isAddingFreeDR: false,
      };
    }
    case ADD_FREE_DR_FAILURE: {
      return {
        ...state,
        isAddingFreeDR: false,
      };
    }
    default:
      return state;
  }
}
