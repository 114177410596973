import styled from 'styled-components';
import { BsChevronLeft } from 'react-icons/bs';

export const Wrapper = styled.div`
  max-width: 1296px;
  width: 100%;
  margin: 0 auto;
  padding: 80px 16px 0;
  display: flex;
  box-sizing: border-box;
  @media (max-width: 1175px) {
    margin-top: 60px;
  }
  @media (max-width: 745px) {
    margin-top: 140px;
    flex-wrap: wrap;
  }
`;

export const MenuAndOptionsWrapper = styled.div`
  margin-right: 24px;
  max-width: 304px;
  width: 100%;
  @media (max-width: 800px) {
    max-width: 100%;
    margin: 0 auto;
  }
`;

export const Back = styled.button`
  letter-spacing: 0.14px;
  font-size: 14px;
  font-weight: 700;
  color: #4D4D4D;
  display: flex;
  align-items: center;
  margin-bottom: 45px;
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

export const ArrowLeft = styled(BsChevronLeft)`
  opacity: 0.8;
  font-size: 24px;
  position: relative;
  left: -4px;
`;

export const ResultsWrapper = styled.div`
  max-width: 952px;
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  padding: 0 8px;
  box-sizing: border-box;
  @media (max-width: 800px) {
    padding: 0;
  }
`;

export const TopWrapper = styled.div`
  margin-bottom: 16px;
`;

export const TopTitle = styled.div`
  font-size: 14px;
  color: #4D4D4D;
  padding-top: 4px;
  margin-bottom: 2px;
`;
export const ResultsAmount = styled.div`
  font-size: 16px;
  color: #9f9f9f;
  font-weight: 600;
`;
export const Header = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 24px;
  color: #4D4D4D;
`;

export const Bolded700 = styled.div`
  font-weight: 700;
  margin: 0 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 68%;
  white-space: nowrap;
`;

export const Bolded800 = styled.span`
  font-weight: 800;
`;

export const SliderWrapper = styled.div`
  max-width: 952px;
  border-top: 1px solid #707070;
  padding-top: 8px;
  width: 100%;
  margin: 68px auto 53px auto;
`;

export const SliderTitle = styled.div`
  color: #4D4D4D;
  font-size: 16px;
  margin-bottom: 8px;
`;
