import styled from 'styled-components';
import U from '../../Assets/commonCSS';
import SVG from '../../Assets/SVG/svg-exporter';

export const HeaderWrapper = styled.div`
  height: 70px;
  justify-content: center;
  box-shadow: 0px 3px 15px #0000000f;
  position: fixed;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: #ffff;
  z-index: 9;
  display: none;
  @media (min-width: 746px) {
    display: flex;
  }
  @media (max-width: 1175px) {
    height: 108px;
  }
`;

export const HeaderMobileWrapper = styled.div`
  box-shadow: 0px 3px 15px #0000000f;
  position: fixed;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: #ffff;
  z-index: 9;
  align-items: center;
  display: none;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 745px) {
    display: flex;
  }
`;

export const HeaderMobileRow = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0;
  width: 100%;
  justify-content: space-evenly;
`;

export const MenuWrapper = styled.div`
  position: relative;
`;

export const HeaderContainer = styled.div`
  height: 100%;
  width: 100%;
  ${U.flexStandard}
  max-width: 1296px;
  padding: 0 16px;
  box-sizing: border-box;
  margin: 0 auto;
`;

export const HeaderBody = styled.div`
  height: 100%;
  max-width: 990px;
  width: 100%;
  ${U.flexStandard}
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  @media (max-width: 640px) {
    flex-direction: row;
    z-index: ${U.zIndexMenu};
    flex-direction: inherit;
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  position: relative;
`;

export const Image = styled.img`
  width: auto;
  height: 100%;
`;

export const AbsoluteImage = styled.img`
  width: auto;
  height: 30px;
  position: absolute;
  bottom: -18px;
  left: 40px;
`;
export const MobileImage = styled(Image)`
  height: 42px;
  margin-top: 2px;
`;

export const SearchBoxWrapper = styled.div`
  max-width: 560px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 8px 0;
`;

export const Button = styled.button`
  cursor: pointer;
  text-align: center;
  padding: 0;
  border-radius: 3px;
  text-transform: uppercase;
  width: 108px;
  height: 32px;
  letter-spacing: 0.14px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  color: #0077df;
  height: 38px;
  background-color: rgba(0, 119, 223, 0.1);
  border: 1px solid #0077df;
`;

export const Explore = styled.button`
  font-size: 16px;
  letter-spacing: 0.16px;
  color: #00071a;
  border: none;
  background-color: #fff;
  border-radius: 4px;
  height: 34px;
  text-align: center;
  padding-top: 5px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 5px 0;
`;

export const Icons = styled.div`
  ${U.flexStandard}
  margin: 0 24px;
  justify-content: space-between;
  @media (max-width: 1088px) {
    margin-top: 12px;
  }
`;

export const Icon = styled.div`
  display: flex;
  cursor: pointer;
  min-width: 38px;
  width: 38px;
  height: 38px;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  border: 1px solid #95989a;
  border-radius: 20px;
`;

export const NotificationsIcon = styled.img.attrs({ src: SVG.notificationsBell })`
  width: 40px;
  height: 40px;
`;

export const ProfileIcon = styled.img.attrs({ src: SVG.emptyAvatar })``;

export const RedDot = styled.div`
  background-color: #ff4b01;
  border: 2px solid #fff;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  position: relative;
  top: -15px;
  left: 18px;
  ${U.flexCentered}
`;

export const NotificationNumber = styled.div`
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0.08px;
  color: #ffffff;
`;

export const DotWrapper = styled.div`
  position: absolute;
`;

export const MobileIcon = styled.div`
  display: flex;
  cursor: pointer;
`;

export const Form = styled.form`
  ${U.flexStandard}
`;

export const Avatar = styled.img`
  width: 38px;
  height: 38px;
  border-radius: 100%;
  object-fit: cover;
`;

export const ActionsWrapper = styled.div`
  display: flex;

  margin: 8px 0;
  order: 0;
`;

export const ButtonTabWrapper = styled.div`
  display: block;
  width: 108px;
  height: 32px;
  margin: 0 26px;
`;

export const ExploreTabWrapper = styled.button`
  display: block;
  height: 34px;
  width: 160px;
`;

export const ControlsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const CreateAndIconsWrapper = styled.div`
  display: flex;
  height: 38px;
  margin-left: 26px;
  @media (max-width: 1088px) {
    flex-direction: column;
    height: 75px;
  }
`;

export const PlainButton = styled.button<{ isMarginNeeded?: boolean }>`
  border: none;
  background-color: transparent;
  margin-left: ${({ isMarginNeeded }) => (isMarginNeeded ? ' 26px;' : '0px;')};
  @media (max-width: 745px) {
    margin-left: 12px;
  }
`;

export const HelpCenter = styled.span`
  display: flex;
  width: 100%;
  font-size: 24px;
  color: #4d4d4d;
  padding-left: 12px;
`;
