import React from 'react';
import { Map, TileLayer, Circle } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

interface StreetMapProps {
  coordinates: [number, number];
  zoom?: number;
  radius?: number;
}

const StreetMap = ({ coordinates, zoom = 11, radius = 7500 }: StreetMapProps) => {
  if (coordinates[0] === 0 || coordinates[1] === 0) {
    return <></>;
  }
  return (
    <Map center={coordinates} zoom={zoom} style={{ width: '844px', height: '475px', zIndex: 1 }}>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Circle center={coordinates} fillColor="blue" radius={radius} />
    </Map>
  );
};

export default StreetMap;
