import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Title = styled.div`
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
  font-weight: bold;
`;

export const MainContainer = styled.div`
  border: 1px solid #bfbfbf;
  box-sizing: border-box;
  padding: 14px 0;
  border-radius: 4px;
  display: flex;
  display: flex;
  flex-direction: column;
  min-height: 107px;
  position: relative;
`;

export const SelectedLicense = styled.div`
  display: flex;
  padding: 0 12px;
  margin-bottom: 5px;
`;

export const LicenseDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const LicenseTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 5px;
`;

export const LicenseDetail = styled.div`
  font-size: 11px;
  padding-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
  max-width: 350px;
`;

export const ArrowBox = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  margin-right: 10px;
`;

export const Arrow = styled.div<{ isOpen: boolean }>`
  border-top: 2px solid #707070;
  border-right: 2px solid #707070;
  width: 14px;
  height: 14px;
  cursor: pointer;
  ${(props: any) => (props.isOpen ? 'transform: rotate(315deg)' : 'transform: rotate(135deg)')};
`;

export const Split = styled.div`
  border-top: 1px solid #bfbfbf;
  width: 100%;
`;
export const OverWrap = styled.div`
  position: absolute;
  border: 1px solid #bfbfbf;
  border-top: none;
  border-bottom: none;
  box-sizing: border-box;
  top: 105px;
  border-radius: 4px;
`;
