export const NAVIGATION_OPTIONS = [
  {
    id: 1,
    info: 'Explore ',
    infoBold: '3D Market',
    title: 'Objects',
    options: [
      {
        question: 'You have 3D printed objects?',
        link: 'Sell printed objects',
        url: '/object/new',
      },
      {
        question: 'Looking for sth interesting?',
        link: 'Explore printed objects for sale',
        url: '/search?query=&objects=true',
      },
    ],
  },
  {
    id: 2,
    title: 'Printing',
    options: [
      {
        question: 'You have a 3D printer?',
        link: 'Explore print requests',
        url: '/search?query=&prints=true',
      },
      {
        question: 'You need printing service?',
        link: 'Create print requests',
        url: '/print-request/new',
      },
    ],
  },
  {
    id: 3,
    info: '',
    title: 'Design',
    options: [
      {
        question: "You're a designer?",
        link: 'Explore design requests',
        url: '/search?query=&designs=true',
      },
      {
        question: 'You need design service?',
        link: 'Create design requests',
        url: '/design-request/new',
      },
    ],
  },
];

export enum SLIDER_TYPE {
  FEATURED,
  COMPACT,
  WIDE,
  MARKET,
}

export const SLIDER_LENGTH = {
  [SLIDER_TYPE.FEATURED]: 4,
  [SLIDER_TYPE.COMPACT]: 6,
  [SLIDER_TYPE.WIDE]: 3,
  [SLIDER_TYPE.MARKET]: 6,
};
