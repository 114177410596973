import React, { memo, useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserNotifications,
  markAsReadNotifications,
} from '../../../../redux/reducers/auth/actions';
import { Notification } from '../../../../utils/notificationTypes';
import CircularIndeterminateLoader from '../../Loader/CircularIndeterminateLoader';
import NotificationEntity from './Notification';
import { isModalOpen } from '../../../../redux/reducers/utils/selectors';
import { MODAL_NAME } from '../../../../redux/reducers/utils/models';
import {
  isUserLoggedIn,
  isLoadingUsersNotifications,
  userNotifications,
  loggedUser,
} from '../../../../redux/reducers/auth/selectors';
import * as S from './NotificationsMenu.styles';
import { getNotificationDestination } from '../../../../utils/getNotificationDestination';
import { useNavigate } from 'react-router-dom';

const NotificationsMenu = () => {
  const dispatch = useDispatch();
   const navigate = useNavigate();
  const isLoggedIn = useSelector(isUserLoggedIn);
  const { id: userId } = useSelector(loggedUser);
  const isVisible = useSelector(isModalOpen(MODAL_NAME.NOTIFICATIONS));
  const [isMenuDisplayed, setIsMenuDisplayed] = useState(false);
  const isLoadingNotifications = useSelector(isLoadingUsersNotifications);
  const notifications = useSelector(userNotifications);

  const reducer = (
    accumulator: { newNotifications: Notification[]; previousNotifications: Notification[] },
    currVal: Notification
  ) => {
    const { newNotifications, previousNotifications } = accumulator;
    const { createdAt } = currVal;
    const isNew = moment(new Date()).diff(createdAt, 'h') < 24;
    if (isNew) {
      return { newNotifications: [...newNotifications, currVal], previousNotifications };
    }
    return { previousNotifications: [...previousNotifications, currVal], newNotifications };
  };
  const { newNotifications, previousNotifications } = notifications.reduce(reducer, {
    newNotifications: [],
    previousNotifications: [],
  });

  const markAllAsRead = () => {
    const ids = notifications.map(({ id }: Notification) => id);
    dispatch(markAsReadNotifications(ids));
  };

  useEffect(() => {
    dispatch(getUserNotifications({}));
  }, [dispatch]);

  useEffect(() => {
    setIsMenuDisplayed(false);
  }, [isVisible]);

  if (!isLoggedIn || !isVisible) {
    return null;
  }

  const handleClick = (notification: Notification) => {
    const direction = getNotificationDestination(notification, userId);
    const { id } = notification;
    dispatch(markAsReadNotifications([id]));
    navigate(direction);
  };

  const mapNotifications = () => {
    const areNewNotifications = newNotifications.length > 0;

    return (
      <>
        {areNewNotifications && (
          <S.NotificationsWrapper>
            <S.Label>New</S.Label>
            {newNotifications
              .sort((a, b) => {
                const dateA = moment(a.createdAt),
                  dateB = moment(b.createdAt);
                return dateB.diff(dateA);
              })
              .map((notification: Notification) => (
                <NotificationEntity
                  key={notification.id}
                  notification={notification}
                  markAsRead={() => handleClick(notification)}
                />
              ))}
          </S.NotificationsWrapper>
        )}
        {previousNotifications.length > 0 && (
          <S.NotificationsWrapper>
            {areNewNotifications && <S.Label>Earlier</S.Label>}
            {previousNotifications
              .sort((a, b) => {
                const dateA = moment(a.createdAt),
                  dateB = moment(b.createdAt);
                return dateB.diff(dateA);
              })
              .map((notification: Notification) => (
                <NotificationEntity
                  key={notification.id}
                  notification={notification}
                  markAsRead={() => handleClick(notification)}
                />
              ))}
          </S.NotificationsWrapper>
        )}
      </>
    );
  };

  return (
    <S.Wrapper>
      <S.HeaderRow>
        <S.NotificationsHeader>Notifications</S.NotificationsHeader>
        <S.MenuButton onClick={() => setIsMenuDisplayed(!isMenuDisplayed)}>
          <S.DotsIcon />
        </S.MenuButton>
        <S.MenuPlacer>
          {isMenuDisplayed && (
            <S.MenuWrapper>
              <S.MenuHeader>Notifications</S.MenuHeader>
              <S.MenuOption
                onClick={() => {
                  markAllAsRead();
                  setIsMenuDisplayed(false);
                }}
              >
                Mark all as read
              </S.MenuOption>
            </S.MenuWrapper>
          )}
        </S.MenuPlacer>
      </S.HeaderRow>
      <S.Results>{mapNotifications()}</S.Results>
    </S.Wrapper>
  );
};

export default memo(NotificationsMenu);
