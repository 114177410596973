import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loginWithGoogle } from '../../../redux/reducers/auth/actions';

const ContinueWithGoogleButton = ({ action }: { action: string }) => {
  const dispatch = useDispatch();
  const onSignIn = (googleUser: any) => {
    const authResponse = googleUser.getAuthResponse();
    dispatch(loginWithGoogle(authResponse));
  };
  const renderGoogleLoginButton = () => {
    (window as any).gapi.signin2.render(action, {
      scope: 'https://www.googleapis.com/auth/plus.login',
      width: 'auto',
      height: 48,
      longtitle: true,
      theme: 'dark',
      onsuccess: onSignIn,
    });
  };

  useEffect(() => {
    renderGoogleLoginButton();
  });

  return (
    <div id={action} style={{ marginBottom: '15px' }}>
      Continue with Google
    </div>
  );
};

export default ContinueWithGoogleButton;
