import moment from 'moment';
import { Comment } from '../models/common';

export const updateCommentsAfterDelete = (comments: Comment[], commentId: string) => {
  const filteredComments = [
    ...comments.filter((comment: Comment) => comment.id !== commentId),
  ].sort((a, b) => {
    const dateA = moment(a.createdAt),
      dateB = moment(b.createdAt);
    return dateB.diff(dateA);
  });
  return filteredComments;
};
