export const SET_MODAL_VISIBILITY = 'SET_MODAL_VISIBILITY';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const TOGGLE_PHOTO_VIEW = 'TOGGLE_PHOTO_VIEW';
export const CLOSE_MODALS = 'CLOSE_MODALS';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const SET_GALLERY_ID_FOR_EDIT = 'SET_GALLERY_ID_FOR_EDIT';
export const SET_SELECTED_DELIVERY_ADDRESS = 'SET_SELECTED_DELIVERY_ADDRESS';
export const SET_SELECTED_DEVICE_ID = 'SET_SELECTED_DEVICE_ID';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const SET_NETWORK_ACTION_STATE = 'SET_NETWORK_ACTION_STATE';
export const SET_MODAL_ELEMENT_ID = 'SET_MODAL_ELEMENT_ID';
export const SET_MODAL_PRODUCT_TYPE = 'SET_MODAL_PRODUCT_TYPE';
export const SET_MODAL_GALLERY_ID = 'SET_MODAL_GALLERY_ID';
export const CLEAR_MODAL_STATES = 'CLEAR_MODAL_STATES';
export const CLEAR_EXTERNAL_AUTH_STATES = 'CLEAR_EXTERNAL_AUTH_STATES';
export const CLEAR_ADDRESS_MODALS = 'CLEAR_ADDRESS_MODALS';
export const SET_MODAL_OFFERS = 'SET_MODAL_OFFERS';
export const SET_VIEWED_PRODUCT = 'SET_VIEWED_PRODUCT';
export const ADD_NEW_LICENSE = 'ADD_NEW_LICENSE';
export const ADD_NEW_LICENSE_SUCCESS = 'ADD_NEW_LICENSE_SUCCESS';
export const ADD_NEW_LICENSE_FAILURE = 'ADD_NEW_LICENSE_FAILURE';
export const SET_IS_LICENSE_BOUGHT = 'SET_IS_LICENSE_BOUGHT';
export const SET_IS_LICENSE_BOUGHT_SUCCESS = 'SET_IS_LICENSE_BOUGHT_SUCCESS';
export const SET_IS_LICENSE_BOUGHT_FAILURE = 'SET_IS_LICENSE_BOUGHT_FAILURE';
export const SET_PREVIEW_PRINT_REQUEST = 'SET_PREVIEW_PRINT_REQUEST';
export const SET_PREVIEW_DESIGN_REQUEST = 'SET_PREVIEW_DESIGN_REQUEST';
export const SET_PREVIEW_DESIGN_FOR_SELL = 'SET_PREVIEW_DESIGN_FOR_SELL';
export const SET_PREVIEW_OBJECT_FOR_SELL = 'SET_PREVIEW_OBJECT_FOR_SELL';
export const SET_SELECTED_LICENSE_IDS = 'SET_SELECTED_LICENSE_IDS';
export const SET_SELECTED_VIEWED_DESIGN_LICENSE = 'SET_SELECTED_VIEWED_DESIGN_LICENSE';
export const SET_MORE_ACTIONS_ID = 'SET_MORE_ACTIONS_ID';
export const SET_DESIGN_OFFER_MORE_ACTIONS_ID = 'SET_DESIGN_OFFER_MORE_ACTIONS_ID';
export const EDIT_NEW_LICENSE = 'EDIT_NEW_LICENSE';
export const EDIT_NEW_LICENSE_SUCCESS = 'EDIT_NEW_LICENSE_SUCCESS';
export const EDIT_NEW_LICENSE_FAILURE = 'EDIT_NEW_LICENSE_FAILURE';
export const SET_LICENSE_DESCRIPTION = 'SET_LICENSE_DESCRIPTION';
export const CLEAR_ADD_LICENSE_ERROR_MESSAGE = 'CLEAR_ADD_LICENSE_ERROR_MESSAGE';
export const SET_CREATE_PRODUCT_TYPE = 'SET_CREATE_PRODUCT_TYPE'