import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding-top: 60px;
`;
export const ParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Inter;
  color: black;
`;

export const Paragraph = styled.p`
  font-size: 22px;
  font-weight: 600;
`;

export const Content = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
  padding-bottom: 16px;
  word-break: break-word;
`;
