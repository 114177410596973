import styled from 'styled-components';
import themes from '../../Assets/colorThemes';

export const WorkshopWrapper = styled.div`
  min-height: 620px;
  max-width: 844px;
  width: 100%;
  flex-direction: column;
  display: flex;
`;

export const WorkshopCategory = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 34px;
`;

export const CategoryTitle = styled.div`
  font-size: 24px;
  font-weight: 800;
  color: ${themes.default.gray4d};
  margin-bottom: 16px;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Item = styled.div`
  max-width: 412px;
  width: 100%;
  height: 89px;
  border: 1px solid ${themes.default.grayEf};
  border-radius: 5px;
  display: flex;
  margin-bottom: 20px;
  box-sizing: border-box;
  position: relative;
`;

export const PrinterImage = styled.div<{ source: string }>`
  position: absolute;
  background-image: url(${(props: any) => props.source});
  min-width: 88px;
  height: 89px;
  top: -1px;
  left: -1px;
  border-radius: 4px 0px 0px 4px;
  background-color: ${themes.default.grayE2};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const ItemDetailsWrapper = styled.div`
  width: 100%;
  padding: 8px 20px;
  margin-left: 88px;
`;

export const ItemDetail = styled.div`
  font-size: 12px;
  margin-bottom: 10px;
  color: ${themes.default.gray9a};
`;

export const ItemName = styled.div`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 10px;
  color: ${themes.default.gray4d};
`;
