import { AnyAction } from 'redux';
import {
  SET_UPDATED_OFFERS,
  SET_FEATURED_ITEMS,
  GET_OBJECT_FOR_SELL_DETAILS_SUCCESS,
  GET_OBJECT_FOR_SELL_DETAILS,
  SET_SUBMITTING_OBJECT_FOR_SELL,
  REMOVE_COMMENT,
  SET_COMMENT,
  SET_COMMENTS,
  SET_LIKE,
  ADD_PACKAGE,
  ADD_PACKAGE_SUCCESS,
  UPDATE_PACKAGE,
  UPDATE_PACKAGE_SUCCESS,
} from '../actionTypes';
import { useSelector } from 'react-redux';
import { AppState } from '.';
import { ObjectForSellDetailed } from '../../models/objectForSell';
import moment from 'moment';
import { CommentProps } from '../../components/ProductDetails/Comment/Comment';
import { PRODUCT_TYPE } from '../../utils/productType';
import { Package } from '../../models/package';
import _get from 'lodash/get';
import { LOGOUT_USER_SUCCESS } from './auth/actionTypes';
import { CommentLike } from '../../models/common';

export interface oldObjectForSellState {
  objectsForSell: { [key: string]: ObjectForSellDetailed };
  isLoading: boolean;
  isSubmitting: boolean;
  featuredItems: { count: number; rows: [] };
  isLoadingPackages: boolean;
}

export const ObjectForSellEmpty: ObjectForSellDetailed = {
  phone: '',
  email: '',
  id: '',
  title: '',
  price: 0,
  quantity: 0,
  deadline: '',
  accepting: '',
  offersAmount: 0,
  description: '',
  status: '',
  height: 0,
  width: 0,
  depth: 0,
  speed: 0,
  comments: [],
  temperatureUnit: {
    id: '',
    type: '',
  },
  material: '',
  colour: '',
  volume: 0,
  size: {
    id: '',
    symbol: '',
  },
  unit: {
    id: '',
    type: '',
  },
  currency: {
    id: '',
    symbol: '',
  },
  user: {
    id: '',
    avatar: '',
    name: '',
    rating: 0,
    nickname: '',
  },
  technology: {
    id: '',
    name: '',
  },
  infill: {
    id: '',
    name: '',
  },
  address: {
    id: '',
    city: '',
    country: '',
    lat: 0,
    lon: 0,
  },
  temperatureBed: 0,
  temperatureNozzle: 0,
  printer: '',
  supports: false,
  infillDensity: 0,
  layerHeight: 0,
  perimeters: 0,
  printTimeHour: 0,
  printTimeMinute: 0,
  printTimeDays: 0,
  photos: [],
  images: [],
  nozzleDiameter: '',
  purchase: null,
  deliveryTypes: [],
  // isAddingNewAddress: false,
};

const initialState: oldObjectForSellState = {
  objectsForSell: {},
  isLoading: true,
  isSubmitting: false,
  featuredItems: { count: 0, rows: [] },
  isLoadingPackages: false,
};

export const useObjectForSellDetails = (requestId: string) => {
  return useSelector((state: AppState) => {
    return {
      request: state.oldObjectsForSell.objectsForSell[requestId] || ObjectForSellEmpty,
      isLoading: state.oldObjectsForSell.isLoading,
    };
  });
};

export default function (state = initialState, action: AnyAction): oldObjectForSellState {
  switch (action.type) {
    case LOGOUT_USER_SUCCESS: {
      return initialState;
    }
    case GET_OBJECT_FOR_SELL_DETAILS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SET_SUBMITTING_OBJECT_FOR_SELL: {
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };
    }
    case GET_OBJECT_FOR_SELL_DETAILS_SUCCESS: {
      const {
        request,
        request: { id },
      } = action.data;
      request.images = request.images.filter((i: string) => i !== '{}');
      return {
        ...state,
        objectsForSell: {
          ...state.objectsForSell,
          [id]: request,
        },
        isLoading: false,
      };
    }
    case SET_UPDATED_OFFERS: {
      const offers = action.data;
      const objectForSellId = offers[0].designRequestId;
      const newPrintRequest = {
        ...state.objectsForSell[objectForSellId],
        offers,
      };
      return {
        ...state,
        objectsForSell: {
          ...state.objectsForSell,
          [objectForSellId]: newPrintRequest,
        },
      };
    }
    case SET_FEATURED_ITEMS: {
      return {
        ...state,
        featuredItems: { ...action.data.objetsForSell },
      };
    }
    case SET_COMMENTS: {
      const { comments, productId, productType } = action.data;
      if (productType !== PRODUCT_TYPE.OBJECT_FOR_SELL) {
        return state;
      }
      return {
        ...state,

        objectsForSell: {
          ...state.objectsForSell,
          [productId]: { ...state.objectsForSell[productId], comments },
        },
      };
    }
    case SET_COMMENT: {
      const { comment, productId, productType } = action.data;

      if (productType !== PRODUCT_TYPE.OBJECT_FOR_SELL) {
        return state;
      }
      return {
        ...state,
        objectsForSell: {
          ...state.objectsForSell,
          [productId]: {
            ...state.objectsForSell[productId],
            comments: [...state.objectsForSell[productId].comments, comment],
          },
        },
      };
    }
    case REMOVE_COMMENT: {
      const { commentId, productId, productType } = action.data;
      if (productType !== PRODUCT_TYPE.OBJECT_FOR_SELL) {
        return state;
      }

      const { comments } = state.objectsForSell[productId];
      const filteredComments = comments.filter(({ id }: CommentProps) => id !== commentId);
      return {
        ...state,
        objectsForSell: {
          ...state.objectsForSell,
          [productId]: { ...state.objectsForSell[productId], comments: filteredComments },
        },
      };
    }
    case SET_LIKE: {
      const { like, productId, productType, userId } = action.data;
      const { commentId } = like;

      if (productType !== PRODUCT_TYPE.OBJECT_FOR_SELL) {
        return state;
      }
      const selectedProduct = state.objectsForSell[productId];
      const { comments } = selectedProduct;
      const comment = comments.find(({ id }: CommentProps) => id === commentId);
      const { likes } = comment;
      const filteredLikes = likes.filter(({ authorId }: CommentLike) => authorId !== userId);
      const filteredComments = comments.filter(({ id }: CommentProps) => id !== commentId);
      const refreshedComments = [
        ...filteredComments,
        { ...comment, likes: [...filteredLikes, like] },
      ].sort((a, b) => {
        const dateA = moment(a.createdAt),
          dateB = moment(b.createdAt);
        return dateB.diff(dateA);
      });

      const refreshedProduct = {
        [productId]: {
          ...selectedProduct,
          comments: refreshedComments,
        },
      };
      return {
        ...state,
        objectsForSell: {
          ...state.objectsForSell,
          ...refreshedProduct,
        },
      };
    }
    case ADD_PACKAGE: {
      return { ...state, isLoadingPackages: true };
    }
    case ADD_PACKAGE_SUCCESS: {
      const { parcel, productId } = action.data;
      const maybeObjectForSell = _get(state.objectsForSell, productId);
      if (!maybeObjectForSell) {
        return state;
      }
      const newPackages = maybeObjectForSell.packages
        ? [...maybeObjectForSell.packages, parcel]
        : [parcel];
      return {
        ...state,
        isLoadingPackages: false,
        objectsForSell: {
          ...state.objectsForSell,
          productId: { ...maybeObjectForSell, packages: newPackages },
        },
      };
    }
    case UPDATE_PACKAGE: {
      return { ...state, isLoadingPackages: true };
    }
    case UPDATE_PACKAGE_SUCCESS: {
      const { parcel, productId } = action.data;
      const maybeObjectForSell = _get(state.objectsForSell, productId);
      if (!maybeObjectForSell) {
        return state;
      }
      const packages = maybeObjectForSell.packages
        ? maybeObjectForSell.packages.filter(({ id }: Package) => id !== parcel.id)
        : [];
      const newPackages = [...packages, parcel];
      return {
        ...state,
        isLoadingPackages: false,
        objectsForSell: {
          ...state.objectsForSell,
          productId: { ...maybeObjectForSell, packages: newPackages },
        },
      };
    }
    default:
      return state;
  }
}
