import styled from 'styled-components';
import { MdPerson } from 'react-icons/md';

export const ResultWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  border: solid 1px #ebebeb;
  max-width: 952px;
  width: 100%;
  margin-bottom: 20px;
`;
export const ImageWrapper = styled.div<{ photo?: string }>`
  width: 304px;
  height: 196px;
  @media (max-width: 800px) {
    min-width: 160px;
    height: 156px;
  }
  ${(props: any) =>
    props.photo
      ? `
  background-image: url('${props.photo}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  `
      : 'background-color: #dddddd;'}
`;

export const BodyWrapper = styled.div`
  padding: 22px 20px;
  max-width: 650px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media (max-width: 500px) {
    padding: 10px;
  }
`;

export const Title = styled.div`
  letter-spacing: 0.18px;
  color: #4D4D4D;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 4px;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 8px;
`;

export const Price = styled.div`
  color: #4D4D4D;
  font-weight: 700;
  font-size: 16px;
  margin-right: 4px;
`;

export const CopesQuantity = styled.div`
  letter-spacing: 0.12px;
  color: #4D4D4D;
  font-size: 12px;
  margin-right: 10px;
`;

export const DaysLeft = styled.div`
  letter-spacing: 0.12px;
  color: #9a9a9a;
  font-size: 12px;
  margin-bottom: auto;
`;

export const BottomPanel = styled.div`
  display: flex;
  padding-right: 23px;
  align-items: center;
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-top: auto;
`;

export const Author = styled.div`
  display: flex;
  color: #4D4D4D;
  font-size: 14px;
  font-weight: 700;
`;

export const Person = styled(MdPerson)`
  width: 19px;
  height: 19px;
  background-color: #9a9a9a;
  color: #fff;
  border-radius: 100%;
  padding: 2px;
  margin-right: 10px;
`;

export const Avatar = styled.img`
  width: 19px;
  height: 19px;
  color: #fff;
  border-radius: 100%;
  padding: 2px;
  margin-right: 10px;
`;

export const Offers = styled.div`
  color: #4D4D4D;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #4D4D4D;
`;
