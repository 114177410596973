import React, { useState, memo } from 'react';
import * as S from './PhotosSlider.style';

const PhotosSlider = ({
  photos,
  selectPhoto,
}: {
  photos: any[];
  selectPhoto: (index: number) => void;
}) => {
  const photoWidth = 98;
  const [current, setCurrent] = useState(0);
  const [translate, setTranslate] = useState(0);
  const lastSlide = photos.length - 8;

  const previous = () => {
    const prevStep = current - 8;

    if (prevStep > 0) {
      setCurrent(prevStep);
      setTranslate(prevStep * photoWidth);
    } else if (prevStep <= 0 && current !== 0) {
      setCurrent(0);
      setTranslate(0);
    } else {
      setCurrent(lastSlide);
      setTranslate(lastSlide * photoWidth);
    }
  };

  const next = () => {
    const nextStep = current + 8;

    if (nextStep < lastSlide) {
      setCurrent(nextStep);
      setTranslate(nextStep * photoWidth);
    } else if (nextStep >= lastSlide && current !== lastSlide) {
      setCurrent(lastSlide);
      setTranslate(lastSlide * photoWidth);
    } else {
      setCurrent(0);
      setTranslate(0);
    }
  };

  const isPhotosEmpty = !photos || photos.length === 0;
  const sortedPhotos = isPhotosEmpty
    ? []
    : photos.sort((a: any, b: any) => {
        return a.order - b.order;
      });

  return (
    <S.Wrapper>
      {photos.length > 10 && <S.LeftArrow onClick={previous} />}
      <S.SliderContent translate={translate} width={photoWidth * photos.length}>
        {sortedPhotos.map(({ id, src, order }: any) => (
          <S.Slide key={id} content={src} onClick={() => selectPhoto(order)} />
        ))}
      </S.SliderContent>
      {photos.length > 10 && <S.RightArrow onClick={next} />}
    </S.Wrapper>
  );
};

export default memo(PhotosSlider);
