import { User } from './user';

export enum MODAL {
  login = 'LOGIN',
  userMenu = 'USER_MENU',
  createMenu = 'CREATE_MENU',
  signup = 'SIGN_UP',
  pickAWinner = 'PICK_A_WINNER',
}

export enum OFFER_WINNER_STEP {
  PICK_A_WINNER,
  LEAVE_COMMENT,
  SUMMARY,
  PAYMENT,
}

export enum VIEW_TYPE {
  BASIC,
  EXTENDED,
}

export interface Option {
  label: string;
  value: any;
}

export interface ImageProps {
  id: string;
  order: number;
  src: string;
}

export enum PRODUCT_STATUS {
  WAITING_FOR_FINALIZATION = 'Waiting for Finalization',
  FINALIZED = 'Finalized',
  PAID = 'Paid',
  PENDING = 'Pending',
  PREVIEW = 'Preview',
  SOLD_OUT = 'Sold Out',
  OPEN = 'Open',
  CLOSED = 'Closed',
}

export enum OFFER_STATUS {
  WAITING_FOR_APPROVAL = 'Waiting for Approval',
  ACCEPTED = 'Accepted',
  CANCELED = 'Canceled',
  REJECTED = 'Rejected',
  PAID = 'Paid',
}

export interface CommentLike {
  id: string;
  authorId: string;
  commentId: string;
  user: User;
  hasLiked: boolean;
}

export interface Comment {
  id: string;
  message: string;
  authorId: string;
  designForSellId: string;
  designRequestId: string;
  printRequestId: string;
  objectForSellId: string;
  user: User;
  likes: CommentLike[];
  createdAt: string;
}

export enum SimpleSearchFlag {
  ALL = 'All',
  PRINT_REQUESTS = 'PRINT_REQUESTS',
  OBJECT_FOR_SELL = 'OBJECT_FOR_SELL',
  DESIGN_REQUEST = 'DESIGN_REQUEST',
  DESIGNS_FOR_SELL = 'DESIGNS_FOR_SELL',
}

export interface AddLicensePayload {
  title: string;
  userId: string;
  // isCommercialAllowed: boolean;
  // isModifyAllowed: boolean;
  price: number;
  currencyId: string;
  // printingLimit: number;
  shortDescription: string;
  description: string;
}

export interface EditLicensePayload extends AddLicensePayload {
  id: string;
}

export interface SearchRequirements {
  query?: string;
  sortBy?: string;
  limit?: number;
  offset?: number;
}
