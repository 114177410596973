import styled from 'styled-components';

export const ExternalWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #eaeffa;
  padding-top: 60px;
  margin-bottom: 60px;
  `

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Inter;
  width: 100%;
  max-width: 1296px;
  margin: 0 auto;
  padding: 0 32px;
  margin-bottom: 40px;
`;

export const HeaderWrapper = styled.div`
display: flex;
padding-bottom: 20px;
`

export const Header = styled.h2`
margin: 0;
font-size: 30px;
font-weight: 700;
`

export const Container = styled.div`
display: flex;
flex-wrap: wrap;
gap: 20px;
`

export const TextContainer = styled.div`
display: flex;
flex-direction: column;
max-width: 410px;
padding: 40px 0;
`

export const LittleHeader = styled.h3`
margin: 0;
font-size: 24px;
padding-bottom: 16px;
font-weight: 500;
`

export const Body = styled.span``