import { MdCheck } from 'react-icons/md';
import styled from 'styled-components';
import U from '../../Assets/commonCSS';
export const Wrapper = styled.div``;

export const OfferPrintingButton = styled.button<{ disabled: boolean }>`
  ${U.flexCentered}
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.14px;
  max-width: 411px;
  width: 100%;
  height: 48px;
  margin-bottom: 19px;
  box-sizing: border-box;
  ${(props: any) => (props.disabled ? U.commonOutlinedDisabledButton : U.commonFullButton)}
`;

export const OffersAmount = styled.div`
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #4D4D4D;
  margin-bottom: 14px;
`;

export const PrintingOfferedWrapper = styled.div`
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 48px;
  margin-bottom: 19px;
  box-sizing: border-box;
`;

export const CheckTag = styled(MdCheck)`
  border-radius: 100%;
  width: 18px;
  height: 18px;
  padding: 2px;
  background-color: #1eaf56;
  color: #fff;
  margin-right: 10px;
`;

export const PrintingOfferedText = styled.div`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.14px;
  color: #4D4D4D;
`;

export const Underline = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin-left: 4px;
`;
