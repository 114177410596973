import styled from 'styled-components';
import themes from '../../Assets/colorThemes';

export const Wrapper = styled.div<{ isHelpCenter?: boolean }>`
  background-color: ${({ isHelpCenter }) => (isHelpCenter ? 'white' : themes.default.grayEf)};
  min-width: 304px;
  padding-top: 80px;
  padding-left: 22px;
  @media (max-width: 800px) {
    padding-top: 100px;
    padding-left: 0;
  }
`;

export const Header = styled.div`
  font-size: 14px;
  letter-spacing: 0.14px;
  color: ${themes.default.gray4d};
  font-weight: 500;
  padding-bottom: 5px;
  @media (max-width: 800px) {
    padding-left: 12px;
  }
`;

export const Breaker = styled.div`
  border: 0.5px solid ${themes.default.grey70};
  margin-bottom: 23px;
`;

export const Section = styled.div`
  font-weight: 500;
  margin-bottom: 23px;
  @media (max-width: 800px) {
    min-width: 160px;
  }
`;

export const MockSection = styled(Section)`
  display: none;
  @media (max-width: 519px) {
    display: block;
    height: 66px;
  }
`;

export const MenuContainer = styled.div`
  padding-top: 8px;
  @media (max-width: 800px) {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 8px 15px 0;
  }
`;

export const SectionHeader = styled.div`
  font-size: 11px;
  letter-spacing: 0.15px;
  margin-bottom: 1px;
`;

export const MenuItem = styled.div<{ isActive?: boolean }>`
  font-size: 16px;
  color: ${themes.default.gray4d};
  margin-top: 6px;
  text-decoration: ${({ isActive }) => (isActive ? 'underline' : '')};
  font-weight: ${({ isActive }) => (isActive ? 800 : 500)};
`;
