import styled from 'styled-components';
import themes from '../components/Assets/colorThemes';

export const SizeWrapper = styled.div<{ color: string; width?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props: any) => props.color};
  border-radius: 100%;
  min-width: 20px;
  /* margin-left: 5px; */
  width: ${(props: any) => (props.width ? props.width : '20px')};
  height: ${(props: any) => (props.width ? props.width : '20px')};
`;

export const Size = styled.div<{ isBlack: boolean; size?: string }>`
  font-size: ${(props: any) => (props.size ? props.size : '12px')};
  font-weight: 800;
  color: ${(props: any) =>
    props.isBlack ? `${themes.default.black0}` : `${themes.default.white}`};
`;
