import React, { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdClose } from 'react-icons/md';
import { setLoadingCreateNewAddress } from '../../../redux/actions';
import ClipLoader from 'react-spinners/ClipLoader';
import AddressOption from './AddressOption';
import AddressForm from './AddressForm';
import { isNewAddressAdding, userDefaultAddress } from '../../../redux/reducers/auth/selectors';
import NoDefaultAddress from './NoDefaultAddress';
import _get from 'lodash/get';
import * as S from './DeliveryAddressSelector.style';
import { clearAddressModals } from '../../../redux/reducers/utils/action';
import {
  createDeliveryAddress,
  setSelectedDeliveryAddress,
} from '../../../redux/reducers/auth/actions';

const DeliveryAddressSelector = ({ isDefault = false }: { isDefault?: boolean }) => {
  const dispatch = useDispatch();
  const defaultAddress = useSelector(userDefaultAddress);
  const isAddingNewAddress = useSelector(isNewAddressAdding);
  const [activeId, setActiveId] = useState(defaultAddress ? defaultAddress.id : '');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const areInputsFilled = name && surname && address && city && zipCode && country;
  const isApplyDisabled = isAddingNewAddress || !areInputsFilled;
  useEffect(() => {
    if (isDefault) {
      setName(_get(defaultAddress, 'name', ''));
      setSurname(_get(defaultAddress, 'surname', ''));
      setAddress(_get(defaultAddress, 'address', ''));
      setCity(_get(defaultAddress, 'city', ''));
      setZipCode(_get(defaultAddress, 'zipCode', ''));
      setCountry(_get(defaultAddress, 'country', ''));
      setRegion(_get(defaultAddress, 'region', ''));
    }
  }, [defaultAddress, isDefault]);

  const newAddress = {
    name,
    surname,
    address,
    city,
    zipCode,
    country,
    region,
  };

  const handleClearForm = () => {
    setName('');
    setSurname('');
    setAddress('');
    setCity('');
    setZipCode('');
    setCountry('');
    setRegion('');
  };

  const handleApply = () => {
    if (activeId === '') {
      dispatch(setLoadingCreateNewAddress(true));
      dispatch(createDeliveryAddress(newAddress));
    } else {
      dispatch(setSelectedDeliveryAddress(activeId));
      dispatch(clearAddressModals());
    }
  };
  const handleClose = () => {
    dispatch(setLoadingCreateNewAddress(false));
    setActiveId(activeId);
    dispatch(clearAddressModals());
    handleClearForm();
  };
  return (
    <S.ExternalWrapper>
      <S.Wrapper maxWidth="630px">
        <S.TopWrapper>
          <S.Title>{isDefault ? 'Address' : 'Delivery address'}</S.Title>
          <MdClose
            style={{
              color: '#BFBFBF',
              marginLeft: 'auto',
              paddingRight: '10px',
              cursor: 'pointer',
            }}
            size={28}
            onClick={handleClose}
          />
        </S.TopWrapper>
        <S.OptionsWrapper>
          {!isDefault &&
            (defaultAddress ? (
              <AddressOption
                isActive={defaultAddress.id === activeId}
                onClick={() => setActiveId(defaultAddress.id)}
                key={defaultAddress.id}
                address={defaultAddress}
              />
            ) : (
              <NoDefaultAddress />
            ))}
          {
            <AddressForm
              isSelectable={!isDefault}
              isActive={activeId === ''}
              onClick={() => setActiveId('')}
              setAddress={setAddress}
              setSurname={setSurname}
              setName={setName}
              setZipCode={setZipCode}
              setCountry={setCountry}
              setCity={setCity}
              setRegion={setRegion}
              name={name}
              surname={surname}
              address={address}
              city={city}
              zipCode={zipCode}
              country={country}
              region={region}
            />
          }
        </S.OptionsWrapper>
        <S.BottomWrapper>
          <S.CancelButton disabled={isAddingNewAddress} onClick={handleClose}>
            Cancel
          </S.CancelButton>
          <S.ApplyButton disabled={isApplyDisabled} onClick={handleApply}>
            {isAddingNewAddress ? <ClipLoader size={20} color={'#9B9B9B'} loading /> : <>Apply</>}
          </S.ApplyButton>
        </S.BottomWrapper>
      </S.Wrapper>
    </S.ExternalWrapper>
  );
};

export default memo(DeliveryAddressSelector);
