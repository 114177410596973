import { Notification } from '../utils/notificationTypes';
import { MODAL_NAME } from '../redux/reducers/utils/models';

export const EmptyCurrency: Currency = {
  id: '',
  symbol: '',
  currency: '',
};

export interface User {
  id: string;
  name: string;
  surname: string;
  nickname: string;
  rating: number;
  addresses: UserAddress[];
  isAdmin: boolean;
  avatar?: string;
  ratingCount: number;
  about?: string;
  createdAt?: string;
  email: string;
  deliveryOptions: DeliveryOptionProps[];
  selectedDeliveryTypes: string[];
  phone: string;
  language: any;
  timeZone: any;
  currency: any;
  isMapDisplayed: boolean;
  notifications: Notification[];
  iban: string;
  swift: string;
  paymentAddress: string;
  paymentCountry: string;
  paymentCity: string;
  paymentZipCode: string;
  paymentRegion: string;
  accountOwner: string;
  role: string;
  isArchived?: boolean;
  isNeccessaryAllowed?: boolean;
  isAnalyticsAllowed?: boolean;
  isMarketingAllowed?: boolean;
}

export const EmptyUser: User = {
  id: '',
  name: '',
  surname: '',
  nickname: '',
  rating: 0,
  addresses: [],
  isAdmin: false,
  ratingCount: 0,
  email: '',
  deliveryOptions: [],
  selectedDeliveryTypes: [],
  phone: '',
  language: {},
  timeZone: {},
  currency: EmptyCurrency,
  isMapDisplayed: false,
  notifications: [],
  iban: '',
  swift: '',
  paymentAddress: '',
  paymentCountry: '',
  paymentCity: '',
  paymentZipCode: '',
  paymentRegion: '',
  accountOwner: '',
  role: '',
};

export interface TimezoneProps {
  name: string;
  value: string;
  offset: number;
  id: string;
}

export interface UserAddress {
  address: string;
  city: string;
  country: string;
  region: string;
  zipCode: string;
  isDefault: boolean;
  id: string;
  name: string;
  surname: string;
  createdAt: string;
  isSelected?: boolean;
  lat: number;
  lon: number;
  isArchived: boolean;
}

export const EmptyAddress: UserAddress = {
  id: '',
  address: '',
  city: '',
  country: '',
  region: '',
  zipCode: '',
  isDefault: false,
  name: '',
  surname: '',
  createdAt: '',
  lat: 0,
  lon: 0,
  isArchived: false,
};

export interface Currency {
  id: string;
  symbol: string;
  currency: string;
}

export interface DeliveryOptionProps {
  id: string;
  name: string;
  price: number;
  currencyId: string;
  description: string;
  isDirty: boolean;
  isNew: boolean;
  isDeleted: boolean;
  isSelected: boolean;
  createdAt: string;
  currency: Currency;
}

export interface UserDataFormPayload {
  name?: string;
  surname?: string;
  nickname?: string;
  about?: string;
  phone?: string;
  email?: string;
  isMapDisplayed?: boolean;
  flag: UPDATE_USER_INFO_FLAGS;
  isNeccessaryAllowed?: boolean;
  isMarketingAllowed?: boolean;
  isAnalyticsAllowed?: boolean;
}

export enum USER_PROFILE_SECTION {
  ABOUT = 'about',
  PHOTOS = 'photos',
  WORKSHOP = 'workshop',
  DESIGN_OFFERS = 'design_offers',
  MARKET = 'market',
  REVIEWS = 'reviews',
  OFFERS = 'offers',
}

export interface UserProfileRequestPayload {
  flag: USER_PROFILE_SECTION;
  id: string;
}

export interface CreateDeliveryAddressPayload {
  name: string;
  surname: string;
  address: string;
  city: string;
  zipCode: string;
  country: string;
  region: string;
  lat?: number;
  lon?: number;
}

export interface AddOfferPayload {
  printRequestId: string;
  message: string;
  isDesign?: boolean;
}

export interface AddOffer {
  id: string;
  message: string;
}

export enum UPDATE_USER_INFO_FLAGS {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  ABOUT = 'ABOUT',
  NAME = 'NAME',
  MAP = 'MAP',
  COOKIES = 'COOKIES',
  PRIVACY = 'PRIVACY',
}

export const userUpdateFlagsToModalTitles = {
  [UPDATE_USER_INFO_FLAGS.EMAIL]: MODAL_NAME.CHANGE_EMAIL,
  [UPDATE_USER_INFO_FLAGS.PHONE]: MODAL_NAME.CHANGE_PHONE,
  [UPDATE_USER_INFO_FLAGS.ABOUT]: MODAL_NAME.CHANGE_ABOUT,
  [UPDATE_USER_INFO_FLAGS.NAME]: MODAL_NAME.CHANGE_NAME,
  [UPDATE_USER_INFO_FLAGS.MAP]: MODAL_NAME.MAP_ENLARGED,
  [UPDATE_USER_INFO_FLAGS.COOKIES]: MODAL_NAME.COOKIES,
  [UPDATE_USER_INFO_FLAGS.PRIVACY]: MODAL_NAME.PRIVACY,
};
