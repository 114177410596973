import about from './About.svg';
import photosLeftConstruct from './photosLeftConstruct.svg';
import photosMiddleConstruct from './photosMiddleConstruct.svg';
import photosRightConstruct from './photosRightConstruct.svg';
import marketLeftConstruct from './marketLeftConstruct.svg';
import marketRightConstruct from './marketRightConstruct.svg';
import emptyDiscussion from './emptyDiscussion.svg';
import emptyOffer from './emptyOffer.svg';
import emptyOfferConstruct from './emptyOfferConstruct.svg';

export default {
  about,
  photosLeftConstruct,
  photosMiddleConstruct,
  photosRightConstruct,
  marketLeftConstruct,
  marketRightConstruct,
  emptyDiscussion,
  emptyOffer,
  emptyOfferConstruct,
};
