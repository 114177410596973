import React, { memo } from 'react';
import SingleReview from './SingleReview/SingleReview';
import { Review } from '../../../models/reviews';
import { useParams } from 'react-router';
import { useUserProfile } from '../../../redux/reducers/userProfiles';
import * as S from './Reviews.style';

const Reviews = () => {
  const params: any = useParams();
  const { userId } = params;
  const { reviews } = useUserProfile(userId);

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <S.Header>Reviews</S.Header>
        <S.ReviewsCount>( {reviews.length} reviews )</S.ReviewsCount>
      </S.HeaderWrapper>
      <S.ReviewsWrapper>
        {reviews &&
          reviews.map((review: Review, index: number) => <SingleReview key={index} {...review} />)}
        <S.ShowMore>
          <S.B>Show more</S.B> reviews
        </S.ShowMore>
      </S.ReviewsWrapper>
    </S.Wrapper>
  );
};

export default memo(Reviews);
