import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
max-width: 1296px;
width: 100%;
margin: 0 auto
padding-top: 90px;
box-sizing: border-box;
padding: 0 16px;
box-sizing: border-box;
@media (max-width: 1175px) {
  margin-top: 128px;
}
@media (max-width: 745px) {
  margin-top: 200px;
}
`;

export const TitleWrapper = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid #efefef;
  width: 100%;
  margin-bottom: 32px;
`;

export const Title = styled.div`
  font-size: 24px;
  color: #4D4D4D;
  display: flex;
  align-items: baseline;
`;

export const Bolded = styled.div`
  font-weight: 900;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 75%;
  white-space: nowrap;
  display: inline;
`;

export const ResultsCount = styled.span`
  font-size: 16px;
  color: #9a9a9a;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
`;

export const Header = styled.div`
  font-size: 24px;
  color: #4D4D4D;
  margin-right: 4px;
`;

export const SecondaryHeader = styled(Link)`
  height: 17px;
  font-size: 14px;
  letter-spacing: 0.14px;
  color: #4D4D4D;
  margin: 0 0 0 auto;
  cursor: pointer;
`;

export const PrintRequestsWrapper = styled.div`
  min-height: 400px;
`;

export const ObjectsWrapper = styled(PrintRequestsWrapper)``;

export const DesignsWrapper = styled(PrintRequestsWrapper)``;

export const NoResultsWrapper = styled.div`
max-width: 1276px;
width: 100%;
height: 272px;
border: 1px solid #E2E2E2;
border-radius: 3px;
display: flex;
justify-content: center;
align-items center;
`;

export const LoaderWrapper = styled(NoResultsWrapper)`
  border: none;
`;

export const TextWrapper = styled.div``;

export const NoResultsTitle = styled.div`
  height: 24px;
  font-size: 20px;
  color: #4D4D4D;
  margin-bottom: 10px;
`;

export const NoResultsSubTitle = styled.div`
  height: 17px;
  font-size: 14px;
  letter-spacing: 0.14px;
  color: #4D4D4D;
`;
