import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import OfferAddedModal from '../Modals/OfferAddedModal/OfferAddedModal';
import { useLoggedInUser } from '../../redux/reducers/auth/selectors';
import PhotosSlider from './PhotosSlider/PhotosSlider';
import Slider from '../Common/Slider/Slider';
import { SLIDER_TYPE } from '../Dashboard/constant';
import { getSizeIcon } from '../../helpers/functions';
import { HashLink } from 'react-router-hash-link';
import Linkify from 'react-linkify';
import { linkDecorator } from '../../utils/linkDecorator';
import Discussion from './Discussion';
import { ImageProps } from '../../models/common';
import { PRODUCT_TYPE, productTypeName } from '../../utils/productType';
import CreatedProductModal from '../Modals/CreatedProductModal/CreatedProductModal';
import {
  viewedDesignForSell,
  featuredDesignsForSell,
  usersDesingsForSell,
  isLoadingViewedDesignForSell,
} from '../../redux/reducers/designForSell/selectors';
import { prepareDesignForSellDetails } from '../../redux/reducers/designForSell/actions';
import Loader from 'react-spinners/ClipLoader';
import {
  isDesignLicenseBought,
  previewDesignForSell,
  selectedViewedDesignLicense,
  useProductPhotosViewerModalState,
} from '../../redux/reducers/utils/selectors';
import { checkLicense, setModalProduct, toggleModal } from '../../redux/reducers/utils/action';
import BoughtLicense from './BoughtLicense/BoughtLicense';
import PickLicense from '../Common/PickLicense/PickLicense';
import FormatPrice from '../Common/FormatPrice/FormatPrice';
import { MODAL_NAME } from '../../redux/reducers/utils/models';
import ModalWrapper from '../Modals/ModalWrapper';
import ProductsViewer from '../Common/PhotosViewer/ProductsViewer';
import PurchasableProductDetailsOwnerActions from './ActionButton/PurchasableProductDetailsActions/PurchasableProductDetailsOwnerActions';
import PurchasableProductDetailsBuyerActions from './ActionButton/PurchasableProductDetailsActions/PurchasableProductDetailsBuyerActions';
import * as S from './ProductDetails.style';

const DesignForSellDetails = () => {
  const dispatch = useDispatch();
  const params: any = useParams();
   const navigate = useNavigate();
  const { hash } = useLocation();
  const { designForSellId }: any = params;
  const isPreview = designForSellId === 'preview';

  const isLoading = useSelector(isLoadingViewedDesignForSell);
  const designForSell = useSelector(viewedDesignForSell);
  const designPreview = useSelector(previewDesignForSell);
  const usedDesign = isPreview ? designPreview : designForSell;
  const isBought = useSelector(isDesignLicenseBought);

  const [selectedPhoto, setSelectedPhoto] = useState(0);
  const {
    id: desingId,
    comments = [],
    title,
    description,
    height,
    width,
    depth,
    size: { symbol },
    images = [],
    status,
    software,
    fileFormat,
    licenses,
    user: { id: ownerId, name, nickname, avatar },
  } = usedDesign;
  const budgetOrPriceText = 'Price';
  const { id: userId } = useSelector(useLoggedInUser);
  const isOwner = userId === ownerId;
  const isShowOwnerActionsActive = !isPreview && isOwner;
  const isShowBuyerActionsActive = !isPreview && !isOwner;
  const featuredDesigns = useSelector(featuredDesignsForSell);
  const usersDesigns = useSelector(usersDesingsForSell);
  const defaultLicense = useSelector(selectedViewedDesignLicense);
  const searchLink = '/search/design-for-sell/';
  const isProperId = !isPreview ? designForSellId === desingId : true;
  const {
    id: licenseId,
    price,
    currency: { symbol: currencySymbol, currency },
  } = defaultLicense;
  const isProductPhotosViewerActive = useSelector(useProductPhotosViewerModalState);

  useEffect(() => {
    if (isProductPhotosViewerActive) {
      dispatch(toggleModal(MODAL_NAME.PRODUCT_PHOTOS_VIEWER));
    }
    if (!isPreview) {
      dispatch(prepareDesignForSellDetails(designForSellId, false));
    }
  }, [designForSellId, dispatch, isPreview]);

  useEffect(() => {
    if (licenseId) {
      dispatch(checkLicense(designForSellId, licenseId));
    }
  }, [designForSellId, licenseId]);

  const userName = nickname && nickname.length > 0 ? nickname : name;

  const goBack = () => navigate(-1)

  const HashOffset = ({ hashVal, offset }: { hashVal: string; offset: number }) => (
    <div style={{ position: 'relative' }}>
      <span id={hashVal} style={{ position: 'absolute', top: `${offset}px` }} />
    </div>
  );

  const handleBackToEdit = () => {
    navigate('/design/new');
  };

  const displaySoftwareSpecification = () => {
    return (
      <S.PrintingSpecificationWrapper>
        <S.Row>
          <S.SubsectionTitle>Printing</S.SubsectionTitle>
        </S.Row>
        <S.Row>
          <S.QualityName>File Extension</S.QualityName>
          <S.Value>{fileFormat}</S.Value>
        </S.Row>
        <S.Row>
          <S.QualityName>Software</S.QualityName>
          <S.Value>{software}</S.Value>
        </S.Row>
      </S.PrintingSpecificationWrapper>
    );
  };

  const selectedImage = images.find(({ order }: ImageProps) => order === selectedPhoto);

  const openImageView = () => {
    dispatch(setModalProduct(usedDesign));
    dispatch(toggleModal(MODAL_NAME.PRODUCT_PHOTOS_VIEWER));
  };
  const displayImage = () => {
    if (selectedImage) {
      const { src } = selectedImage;
      return (
        <S.ImageWrapper>
          <S.PictureWrapper>
            <S.Picture onClick={() => openImageView()} src={src} />
          </S.PictureWrapper>
          <PhotosSlider photos={images} selectPhoto={setSelectedPhoto} />
        </S.ImageWrapper>
      );
    }
  };

  const displayMobileImage = () => {
    if (selectedImage) {
      const { src } = selectedImage;
      return (
        <S.MobileImageWrapper>
          <S.PictureWrapper>
            <S.Picture onClick={() => openImageView()} src={src} />
          </S.PictureWrapper>
          <PhotosSlider photos={images} selectPhoto={setSelectedPhoto} />
        </S.MobileImageWrapper>
      );
    }
  };

  if (isLoading || (!isPreview && !desingId) || !isProperId) {
    return (
      <S.LoaderWrapper>
        <Loader />
      </S.LoaderWrapper>
    );
  }
  return (
    <>
      <ModalWrapper
        title={MODAL_NAME.PRODUCT_PHOTOS_VIEWER}
        child={
          <ProductsViewer
            currentPhotoIndex={selectedPhoto}
            setParentImageIndex={setSelectedPhoto}
          />
        }
      />
      <S.Wrapper>
        {isPreview && (
          <S.PreviewWrapper>
            <S.PreviewLeft>
              <S.TopTitle>New design for sale -</S.TopTitle>
              <S.BottomTitle>Preview</S.BottomTitle>
            </S.PreviewLeft>
            <S.PreviewRight>
              <S.previewInfo>The design is not published yet</S.previewInfo>
              <S.BackToEditing onClick={handleBackToEdit}>Back to editing</S.BackToEditing>
              <S.Publish>Publish</S.Publish>
            </S.PreviewRight>
          </S.PreviewWrapper>
        )}
        <OfferAddedModal />
        <CreatedProductModal productType={PRODUCT_TYPE.DESIGN_FOR_SELL} />
        <S.Back>
          <S.ArrowLeft onClick={goBack} /> <S.BackText onClick={goBack}>Back</S.BackText>
        </S.Back>
        <S.PrintWrapper>
          <S.RightColumn>
            <S.AbstractWrapper>
              <S.SmallTitle>
                <b>Design</b> for sale ––
              </S.SmallTitle>
              <S.Title>
                {title} <S.ItemThumbnailHotStatus isFeatured={false}>New</S.ItemThumbnailHotStatus>
              </S.Title>
              {displayMobileImage()}
              <S.Author to={`/profile/${ownerId}`}>
                <S.CreatedBy>Created by:</S.CreatedBy>
                {avatar ? <S.Avatar src={avatar} /> : <S.Person />}
                <S.Name>{userName}</S.Name>
              </S.Author>
              <S.BudgetWrapper>
                <S.Tag>{budgetOrPriceText}</S.Tag>
                <S.BudgetValuesWrapper>
                  <S.Price>
                    <FormatPrice
                      price={price}
                      productCurrency={{ symbol: currencySymbol, currency }}
                    />
                  </S.Price>
                </S.BudgetValuesWrapper>
              </S.BudgetWrapper>
              <PickLicense licenses={licenses} />
              <S.OfferPrintingWrapper>
                {isShowOwnerActionsActive && (
                  <PurchasableProductDetailsOwnerActions
                    id={desingId}
                    productType={PRODUCT_TYPE.DESIGN_FOR_SELL}
                  />
                )}
                {isShowBuyerActionsActive && (
                  <>
                    <PurchasableProductDetailsBuyerActions
                      id={desingId}
                      productStatus={status}
                      productType={PRODUCT_TYPE.DESIGN_FOR_SELL}
                    />
                    {isBought && <BoughtLicense />}
                  </>
                )}
              </S.OfferPrintingWrapper>
            </S.AbstractWrapper>
          </S.RightColumn>
          <S.LeftColumn>
            {displayImage()}
            <S.MenuWrapper>
              <HashLink smooth to="#description-hash">
                <S.MenuItem isActive={hash === '#description-hash' || !hash}>
                  Description
                </S.MenuItem>
              </HashLink>
              <HashLink smooth to="#specification-hash">
                <S.MenuItem isActive={hash === '#specification-hash'}>Specification</S.MenuItem>
              </HashLink>
              <HashLink smooth to="#discussion-hash">
                <S.MenuItem isActive={hash === '#discussion-hash'}>Discussion</S.MenuItem>
              </HashLink>
            </S.MenuWrapper>
            <S.DescriptionWrapper>
              <HashOffset hashVal="description-hash" offset={-120} />
              <S.SectionTitle>Description</S.SectionTitle>
              <S.Description>
              {/* TODO */}
                {/* <Linkify componentDecorator={linkDecorator}>{description}</Linkify> */}
              </S.Description>
            </S.DescriptionWrapper>
            <S.SpecificationWrapper>
              <HashOffset hashVal="specification-hash" offset={-120} />
              <S.SectionTitle>Specification</S.SectionTitle>
              <S.DimensionsWrapper>
                <S.Row>
                  <S.SubsectionTitle>Size</S.SubsectionTitle>
                  {getSizeIcon(symbol)}
                </S.Row>
                <S.Row>
                  <S.QualityName>Width</S.QualityName>
                  <S.Value>{width} mm</S.Value>
                </S.Row>
                <S.Row>
                  <S.QualityName>Depth</S.QualityName>
                  <S.Value>{depth} mm</S.Value>
                </S.Row>
                <S.Row>
                  <S.QualityName>Height</S.QualityName>
                  <S.Value>{height} mm</S.Value>
                </S.Row>
              </S.DimensionsWrapper>
              {displaySoftwareSpecification()}
            </S.SpecificationWrapper>
            <>
              <HashOffset hashVal="discussion-hash" offset={-120} />
              <Discussion
                comments={comments}
                productId={designForSellId}
                productType={PRODUCT_TYPE.DESIGN_FOR_SELL}
              />
            </>
          </S.LeftColumn>
        </S.PrintWrapper>
        <S.SlidersWrapper>
          {usersDesigns.length > 0 && (
            <S.SliderWrapper>
              <S.SliderTitle to={`/profile/${ownerId}/market`}>
                <S.SliderUsersName>Other {userName}'s</S.SliderUsersName>{' '}
                {productTypeName[PRODUCT_TYPE.DESIGN_FOR_SELL]} ({usersDesigns.length})
              </S.SliderTitle>
              <Slider
                productType={PRODUCT_TYPE.DESIGN_FOR_SELL}
                items={usersDesigns}
                sliderType={SLIDER_TYPE.COMPACT}
              />
            </S.SliderWrapper>
          )}
          {featuredDesigns.length > 0 && (
            <S.SliderWrapper>
              <S.SliderTitle to={searchLink}>
                <S.B>Featured</S.B> {PRODUCT_TYPE.DESIGN_FOR_SELL}
              </S.SliderTitle>
              <Slider
                productType={PRODUCT_TYPE.DESIGN_FOR_SELL}
                items={featuredDesigns}
                sliderType={SLIDER_TYPE.COMPACT}
              />
            </S.SliderWrapper>
          )}
        </S.SlidersWrapper>
      </S.Wrapper>
    </>
  );
};

export default memo(DesignForSellDetails);
