import React from 'react';
import { history } from '../../../App';
import { OfferSimpleProps } from '../../../models/offer';
import * as S from './OfferSimple.style';

const OfferSimple = ({ user: { id, name, rating, addresses, avatar } }: OfferSimpleProps) => {
  const renderStars = () => {
    var stars = [];
    for (var i = 1; i <= 5; i++) {
      stars.push(i <= rating ? <S.FullStar key={i} /> : <S.ShadowStar key={i} />);
    }
    return stars;
  };
  const { city, country } =
    addresses.length > 0 ? addresses[addresses.length - 1] : { city: '', country: '' };
    const handleRedirectToUser = () => {
      // TODO
      // navigate( `/profile/${id}`)
    }
  return (
    <S.Wrapper>
      {avatar ? <S.Avatar src={avatar} /> : <S.Person />}
      <S.DataWrapper>
        <S.UserName onClick={handleRedirectToUser}>{name}</S.UserName>
        <S.Rating>
          <S.Stars>{renderStars()}</S.Stars>
          <S.RevsNo></S.RevsNo>
        </S.Rating>
        <S.Location>
          {city}
          {country.length > 1 && `, ${country}`}
        </S.Location>
      </S.DataWrapper>
    </S.Wrapper>
  );
};

export default OfferSimple;
