import * as api from '../../../api/common-api';
import {
  CREATE_DESIGN_FOR_SELL_SUCCESS,
  GET_FEATURED_DESIGNS_FOR_SELL,
  GET_FEATURED_DESIGNS_FOR_SELL_FAILURE,
  GET_FEATURED_DESIGNS_FOR_SELL_SUCCESS,
  GET_USER_DESIGNS_FOR_SELL,
  GET_USER_DESIGNS_FOR_SELL_FAILURE,
  GET_USER_DESIGNS_FOR_SELL_SUCCESS,
  GET_VIEWED_DESIGN_FOR_SELL,
  GET_VIEWED_DESIGN_FOR_SELL_FAILURE,
  GET_VIEWED_DESIGN_FOR_SELL_SUCCESS,
  SET_DESIGN_FOR_SELL_SUBMIT_ERROR,
  SET_SUBMITTING_DESIGN_FOR_SELL,
  PREPARE_DESIGN_FOR_SELL_DETAILS,
  PREPARE_DESIGN_FOR_SELL_DETAILS_SUCCESS,
  ADD_FREE_DFS,
  ADD_FREE_DFS_SUCCESS,
  ADD_FREE_DFS_FAILURE,
  REMOVE_DFS_COMMENT,
  REMOVE_DFS_COMMENT_SUCCESS,
  REMOVE_DFS_COMMENT_FAILURE,
  UPLOAD_DFS_FILES,
  UPLOAD_DFS_FILES_SUCCESS,
  UPLOAD_DFS_FILES_FAILURE,
} from './actionTypes';
import { history } from '../../../App';
import _get from 'lodash/get';
import { toggleModal, setSelectedDesignLicence } from '../utils/action';
import { MODAL_NAME } from '../utils/models';
import { EmptyLicense } from './models';

export function getUserDesignsForSell(userId: string, productId: string) {
  return function (dispatch: any) {
    dispatch({ type: GET_USER_DESIGNS_FOR_SELL });
    api.getUsersDesignsForSell(userId, productId).then(
      (response: any) => {
        dispatch({ type: GET_USER_DESIGNS_FOR_SELL_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: GET_USER_DESIGNS_FOR_SELL_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
}

export function getFeaturedDesignsForSell(productId: string) {
  return function (dispatch: any) {
    dispatch({ type: GET_FEATURED_DESIGNS_FOR_SELL });
    api.getFeaturedDesignsForSell(productId).then(
      (response: any) => {
        dispatch({ type: GET_FEATURED_DESIGNS_FOR_SELL_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: GET_FEATURED_DESIGNS_FOR_SELL_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
}

export function getViewedDesignForSell(productId: string) {
  return function (dispatch: any) {
    dispatch({ type: GET_VIEWED_DESIGN_FOR_SELL });
    api.getViewedDesignForSell(productId).then(
      (response: any) => {
        dispatch({ type: GET_VIEWED_DESIGN_FOR_SELL_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: GET_VIEWED_DESIGN_FOR_SELL_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
}
export const setSubmittingDesignForSell = (isSubmitting: boolean) => ({
  type: SET_SUBMITTING_DESIGN_FOR_SELL,
  isSubmitting,
});

export const setDesignForSellSubmitErrorMessage = (errorMessage: string) => ({
  type: SET_DESIGN_FOR_SELL_SUBMIT_ERROR,
  errorMessage,
});

export function createDesignForSell(data: any) {
  return function (dispatch: any) {
    return api.createDesignForSell(data).then(
      (response: any) => {
        dispatch({ type: CREATE_DESIGN_FOR_SELL_SUCCESS, data: response });
        // navigate(`/design-for-sell/${response.result.id}`);
        window.location.href = `/design-for-sell/${response.result.id}`;
        dispatch(setSubmittingDesignForSell(false));
        dispatch(toggleModal(MODAL_NAME.CREATE_PRODUCT));
      },
      (error: any) => {
        const errorMessage = _get(error, 'response.data', 'Unexpected error');
        if (typeof errorMessage === 'string') {
          dispatch(setDesignForSellSubmitErrorMessage(errorMessage));
        } else {
          dispatch(setDesignForSellSubmitErrorMessage('Unexpected error'));
        }
        dispatch(setSubmittingDesignForSell(false));
        console.log('An error occurred.', error);
      }
    );
  };
}

export function prepareDesignForSellDetails(designForSellId: string, blockLicenseReload: boolean) {
  return async function (dispatch: any) {
    dispatch({ type: PREPARE_DESIGN_FOR_SELL_DETAILS });
    const viewedDesignForSell = await api.getViewedDesignForSell(designForSellId);
    const {
      user: { id },
      licenses,
    } = viewedDesignForSell;
    const featuredDesignForSell = await api.getFeaturedDesignsForSell(designForSellId);
    const userDesignsForSell = await api.getUsersDesignsForSell(id, designForSellId);
    const data = {
      viewedDesignForSell,
      featuredDesignForSell,
      userDesignsForSell,
    };
    const defaultLicense = licenses && licenses.length > 0 ? licenses[0].offerLicense : EmptyLicense;
    if (!blockLicenseReload) {
      dispatch(setSelectedDesignLicence(defaultLicense));
    }
    dispatch({ type: PREPARE_DESIGN_FOR_SELL_DETAILS_SUCCESS, data });
  };
}

export function addFreeDesignForSell(dfsId: string, licenseId: string) {
  return function (dispatch: any) {
    dispatch({ type: ADD_FREE_DFS });
    api.addFreeDesignForSell(dfsId, licenseId).then(
      (response: any) => {
        dispatch({ type: ADD_FREE_DFS_SUCCESS, data: response });
        // navigate('');
        window.location.href = '';
          
      },
      (error: any) => {
        dispatch({ type: ADD_FREE_DFS_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
}

export function removeDFSComment(productId: string, commentId: string) {
  return function (dispatch: any) {
    dispatch({ type: REMOVE_DFS_COMMENT });
    api.removeComment(commentId).then(
      (response: any) => {
        dispatch({ type: REMOVE_DFS_COMMENT_SUCCESS, commentId, productId });
      },
      (error: any) => {
        dispatch({ type: REMOVE_DFS_COMMENT_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
}

export function uploadDFSFiles(data: any) {
  return function (dispatch: any) {
    dispatch({type: UPLOAD_DFS_FILES})
    return api.uploadDFSFiles(data).then(
      (response: any) => {
        const files = response
        dispatch({type: UPLOAD_DFS_FILES_SUCCESS, files })
      },
      (error: any) => {
        dispatch({type: UPLOAD_DFS_FILES_FAILURE})
      }
    );
  };
}