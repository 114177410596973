import { AnyAction } from 'redux';
import { GET_ALL_USERS_SUCCESS, UPDATE_USER_ARCHIVE_STATUS_SUCCESS } from '../actionTypes';
import { User } from '../../models/user';

export interface AdminState {
  users: {
    count: number;
    rows: User[];
  };
}

const initialState: AdminState = {
  users: {
    count: 0,
    rows: [],
  },
};

export default function (state = initialState, action: AnyAction): AdminState {
  switch (action.type) {
    case GET_ALL_USERS_SUCCESS: {
      return {
        ...state,
        users: action.data,
      };
    }
    case UPDATE_USER_ARCHIVE_STATUS_SUCCESS: {
      const updatedUser = action.data;
      const updatedUserId = updatedUser.id;
      const { count, rows } = state.users;
      const otherUsers = rows.filter(({ id }: any) => id !== updatedUserId);
      const allUsers = [updatedUser, ...otherUsers];
      return {
        ...state,
        users: { count, rows: allUsers },
      };
    }

    default:
      return state;
  }
}
