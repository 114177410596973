import styled from 'styled-components';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import U from '../../Assets/commonCSS';

export const Wrapper = styled.div`
  max-width: 823px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: 0;
  margin-bottom: 32px;
`;

const arrowCSS = `
width: 35px;
height: 35px;
border-radius: 100%;
background-color: #fff;
color: #BFBFBF;
padding: 2px;
z-index: ${U.zIndex1};
border: 1px solid #BFBFBF;
box-shadow: 0px 3px 4px #00000029;
`;

export const LeftArrow = styled(IoIosArrowBack)`
  ${arrowCSS}
  left: 306px;
`;

export const RightArrow = styled(IoIosArrowForward)`
  ${arrowCSS}
  left: 1130px;
`;

export const Slide = styled.div<{ content: string }>`
height: 88px;
width: 88px;
background-image: url('${(props: any) => props.content}');
background-repeat: no-repeat;
background-size: cover;
background-position: center;
margin-right: 8px;
cursor: pointer;
border-radius: 4px;
`;

interface ISlideContent {
  translate: number;
  width: number;
}
export const SliderContent = styled.div<ISlideContent>`
  transform: translateX(-${(props: ISlideContent) => props.translate}px);
  transition: transform ease-out 0.45s;
  height: 100%;
  width: ${(props: any) => props.width}px;
  display: flex;
` as any;
