import styled from 'styled-components';
import SVG from '../../Assets/SVG/svg-exporter';
export const Wrapper = styled.div`
  height: 184px;
  background-color: rgba(30, 175, 86, 0.2);
  display: flex;
`;

export const LeftColumn = styled.div`
  margin: 20px 34px 0 40px;
`;

export const RightColumn = styled.div`
  margin: 20px 0 0 0;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextWrapper = styled.div`
  font-size: 16px;
  color: #333333;
  width: 273px;
  cursor: pointer;
`;

export const Button = styled.div`
  border: 1px solid #1eaf56;
  box-sizing: border-box;
  color: #1eaf56;
  font-size: 14px;
  padding: 15px 63px;
  margin-top: 24px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
`;

export const Triangle = styled.img.attrs({ src: SVG.greenArrow })``;
