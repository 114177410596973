import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PRODUCT_TYPE } from '../../../utils/productType';
import { userDashboardSectionPath } from '../../../utils/productType';
import * as S from './CreatedProductModal.style';
import { isModalOpen } from '../../../redux/reducers/utils/selectors';
import { MODAL_NAME } from '../../../redux/reducers/utils/models';
import { toggleModal } from '../../../redux/reducers/utils/action';

interface CreateProductModalProps {
  productType: PRODUCT_TYPE;
}

const CreatedProductModal = ({ productType }: CreateProductModalProps) => {
  const dispatch = useDispatch();

  const isOpen = useSelector(isModalOpen(MODAL_NAME.CREATE_PRODUCT));

  return (
    <S.Wrapper open={isOpen}>
      <S.MessageWrapper>
        <S.Close onClick={() => dispatch(toggleModal(MODAL_NAME.CREATE_PRODUCT))} />
        <S.MessageText>
          Your <S.Bolded>product has been created</S.Bolded>—you can
          <S.Underline to={userDashboardSectionPath[productType]}>
            manage products in your dashboard.
          </S.Underline>
        </S.MessageText>
      </S.MessageWrapper>
    </S.Wrapper>
  );
};

export default CreatedProductModal;
