import React, { memo } from 'react';
import Linkify from 'react-linkify';
import { useDispatch, useSelector } from 'react-redux';
import { likeComment } from '../../../redux/actions';
import { linkDecorator } from '../../../utils/linkDecorator';
import { useLoggedInUser } from '../../../redux/reducers/auth/selectors';
import { PRODUCT_TYPE } from '../../../utils/productType';
import { Comment as IComment, CommentLike } from '../../../models/common';
import * as S from './Comment.style';
import { removePRComment } from '../../../redux/reducers/printRequest/actions';
import { removeDRComment } from '../../../redux/reducers/designRequest/actions';
import { removeOFSComment } from '../../../redux/reducers/objectForSell/actions';
import { removeDFSComment } from '../../../redux/reducers/designForSell/actions';

export interface CommentProps {
  id: string;
  message: string;
  authorId: string;
  productId: string;
  likes: CommentLike[];
  createdAt: Date;
  updatedAt: Date;
  user: {
    name: string;
    surname: string;
    nickname: string;
    avatar?: string;
  };
}

const Comment = ({
  productId,
  productType,
  comment: {
    id,
    message,
    likes,
    user: { id: commenterId, name, surname, nickname, avatar },
  },
}: {
  comment: IComment;
  productId: string;
  productType: PRODUCT_TYPE;
}) => {
  const dispatch = useDispatch();
  const upVotes = likes.filter(({ hasLiked }: CommentLike) => hasLiked).length;
  const downVotes = likes.filter(({ hasLiked }: CommentLike) => !hasLiked).length;

  const productRemoveComment = {
    [PRODUCT_TYPE.PRINT_REQUEST]: removePRComment,
    [PRODUCT_TYPE.DESIGN_REQUEST]: removeDRComment,
    [PRODUCT_TYPE.OBJECT_FOR_SELL]: removeOFSComment,
    [PRODUCT_TYPE.DESIGN_FOR_SELL]: removeDFSComment,
  };

  const removeComment = productRemoveComment[productType];

  const handleLike = (isLike: boolean) => {
    dispatch(likeComment({ payload: { commentId: id, isLike }, productType }));
  };

  const handleRemoveComment = () => {
    dispatch(removeComment(productId, id));
  };
  const { id: loggedInUserId } = useSelector(useLoggedInUser);
  return (
    <S.Wrapper>
      <S.BodyWrapper>
        <S.UserWrapper to={`/profile/${commenterId}`}>
          {avatar ? <S.Avatar src={avatar} /> : <S.Person />}
          <S.UserName>{nickname ? nickname : `${name} ${surname && surname[0]}`}</S.UserName>
        </S.UserWrapper>
        <S.Message>
        {/* TODO */}
          {/* <Linkify componentDecorator={linkDecorator}>{message}</Linkify> */}
        </S.Message>
        <S.FooterWrapper>
          <S.UpVotes>
            <S.ArrowUp onClick={() => handleLike(true)} />
            <S.Value>{upVotes}</S.Value>
          </S.UpVotes>
          <S.DownVotes>
            <S.ArrowDown onClick={() => handleLike(false)} />
            <S.Value>{downVotes}</S.Value>
          </S.DownVotes>
          {loggedInUserId === commenterId && (
            <S.DeleteWrapper>
              <S.Delete onClick={handleRemoveComment} />
            </S.DeleteWrapper>
          )}
        </S.FooterWrapper>
      </S.BodyWrapper>
    </S.Wrapper>
  );
};

export default memo(Comment);
