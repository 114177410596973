import styled from 'styled-components';

interface IWrapper {
  isSelected?: boolean;
}

export const Wrapper = styled.div`
  padding: 5px;
  border-bottom: 1px solid #bfbfbf;
  background-color: #fff;
  border-radius: 4px;
`;

export const Title = styled.div`
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
  font-weight: bold;
`;

export const MainContainer = styled.div<IWrapper>`
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: ${(props: any) => (props.isSelected ? '14px 12px' : '14px 7px')};
  background-color: ${(props: any) => (props.isSelected ? '#0077df' : '#fff')};
  color: ${(props: any) => (props.isSelected ? '#fff' : '')};
  :hover {
    background-color: #0077df;
    color: #fff;
  }
`;

export const SelectedLicense = styled.div`
  display: flex;
`;

export const LicenseDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const LicenseTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 5px;
`;

export const LicenseDetail = styled.div`
  font-size: 11px;
  padding-bottom: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
  max-width: 350px;
`;

export const PriceBox = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 1;
  justify-content: space-between;
  margin-right: 10px;
  font-weight: bold;
  flex-direction: column;
  font-size: 24px;
`;

export const ShowDetails = styled.div<IWrapper>`
  font-size: 11px;
  text-decoration: underline;
  .MainContainer:hover {
    color: #fff;
  }
`;
