import React, { memo, useEffect } from 'react';
import GalleryIcon from './GalleryIcon/GalleryIcon';
import { GalleryWithPhotos } from '../../../models/photos';
import { useParams } from 'react-router';
import { useUserProfile } from '../../../redux/reducers/userProfiles';
import EmptyGalleries from '../EmptyPanels/EmptyGalleries/EmptyGalleries';
import { useDispatch } from 'react-redux';
import * as S from './PhotosPage.style';
import { getUserProfile } from '../../../redux/actions';
import { USER_PROFILE_SECTION } from '../../../models/user';

const PhotosPage = () => {
  const params: any = useParams();
  const dispatch = useDispatch();
  const { userId } = params;
  const {
    photos: { galleries },
  } = useUserProfile(userId);
  const leftOvers = 4 - (galleries.length % 4);
  const isGalleriesEmpty = galleries && galleries.length === 0;

  useEffect(() => {
    dispatch(
      getUserProfile({
        flag:  USER_PROFILE_SECTION.PHOTOS,
        id: userId,
      })
    );
  },[ userId])

  return (
    <S.Wrapper>
      <S.Header>My galleries</S.Header>
      {isGalleriesEmpty ? (
        <EmptyGalleries />
      ) : (
        <S.GalleriesWrapper>
          {galleries.map((gallery: GalleryWithPhotos) => (
            <GalleryIcon key={gallery.id} {...gallery} userId={userId} />
          ))}
          {leftOvers >= 1 && <S.MockGallery />}
          {leftOvers >= 2 && <S.MockGallery />}
        </S.GalleriesWrapper>
      )}
    </S.Wrapper>
  );
};

export default memo(PhotosPage);
