export const GET_PRODUCT_PURCHASES = 'GET_PRODUCT_PURCHASES';
export const GET_PRODUCT_PURCHASES_SUCCESS = 'GET_PRODUCT_PURCHASES_SUCCESS';
export const GET_PRODUCT_PURCHASES_FAILURE = 'GET_PRODUCT_PURCHASES_FAILURE';
export const GET_PURCHASE = 'GET_PURCHASE';
export const GET_PURCHASE_SUCCESS = 'GET_PURCHASE_SUCCESS';
export const GET_PURCHASE_FAILURE = 'GET_PURCHASE_FAILURE';
export const SET_IS_PURCHASE_OWNER = 'SET_IS_PURCHASE_OWNER';
export const GET_MY_SHOPPING = 'GET_MY_SHOPPING';
export const GET_MY_SHOPPING_SUCCESS = 'GET_MY_SHOPPING_SUCCESS';
export const GET_MY_SHOPPING_FAILURE = 'GET_MY_SHOPPING_FAILURE';
