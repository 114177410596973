import React, { memo } from 'react';
import * as S from './Partners.styles';

interface IPartners {
  isHeaderShown?: boolean
}

const Partners = ({isHeaderShown = false}: IPartners) => (
  <S.Wrapper>
    {isHeaderShown && <S.Header>Partners</S.Header>}
    <S.LogosWrapper>
      {/* <S.FabLabLogo />
      <S.PrintingComLogo />
      <S.ThingiverseLogo />
      <S.FillamentumLogo /> */}
      <S.SimpleLogoContainer>
        <S.TmcLogo />
      </S.SimpleLogoContainer>
      <S.SpaghettiContainer>
        <S.TheSpaghettiDetective />
      </S.SpaghettiContainer>
      <S.SimpleLogoContainer>
        <S.Fiberlogy />
      </S.SimpleLogoContainer>
      <S.SimpleLogoContainer>
        <S.ByFlow />
      </S.SimpleLogoContainer>
    </S.LogosWrapper>
  </S.Wrapper>
);

export default memo(Partners);
