import React, { memo } from 'react';
import {
  productDashboardDetailsPath,
  PRODUCT_TYPE,
} from '../../../../utils/productType';
import { useNavigate } from 'react-router-dom';
import * as S from '../ActionButton.style';
import { useDispatch, useSelector } from 'react-redux';
import { useLoggedInUser } from '../../../../redux/reducers/auth/selectors';
import { toggleModal } from '../../../../redux/reducers/utils/action';
import { MODAL_NAME } from '../../../../redux/reducers/utils/models';

interface IPurchasableProductDetailsOwnerActions {
  id: string;
  productType: PRODUCT_TYPE;
}

const PurchasableProductDetailsOwnerActions = ({
  id,
  productType,
}: IPurchasableProductDetailsOwnerActions) => {
   const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: userId } = useSelector(useLoggedInUser);
  const typeText = productType === PRODUCT_TYPE.OBJECT_FOR_SELL ? 'object' : 'design'

  const handleRedirection = () => {
    if (!userId) {
      dispatch(toggleModal(MODAL_NAME.LOGIN));
      return;
    }
    navigate(`${productDashboardDetailsPath[productType]}${id}`)
  };

  return (
    <S.PrintingOfferedWrapper>
      <S.CheckTag />
      <S.PrintingOfferedText>
        <S.Underline onClick={handleRedirection}>
          Show {typeText} details
        </S.Underline>
      </S.PrintingOfferedText>
    </S.PrintingOfferedWrapper>
  );
};

export default memo(PurchasableProductDetailsOwnerActions);
