import { OFFER_WINNER_STEP } from '../../../models/common';
import { PRODUCT_TYPE } from '../../../utils/productType';
import { DesignForSell } from '../designForSell/models';
import { DesignRequest } from '../designRequest/models';
import { ObjectForSell } from '../objectForSell/models';
import { PrintRequest } from '../printRequest/models';

export interface Size {
  id: string;
  symbol: string;
  fullName: string;
  orderId: number;
  rangeFrom: number;
  rangeTo: number;
}

export interface Currency {
  id: string;
  symbol: string;
  currency: string;
  stripeFormat: number;
}

export interface Infill {
  id: string;
  name: string;
}

export interface Technology {
  id: string;
  name: string;
}

export interface Temperature {
  id: string;
  type: string;
}

export interface Unit {
  id: string;
  type: string;
}

export interface ShippingMethod {
  id: string;
  method: string;
}

export interface Material {
  id: string;
  name: string;
}

export interface FileExtension {
  id: string;
  name: string;
}

export interface Software {
  id: string;
  name: string;
}

export interface Distance {
  id: string;
  name: string;
}

export interface ExchangeRate {
  id: string;
  base: string;
  currency: string;
  rate: number;
}

export interface Language {
  id: string;
  name: string;
}

export interface TimeZone {
  id: string;
  name: string;
  value: string;
  offset: number;
}

export interface License {
  id: string;
  title: string;
  // isCommercialAllowed: boolean;
  // isModifyAllowed: boolean;
  isGlobal: boolean;
  price: number;
  // printingLimit: number;
  shortDescription: string;
  description: string;
  currency: Currency;
}

export interface Settings {
  sizes: Size[];
  currency: Currency[];
  infills: Infill[];
  technologies: Technology[];
  temperatures: Temperature[];
  units: Unit[];
  shippingMethods: ShippingMethod[];
  materials: Material[];
  fileExtensions: FileExtension[];
  software: Software[];
  distances: Distance[];
  languages: Language[];
  timeZones: TimeZone[];
  licenses: License[];
  exchangeRates: ExchangeRate[];
}

export enum VIEW_TYPE {
  BASIC,
  EXTENDED,
}

export enum MODAL_NAME {
  ADD_DEVICE = 'ADD_DEVICE',
  ADD_GALLERY = 'ADD_GALLERY',
  ADD_PACKAGE = 'ADD_PACKAGE',
  CHANGE_ABOUT = 'CHANGE_ABOUT',
  CHANGE_ACCOUNT = 'CHANGE_ACCOUNT',
  CHANGE_EMAIL = 'CHANGE_EMAIL',
  CHANGE_NAME = 'CHANGE_NAME',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CHANGE_PHONE = 'CHANGE_PHONE',
  CONFIRM_PACKAGE = 'CONFIRM_PACKAGE',
  COOKIES = 'COOKIES',
  CREATE_MENU = 'CREATE_MENU',
  CREATE_PRODUCT = 'CREATE_PRODUCT',
  DEFAULT_ADDRESS = 'DEFAULT_ADDRESS',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  DELETE_ACCOUNT_CONFIRM = 'DELETE_ACCOUNT_CONFIRM',
  DELETE_GALLERY = 'DELETE_GALLERY',
  DELIVERY_ADDRESS = 'DELIVERY_ADDRESS',
  DELIVERY_OPTIONS = 'DELIVERY_OPTIONS',
  DEVICE_MENU = 'DEVICE_MENU',
  DEVICE_OPERATION = 'DEVICE_OPERATION',
  FINALIZE_OFFER = 'FINALIZE_OFFER',
  EDIT_DEVICE = 'EDIT_DEVICE',
  EMAIL_SENT = 'EMAIL_SENT',
  EMAIL_CHANGED = 'EMAIL_CHANGED',
  EMAIL_CONFIRMATION = 'EMAIL_CONFIRMATION',
  FILL_PROFILE = 'FILL_PROFILE',
  HEADER_MAIN_MENU = 'HEADER_MAIN_MENU',
  LOGIN = 'LOGIN',
  MAP_ENLARGED = 'MAP_ENLARGED',
  NOTIFICATIONS = 'NOTIFICATIONS',
  OFFER_ADDED = 'OFFER_ADDED',
  OFFER_MENU = 'OFFER_MENU',
  PACKAGES_NOT_RECEIVED = 'PACKAGES_NOT_RECEIVED',
  PASSWORD_UPDATED = 'PASSWORD_UPDATED',
  PHOTO_MENU = 'PHOTO_MENU',
  PREVIEW = 'PREVIEW',
  PRIVACY = 'PRIVACY',
  RATE_CONTRACTOR = 'RATE_CONTRACTOR',
  REGIONAL_SETTINGS = 'REGIONAL_SETTINGS',
  SEARCH_BOX = 'SEARCH_BOX',
  SELECT_WINNING_OFFER = 'SELECT_WINNING_OFFER',
  SEND_A_TIP = 'SEND_A_TIP',
  SEND_EMAIL = 'SEND_EMAIL',
  SIGNUP = 'SIGNUP',
  USER_MENU = 'USER_MENU',
  LEAVE_COMMENT = 'LEAVE_COMMENT',
  SUMMARY = 'SUMMARY',
  PAYMENT = 'PAYMENT',
  ADD_LICENSE = 'ADD_LICENSE',
  DFS_MORE_ACTIONS = 'DFS_MORE_ACTIONS',
  DR_MORE_ACTIONS = 'DR_MORE_ACTIONS',
  OFS_MORE_ACTIONS = 'OFS_MORE_ACTIONS',
  PR_MORE_ACTIONS = 'PR_MORE_ACTIONS',
  DESIGN_OFFER_MORE_ACTIONS = 'DESIGN_OFFER_MORE_ACTIONS',
  EDIT_LICENSE = 'EDIT_LICENSE',
  PRODUCT_PHOTOS_VIEWER = 'PRODUCT_PHOTOS_VIEWER',
  PHOTOS_VIEWER = 'PHOTOS_VIEWER',
  LICENSE_DETAILS = 'LICENSE_DETAILS',
  RATE_SELLER = 'RATE_SELLER',
}

export interface IModalState {
  name: MODAL_NAME;
  isOpen: boolean;
}

export interface ModalsProperties {
  elementId: string;
  productType: PRODUCT_TYPE;
  galleryId: string;
  offers: any[];
  viewedProduct: PrintRequest | DesignRequest | ObjectForSell | DesignForSell | any;
  isAddingLicense: boolean;
  moreActionsId: string;
  designOfferMoreActionsId: string;
  isEditingLicense: boolean;
  licenseDescription: string;
  addLicenseErrorMessage: string;
}

export interface SelectedWinningOffer {
  step: OFFER_WINNER_STEP;
  id: string;
  comment: string;
}

export interface SelectedDevice {
  id: string;
  isPrinter: boolean;
}

export const getEmptySize = (): Size => ({
  id: '',
  fullName: '',
  symbol: '',
  rangeFrom: 0,
  rangeTo: 0,
  orderId: 0,
});

export const getEmptyUnit = (): Unit => ({
  id: '',
  type: '',
});

export const initialModalActiveStates = [
  { name: MODAL_NAME.ADD_LICENSE, isOpen: false },
  { name: MODAL_NAME.ADD_DEVICE, isOpen: false },
  { name: MODAL_NAME.ADD_GALLERY, isOpen: false },
  { name: MODAL_NAME.ADD_PACKAGE, isOpen: false },
  { name: MODAL_NAME.CHANGE_ABOUT, isOpen: false },
  { name: MODAL_NAME.CHANGE_ACCOUNT, isOpen: false },
  { name: MODAL_NAME.CHANGE_EMAIL, isOpen: false },
  { name: MODAL_NAME.CHANGE_NAME, isOpen: false },
  { name: MODAL_NAME.CHANGE_PASSWORD, isOpen: false },
  { name: MODAL_NAME.CHANGE_PHONE, isOpen: false },
  { name: MODAL_NAME.CONFIRM_PACKAGE, isOpen: false },
  { name: MODAL_NAME.COOKIES, isOpen: false },
  { name: MODAL_NAME.CREATE_MENU, isOpen: false },
  { name: MODAL_NAME.CREATE_PRODUCT, isOpen: false },
  { name: MODAL_NAME.DEFAULT_ADDRESS, isOpen: false },
  { name: MODAL_NAME.DELETE_ACCOUNT, isOpen: false },
  { name: MODAL_NAME.DELETE_ACCOUNT_CONFIRM, isOpen: false },
  { name: MODAL_NAME.DELETE_GALLERY, isOpen: false },
  { name: MODAL_NAME.DELIVERY_ADDRESS, isOpen: false },
  { name: MODAL_NAME.DELIVERY_OPTIONS, isOpen: false },
  { name: MODAL_NAME.DEVICE_MENU, isOpen: false },
  { name: MODAL_NAME.DEVICE_OPERATION, isOpen: false },
  { name: MODAL_NAME.FINALIZE_OFFER, isOpen: false },
  { name: MODAL_NAME.EDIT_DEVICE, isOpen: false },
  { name: MODAL_NAME.EMAIL_SENT, isOpen: false },
  { name: MODAL_NAME.EMAIL_CHANGED, isOpen: false },
  { name: MODAL_NAME.EMAIL_CONFIRMATION, isOpen: false },
  { name: MODAL_NAME.FILL_PROFILE, isOpen: false },
  { name: MODAL_NAME.HEADER_MAIN_MENU, isOpen: false },
  { name: MODAL_NAME.LOGIN, isOpen: false },
  { name: MODAL_NAME.MAP_ENLARGED, isOpen: false },
  { name: MODAL_NAME.NOTIFICATIONS, isOpen: false },
  { name: MODAL_NAME.OFFER_ADDED, isOpen: false },
  { name: MODAL_NAME.PACKAGES_NOT_RECEIVED, isOpen: false },
  { name: MODAL_NAME.PASSWORD_UPDATED, isOpen: false },
  { name: MODAL_NAME.PHOTO_MENU, isOpen: false },
  { name: MODAL_NAME.PREVIEW, isOpen: false },
  { name: MODAL_NAME.PRIVACY, isOpen: false },
  { name: MODAL_NAME.RATE_CONTRACTOR, isOpen: false },
  { name: MODAL_NAME.REGIONAL_SETTINGS, isOpen: false },
  { name: MODAL_NAME.SEARCH_BOX, isOpen: false },
  { name: MODAL_NAME.SELECT_WINNING_OFFER, isOpen: false },
  { name: MODAL_NAME.SEND_A_TIP, isOpen: false },
  { name: MODAL_NAME.SEND_EMAIL, isOpen: false },
  { name: MODAL_NAME.SIGNUP, isOpen: false },
  { name: MODAL_NAME.USER_MENU, isOpen: false },
  { name: MODAL_NAME.LEAVE_COMMENT, isOpen: false },
  { name: MODAL_NAME.PAYMENT, isOpen: false },
  { name: MODAL_NAME.SUMMARY, isOpen: false },
  { name: MODAL_NAME.DFS_MORE_ACTIONS, isOpen: false },
  { name: MODAL_NAME.DR_MORE_ACTIONS, isOpen: false },
  { name: MODAL_NAME.OFS_MORE_ACTIONS, isOpen: false },
  { name: MODAL_NAME.PR_MORE_ACTIONS, isOpen: false },
  { name: MODAL_NAME.DESIGN_OFFER_MORE_ACTIONS, isOpen: false },
  { name: MODAL_NAME.EDIT_LICENSE, isOpen: false },
  { name: MODAL_NAME.PRODUCT_PHOTOS_VIEWER, isOpen: false },
  { name: MODAL_NAME.PHOTOS_VIEWER, isOpen: false },
  { name: MODAL_NAME.LICENSE_DETAILS, isOpen: false },
  { name: MODAL_NAME.RATE_SELLER, isOpen: false },
];
export const ModalsClosedOnClickNames = [
  MODAL_NAME.DEVICE_MENU,
  MODAL_NAME.USER_MENU,
  MODAL_NAME.HEADER_MAIN_MENU,
  MODAL_NAME.CREATE_MENU,
  MODAL_NAME.SEARCH_BOX,
  MODAL_NAME.NOTIFICATIONS,
  MODAL_NAME.CREATE_PRODUCT,
  MODAL_NAME.FILL_PROFILE,
];

export const ModalsClosedOnClick = [
  { name: MODAL_NAME.DEVICE_MENU, isOpen: false },
  { name: MODAL_NAME.USER_MENU, isOpen: false },
  { name: MODAL_NAME.HEADER_MAIN_MENU, isOpen: false },
  { name: MODAL_NAME.CREATE_MENU, isOpen: false },
  { name: MODAL_NAME.SEARCH_BOX, isOpen: false },
  { name: MODAL_NAME.NOTIFICATIONS, isOpen: false },
  { name: MODAL_NAME.CREATE_PRODUCT, isOpen: false },
  { name: MODAL_NAME.FILL_PROFILE, isOpen: false },
];
