import React, { memo, useState } from 'react';
import Content from '../Common/Content';
import TableOfContents from '../Common/TableOfContents';
import PrivacyPLContent from './privacy.json';
import PrivacyENContent from './privacy.en.json';
import PrivacyNLContent from './privacy.nl.json'
import * as S from './Privacy.styles';

const Privacy = () => {
  const PrivacyPl = PrivacyPLContent.Privacy;
  const PrivacyEn = PrivacyENContent.Privacy;
  const PrivacyNl = PrivacyNLContent.Privacy;
  const PL = PrivacyPl;
  const EN = PrivacyEn;
  const NL = PrivacyNl
  const [usedParsedData, setIsUsedParsedData] = useState(PL);
  return (
    <S.ExternalWrapper>
      <S.Wrapper>
        <S.HeaderContainer>
          <S.Header>Privacy</S.Header>
          {/* <button onClick={() => handleChangeContent()}>przycisk</button> */}
        </S.HeaderContainer>
        <S.LastUpdateContainer>
          <S.LastUpdate>
            Last update: <S.BoldedDate>1 Oct 2021</S.BoldedDate>
          </S.LastUpdate>
          <S.ButtonContainer>
            <S.StyledButton onClick={() => setIsUsedParsedData(PL)}>PL</S.StyledButton>
            <S.StyledButton onClick={() => setIsUsedParsedData(EN)}>EN</S.StyledButton>
            <S.StyledButton onClick={() => setIsUsedParsedData(NL)}>NL</S.StyledButton>
          </S.ButtonContainer>
        </S.LastUpdateContainer>
        <TableOfContents headers={usedParsedData} />
        <Content paragraphs={usedParsedData} />
      </S.Wrapper>
    </S.ExternalWrapper>
  );
};

export default memo(Privacy);
