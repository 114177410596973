import React, { memo, useState } from 'react';
import { Photo } from '../../../models/photos';
import { useParams } from 'react-router';
import { useUserProfile } from '../../../redux/reducers/userProfiles';
import Modal from '@material-ui/core/Modal';
import SelectedPhotoViewer from '../SelectedPhotoViewer/SelectedPhotoViewer';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import _get from 'lodash/get';
import U from '../../Assets/commonCSS';
import * as S from './PhotosViewer.style';

const PhotosViewer = () => {
  const params: any = useParams();
  const { userId, galleryId } = params;
  const user = useUserProfile(userId);
   const navigate = useNavigate();

  const {
    photos: { galleries },
  } = user;

  const maybeGallery = galleries ? galleries.find((g) => g.id === galleryId) : undefined;
  const initialImage = _get(maybeGallery, 'photos[0].id', null);
  const [photoId, setPhotoId] = useState(initialImage);
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!maybeGallery) {
    navigate(-1)
    return null;
  }

  const { title, photos, createdAt } = maybeGallery;
  const selectedPhoto = photos.find((photo: Photo) => photo.id === photoId) || initialImage;
  const selectedPhotoSource = selectedPhoto ? selectedPhoto.image : photos[0].image;
  const isFillUpRequired = photos.length % 3 === 2;
  const currentIndex = photos.findIndex((photo: Photo) => photo.id === photoId);

  const nextPhoto = () => {
    if (currentIndex < photos.length - 1) {
      setPhotoId(photos[currentIndex + 1].id);
      return;
    }
    setPhotoId(photos[0].id);
  };

  const previousPhoto = () => {
    if (currentIndex === 0) {
      const length = photos.length;
      setPhotoId(photos[length - 1].id);
      return;
    }
    setPhotoId(photos[currentIndex - 1].id);
  };
  const showArrows = photos.length > 1;

  const handleClose = () => navigate(-1)

  return (
    <S.ExternalWrapper>
      {/* TODO */}
      {/* <Modal
        onClick={() => setIsModalOpen(false)}
        style={U.modalStyleProps(false)}
        open={isModalOpen}
        onBackdropClick={() => setIsModalOpen(false)}
      >
        <SelectedPhotoViewer
          selectedPhoto={selectedPhotoSource}
          close={() => setIsModalOpen(false)}
        />
      </Modal> */}
      <S.Wrapper>
        <S.TopWrapper>
          <S.Back onClick={handleClose}>
            <S.BackIcon />
            <S.BackToGalleries>Back </S.BackToGalleries>
          </S.Back>
          <S.CloseWrapper onClick={handleClose}>
            <S.Close />
          </S.CloseWrapper>
        </S.TopWrapper>
        <S.BodyWrapper>
          <S.MenuWrapper>
            <S.Title>{title}</S.Title>
            <S.CreatedAt>{moment(createdAt).format('DD MMM YYYY, HH:mm')}</S.CreatedAt>
            <S.MiniaturesWrapper>
              {photos.map(({ id, image }: Photo) => (
                <S.Miniature
                  key={id}
                  isSelected={photoId === id}
                  source={image}
                  onClick={() => setPhotoId(id)}
                />
              ))}
              {isFillUpRequired && <S.MockPhoto />}
            </S.MiniaturesWrapper>
          </S.MenuWrapper>
          <S.EmptyColumn />
          <S.SelectedPhotoWrapper>
            {showArrows && (
              <S.NavigateLeft onClick={previousPhoto}>
                <S.LeftIcon />
              </S.NavigateLeft>
            )}
            <S.SelectedPhoto onClick={() => setIsModalOpen(true)} source={selectedPhotoSource} />
            {showArrows && (
              <S.NavigateRight>
                <S.RightIcon onClick={nextPhoto} />
              </S.NavigateRight>
            )}
          </S.SelectedPhotoWrapper>
        </S.BodyWrapper>
      </S.Wrapper>
    </S.ExternalWrapper>
  );
};

export default memo(PhotosViewer);
