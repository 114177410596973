import styled from 'styled-components';
import U from '../../../Assets/commonCSS';
import themes from '../../../Assets/colorThemes';

export const MenuWrapper = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  position: absolute;
  background-color: ${themes.default.white};
  box-shadow: 1px 6px 16px ${themes.default.transparentGray};
  border: 1px solid ${themes.default.grayBf};
  border-radius: 4px;
  top: 130%;
  width: 251px;
  flex-direction: column;
  right: 0px;
  box-sizing: border-box;
  z-index: ${U.zIndexMenu};
`;

export const OptionsWrapper = styled.div`
  padding: 11px 0;
  font-weight: 500;
  height: 100%;
`;

export const MenuOption = styled.button`
  border: none;
  background-color: transparent;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  height: 42px;
  margin: 2px 0;
  font-size: 14px;
  padding: 12px 0 12px 24px;
  letter-spacing: 0;
  font-weight: 500;
  cursor: pointer;
  font-family: inherit;
  &:hover {
    background-color: ${themes.default.grayEf};
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  box-sizing: border-box;
  border-top: 1px solid ${themes.default.grayEf};
`;

export const Action = styled.button`
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  border-top: none;
  border-right: 1px solid ${themes.default.grayEf};
  flex-grow: 1;
  flex-basis: 50%;
  cursor: pointer;
  box-sizing: border-box;
  font-weight: 600;
  font-family: inherit;
  font-size: 16px;
  color: ${themes.default.black0};
`;

export const ActionLeft = styled(Action)`
  background-color: ${themes.default.blue};
  color: ${themes.default.white};
  border-radius: 0 0 0 4px;
`;

export const ActionRight = styled(Action)`
  border-radius: 0 0 4px 0;
`;

export const MenuLabel = styled.div``;
