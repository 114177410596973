import React, { memo } from 'react';
import Partners from '../../../Dashboard/Partners/Partners';
import * as S from './PartnersSection.styles';

const PartnersSection = () => {
  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <S.Header>Partnership and collaboration</S.Header>
        <S.Body>
          Let’s work together! We are open for innovative partnerships —{' '}
          <S.StyledAnchor>let’s start our partnership!</S.StyledAnchor>
        </S.Body>
      </S.HeaderWrapper>
      <Partners />
    </S.Wrapper>
  );
};

export default memo(PartnersSection);
