import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { logout } from '../../../../redux/reducers/auth/actions';
import * as S from './UserMenu.styles';
import { isModalOpen } from '../../../../redux/reducers/utils/selectors';
import { MODAL_NAME } from '../../../../redux/reducers/utils/models';
import { clearAllModalsStates, toggleModal } from '../../../../redux/reducers/utils/action';
import { isUserLoggedIn, loggedUser } from '../../../../redux/reducers/auth/selectors';

const PRINTING_REQUESTS_URL = '/dashboard/printing-requests';
const PRINTING_OFFERS_URL = '/dashboard/printing-offers';
const MY_SELLING_URL = '/dashboard/my-selling-objects';
const USER_CONTACT_INFO_URL = '/settings/personal-information';

const UserMenu = () => {
  const isLoggedIn = useSelector(isUserLoggedIn);
  const user = useSelector(loggedUser);
  const role = user ? user.role : 'user';
  const isVisible = useSelector(isModalOpen(MODAL_NAME.USER_MENU));
   const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleOptionSelect = (redirectUrl: string) => {
    dispatch(toggleModal(MODAL_NAME.USER_MENU));
    if (isLoggedIn) {
      dispatch(clearAllModalsStates);
      navigate(redirectUrl);
    } else {
      dispatch(toggleModal(MODAL_NAME.LOGIN));
    }
  };
  const handleProfileSelect = () => {
    if (user && user.id) {
      dispatch(clearAllModalsStates);
      navigate(`/profile/${user.id}`);
    } else {
      dispatch(toggleModal(MODAL_NAME.LOGIN));
    }
  };
  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  return (
    <S.MenuWrapper isVisible={isVisible}>
      <S.OptionsWrapper>
        {role === 'ADMIN' && (
          <S.MenuOption onClick={() => handleOptionSelect('/admin/users')}>Settings</S.MenuOption>
        )}
        <S.MenuOption onClick={(e: any) => handleOptionSelect(PRINTING_REQUESTS_URL)}>
          Market dashboard
        </S.MenuOption>
        <S.MenuOption onClick={() => handleOptionSelect(PRINTING_REQUESTS_URL)}>
          <S.MenuLabel>My requests</S.MenuLabel>
        </S.MenuOption>
        <S.MenuOption onClick={() => handleOptionSelect(PRINTING_OFFERS_URL)}>
          <S.MenuLabel>My offers</S.MenuLabel>
        </S.MenuOption>
        <S.MenuOption onClick={() => handleOptionSelect(MY_SELLING_URL)}>
          <S.MenuLabel>My selling</S.MenuLabel>
        </S.MenuOption>
        <S.MenuOption onClick={() => handleProfileSelect()}>Profile</S.MenuOption>
        <S.MenuOption onClick={() => handleOptionSelect(USER_CONTACT_INFO_URL)}>
          Account settings
        </S.MenuOption>
      </S.OptionsWrapper>
      <S.Actions>
        {isLoggedIn && <S.Action onClick={() => handleLogout()}>Log out</S.Action>}
        {!isLoggedIn && (
          <>
            <S.ActionLeft onClick={() => dispatch(toggleModal(MODAL_NAME.SIGNUP))}>
              Sign up
            </S.ActionLeft>
            <S.ActionRight onClick={() => dispatch(toggleModal(MODAL_NAME.LOGIN))}>
              Log in
            </S.ActionRight>
          </>
        )}
      </S.Actions>
    </S.MenuWrapper>
  );
};

export default memo(UserMenu);
