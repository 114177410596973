import styled from 'styled-components';
import U from '../../Assets/commonCSS';
import { Link as Links } from 'react-router-dom';

export const FooterContainer = styled.div`
  display: flex;
  background-color: #000;
  color: #e2e2e2;
  flex-shrink: 0;
  z-index: ${U.zIndex2};
  justify-content: center;
  width: 100%;
`;

export const InnerWrapper = styled.div`
  display: flex;
  max-width: 1275px;
  width: 100%;
`;
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
`;

export const TopWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

export const LinksContainer = styled.div`
  display: flex;
  max-width: 1276px;
  width: 100%;
  margin: auto;
  flex-wrap: wrap;
  padding: 0 12px;
  box-sizing: border-box;
  justify-content: space-evenly;
`;
export const Link = styled.div`
  font-size: 13px;
  font-weight: bold;
`;
export const Link_title = styled.div`
  text-align: left;
  letter-spacing: 0.3px;
  color: #bfbfbf;
  border-bottom: 1px solid #b6b6b6;
  width: 200px;
  margin-top: 12px;
  margin-bottom: 16px;
  font-weight: 900;
  line-height: 29px;
  @media (min-width: 1289px) {
    margin-right: 16px;
  }
`;
export const LinkList = styled.div`
  height: 200px;
  color: #e2e2e2;
  @media (max-width: 1000px) {
    height: 160px;
  }
`;
export const LinkFollowUs = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: white;
  justify-content: space-between;
`;
export const Icon = styled.div`
  margin-right: 10px;
  .icon {
    height: 18px;
    width: 18px;
  }
`;
export const Logo = styled.div`
  margin-top: 12px;
  @media (max-width: 1497px) {
    margin: 0;
  }
`;
// export const BottomWrapper = styled.div`
//   padding: 0 12px;
//   justify-content: flex-start;
// `;
export const Gap = styled.div`
  height: 80px;
`;
export const Copyright = styled.div`
  font-size: 13px;
  border-top: 1px solid #b6b6b6;
  padding: 8px;
  box-sizing: border-box;
  width: 100%;
  .bolded {
    font-weight: 800;
    color: #e2e2e2;
  }
  .spaceless {
    letter-spacing: -3px;
    width: 100%;
  }
`;
export const Euro = styled.div`
  width: 100%;
  margin: 0 auto 6px 0;
  display: flex;
  justify-content: flex-end;
  background-color: #000;
  .Euro_Button {
    background-color: #000;
    height: 29px;
    width: 72px;
    border-radius: 3px;
    font-size: 11px;
    padding: 3px;
    color: #0077df;
    border-color: #0077df;
  }
`;

export const FooterOption = styled(Links)`
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding-bottom: 4px;
  color: white;
  &:hover {
    color: #0077df;
  }
`;

export const StyledUi = styled.ul`
  display: flex;
  flex-direction: column;
`;
