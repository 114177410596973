import styled from 'styled-components';
import { AiOutlineSearch } from 'react-icons/ai';
import { WiTime9 } from 'react-icons/wi';
import { IoMdClose } from 'react-icons/io';
import U from '../../Assets/commonCSS';

export const Wrapper = styled.div<{ isActive?: boolean }>`
  max-width: ${(props: any) => (props.isActive ? '534' : '304')}px;
  width: 100%;
  @media (max-width: 700px) {
    width: 90.5vw;
  }
`;

export const SearchWrapper = styled.div<{ isActive?: boolean }>`
  max-width: ${(props: any) => (props.isActive ? '534' : '304')}px;
  width: 100%;
  height: 40px;
  display: flex;
  box-shadow: 1px 2px 4px #00000014;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  box-sizing: border-box;
  border-width: ${(props: any) => (props.isActive ? '1px 1px 0 1px' : '1px')};
  border-radius: ${(props: any) => (props.isActive ? '4px 4px 0 0' : '4px')};
  min-width: 110px;
`;

export const SearchIcon = styled(AiOutlineSearch)`
  height: 20px;
  min-width: 20px;
  margin: auto 8px auto 8px;
  cursor: pointer;
`;

export const Input = styled.input.attrs({ type: 'text', autocomplete: 'off' })<{
  isActive?: boolean;
}>`
  border: none;
  height: 100%;
  padding: 0 14px;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  z-index: ${U.zIndex1};
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px #0077df;
  }
`;

export const MenuWrapper = styled.div<{ isActive?: boolean }>`
  position: absolute;
  box-sizing: border-box;
  display: ${(props: any) => (props.isActive ? 'block' : 'none')};
  max-width: 534px;
  width: 100%;
  height: 352px;
  padding: 14px 16px 0 14px;
  background-color: #ffffff;
  border: solid #bfbfbf 1px;
  border-radius: 0px 0px 4px 4px;
  z-index: ${U.zIndex1};
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #bfbfbf;
  }
  @media (max-width: 700px) {
    width: 90.5vw;
  }
`;

export const RecentQueries = styled.div`
  height: 238px;
  overflow: hidden;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
`;

export const ItemText = styled.div`
  font-size: 14px;
  letter-spacing: 0.14px;
  color: #000000;
`;

export const RecentIcon = styled(WiTime9)`
  height: 17px;
  width: 17px;
  margin-right: 12px;
  color: #9a9a9a;
`;

export const SymbolWrapper = styled.div`
  width: 16px;
  height: 16px;
  background-color: #e2e2e2;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  padding: 1px;
`;

export const Symbol = styled.div`
  letter-spacing: 0.14px;
  color: #9a9a9a;
  font-size: 14px;
  font-weight: 600;
`;

export const RemoveIcon = styled(IoMdClose)`
  height: 17px;
  width: 17px;
  margin: 0 0 0 auto;
  color: #9a9a9a;
  cursor: pointer;
`;

export const SearchSelector = styled.button<{ isActive: boolean }>`
  font-size: 14px;
  background-color: ${({ isActive }) => (isActive ? '#4D4D4D' : '#EFEFEF')};
  color: ${({ isActive }) => (isActive ? '#efefef' : '#4d4d4d')};
  margin-right: 16px;
  margin-bottom: 24px;
  border: 1px ${({ isActive }) => (isActive ? '#4D4D4D' : '#BFBFBF')} solid;
  height: 35px;
  font-weight: 600;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
`;

export const MenuHeader = styled.div`
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #9a9a9a;
  text-transform: uppercase;
  margin-bottom: 14px;
`;

export const RecentHeader = styled(MenuHeader)`
  margin-bottom: 0;
`;

export const OptionsWrapper = styled.div`
  display: flex;
`;

export const DropdownWrapper = styled.div``;

export const ItemSymbolWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background-color: #e2e2e2;
  border-radius: 4px;
`;

export const listItemSymbol = styled.div`
  letter-spacing: 0.14px;
  color: #9a9a9a;
  font-size: 14px;
  font-weight: 700;
`;

export const PlainButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
`;
