export const GET_USER_PRINT_REQUESTS = 'GET_USER_PRINT_REQUESTS';
export const GET_USER_PRINT_REQUESTS_SUCCESS = 'GET_USER_PRINT_REQUESTS_SUCCESS';
export const GET_USER_PRINT_REQUESTS_FAILURE = 'GET_USER_PRINT_REQUESTS_FAILURE';
export const GET_FEATURED_PRINT_REQUESTS = 'GET_FEATURED_PRINT_REQUESTS';
export const GET_FEATURED_PRINT_REQUESTS_SUCCESS = 'GET_FEATURED_PRINT_REQUESTS_SUCCESS';
export const GET_FEATURED_PRINT_REQUESTS_FAILURE = 'GET_FEATURED_PRINT_REQUESTS_FAILURE';
export const GET_VIEWED_PRINT_REQUEST = 'GET_VIEWED_PRINT_REQUEST';
export const GET_VIEWED_PRINT_REQUEST_SUCCESS = 'GET_VIEWED_PRINT_REQUEST_SUCCESS';
export const GET_VIEWED_PRINT_REQUEST_FAILURE = 'GET_VIEWED_PRINT_REQUEST_FAILURE';
export const GET_SEARCHED_PRINT_REQUEST = 'GET_SEARCHED_PRINT_REQUEST';
export const GET_SEARCHED_PRINT_REQUEST_SUCCESS = 'GET_SEARCHED_PRINT_REQUEST_SUCCESS';
export const GET_SEARCHED_PRINT_REQUEST_FAILURE = 'GET_SEARCHED_PRINT_REQUEST_FAILURE';
export const SET_SUBMITTING_PRINT_REQUEST = 'SET_SUBMITTING_PRINT_REQUEST';
export const SET_PRINT_REQUEST_SUBMIT_ERROR = 'SET_PRINT_REQUEST_SUBMIT_ERROR';
export const CREATE_PRINT_REQUEST = 'CREATE_PRINT_REQUEST';
export const CREATE_PRINT_REQUEST_SUCCESS = 'CREATE_PRINT_REQUEST_SUCCESS';
export const CREATE_PRINT_REQUEST_FAILURE = 'CREATE_PRINT_REQUEST_FAILURE';
export const PREPARE_PRINT_REQUEST_DETAILS = 'PREPARE_PRINT_REQUEST_DETAILS';
export const PREPARE_PRINT_REQUEST_DETAILS_SUCCESS = 'PREPARE_PRINT_REQUEST_DETAILS_SUCCESS';
export const SET_PR_COMMENTS = 'SET_PR_COMMENTS';
export const SET_PR_OFFERS = 'SET_PR_OFFERS';
export const ADD_PR_PACKAGE = 'ADD_PR_PACKAGE';
export const ADD_PR_PACKAGE_SUCCESS = 'ADD_PR_PACKAGE_SUCCESS';
export const ADD_PR_PACKAGE_FAIL = 'ADD_PR_PACKAGE_FAIL';
export const UPDATE_PR_PACKAGES = 'UPDATE_PR_PACKAGES';
export const UPDATE_PR_PACKAGES_SUCCESS = 'UPDATE_PR_PACKAGES_SUCCESS';
export const UPDATE_PR_PACKAGES_FAIL = 'UPDATE_PR_PACKAGES_FAIL';
export const SET_UPDATED_PR_OFFERS = 'SET_UPDATED_PR_OFFERS';
export const SET_UPDATED_PR_OFFERS_SUCCESS = 'SET_UPDATED_PR_OFFERS_SUCCESS';
export const SET_UPDATED_PR_OFFERS_FAILURE = 'SET_UPDATED_PR_OFFERS_FAILURE';
export const SET_PR_LIKE = 'SET_PR_LIKE';
export const REMOVE_PR_COMMENT = 'REMOVE_PR_COMMENT';
export const REMOVE_PR_COMMENT_SUCCESS = 'REMOVE_PR_COMMENT_SUCCESS';
export const REMOVE_PR_COMMENT_FAILURE = 'REMOVE_PR_COMMENT_FAILURE';
export const UPLOAD_PR_FILES = 'UPLOAD_PR_FILES';
export const UPLOAD_PR_FILES_SUCCESS = 'UPLOAD_PR_FILES_SUCCESS';
export const UPLOAD_PR_FILES_FAILURE = 'UPLOAD_PR_FILES_FAILURE';
export const ADD_FREE_PR = 'ADD_FREE_PR'
export const ADD_FREE_PR_SUCCESS = 'ADD_FREE_PR_SUCCESS'
export const ADD_FREE_PR_FAILURE = 'ADD_FREE_PR_FAILURE'