import React, { memo } from 'react';
import { Option } from '../../../models/common';
import Selector, {
  SELECTOR_BORDER_RADIUS_OPTIONS,
  SELECTOR_TYPE,
} from '../../CreateProduct/Inputs/Selector';
import * as S from './Partials.style';

const sortByDefaultOptions: any[] = [
  { value: { sortByTarget: 'createdAt', sortByDirection: 'DESC' }, label: 'Creation date' },
  { value: { sortByTarget: 'status', sortByDirection: 'ASC' }, label: 'Status' },
  { value: { sortByTarget: 'price', sortByDirection: 'ASC' }, label: 'Lowest price' },
  { value: { sortByTarget: 'price', sortByDirection: 'DESC' }, label: 'Highest price' },
];

interface ResultsHeaderProps {
  page: number;
  sortBy: Option;
  pagesNo: number;
  query: string;
  isSearchDisabled?: boolean;
  sortByOptions?: any[];
  setQuery: (value: string) => void;
  setPage: (val: number) => void;
  setSortBy: (option: Option) => void;
  setRefresh: (state: boolean) => void;
}

const ResultsHeader = ({
  page,
  setPage,
  sortBy,
  setSortBy,
  pagesNo,
  query,
  sortByOptions = sortByDefaultOptions,
  setQuery,
  setRefresh,
  isSearchDisabled = false,
}: ResultsHeaderProps) => {
  const incrementPage = () => {
    if (pagesNo - 1 >= page + 1) {
      setPage(page + 1);
      setRefresh(true);
    }
  };
  const decrementPage = () => {
    if (page > 0) {
      setPage(page - 1);
      setRefresh(true);
    }
  };

  const onKeyPressed = (event: any) => {
    if (event.key === 'Enter') {
      setRefresh(true);
    }
  };

  const onSearchClick = () => {
    setRefresh(true);
  };

  return (
    <S.SearchWrapper>
      {isSearchDisabled ? (
        <S.EmptySpaceWrapper />
      ) : (
        <S.InputWrapper>
          <S.Search
            value={query}
            placeholder="Search for title or user…"
            onChange={(e: any) => setQuery(e.target.value)}
            onKeyPress={(e: any) => onKeyPressed(e)}
          />
          <S.MagnifyingGlass onClick={() => onSearchClick()} />
        </S.InputWrapper>
      )}
      <S.SortWrapper>
        <S.Label>Sort by</S.Label>
        <Selector
          placeholder={sortBy.label}
          isSearchable={false}
          options={sortByOptions}
          selected={sortBy.label}
          handleOptionSelect={setSortBy}
          selectorAlignment={SELECTOR_BORDER_RADIUS_OPTIONS.CENTER}
          type={SELECTOR_TYPE.USER_DASHBOARD}
        />
      </S.SortWrapper>
      <S.PaginationWrapper>
        <S.ArrowLeft onClick={decrementPage} />
        <S.PageInput
          value={page + 1}
          onChange={(e: any) =>
            e.target.value - 1 <= pagesNo && e.target.value - 1 >= 0 && setPage(e.target.value - 1)
          }
        />
        <S.PerPage>/ {pagesNo === 0 ? 1 : pagesNo}</S.PerPage>
        <S.ArrowRight onClick={incrementPage} />
      </S.PaginationWrapper>
    </S.SearchWrapper>
  );
};

export default memo(ResultsHeader);
