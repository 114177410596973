import React, { memo } from 'react';
import * as S from './Content.styles';

interface ISimpleParagraph {
  title: string;
  body?: string;
  items?: ISimpleParagraph[];
}
// interface ISubParagraph {
//   title: string;
//   body?: string;
//   items?: ISimpleParagraph[];
// }

export interface IParagraph {
  title: string;
  body?: string;
  items?: ISimpleParagraph[];
}
export interface IContent {
  paragraphs: IParagraph[];
}

const Content = ({ paragraphs }: IContent) => {
  return (
    <S.Wrapper>
      {paragraphs.map((paragraph, index) => (
        <S.ParagraphContainer key={index + 1}>
          <S.Paragraph id={paragraph.title}>
            {index + 1}. {paragraph.title}
          </S.Paragraph>
          <S.Content>{paragraph.body}</S.Content>
          {paragraph.items &&
            paragraph.items.length > 0 &&
            paragraph.items.map((subparagraph, subIndex) => (
              <S.ParagraphContainer key={subIndex + 1}>
                <S.Paragraph id={subparagraph.title}>
                  {index + 1}.{subIndex + 1} {subparagraph.title}
                </S.Paragraph>
                <S.Content>{subparagraph.body}</S.Content>
                {subparagraph.items &&
                  subparagraph.items.length > 0 &&
                  subparagraph.items.map((secondaryPar, secIndex) => (
                    <S.ParagraphContainer key={subIndex + 1}>
                      <S.Paragraph id={secondaryPar.title}>
                        {index + 1}.{subIndex + 1}.{secIndex + 1} {secondaryPar.title}
                      </S.Paragraph>
                      <S.Content>{secondaryPar.body}</S.Content>
                      {secondaryPar.items &&
                        secondaryPar.items.length > 0 &&
                        secondaryPar.items.map((thirdPar, thirdIndex) => (
                          <S.ParagraphContainer key={thirdIndex + 1}>
                            <S.Paragraph id={thirdPar.title}>
                              {index + 1}.{subIndex + 1}.{secIndex + 1}.{thirdIndex + 1}{' '}
                              {thirdPar.title}
                            </S.Paragraph>
                            <S.Content>{thirdPar.body}</S.Content>
                            {thirdPar.items &&
                              thirdPar.items.length > 0 &&
                              thirdPar.items.map((forthPar, forthIndex) => (
                                <S.ParagraphContainer key={forthIndex + 1}>
                                  <S.Paragraph id={forthPar.title}>
                                    {index + 1}.{subIndex + 1}.{secIndex + 1}.{thirdIndex + 1}.{forthIndex + 1}{' '}
                                    {forthPar.title}
                                  </S.Paragraph>
                                  <S.Content>{forthPar.body}</S.Content>
                                </S.ParagraphContainer>
                              ))}
                          </S.ParagraphContainer>
                        ))}
                    </S.ParagraphContainer>
                  ))}
              </S.ParagraphContainer>
            ))}
        </S.ParagraphContainer>
      ))}
    </S.Wrapper>
  );
};

export default memo(Content);
