import * as api from '../../../api/common-api';
import {
  CREATE_DESIGN_REQUEST_SUCCESS,
  GET_USER_DESIGN_REQUESTS,
  GET_USER_DESIGN_REQUESTS_FAILURE,
  GET_USER_DESIGN_REQUESTS_SUCCESS,
  SET_DESIGN_REQUEST_SUBMIT_ERROR,
  SET_SUBMITTING_DESIGN_REQUEST,
  GET_FEATURED_DESIGN_REQUESTS,
  GET_FEATURED_DESIGN_REQUESTS_SUCCESS,
  GET_FEATURED_DESIGN_REQUESTS_FAILURE,
  GET_VIEWED_DESIGN_REQUEST,
  GET_VIEWED_DESIGN_REQUEST_SUCCESS,
  GET_VIEWED_DESIGN_REQUEST_FAILURE,
  PREPARE_DESIGN_REQUEST_DETAILS,
  PREPARE_DESIGN_REQUEST_DETAILS_SUCCESS,
  SET_UPDATED_DR_OFFERS,
  SET_UPDATED_DR_OFFERS_SUCCESS,
  SET_UPDATED_DR_OFFERS_FAILURE,
  REMOVE_DR_COMMENT,
  REMOVE_DR_COMMENT_SUCCESS,
  REMOVE_DR_COMMENT_FAILURE,
  UPLOAD_DR_FILES,
  UPLOAD_DR_FILES_SUCCESS,
  UPLOAD_DR_FILES_FAILURE,
  ADD_FREE_DR,
  ADD_FREE_DR_SUCCESS,
  ADD_FREE_DR_FAILURE,
} from './actionTypes';
import { history } from '../../../App';
import _get from 'lodash/get';
import { toggleModal, setModalProduct } from '../utils/action';
import { MODAL_NAME } from '../utils/models';

export function getUserDesignRequests(userId: string, productId: string) {
  return function (dispatch: any) {
    dispatch({ type: GET_USER_DESIGN_REQUESTS });
    api.getUsersDesignRequests(userId, productId).then(
      (response: any) => {
        dispatch({ type: GET_USER_DESIGN_REQUESTS_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: GET_USER_DESIGN_REQUESTS_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
}

export function getFeaturedDesignRequests(productId: string) {
  return function (dispatch: any) {
    dispatch({ type: GET_FEATURED_DESIGN_REQUESTS });
    api.getFeaturedDesignRequests(productId).then(
      (response: any) => {
        dispatch({ type: GET_FEATURED_DESIGN_REQUESTS_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: GET_FEATURED_DESIGN_REQUESTS_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
}

export function getViewedDesignRequest(productId: string) {
  return function (dispatch: any) {
    dispatch({ type: GET_VIEWED_DESIGN_REQUEST });
    api.getViewedDesignRequest(productId).then(
      (response: any) => {
        dispatch(setModalProduct(response));
        dispatch({ type: GET_VIEWED_DESIGN_REQUEST_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: GET_VIEWED_DESIGN_REQUEST_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
}
export const setSubmittingDesignRequest = (isSubmitting: boolean) => ({
  type: SET_SUBMITTING_DESIGN_REQUEST,
  isSubmitting,
});

export const setDesignRequestSubmitErrorMessage = (errorMessage: string) => ({
  type: SET_DESIGN_REQUEST_SUBMIT_ERROR,
  errorMessage,
});

export function createDesignRequest(data: any) {
  return function (dispatch: any) {
    return api.createDesignRequest(data).then(
      (response: any) => {
        dispatch({ type: CREATE_DESIGN_REQUEST_SUCCESS, data: response });
        // navigate(`/design-request/${response.result.id}`);
        // @TODO
        dispatch(setSubmittingDesignRequest(false));
        dispatch(toggleModal(MODAL_NAME.CREATE_PRODUCT));
      },
      (error: any) => {
        const errorMessage = _get(error, 'response.data', 'Unexpected error');
        if (typeof errorMessage === 'string') {
          dispatch(setDesignRequestSubmitErrorMessage(errorMessage));
        } else {
          dispatch(setDesignRequestSubmitErrorMessage('Unexpected error'));
        }
        dispatch(setSubmittingDesignRequest(false));
        console.log('An error occurred.', error);
      }
    );
  };
}

export function prepareDesignRequstDetails(productId: string) {
  return async function (dispatch: any) {
    dispatch({ type: PREPARE_DESIGN_REQUEST_DETAILS });
    const viewedDesignRequest = await api.getViewedDesignRequest(productId);
    const {
      user: { id },
    } = viewedDesignRequest;
    const featuredDesignRequests = await api.getFeaturedDesignRequests(productId);
    const userDesignRequests = await api.getUsersDesignRequests(id, productId);

    const data = {
      viewedDesignRequest,
      featuredDesignRequests,
      userDesignRequests,
    };
    dispatch({ type: PREPARE_DESIGN_REQUEST_DETAILS_SUCCESS, data });
  };
}

export function pickDROffer(offerId: string, responseMessage?: string) {
  return function (dispatch: any) {
    dispatch({ type: SET_UPDATED_DR_OFFERS });
    api.pickOffer({ offerId, responseMessage }).then(
      (response: any) => {
        dispatch({ type: SET_UPDATED_DR_OFFERS_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: SET_UPDATED_DR_OFFERS_FAILURE });
        console.log('An error occurred.', error);
      }
    );
  };
}

export function removeDRComment(productId: string, commentId: string) {
  return function (dispatch: any) {
    dispatch({ type: REMOVE_DR_COMMENT });
    api.removeComment(commentId).then(
      (response: any) => {
        dispatch({ type: REMOVE_DR_COMMENT_SUCCESS, commentId, productId });
      },
      (error: any) => {
        dispatch({ type: REMOVE_DR_COMMENT_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
}

export function uploadDRFiles(uploadData: any) {
  return function (dispatch: any) {
    dispatch({ type: UPLOAD_DR_FILES });
    api.uploadDRFiles(uploadData).then(
      (response: any) => {
        dispatch({ type: UPLOAD_DR_FILES_SUCCESS, files: response });
      },
      (error: any) => {
        dispatch({ type: UPLOAD_DR_FILES_FAILURE });
        console.log('An error occurred.', error);
      }
    );
  };
}

export function addFreeDesignRequest(id: string) {
  return function (dispatch: any) {
    dispatch({ type: ADD_FREE_DR });
    api.addFreeDesignRequest(id).then(
      (response: any) => {
        dispatch({ type: ADD_FREE_DR_SUCCESS, data: response });
        // navigate('');
        // TODO
      },
      (error: any) => {
        dispatch({ type: ADD_FREE_DR_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
}