import { Comment, PRODUCT_STATUS, ImageProps } from '../../../models/common';
import { OfferSimpleProps } from '../../../models/offer';
import { Size, Unit, EmptySize, EmptyUnit } from '../../../models/units';
import { Currency, User, EmptyCurrency, EmptyUser } from '../../../models/user';
import { Purchase } from '../purchase/models';
import { License, EmptyLicense, OfferLicense } from '../designForSell/models';

export interface DesignRequest {
  id: string;
  images: ImageProps[];
  notifications: any[];
  attachment: string[];
  title: string;
  price: number;
  currency: Currency;
  isPaid: boolean;
  deadline: string;
  licenseRequirements: string;
  description: string;
  status: PRODUCT_STATUS;
  height: number;
  width: number;
  depth: number;
  positionX: string;
  positionY: string;
  volume: number;
  email: string;
  phone: string;
  distance: string;
  isArchived: boolean;
  fileFormat: string;
  user: User;
  size: Size;
  unit: Unit;
  comments: Comment[];
  offers: OfferSimpleProps[];
  purchase?: Purchase;
  quantity?: number;
  license: License;
  offerLicense: OfferLicense
  files: any[];
}

export const EmptyDesignRequest: DesignRequest = {
  id: '',
  images: [],
  notifications: [],
  attachment: [],
  title: '',
  price: 0,
  currency: EmptyCurrency,
  isPaid: false,
  deadline: '',
  licenseRequirements: '',
  description: '',
  status: PRODUCT_STATUS.WAITING_FOR_FINALIZATION,
  height: 0,
  width: 0,
  depth: 0,
  positionX: '',
  positionY: '',
  volume: 0,
  email: '',
  phone: '',
  distance: '',
  isArchived: false,
  fileFormat: '',
  user: EmptyUser,
  size: EmptySize,
  unit: EmptyUnit,
  comments: [],
  offers: [],
  license: EmptyLicense,
  files: [],
  offerLicense: EmptyLicense
};
