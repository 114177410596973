import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as S from './FillProfile.style';
import { toggleModal } from '../../../redux/reducers/utils/action';
import { MODAL_NAME } from '../../../redux/reducers/utils/models';
import { fillRequirements } from './FillProfileRequirements';
import { useCreateProductType } from '../../../redux/reducers/utils/selectors';
import { useLoggedInUser } from '../../../redux/reducers/auth/selectors';

const FillProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const productType = useSelector(useCreateProductType);
  const handleGoToProfilePage = () => {
    dispatch(toggleModal(MODAL_NAME.FILL_PROFILE));
    navigate(`/settings/personal-information`);
  };
  const handleGoBack = () => {
    dispatch(toggleModal(MODAL_NAME.FILL_PROFILE));
    navigate(-1);  // Go back to the previous page
  };

  const {
    name,
    surname,
    phone,
    email,
    addresses,
    iban,
    swift,
    paymentAddress,
    paymentCountry,
    paymentCity,
    paymentZipCode,
    paymentRegion,
    accountOwner,
  } = useSelector(useLoggedInUser) || '';

  let requirements = fillRequirements[productType];

  const isPaymentSectionFilled = iban && iban !== '' &&  swift && swift !== '' &&
  paymentAddress && paymentAddress !== '' &&
  paymentCountry && paymentCountry !== '' &&
  paymentCity && paymentCity !== '' &&
  paymentZipCode && paymentZipCode !== '' &&
  paymentRegion && paymentRegion !== '' &&
  accountOwner && accountOwner !== ''

  const renderRequirements = () => {
    if (!requirements || requirements.length === 0) {
      return;
    }
    if (name && name !== '') {
      requirements = requirements.filter((x) => x !== '- Name');
    }
    if (surname && surname !== '') {
      requirements = requirements.filter((x) => x !== '- Surname');
    }
    if (phone && phone !== '') {
      requirements = requirements.filter((x) => x !== '- Phone');
    }
    if (email && email !== '') {
      requirements = requirements.filter((x) => x !== '- Email');
    }
    if (addresses && addresses.length > 0) {
      requirements = requirements.filter((x) => x !== '- At least one address');
    }
    if(isPaymentSectionFilled){
      requirements = requirements.filter((x) => x !== '- Banking information (payment section)');
    }
    return requirements.map((r, index) => <S.Requirement key={index}>{r}</S.Requirement>);
  };

  return (
    <S.ExternalWrapper>
      <S.Wrapper>
        <S.TopWrapper>
          <S.Title>
            <S.BoldTitle>Missing details</S.BoldTitle>
          </S.Title>
        </S.TopWrapper>
        <S.FormWrapper>
          <S.Label>
            Before you continue, please fill out your profile page to add a new submission
          </S.Label>
          <S.RequirementsWrapper>
            <S.RequirementsList>{renderRequirements()}</S.RequirementsList>
          </S.RequirementsWrapper>
          <S.LoginButton onClick={handleGoToProfilePage}>Go to profile page</S.LoginButton>
        </S.FormWrapper>
        <S.BottomWrapper>
          No time at the moment?
          <S.Join onClick={handleGoBack}>Cancel and come back later</S.Join>
        </S.BottomWrapper>
      </S.Wrapper>
    </S.ExternalWrapper>
  );
};

export default memo(FillProfile);
