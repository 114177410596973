import React, { memo } from 'react';
import Avatar from '../../../../images/avatar.svg'
import * as S from './TeamSection.styles';

const ProTeamData = [
  {
    name: 'Piotr',
    position: 'Founder and CEO',
    src: ""
  },
  {
    name: 'Sebastian',
    position: 'UX/UI designer',
    src: '',
  },
  {
    name: 'Oktawian',
    position: 'CTO',
    src: '',
  },
];
const TeamData = [
  {
    name: 'Ramzes',
    position: 'Senior developer',
    src: '',
  },
  {
    name: 'Łukasz',
    position: 'Senior developer',
    src: '',
  },
  {
    name: 'Dawid',
    position: 'QA specialist',
    src: '',
  },
  {
    name: 'Dawid',
    position: 'QA specialist',
    src: '',
  }
];

const getImportantPeople =  
  ProTeamData.map((p, index) => {
    return (
      <S.PersonWrapper key={index}>
        <S.ImportantPeopleImageContainer>
          <S.ImportantPeopleImage src={p.src ? p.src : `${Avatar}`} />
        </S.ImportantPeopleImageContainer>
        <S.PersonName>{p.name}</S.PersonName>
        <S.PersonRole>{p.position}</S.PersonRole>
      </S.PersonWrapper>
    );
  });

  const getPeople =  
  TeamData.map((p, index) => {
    return (
      <S.PersonWrapper key={index}>
        <S.LessImportantPeopleImageContainer>
          <S.ImportantPeopleImage src={p.src ? p.src : `${Avatar}`} />
        </S.LessImportantPeopleImageContainer>
        <S.PersonName>{p.name}</S.PersonName>
        <S.PersonRole>{p.position}</S.PersonRole>
      </S.PersonWrapper>
    );
  });


const TeamSection = () => {
  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <S.Header>Meet our team</S.Header>
      </S.HeaderWrapper>
      <S.PeopleWrapper>
        <S.ImportantPplWrapper>{getImportantPeople}</S.ImportantPplWrapper>
        <S.LessImportantPplWrapper>{getPeople}</S.LessImportantPplWrapper>
      </S.PeopleWrapper>
    </S.Wrapper>
  );
};

export default memo(TeamSection);
