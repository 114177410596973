import React, { memo } from 'react';
import { getSizeIcon, generateLink } from '../../../../helpers/functions';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { PRODUCT_TYPE } from '../../../../utils/productType';
import { getProductPrice } from '../../../../utils/getProductPrice';
import { PrintRequest } from '../../../../redux/reducers/printRequest/models';
import { DesignForSell } from '../../../../redux/reducers/designForSell/models';
import { ObjectForSell } from '../../../../redux/reducers/objectForSell/models';
import { DesignRequest } from '../../../../redux/reducers/designRequest/models';
import FormatPrice from '../../../Common/FormatPrice/FormatPrice';
import * as S from './Result.style';
export interface Image {
  id: string;
  src: string;
}

const Result = ({
  productType,
  request,
}: {
  productType: PRODUCT_TYPE;
  request: PrintRequest | DesignForSell | ObjectForSell | DesignRequest;
}) => {
  const {
    id,
    title,
    deadline,
    size,
    images,
    user: { name, avatar, nickname },
  } = request;
  const now = deadline ? moment(deadline).startOf('day') : '';
  const deadlineDays = now ? now.diff(moment().startOf('day'), 'days') : 0;
  const isExpired = deadlineDays < 0;
  const dayDaysText = deadlineDays === 1 ? 'day' : 'days';
  const deadlineText = isExpired
    ? 'Expired'
    : ` ${deadlineDays && deadlineDays >= 1 ? `${deadlineDays} ${dayDaysText}` : 'today'}`;
  const { price, symbol, quantity, currency } = getProductPrice(productType, request);
  const emptyThumbnail = { src: '' };
  const thumbnail =
    images && images.length > 0
      ? images.find((image: any) => image.order === 0) || emptyThumbnail
      : emptyThumbnail;

  const displayedName = nickname ? nickname : name;
  return (
    <Link to={generateLink(productType, title, id)}>
      <S.ResultWrapper>
        <S.ImageWrapper photo={thumbnail.src} />
        <S.BodyWrapper>
          <S.Title>{title}</S.Title>
          <S.DetailsWrapper>
            <S.Price>
              <FormatPrice price={price} productCurrency={{ symbol, currency }} />
            </S.Price>
            {quantity && (
              <S.CopesQuantity>
                for <b>{quantity} copies</b>
              </S.CopesQuantity>
            )}
            {size && getSizeIcon(size.symbol)}
          </S.DetailsWrapper>
          {deadlineDays > 0 && <S.DaysLeft>{deadlineText}</S.DaysLeft>}
          <S.BottomPanel>
            <S.UserWrapper>
              {avatar ? <S.Avatar src={avatar} /> : <S.Person />}
              <S.Author>{displayedName}</S.Author>
            </S.UserWrapper>
            {/* <S.Offers>{offers && `${offers.length} print offers`}</S.Offers> */}
          </S.BottomPanel>
        </S.BodyWrapper>
      </S.ResultWrapper>
    </Link>
  );
};

export default memo(Result);
