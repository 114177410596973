import React, { memo, useState } from 'react';
import * as S from './ContactSection.styles';

const InfoSectionData = [
  {
    title: 'Feedback',
    body: 'Send us your opinion about the app.',
  },
  {
    title: 'Partnership',
    body:
      'If you think we can collaborate, you are most likely right! Send us your inquiry today. We will find the way to work together.',
  },
  {
    title: 'Development',
    body:
      'We want to follow our users’ needs and continue developing our app. Let us know what improvements you would like to see.',
  },
];

const InfoSection = () =>
  InfoSectionData.map((i, index) => {
    return (
      <S.SimpleInfoSectionContainer key={index}>
        <S.InfoSectionTitle>{i.title}</S.InfoSectionTitle>
        <S.InfoSectionBody>{i.body}</S.InfoSectionBody>
      </S.SimpleInfoSectionContainer>
    );
  });

const handleContact = () => console.log('test');
const FormSection = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const isButtonDisabled = !name || !email || !subject || !message;
  return (
    <S.FormWrapper
      onSubmit={(e: any) => {
        e.preventDefault();
        handleContact();
      }}
    >
      <S.InputContainer>
        <S.StyledLabel>Name</S.StyledLabel>
        <S.StyledInput
          name="name"
          placeholder="Name"
          value={name}
          onChange={(e: any) => setName(e.target.value)}
        />
      </S.InputContainer>
      <S.InputContainer>
        <S.StyledLabel>Email</S.StyledLabel>
        <S.StyledInput
          name="email"
          placeholder="Email"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
        />
      </S.InputContainer>
      <S.InputContainer>
        <S.StyledLabel>Subject</S.StyledLabel>
        <S.StyledInput
          name="subject"
          placeholder="Subject"
          value={subject}
          onChange={(e: any) => setSubject(e.target.value)}
        />
      </S.InputContainer>
      <S.InputContainer isTextArea>
        <S.StyledLabel>Message</S.StyledLabel>
        <S.StyledTextArea
          name="message"
          placeholder="Message"
          value={message}
          onChange={(e: any) => setMessage(e.target.value)}
          rows={8}
        />
      </S.InputContainer>
      <S.ButtonWrapper>
        <S.StyledButton
          disabled={isButtonDisabled}
          isButtonDisabled={isButtonDisabled}
          type="submit"
        >
          Send message
        </S.StyledButton>
      </S.ButtonWrapper>
      <S.PolicyContainer>
        By clicking submit you agree to our&nbsp;
        <S.StyledAnchor href={'https://www.i-3d.io/help-center/legal/tos'}>
          Terms of Service
        </S.StyledAnchor>
        &nbsp;and&nbsp;
        <S.StyledAnchor href={'https://www.i-3d.io/help-center/legal/privacy'}>
          Privacy Policy
        </S.StyledAnchor>
        .
      </S.PolicyContainer>
    </S.FormWrapper>
  );
};

const ContactSection = () => {
  return (
    <S.Wrapper>
      <S.Title>Contact us</S.Title>
      <S.SectionsWrapper>
        <S.FormSection>{FormSection()}</S.FormSection>
        <S.InfoSection>{InfoSection()}</S.InfoSection>
      </S.SectionsWrapper>
    </S.Wrapper>
  );
};

export default memo(ContactSection);
