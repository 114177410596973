import * as api from '../../../api/common-api';
import { SimpleSearchPayload, AdvancedSearchPayload } from '../../../models/search';
import {
  GET_SIMPLE_SEARCH_ITEMS,
  GET_SIMPLE_SEARCH_ITEMS_FAILURE,
  GET_SIMPLE_SEARCH_ITEMS_SUCCESS,
  GET_ADVANCED_SEARCH_PRINT_REQUESTS,
  GET_ADVANCED_SEARCH_PRINT_REQUESTS_SUCCESS,
  GET_ADVANCED_SEARCH_PRINT_REQUESTS_FAILURE,
  GET_ADVANCED_SEARCH_DESIGN_REQUESTS,
  GET_ADVANCED_SEARCH_DESIGN_REQUESTS_SUCCESS,
  GET_ADVANCED_SEARCH_DESIGN_REQUESTS_FAILURE,
  GET_ADVANCED_SEARCH_DESIGNS_FOR_SELL,
  GET_ADVANCED_SEARCH_DESIGNS_FOR_SELL_SUCCESS,
  GET_ADVANCED_SEARCH_DESIGNS_FOR_SELL_FAILURE,
  GET_ADVANCED_SEARCH_OBJECTS_FOR_SELL,
  GET_ADVANCED_SEARCH_OBJECTS_FOR_SELL_SUCCESS,
  GET_ADVANCED_SEARCH_OBJECTS_FOR_SELL_FAILURE,
} from './actionTypes';
import { PRODUCT_TYPE } from '../../../utils/productType';

export const ADVANCED_SEARCH_TYPES = {
  [PRODUCT_TYPE.PRINT_REQUEST]: {
    start: GET_ADVANCED_SEARCH_PRINT_REQUESTS,
    success: GET_ADVANCED_SEARCH_PRINT_REQUESTS_SUCCESS,
    fail: GET_ADVANCED_SEARCH_PRINT_REQUESTS_FAILURE,
  },
  [PRODUCT_TYPE.DESIGN_REQUEST]: {
    start: GET_ADVANCED_SEARCH_DESIGN_REQUESTS,
    success: GET_ADVANCED_SEARCH_DESIGN_REQUESTS_SUCCESS,
    fail: GET_ADVANCED_SEARCH_DESIGN_REQUESTS_FAILURE,
  },
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: {
    start: GET_ADVANCED_SEARCH_DESIGNS_FOR_SELL,
    success: GET_ADVANCED_SEARCH_DESIGNS_FOR_SELL_SUCCESS,
    fail: GET_ADVANCED_SEARCH_DESIGNS_FOR_SELL_FAILURE,
  },
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: {
    start: GET_ADVANCED_SEARCH_OBJECTS_FOR_SELL,
    success: GET_ADVANCED_SEARCH_OBJECTS_FOR_SELL_SUCCESS,
    fail: GET_ADVANCED_SEARCH_OBJECTS_FOR_SELL_FAILURE,
  },
};

export function getShortItems(payload: SimpleSearchPayload) {
  return function (dispatch: any) {
    dispatch({ type: GET_SIMPLE_SEARCH_ITEMS });
    api.getSimpleSearchResults(payload).then(
      (response: any) => {
        dispatch({ type: GET_SIMPLE_SEARCH_ITEMS_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: GET_SIMPLE_SEARCH_ITEMS_FAILURE });
        console.log('Simple Search getting error', error);
      }
    );
  };
}

export function getAdvancedSearch(payload: AdvancedSearchPayload) {
  const { productType } = payload;
  const { start, success, fail } = ADVANCED_SEARCH_TYPES[productType];
  return function (dispatch: any) {
    dispatch({ type: start });
    api.getAdvancedSearch(payload).then(
      (response: any) => dispatch({ type: success, data: response }),
      (error: any) => {
        dispatch({ type: fail });
        console.log('An error occurred.', error);
      }
    );
  };
}
