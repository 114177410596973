import { MODAL_NAME } from '../redux/reducers/utils/models';

enum USER_DASHBOARD_SECTION {
  PRINTING_REQUESTS,
  OFFER_DETAILS,
  MY_SELLING_OBJECTS,
  DESIGN_REQUESTS,
  PRINTING_OFFERS,
  PRINTING_REQUEST_DETAILS,
  OBJECT_FOR_SELL_DETAILS,
  DESIGN_OFFERS,
  MY_SHOPPING_OBJECTS,
  MY_PRINTERS,
  MY_SELLING_DESIGNS,
  MY_SHOPPING_DESIGNS,
  DESIGN_OFFER_DETAILS,
  DESIGN_REQUEST_DETAILS,
  DESIGN_FOR_SELL_DETAILS,
}

export enum PRODUCT_TYPE {
  PRINT_REQUEST = 'PRINT_REQUEST',
  OBJECT_FOR_SELL = 'OBJECT_FOR_SELL',
  DESIGN_REQUEST = 'DESIGN_REQUEST',
  DESIGN_FOR_SELL = 'DESIGN_FOR_SELL',
}

export const productTypeName = {
  [PRODUCT_TYPE.PRINT_REQUEST]: 'printing requests',
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: 'objects for sale',
  [PRODUCT_TYPE.DESIGN_REQUEST]: 'design requests',
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: 'design for sale',
};

export const productTypeLink = {
  [PRODUCT_TYPE.PRINT_REQUEST]: 'print-request',
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: 'object-for-sell',
  [PRODUCT_TYPE.DESIGN_REQUEST]: 'design-request',
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: 'design-for-sell',
};

export const userDashboardSectionPath = {
  [PRODUCT_TYPE.PRINT_REQUEST]: `/dashboard/printing-requests`,
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: `/dashboard/my-selling-objects`,
  [PRODUCT_TYPE.DESIGN_REQUEST]: `/dashboard/design-requests`,
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: `/dashboard/my-selling-designs`,
};

export const userDashboardSectionName = {
  [PRODUCT_TYPE.PRINT_REQUEST]: `print requests`,
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: `objects for sale`,
  [PRODUCT_TYPE.DESIGN_REQUEST]: `design requests`,
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: `designs for sale`,
};

export const productDetailsPath = {
  [PRODUCT_TYPE.PRINT_REQUEST]: '/print-request',
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: '/object-for-sell',
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: '/design-for-sell',
  [PRODUCT_TYPE.DESIGN_REQUEST]: '/design-request',
};

export const shopingOrOffersDashboardpath = {
  [PRODUCT_TYPE.PRINT_REQUEST]: '/dashboard/my-printing-offer/',
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: '/dashboard/my-shopping-objects/',
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: '/dashboard/my-shopping-designs/',
  [PRODUCT_TYPE.DESIGN_REQUEST]: '/dashboard/my-design-offer/',
};

export const BuyOrOfferButtonText = {
  [PRODUCT_TYPE.PRINT_REQUEST]: 'Offer printing',
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: 'Buy it now',
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: 'Buy it now',
  [PRODUCT_TYPE.DESIGN_REQUEST]: 'Offer designing',
};

export const finishedOfferingOrBuyingText = {
  [PRODUCT_TYPE.PRINT_REQUEST]: 'offered printing',
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: 'bought this object',
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: 'bought this design',
  [PRODUCT_TYPE.DESIGN_REQUEST]: 'offered designing',
};

export const productDashboardDetailsPath = {
  [PRODUCT_TYPE.PRINT_REQUEST]: '/dashboard/print-request/',
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: '/dashboard/object-for-sell/',
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: '/dashboard/design-for-sell/',
  [PRODUCT_TYPE.DESIGN_REQUEST]: '/dashboard/design-request/',
};

export const isProductForPurchase = {
  [PRODUCT_TYPE.PRINT_REQUEST]: false,
  [PRODUCT_TYPE.DESIGN_REQUEST]: false,
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: true,
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: true,
};

export const createProductpath = {
  [PRODUCT_TYPE.PRINT_REQUEST]: '/print-request/new',
  [PRODUCT_TYPE.DESIGN_REQUEST]: '/design-request/new',
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: '/design/new',
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: '/object/new',
};

export const singularProductText = {
  [PRODUCT_TYPE.PRINT_REQUEST]: 'printing request',
  [PRODUCT_TYPE.DESIGN_REQUEST]: 'design request',
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: 'design for sale',
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: 'object for sale',
  [USER_DASHBOARD_SECTION.MY_SHOPPING_OBJECTS]: 'My shopping',
};

export const pluralProductText = {
  [PRODUCT_TYPE.PRINT_REQUEST]: 'Printing requests',
  [PRODUCT_TYPE.DESIGN_REQUEST]: 'Design requests',
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: 'Designs for sale',
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: 'Objects for sale',
  [USER_DASHBOARD_SECTION.MY_SHOPPING_OBJECTS]: 'My shopping',
};

export const productTitleWithBold = {
  [PRODUCT_TYPE.PRINT_REQUEST]: '',
  [PRODUCT_TYPE.DESIGN_REQUEST]: '/design-request/new',
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: '/object/new',
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: '/design/new',
};

export const productDeadlineInfoText = {
  [PRODUCT_TYPE.PRINT_REQUEST]: 'Deadline',
  [PRODUCT_TYPE.DESIGN_REQUEST]: 'Deadline',
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: '',
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: 'Available for',
};

export const isProductWithLocation = {
  [PRODUCT_TYPE.PRINT_REQUEST]: true,
  [PRODUCT_TYPE.DESIGN_REQUEST]: false,
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: false,
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: true,
};

export const deliveryOrUploadSectionTitle = {
  [PRODUCT_TYPE.PRINT_REQUEST]: 'Design and delivery',
  [PRODUCT_TYPE.DESIGN_REQUEST]: 'Upload Files',
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: 'Upload Files',
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: 'Design and delivery',
};

export const deliveryOrUploadSectionDescription = {
  [PRODUCT_TYPE.PRINT_REQUEST]:
    'Provide files and information necessary \nto print and deliver your objects.',
  [PRODUCT_TYPE.DESIGN_REQUEST]:
    'Provide design files and information \nnecessary to print and deliver your objects.',
  [PRODUCT_TYPE.DESIGN_FOR_SELL]:
    'Provide design files and information \nnecessary to print and deliver your objects.',
  [PRODUCT_TYPE.OBJECT_FOR_SELL]:
    'Provide files and information necessary \nto print and deliver your objects.',
};

export const offersAmountText = {
  [PRODUCT_TYPE.PRINT_REQUEST]: 'print offerers',
  [PRODUCT_TYPE.DESIGN_REQUEST]: 'design offerers',
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: '',
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: '',
};

export const productMoreActionModalName = {
  [PRODUCT_TYPE.PRINT_REQUEST]: MODAL_NAME.PR_MORE_ACTIONS,
  [PRODUCT_TYPE.DESIGN_REQUEST]: MODAL_NAME.DR_MORE_ACTIONS,
  [PRODUCT_TYPE.OBJECT_FOR_SELL]: MODAL_NAME.OFS_MORE_ACTIONS,
  [PRODUCT_TYPE.DESIGN_FOR_SELL]: MODAL_NAME.DFS_MORE_ACTIONS,
};
