import React, { memo } from 'react';
import { PRODUCT_STATUS } from '../../../../models/common';
import {
  BuyOrOfferButtonText,
  finishedOfferingOrBuyingText,
  offersAmountText,
  productDetailsPath,
  PRODUCT_TYPE,
  shopingOrOffersDashboardpath,
} from '../../../../utils/productType';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLoggedInUser } from '../../../../redux/reducers/auth/selectors';
import { toggleModal } from '../../../../redux/reducers/utils/action';
import { MODAL_NAME } from '../../../../redux/reducers/utils/models';
import { getIsFinalized } from '../../../../utils/status';
import * as S from '../ActionButton.style';

interface IOfferableProductDetailsOffererActions {
  id: string;
  productType: PRODUCT_TYPE;
  productStatus: PRODUCT_STATUS;
  hasPlacedOffer: boolean;
  offersAmmount: number
}

const OfferableProductDetailsOffererActions = ({
  id,
  productType,
  productStatus,
  hasPlacedOffer,
  offersAmmount
}: IOfferableProductDetailsOffererActions) => {
   const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: userId } = useSelector(useLoggedInUser);
  const isFinalized = getIsFinalized(productStatus);
  const offerPath = `${productDetailsPath[productType]}/${id}/offer`;

  const handleProductAction = () => {
    if (!userId) {
      dispatch(toggleModal(MODAL_NAME.LOGIN));
      return;
    }
    return navigate(offerPath);
  };

if (!hasPlacedOffer) {
      return (
        <S.Wrapper>
          <S.OffersAmount>
            {offersAmmount} {offersAmountText[productType]}
          </S.OffersAmount>
          <S.OfferPrintingButton disabled={isFinalized} onClick={handleProductAction}>
            {isFinalized ? 'Printing finalized' : BuyOrOfferButtonText[productType]}
          </S.OfferPrintingButton>
        </S.Wrapper>
      );
    }
    return (
      <S.PrintingOfferedWrapper>
        <S.CheckTag />
        <S.PrintingOfferedText>
          You have {finishedOfferingOrBuyingText[productType]} –
          <S.Underline
            onClick={() => navigate(`${shopingOrOffersDashboardpath[productType]}${id}`)}
          >
            Show offer details
          </S.Underline>
        </S.PrintingOfferedText>
      </S.PrintingOfferedWrapper>
    );
};

export default memo(OfferableProductDetailsOffererActions);
