import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../../redux/reducers/utils/action';
import { MODAL_NAME } from '../../../redux/reducers/utils/models';
import { useLicenseDescription } from '../../../redux/reducers/utils/selectors';
import * as S from './LicenseDetailsModal.styles';

const LicenseDetailsModal = () => {
  const dispatch = useDispatch();
  const description = useSelector(useLicenseDescription);
  const handleClose = () => dispatch(toggleModal(MODAL_NAME.LICENSE_DETAILS));
  const licensesPrivacyLink = `https://www.i-3d.io/help-center/legal/privacy`;
  return (
    <S.Wrapper>
      <S.TopWrapper>
        <S.Title>Show license details</S.Title>
        <S.Close onClick={handleClose} />
      </S.TopWrapper>
      <S.BodyWrapper>{description}</S.BodyWrapper>
      <S.LinkWrapper>
        More information about licenses:{' '}
        <S.LicensePrivacyLink href={licensesPrivacyLink}>
          {licensesPrivacyLink}
        </S.LicensePrivacyLink>
      </S.LinkWrapper>
    </S.Wrapper>
  );
};

export default memo(LicenseDetailsModal);
