import styled from 'styled-components';
import themes from '../../Assets/colorThemes';

export const ExternalWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 97vh;
  background-color: ${themes.default.white};
`;

export const Wrapper = styled.div`
  height: 100%;
  padding: 84px 20px;
  max-width: 668px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto 0 0;
  @media (max-width: 800px) {
    padding: 32px 16px;
    margin: 0 auto;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  padding-bottom: 16px;
`;

export const Header = styled.div`
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  color: black;
`;

export const LastUpdateContainer = styled.div`
  display: flex;
  padding-bottom: 18px;
  flex-direction: column;
`;

export const LastUpdate = styled.div`
  font-family: Inter;
  font-size: 14px;
  padding-bottom: 18px;
`;

export const BoldedDate = styled.span`
  font-weight: 600;
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const StyledButton = styled.button`
  margin-right: 6px;
  font-family: Inter;
  font-size: 14px;
  border: none;
  background-color: #0077df;
  color: white;
  border-radius: 4px;
  cursor: pointer;
`;