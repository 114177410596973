import React from 'react';
import { useDispatch } from 'react-redux';
import { loginWithFacebook } from '../../../redux/reducers/auth/actions';
import { IoLogoFacebook } from 'react-icons/io';
import * as S from './Login.style';

const ContinueWithFacebookButton = () => {
  const dispatch = useDispatch();
  const handleLoginWithFacebook = async () => {
    await (window as any).FB.login(
      (response: any) => {
        dispatch(loginWithFacebook(response));
      },
      {
        scope: 'email',
      }
    );
  };
  return (
    <S.FacebookButton onClick={() => handleLoginWithFacebook()}>
      <IoLogoFacebook style={S.iconLarge} />
      Log in with Facebook
    </S.FacebookButton>
  );
};

export default ContinueWithFacebookButton;
