import React from 'react';
import img404 from '../../images/404.svg';
import * as S from './Page404.styles';

export default () => (
  <S.Wrapper>
    <S.BodyWrapper>
      <S.Header>
        Oops, <S.B>the page was not found…</S.B>
      </S.Header>
      <S.Description>
        You may have mistyped the URL
        <br /> or the page was removed.
      </S.Description>
      <S.StyledLink to="/">Let’s go the the home page</S.StyledLink>
    </S.BodyWrapper>
    {/* <S.ImageWrapper> */}
    <S.Image src={img404} alt="page 404" />
    {/* </S.ImageWrapper> */}
  </S.Wrapper>
);
