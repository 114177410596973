import React, { memo } from 'react';
import ContactSection from './Sections/ContactSection/ContactSection';
import HeaderSection from './Sections/HeaderSection/HeaderSection';
import PartnersSection from './Sections/PartnersSection/PartnersSection';
import TeamSection from './Sections/TeamSection/TeamSection';
import WhatIsSection from './Sections/WhatIsSection/WhatIsSection';
import * as S from './AboutUs.styles'

const AboutUsPage = () => {
  return (
    <S.Wrapper>
      <HeaderSection/>
      <WhatIsSection/>
      <TeamSection/>
      <PartnersSection/>
      <ContactSection />
    </S.Wrapper>
  );
};

export default memo(AboutUsPage);
