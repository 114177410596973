import { AppState } from '..';
import { EmptyDesignForSell } from './models';

export const purchaseState = (state: AppState) => state.designForSell;

export const isLoadingUserDesignsForSell = (state: AppState) =>
  purchaseState(state).isLoadingUserDesignsForSell;

export const isLoadingFeaturedDesignsForSell = (state: AppState) =>
  purchaseState(state).isLoadingFeaturedDesignsForSell;

export const isLoadingViewedDesignForSell = (state: AppState) =>
  purchaseState(state).isLoadingViewedDesigForSell;

export const usersDesingsForSell = (state: AppState) => purchaseState(state).userDesignsForSell;

export const featuredDesignsForSell = (state: AppState) =>
  purchaseState(state).featuredDesignsForSell;

export const viewedDesignForSell = (state: AppState) =>
  purchaseState(state).viewedDesignForSell || EmptyDesignForSell;

export const isSubmitting = (state: AppState) => purchaseState(state).isSubmitting;

export const errorMessage = (state: AppState) => purchaseState(state).errorMessage;

export const useIsAddingFreeDFS = (state: AppState) => purchaseState(state).isAddingFreeDFS;
