import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { allExchangeRates } from '../../../redux/reducers/utils/selectors';
import { loggedUser } from '../../../redux/reducers/auth/selectors';
import * as S from './FormatPrice.styles';
import { formatPrice } from '../../../helpers/functions';

interface FormatCurrency {
  currency: string;
  symbol: string;
}

interface FormatPriceProps {
  price: number;
  productCurrency: FormatCurrency;
}

const FormatPrice = ({ price, productCurrency }: FormatPriceProps) => {
  const rates = useSelector(allExchangeRates);
  const { currency } = useSelector(loggedUser);
  const userCurrency = currency ? currency.currency : '';
  const outputValue = formatPrice(price, productCurrency, userCurrency, rates);
  return <S.Wrapper>{outputValue}</S.Wrapper>;
};

export default memo(FormatPrice);
