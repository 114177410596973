import { AnyAction } from 'redux';
import {
  GET_SIMPLE_SEARCH_ITEMS,
  GET_SIMPLE_SEARCH_ITEMS_FAILURE,
  GET_SIMPLE_SEARCH_ITEMS_SUCCESS,
  GET_ADVANCED_SEARCH_DESIGN_REQUESTS,
  GET_ADVANCED_SEARCH_DESIGN_REQUESTS_SUCCESS,
  GET_ADVANCED_SEARCH_PRINT_REQUESTS,
  GET_ADVANCED_SEARCH_DESIGN_REQUESTS_FAILURE,
  GET_ADVANCED_SEARCH_PRINT_REQUESTS_SUCCESS,
  GET_ADVANCED_SEARCH_PRINT_REQUESTS_FAILURE,
  GET_ADVANCED_SEARCH_DESIGNS_FOR_SELL,
  GET_ADVANCED_SEARCH_DESIGNS_FOR_SELL_SUCCESS,
  GET_ADVANCED_SEARCH_DESIGNS_FOR_SELL_FAILURE,
  GET_ADVANCED_SEARCH_OBJECTS_FOR_SELL,
  GET_ADVANCED_SEARCH_OBJECTS_FOR_SELL_SUCCESS,
  GET_ADVANCED_SEARCH_OBJECTS_FOR_SELL_FAILURE,
} from './actionTypes';

export interface SearchState {
  shortItems: {
    designsForSell: any[];
    designRequests: any[];
    printRequests: any[];
    objectsForSell: any[];
  };
  isLoadingShort: boolean;
  extendedItems: {
    designsForSell: any[];
    designRequests: any[];
    printRequests: any[];
    objectsForSell: any[];
  };
  isLoadingExtended: boolean;
  lastlySearched: string;
  query: string;
  toggledSearchItems: {
    prints: boolean;
    objects: boolean;
    designs: boolean;
    designsFS: boolean;
  };
}

const initialState: SearchState = {
  shortItems: {
    designRequests: [],
    designsForSell: [],
    printRequests: [],
    objectsForSell: [],
  },
  isLoadingShort: false,
  extendedItems: {
    designRequests: [],
    designsForSell: [],
    printRequests: [],
    objectsForSell: [],
  },
  isLoadingExtended: false,
  toggledSearchItems: {
    prints: true,
    objects: true,
    designs: true,
    designsFS: true,
  },
  lastlySearched: '',
  query: '',
};

export default function (state = initialState, action: AnyAction): SearchState {
  switch (action.type) {
    case GET_SIMPLE_SEARCH_ITEMS: {
      return {
        ...state,
        isLoadingShort: true,
      };
    }
    case GET_SIMPLE_SEARCH_ITEMS_SUCCESS: {
      return {
        ...state,
        isLoadingShort: false,
        shortItems: { ...state.shortItems, ...action.data },
      };
    }
    case GET_SIMPLE_SEARCH_ITEMS_FAILURE: {
      return {
        ...state,
        isLoadingShort: false,
      };
    }
    case GET_ADVANCED_SEARCH_DESIGN_REQUESTS: {
      return {
        ...state,
        isLoadingExtended: true,
      };
    }
    case GET_ADVANCED_SEARCH_DESIGN_REQUESTS_SUCCESS: {
      const { extendedItems } = state;
      return {
        ...state,
        isLoadingExtended: false,
        extendedItems: { ...extendedItems, designRequests: action.data },
      };
    }
    case GET_ADVANCED_SEARCH_DESIGN_REQUESTS_FAILURE: {
      return {
        ...state,
        isLoadingExtended: false,
      };
    }
    case GET_ADVANCED_SEARCH_PRINT_REQUESTS: {
      return {
        ...state,
        isLoadingExtended: true,
      };
    }
    case GET_ADVANCED_SEARCH_PRINT_REQUESTS_SUCCESS: {
      const { extendedItems } = state;
      return {
        ...state,
        isLoadingExtended: false,
        extendedItems: { ...extendedItems, printRequests: action.data },
      };
    }
    case GET_ADVANCED_SEARCH_PRINT_REQUESTS_FAILURE: {
      return {
        ...state,
        isLoadingExtended: false,
      };
    }
    case GET_ADVANCED_SEARCH_DESIGNS_FOR_SELL: {
      return {
        ...state,
        isLoadingExtended: true,
      };
    }
    case GET_ADVANCED_SEARCH_DESIGNS_FOR_SELL_SUCCESS: {
      const { extendedItems } = state;
      return {
        ...state,
        isLoadingExtended: false,
        extendedItems: { ...extendedItems, designsForSell: action.data },
      };
    }
    case GET_ADVANCED_SEARCH_DESIGNS_FOR_SELL_FAILURE: {
      return {
        ...state,
        isLoadingExtended: false,
      };
    }
    case GET_ADVANCED_SEARCH_OBJECTS_FOR_SELL: {
      return {
        ...state,
        isLoadingExtended: true,
      };
    }
    case GET_ADVANCED_SEARCH_OBJECTS_FOR_SELL_SUCCESS: {
      const { extendedItems } = state;
      return {
        ...state,
        isLoadingExtended: false,
        extendedItems: { ...extendedItems, objectsForSell: action.data },
      };
    }
    case GET_ADVANCED_SEARCH_OBJECTS_FOR_SELL_FAILURE: {
      return {
        ...state,
        isLoadingExtended: false,
      };
    }
    default:
      return state;
  }
}
