import { PRODUCT_TYPE } from '../../utils/productType';

export enum ITEM_TYPE {
  PRINTS = 'prints',
  OBJECTS = 'objects',
  DESIGNS = 'designs',
  ALL = 'all',
}

export interface ModalData {
  title: string;
  isActive: boolean;
  persistModalOnRedirect?: boolean;
  mergeInitialState?: boolean;
  elementId?: string;
  productType?: PRODUCT_TYPE;
  galleryId?: string;
}

export interface UserCredentials {
  password: string;
  email: string;
}

export interface IAccountConfirmationParams {
  token: string;
}

export interface UserData extends UserCredentials {
  name: string;
  surname: string;
}
