import { combineReducers } from 'redux';
import searchOld, { SearchOldState } from './search';
import auth, { AuthState } from './auth/auth';
import userProfiles, { UserProfilesState } from './userProfiles';
import designRequests, { DesignRequestState } from './design-requests';
import designsForSell, { DesignForSellState } from './design-for-sell';
import oldObjectsForSell, { oldObjectForSellState } from './object-for-sell';
import user, { UserState } from './user';
import admin, { AdminState } from './admin';
import advSearch, { AdvancedSearchState } from './advanced-search';
import purchase, { PurchaseState } from './purchase/purchase';
import designRequest, { DesignRequestsState } from './designRequest/designRequest';
import designForSell, { DesignsForSellState } from './designForSell/designForSell';
import objectForSell, { ObjectsForSellState } from './objectForSell/objectForSell';
import printRequest, { PrintRequestsState } from './printRequest/printRequest';
import utils, { UtilsState } from './utils/utils';
import search, { SearchState } from './search/search';
import offer, { OffersState } from './offer/offer';

export default combineReducers({
  searchOld,
  auth,
  user,
  admin,
  userProfiles,
  designRequests,
  designsForSell,
  oldObjectsForSell,
  advSearch,
  purchase,
  designRequest,
  designForSell,
  objectForSell,
  printRequest,
  utils,
  search,
  offer,
});

export type AppState = {
  searchOld: SearchOldState;
  auth: AuthState;
  user: UserState;
  admin: AdminState;
  userProfiles: UserProfilesState;
  designRequests: DesignRequestState;
  designsForSell: DesignForSellState;
  oldObjectsForSell: oldObjectForSellState;
  advSearch: AdvancedSearchState;
  purchase: PurchaseState;
  designRequest: DesignRequestsState;
  designForSell: DesignsForSellState;
  objectForSell: ObjectsForSellState;
  printRequest: PrintRequestsState;
  utils: UtilsState;
  search: SearchState;
  offer: OffersState;
};
