import React, { memo } from 'react';
import * as S from '../FiltersPanel.style';

interface Double {
  handleFirstInput: (v: number) => void;
  handleSecondInput: (v: number) => void;
  firstValue: string;
  secondValue: string;
  unit: string | JSX.Element;
}

const DoubleInput = ({
  handleFirstInput,
  handleSecondInput,
  firstValue,
  secondValue,
  unit,
}: Double) => (
  <S.LineBlock>
    <S.SmallInput
      type="number"
      value={firstValue}
      onChange={(e: any) => handleFirstInput(e.target.value)}
    />
    <S.Span>–</S.Span>
    <S.SmallInput
      type="number"
      value={secondValue}
      onChange={(e: any) => handleSecondInput(e.target.value)}
    />
    <S.Span>{unit}</S.Span>
  </S.LineBlock>
);

export default memo(DoubleInput);
