import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import U from '../Assets/commonCSS';
import * as S from './ModalWrapper.styles';
import { MODAL_NAME } from '../../redux/reducers/utils/models';
import { isModalOpen } from '../../redux/reducers/utils/selectors';
import { clearAllModalsStates } from '../../redux/reducers/utils/action';

interface ModalWrapperProps {
  title: MODAL_NAME;
  child: void | JSX.Element | null;
}

export default ({ title, child }: ModalWrapperProps) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(isModalOpen(title));
  const hasAcceptedCookies = useSelector((state: any) => state.utils.hasAcceptedCookies);
  const onClose = () => dispatch(clearAllModalsStates);

  if (!isOpen || (title === MODAL_NAME.COOKIES && hasAcceptedCookies)) {
    return null;
  }
  if (!child) {
    return null;
  }
  return (
    <Modal style={U.modalStyleProps(false)} open={isOpen} onBackdropClick={onClose}>
      <S.Wrapper>{child}</S.Wrapper>
    </Modal>
  );
};
