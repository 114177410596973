import React from 'react';
import Modal from '@material-ui/core/Modal';
import MapEnlarged, { MapEnlargedProps } from '../Common/MapEnlarged/MapEnlarged';
import { useSelector } from 'react-redux';
import U from '../Assets/commonCSS';
import { isModalOpen } from '../../redux/reducers/utils/selectors';
import { MODAL_NAME } from '../../redux/reducers/utils/models';

const MapModal = ({ lat, lon }: MapEnlargedProps) => {
  const isOpen = useSelector(isModalOpen(MODAL_NAME.MAP_ENLARGED));

  if (lat === 0 && lon === 0) {
    return <></>;
  }

  return (
    <Modal style={U.modalStyleProps()} open={isOpen}>
      <MapEnlarged {...{ lat, lon }} />
    </Modal>
  );
};

export default MapModal;
