import React, { memo, useState } from 'react';
import UsersPanel from './Panels/UsersPanel';
import PageWithMenuWrapper from '../Common/PageWithMenuWrapper/PageWithMenuWrapper';
import Menu from './Menu/Menu';
import * as S from './AdminBoard.style';

export enum ADMIN_BOARD {
  USERS = 'USERS',
  OTHER = 'OTHER',
}

interface AdminBoardProps {
  selectedSection?: ADMIN_BOARD;
}

const AdminBoard = ({ selectedSection }: AdminBoardProps) => {
  const [selected, setSelected] = useState<ADMIN_BOARD>(
    selectedSection ? selectedSection : ADMIN_BOARD.USERS
  );
  return (
    <S.Wrapper>
      <PageWithMenuWrapper
        menu={<Menu selected={selected} setSelected={setSelected} />}
        body={
          <S.BodyWrapper>{selectedSection === ADMIN_BOARD.USERS && <UsersPanel />}</S.BodyWrapper>
        }
      />
    </S.Wrapper>
  );
};

export default memo(AdminBoard);
