import React, { memo, useState, useEffect } from 'react';
import { Option } from '../../../models/common';
import LocationInput from './Inputs/LocationInput';
import CustomCheckbox from './Inputs/CustomCheckbox';
import DoubleInput from './Inputs/DoubleInput';
import Chip, { FILTERS_TYPE, Filter, EXTREMES } from './Chip';
import { useSelector } from 'react-redux';
import { Size, EmptySize } from '../../../models/units';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { max } from 'lodash';
import { PRODUCT_TYPE } from '../../../utils/productType';
import * as S from './FiltersPanel.style';
import { allSizes } from '../../../redux/reducers/utils/selectors';

export enum SORT_BY_OPTIONS {
  PRICE_HIGHT = 'PRICE_HIGHT',
  PRICE_LOW = 'PRICE_LOW',
  DEADLINE = 'DEADLINE',
}

export const SortByDecode = {
  [SORT_BY_OPTIONS.PRICE_HIGHT]: { sortByTarget: 'price', sortByDirection: 'DESC' },
  [SORT_BY_OPTIONS.PRICE_LOW]: { sortByTarget: 'price', sortByDirection: 'ASC' },
  [SORT_BY_OPTIONS.DEADLINE]: { sortByTarget: 'deadline', sortByDirection: 'ASC' },
};

interface FiltersPanelProps {
  itemsPerPage: Option;
  sortBy: { label: string; value: SORT_BY_OPTIONS };
  page: number;
  setSearchPayload: (arg: any) => void;
  setRefresh: (value: boolean) => void;
  productType: PRODUCT_TYPE;
}

const locationOptions: Option[] = [
  { label: 'Krakow', value: 'Krakow' },
  { label: 'Gniezno', value: 'Gniezno' },
  { label: 'Poznan', value: 'Poznan' },
];

const distanceOptions: Option[] = [
  { label: '100km', value: '100km' },
  { label: '300km', value: '300km' },
  { label: '500km', value: '500km' },
];

enum SIZES {
  EXTRA_SMALL = 'extra small',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  EXTRA_LARGE = 'extra large',
}

enum LOCATION_TYPE {
  PLACE = 'place',
  DISTANCE = 'distance',
}

const FiltersPanel = ({
  sortBy,
  itemsPerPage,
  page,
  setSearchPayload,
  setRefresh,
  productType,
}: FiltersPanelProps) => {
  const [location, setLocation] = useState('');
  const [distance, setDistance] = useState('');
  const [deadline, setDeadline] = useState('');
  const [layerHeight, setLayerHeight] = useState('');
  const [budgetMin, setBudgetMin] = useState('');
  const [budgetMax, setBudgetMax] = useState('');
  const [sizeMin, setSizeMin] = useState('');
  const [sizeMax, setSizeMax] = useState('');

  const handleNumberChange = (val: any, setter: React.Dispatch<React.SetStateAction<any>>) => {
    const result = max([+val, 0]) || 0;
    setter(result.toString());
  };

  const sizes = useSelector(allSizes);

  const params: any = useParams();
  const { query } = params;

  let initialSelectedFilters: Filter[] = [];
  const deadlineFilter = { type: FILTERS_TYPE.DEADLINE, values: [] };
  const resolutionFilter = { type: FILTERS_TYPE.RESOLUTION, values: [] };
  const locationFilter = { type: FILTERS_TYPE.LOCATION, values: [], place: '', distance: '' };
  const budgetFilter = { type: FILTERS_TYPE.BUDGET, values: [], min: 0, max: 0 };
  const initialSelectedSizes: { [key: string]: boolean } = {
    [SIZES.EXTRA_SMALL]: false,
    [SIZES.SMALL]: false,
    [SIZES.MEDIUM]: false,
    [SIZES.LARGE]: false,
    [SIZES.EXTRA_LARGE]: false,
  };

  const [selectedSizes, setSelectedSizes] = useState(initialSelectedSizes);
  const [selectedFilters, setSelectedFilters] = useState(initialSelectedFilters);
  const validFilterTypes = selectedFilters.filter(
    (filter: Filter) => !(filter.type === FILTERS_TYPE.LOCATION && !filter.place)
  );

  const remove = (type: FILTERS_TYPE) => {
    switch (type) {
      case FILTERS_TYPE.BUDGET: {
        setBudgetMax('');
        setBudgetMin('');
        break;
      }
      case FILTERS_TYPE.SIZE: {
        setSelectedSizes(initialSelectedSizes);
        setSizeMin('');
        setSizeMax('');
        break;
      }
      case FILTERS_TYPE.DEADLINE: {
        setDeadline('');
        break;
      }
      case FILTERS_TYPE.RESOLUTION: {
        setLayerHeight('');
        break;
      }
    }
    setSelectedFilters(selectedFilters.filter((filter: Filter) => filter.type !== type));
  };

  const handleExtremeSizeMin = () => {
    const isSizeSelected =
      selectedSizes[SIZES.EXTRA_LARGE] &&
      selectedSizes[SIZES.LARGE] &&
      selectedSizes[SIZES.MEDIUM] &&
      selectedSizes[SIZES.SMALL] &&
      selectedSizes[SIZES.EXTRA_SMALL];
    if (!isSizeSelected) {
      setSizeMin('');
    }
    if (selectedSizes[SIZES.EXTRA_SMALL]) {
      return setSizeMin(sizes.find((size: Size) => size.symbol === 'XS')!.rangeFrom.toString());
    }
    if (selectedSizes[SIZES.SMALL]) {
      return setSizeMin(sizes.find((size: Size) => size.symbol === 'S')!.rangeFrom.toString());
    }
    if (selectedSizes[SIZES.MEDIUM]) {
      return setSizeMin(sizes.find((size: Size) => size.symbol === 'M')!.rangeFrom.toString());
    }
    if (selectedSizes[SIZES.LARGE]) {
      return setSizeMin(sizes.find((size: Size) => size.symbol === 'L')!.rangeFrom.toString());
    }
    if (selectedSizes[SIZES.EXTRA_LARGE]) {
      return setSizeMin(sizes.find((size: Size) => size.symbol === 'XL')!.rangeFrom.toString());
    }
  };

  const handleExtremeSizeMax = () => {
    const isSizeSelected =
      selectedSizes[SIZES.EXTRA_LARGE] &&
      selectedSizes[SIZES.LARGE] &&
      selectedSizes[SIZES.MEDIUM] &&
      selectedSizes[SIZES.SMALL] &&
      selectedSizes[SIZES.EXTRA_SMALL];
    if (!isSizeSelected) {
      setSizeMax('');
    }
    if (selectedSizes[SIZES.EXTRA_LARGE]) {
      return setSizeMax(sizes.find((size: Size) => size.symbol === 'XL')!.rangeTo.toString());
    }
    if (selectedSizes[SIZES.LARGE]) {
      return setSizeMax(sizes.find((size: Size) => size.symbol === 'L')!.rangeTo.toString());
    }
    if (selectedSizes[SIZES.MEDIUM]) {
      return setSizeMax(sizes.find((size: Size) => size.symbol === 'M')!.rangeTo.toString());
    }
    if (selectedSizes[SIZES.SMALL]) {
      return setSizeMax(sizes.find((size: Size) => size.symbol === 'S')!.rangeTo.toString());
    }
    if (selectedSizes[SIZES.EXTRA_SMALL]) {
      return setSizeMax(sizes.find((size: Size) => size.symbol === 'XS')!.rangeTo.toString());
    }
  };

  const updateSizesExtremes = () => {
    handleExtremeSizeMin();
    handleExtremeSizeMax();
  };

  const handleSizesUpdate = (value: number, type: 'min' | 'max') => {
    const min = type === 'min' ? value : sizeMin;
    const max = type === 'max' ? value : sizeMax;
    let newSizes: any = {};
    let sizesList: any = [];
    let currentSize: Size = EmptySize;
    for (let index = 0; index < sizes.length; index++) {
      currentSize = sizes[index];
      if (min < currentSize.rangeTo) {
        if (max > 0 && max > currentSize.rangeFrom) newSizes[currentSize.fullName] = true;
        sizesList.push(currentSize.fullName);
      }
    }

    setSelectedSizes(newSizes);
    const filters = selectedFilters.filter((filter: Filter) => filter.type !== FILTERS_TYPE.SIZE);
    let tempFilter = selectedFilters.find(
      (filter: Filter) => filter.type === FILTERS_TYPE.SIZE
    ) || {
      values: [],
      type: FILTERS_TYPE.SIZE,
    };
    tempFilter.values = sizesList;
    if (sizesList.length > 0) {
      setSelectedFilters([...filters, tempFilter]);
    } else {
      remove(FILTERS_TYPE.SIZE);
    }
  };

  const handleSizeMinChange = (value: number) => {
    const parsedValue = max([value, 0]) || 0;
    setSizeMin(parsedValue.toString());
    if (+sizeMax > 0) {
      handleSizesUpdate(parsedValue, 'min');
    }
  };

  const handleSizeMaxChange = (value: number) => {
    const parsedValue = max([value, 0]) || 0;
    setSizeMax(parsedValue.toString());
    handleSizesUpdate(parsedValue, 'max');
  };

  const toggleSelectedSize = (size: SIZES) => {
    selectedSizes[size] = !selectedSizes[size];
    let tempFilter = selectedFilters.find(
      (filter: Filter) => filter.type === FILTERS_TYPE.SIZE
    ) || {
      values: [],
      type: FILTERS_TYPE.SIZE,
    };
    tempFilter.values.push(size);
    if (!selectedSizes[size]) {
      tempFilter.values = tempFilter.values.filter(
        (tempSize: string | number) => tempSize !== size
      );
    }
    const newSelectedFilters = selectedFilters.filter(
      (filter: Filter) => filter.type !== FILTERS_TYPE.SIZE
    );
    if (tempFilter.values.length !== 0) {
      setSelectedFilters([...newSelectedFilters, tempFilter]);
    } else {
      setSelectedFilters([...newSelectedFilters]);
    }
    updateSizesExtremes();
  };

  const handleBudgetChange = (extrema: EXTREMES, value: number) => {
    const formattedValue = +value.toString();
    let selectedBudgetFilter =
      selectedFilters.find((filter: Filter) => filter.type === FILTERS_TYPE.BUDGET) || budgetFilter;
    if (extrema === EXTREMES.MIN) {
      setBudgetMin(formattedValue.toString());
      selectedBudgetFilter.min = value;
    } else {
      setBudgetMax(formattedValue.toString());
      selectedBudgetFilter.max = value;
    }
    const filters = selectedFilters.filter((filter: Filter) => filter.type !== FILTERS_TYPE.BUDGET);
    setSelectedFilters([...filters, selectedBudgetFilter]);
    if (value === 0 || !value) {
      remove(FILTERS_TYPE.BUDGET);
    }
  };

  const handleDeadlineChange = (value: number) => {
    let selectedDeadlineFilter =
      selectedFilters.find((filter: Filter) => filter.type === FILTERS_TYPE.DEADLINE) ||
      deadlineFilter;
    setDeadline(value.toString());
    selectedDeadlineFilter.values = [value];
    const filters = selectedFilters.filter(
      (filter: Filter) => filter.type !== FILTERS_TYPE.DEADLINE
    );
    setSelectedFilters([...filters, selectedDeadlineFilter]);
    if (value === 0 || !value) {
      remove(FILTERS_TYPE.DEADLINE);
    }
  };

  const handleLayerHeightChange = (value: number) => {
    let selectedResolutionFilter =
      selectedFilters.find((filter: Filter) => filter.type === FILTERS_TYPE.RESOLUTION) ||
      resolutionFilter;
    setLayerHeight(value.toString());
    selectedResolutionFilter.values = [value];
    const filters = selectedFilters.filter(
      (filter: Filter) => filter.type !== FILTERS_TYPE.RESOLUTION
    );
    setSelectedFilters([...filters, selectedResolutionFilter]);
    if (value === 0 || !value) {
      remove(FILTERS_TYPE.RESOLUTION);
    }
  };

  const handleLocationChange = (type: LOCATION_TYPE, value: string) => {
    let selectedLocationFilter =
      selectedFilters.find((filter: Filter) => filter.type === FILTERS_TYPE.LOCATION) ||
      locationFilter;
    if (type === LOCATION_TYPE.PLACE) {
      setLocation(value);
      selectedLocationFilter.place = value;
    } else {
      setDistance(value);
      selectedLocationFilter.distance = value;
    }
    const filters = selectedFilters.filter(
      (filter: Filter) => filter.type !== FILTERS_TYPE.LOCATION
    );
    setSelectedFilters([...filters, selectedLocationFilter]);
  };

  const applySearch = () => {
    const today = new Date();
    const sizeLabels = selectedFilters.find((filter: Filter) => filter.type === FILTERS_TYPE.SIZE);
    const payload = {
      query: query ? query : '',
      offset: page,
      limit: itemsPerPage ? +itemsPerPage.value : null,
      volumeMin: sizeMin ? +sizeMin : null,
      volumeMax: sizeMax ? +sizeMax : null,
      budgetMin: budgetMin ? +budgetMin : null,
      budgetMax: budgetMax ? +budgetMax : null,
      layerHeight: layerHeight ? +layerHeight : null,
      // distance: +distance,
      city: location ? location : null,
      deadline: deadline ? moment(today, 'DD-MM-YYYY').add(deadline, 'days').toString() : null,
      sizeLabels: sizeLabels ? sizeLabels.values : [],
      ...SortByDecode[sortBy.value],
      productType,
    };
    setSearchPayload(payload);
    setRefresh(true);
  };

  const resetFilters = () => {
    setLocation('');
    setDistance('');
    setDeadline('');
    setLayerHeight('');
    setBudgetMin('');
    setBudgetMax('');
    setSizeMin('');
    setSizeMax('');
    setSelectedFilters(initialSelectedFilters);
    setSelectedSizes(initialSelectedSizes);
    const payload = {
      query: query ? query : null,
      offset: null,
      limit: null,
      volumeMin: null,
      volumeMax: null,
      budgetMin: null,
      budgetMax: null,
      layerHeight: null,
      // distance: NULL,
      city: null,
      deadline: null,
      sizeLabels: [],
      ...SortByDecode[sortBy.value],
    };
    setSearchPayload(payload);
    setRefresh(true);
  };

  const notDesign =
    productType !== PRODUCT_TYPE.DESIGN_REQUEST && productType !== PRODUCT_TYPE.DESIGN_FOR_SELL;

  useEffect(() => {
    applySearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, page, itemsPerPage, sortBy]);

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <S.Title>FILTERS</S.Title>
        <S.RemoveFilters onClick={resetFilters}>Remove all filters</S.RemoveFilters>
      </S.HeaderWrapper>
      <S.MobileRow>
        {validFilterTypes.length > 0 && (
          <S.SelectedFiltersWrapper>
            {validFilterTypes.map((selectedFilter: Filter, index) => (
              <Chip key={index} {...selectedFilter} remove={() => remove(selectedFilter.type)} />
            ))}
          </S.SelectedFiltersWrapper>
        )}
        <S.FormWrapper>
          <S.Column>
            <S.Label>Budget</S.Label>
            <DoubleInput
              handleFirstInput={(v: number) =>
                handleNumberChange(v, (v: number) => handleBudgetChange(EXTREMES.MIN, v))
              }
              handleSecondInput={(v: number) =>
                handleNumberChange(v, (v: number) => handleBudgetChange(EXTREMES.MAX, v))
              }
              firstValue={budgetMin}
              secondValue={budgetMax}
              unit={`$`}
            />
          </S.Column>
          {notDesign && (
            <S.Column>
              <S.Label>Deadline</S.Label>
              <S.LineBlock>
                <S.MediumInput
                  id="deadline"
                  type="number"
                  step="1"
                  min="0"
                  value={deadline}
                  onChange={(e: any) => handleNumberChange(e.target.value, handleDeadlineChange)}
                />
                <S.Span>days</S.Span>
              </S.LineBlock>
            </S.Column>
          )}
          {notDesign && (
            <S.Column>
              <S.Label>Location</S.Label>
              <LocationInput
                handleOptionSelectLocation={(e: Option) =>
                  handleLocationChange(LOCATION_TYPE.PLACE, e.value)
                }
                handleOptionSelectDistance={(e: Option) =>
                  handleLocationChange(LOCATION_TYPE.DISTANCE, e.value)
                }
                selectedLocation={location}
                selectedDistance={distance}
                optionsLocation={locationOptions}
                optionsDistance={distanceOptions}
              />
            </S.Column>
          )}
          {/* <S.DisplayMap>Show map</S.DisplayMap> */}
          <S.Column>
            <S.Label>Size</S.Label>
            <S.SizeWrapper>
              <CustomCheckbox
                isSelected={selectedSizes[SIZES.EXTRA_SMALL]}
                toggle={() => toggleSelectedSize(SIZES.EXTRA_SMALL)}
                description={'Extra small'}
                // resultsNo={54}
              />
              <CustomCheckbox
                isSelected={selectedSizes[SIZES.SMALL]}
                toggle={() => toggleSelectedSize(SIZES.SMALL)}
                description={'Small'}
                // resultsNo={54}
              />
              <CustomCheckbox
                isSelected={selectedSizes[SIZES.MEDIUM]}
                toggle={() => toggleSelectedSize(SIZES.MEDIUM)}
                description={'Medium'}
                // resultsNo={125}
              />
              <CustomCheckbox
                isSelected={selectedSizes[SIZES.LARGE]}
                toggle={() => toggleSelectedSize(SIZES.LARGE)}
                description={'Large'}
                // resultsNo={33}
              />
              <CustomCheckbox
                isSelected={selectedSizes[SIZES.EXTRA_LARGE]}
                toggle={() => toggleSelectedSize(SIZES.EXTRA_LARGE)}
                description={'Extra large'}
                // resultsNo={25}
              />
            </S.SizeWrapper>
            <DoubleInput
              handleFirstInput={(v: number) => handleSizeMinChange(v)}
              handleSecondInput={(v: number) => handleSizeMaxChange(v)}
              firstValue={sizeMin}
              secondValue={sizeMax}
              unit={
                <span>
                  mm<sup>3</sup>
                </span>
              }
            />
          </S.Column>
          {notDesign && (
            <S.Column>
              <S.Label>Layer height</S.Label>
              <S.LineBlock>
                <S.MediumInput
                  type="number"
                  step="0.01"
                  min="0"
                  value={layerHeight}
                  onChange={(e: any) => handleNumberChange(e.target.value, handleLayerHeightChange)}
                />
                <S.Span>mm</S.Span>
              </S.LineBlock>
            </S.Column>
          )}
        </S.FormWrapper>
      </S.MobileRow>
      <S.SubmitButton onClick={applySearch}>Submit</S.SubmitButton>
      <S.Separator />
      <S.MoreFilters>More filters</S.MoreFilters>
    </S.Wrapper>
  );
};

export default memo(FiltersPanel);
