import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  font-family: Inter;
  width: 100%;
  max-width: 1296px;
  margin: 0 auto;
  padding: 0 32px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
`;

export const Header = styled.h3`
  margin: 0;
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 8px;
`;

export const Body = styled.span`
  font-size: 24px;
`;

export const StyledAnchor = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: #319dfb;
  font-weight: 700;
  transition: 200ms;
`;
