import React, { memo, useEffect, useState } from 'react';
import FiltersPanel, { SORT_BY_OPTIONS } from './Filters/FiltersPanel';
import Results from './Results/Results';
import Slider from '../Common/Slider/Slider';
import { SLIDER_TYPE } from '../Dashboard/constant';
import { useDispatch, useSelector } from 'react-redux';
import { setLastlySearched } from '../../redux/actions';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Option, SimpleSearchFlag } from '../../models/common';
import { PRODUCT_TYPE, productTypeName } from '../../utils/productType';
import * as S from './AdvancedSearchPage.style';
import { getShortItems, getAdvancedSearch } from '../../redux/reducers/search/actions';
import { isLoadingExtendedItems, extendedItemsToShow } from '../../redux/reducers/search/selectors';

const options: { label: string; value: SORT_BY_OPTIONS }[] = [
  { value: SORT_BY_OPTIONS.DEADLINE, label: 'Deadline' },
  { value: SORT_BY_OPTIONS.PRICE_LOW, label: 'Lowest Price' },
  { value: SORT_BY_OPTIONS.PRICE_HIGHT, label: 'Highest Price' },
];

const perPageOptions: Option[] = [
  { value: '30', label: '30' },
  { value: '60', label: '60' },
  { value: '90', label: '90' },
];

const AdvancedSearchPage = ({ itemsToSearch }: { itemsToSearch: PRODUCT_TYPE }) => {
  const [refresh, setRefresh] = useState(true);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params: any = useParams();
  let { query } = params;
  query = query ? query : '';
  const decodedQuery = decodeURIComponent(query);

  const [sortBy, setSortBy] = useState({ value: SORT_BY_OPTIONS.DEADLINE, label: 'Deadline' });
  const [itemsPerPage, setPerPage] = useState({ value: '30', label: '30' });
  const [page, setPage] = useState(0);
  const [searchPayload, setSearchPayload] = useState<any>({
    query: decodedQuery,
    offset: page,
    limit: null,
    volumeMin: null,
    volumeMax: null,
    budgetMin: null,
    budgetMax: null,
    resolution: null,
    city: null,
    deadline: null,
    sortByDirection: 'ASC',
    sortByTarget: 'deadline',
    productType: itemsToSearch,
    page,
  });
  const products = useSelector(extendedItemsToShow(itemsToSearch));
  const count = products ? products.length : 0;
  const isFetching = useSelector(isLoadingExtendedItems);
  let featuredItems = { count: 0, rows: [] };
  // if (itemsToSearch === PRODUCT_TYPE.PRINT_REQUEST) {
  //   featuredItems = featuredPrintRequests;
  // }
  // if (itemsToSearch === PRODUCT_TYPE.OBJECT_FOR_SELL) {
  //   featuredItems = featuredObjects;
  // }
  // if (itemsToSearch === PRODUCT_TYPE.DESIGN_REQUEST) {
  //   featuredItems = featuredDesignRequests;
  // }
  // if (itemsToSearch === PRODUCT_TYPE.DESIGN_FOR_SELL) {
  //   featuredItems = featuredDesignsForSell;
  // }

  const goBack = () => {
    // navigate(`/search?query=${query}&prints=true&objects=true&designs=true`);
    navigate(-1);  // Go back to the previous page
  };

  const setProductType = () => {
    return; //setResults(products);
  };

  useEffect(() => {
    setProductType();
    if (refresh) {
      dispatch(getShortItems({ flag: SimpleSearchFlag.ALL }));
      dispatch(setLastlySearched(pathname.slice(1)));
      setRefresh(false);
      dispatch(getAdvancedSearch(searchPayload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pathname, refresh, products, page]);

  useEffect(() => {
    if (refresh) {
      dispatch(setLastlySearched(pathname.slice(1)));
      dispatch(getShortItems({ flag: SimpleSearchFlag.ALL }));
      setRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pathname, refresh, itemsPerPage, sortBy, page]);

  const getTitle = () => {
    switch (itemsToSearch) {
      case PRODUCT_TYPE.PRINT_REQUEST: {
        return (
          <>
            <S.Bolded800>Print</S.Bolded800> requests –
          </>
        );
      }
      case PRODUCT_TYPE.OBJECT_FOR_SELL: {
        return (
          <>
            <S.Bolded800>Objects</S.Bolded800> for sale –
          </>
        );
      }
      case PRODUCT_TYPE.DESIGN_FOR_SELL: {
        return (
          <>
            <S.Bolded800>Designs</S.Bolded800> for sale –
          </>
        );
      }
      case PRODUCT_TYPE.DESIGN_REQUEST: {
        return (
          <>
            <S.Bolded800>Designs</S.Bolded800> requests –
          </>
        );
      }
    }
  };

  return (
    <S.Wrapper>
      <S.MenuAndOptionsWrapper>
        <S.Back onClick={goBack}>
          <S.ArrowLeft /> Back
        </S.Back>
        <FiltersPanel
          {...{
            itemsPerPage,
            sortBy,
            page,
            setRefresh,
            setSearchPayload,
            productType: itemsToSearch,
          }}
        />
      </S.MenuAndOptionsWrapper>
      <S.ResultsWrapper>
        <S.TopWrapper>
          <S.TopTitle>{getTitle()}</S.TopTitle>
          <S.Header>
            Search results for: <S.Bolded700> "{decodedQuery}"</S.Bolded700>
            <S.ResultsAmount>({count} results)</S.ResultsAmount>
          </S.Header>
        </S.TopWrapper>
        <Results
          productType={itemsToSearch}
          pagination={{
            options,
            perPageOptions,
            itemsPerPage,
            sortBy,
            page,
            setPage,
            setSortBy,
            setPerPage,
            setRefresh,
            rows: products,
            count,
            isFetchingPrintRequests: isFetching,
          }}
        />
        <S.SliderWrapper>
          <S.SliderTitle>
            <b>Featured</b> {productTypeName[itemsToSearch]}
          </S.SliderTitle>
          <Slider
            sliderType={SLIDER_TYPE.COMPACT}
            items={featuredItems.rows}
            maxWidth={0.51}
            productType={itemsToSearch}
          />
        </S.SliderWrapper>
      </S.ResultsWrapper>
    </S.Wrapper>
  );
};
export default memo(AdvancedSearchPage);
