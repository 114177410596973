import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as S from './BoughtLicense.styles';

const BoughtLicense = () => {
   const navigate = useNavigate();
  const redirect = () => {
    navigate('/dashboard/my-shopping-designs');
  };
  return (
    <S.Wrapper>
      <S.LeftColumn>
        <S.Triangle />
      </S.LeftColumn>
      <S.RightColumn>
        <S.TextWrapper onClick={redirect}>
          <b>You already have bought this design license ---</b>{' '}
          <u>show my ready designs licences</u>
        </S.TextWrapper>
        <S.Button>Download design files</S.Button>
      </S.RightColumn>
    </S.Wrapper>
  );
};

export default BoughtLicense;
