import styled from 'styled-components';
import themes from '../../Assets/colorThemes';
import { Link } from 'react-router-dom';

export const MarketWrapper = styled.div``;

export const MarketCategory = styled.div`
  margin-bottom: 54px;
`;
export const CategoryTitleWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
`;
export const CategoryTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  margin-right: 7px;
`;
export const CategoryTitleBold = styled(CategoryTitle)`
  font-weight: 900;
`;
export const SliderWrapper = styled.div``;

export const SliderRow = styled.div`
  margin-bottom: 31px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  max-width: 844px;
  width: 100%;
`;
export const Item = styled(Link)`
  height: 315px;
  width: 196px;
  margin-right: 20px;
  margin-bottom: 30px;
`;

export const MockItem = styled.div`
  height: 315px;
  margin-right: 20px;
  margin-bottom: 30px;
  cursor: default;
  min-width: 1px;
  flex-grow: 1;
`;

export const ItemImage = styled.img`
  height: 196px;
  width: 196px;
  background-color: ${themes.default.grayE2};
  border-radius: 3px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
`;

export const ItemDetails = styled.div`
  height: 108px;
  padding-top: 11px;
  box-sizing: border-box;
`;
export const ItemTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;
export const ItemTitle = styled.div`
  font-size: 14px;
  color: ${themes.default.gray4d};
  font-weight: 700;
  letter-spacing: 0.2px;
  margin-right: 4px;
`;

export const ItemIcon = styled.div``;
export const ItemPrice = styled.div`
  font-size: 16px;
  color: ${themes.default.gray4d};
  font-weight: 700;
  margin-bottom: 6px;
`;
export const ItemCopiesLeft = styled.div`
  font-size: 12px;
  color: ${themes.default.gray4d};
  font-weight: 600;
  letter-spacing: 0.2px;
  margin-bottom: 6px;
`;

export const ItemDaysLeft = styled.div`
  font-size: 12px;
  color: ${themes.default.gray9a};
`;

export const NoResultsWrapper = styled.div`
  height: 276px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoResults = styled.div`
  margin: auto;
  font-size: 20px;
  font-weight: 500;
  color: ${themes.default.gray4d};
`;
