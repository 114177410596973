import React from 'react';
import * as S from './EmptyMarket.style';

export default () => {
  return (
    <S.EmptyMarketContainer>
      <S.LeftMarketConstruct />
      <S.RightMarketConstruct />
      <S.EmptyProductColumn>
        <S.EmptyShortBox />
        <S.EmptyTextBar isShort={false} />
        <S.EmptyTextBar isShort={true} />
      </S.EmptyProductColumn>
      <S.EmptyProductColumn>
        <S.EmptyShortBox />
        <S.EmptyTextBar isShort={false} />
        <S.EmptyTextBar isShort={true} />
      </S.EmptyProductColumn>
      <S.EmptyProductColumn>
        <S.EmptyShortBox />
        <S.EmptyTextBar isShort={false} />
        <S.EmptyTextBar isShort={true} />
      </S.EmptyProductColumn>
      <S.EmptyProductColumn>
        <S.EmptyShortBox />
        <S.EmptyTextBar isShort={false} />
        <S.EmptyTextBar isShort={true} />
      </S.EmptyProductColumn>
    </S.EmptyMarketContainer>
  );
};
