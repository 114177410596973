import React, { memo, useEffect } from 'react';
import Slider from '../Common/Slider/Slider';
import { SLIDER_TYPE } from '../Dashboard/constant';
import { useSelector, useDispatch } from 'react-redux';
import { setSimpleSearchParams, setLastlySearched } from '../../redux/actions';
import { SimpleSearchFlag } from '../../models/common';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import CircularIndeterminateLoader from '../Common/Loader/CircularIndeterminateLoader';
import { PRODUCT_TYPE } from '../../utils/productType';
import * as S from './SearchSimple.style';
import { getShortItems } from '../../redux/reducers/search/actions';
import { isLoadingShortItems, shortItems } from '../../redux/reducers/search/selectors';

const SearchSimple = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params = queryString.parse(search, {
    arrayFormat: 'comma',
  });
  const isLoading = useSelector(isLoadingShortItems);
  const { designRequests, designsForSell, objectsForSell, printRequests } = useSelector(shortItems);
  const prints = params.prints === 'true';
  const objects = params.objects === 'true';
  const designs = params.designs === 'true';
  const designsFS = params.designsFS === 'true';
  const all = prints && objects && designs && designsFS;
  const query = String(params.query);
  const encodedQuery = encodeURIComponent(query);
  const handleSearch = () => {
    if (all) {
      return dispatch(getShortItems({ query, flag: SimpleSearchFlag.ALL, limit: 24, offset: 0 }));
    }
    if (prints) {
      dispatch(
        getShortItems({
          query,
          flag: SimpleSearchFlag.PRINT_REQUESTS,
          limit: 24,
          offset: 0,
        })
      );
    }
    if (objects) {
      dispatch(
        getShortItems({
          query,
          flag: SimpleSearchFlag.OBJECT_FOR_SELL,
          limit: 24,
          offset: 0,
        })
      );
    }
    if (designs) {
      dispatch(
        getShortItems({
          query,
          flag: SimpleSearchFlag.DESIGN_REQUEST,
          limit: 24,
          offset: 0,
        })
      );
    }
    if (designsFS) {
      dispatch(
        getShortItems({
          query,
          flag: SimpleSearchFlag.DESIGNS_FOR_SELL,
          limit: 24,
          offset: 0,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(setLastlySearched(`search/${search}`));
    dispatch(setSimpleSearchParams({ query, prints, objects, designs, designsFS }));
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prints, objects, designs, all, query, dispatch]);

  const renderResults = (
    type: string,
    results: any[] = [],
    isFetching: boolean,
    productType: PRODUCT_TYPE
  ) => {
    if (isFetching) {
      return (
        <S.LoaderWrapper>
          <CircularIndeterminateLoader />
        </S.LoaderWrapper>
      );
    }
    if (results && results.length > 0) {
      return (
        <Slider
          items={results}
          sliderType={SLIDER_TYPE.COMPACT}
          maxWidth={0.67}
          productType={productType}
        />
      );
    } else {
      return (
        <S.NoResultsWrapper>
          <S.TextWrapper>
            <S.NoResultsTitle>No results…</S.NoResultsTitle>
            <S.NoResultsSubTitle>
              No <S.Bolded>{type}</S.Bolded> has been found for: “{query}”.
            </S.NoResultsSubTitle>
          </S.TextWrapper>
        </S.NoResultsWrapper>
      );
    }
  };

  const printRequestResults = () => {
    if (all || prints) {
      return (
        <S.PrintRequestsWrapper>
          <S.HeaderWrapper>
            <S.Header>
              <S.Bolded>Print</S.Bolded> requests
            </S.Header>
            <S.SecondaryHeader to={`/search/print-requests/${encodedQuery}`}>
              <S.Bolded>Show All</S.Bolded> print requests
              {printRequests.length >= 1 && `(${printRequests.length})`}
            </S.SecondaryHeader>
          </S.HeaderWrapper>
          {renderResults('print requests', printRequests, isLoading, PRODUCT_TYPE.PRINT_REQUEST)}
        </S.PrintRequestsWrapper>
      );
    }
  };

  const objectsResults = () => {
    if (all || objects) {
      return (
        <S.ObjectsWrapper>
          <S.HeaderWrapper>
            <S.Header>
              <S.Bolded>Printed objects</S.Bolded> for sale
            </S.Header>
            <S.SecondaryHeader to={`/search/objects-for-sell/${encodedQuery}`}>
              <S.Bolded>Show All</S.Bolded> printed objects
              {objectsForSell.length >= 1 && `(${objectsForSell.length})`}
            </S.SecondaryHeader>
          </S.HeaderWrapper>
          {renderResults(
            'objects for sale',
            objectsForSell,
            isLoading,
            PRODUCT_TYPE.OBJECT_FOR_SELL
          )}
        </S.ObjectsWrapper>
      );
    }
  };

  const designRequestsResults = () => {
    if (all || designs) {
      return (
        <S.DesignsWrapper>
          <S.HeaderWrapper>
            <S.Header>
              <S.Bolded>Design</S.Bolded> requests
            </S.Header>
            <S.SecondaryHeader to={`/search/design-requests/${encodedQuery}`}>
              <S.Bolded>Show All</S.Bolded> design requests
              {designRequests.length >= 1 && `(${designRequests.length})`}
            </S.SecondaryHeader>
          </S.HeaderWrapper>
          {renderResults('design requests', designRequests, isLoading, PRODUCT_TYPE.DESIGN_REQUEST)}
        </S.DesignsWrapper>
      );
    }
  };
  const designsForSellResults = () => {
    if (all || designsFS) {
      return (
        <S.DesignsWrapper>
          <S.HeaderWrapper>
            <S.Header>
              <S.Bolded>Designs</S.Bolded> for sale
            </S.Header>
            <S.SecondaryHeader to={`/search/designs-for-sell/${encodedQuery}`}>
              <S.Bolded>Show All</S.Bolded> designs for sale
              {designsForSell.length >= 1 && `(${designsForSell.length})`}
            </S.SecondaryHeader>
          </S.HeaderWrapper>
          {renderResults(
            'design for sale',
            designsForSell,
            isLoading,
            PRODUCT_TYPE.DESIGN_FOR_SELL
          )}
        </S.DesignsWrapper>
      );
    }
  };
  return (
    <S.Wrapper>
      <S.TitleWrapper>
        <S.Title>
          Search results for: "<S.Bolded>{query}</S.Bolded>"
          <S.ResultsCount>
            ({printRequests.length + objectsForSell.length + designRequests.length} results)
          </S.ResultsCount>
        </S.Title>
      </S.TitleWrapper>
      {printRequestResults()}
      {objectsResults()}
      {designRequestsResults()}
      {designsForSellResults()}
    </S.Wrapper>
  );
};

export default memo(SearchSimple);
