import styled from 'styled-components';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

export const Wrapper = styled.div`
  max-width: 952px;
  width: 100%;
  border-top: solid 1px #efefef;
`;

export const TopPaginationWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  max-width: 970px;
  width: 100%;
  font-size: 9px;
  align-items: center;
  color: #4D4D4D;
  padding: 15px 0 5px 0;
  margin-left: auto;
  flex-wrap: wrap;
  @media (max-width: 800px) {
    justify-content: space-evenly;
  }
`;

export const Label = styled.div`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.14px;
  color: #4D4D4D;
  margin-right: 9px;
`;

export const SelectSortBy = styled.div`
  box-sizing: border-box;
  width: 246px;
  height: 41px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    margin-bottom: 8px;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ArrowLeft = styled(IoIosArrowBack)`
  height: 26px;
  width: 26px;
  cursor: pointer;
  opacity: 0.7;
`;

export const ArrowRight = styled(IoIosArrowForward)`
  height: 39px;
  width: 39px;
  cursor: pointer;
  opacity: 0.7;
`;

export const ArrowLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.14px;
  color: #000000;
  padding-left: 8px;
`;

export const PageInput = styled.input`
  box-sizing: border-box;
  width: 48px;
  height: 40px;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  padding: 12px 14px;
  font-size: 14px;
  margin: 0 8px;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px #0077df;
  }
`;

export const PerPage = styled.div`
  font-size: 14px;
  margin-right: 2px;
`;

export const BottomPaginationWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  max-width: 970px;
  width: 100%;
  font-size: 9px;
  align-items: center;
  color: #4D4D4D;
  padding: 16px 5px 16px 10px;
  justify-content: space-evenly;
  @media (min-width: 508px) {
    justify-content: space-between;
  }
`;

export const SelectPerPage = styled.div`
  box-sizing: border-box;
  width: 96px;
  height: 41px;
  margin-right: auto;
`;

export const ResultsWrapper = styled.div`
  min-height: 645px;
`;
