import { Comment, ImageProps, PRODUCT_STATUS } from '../../../models/common';
import { Package } from '../../../models/package';
import { EmptySize, EmptyUnit, Size, Unit } from '../../../models/units';
import {
  Currency,
  EmptyAddress,
  EmptyCurrency,
  EmptyUser,
  User,
  UserAddress,
} from '../../../models/user';
import { Purchase } from '../purchase/models';

export interface ShippingMethod {
  id: string;
  method: string;
}

export const EmptyShippingmethod: ShippingMethod = {
  id: '',
  method: '',
};

export interface Temperature {
  id: string;
  type: string;
}

export const EmptyTemperature: Temperature = {
  id: '',
  type: '',
};

export interface Distance {
  id: string;
  name: string;
}

export const EmptyDistance: Distance = {
  id: '',
  name: '',
};

export interface DeliveryType {
  id: string;
  name: string;
  price: number;
  description: string;
  distance: number;
  isDefault: boolean;
  user: User;
  currency: Currency;
}

export interface ProductDeliveryType {
  id: string;
  deliveryType: DeliveryType;
}

export interface Technology {
  id: string;
  name: string;
}

export const EmptyTechnology: Technology = {
  id: '',
  name: '',
};

export interface Material {
  id: string;
  name: string;
}

export const EmptyMaterial: Material = {
  id: '',
  name: '',
};

export interface Infill {
  id: string;
  name: string;
}

export const EmptyInfill: Infill = {
  id: '',
  name: '',
};

export interface ObjectForSell {
  id: string;
  images: ImageProps[];
  packages: Package[];
  notifications: any[];
  attachment: string[];
  title: string;
  price: number;
  currency: Currency;
  isPaid: boolean;
  quantity: number;
  deadline: string;
  description: string;
  status: PRODUCT_STATUS;
  height: number;
  width: number;
  depth: number;
  speed: number;
  material: string;
  colour: string;
  positionX: string;
  positionY: string;
  volume: number;
  email: string;
  phone: string;
  otherTechnology: string;
  temperatureBed: number;
  temperatureNozzle: number;
  printer: string;
  supports: boolean;
  distance: string;
  shippingCosts: number;
  isArchived: boolean;
  layerHeight: number;
  infillDensity: number;
  perimeters: number;
  printTimeHour: number;
  printTimeMinute: number;
  nozzleDiameter: number;
  printTimeDays: number;
  user: User;
  size: Size;
  unit: Unit;
  shippingMetod: ShippingMethod;
  address: UserAddress;
  temperatureUnit: Temperature;
  distanceValue: Distance;
  comments: Comment[];
  deliveryTypes: ProductDeliveryType[];
  technology: Technology;
  selectedMaterial: Material;
  infill: Infill;
  purchases: Purchase[];
  createdAt: string;
}

export const EmptyObjectForSell = {
  id: '',
  images: [],
  packages: [],
  notifications: [],
  attachment: [],
  title: '',
  price: 0,
  currency: EmptyCurrency,
  isPaid: false,
  quantity: 0,
  deadline: '',
  description: '',
  status: PRODUCT_STATUS.WAITING_FOR_FINALIZATION,
  height: 0,
  width: 0,
  depth: 0,
  speed: 0,
  material: '',
  colour: '',
  positionX: '',
  positionY: '',
  volume: 0,
  email: '',
  phone: '',
  otherTechnology: '',
  temperatureBed: 0,
  temperatureNozzle: 0,
  printer: '',
  supports: false,
  distance: '',
  shippingCosts: 0,
  isArchived: false,
  layerHeight: 0,
  infillDensity: 0,
  perimeters: 0,
  printTimeHour: 0,
  printTimeMinute: 0,
  nozzleDiameter: 0,
  printTimeDays: 0,
  user: EmptyUser,
  size: EmptySize,
  unit: EmptyUnit,
  shippingMetod: EmptyShippingmethod,
  address: EmptyAddress,
  temperatureUnit: EmptyTemperature,
  distanceValue: EmptyDistance,
  comments: [],
  deliveryTypes: [],
  technology: EmptyTechnology,
  selectedMaterial: EmptyMaterial,
  infill: EmptyInfill,
  purchases: [],
  createdAt: '',
};
