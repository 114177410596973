export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS';
export const CREATE_USER_DELIVERY_ADDRESS_SUCCESS = 'CREATE_USER_DELIVERY_ADDRESS_SUCCESS';
export const SET_SELECTED_DELIVERY_ADDRESS = 'SET_SELECTED_DELIVERY_ADDRESS';
export const REGISTER_IN_USE_ERROR = 'REGISTER_IN_USE_ERROR';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const SET_SELECTED_DELIVERY_TYPES = 'SET_SELECTED_DELIVERY_TYPES';
export const UPDATE_USER_GENERAL_PREFERENCES = 'UPDATE_USER_GENERAL_PREFERENCES';
export const SET_DELIVERY_OPTIONS_ERROR = 'SET_DELIVERY_OPTIONS_ERROR';
export const SET_LOADING_UPDATE_DELIVERY_OPTIONS = 'SET_LOADING_UPDATE_DELIVERY_OPTIONS';
export const SET_IS_CHANGING_USER_DATA = 'SET_IS_CHANGING_USER_DATA';
export const SET_DELIVERY_OPTIONS = 'SET_DELIVERY_OPTIONS';
export const SET_MAP_VISIBILITY = 'SET_MAP_VISIBILITY';
export const SET_USER_ADDRESSES = 'SET_USER_ADDRESSES';
export const GET_CURRENCY_SUCCESS = 'GET_CURRENCY_SUCCESS';
export const SIMPLE_AUTH_SUCCESS = 'SIMPLE_AUTH_SUCCESS';
export const GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS';
export const GET_USER_NOTIFICATIONS_SUCCESS = 'GET_USER_NOTIFICATIONS_SUCCESS';
export const GET_USER_NOTIFICATIONS_FAIL = 'GET_USER_NOTIFICATIONS_FAIL';
export const SET_USER_DATA_LOADING_STATE = 'SET_USER_DATA_LOADING_STATE';
export const UPLOAD_USER_AVATAR_SUCCESS = 'UPLOAD_USER_AVATAR_SUCCESS';
export const SET_IS_ERROR = 'SET_IS_ERROR';
export const ADD_USER_PRINTER_ERROR = 'ADD_USER_PRINTER_ERROR';
export const SET_IS_ADDING_NEW_ADDRESS = 'SET_IS_ADDING_NEW_ADDRESS';
export const CHANGE_USER_EMAIL = 'CHANGE_USER_EMAIL';
export const CHANGE_USER_EMAIL_SUCCESS = 'CHANGE_USER_EMAIL_SUCCESS';
export const CHANGE_USER_EMAIL_FAILURE = 'CHANGE_USER_EMAIL_FAILURE';
export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';
export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_FAILURE = 'CHANGE_USER_PASSWORD_FAILURE';
