import {
  ARCHIVE_PRODUCT_SUCCESS,
  BUY_PRODUCT_SUCCESS,
  CHECK_OFFER_REVIEW_SUCCESS,
  CLOSE_MODALS,
  CREATE_REQUEST_SUCCESS,
  EDIT_PRODUCT_SUCESS,
  FETCH_OWN_DESIGN_REQUESTS_SUCCESS,
  FETCH_OWN_DESIGNS_FOR_SELL,
  FETCH_OWN_OBJECTS_FOR_SELL,
  FETCH_OWN_OFFERS_SUCCESS,
  FETCH_OWN_PRINT_REQUESTS_SUCCESS,
  GALLERY_ADDED_SUCCESS,
  GALLERY_ADDED,
  GALLERY_DELETED_SUCCESS,
  GALLERY_UPDATED_SUCCESS,
  GET_ADVANCED_SEARCH_RESULTS_START,
  GET_ADVANCED_SEARCH_RESULTS_SUCCESS,
  GET_ALL_USERS_SUCCESS,
  GET_DESIGN_FOR_SELL_DETAILS_SUCCESS,
  GET_DESIGN_FOR_SELL_DETAILS,
  GET_DESIGN_REQUEST_DETAILS_SUCCESS,
  GET_DESIGN_REQUEST_DETAILS,
  GET_GALLERY_DATA,
  GET_OBJECT_FOR_SELL_DETAILS_SUCCESS,
  GET_OBJECT_FOR_SELL_DETAILS,
  GET_PAYMENTS_FAILURE,
  GET_PAYMENTS_START,
  GET_PAYMENTS_SUCCESS,
  GET_PAYOUTS_FAILURE,
  GET_PAYOUTS_START,
  GET_PAYOUTS_SUCCESS,
  GET_PRINT_REQUEST_DETAILS_SUCCESS,
  GET_PRINT_REQUEST_DETAILS,
  GET_USER_DATA_SUCCESS,
  REFRESH_REQUEST_SUCCESS,
  REMOVE_COMMENT,
  SET_COMMENT,
  SET_COMMENTS,
  SET_GALLERY_ID_FOR_EDIT,
  SET_LASTLY_SEARCHED,
  SET_LASTLY_VISITED,
  SET_LIKE,
  SET_LOADING_CREATE_NEW_ADDRESS,
  SET_LOADING_RESULTS,
  SET_LOADING,
  SET_PICK_A_WINNER_STEP,
  SET_PRODUCT_CREATION_ERROR_MESSAGE,
  SET_SELECTED_DEVICE_ID,
  SET_SIMPLE_SEARCH_PARAMS,
  SET_SIMPLE_SEARCH_RESULTS,
  SET_SUBMITTING_DESIGN_REQUEST,
  SET_SUBMITTING_OBJECT_FOR_SELL,
  SET_SUBMITTING_PRINT_REQUEST,
  SET_UPDATED_OFFERS,
  SET_USERS_QUERIES,
  SET_WINNER_MESSAGE,
  SET_WINNER_OFFER_ID,
  SET_WORKSHOP_DEVICES,
  TOGGLE_PHOTO_VIEW,
  UPDATE_USER_ARCHIVE_STATUS_SUCCESS,
  GET_USER_DATA_START,
  GET_USER_DATA_FAILURE,
  FINALIZE_OFFER,
  FINALIZE_OFFER_SUCCESS,
  FINALIZE_OFFER_FAIL,
  SET_NETWORK_ACTION_STATE,
  GET_PURCHASE_INFO,
  GET_PURCHASE_INFO_SUCCESS,
  GET_PURCHASE_INFO_FAILURE,
  CLOSE_PRODUCT_SELLING,
  CLOSE_PRODUCT_SELLING_SUCCESS,
  CLOSE_PRODUCT_SELLING_FAILURE,
  CLOSE_DFS_SELLING,
  CLOSE_DFS_SELLING_SUCCESS,
  CLOSE_DFS_SELLING_FAIL,
  OPEN_DFS_SELLING,
  OPEN_DFS_SELLING_SUCCESS,
  OPEN_DFS_SELLING_FAIL,
  CLOSE_OFS_SELLING,
  CLOSE_OFS_SELLING_SUCCESS,
  CLOSE_OFS_SELLING_FAIL,
  OPEN_OFS_SELLING,
  OPEN_OFS_SELLING_SUCCESS,
  OPEN_OFS_SELLING_FAIL,
  CANCEL_DR,
  CANCEL_DR_SUCCESS,
  CANCEL_DR_FAIL,
  CANCEL_PR,
  CANCEL_PR_SUCCESS,
  CANCEL_PR_FAIL,
} from './actionTypes';
import * as api from '../api/common-api';
import { history } from '../App';
import { CommentLike, OFFER_WINNER_STEP, VIEW_TYPE } from '../models/common';
import {
  UserProfileRequestPayload,
  AddOfferPayload,
  USER_PROFILE_SECTION,
  AddOffer,
} from '../models/user';
import { SimpleSearchFlag, AdvancedSearchPayload } from '../models/search';
import { ReviewPayload } from '../api/common-api';
import getProductRoute from '../utils/getProductRoute';
import { AppState } from './reducers';
import _get from 'lodash/get';
import { UserProfile } from '../models/market';
import { PRODUCT_TYPE } from '../utils/productType';
import moment from 'moment';
import { maxDeadline } from '../utils/const';
import { CommentProps } from '../components/ProductDetails/Comment/Comment';
import { SET_IS_PURCHASE_OWNER } from './reducers/purchase/actionTypes';
import { toggleModal, setModalProductType } from './reducers/utils/action';
import { MODAL_NAME } from './reducers/utils/models';
import {
  SET_DFS_COMMENTS,
  SET_DFS_LIKE,
  SET_SUBMITTING_DESIGN_FOR_SELL,
} from './reducers/designForSell/actionTypes';
import { getViewedPrintRequest } from './reducers/printRequest/actions';
import { getViewedDesignRequest } from './reducers/designRequest/actions';
import { SET_PR_COMMENTS, SET_PR_LIKE, SET_PR_OFFERS } from './reducers/printRequest/actionTypes';
import { SET_DR_COMMENTS, SET_DR_LIKE, SET_DR_OFFERS } from './reducers/designRequest/actionTypes';
import { SET_OFS_COMMENTS, SET_OFS_LIKE } from './reducers/objectForSell/actionTypes';
import { getPurchase } from './reducers/purchase/actions';

/*
  GEODATA function saved 
 return function (dispatch: any, getState: any) {
    dispatch(setIsChangingUserData(true));
    const state: AppState = getState();
    const { about, name, surname, nickname, addresses } = state.auth.user;
    const { city, country, region, zipCode, address, lat, lon } = addresses.find(
      ({ isDefault }: any) => isDefault
    )!;
    const userData = {
      name,
      surname,
      nickname,
      city,
      region,
      zipCode,
      address,
      country,
      about,
      lat,
      lon,
    };
    const geoDataPayload = {
      city: data.city ? data.city : city,
      country: data.country ? data.country : country,
    };
    return api.getGeoData(geoDataPayload).then((response: any) => {
      const { lat, lon } = response[0] || { lat: 0, lon: 0 };
      api.updateUserData({ ...userData, ...data, lat, lon }).then(
        (response: any) => {
          dispatch({
            type: UPDATE_USER_DATA_SUCCESS,
            data: { ...response, lat, lon },
          });
          dispatch(setIsChangingUserData(true));
        },
        (error: any) => {
          dispatch(setIsChangingUserData(true));
          if (error.response.status === 400) {
            console.log('Map pin not found:', error);
          } else {
            console.log('An error occurred.', error);
          }
*/

const checkIfUserNeedsToUpdate = (user: UserProfile, flag: USER_PROFILE_SECTION): boolean => {
  switch (flag) {
    case USER_PROFILE_SECTION.WORKSHOP: {
      const { printers, other } = user.workshop;
      return printers.length + other.length < 1;
    }
    case USER_PROFILE_SECTION.REVIEWS: {
      return user.reviews.length === 0;
    }
  }
  return true;
};

export function getUserProfile(payload: UserProfileRequestPayload) {
  const { id } = payload;
  return function (dispatch: any, getState: any) {
    const state: AppState = getState();
    const maybeUser = _get(state, `userProfiles.users[${id}]`);
    const flag = maybeUser ? payload.flag : USER_PROFILE_SECTION.ABOUT;
    const updateRequired = !maybeUser || checkIfUserNeedsToUpdate(maybeUser, flag);
    if (!updateRequired) return;
    dispatch({ type: GET_USER_DATA_START });
    api.getUserProfile({ ...payload, flag }).then(
      (data: any) => {
        dispatch({
          type: GET_USER_DATA_SUCCESS,
          data: {
            ...data,
            userId: id,
            flag,
          },
        });
      },
      (e: any) => {
        dispatch({ type: GET_USER_DATA_FAILURE });
        console.log('Error occurred while fetching user profile', e);
        if (e.response.status === 403) {
          window.location.href = '/404';
          // return navigate('/');
        }
      }
    );
  };
}

export const setLoadingCreateNewAddress = (isAddingNewAddress: boolean) => ({
  type: SET_LOADING_CREATE_NEW_ADDRESS,
  isAddingNewAddress,
});

export const setSubmittingPrintRequest = (isSubmitting: boolean) => ({
  type: SET_SUBMITTING_PRINT_REQUEST,
  isSubmitting,
});

export const setProductCreationErrorMessage = (errorMessage: string) => ({
  type: SET_PRODUCT_CREATION_ERROR_MESSAGE,
  errorMessage,
});

export const setSubmittingDesignRequest = (isSubmitting: boolean) => ({
  type: SET_SUBMITTING_DESIGN_REQUEST,
  isSubmitting,
});

export const setSubmittingDesignForSell = (isSubmitting: boolean) => ({
  type: SET_SUBMITTING_DESIGN_FOR_SELL,
  isSubmitting,
});

export const setSubmittingObjectForSell = (isSubmitting: boolean) => ({
  type: SET_SUBMITTING_OBJECT_FOR_SELL,
  isSubmitting,
});

export function createPrintRequest(data: any, productType: PRODUCT_TYPE) {
  return function (dispatch: any) {
    return api.createPrintRequest(data).then(
      (response: any) => {
        const productRoute = getProductRoute(productType);
        dispatch({ type: CREATE_REQUEST_SUCCESS, data: response });
        // navigate(`${productRoute}${response.result.id}`);
        window.location.href = `${productRoute}${response.result.id}`;
        dispatch(toggleModal(MODAL_NAME.CREATE_PRODUCT));
      },
      (error: any) => {
        const errorMessage = _get(error, 'response.data', 'Unexpected error');
        if (typeof errorMessage === 'string') {
          dispatch(setProductCreationErrorMessage(errorMessage));
        } else {
          dispatch(setProductCreationErrorMessage('Unexpected error'));
        }
        dispatch(setSubmittingPrintRequest(false));
        console.log('An error occurred.', error);
      }
    );
  };
}

export function refreshPrintRequest(id: any, productType: PRODUCT_TYPE) {
  return function (dispatch: any) {
    const deadline = moment().add(maxDeadline, 'days');
    return api
      .refreshPrintRequest({ id, data: { deadline }, productAction: api.PRODUCT_ACTION.RENEW })
      .then(
        (response: any) => {
          dispatch({ type: REFRESH_REQUEST_SUCCESS, data: { product: response, productType } });
        },
        (error: any) => console.log('Error while refreshing product', error)
      );
  };
}

const getProductAction = (actionType: PRODUCT_TYPE) => {
  switch (actionType) {
    case PRODUCT_TYPE.PRINT_REQUEST: {
      return GET_PRINT_REQUEST_DETAILS_SUCCESS;
    }
    case PRODUCT_TYPE.OBJECT_FOR_SELL: {
      return GET_OBJECT_FOR_SELL_DETAILS_SUCCESS;
    }
    case PRODUCT_TYPE.DESIGN_FOR_SELL: {
      return GET_DESIGN_FOR_SELL_DETAILS_SUCCESS;
    }
    case PRODUCT_TYPE.DESIGN_REQUEST: {
      return GET_DESIGN_REQUEST_DETAILS_SUCCESS;
    }
  }
};

const getProductLoadingAction = (actionType: PRODUCT_TYPE) => {
  switch (actionType) {
    case PRODUCT_TYPE.PRINT_REQUEST: {
      return GET_PRINT_REQUEST_DETAILS;
    }
    case PRODUCT_TYPE.OBJECT_FOR_SELL: {
      return GET_OBJECT_FOR_SELL_DETAILS;
    }
    case PRODUCT_TYPE.DESIGN_FOR_SELL: {
      return GET_DESIGN_FOR_SELL_DETAILS;
    }
    case PRODUCT_TYPE.DESIGN_REQUEST: {
      return GET_DESIGN_REQUEST_DETAILS;
    }
  }
};

export function publishObject(id: string, productType: PRODUCT_TYPE) {
  return async function (dispatch: any) {
    const request = await api.publishProduct({
      id,
      data: null,
      productAction: api.PRODUCT_ACTION.PUBLISH,
    });
    dispatch({
      type: getProductAction(productType),
      data: { request, productType },
    });
  };
}

export function getPrintRequestDetails({
  productId,
  productType,
  redirectOnNotFound,
  getFullAddress,
  fetchPackages,
}: {
  productId: string;
  productType: PRODUCT_TYPE;
  redirectOnNotFound?: boolean;
  getFullAddress?: boolean;
  fetchPackages?: boolean;
}) {
  return function (dispatch: any) {
    dispatch({ type: getProductLoadingAction(productType) });
    api.getPrintRequestDetails(productId, productType, fetchPackages).then(
      (response: any) => {
        if (getFullAddress) {
          api.getAddressById(response.address.id).then(
            (resAddress: any) => {
              dispatch({
                type: getProductAction(productType),
                data: { request: { ...response, address: resAddress }, productId },
              });
            },
            (error: any) => console.log('An error occurred.', error)
          );
        } else {
          dispatch({ type: getProductLoadingAction(productType) });
          dispatch({
            type: getProductAction(productType),
            data: { request: response, productId },
          });
        }
      },
      (error: any) => {
        if (error.response.status === 404 && redirectOnNotFound) {
          //TODO
          // navigate('/');
        } else {
          console.log('An error occurred.', error);
        }
      }
    );
  };
}

export function getObjectForSellDetails(
  objectForSellId: string,
  productType: string,
  redirectOnNotFound?: boolean,
  getFullAddress?: boolean
) {
  return function (dispatch: any) {
    dispatch({ type: GET_OBJECT_FOR_SELL_DETAILS });
    api.getPrintRequestDetails(objectForSellId, productType).then(
      (response: any) => {
        if (getFullAddress) {
          api.getAddressById(response.address.id).then(
            (resAddress: any) => {
              dispatch({
                type: GET_OBJECT_FOR_SELL_DETAILS_SUCCESS,
                data: { data: { ...response, address: resAddress }, objectForSellId },
              });
            },
            (error: any) => console.log('An error occurred.', error)
          );
        } else {
          dispatch({
            type: GET_OBJECT_FOR_SELL_DETAILS_SUCCESS,
            data: { data: response, objectForSellId },
          });
        }
      },
      (error: any) => {
        if (error.response.status === 404 && redirectOnNotFound) {
          // navigate('/');
// TODO
          window.location.href = '/';
        } else {
          console.log('An error occurred.', error);
        }
      }
    );
  };
}

export function getDesignRequestDetails(
  designRequestId: string,
  productType: string,
  redirectOnNotFound?: boolean
) {
  return function (dispatch: any) {
    dispatch({ type: GET_DESIGN_REQUEST_DETAILS });
    api.getPrintRequestDetails(designRequestId, productType).then(
      (response: any) => {
        dispatch({
          type: GET_DESIGN_REQUEST_DETAILS_SUCCESS,
          data: { data: response, designRequestId },
        });
      },
      (error: any) => {
        if (error.response.status === 404 && redirectOnNotFound) {
          // navigate('/');
          window.location.href = '/';
        } else {
          console.log('An error occurred.', error);
        }
      }
    );
  };
}

export function getDesignForSellDetails(
  designForSellId: string,
  productType: string,
  redirectOnNotFound?: boolean
) {
  return function (dispatch: any) {
    dispatch({ type: GET_DESIGN_FOR_SELL_DETAILS });
    api.getPrintRequestDetails(designForSellId, productType).then(
      (response: any) => {
        dispatch({
          type: GET_DESIGN_FOR_SELL_DETAILS_SUCCESS,
          data: { data: response, designForSellId },
        });
      },
      (error: any) => {
        if (error.response.status === 404 && redirectOnNotFound) {
          // navigate('/');
          window.location.href = '/';
        } else {
          console.log('An error occurred.', error);
        }
      }
    );
  };
}

export const setSelectedDevice = (id: string, isPrinter: boolean) => ({
  type: SET_SELECTED_DEVICE_ID,
  id,
  isPrinter,
});

export const closeModals = () => ({
  type: CLOSE_MODALS,
});

export const setWinnerOfferId = (data: string) => ({
  type: SET_WINNER_OFFER_ID,
  data,
});

export const setWinnerOfferMessage = (data: string) => ({
  type: SET_WINNER_MESSAGE,
  data,
});

export const setPickAWinnerStep = (data: OFFER_WINNER_STEP) => ({
  type: SET_PICK_A_WINNER_STEP,
  data,
});

export function createGallery(formData: any, userId: string) {
  return function (dispatch: any) {
    dispatch({
      type: GALLERY_ADDED,
    });
    api.createGallery(formData).then(
      (data: any) => {
        dispatch({
          type: GALLERY_ADDED_SUCCESS,
          data: { data, userId },
        });
      },
      (error: any) => console.log('error', error)
    );
  };
}

export const editGallery = (formData: any, userId: string) => {
  return function (dispatch: any) {
    api.editGallery(formData).then(
      (res: any) =>
        dispatch({
          type: GALLERY_UPDATED_SUCCESS,
          data: { galleries: res, userId },
        }),
      (error: any) => console.log('An error occurred.', error)
    );
  };
};

export const setEditGalleryId = (data: string) => ({
  type: SET_GALLERY_ID_FOR_EDIT,
  data,
});

export const deleteGallery = (id: string, userId: string) => {
  return function (dispatch: any) {
    api.deleteGallery(id).then(
      (res: any) => {
        dispatch({
          type: GALLERY_DELETED_SUCCESS,
          data: { galleries: res, userId },
        });
        dispatch(toggleModal(MODAL_NAME.DELETE_GALLERY));
      },
      (error: any) => console.log('An error occurred.', error)
    );
  };
};

export function getGalleryData(galleryId: string) {
  return function (dispatch: any) {
    api.getGalleryPhotos(galleryId).then((data: any) => dispatch({ type: GET_GALLERY_DATA, data }));
  };
}

export const togglePhotoView = (data: VIEW_TYPE) => ({
  type: TOGGLE_PHOTO_VIEW,
  data,
});

export function getDashboardData(requirements: any) {
  return function (dispatch: any) {
    const { productType, flag } = requirements;
    return api.callUserDashboard(requirements).then(
      (response: any) => {
        productType === 'PRINT_REQUEST' &&
          flag !== 'Offers' &&
          dispatch({ type: FETCH_OWN_PRINT_REQUESTS_SUCCESS, data: response });
        productType === 'DESIGN_REQUEST' &&
          flag !== 'Offers' &&
          dispatch({ type: FETCH_OWN_DESIGN_REQUESTS_SUCCESS, data: response });
        productType === 'DESIGN_FOR_SELL' &&
          dispatch({ type: FETCH_OWN_DESIGNS_FOR_SELL, data: response });
        productType === 'OBJECT_FOR_SELL' &&
          dispatch({ type: FETCH_OWN_OBJECTS_FOR_SELL, data: response });
        flag === 'Offers' && dispatch({ type: FETCH_OWN_OFFERS_SUCCESS, data: response });
      },
      (error: any) => console.log('An error occurred.', error)
    );
  };
}

export function addOffer({ printRequestId, message, isDesign }: AddOfferPayload) {
  return function (dispatch: any) {
    dispatch({ type: SET_NETWORK_ACTION_STATE, data: true });
    api.addOffer({ printRequestId, message }).then(
      (response: any) => {
        dispatch({ type: SET_NETWORK_ACTION_STATE, data: false });
        // navigate(`/${isDesign ? 'design-request' : 'print-request'}/${printRequestId}`);
        window.location.href = `/${isDesign ? 'design-request' : 'print-request'}/${printRequestId}`;
        dispatch(toggleModal(MODAL_NAME.OFFER_ADDED));
      },
      (error: any) => {
        dispatch({ type: SET_NETWORK_ACTION_STATE, data: false });
        console.log('An error occurred.', error);
      }
    );
  };
}

export function pickOffer(offerId: string, responseMessage?: string) {
  return function (dispatch: any) {
    api.pickOffer({ offerId, responseMessage }).then(
      (response: any) => {
        dispatch({ type: SET_UPDATED_OFFERS, data: response });
      },
      (error: any) => console.log('An error occurred.', error)
    );
  };
}

// export function getSimpleSearchResults(payLoad: SimpleSearchPayload) {
//   const { flag } = payLoad;
//   return function (dispatch: any) {
//     dispatch(setLoadingResults(flag));
//     api
//       .getSimpleSearchResults(payLoad)
//       .then((response: any) => dispatch(setSimpleSearchResults({ response, flag })));
//   };
// }

export function setSimpleSearchResults(data: any) {
  return {
    type: SET_SIMPLE_SEARCH_RESULTS,
    data,
  };
}

export const setLastlyVisited = (data: string) => ({
  type: SET_LASTLY_VISITED,
  data,
});

export function setLastlySearched(data: string) {
  return {
    type: SET_LASTLY_SEARCHED,
    data,
  };
}

// export function getFeaturedItems() {
//   return function (dispatch: any) {
//     dispatch(setLoadingResults(SimpleSearchFlag.ALL));
//     api
//       .getSimpleSearchResults({
//         query: '',
//         flag: SimpleSearchFlag.ALL,
//         limit: 24,
//         offset: 0,
//       })
//       .then((response: any) => dispatch(setFeaturedItems(response)));
//   };
// }

// export function setFeaturedItems(data: any) {
//   return {
//     type: SET_FEATURED_ITEMS,
//     data,
//   };
// }

export function setLoadingResults(itemType: SimpleSearchFlag) {
  return {
    type: SET_LOADING_RESULTS,
    data: { itemType },
  };
}

export function setSimpleSearchParams(data: {
  query: string;
  prints: boolean;
  objects: boolean;
  designs: boolean;
  designsFS: boolean;
}) {
  return {
    type: SET_SIMPLE_SEARCH_PARAMS,
    data,
  };
}

export function getUsersQueries(payload: {
  filterFlag: 'RECENT' | 'MATCH';
  query?: string;
  symbols?: string[];
}) {
  return function (dispatch: any) {
    api
      .getUsersQueries(payload)
      .then((response: any) => dispatch({ type: SET_USERS_QUERIES, data: response }));
  };
}

export function deleteUsersQuery(id: string) {
  return function () {
    api.deleteUsersQuery({ id });
  };
}

export function getAdvancedSearch(payload: AdvancedSearchPayload) {
  return function (dispatch: any) {
    dispatch({ type: GET_ADVANCED_SEARCH_RESULTS_START });
    api.getAdvancedSearch(payload).then(
      (response: any) => dispatch({ type: GET_ADVANCED_SEARCH_RESULTS_SUCCESS, data: response }),
      (error: any) => {
        dispatch({ type: GET_ADVANCED_SEARCH_RESULTS_START });
        console.log('An error occurred.', error);
      }
    );
  };
}

export function cancelOffer(id: string) {
  return function (dispatch: any) {
    api.cancelOffer({ id }).then(
      (response: any) => dispatch({ type: FETCH_OWN_OFFERS_SUCCESS, data: response }),
      (error: any) => console.log('An error occurred.', error)
    );
  };
}

export function removeWorkshopDevice(id: string) {
  return function (dispatch: any) {
    api
      .deleteWorkshopDevice(id)
      .then((response: any) => dispatch({ type: SET_WORKSHOP_DEVICES, data: response }));
  };
}

export function editWorkshopDevice(formData: any) {
  return function (dispatch: any) {
    api.editWorkshopDevice(formData).then((response: any) => {
      dispatch({ type: SET_WORKSHOP_DEVICES, data: response });
    });
  };
}

export function addContractorReview(
  reviewData: ReviewPayload,
  isPrint: boolean,
  productId: string
) {
  return function (dispatch: any) {
    api.addReview(reviewData).then(
      (response: any) => {
        dispatch(toggleModal(MODAL_NAME.RATE_CONTRACTOR));
        if (isPrint) {
          dispatch(getViewedPrintRequest(productId));
        } else {
          dispatch(getViewedDesignRequest(productId));
        }
      },
      (error: any) => console.log('addContractorReview Error')
    );
  };
}

export function archivePrintRequest(productId: string, productType: PRODUCT_TYPE) {
  return function (dispatch: any) {
    api.archivePrintRequest(productId).then(
      (response: any) =>
        dispatch({
          type: ARCHIVE_PRODUCT_SUCCESS,
          data: { productId, productType },
        }),
      (error: any) => console.log('Error while archiving product', error)
    );
  };
}

export function getAllUsers(data: {
  query?: string;
  sortBy?: string;
  limit?: number;
  offset?: number;
}) {
  return function (dispatch: any) {
    api.getAllUsers(data).then(
      (response: any) =>
        dispatch({
          type: GET_ALL_USERS_SUCCESS,
          data: response,
        }),
      (error: any) => console.log('Error while fetching users', error)
    );
  };
}

export function archiveUser(data: { userId: string; isSetArchived: boolean }) {
  return function (dispatch: any) {
    api.archiveUser(data).then(
      (response: any) =>
        dispatch({
          type: UPDATE_USER_ARCHIVE_STATUS_SUCCESS,
          data: response,
        }),
      (error: any) => console.log("Error while updating user's archive status", error)
    );
  };
}

export function checkOfferReview(offerId: string, profileId: string, productType: PRODUCT_TYPE) {
  return function (dispatch: any) {
    api.checkOfferReview({ offerId, profileId }).then(
      (response: any) =>
        dispatch({
          type: CHECK_OFFER_REVIEW_SUCCESS,
          data: response,
          productType,
        }),
      (error: any) => console.log('Error while checkOfferReview product', error)
    );
  };
}

// export function getMyShopping(productType: PRODUCT_TYPE) {
//   return function (dispatch: any) {
//     api.getMyShopping(productType).then(
//       (response: any) =>
//         dispatch({
//           type: GET_MY_SHOPPING_SUCCESS,
//           data: response,
//         }),
//       (error: any) => console.log('Error while getting myShopping', error)
//     );
//   };
// }

export function buyProduct(id: string, productType: PRODUCT_TYPE) {
  return function (dispatch: any) {
    api.buyProduct({ id, productType }).then(
      (response: any) =>
        dispatch({
          type: BUY_PRODUCT_SUCCESS,
          data: response,
        }),
      (error: any) => console.log('Error while buying product', error)
    );
  };
}

export function editProduct(id: string, productType: PRODUCT_TYPE, data: any) {
  return function (dispatch: any) {
    api.editProduct({ id, productType, data }).then(
      (response: any) =>
        dispatch({
          type: EDIT_PRODUCT_SUCESS,
          data: response,
        }),
      (error: any) => console.log('Error occurred while editing product: ', error)
    );
  };
}

export const setCommentsOld = (data: {
  comments: CommentProps[];
  productId: string;
  productType: PRODUCT_TYPE;
}) => ({
  type: SET_COMMENTS,
  data,
});

export const setRemovedComment = (data: {
  commentId: string;
  productId: string;
  productType: PRODUCT_TYPE;
}) => ({
  type: REMOVE_COMMENT,
  data,
});

export const setLike = (like: CommentLike, type: string) => ({
  type,
  like,
});

interface AddComment {
  payload: api.AddCommentPayload;
  productId: string;
  productType: PRODUCT_TYPE;
}

interface RemoveComment {
  commentId: string;
  productId: string;
}

interface SetLikeProps {
  payload: api.LikeCommentPayload;
  productType: PRODUCT_TYPE;
}

const getLikeCommentActionType = (productType: PRODUCT_TYPE) => {
  switch (productType) {
    case PRODUCT_TYPE.PRINT_REQUEST:
      return SET_PR_LIKE;
    case PRODUCT_TYPE.DESIGN_REQUEST:
      return SET_DR_LIKE;
    case PRODUCT_TYPE.DESIGN_FOR_SELL:
      return SET_DFS_LIKE;
    case PRODUCT_TYPE.OBJECT_FOR_SELL:
      return SET_OFS_LIKE;
  }
};

export function likeComment(data: SetLikeProps) {
  const { payload, productType } = data;
  return function (dispatch: any) {
    dispatch(setLoading(true));
    api.likeComment(payload).then(
      (response: CommentLike) => {
        const likeType = getLikeCommentActionType(productType);
        dispatch(setLike(response, likeType));
        dispatch(setLoading(false));
      },
      (error: any) => {
        console.log('Error occurred while editing product: ', error);
        dispatch(setLoading(false));
      }
    );
  };
}

export const setLoading = (isLoading: boolean) => ({ type: SET_LOADING, data: isLoading });

export const getPayments = () => {
  return function (dispatch: any) {
    dispatch({ type: GET_PAYMENTS_START });
    api.getPayments().then(
      (response: any) =>
        dispatch({
          type: GET_PAYMENTS_SUCCESS,
          data: response,
        }),
      (error: any) => {
        dispatch({ type: GET_PAYMENTS_FAILURE });
        console.log('error has occured', error);
      }
    );
  };
};

export const getPayouts = () => {
  return function (dispatch: any) {
    dispatch({ type: GET_PAYOUTS_START });
    api.getPayouts().then(
      (response: any) =>
        dispatch({
          type: GET_PAYOUTS_SUCCESS,
          data: response,
        }),
      (error: any) => {
        dispatch({ type: GET_PAYOUTS_FAILURE });
        console.log('error has occured', error);
      }
    );
  };
};

export const finalizeOffer = ({ productId }: { productId: string }) => {
  return function (dispatch: any) {
    dispatch({ type: FINALIZE_OFFER });
    api.finalizeOffer(productId).then(
      (response: any) => {
        dispatch({ type: FINALIZE_OFFER_SUCCESS, data: { productId } });
        dispatch(toggleModal(MODAL_NAME.FINALIZE_OFFER));
      },
      (error: any) => {
        dispatch({ type: FINALIZE_OFFER_FAIL });
        console.log('Error has occured', error);
      }
    );
  };
};

export const getPurchaseInfo = (printRequestId: string, userId: string) => {
  return function (dispatch: any) {
    dispatch({ type: GET_PURCHASE_INFO });
    api.getPurchaseByProduct(printRequestId).then(
      (response: any) => {
        dispatch({ type: GET_PURCHASE_INFO_SUCCESS, data: response });
        const responseUserId = response.user.id;
        dispatch({ type: SET_IS_PURCHASE_OWNER, data: responseUserId === userId });
      },
      (error: any) => {
        dispatch({ type: GET_PURCHASE_INFO_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
};

export function closeProductSelling(productId: string) {
  return function (dispatch: any) {
    dispatch({ type: CLOSE_PRODUCT_SELLING });
    api.closeProdyctSelling(productId).then(
      (response: any) => {
        dispatch({ type: CLOSE_PRODUCT_SELLING_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: CLOSE_PRODUCT_SELLING_FAILURE });
        console.log('Error has occured', error);
      }
    );
  };
}

export function addDesignOffer({ id, message }: AddOffer) {
  return function (dispatch: any) {
    dispatch({ type: SET_NETWORK_ACTION_STATE, data: true });
    api.addDesignOffer({ id, message }).then(
      (response: any) => {
        dispatch({ type: SET_NETWORK_ACTION_STATE, data: false });
        // navigate(`/design-request/${id}`);

        window.location.href = `/design-request/${id}`;
        dispatch(toggleModal(MODAL_NAME.OFFER_ADDED));
        dispatch(setModalProductType(PRODUCT_TYPE.DESIGN_REQUEST));
      },
      (error: any) => {
        dispatch({ type: SET_NETWORK_ACTION_STATE, data: false });
        console.log('An error occurred.', error);
      }
    );
  };
}

export function addPrintOffer({ id, message }: AddOffer) {
  return function (dispatch: any) {
    dispatch({ type: SET_NETWORK_ACTION_STATE, data: true });
    api.addPrintOffer({ id, message }).then(
      (response: any) => {
        dispatch({ type: SET_NETWORK_ACTION_STATE, data: false });
        // navigate(`/print-request/${id}`);
        window.location.href = `/print-request/${id}`;
        dispatch(toggleModal(MODAL_NAME.OFFER_ADDED));
        dispatch(setModalProductType(PRODUCT_TYPE.PRINT_REQUEST));
      },
      (error: any) => {
        dispatch({ type: SET_NETWORK_ACTION_STATE, data: false });
        console.log('An error occurred.', error);
      }
    );
  };
}

const getCommentActionType = (productType: PRODUCT_TYPE) => {
  switch (productType) {
    case PRODUCT_TYPE.PRINT_REQUEST: {
      return SET_PR_COMMENTS;
    }
    case PRODUCT_TYPE.DESIGN_REQUEST: {
      return SET_DR_COMMENTS;
    }
    case PRODUCT_TYPE.DESIGN_FOR_SELL: {
      return SET_DFS_COMMENTS;
    }
    case PRODUCT_TYPE.OBJECT_FOR_SELL: {
      return SET_OFS_COMMENTS;
    }
  }
};

export const setComment2 = (data: {
  comment: CommentProps;
  productId: string;
  productType: PRODUCT_TYPE;
}) => ({
  type: SET_COMMENT,
  data,
});

export const setComments = (comments: any[], actionType: string) => ({
  type: actionType,
  comments,
});

export function addComment({ payload, productType }: AddComment) {
  return function (dispatch: any) {
    // dispatch(setLoading(true));
    api.addComment(payload).then(
      (response: any) => {
        const actionType = getCommentActionType(productType);
        dispatch(setComments(response, actionType));
        // dispatch(setLoading(false));
      },
      (error: any) => {
        console.log('Error occurred while editing product: ', error);
        // dispatch(setLoading(false));
      }
    );
  };
}

export function rejectOffer(offerId: string, productType: PRODUCT_TYPE) {
  return function (dispatch: any) {
    api.rejectOffer({ offerId }).then(
      (response: any) => {
        const actionType =
          productType === PRODUCT_TYPE.PRINT_REQUEST ? SET_PR_OFFERS : SET_DR_OFFERS;
        dispatch({ type: actionType, offers: response });
      },
      (error: any) => console.log('An error occurred.', error)
    );
  };
}

export function closeDFS(id: string) {
  return function (dispatch: any) {
    dispatch({ type: CLOSE_DFS_SELLING });
    api.closeDFSSelling(id).then(
      (response: any) => {
        dispatch({ type: CLOSE_DFS_SELLING_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: CLOSE_DFS_SELLING_FAIL });
        console.log('ERROR', error);
      }
    );
  };
}

export function openDFS(id: string) {
  return function (dispatch: any) {
    dispatch({ type: OPEN_DFS_SELLING });
    api.openDFSSelling(id).then(
      (response: any) => {
        dispatch({ type: OPEN_DFS_SELLING_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: OPEN_DFS_SELLING_FAIL });
        console.log('ERROR', error);
      }
    );
  };
}

export function closeOFS(id: string) {
  return function (dispatch: any) {
    dispatch({ type: CLOSE_OFS_SELLING });
    api.closeOFSSelling(id).then(
      (response: any) => {
        dispatch({ type: CLOSE_OFS_SELLING_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: CLOSE_OFS_SELLING_FAIL });
        console.log('ERROR', error);
      }
    );
  };
}

export function openOFS(id: string) {
  return function (dispatch: any) {
    dispatch({ type: OPEN_OFS_SELLING });
    api.openOFSSelling(id).then(
      (response: any) => {
        dispatch({ type: OPEN_OFS_SELLING_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: OPEN_OFS_SELLING_FAIL });
        console.log('ERROR', error);
      }
    );
  };
}

export function cancelDR(id: string) {
  return function (dispatch: any) {
    dispatch({ type: CANCEL_DR });
    api.cancelDR(id).then(
      (response: any) => {
        dispatch({ type: CANCEL_DR_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: CANCEL_DR_FAIL });
        console.log('ERROR', error);
      }
    );
  };
}

export function cancelPR(id: string) {
  return function (dispatch: any) {
    dispatch({ type: CANCEL_PR });
    api.cancelPR(id).then(
      (response: any) => {
        dispatch({ type: CANCEL_PR_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: CANCEL_PR_FAIL });
        console.log('ERROR', error);
      }
    );
  };
}

export interface ReviewSellerPayload {
  profileId: string;
  purchaseId: string;
  rating: number;
  message?: string;
}

export function addSellerReview(reviewData: ReviewSellerPayload, userId: string) {
  const { purchaseId } = reviewData;
  return function (dispatch: any) {
    api.addSellerReview(reviewData).then(
      (response: any) => {
        dispatch(toggleModal(MODAL_NAME.RATE_CONTRACTOR));
        dispatch(getPurchase(purchaseId, userId));
      },
      (error: any) => console.log('addContractorReview Error')
    );
  };
}
