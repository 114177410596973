import React, { useState, memo, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getUsersQueries, setSimpleSearchParams, deleteUsersQuery } from '../../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import DropDownMenu from './SearchBoxMenu';
import { AppState } from '../../../redux/reducers';
import { maxRequestNameLength } from '../../../utils/const';
import * as S from './SearchBox.style';
import { isModalOpen } from '../../../redux/reducers/utils/selectors';
import { MODAL_NAME } from '../../../redux/reducers/utils/models';
import { toggleModal } from '../../../redux/reducers/utils/action';

interface ISearchBox {
  query: string;
  setQuery: (value: string) => void;
}

const SearchBox = ({ query, setQuery }: ISearchBox) => {
  const dispatch = useDispatch();
   const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const { oldQueries } = useSelector((state: AppState) => state.user);
  const { prints, objects, designs, designsFS } = useSelector(
    (state: AppState) => state.search.toggledSearchItems
  );
  const isOpen = useSelector(isModalOpen(MODAL_NAME.SEARCH_BOX));
  const [arePrintRequests, setPrintRequests] = useState(prints);
  const [arePrintedObjects, setPrintedObjects] = useState(objects);
  const [areDesignRequests, setDesignRequests] = useState(designs);
  const [areDesignsForSell, setDesignsForSell] = useState(designsFS);
  useEffect(() => {
    dispatch(getUsersQueries({ filterFlag: 'RECENT' }));
    setRefresh(false);
  }, [dispatch, refresh]);

  const redirectSearch = () => {
    const encodedQuery = encodeURIComponent(query);
    const reDirURL = `/search?query=${encodedQuery}&prints=${arePrintRequests}&objects=${arePrintedObjects}&designs=${areDesignRequests}&designsFS=${areDesignsForSell}`;
    navigate(reDirURL);
    dispatch(getUsersQueries({ filterFlag: 'RECENT' }));
    dispatch(
      setSimpleSearchParams({
        query,
        prints: arePrintRequests,
        objects: arePrintedObjects,
        designs: areDesignRequests,
        designsFS: areDesignsForSell,
      })
    );
  };
  const onClickSearch = () => {
    redirectSearch();
  };

  const onKeyPressed = (event: any) => {
    if (event.key === 'Enter') {
      redirectSearch();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<{ value: string }>) => {
    const searchQuery = e.target.value;
    if (searchQuery.length <= maxRequestNameLength) {
      setQuery(searchQuery);
      dispatch(
        getUsersQueries({
          filterFlag: 'MATCH',
          query: searchQuery,
          symbols: ['A', 'P', 'O', 'D'],
        })
      );
    }
  };

  const deleteQuery = (id: string) => {
    dispatch(deleteUsersQuery(id));
    setRefresh(true);
  };

  const handleDropdownToggle = () => {
    dispatch(toggleModal(MODAL_NAME.SEARCH_BOX));
  };

  return (
    <S.Wrapper isActive={isOpen}>
      <S.SearchWrapper isActive={isOpen}>
        <S.Input
          onChange={handleInputChange}
          onKeyPress={(e: any) => onKeyPressed(e)}
          placeholder="Search for..."
          name="search"
          onClick={handleDropdownToggle}
          value={query}
          isActive={isOpen}
          autoComplete="off"
          type="text"
        />
        <S.PlainButton onClick={onClickSearch}>
          <S.SearchIcon />
        </S.PlainButton>
      </S.SearchWrapper>
      <DropDownMenu
        isActive={isOpen}
        oldQueries={oldQueries}
        query={query}
        arePrintRequests={arePrintRequests}
        arePrintedObjects={arePrintedObjects}
        areDesignRequests={areDesignRequests}
        areDesignsForSell={areDesignsForSell}
        setPrintRequests={setPrintRequests}
        setPrintedObjects={setPrintedObjects}
        setDesignRequests={setDesignRequests}
        setDesignsForSell={setDesignsForSell}
        deleteQuery={deleteQuery}
        setQuery={setQuery}
      />
    </S.Wrapper>
  );
};

export default memo(SearchBox);
