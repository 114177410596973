import React, { memo } from 'react';
import { SLIDER_TYPE } from '../../Dashboard/constant';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getSizeIcon, generateLink } from '../../../helpers/functions';
import darkLogo from '../../../images/logo_dark.png';
import { PRODUCT_TYPE } from '../../../utils/productType';
import { ImageProps } from '../../../models/common';
import { PrintRequest } from '../../../redux/reducers/printRequest/models';
import { ObjectForSell } from '../../../redux/reducers/objectForSell/models';
import { DesignForSell } from '../../../redux/reducers/designForSell/models';
import { DesignRequest } from '../../../redux/reducers/designRequest/models';
import * as S from './Slider.style';
import { getProductPrice } from '../../../utils/getProductPrice';
import FormatPrice from '../FormatPrice/FormatPrice';

interface ItemProps {
  product: PrintRequest | ObjectForSell | DesignForSell | DesignRequest;
  type: SLIDER_TYPE;
  productType: PRODUCT_TYPE;
}

const SliderItem = ({ type, productType, product }: ItemProps) => {
  const { id, title, deadline, size, images = [], description } = product;
  const now = moment(deadline).startOf('day');
  const daysFromToday = now.diff(moment().startOf('day'), 'days');
  const selectedPhoto = images.find(({ order }: ImageProps) => order === 0);
  const { src } = selectedPhoto ? selectedPhoto : { src: darkLogo };
  const { symbol, price, quantity, currency } = getProductPrice(productType, product);
  const hasQuantity = quantity ? quantity >= 0 : false;
  return (
    <S.ItemWrapper type={type}>
      <Link style={{ width: '100%' }} to={generateLink(productType, title, id)}>
        <S.ItemThumbnailWrapper type={type}>
          <S.ItemThumbnailImage src={src} />
          <S.ItemThumbnailHotStatus isFeatured={type === SLIDER_TYPE.FEATURED}>
            New
          </S.ItemThumbnailHotStatus>
        </S.ItemThumbnailWrapper>
        <S.ItemDescription>
          <S.ItemTitleWrapper>
            <S.SimpleDescContainer>
            <S.ItemTitle>{title}</S.ItemTitle>
            {productType === PRODUCT_TYPE.DESIGN_REQUEST && (
            <S.SimpleDesc>{description}</S.SimpleDesc>
            )}
            </S.SimpleDescContainer>
            {size && productType !== PRODUCT_TYPE.DESIGN_REQUEST ? (
              <S.ItemSize>
                <S.ItemInternalSize>{getSizeIcon(size.symbol)}</S.ItemInternalSize>
              </S.ItemSize>
            ) : null}
          </S.ItemTitleWrapper>
          <S.ItemPricingWrapper>
            <S.ItemPricingCost>
              <FormatPrice price={price} productCurrency={{ symbol, currency }} />
            </S.ItemPricingCost>
            <S.ItemPricingFor> for </S.ItemPricingFor>
            {hasQuantity ? (
              <S.ItemPricingCopies> {quantity} copies</S.ItemPricingCopies>
            ) : (
              <S.ItemPricingCopies>license</S.ItemPricingCopies>
            )}
          </S.ItemPricingWrapper>
          {deadline && <S.ItemDaysLeft>{daysFromToday} days left</S.ItemDaysLeft>}
        </S.ItemDescription>
      </Link>
    </S.ItemWrapper>
  );
};

export default memo(SliderItem);
