import { ModalData } from '../../../helpers/definitions/interfaces';
import {
  SET_MODAL_VISIBILITY,
  GET_SETTINGS_SUCCESS,
  TOGGLE_PHOTO_VIEW,
  CLOSE_MODALS,
  TOGGLE_MODAL,
  SET_GALLERY_ID_FOR_EDIT,
  SET_SELECTED_DELIVERY_ADDRESS,
  SET_SELECTED_DEVICE_ID,
  LOGOUT_USER_SUCCESS,
  SET_NETWORK_ACTION_STATE,
  SET_MODAL_ELEMENT_ID,
  SET_MODAL_PRODUCT_TYPE,
  SET_MODAL_GALLERY_ID,
  CLEAR_MODAL_STATES,
  CLEAR_EXTERNAL_AUTH_STATES,
  CLEAR_ADDRESS_MODALS,
  SET_MODAL_OFFERS,
  SET_VIEWED_PRODUCT,
  ADD_NEW_LICENSE,
  ADD_NEW_LICENSE_SUCCESS,
  ADD_NEW_LICENSE_FAILURE,
  SET_IS_LICENSE_BOUGHT,
  SET_IS_LICENSE_BOUGHT_SUCCESS,
  SET_IS_LICENSE_BOUGHT_FAILURE,
  SET_PREVIEW_PRINT_REQUEST,
  SET_PREVIEW_DESIGN_REQUEST,
  SET_PREVIEW_OBJECT_FOR_SELL,
  SET_PREVIEW_DESIGN_FOR_SELL,
  SET_SELECTED_LICENSE_IDS,
  SET_SELECTED_VIEWED_DESIGN_LICENSE,
  SET_DESIGN_OFFER_MORE_ACTIONS_ID,
  EDIT_NEW_LICENSE,
  EDIT_NEW_LICENSE_SUCCESS,
  EDIT_NEW_LICENSE_FAILURE,
  SET_MORE_ACTIONS_ID,
  SET_LICENSE_DESCRIPTION,
  CLEAR_ADD_LICENSE_ERROR_MESSAGE,
  SET_CREATE_PRODUCT_TYPE,
} from './actionTypes';
import * as api from '../../../api/common-api';
import { MODAL_NAME, VIEW_TYPE } from './models';
import { AddOfferPayload } from '../../../models/user';
import { history } from '../../../App';
import { PRODUCT_TYPE } from '../../../utils/productType';
import { getShortItems } from '../search/actions';
import { AddLicensePayload, SimpleSearchFlag, EditLicensePayload } from '../../../models/common';
import { PrintRequest } from '../printRequest/models';
import { DesignRequest } from '../designRequest/models';
import { ObjectForSell } from '../objectForSell/models';
import { DesignForSell, License } from '../designForSell/models';

export const setModalVisibility = (data: ModalData) => ({
  type: SET_MODAL_VISIBILITY,
  data,
});

export function getSettings() {
  return function (dispatch: any) {
    return api.getSettings().then(
      (response: any) => {
        // dispatch(getCurrencyRate());
        dispatch({ type: GET_SETTINGS_SUCCESS, data: response });
      },
      (error: any) => console.log('An error occurred.', error)
    );
  };
}

export const togglePhotoView = (data: VIEW_TYPE) => ({
  type: TOGGLE_PHOTO_VIEW,
  data,
});

export const closeModals = () => ({
  type: CLOSE_MODALS,
});

export const toggleModal = (name: string) => ({
  type: TOGGLE_MODAL,
  name,
});

export const setEditGalleryId = (data: string) => ({
  type: SET_GALLERY_ID_FOR_EDIT,
  data,
});

export const setSelectedDeliveryAddress = (id: string) => ({
  type: SET_SELECTED_DELIVERY_ADDRESS,
  id,
});

export const setSelectedDevice = (id: string, isPrinter: boolean) => ({
  type: SET_SELECTED_DEVICE_ID,
  id,
  isPrinter,
});

export function logout() {
  return async function (dispatch: any) {
    const globalWindow = window as any;
    await new Promise<any>(async (resolve) => {
      globalWindow.FB.getLoginStatus(function (loginStatusResponse: any) {
        if (loginStatusResponse.status === 'connected') {
          globalWindow.FB.logout(function (response: any) {
            resolve(response);
          });
        } else {
          resolve(loginStatusResponse);
        }
      });
      //todo: handle logout actions
    });
    return api
      .logout()
      .then(
        (response: any) => {
          dispatch({ type: LOGOUT_USER_SUCCESS, data: response });
          dispatch(getShortItems({ flag: SimpleSearchFlag.ALL }));
        },
        (error: any) => console.log('An error occurred.', error)
      )
      .then(() =>
        dispatch({
          type: SET_MODAL_VISIBILITY,
          data: {
            title: 'userMenu',
            isActive: false,
          },
        })
      )
      .then(() => dispatch(getSettings()));
  };
}

export function addOffer({ printRequestId, message, isDesign }: AddOfferPayload) {
  return function (dispatch: any) {
    dispatch({ type: SET_NETWORK_ACTION_STATE, data: true });
    api.addOffer({ printRequestId, message }).then(
      (response: any) => {
        dispatch({ type: SET_NETWORK_ACTION_STATE, data: false });
        // navigate(`/${isDesign ? 'design-request' : 'print-request'}/${printRequestId}`);
        history.push(`/${isDesign ? 'design-request' : 'print-request'}/${printRequestId}`);
        dispatch({
          type: SET_MODAL_VISIBILITY,
          data: { title: 'offerAdded', isActive: true },
        });
      },
      (error: any) => {
        dispatch({ type: SET_NETWORK_ACTION_STATE, data: false });
        console.log('An error occurred.', error);
      }
    );
  };
}

export const setModalElementId = (elementId: string) => ({
  type: SET_MODAL_ELEMENT_ID,
  elementId,
});

export const setModalProductType = (productType: PRODUCT_TYPE) => ({
  type: SET_MODAL_PRODUCT_TYPE,
  productType,
});

export const setModalGalleryId = (galleryId: string) => ({
  type: SET_MODAL_GALLERY_ID,
  galleryId,
});

export const clearAllModalsStates = () => ({
  type: CLEAR_MODAL_STATES,
});

export const clearExternalAuthStates = () => ({
  type: CLEAR_EXTERNAL_AUTH_STATES,
});

export const clearAddressModals = () => ({
  type: CLEAR_ADDRESS_MODALS,
});

export const setModalOffers = (offers: any[]) => ({
  type: SET_MODAL_OFFERS,
  offers,
});

export const setModalProduct = (viewedProduct: any) => ({
  type: SET_VIEWED_PRODUCT,
  viewedProduct,
});

export const setMoreActionsId = (moreActionsId: string) => ({
  type: SET_MORE_ACTIONS_ID,
  moreActionsId,
});

export const setDesignOfferMoreActionsId = (designOfferMoreActionsId: string) => ({
  type: SET_DESIGN_OFFER_MORE_ACTIONS_ID,
  designOfferMoreActionsId,
});

export const setSelectedLicenseIds = (selectedLicenseId: string) => ({
  type: SET_SELECTED_LICENSE_IDS,
  selectedLicenseId,
});

export function addLicense(payload: AddLicensePayload) {
  return function (dispatch: any) {
    dispatch({ type: ADD_NEW_LICENSE, data: true });
    api.addLicense(payload).then(
      (response: any) => {
        dispatch({ type: ADD_NEW_LICENSE_SUCCESS, data: response });
        dispatch(toggleModal(MODAL_NAME.ADD_LICENSE));
      },
      (error: any) => {
        dispatch({ type: ADD_NEW_LICENSE_FAILURE, addLicenseErrorMessage: error.response.data });
        console.log('An error occurred.', error);
      }
    );
  };
}

export function checkLicense(id: string, licenseId: string) {
  return function (dispatch: any) {
    dispatch({ type: SET_IS_LICENSE_BOUGHT, data: true });
    api.checkLicense(id, licenseId).then(
      (response: any) => {
        dispatch({ type: SET_IS_LICENSE_BOUGHT_SUCCESS, data: response });
      },
      (error: any) => {
        dispatch({ type: SET_IS_LICENSE_BOUGHT_FAILURE, data: false });
        console.log('An error occurred.', error);
      }
    );
  };
}

export const setPreviewPR = (previewPrintRequest: PrintRequest) => ({
  type: SET_PREVIEW_PRINT_REQUEST,
  previewPrintRequest,
});

export const setPreviewDR = (previewDesignRequest: DesignRequest) => ({
  type: SET_PREVIEW_DESIGN_REQUEST,
  previewDesignRequest,
});

export const setPreviewOFS = (previewObjectForSell: ObjectForSell) => ({
  type: SET_PREVIEW_OBJECT_FOR_SELL,
  previewObjectForSell,
});

export const setPreviewDFS = (previewDesignForSell: DesignForSell) => ({
  type: SET_PREVIEW_DESIGN_FOR_SELL,
  previewDesignForSell,
});

export const setSelectedDesignLicence = (license: License) => ({
  type: SET_SELECTED_VIEWED_DESIGN_LICENSE,
  license,
});

export function editLicense(payload: EditLicensePayload) {
  return function (dispatch: any) {
    dispatch({ type: EDIT_NEW_LICENSE, data: true });
    api.editLicense(payload).then(
      (response: any) => {
        dispatch({ type: EDIT_NEW_LICENSE_SUCCESS, data: response });
        dispatch(toggleModal(MODAL_NAME.EDIT_LICENSE));
      },
      (error: any) => {
        dispatch({ type: EDIT_NEW_LICENSE_FAILURE, data: false });
        console.log('An error occurred.', error);
      }
    );
  };
}

export const setLicenseDescription = (licenseDescription: string) => ({
  type: SET_LICENSE_DESCRIPTION,
  licenseDescription,
});

export const clearAddLicenseErrorMessage = () => ({
  type: CLEAR_ADD_LICENSE_ERROR_MESSAGE,
});

export const setCreateProductType = (createProductType: PRODUCT_TYPE) => ({
  type: SET_CREATE_PRODUCT_TYPE,
  createProductType,
});
