import React, { memo, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import * as S from './SliderSlice.styles';

const SliderSlice = ({
  // quantity = 0,
  title,
  title2,
  title3 = '',
  simpleSearch = false,
  children,
  query = '',
  link,
}: {
  title: string;
  title2: string;
  title3?: string;
  simpleSearch?: boolean;
  children: ReactNode;
  query?: string;
  link: string;
}) => (
  <S.Wrapper simpleSearch={simpleSearch}>
    <S.Header>
      <S.Title>
        <S.Bold>{title}</S.Bold>
        {title2}
      </S.Title>
      <Link to={`${link}${query}`}>
        <S.SubTitle>
          <S.Bold>Show all</S.Bold> {title3}
        </S.SubTitle>
      </Link>
    </S.Header>
    {children}
  </S.Wrapper>
);

export default memo(SliderSlice);
