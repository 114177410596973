import { AnyAction } from 'redux';
import {
  GET_SETTINGS_SUCCESS,
  TOGGLE_PHOTO_VIEW,
  TOGGLE_MODAL,
  SET_GALLERY_ID_FOR_EDIT,
  SET_SELECTED_DELIVERY_ADDRESS,
  SET_SELECTED_DEVICE_ID,
  LOGOUT_USER_SUCCESS,
  SET_NETWORK_ACTION_STATE,
  SET_MODAL_ELEMENT_ID,
  SET_MODAL_PRODUCT_TYPE,
  SET_MODAL_GALLERY_ID,
  CLEAR_MODAL_STATES,
  CLEAR_EXTERNAL_AUTH_STATES,
  CLEAR_ADDRESS_MODALS,
  SET_MODAL_OFFERS,
  SET_VIEWED_PRODUCT,
  ADD_NEW_LICENSE,
  ADD_NEW_LICENSE_SUCCESS,
  ADD_NEW_LICENSE_FAILURE,
  SET_IS_LICENSE_BOUGHT,
  SET_IS_LICENSE_BOUGHT_SUCCESS,
  SET_IS_LICENSE_BOUGHT_FAILURE,
  CLOSE_MODALS,
  SET_PREVIEW_PRINT_REQUEST,
  SET_PREVIEW_DESIGN_REQUEST,
  SET_PREVIEW_DESIGN_FOR_SELL,
  SET_PREVIEW_OBJECT_FOR_SELL,
  SET_SELECTED_LICENSE_IDS,
  SET_SELECTED_VIEWED_DESIGN_LICENSE,
  SET_DESIGN_OFFER_MORE_ACTIONS_ID,
  EDIT_NEW_LICENSE_FAILURE,
  EDIT_NEW_LICENSE,
  EDIT_NEW_LICENSE_SUCCESS,
  SET_MORE_ACTIONS_ID,
  SET_LICENSE_DESCRIPTION,
  CLEAR_ADD_LICENSE_ERROR_MESSAGE,
  SET_CREATE_PRODUCT_TYPE,
} from './actionTypes';
import {
  Settings,
  VIEW_TYPE,
  IModalState,
  ModalsProperties,
  MODAL_NAME,
  SelectedWinningOffer,
  SelectedDevice,
  initialModalActiveStates,
  ModalsClosedOnClick,
  ModalsClosedOnClickNames,
} from './models';
import { OFFER_WINNER_STEP } from '../../../models/common';
import { PRODUCT_TYPE } from '../../../utils/productType';
import { EmptyPrintRequest, PrintRequest } from '../printRequest/models';
import { DesignRequest, EmptyDesignRequest } from '../designRequest/models';
import { EmptyObjectForSell, ObjectForSell } from '../objectForSell/models';
import { DesignForSell, EmptyDesignForSell, License, EmptyLicense } from '../designForSell/models';

export interface UtilsState {
  persistModalOnRedirect: boolean;
  modalsActiveStates: IModalState[];
  modalsProperties: ModalsProperties;
  settings: Settings;
  photosViewerState: {
    viewType: VIEW_TYPE.BASIC;
  };
  galleryIdForEdit: string;
  isNewAddressError: boolean;
  selectedDevice: SelectedDevice;
  isNetworkActionLoading: boolean;
  isLoadingUtilsState: boolean;
  isProductPurchased: boolean;
  selectedWinningOffer: SelectedWinningOffer;
  isLicenseBought: boolean;
  previewPrintRequest: PrintRequest;
  previewDesignRequest: DesignRequest;
  previewObjectForSell: ObjectForSell;
  previewDesignForSell: DesignForSell;
  selectedLicenseIds: string[];
  selectedViewedDesignLicense: License;
  createProductType: PRODUCT_TYPE
}

const initialState: UtilsState = {
  persistModalOnRedirect: false,
  modalsActiveStates: initialModalActiveStates,
  modalsProperties: {
    elementId: '',
    productType: PRODUCT_TYPE.PRINT_REQUEST,
    galleryId: '',
    offers: [],
    viewedProduct: null,
    isAddingLicense: false,
    moreActionsId: '',
    designOfferMoreActionsId: '',
    isEditingLicense: false,
    licenseDescription: '',
    addLicenseErrorMessage: '',
  },
  settings: {
    sizes: [],
    currency: [],
    infills: [],
    technologies: [],
    temperatures: [],
    units: [],
    shippingMethods: [],
    materials: [],
    fileExtensions: [],
    software: [],
    distances: [],
    languages: [],
    timeZones: [],
    licenses: [],
    exchangeRates: [],
  },
  photosViewerState: {
    viewType: VIEW_TYPE.BASIC,
  },
  selectedDevice: {
    id: '',
    isPrinter: true,
  },
  galleryIdForEdit: '',
  isNewAddressError: false,
  isNetworkActionLoading: false,
  isLoadingUtilsState: false,
  isProductPurchased: false,
  selectedWinningOffer: {
    step: OFFER_WINNER_STEP.PICK_A_WINNER,
    id: '',
    comment: '',
  },
  isLicenseBought: false,
  previewDesignForSell: EmptyDesignForSell,
  previewDesignRequest: EmptyDesignRequest,
  previewObjectForSell: EmptyObjectForSell,
  previewPrintRequest: EmptyPrintRequest,
  selectedLicenseIds: [],
  selectedViewedDesignLicense: EmptyLicense,
  createProductType: PRODUCT_TYPE.PRINT_REQUEST
};

export default function (state = initialState, action: AnyAction): UtilsState {
  switch (action.type) {
    case LOGOUT_USER_SUCCESS: {
      return initialState;
    }
    case SET_GALLERY_ID_FOR_EDIT: {
      return {
        ...state,
        galleryIdForEdit: action.data,
      };
    }
    case TOGGLE_MODAL: {
      const { name } = action;
      const { modalsActiveStates } = state;
      const currentState = modalsActiveStates.find((t) => t.name === name);
      if (currentState === undefined) {
        return state;
      }
      const initialActiveStates = initialState.modalsActiveStates;
      return {
        ...state,
        modalsActiveStates: [
          ...initialActiveStates.filter((s) => s.name !== name),
          {
            name,
            isOpen: !currentState.isOpen,
          },
        ],
      };
    }
    case TOGGLE_PHOTO_VIEW: {
      return {
        ...state,
        photosViewerState: {
          ...state.photosViewerState,
          viewType: action.data,
        },
      };
    }
    case GET_SETTINGS_SUCCESS: {
      return {
        ...state,
        settings: action.data,
      };
    }
    case SET_SELECTED_DELIVERY_ADDRESS: {
      return {
        ...state,
        isNewAddressError: true,
      };
    }
    case SET_SELECTED_DEVICE_ID: {
      const { id, isPrinter } = action;
      return {
        ...state,
        selectedDevice: {
          id,
          isPrinter,
        },
      };
    }
    case SET_NETWORK_ACTION_STATE: {
      return {
        ...state,
        isNetworkActionLoading: action.data,
      };
    }
    case SET_MODAL_ELEMENT_ID: {
      const { elementId } = action;
      const { modalsProperties } = state;
      return {
        ...state,
        modalsProperties: { ...modalsProperties, elementId },
      };
    }
    case SET_MODAL_PRODUCT_TYPE: {
      const { productType } = action;
      const { modalsProperties } = state;
      return {
        ...state,
        modalsProperties: { ...modalsProperties, productType },
      };
    }
    case SET_MODAL_GALLERY_ID: {
      const { galleryId } = action;
      const { modalsProperties } = state;
      return {
        ...state,
        modalsProperties: { ...modalsProperties, galleryId },
      };
    }
    case CLEAR_MODAL_STATES: {
      return {
        ...state,
        modalsActiveStates: initialState.modalsActiveStates,
      };
    }
    case CLEAR_EXTERNAL_AUTH_STATES: {
      const initialActiveStates = initialState.modalsActiveStates;
      return {
        ...state,
        modalsActiveStates: [
          ...initialActiveStates.filter(
            (s) => s.name !== MODAL_NAME.LOGIN && s.name !== MODAL_NAME.SIGNUP
          ),
          { name: MODAL_NAME.SIGNUP, isOpen: false },
          { name: MODAL_NAME.LOGIN, isOpen: false },
        ],
      };
    }
    case CLEAR_ADDRESS_MODALS: {
      const initialActiveStates = initialState.modalsActiveStates;
      return {
        ...state,
        modalsActiveStates: [
          ...initialActiveStates.filter(
            (s) => s.name !== MODAL_NAME.DEFAULT_ADDRESS && s.name !== MODAL_NAME.DELIVERY_ADDRESS
          ),
          { name: MODAL_NAME.DEFAULT_ADDRESS, isOpen: false },
          { name: MODAL_NAME.DELIVERY_ADDRESS, isOpen: false },
        ],
      };
    }
    case SET_MODAL_OFFERS: {
      const { offers } = action;
      const { modalsProperties } = state;
      return {
        ...state,
        modalsProperties: { ...modalsProperties, offers },
      };
    }
    case SET_VIEWED_PRODUCT: {
      const { viewedProduct } = action;
      const { modalsProperties } = state;
      return {
        ...state,
        modalsProperties: { ...modalsProperties, viewedProduct },
      };
    }
    case ADD_NEW_LICENSE: {
      const { modalsProperties } = state;
      return {
        ...state,
        modalsProperties: { ...modalsProperties, isAddingLicense: true },
      };
    }
    case ADD_NEW_LICENSE_SUCCESS: {
      const licenses = action.data;
      const { modalsProperties, settings } = state;
      return {
        ...state,
        settings: { ...settings, licenses },
        modalsProperties: { ...modalsProperties, isAddingLicense: false },
      };
    }
    case ADD_NEW_LICENSE_FAILURE: {
      const { modalsProperties } = state;
      const { addLicenseErrorMessage } = action;
      return {
        ...state,
        modalsProperties: { ...modalsProperties, isAddingLicense: false, addLicenseErrorMessage },
      };
    }
    case SET_IS_LICENSE_BOUGHT: {
      return {
        ...state,
        isLoadingUtilsState: true,
      };
    }
    case SET_IS_LICENSE_BOUGHT_SUCCESS: {
      return {
        ...state,
        isLicenseBought: action.data,
        isLoadingUtilsState: false,
      };
    }
    case SET_IS_LICENSE_BOUGHT_FAILURE: {
      return {
        ...state,
        isLoadingUtilsState: false,
      };
    }
    case CLOSE_MODALS: {
      const { modalsActiveStates } = state;
      return {
        ...state,
        modalsActiveStates: [
          ...modalsActiveStates.filter((s) => !ModalsClosedOnClickNames.includes(s.name)),
          ...ModalsClosedOnClick,
        ],
      };
    }
    case SET_PREVIEW_PRINT_REQUEST: {
      const { previewPrintRequest } = action;
      return {
        ...state,
        previewPrintRequest,
      };
    }
    case SET_PREVIEW_DESIGN_REQUEST: {
      const { previewDesignRequest } = action;
      return {
        ...state,
        previewDesignRequest,
      };
    }
    case SET_PREVIEW_DESIGN_FOR_SELL: {
      const { previewDesignForSell } = action;
      return {
        ...state,
        previewDesignForSell,
      };
    }
    case SET_PREVIEW_OBJECT_FOR_SELL: {
      const { previewObjectForSell } = action;
      return {
        ...state,
        previewObjectForSell,
      };
    }
    case SET_SELECTED_LICENSE_IDS: {
      const { selectedLicenseId } = action;
      const { selectedLicenseIds } = state;
      const isLicenseSelected = selectedLicenseIds.includes(selectedLicenseId);
      if (isLicenseSelected) {
        const filteredIds = selectedLicenseIds.filter((id: string) => id !== selectedLicenseId);
        return {
          ...state,
          selectedLicenseIds: filteredIds,
        };
      } else {
        const newIds = [...selectedLicenseIds, selectedLicenseId];
        return {
          ...state,
          selectedLicenseIds: newIds,
        };
      }
    }
    case SET_SELECTED_VIEWED_DESIGN_LICENSE: {
      const { license } = action;
      return {
        ...state,
        selectedViewedDesignLicense: license,
      };
    }
    case SET_MORE_ACTIONS_ID: {
      const { moreActionsId } = action;
      const { modalsProperties } = state;
      return {
        ...state,
        modalsProperties: { ...modalsProperties, moreActionsId },
      };
    }
    case SET_DESIGN_OFFER_MORE_ACTIONS_ID: {
      const { designOfferMoreActionsId } = action;
      const { modalsProperties } = state;
      return {
        ...state,
        modalsProperties: { ...modalsProperties, designOfferMoreActionsId },
      };
    }
    case EDIT_NEW_LICENSE: {
      const { modalsProperties } = state;
      return {
        ...state,
        modalsProperties: { ...modalsProperties, isEditingLicense: true },
      };
    }
    case EDIT_NEW_LICENSE_SUCCESS: {
      const licenses = action.data;
      const { modalsProperties, settings } = state;
      return {
        ...state,
        settings: { ...settings, licenses },
        modalsProperties: { ...modalsProperties, isEditingLicense: false },
      };
    }
    case EDIT_NEW_LICENSE_FAILURE: {
      const { modalsProperties } = state;
      return {
        ...state,
        modalsProperties: { ...modalsProperties, isEditingLicense: false },
      };
    }
    case SET_LICENSE_DESCRIPTION: {
      const { modalsProperties } = state;
      const { licenseDescription } = action;
      return {
        ...state,
        modalsProperties: { ...modalsProperties, licenseDescription },
      };
    }
    case CLEAR_ADD_LICENSE_ERROR_MESSAGE: {
      const { modalsProperties } = state;
      return {
        ...state,
        modalsProperties: { ...modalsProperties, addLicenseErrorMessage: '' },
      };
    }
    case SET_CREATE_PRODUCT_TYPE: {
      const {createProductType} = action; 
      return {
        ...state,
        createProductType
      };
    }
    default:
      return state;
  }
}
