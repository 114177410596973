import React, { memo } from 'react';
import { useParams, useNavigate } from 'react-router';
import GalleriesSlider from './GalleriesSlider/GalleriesSlider';
import Slider from '../../Common/Slider/Slider';
import { SLIDER_TYPE } from '../../Dashboard/constant';
import DevicesMapper from '../Workshop/Printers/DevicesMapper';
import { useLoadingUserProfileData, useUserProfile } from '../../../redux/reducers/userProfiles';
import { UserAddress } from '../../../models/user';
import { Review } from '../../../models/reviews';
import SingleReview from '../Reviews/SingleReview/SingleReview';
import MapModal from '../../Modals/MapModal';
import StreetMap from '../../Common/StreetMap/StreetMap';
import { useDispatch } from 'react-redux';
import { PRODUCT_TYPE } from '../../../utils/productType';
import { linkDecorator } from '../../../utils/linkDecorator';
import Linkify from 'react-linkify';
import * as S from './About.styles';
import Loader from 'react-spinners/ClipLoader';
import EmptyGalleries from '../EmptyPanels/EmptyGalleries/EmptyGalleries';
import Emptymarket from '../EmptyPanels/EmptyMarket/Emptymarket';
import EmptyWorkshop from '../EmptyPanels/EmptyWorkshop/EmptyWorkshop';
import { toggleModal } from '../../../redux/reducers/utils/action';
import { MODAL_NAME } from '../../../redux/reducers/utils/models';

const About = () => {
  const dispatch = useDispatch();
  const params: any = useParams();
   const navigate = useNavigate();
  const { userId } = params;
  const link = `/profile/${userId}/`;
  const {
    market: { printRequests, printedObjects, objectDesigns, designsForSell },
    reviews,
    photos: { galleries = [] },
    workshop: { printers, other },
    about,
    addresses,
    isMapDisplayed,
  } = useUserProfile(userId);
  const isLoadingData = useLoadingUserProfileData();
  const principalAddress = addresses.find((address: UserAddress) => address.isDefault) || {
    city: '',
    country: '',
    lat: 0,
    lon: 0,
  };
  const { city, country, lat, lon } = principalAddress;
  const userGalleries = galleries ? galleries : [];
  const isWorkshopEmpty = printers && printers.length === 0 && other && other.length === 0;
  const hasReviews = reviews && reviews.length > 0;
  const isAddressLineShown = city || country;
  const isMapShown = isMapDisplayed && (lat > 0 || lon > 0);

  if (isLoadingData) {
    return <Loader />;
  }

  return (
    <S.Wrapper>
      <MapModal {...{ lat, lon }} />
      <S.DescriptionWrapper>
        <S.Description>
          {/* TODO */}
          {/* {about ? <Linkify componentDecorator={linkDecorator}>{about}</Linkify> : <S.EmptyAbout />} */}
        </S.Description>
      </S.DescriptionWrapper>
      <S.PhotosWrapper>
        <S.HeaderWrapper>
          <S.Header>Galleries</S.Header>
          <S.SecondaryHeader onClick={() => navigate(link + 'photos')} isClickable>
            <S.Bold>Show all</S.Bold> galleries ({userGalleries.length})
          </S.SecondaryHeader>
        </S.HeaderWrapper>
        {userGalleries.length > 0 ? (
          <GalleriesSlider galleries={userGalleries} userId={userId} />
        ) : (
          <EmptyGalleries />
        )}
      </S.PhotosWrapper>
      <S.MarketWrapper>
        <S.Header>Market</S.Header>
        {printRequests.length === 0 &&
          printedObjects.length === 0 &&
          objectDesigns.length === 0 && <Emptymarket />}
        {printRequests.length >= 1 && (
          <>
            <S.HeaderWrapper>
              <S.MarketHeader>
                <S.Bold>Print</S.Bold> requests
              </S.MarketHeader>
              <S.SecondaryHeader onClick={() => navigate(link + 'market')} isClickable>
                <S.Bold>Show all</S.Bold> print requests ({printRequests.length})
              </S.SecondaryHeader>
            </S.HeaderWrapper>
            <S.SliderWrapper>
              <Slider
                items={printRequests}
                sliderType={SLIDER_TYPE.MARKET}
                maxWidth={0.45}
                productType={PRODUCT_TYPE.PRINT_REQUEST}
              />
            </S.SliderWrapper>
          </>
        )}
        {printedObjects.length >= 1 && (
          <>
            <S.HeaderWrapper>
              <S.MarketHeader>
                <S.Bold>Printed objects</S.Bold> for sale
              </S.MarketHeader>
              <S.SecondaryHeader onClick={() => navigate(link + 'market')} isClickable>
                <S.Bold>Show all</S.Bold> printed objects ({printedObjects.length})
              </S.SecondaryHeader>
            </S.HeaderWrapper>
            <S.SliderWrapper>
              <Slider
                items={printedObjects}
                sliderType={SLIDER_TYPE.MARKET}
                maxWidth={0.45}
                productType={PRODUCT_TYPE.OBJECT_FOR_SELL}
              />
            </S.SliderWrapper>
          </>
        )}
        {objectDesigns.length >= 1 && (
          <>
            <S.HeaderWrapper>
              <S.MarketHeader>
                <S.Bold>Design</S.Bold> requests
              </S.MarketHeader>
              <S.SecondaryHeader onClick={() => navigate(link + 'market')} isClickable>
                <S.Bold>Show all</S.Bold> design requests ({objectDesigns.length})
              </S.SecondaryHeader>
            </S.HeaderWrapper>
            <S.SliderWrapper>
              <Slider
                items={objectDesigns}
                sliderType={SLIDER_TYPE.MARKET}
                maxWidth={0.45}
                productType={PRODUCT_TYPE.DESIGN_REQUEST}
              />
            </S.SliderWrapper>
          </>
        )}
        {designsForSell.length >= 1 && (
          <>
            <S.HeaderWrapper>
              <S.MarketHeader>
                <S.Bold>Designs</S.Bold> for sale
              </S.MarketHeader>
              <S.SecondaryHeader onClick={() => navigate(link + 'market')} isClickable>
                <S.Bold>Show all</S.Bold> designs for sale ({designsForSell.length})
              </S.SecondaryHeader>
            </S.HeaderWrapper>
            <S.SliderWrapper>
              <Slider
                items={designsForSell}
                sliderType={SLIDER_TYPE.MARKET}
                maxWidth={0.45}
                productType={PRODUCT_TYPE.DESIGN_FOR_SELL}
              />
            </S.SliderWrapper>
          </>
        )}
      </S.MarketWrapper>
      <S.WorkshopWrapper>
        <S.Header>Workshop</S.Header>
        {isWorkshopEmpty && <EmptyWorkshop />}
        {printers.length >= 1 && (
          <>
            <S.HeaderWrapper>
              <S.SecondaryHeader>
                <b>3D printers ({printers.length})</b>
              </S.SecondaryHeader>
            </S.HeaderWrapper>
            <DevicesMapper devices={printers} />
          </>
        )}
        {other.length >= 1 && (
          <>
            <S.HeaderWrapper>
              <S.SecondaryHeader>
                <b>Other ({other.length})</b>
              </S.SecondaryHeader>
            </S.HeaderWrapper>
            <DevicesMapper devices={other} />
          </>
        )}
      </S.WorkshopWrapper>
      {isMapShown && (
        <S.LocationWrapper>
          <S.Header>Location</S.Header>
          <S.AddressLine>
            <b>
              {city}
              {country && `, ${country}`}
            </b>
          </S.AddressLine>
          {
            <>
              <S.MapWrapper>
                <StreetMap coordinates={[lat, lon]} />
              </S.MapWrapper>
              <S.FullMap onClick={() => dispatch(toggleModal(MODAL_NAME.MAP_ENLARGED))}>
                Show full map
              </S.FullMap>
            </>
          }
        </S.LocationWrapper>
      )}
      {hasReviews && (
        <S.ReviewsWrapper>
          <S.HeaderWrapper>
            <S.Header>Reviews</S.Header>
            <S.ShadowHeader>( {reviews.length} reviews )</S.ShadowHeader>
            <S.SecondaryHeader onClick={() => navigate(link + 'reviews')} isClickable>
              <S.Bold>Show all</S.Bold> reviews
            </S.SecondaryHeader>
          </S.HeaderWrapper>
          <>
            {reviews &&
              reviews.map((review: Review) => <SingleReview key={review.id} {...review} />)}
          </>
        </S.ReviewsWrapper>
      )}
    </S.Wrapper>
  );
};

export default memo(About);
