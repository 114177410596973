import { AnyAction } from 'redux';
import {
  TOGGLE_ITEM_TO_SEARCH,
  SET_SIMPLE_SEARCH_PARAMS,
  SET_LASTLY_SEARCHED,
} from '../actionTypes';
import { ITEM_TYPE } from '../../helpers/definitions/interfaces';

export interface SearchOldState {
  toggledSearchItems: {
    prints: boolean;
    objects: boolean;
    designs: boolean;
    designsFS: boolean;
  };
  query: string;
  lastlySearched: string;
}

const initialState = {
  toggledSearchItems: {
    prints: true,
    objects: true,
    designs: true,
    designsFS: true,
  },
  query: '',
  lastlySearched: '',
};

export default function (state = initialState, action: AnyAction): SearchOldState {
  switch (action.type) {
    case TOGGLE_ITEM_TO_SEARCH: {
      const itemType = action.data as ITEM_TYPE;
      if (itemType === ITEM_TYPE.ALL) {
        return {
          ...state,
          toggledSearchItems: {
            prints: false,
            objects: false,
            designs: false,
            designsFS: false,
          },
        };
      } else {
        const isActive = state.toggledSearchItems[itemType];
        return {
          ...state,
          toggledSearchItems: {
            ...state.toggledSearchItems,
            [itemType]: !isActive,
          },
        };
      }
    }
    case SET_SIMPLE_SEARCH_PARAMS: {
      const { query, prints, objects, designs, designsFS } = action.data;
      return {
        ...state,
        query,
        toggledSearchItems: {
          prints,
          objects,
          designs,
          designsFS,
        },
      };
    }
    case SET_LASTLY_SEARCHED: {
      return {
        ...state,
        lastlySearched: action.data,
      };
    }
    default:
      return state;
  }
}
