import styled from 'styled-components';
import U from '../../Assets/commonCSS';

export const LocationSectionWrapper = styled.div`
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 48px;
  margin-bottom: 20px;
`;

export const SectionTitle = styled.div`
  font-size: 24px;
  color: #4D4D4D;
  font-weight: 900;
  margin-bottom: 18px;
`;

export const LocationItem = styled.div`
  height: 19px;
  font-size: 16px;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 844px;
  width: 100%;
  height: 22px;
`;

export const MapWrapper = styled.div`
  max-width: 844px;
  width: 100%;
  height: 475px;
  background-color: #e2e2e2;
  margin-bottom: 18px;
  overflow: hidden;
  z-index: ${U.zIndex1};
  @media (max-width: 900px) {
    width: 95vw;
    height: 60vw;
  }
`;

export const FullMap = styled.div`
  text-align: right;
  text-decoration: underline;
  font-size: 14px;
  width: 100%;
  letter-spacing: 0.14px;
  color: #4D4D4D;
  cursor: pointer;
`;
