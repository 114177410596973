import { AnyAction } from 'redux';
import { Purchase } from './models';
import {
  GET_PRODUCT_PURCHASES,
  GET_PRODUCT_PURCHASES_SUCCESS,
  GET_PRODUCT_PURCHASES_FAILURE,
  GET_PURCHASE,
  GET_PURCHASE_SUCCESS,
  GET_PURCHASE_FAILURE,
  SET_IS_PURCHASE_OWNER,
  GET_MY_SHOPPING,
  GET_MY_SHOPPING_FAILURE,
} from './actionTypes';
import { GET_MY_SHOPPING_SUCCESS } from '../../actionTypes';
import { ADD_OFS_PACKAGE_SUCCESS, UPDATE_OFS_PACKAGES_SUCCESS } from '../objectForSell/actionTypes';
import { EmptyDesignForSell } from '../designForSell/models';
import { EmptyObjectForSell } from '../objectForSell/models';
import {
  UPLOAD_DFS_FILES,
  UPLOAD_DFS_FILES_FAILURE,
  UPLOAD_DFS_FILES_SUCCESS,
} from '../designForSell/actionTypes';
import { tr } from 'date-fns/locale';

export interface PurchaseState {
  currentPurchase: Purchase | null;
  productPurchases: Purchase[];
  isLoadingCurrentPurchase: boolean;
  isLoadingProductPurchases: boolean;
  isPurchaseOwner: boolean;
  isUploadingDfsFiles: boolean;
}

const initialState: PurchaseState = {
  currentPurchase: null,
  productPurchases: [],
  isLoadingCurrentPurchase: false,
  isLoadingProductPurchases: false,
  isPurchaseOwner: false,
  isUploadingDfsFiles: false,
};
export default function (state = initialState, action: AnyAction): PurchaseState {
  switch (action.type) {
    case GET_PRODUCT_PURCHASES: {
      return {
        ...state,
        isLoadingProductPurchases: true,
      };
    }
    case GET_PRODUCT_PURCHASES_SUCCESS: {
      return {
        ...state,
        isLoadingProductPurchases: false,
        productPurchases: action.data,
      };
    }
    case GET_PRODUCT_PURCHASES_FAILURE: {
      return {
        ...state,
        isLoadingProductPurchases: false,
      };
    }
    case GET_PURCHASE: {
      return {
        ...state,
        isLoadingCurrentPurchase: true,
      };
    }
    case GET_PURCHASE_SUCCESS: {
      const { designForSell, objectForSell } = action.data;
      if (!designForSell) {
        return {
          ...state,
          isLoadingCurrentPurchase: false,
          currentPurchase: { ...action.data, designForSell: EmptyDesignForSell },
        };
      } else if (!objectForSell) {
        return {
          ...state,
          isLoadingCurrentPurchase: false,
          currentPurchase: { ...action.data, objectForSell: EmptyObjectForSell },
        };
      } else {
        return { ...state };
      }
    }
    case GET_PURCHASE_FAILURE: {
      return {
        ...state,
        isLoadingCurrentPurchase: false,
      };
    }
    case SET_IS_PURCHASE_OWNER: {
      return {
        ...state,
        isPurchaseOwner: action.data,
      };
    }
    case GET_MY_SHOPPING: {
      return {
        ...state,
        isLoadingProductPurchases: true,
      };
    }
    case GET_MY_SHOPPING_SUCCESS: {
      return {
        ...state,
        isLoadingProductPurchases: false,
        productPurchases: action.data,
      };
    }
    case GET_MY_SHOPPING_FAILURE: {
      return {
        ...state,
        isLoadingProductPurchases: false,
      };
    }
    case ADD_OFS_PACKAGE_SUCCESS: {
      const { packages } = action;
      const { currentPurchase } = state;
      if (!currentPurchase) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        currentPurchase: { ...currentPurchase, packages },
      };
    }
    case UPDATE_OFS_PACKAGES_SUCCESS: {
      const { packages } = action;
      const { currentPurchase } = state;
      if (!currentPurchase) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        currentPurchase: { ...currentPurchase, packages },
      };
    }
    case UPLOAD_DFS_FILES: {
      return {
        ...state,
        isLoadingProductPurchases: true,
        isUploadingDfsFiles: true,
      };
    }
    case UPLOAD_DFS_FILES_SUCCESS: {
      const { files } = action;
      const { currentPurchase } = state;
      if (!currentPurchase) {
        return { ...state };
      }
      return {
        ...state,
        isLoadingProductPurchases: false,
        isUploadingDfsFiles: false,
        currentPurchase: { ...currentPurchase, files },
      };
    }
    case UPLOAD_DFS_FILES_FAILURE: {
      return {
        ...state,
        isLoadingProductPurchases: false,
        isUploadingDfsFiles: false,
      };
    }
    default:
      return state;
  }
}
