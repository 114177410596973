import { AppState } from '..';
import { PRODUCT_TYPE } from '../../../utils/productType';

export const searchState = (state: AppState) => state.search;

export const isLoadingShortItems = (state: AppState) => searchState(state).isLoadingShort;

export const isLoadingExtendedItems = (state: AppState) => searchState(state).isLoadingShort;

export const shortItems = (state: AppState) => searchState(state).shortItems;

export const shortDesignRequests = (state: AppState) => shortItems(state).designRequests;

export const shortDesignsForSell = (state: AppState) => shortItems(state).designsForSell;

export const shortPrintRequests = (state: AppState) => shortItems(state).printRequests;

export const shortObjectsForSell = (state: AppState) => shortItems(state).objectsForSell;

export const extendedItems = (state: AppState) => searchState(state).extendedItems;

export const extendedDesignRequests = (state: AppState) => extendedItems(state).designRequests;

export const extendedDesignsForSell = (state: AppState) => extendedItems(state).designsForSell;

export const extendedPrintRequests = (state: AppState) => extendedItems(state).printRequests;

export const extendedObjectsForSell = (state: AppState) => extendedItems(state).objectsForSell;

export const lastlySearched = (state: AppState) => searchState(state).lastlySearched;

export const searchQuery = (state: AppState) => searchState(state).query;

export const toggledItems = (state: AppState) => searchState(state).toggledSearchItems;

export const extendedItemsToShow = (productType: PRODUCT_TYPE) => (state: AppState) => {
  switch (productType) {
    case PRODUCT_TYPE.PRINT_REQUEST:
      return extendedPrintRequests(state);
    case PRODUCT_TYPE.DESIGN_REQUEST:
      return extendedDesignRequests(state);
    case PRODUCT_TYPE.DESIGN_FOR_SELL:
      return extendedDesignsForSell(state);
    case PRODUCT_TYPE.OBJECT_FOR_SELL:
      return extendedObjectsForSell(state);
    default:
      return [];
  }
};
