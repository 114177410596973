import React, { memo } from 'react';
import * as S from './TableOfContents..styles';

interface IHeaderItemOfItem {
  title: string;
  body?: string;
}

interface IHeaderItem {
  title: string;
  body?: string;
  items?: IHeaderItemOfItem[];
}
export interface IHeaders {
  title: string;
  body?: string;
  items?: IHeaderItem[];
}
export interface ITableOfContents {
  headers: IHeaders[];
}
const scrollWithOffset = (el: any, offset: any) => {
  const elementPosition = el.offsetTop - offset;
  window.scroll({
    top: elementPosition,
    left: 0,
    // behavior: "smooth"
  });
}


const TableOfContents = ({ headers }: ITableOfContents) => {
  return (
    <S.ToCWrapper>
      <S.ToCHeader>Table of Contents</S.ToCHeader>
      <S.ToCContainer>
        {headers.map((header, index) => (
          <S.ListItem key={index + 1} hasItems={header.items && header.items.length > 0}>
            <S.ListItemContainer>
              <S.TableNumber>{index + 1}.</S.TableNumber>
              <S.ToCTitle scroll={(el)=>scrollWithOffset(el, 108)} to={`#${header.title}`}>{header.title}</S.ToCTitle>
            </S.ListItemContainer> 
            {header.items && header.items.length > 0 && (
              <S.StyledUl>
                {header.items.map((child, childIndex) => (
                  <S.SubListItem key={childIndex + 1}>
                    <S.ListItemContainer>
                      <S.SubTableNumber>
                        {index + 1}.{childIndex + 1}
                      </S.SubTableNumber>
                      <S.ToCSubtitle to={`#${child.title}`}>{child.title}</S.ToCSubtitle>
                    </S.ListItemContainer>
                    {child.items && child.items.length > 0 && (
                      <S.StyledUl>
                        {child.items.map((childOfChild, childOfChildIndex) => (
                          <S.SubSubListItem key={childOfChildIndex + 1}>
                            <S.ListItemContainer>
                              <S.SubTableNumber>
                                {index + 1}.{childIndex + 1}.{childOfChildIndex + 1}
                              </S.SubTableNumber>
                              <S.ToCSubtitle to={`#${childOfChild.title}`}>
                                {childOfChild.title}
                              </S.ToCSubtitle>
                            </S.ListItemContainer>
                          </S.SubSubListItem>
                        ))}
                      </S.StyledUl>
                    )}
                  </S.SubListItem>
                ))}
              </S.StyledUl>
            )}
          </S.ListItem>
        ))}
      </S.ToCContainer>
    </S.ToCWrapper>
  );
};

export default memo(TableOfContents);
