import { IoMdTrash } from 'react-icons/io';
import { MdRefresh } from 'react-icons/md';
import styled from 'styled-components';
import themes from '../../../Assets/colorThemes';

export const Head = styled.h1`
  width: 250px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding-bottom: 4px;
  margin: 0;
  border-bottom: 3px solid #707070;
`;

export const SubHeader = styled.div`
  margin: 12px 0px;
  font-size: 12px;
  font-weight: 500;
`;

export const Wrapper = styled.div`
  padding: 0 16px;
  max-width: 952px;
  width: 100%;
  box-sizing: border-box;
  min-height: 89vh;
`;

export const Table = styled.table`
  width: 100%;
`;

export const TableHeadCell = styled.td`
  padding: 8px;
  background-color: ${themes.default.grayDf};
  color: ${themes.default.black0};
  font-size: 16px;
  font-weight: 700;
`;

export const TableRow = styled.tr<{ count: number; isArchived: boolean }>`
  ${(props: any) => +props.count % 2 === 1 && `background-color: ${themes.default.grayE}`};
  ${(props: any) => props.isArchived && `background-color: rgb(240, 0, 0, 0.4)`};
`;

export const TableCell = styled.td`
  padding: 4px;
  color: ${themes.default.black0};
  font-size: 14px;
  font-weight: 500;
`;

const iconPros = `
width: 24px;
height: 24px;
opacity: 0.4;
:hover {
  opacity: 1;
}
`;

export const RemoveIcon = styled(IoMdTrash)`
  ${iconPros}
  color: ${themes.default.red};
`;

export const ResurrectIcon = styled(MdRefresh)`
  ${iconPros}
  color: ${themes.default.green};
`;

export const PlainButton = styled.button`
  border: none;
  cursor: pointer;
  background: transparent;
  :focus {
    outline: none;
  }
`;
