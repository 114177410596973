import styled from 'styled-components';

export const ExternalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div`
  max-width: 452px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const TopWrapper = styled.div`
  display: flex;
  padding: 24px 13px 11px 20px;
  border-bottom: 1px solid #dbdbdb;
`;

export const Title = styled.div`
  display: flex;
  font-size: 25px;
`;

export const BoldTitle = styled.div`
  font-weight: 700;
  margin-right: 5px;
`;

export const FormWrapper = styled.div`
  flex-grow: 1;
  letter-spacing: 0.7px;
  padding: 23px 20px 0;
`;

export const Label = styled.div`
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 14px;
`;

export const Input = styled.input`
  height: 40px;
  background: #ffffff;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 17px;
  padding: 0 14px;
  font-size: 14px;
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px #0077df;
  }
`;

export const LoginButton = styled.div`
  background-color: #0077df;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  max-width: 411px;
  width: 100%;
  height: 48px;
  margin-top: 6px;
  font-size: 14px;
  letter-spacing: 0.6px;
  margin-bottom: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 16px;
`;

export const LoginButtonWithIcon = styled(LoginButton)`
  margin-bottom: 24px;
`;

export const Forgot = styled.div`
  font-size: 14px;
  text-decoration: underline;
  color: #4D4D4D;
  margin-bottom: 28px;
  cursor: pointer;
`;

export const LoginOptions = styled.div`
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  padding-top: 16px;
  padding-bottom: 4px;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const Or = styled.div`
  position: absolute;
  font-size: 14px;
  top: -10px;
  display: flex;
  justify-content: center;
  background-color: white;
  width: 40px;
  color: #bfbfbf;
  font-weight: 700;
`;

export const BottomWrapper = styled.div`
  display: flex;
  height: 54px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #4D4D4D;
  padding: 0 16px;
`;

export const Join = styled.div`
  display: flex;
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer;
`;

export const errorMessage = styled.div``;

export const BoldJoin = styled.div`
  font-weight: 600;
  margin-right: 5px;
`;

export const iconLarge = {
  width: '32px',
  height: '32px',
  marginRight: '10px',
  color: '#9A9A9A',
};

export const iconSmall = {
  width: '26px',
  height: '26px',
  marginRight: '10px',
  color: '#9A9A9A',
};

export const RequirementsWrapper = styled.div`
margin-bottom: 20px;`

export const RequirementsList = styled.ul``

export const Requirement = styled.div``