import React, { memo } from 'react';
import { GalleryWithPhotos } from '../../../../models/photos';
import Menu from './Menu/Menu';
import moment from 'moment';
import I3dDarkLogo from '../../../../images/logo_dark.png';
import { useDispatch, useSelector } from 'react-redux';
import { setEditGalleryId } from '../../../../redux/actions';
import { Link } from 'react-router-dom';
import * as S from './GalleryIcon.style';
import { isModalOpen, modalElementId } from '../../../../redux/reducers/utils/selectors';
import { MODAL_NAME } from '../../../../redux/reducers/utils/models';
import {
  toggleModal,
  setModalElementId,
  setModalGalleryId,
} from '../../../../redux/reducers/utils/action';

interface GalleryIconProps extends GalleryWithPhotos {
  userId: string;
  displayMenu?: boolean;
}

const GalleryIcon = ({
  id,
  title,
  quantity,
  createdAt,
  userId,
  displayMenu = false,
  photos,
}: GalleryIconProps) => {
  const source = photos.length > 0 ? photos[0].image : I3dDarkLogo;
  const dispatch = useDispatch();
  const photoMenu = useSelector(isModalOpen(MODAL_NAME.PHOTO_MENU));
  const elementId = useSelector(modalElementId);

  const handleMenuVisibility = () => {
    dispatch(toggleModal(MODAL_NAME.PHOTO_MENU));
    if (elementId === id) {
      return dispatch(setModalElementId(elementId));
    }
    return dispatch(setModalElementId(id));
  };

  const localHandleRemove = (event: any) => {
    handleMenuVisibility();
    dispatch(toggleModal(MODAL_NAME.DELETE_GALLERY));
    dispatch(setModalGalleryId(id));
    // dispatch(deleteGallery(id, userId));
    event.preventDefault();
  };

  const localHandleEdit = (event: any) => {
    handleMenuVisibility();
    dispatch(setEditGalleryId(id));
    dispatch(toggleModal(MODAL_NAME.ADD_GALLERY));
    event.preventDefault();
  };

  const displayPhotoMenu = () => {
    if (id === elementId && photoMenu) {
      return (
        <Menu
          styles={'top: -38px; left: 8px;'}
          remove={(e: any) => localHandleRemove(e)}
          edit={(e: any) => localHandleEdit(e)}
        />
      );
    }
  };

  return (
    <S.Container>
      <Link style={S.getWrapperStyle()} to={`/profile/${userId}/gallery/${id}`}>
        <S.BackgroundPhoto src={source} />
        {displayMenu && <S.MenuButton onClick={handleMenuVisibility} />}
        {displayPhotoMenu()}
        <S.TextWrapper>
          <S.Title>
            {title} {quantity > 0 && `(${quantity})`}
          </S.Title>
          <S.CreatedAt>{moment(createdAt).format('DD MMM YYYY, HH:mm')}</S.CreatedAt>
        </S.TextWrapper>
      </Link>
    </S.Container>
  );
};

export default memo(GalleryIcon);
