import styled from 'styled-components';
import { RiErrorWarningLine } from 'react-icons/ri';
import { BsEye, BsEyeSlash } from 'react-icons/bs';

export const ExternalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  height: 100%;
  max-height: 630px;
  max-width: 452px;
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const TopWrapper = styled.div`
  display: flex;
  padding: 24px 13px 11px 20px;
  border-bottom: 1px solid #dbdbdb;
`;

export const Title = styled.div`
  display: flex;
  font-size: 25px;
`;

export const BoldTitle = styled.div`
  font-weight: 700;
  margin-right: 5px;
`;

export const FormWrapper = styled.form`
  flex-grow: 1;
  letter-spacing: 0.7px;
  padding: 23px 20px 0;
  height: 100%;
  overflow: auto;
`;

export const Label = styled.div<{ isError: boolean }>`
  font-weight: 600;
  margin-bottom: 4px;
  font-size: 14px;
  ${(props: any) =>
    props.isError &&
    `
  color: #DB0000;
  `}
`;

export const InputFieldWrapper = styled.div<{ isError?: boolean }>`
  height: 41px;
  background: #ffffff;
  border-radius: 4px;
  ${(props: any) => (props.isError ? 'border: 1px solid #DB0000;' : 'border: 1px solid #333;')};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 17px;
`;

export const WarningIcon = styled(RiErrorWarningLine)`
  height: 75%;
  width: auto;
  color: #db0000;
  margin-right: 10px;
`;

export const Input = styled.input`
  border: none;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 0 18px;
  font-size: 16px;
  :focus {
    outline: none;
  }
`;

export const LoginButton = styled.div`
  background: #0077df;
  border: 1px solid #bfbfbf;
  box-shadow: 0px 3px 6px #0077df4d;
  border-radius: 4px;
  width: 100%;
  height: 48px;
  margin-top: 6px;
  font-size: 14px;
  letter-spacing: 0.6px;
  margin-bottom: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 16px;
  padding: 0;
`;

export const FacebookButton = styled(LoginButton)`
  background: #e2e2e2;
  box-shadow: 0px 3px 6px #00000029;
  color: #333;
`;

export const LoginButtonWithIcon = styled(LoginButton)`
  margin-bottom: 24px;
`;

export const Forgot = styled.div`
  font-size: 14px;
  text-decoration: underline;
  color: #4D4D4D;
  margin-bottom: 28px;
  cursor: pointer;
`;

export const LoginOptions = styled.div`
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  padding-top: 29px;
  padding-bottom: 19px;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const Or = styled.div`
  position: absolute;
  font-size: 14px;
  top: -10px;
  display: flex;
  justify-content: center;
  background-color: white;
  width: 40px;
  color: #bfbfbf;
  font-weight: 700;
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 25px 20px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #4D4D4D;
`;

export const Join = styled.div`
  display: flex;
  margin-left: 5px;
  cursor: pointer;
  border-bottom: 1px solid black;
`;

export const ErrorMessage = styled.div`
  margin-bottom: 10px;
  color: #db0000;
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  height: 20px;
  min-width: 4px;
`;

export const BoldJoin = styled.div`
  font-weight: 600;
  margin-right: 5px;
`;

export const iconLarge = {
  width: '32px',
  height: '32px',
  marginRight: '10px',
  color: '#9A9A9A',
};

export const iconSmall = {
  width: '26px',
  height: '26px',
  marginRight: '10px',
  color: '#9A9A9A',
};

export const ExternalLoginWrapper = styled.div`
  width: 100%;
`;

export const Icon = `
  min-width: 24px;
  height: 24px;
  color: #333333;
  cursor: pointer;
`;

export const Eye = styled(BsEye)`
  ${Icon}
`;

export const EyeBlind = styled(BsEyeSlash)`
  ${Icon}
`;

export const PlainButton = styled.button`
  background-color: transparent;
  border: none;
  :focus {
    outline: none;
  }
`;
