import axios from 'axios';
import {
  UserData,
  UserCredentials,
  IAccountConfirmationParams,
} from '../helpers/definitions/interfaces';
import {
  UserDataFormPayload,
  CreateDeliveryAddressPayload,
  UserAddress,
  UserProfileRequestPayload,
  AddOfferPayload,
  DeliveryOptionProps,
  AddOffer,
} from '../models/user';
import { SimpleSearchPayload, AdvancedSearchPayload } from '../models/search';
import { GetGeoDataPayload } from '../models/printRequest';
import { PRODUCT_TYPE } from '../utils/productType';
import { emptyUser } from '../redux/reducers/auth/auth';
import { PACKAGE_STATUS } from '../models/package';
import { AddLicensePayload, SearchRequirements, EditLicensePayload } from '../models/common';
import { ReviewSellerPayload } from '../redux/actions';
const PORT = process.env.REACT_APP_SERVER_PORT ? `:${process.env.REACT_APP_SERVER_PORT}` : '';
const BASE_URL = process.env.REACT_APP_SERVER_IP
  ? `${process.env.REACT_APP_SERVER_IP}${PORT}/api/v1`
  : 'http://localhost:80/api/v1';
const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

axios.defaults.withCredentials = true;

const handleUserAddressSelectors = (addresses: UserAddress[]) => {
  const result = addresses.map((address: UserAddress) => {
    address.isSelected = address.isDefault;
    return address;
  });
  return result;
};

export const registerUser = async (params: UserData): Promise<any> => {
  const res = await instance.post(`/user`, { ...params });
  res.data.user.addresses = handleUserAddressSelectors(res.data.user.addresses);
  return res.data;
};

export const login = async (params: UserCredentials): Promise<any> => {
  const res = await instance.post(`/auth`, { ...params }, { withCredentials: true });
  res.data.user.addresses = handleUserAddressSelectors(res.data.user.addresses);
  return res.data;
};

export const confirmRegistration = async ({ token }: IAccountConfirmationParams): Promise<any> => {
  const res = await instance.post(`/user/confirmation/${token}`);
  return res.data;
};

interface SimpleAuthCredentials {
  password: string;
  user: string;
}

export const sendSimpleAuthCredentials = async (params: SimpleAuthCredentials) => {
  const res = await instance.post(`/auth/simple`, { ...params }, { withCredentials: true });
  return res.data;
};

export const loginWithFacebook = async (params: any): Promise<any> => {
  const res = await instance.post(`/auth/facebook`, { ...params }, { withCredentials: true });
  res.data.user.addresses = handleUserAddressSelectors(res.data.user.addresses);
  return res.data;
};

export const loginWithGoogle = async (params: any): Promise<any> => {
  const res = await instance.post(`/auth/google`, { ...params }, { withCredentials: true });
  res.data.user.addresses = handleUserAddressSelectors(res.data.user.addresses);
  return res.data;
};

export const logout = async (): Promise<any> => {
  const res = await instance.delete(`/auth`, { withCredentials: true });
  return res.data;
};

// const checkFbLoginState = async () => {
//   const globalWindow = window as any;
//   if (!globalWindow.FB) {
//     return;
//   }
//   const response = await new Promise<any>(async (resolve) => {
//     globalWindow.FB.getLoginStatus((response: any) => {
//       resolve(response);
//     });
//   });
//   if (response && response.status === 'connected') {
//     await instance.post(`/auth/facebook`, { ...response });
//   }
// };

export const checkLoggedIn = async () => {
  try {
    const res = await instance.get(`/auth`);
    const preloadedState = {
      auth: {
        user: {
          ...emptyUser,
          ...res.data.user,
          addresses: handleUserAddressSelectors(res.data.user.addresses),
        },
        isLoggedIn: true,
      },
    };
    return preloadedState;
  } catch (e) {
    // await checkFbLoginState();
    const preloadedState = {
      auth: {
        isLoggedIn: false,
      },
    };
    return preloadedState;
  }
};

export const createPrintRequest = async (formData: any): Promise<any> => {
  const res = await instance.post(`/product`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export interface PublishPayload {
  id: string;
  data: null;
  productAction: PRODUCT_ACTION;
}

export const publishProduct = async (params: PublishPayload): Promise<any> => {
  const res = await instance.post(`/product/publish`, { ...params });
  return res.data;
};

export const uploadUserAvatar = async (formData: any): Promise<any> => {
  const res = await instance.post(`/user/avatar`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const callUserDashboard = async (params: {
  query?: string;
  sortBy?: string;
  limit?: number;
  offset?: number;
  flag: string;
}): Promise<any> => {
  const res = await axios.get(`${BASE_URL}/dashboard`, {
    params: { ...params },
    withCredentials: true,
  });
  return res.data;
};

export const getSettings = async (): Promise<any> => {
  const res = await instance.get(`/settings`);
  return res.data;
};

export const getPrintRequestDetails = async (
  id: string,
  productType: string,
  fetchPackages = false
): Promise<any> => {
  const res = await instance.get(`/product`, { ...{ params: { id, productType, fetchPackages } } });
  return res.data;
};

export const updateUserData = async (params: UserDataFormPayload): Promise<any> => {
  const res = await instance.patch(`/user/`, { ...params }, { withCredentials: true });
  return res.data;
};

export const createDeliveryAddress = async (params: CreateDeliveryAddressPayload): Promise<any> => {
  const res = await instance.post(`/user/address`, { ...params }, { withCredentials: true });
  return res.data;
};

export const getUserProfile = async (params: UserProfileRequestPayload): Promise<any> => {
  const res = await instance.get(`/user/profile`, { params: { ...params } });
  return res.data;
};

export const addUserPrinter = async (formData: any): Promise<any> => {
  const res = await instance.post(`/user/workshop`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const addOffer = async (params: AddOfferPayload): Promise<any> => {
  const res = await instance.post(`/offers/`, { ...params });
  return res.data;
};

export const pickOffer = async (params: {
  offerId: string;
  responseMessage?: string;
}): Promise<any> => {
  const res = await instance.post(`/offers/pick`, { ...params });
  return res.data;
};

export const rejectOffer = async (params: { offerId: string }): Promise<any> => {
  const res = await instance.post(`/offers/reject`, { ...params });
  return res.data;
};

// export const getSimpleSearchResults = async (params: SimpleSearchPayload): Promise<any> => {
//   const res = await instance.get(`/search`, { params: { ...params } });
//   return res.data;
// };

export const getUsersQueries = async (params: {
  filterFlag: 'RECENT' | 'MATCH';
  query?: string;
  symbols?: string[];
}): Promise<any> => {
  const res = await instance.get(`/user/queries/`, { params: { ...params } });
  return res.data;
};

export const deleteUsersQuery = async (params: { id: string }): Promise<any> => {
  const res = await instance.delete(`/user/queries/`, { params: { id: params.id } });
  return res.data;
};

export const getAdvancedSearch = async (params: AdvancedSearchPayload): Promise<any> => {
  params.query = params.query ? params.query : '';
  const res = await instance.post('/advanced-search/print-requests', { params: params });
  return res.data;
};

export const getGeoData = async (params: GetGeoDataPayload): Promise<any> => {
  let url = new URL('https://nominatim.openstreetmap.org/search');
  url.searchParams.append('format', 'json');
  // @ts-ignore
  Object.keys(params).forEach((key: string) => url.searchParams.append(key, params[key]));
  const res = await fetch(url.href);
  return res.json();
};

export const createPayment = async (
  id: string,
  email: string,
  quantity?: string,
  addressId?: string,
  deliveryTypeId?: string
): Promise<any> => {
  const res = await instance.post('/payment', {
    params: { id, email, quantity, addressId, deliveryTypeId },
  });
  return res.data;
};

export const cancelOffer = async (params: { id: string }): Promise<any> => {
  const res = await instance.delete(`/offers`, { params: { ...params } });
  return res.data;
};

export const getAddressById = async (id: string): Promise<any> => {
  const res = await instance.get(`/user/address`, { params: { id } });
  return res.data;
};

export interface CreateGalleryProps {
  title: string;
}

export const getGalleryPhotos = async (galleryId: string): Promise<any> => {
  const res = await instance.get(`/user/gallery/photos`, { params: { galleryId } });
  return res.data;
};

export const deleteGallery = async (id: string): Promise<any> => {
  const res = await instance.delete(`/user/gallery`, { params: { id } });
  return res.data;
};

export const createGallery = async (formData: any): Promise<any> => {
  const res = await instance.post(`/user/gallery`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export interface EditGalleryProps extends CreateGalleryProps {
  id: string;
  newPhotos?: any[];
}

export const editGallery = async (formData: any): Promise<any> => {
  const res = await instance.patch(`/user/gallery`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const batchEditDeliveryTypes = async (params: DeliveryOptionProps[]): Promise<any> => {
  const res = await instance.post(`/delivery-types`, params);
  return res.data;
};

export const getUsersDeliveryTypes = async (): Promise<any> => {
  const res = await instance.get(`/delivery-types`);
  return res.data;
};

export const deleteWorkshopDevice = async (id: string): Promise<any> => {
  const res = await instance.delete(`/user/workshop`, { params: { id } });
  return res.data;
};

export const editWorkshopDevice = async (formData: any): Promise<any> => {
  const res = await instance.patch(`/user/workshop`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

interface GeneralPreferencesProps {
  currencyId?: string;
  languageId?: string;
  timeZoneId?: string;
}

export const updateGeneralPreferences = async (params: GeneralPreferencesProps) => {
  const res = await instance.post(`/user/general-preferences`, { ...params });
  return res.data;
};

export interface ReviewPayload {
  profileId: string;
  offerId: string;
  rating: number;
  message?: string;
}

export const addReview = async (params: ReviewPayload) => {
  const res = await instance.post(`/reviews`, { ...params });
  return res.data;
};

export const getUserNotifications = async (params: {
  isRead?: boolean;
  limit?: number;
  offset?: number;
}): Promise<any> => {
  const res = await instance.get(`/notifications`, { params });
  return res.data;
};

export const markAsReadNotifications = async (ids: string[]): Promise<any> => {
  const res = await instance.post(`/notifications`, { ids });
  return res.data;
};

export const clearNotifications = async (): Promise<any> => {
  const res = await instance.delete(`/notifications`);
  return res.data;
};

export const archiveAddress = async (id: string): Promise<any> => {
  const res = await instance.delete(`/user/address`, { params: { id } });
  return res.data;
};

export interface UserPaymentPayload {
  iban: string;
  swift: string;
  paymentAddress: string;
  paymentCity: string;
  paymentCountry: string;
  paymentZipCode: string;
  paymentRegion: string;
  accountOwner: string;
}

export const updateUserPayments = async (params: UserPaymentPayload) => {
  const res = await instance.post(`/user/payments`, { ...params });
  return res.data;
};

export const getCurrencyRate = async ({ baseCurrency }: { baseCurrency: string }): Promise<any> => {
  let url = new URL(`https://api.exchangeratesapi.io/latest?`);
  url.searchParams.append('base', baseCurrency);
  const res = await fetch(url.href);
  return res.json();
};

export enum PRODUCT_ACTION {
  PUBLISH = 'PUBLISH',
  RENEW = 'RENEW',
}

export interface RenewPayload {
  id: string;
  data: { deadline: any };
  productAction: PRODUCT_ACTION;
}

export const refreshPrintRequest = async (params: RenewPayload): Promise<any> => {
  const res = await instance.post(`/product/renew`, { ...params });
  return res.data;
};

export const archivePrintRequest = async (id: string): Promise<any> => {
  const res = await instance.delete(`/product`, { params: { id } });
  return res.data;
};

export const getAllUsers = async (params: {
  query?: string;
  sortBy?: string;
  limit?: number;
  offset?: number;
}): Promise<any> => {
  const res = await instance.get(`/user/all`, { params });
  return res.data;
};

export const archiveUser = async (params: {
  userId: string;
  isSetArchived: boolean;
}): Promise<any> => {
  const res = await instance.delete(`/admin/user`, { params });
  return res.data;
};
interface CheckOfferReviewPayload {
  offerId: string;
  profileId: string;
}

interface VerifyUserPayload {
  password: string;
  user: string;
}

export const checkOfferReview = async (params: CheckOfferReviewPayload) => {
  const res = await instance.get(`/reviews`, { ...{ params } });
  return res.data;
};

export const verifyUser = async (params: VerifyUserPayload) => {
  const res = await instance.post(`/auth/simple`, { ...{ params } });
  return res.data;
};

export const buyProduct = async (params: { id: string; productType: PRODUCT_TYPE }) => {
  const res = await instance.post(`product/buy`, { ...params });
  return res.data;
};

export const getMyShopping = async (productType: PRODUCT_TYPE) => {
  const res = await instance.get(`product/myShopping/${productType}`);
  return res.data;
};

export interface EditProductPayload {
  id: string;
  productType: PRODUCT_TYPE;
  data: any;
}

export const editProduct = async (params: EditProductPayload) => {
  const res = await instance.patch(`product`, params);
  return res.data;
};

export interface AddCommentPayload {
  message: string;
  productId: string;
  productType: PRODUCT_TYPE;
}

export interface GetCommentsPayload {
  productId: string;
  productType: PRODUCT_TYPE;
}

export interface LikeCommentPayload {
  commentId: string;
  isLike: boolean;
}

export const addComment = async (params: AddCommentPayload) => {
  const res = await instance.post('comment', params);
  return res.data;
};

export const getComments = async (params: GetCommentsPayload) => {
  const res = await instance.get('comment', { params });
  return res.data;
};

export const removeComment = async (commentId: string) => {
  const res = await instance.delete('comment', { params: { commentId } });
  return res.data;
};

export const likeComment = async (params: LikeCommentPayload) => {
  const res = await instance.post('comment/like', params);
  return res.data;
};

export const addPrintPackage = async (params: {
  trackingNo: string;
  status?: PACKAGE_STATUS;
  printRequestId: string;
}) => {
  const res = await instance.post('packages/print', params);
  return res.data;
};

export const addObjectPackage = async (params: {
  trackingNo: string;
  status?: PACKAGE_STATUS;
  purchaseId: string;
}) => {
  const res = await instance.post('packages/object', params);
  return res.data;
};

export const updatePackages = async (
  params: { packageId: string; trackingNo?: string; status?: PACKAGE_STATUS }[]
) => {
  const res = await instance.patch('packages/batch', params);
  return res.data;
};

export const getPayments = async () => {
  const res = await instance.get('settings/payments');
  return res.data;
};

export const getPayouts = async () => {
  const res = await instance.get('settings/payouts');
  return res.data;
};

// TODO: Backend
export const finalizeOffer = async (productId: string) => {
  const res = await instance.patch('product/finalize', { productId });
  return res.data;
};

export const getPurchaseByProduct = async (printRequestId: string): Promise<any> => {
  const res = await instance.get(`/purchase/product/${printRequestId}`);
  return res.data;
};

export const closeProdyctSelling = async (id: string): Promise<any> => {
  const res = await instance.get(`/product/close`, { params: { id } });
  return res.data;
};

export const getProductPurchases = async (printRequestId: string): Promise<any> => {
  const res = await instance.get(`/purchases/${printRequestId}`);
  return res.data;
};

export const getPurchase = async (id: string): Promise<any> => {
  const res = await instance.get(`/purchase/${id}`);
  return res.data;
};

export const getUsersDesignRequests = async (userId: string, designId: string): Promise<any> => {
  const res = await instance.get(`/design-request/user/${userId}/${designId}`);
  return res.data;
};

export const getFeaturedDesignRequests = async (id: string): Promise<any> => {
  const res = await instance.get(`/design-request/featured/${id}`);
  return res.data;
};

export const getViewedDesignRequest = async (id: string): Promise<any> => {
  const res = await instance.get(`/design-request/${id}`);
  return res.data;
};

export const createDesignRequest = async (formData: any): Promise<any> => {
  const res = await instance.post(`/design-request`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const getUsersDesignsForSell = async (userId: string, designId: string): Promise<any> => {
  const res = await instance.get(`/design-for-sell/user/${userId}/${designId}`);
  return res.data;
};

export const getFeaturedDesignsForSell = async (id: string): Promise<any> => {
  const res = await instance.get(`/design-for-sell/featured/${id}`);
  return res.data;
};

export const getViewedDesignForSell = async (id: string): Promise<any> => {
  const res = await instance.get(`/design-for-sell/${id}`);
  return res.data;
};

export const createDesignForSell = async (formData: any): Promise<any> => {
  const res = await instance.post(`/design-for-sell`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const getUsersObjectsForSell = async (userId: string, objectId: string): Promise<any> => {
  const res = await instance.get(`/object-for-sell/user/${userId}/${objectId}`);
  return res.data;
};

export const getFeaturedObjectsForSell = async (id: string): Promise<any> => {
  const res = await instance.get(`/object-for-sell/featured/${id}`);
  return res.data;
};

export const getViewedObjectForSell = async (id: string): Promise<any> => {
  const res = await instance.get(`/object-for-sell/${id}`);
  return res.data;
};

export const createObjectForSell = async (formData: any): Promise<any> => {
  const res = await instance.post(`/object-for-sell`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const getUsersPrintRequests = async (userId: string, printId: string): Promise<any> => {
  const res = await instance.get(`/print-request/user/${userId}/${printId}`);
  return res.data;
};

export const getFeaturedPrintRequests = async (id: string): Promise<any> => {
  const res = await instance.get(`/print-request/featured/${id}`);
  return res.data;
};

export const getViewedPrintRequest = async (id: string): Promise<any> => {
  const res = await instance.get(`/print-request/${id}`);
  return res.data;
};

export const NEWCreatePrintRequest = async (formData: any): Promise<any> => {
  const res = await instance.post(`/print-request`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const getSimpleSearchResults = async (params: SimpleSearchPayload): Promise<any> => {
  const res = await instance.get(`/search`, { params: { ...params } });
  return res.data;
};

export const getDesignRequestDetails = async (id: string): Promise<any> => {
  const res = await instance.get(`/design-request`, { ...{ params: { id } } });
  return res.data;
};

export const getDesignForSellDetails = async (id: string): Promise<any> => {
  const res = await instance.get(`/design-for-sell`, { ...{ params: { id } } });
  return res.data;
};

export const addDesignOffer = async (params: AddOffer): Promise<any> => {
  const res = await instance.post(`/offers/design`, { ...params });
  return res.data;
};

export const createDesignRequestPayment = async (id: string, email: string): Promise<any> => {
  const res = await instance.post('/payment/design-request', {
    params: { id, email },
  });
  return res.data;
};

export const createObjectForSellPayment = async (
  id: string,
  email: string,
  quantity: string,
  addressId: string,
  selectedDeliveryId: string
): Promise<any> => {
  const res = await instance.post('/payment/object-for-sell', {
    params: { id, email, quantity, addressId, deliveryTypeId: selectedDeliveryId },
  });
  return res.data;
};

export const createPrintRequestPayment = async (id: string, email: string): Promise<any> => {
  const res = await instance.post('/payment/print-request', {
    params: { id, email },
  });
  return res.data;
};

export const createDesignForSellPayment = async (
  id: string,
  email: string,
  licenseId: string,
): Promise<any> => {
  const res = await instance.post('/payment/design-for-sell', {
    params: { id, email, licenseId },
  });
  return res.data;
};

export const getMyPurchases = async (payload: SearchRequirements, productType: PRODUCT_TYPE) => {
  const res = await instance.get(`/dashboard`, {
    params: { ...payload, flag: 'Purchases', productType },
  });
  return res.data;
};

export const addPrintOffer = async (params: AddOffer): Promise<any> => {
  const res = await instance.post(`/offers/print`, { ...params });
  return res.data;
};

export const addLicense = async (params: AddLicensePayload): Promise<any> => {
  const res = await instance.post(`/license`, { ...params });
  return res.data;
};

export const checkLicense = async (id: string, licenseId: string) => {
  const res = await instance.get(`/purchase/check/${id}/${licenseId}`);
  return res.data;
};

export const closeDFSSelling = async (id: string): Promise<any> => {
  const res = await instance.post(`/design-for-sell/close`, { params: { id } });
  return res.data;
};

export const editLicense = async (params: EditLicensePayload): Promise<any> => {
  const res = await instance.patch(`/license`, { ...params });
  return res.data;
};

export const openDFSSelling = async (id: string): Promise<any> => {
  const res = await instance.post(`/design-for-sell/open`, { params: { id } });
  return res.data;
};

export const closeOFSSelling = async (id: string): Promise<any> => {
  const res = await instance.post(`/object-for-sell/close`, { params: { id } });
  return res.data;
};

export const openOFSSelling = async (id: string): Promise<any> => {
  const res = await instance.post(`/object-for-sell/open`, { params: { id } });
  return res.data;
};

export const cancelDR = async (id: string): Promise<any> => {
  const res = await instance.post(`/design-request/cancel`, { params: { id } });
  return res.data;
};

export const cancelPR = async (id: string): Promise<any> => {
  const res = await instance.post(`/print-request/cancel`, { params: { id } });
  return res.data;
};

export const changeUserEmail = async (newEmail: string): Promise<any> => {
  const res = await instance.post(`/user/email-change`, { params: { newEmail } });
  return res.data;
};

export const confirmUserEmail = async (code: string): Promise<any> => {
  const res = await instance.post(`/user/email-confirmation/${code}`);
  return res.data;
};

export const changeUserPassword = async (newPassword: string): Promise<any> => {
  const res = await instance.post(`/user/password-change`, { params: { newPassword } });
  return res.data;
};

export const confirmUserPasswordChange = async (code: string): Promise<any> => {
  const res = await instance.post(`/user/password-confirmation/${code}`);
  return res.data;
};

export const addFreeDesignForSell = async (id: string, licenseId: string): Promise<any> => {
  const res = await instance.post(`/design-for-sell/add-free`, { params: { id, licenseId } });
  return res.data;
};

export const deleteAccount = async (): Promise<any> => {
  const res = await instance.post(`/user/account-delete`, { params: {} });
  return res.data;
};

export const confirmAccountDelete = async (code: string): Promise<any> => {
  const res = await instance.post(`/user/account-delete-confirmation/${code}`);
  return res.data;
};

export const uploadDRFiles = async (formData: any): Promise<any> => {
  const res = await instance.post(`/design-request/files`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const uploadPRFiles = async (formData: any): Promise<any> => {
  const res = await instance.post(`/print-request/files`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const addSellerReview = async (params: ReviewSellerPayload) => {
  const res = await instance.post(`/reviews/seller`, { ...params });
  return res.data;
};

export const uploadDFSFiles = async (formData: any): Promise<any> => {
  const res = await instance.post(`/design-for-sell/files`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const addFreePrintRequest = async (id: string): Promise<any> => {
  const res = await instance.post(`/print-request/add-free`, { params: { id } });
  return res.data;
};

export const addFreeDesignRequest = async (id: string): Promise<any> => {
  const res = await instance.post(`/design-request/add-free`, { params: { id } });
  return res.data;
};

export const addFreeObjectforSell= async ( id: string, quantity: string, addressId: string, deliveryTypeId: string): Promise<any> => {
  const res = await instance.post(`/object-for-sell/add-free`, { params: { id,quantity,addressId, deliveryTypeId} });
  return res.data;
};


export const createDesignPurchasePayment = async (
  id: string,
  email: string,
  licenseId: string,
  dfsId: string
): Promise<any> => {
  const res = await instance.post('/payment/design-purchase', {
    params: { id, email, licenseId,dfsId },
  });
  return res.data;
};

export const createObjectPurchasePayment = async (
  id: string,
  email: string,
  ofsId: string
): Promise<any> => {
  const res = await instance.post('/payment/object-purchase', {
    params: { id, email,ofsId },
  });
  return res.data;
};

export const hasPendingPayment= async ( id: string): Promise<any> => {
  const res = await instance.post(`/object-for-sell/has-pending-payment`, { params: { id } });
  return res.data;
};
