import {
  ADD_USER_PRINTER_ERROR,
  CHANGE_USER_EMAIL,
  CHANGE_USER_EMAIL_FAILURE,
  CHANGE_USER_EMAIL_SUCCESS,
  CHANGE_USER_PASSWORD,
  CHANGE_USER_PASSWORD_FAILURE,
  CHANGE_USER_PASSWORD_SUCCESS,
  CREATE_USER_DELIVERY_ADDRESS_SUCCESS,
  GET_CURRENCY_SUCCESS,
  GET_USER_NOTIFICATIONS,
  GET_USER_NOTIFICATIONS_FAIL,
  GET_USER_NOTIFICATIONS_SUCCESS,
  LOGIN_ERROR,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_SUCCESS,
  REGISTER_IN_USE_ERROR,
  REGISTER_USER_SUCCESS,
  SET_DELIVERY_OPTIONS,
  SET_DELIVERY_OPTIONS_ERROR,
  SET_IS_CHANGING_USER_DATA,
  SET_IS_ERROR,
  SET_LOADING_UPDATE_DELIVERY_OPTIONS,
  SET_MAP_VISIBILITY,
  SET_SELECTED_DELIVERY_ADDRESS,
  SET_SELECTED_DELIVERY_TYPES,
  SET_USER_ADDRESSES,
  SET_USER_DATA_LOADING_STATE,
  SIMPLE_AUTH_SUCCESS,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_GENERAL_PREFERENCES,
  UPLOAD_USER_AVATAR_SUCCESS,
} from './actionTypes';
import * as api from '../../../api/common-api';
import {
  CreateDeliveryAddressPayload,
  DeliveryOptionProps,
  UserAddress,
  UserDataFormPayload,
  userUpdateFlagsToModalTitles,
} from '../../../models/user';
import { clearAddressModals, clearExternalAuthStates, toggleModal } from '../utils/action';
import {
  ADD_USER_PRINTER,
  ADD_USER_PRINTER_SUCCESS,
  GET_SETTINGS_SUCCESS,
  SET_LOADING_CREATE_NEW_ADDRESS,
  SET_NEW_ADDRESS_ERROR,
} from '../../actionTypes';
import { MODAL_NAME } from '../utils/models';
import { setLoadingCreateNewAddress } from '../../actions';
import { UserCredentials, UserData } from '../../../helpers/definitions/interfaces';
import { AppState } from '..';
import _get from 'lodash/get';

export const setIsChangingUserData = (isChanging: boolean) => ({
  type: SET_IS_CHANGING_USER_DATA,
  isChanging,
});

export const setMapVisibility = (data: UserDataFormPayload) => {
  return function (dispatch: any) {
    api.updateUserData({ ...data }).then(
      (response: any) => {
        dispatch({
          type: SET_MAP_VISIBILITY,
          isDisplayed: response.user.isMapDisplayed,
        });
      },
      (error: any) => {
        if (error.response.status === 400) {
          console.log('An Error occurred.', error);
        }
      }
    );
  };
};

export function updateUserData(data: UserDataFormPayload) {
  return function (dispatch: any) {
    dispatch(setIsChangingUserData(true));
    api.updateUserData({ ...data }).then(
      (response: any) => {
        dispatch({
          type: UPDATE_USER_DATA_SUCCESS,
          data: { ...response },
        });
        dispatch(setIsChangingUserData(false));
        dispatch(toggleModal(userUpdateFlagsToModalTitles[data.flag]));
      },
      (error: any) => {
        dispatch(setIsChangingUserData(false));
        dispatch(setIsError(true));
        if (error.response.status === 400) {
          console.log('An Error occurred.', error);
        }
      }
    );
  };
}

export const setIsError = (isValidationError: boolean) => {
  return function (dispatch: any) {
    dispatch({ type: SET_IS_ERROR, data: isValidationError });
  };
};

export function addUserPrinter(formData: any) {
  return function (dispatch: any) {
    dispatch({ type: ADD_USER_PRINTER });
    api.addUserPrinter(formData).then(
      (data: any) => {
        dispatch({
          type: ADD_USER_PRINTER_SUCCESS,
          data,
        });
        dispatch(toggleModal(MODAL_NAME.ADD_DEVICE));
      },
      (error: any) => {
        // dispatch({ type : ADD_USER_PRINTER_ERROR });
        const { status, data } = error.response;
        if (status === 400 || status === 401 || status === 403) {
          dispatch({
            type: ADD_USER_PRINTER_ERROR,
            value: true,
            data,
          });
        }
        console.log('An error occurred.', error);
      }
    );
  };
}

export function createDeliveryAddress(data: CreateDeliveryAddressPayload) {
  const { city, country } = data;
  const geoDataPayload = {
    city,
    country,
  };
  return function (dispatch: any) {
    return api.getGeoData(geoDataPayload).then((response: any) => {
      const { lat, lon } = response[0] || { lat: 0, lon: 0 };
      api.createDeliveryAddress({ ...data, lat: +lat, lon: +lon }).then(
        (data: UserAddress) => {
          dispatch({ type: CREATE_USER_DELIVERY_ADDRESS_SUCCESS, data });
          dispatch(clearAddressModals());
        },
        (error: any) => {
          setLoadingCreateNewAddress(false);
          dispatch({ type: SET_LOADING_CREATE_NEW_ADDRESS });
          dispatch({ type: SET_NEW_ADDRESS_ERROR });
        }
      );
    });
  };
}

export const setSelectedDeliveryAddress = (id: string) => ({
  type: SET_SELECTED_DELIVERY_ADDRESS,
  id,
});

export const setSelectedDeliveryTypes = (data: string[]) => ({
  type: SET_SELECTED_DELIVERY_TYPES,
  data,
});

export function register(user: UserData) {
  return function (dispatch: any) {
    return api.registerUser(user).then(
      (response: any) => {
        dispatch({ type: REGISTER_USER_SUCCESS, data: response });
        dispatch(toggleModal(MODAL_NAME.SIGNUP));
      },
      (error: any) => {
        if (error.response.status === 400) {
          dispatch({
            type: REGISTER_IN_USE_ERROR,
            value: true,
            data: error.response.data,
          });
        }
      }
    );
  };
}

export function login(credentials: UserCredentials) {
  return function (dispatch: any) {
    return api.login(credentials).then(
      (response: any) => {
        dispatch({
          type: LOGIN_USER_SUCCESS,
          data: response,
        });
        dispatch(toggleModal(MODAL_NAME.LOGIN));
      },
      (error: any) => {
        const { status, data } = error.response;
        if (status === 400 || status === 401 || status === 403) {
          dispatch({
            type: LOGIN_ERROR,
            value: true,
            data,
          });
        }
        console.log('An error occurred.', error);
      }
    );
  };
}

export function loginWithFacebook(data: any) {
  return function (dispatch: any) {
    return api
      .loginWithFacebook(data)
      .then(
        (response: any) => {
          dispatch({ type: LOGIN_USER_SUCCESS, data: response });
          // navigate('/dashboard/printing-requests');
        },
        (error: any) => console.log('An error occurred.', error)
      )
      .then(() => dispatch(toggleModal(MODAL_NAME.LOGIN)));
  };
}

export function loginWithGoogle(data: any) {
  return function (dispatch: any) {
    return api
      .loginWithGoogle(data)
      .then(
        (response: any) => {
          dispatch({ type: LOGIN_USER_SUCCESS, data: response });
          // navigate('/dashboard/printing-requests');
        },
        (error: any) => console.log('An error occurred.', error)
      )
      .then(() => dispatch(clearExternalAuthStates()));
  };
}

export function logout() {
  return async function (dispatch: any) {
    const globalWindow = window as any;
    await new Promise<any>(async (resolve) => {
      globalWindow.FB.getLoginStatus(function (loginStatusResponse: any) {
        if (loginStatusResponse.status === 'connected') {
          globalWindow.FB.logout(function (response: any) {
            resolve(response);
          });
        } else {
          resolve(loginStatusResponse);
        }
      });
      //todo: handle logout actions
    });
    return api
      .logout()
      .then(
        (response: any) => {
          dispatch({ type: LOGOUT_USER_SUCCESS, data: response });
          // dispatch(getFeaturedItems());
        },
        (error: any) => console.log('An error occurred.', error)
      )
      .then(() => dispatch(toggleModal(MODAL_NAME.USER_MENU)))
      .then(() => dispatch(getSettings()));
  };
}

export function uploadUserAvatar(data: any) {
  return function (dispatch: any) {
    return api.uploadUserAvatar(data).then(
      (response: any) => {
        dispatch({ type: UPLOAD_USER_AVATAR_SUCCESS, data: response });
      },
      (error: any) => {
        console.log('An error occurred.', error);
      }
    );
  };
}

export function getCurrencyRate() {
  return function (dispatch: any, getState: any) {
    const state: AppState = getState();
    const { auth } = state;
    const currency = auth.user && auth.user.currency ? auth.user.currency : '';
    if (currency === '') {
      return dispatch({ type: GET_CURRENCY_SUCCESS, data: [] });
    } else {
      return api.getCurrencyRate({ baseCurrency: currency.currency }).then(
        (response: any) => {
          dispatch({ type: GET_CURRENCY_SUCCESS, data: response.rates });
        },
        (error: any) => console.log('Error while fetching currency exchange rates', error)
      );
    }
  };
}

export function getSettings() {
  return function (dispatch: any) {
    return api.getSettings().then(
      (response: any) => {
        dispatch(getCurrencyRate());
        dispatch({ type: GET_SETTINGS_SUCCESS, data: response });
      },
      (error: any) => console.log('An error occurred.', error)
    );
  };
}

export function setLoadingUpdateDeliveryOptions(isLoading: boolean) {
  return {
    type: SET_LOADING_UPDATE_DELIVERY_OPTIONS,
    isLoading,
  };
}

export function setDeliveryTypes(deliveryOptions: any[]) {
  return {
    type: SET_DELIVERY_OPTIONS,
    data: deliveryOptions,
  };
}

export function getUsersDeliveryTypes() {
  return function (dispatch: any) {
    api.getUsersDeliveryTypes().then(
      (response: any) => dispatch(setDeliveryTypes(response)),
      (error: any) => console.log('Get delivery types error:', error)
    );
  };
}

export function batchDeliveryTypesEdition(options: DeliveryOptionProps[]) {
  return function (dispatch: any) {
    dispatch(setLoadingUpdateDeliveryOptions(true));
    api.batchEditDeliveryTypes(options).then(
      (response: any) => {
        dispatch(setDeliveryTypes(response));
        dispatch(toggleModal(MODAL_NAME.DELIVERY_OPTIONS));
      },
      (error: any) => {
        const errorMessage = _get(error, 'response.data', 'Unexpected error occurred');
        dispatch({
          type: SET_DELIVERY_OPTIONS_ERROR,
          errorMessage,
        });
      }
    );
  };
}

export function submitSimpleAuthVerify(user: string, password: string) {
  return function (dispatch: any) {
    api
      .verifyUser({ user, password })
      .then((response: any) => dispatch({ type: SIMPLE_AUTH_SUCCESS, data: response }));
  };
}

export function updateUserGeneralInformation(generalPreferences: any) {
  return function (dispatch: any) {
    api.updateGeneralPreferences(generalPreferences).then(
      (response: any) =>
        dispatch({
          type: UPDATE_USER_GENERAL_PREFERENCES,
          data: response,
        }),
      dispatch(toggleModal(MODAL_NAME.REGIONAL_SETTINGS))
      //todo: add dispatch error
    );
  };
}

export function setUserLoadingDataState(isLoading: boolean) {
  return function (dispatch: any) {
    dispatch({
      type: SET_USER_DATA_LOADING_STATE,
      isLoading,
    });
  };
}

export function getUserNotifications(data: { isRead?: boolean; limit?: number; offset?: number }) {
  return function (dispatch: any) {
    dispatch({
      type: GET_USER_NOTIFICATIONS,
    });
    api.getUserNotifications(data).then(
      (response: any) => {
        dispatch({
          type: GET_USER_NOTIFICATIONS_SUCCESS,
          notifications: response,
        });
      },
      (error: any) => {
        dispatch({
          type: GET_USER_NOTIFICATIONS_FAIL,
        });
        console.log('getUserNotifications Error', error);
      }
    );
  };
}

export function markAsReadNotifications(ids: string[]) {
  return function (dispatch: any) {
    dispatch({ type: GET_USER_NOTIFICATIONS });
    api.markAsReadNotifications(ids).then(
      (response: any) => {
        dispatch({
          type: GET_USER_NOTIFICATIONS_SUCCESS,
          notifications: response,
        });
      },
      (error: any) => {
        dispatch({
          type: GET_USER_NOTIFICATIONS_FAIL,
        });
        console.log('mark notification(s) as read error', error);
      }
    );
  };
}

export function clearNotifications() {
  return function (dispatch: any) {
    dispatch({ type: GET_USER_NOTIFICATIONS });
    api.clearNotifications().then(
      (response: any) =>
        dispatch({
          type: GET_USER_NOTIFICATIONS_SUCCESS,
          notifications: response,
        }),
      (error: any) => {
        dispatch({ type: GET_USER_NOTIFICATIONS_FAIL });
        console.log('clear notifications error', error);
      }
    );
  };
}

export function archiveAddress(id: string) {
  return function (dispatch: any) {
    api.archiveAddress(id).then(
      (response: any) => {
        dispatch({
          type: SET_USER_ADDRESSES,
          data: response,
        });
      },
      (error: any) => console.log('archiveAddress Error')
    );
  };
}

export function updateUserPayments(paymentsPayload: api.UserPaymentPayload) {
  return function (dispatch: any) {
    api.updateUserPayments(paymentsPayload).then(
      (response: any) =>
        dispatch({
          type: UPDATE_USER_DATA_SUCCESS,
          data: response,
        })
      //todo: add dispatch error
    );
  };
}

export const setDeliveryOptionsError = (deliveryOptionErrorMessage: string) => ({
  type: SET_DELIVERY_OPTIONS_ERROR,
  deliveryOptionErrorMessage,
});

export function changeUserEmial(email: string) {
  return function (dispatch: any) {
    dispatch({ type: CHANGE_USER_EMAIL });
    api.changeUserEmail(email).then(
      (response: any) => {
        dispatch(toggleModal(MODAL_NAME.CHANGE_EMAIL));
        dispatch({
          type: CHANGE_USER_EMAIL_SUCCESS,
        });
      },
      (error: any) => {
        dispatch({ type: CHANGE_USER_EMAIL_FAILURE });
        console.log('error', error);
      }
    );
  };
}

export function changeUserPassword(password: string) {
  return function (dispatch: any) {
    dispatch({ type: CHANGE_USER_PASSWORD });
    api.changeUserPassword(password).then(
      (response: any) => {
        dispatch(toggleModal(MODAL_NAME.CHANGE_PASSWORD));
        dispatch({
          type: CHANGE_USER_PASSWORD_SUCCESS,
        });
      },
      (error: any) => {
        dispatch({ type: CHANGE_USER_PASSWORD_FAILURE });
        console.log('error', error);
      }
    );
  };
}

export function deleteAccount() {
  return function (dispatch: any) {
    api.deleteAccount().then(
      (response: any) => {
        dispatch(toggleModal(MODAL_NAME.DELETE_ACCOUNT));
      },
      (error: any) => {
        console.log('error', error);
      }
    );
  };
}
