import moment from 'moment';
import { Comment, CommentLike } from '../models/common';

export const updateProductCommentsAfterLike = (
  comments: Comment[],
  targetComment: Comment,
  like: CommentLike
) => {
  const { commentId, id } = like;
  const filteredLikes = [
    ...targetComment.likes.filter((commentLike: CommentLike) => commentLike.id !== id),
    like,
  ];
  const updatedComment = { ...targetComment, likes: filteredLikes };
  const filteredComments = [
    ...comments.filter((comment: Comment) => comment.id !== commentId),
    updatedComment,
  ].sort((a, b) => {
    const dateA = moment(a.createdAt),
      dateB = moment(b.createdAt);
    return dateB.diff(dateA);
  });
  return filteredComments;
};
