import { CSSProperties } from 'react';

const commonFullButton = `
  background: #0077DF;
  cursor: pointer;
  outline: none;
  text-align: center;
  padding: 0;
  border-radius: 4px;
  color: #fff;
  border: none;

  `;

const commonOutlinedButton = `
  color: #0077DF;
  background-color: rgb(0, 119, 223, 20%);
  border: 1px solid #0077DF;
  border-radius: 4px;
  cursor: pointer;
  `;

const commonNoOutlineButton = `
  color: #0077DF;
  background-color: rgb(0, 119, 223, 20%);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  `;

const commonOutlinedDisabledButton = `
  color: #95989a;
  background-color: #efefef;
  border: none;
  border-radius: 4px;
  cursor: default;
  `;

const flexCentered = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const flexStandard = `
  display: flex;
  align-items: center;
`;

const modalStyleProps = (hasPadding = true) =>
  ({
    zIndex: 7,
    color: '#fff',
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    boxSizing: 'border-box',
    height: '100vh',
    padding: hasPadding ? '16px' : 0,
  } as CSSProperties);

const zIndex0 = 0;
const zIndex1 = 1;
const zIndex2 = 2;
const zIndex3 = 3;
const zIndex4 = 4;
const zIndex5 = 5;
const zIndexMenu = 7;
const zIndexModals = 8;
const zIndex_1 = -1;
const zIndex_2 = -2;

export default {
  commonFullButton,
  flexCentered,
  flexStandard,
  commonOutlinedButton,
  commonOutlinedDisabledButton,
  commonNoOutlineButton,
  modalStyleProps,
  zIndex0,
  zIndex1,
  zIndex2,
  zIndex3,
  zIndex4,
  zIndex5,
  zIndexMenu,
  zIndexModals,
  zIndex_1,
  zIndex_2,
};
