import { BsThreeDots, BsThreeDotsVertical } from 'react-icons/bs';
import styled from 'styled-components';
import U from '../../../Assets/commonCSS';
import themes from '../../../Assets/colorThemes';

export const ExternalWrapper = styled.div`
  position: absolute;
  width: 428px;
  top: 115%;
`;

export const Wrapper = styled.div`
  position: absolute;
  background-color: ${themes.default.white};
  box-shadow: 1px 6px 16px ${themes.default.transparentGray};
  border: 1px solid ${themes.default.grayBf};
  border-radius: 4px;
  top: 128%;
  flex-direction: column;
  right: 0px;
  box-sizing: border-box;
  /* min-height: 314px; */
  min-width: 396px;
  z-index: ${U.zIndexMenu};
`;

export const Results = styled.div`
  width: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${themes.default.white};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${themes.default.grayBf};
  }
`;

export const MenuButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 100%;
  border: 1px solid ${themes.default.grayE2};
  padding: 3px;
  box-sizing: border-box;
  background: ${themes.default.white};
  cursor: pointer;
  :active {
    outline: none;
  }
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px ${themes.default.blue};
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DotsIcon = styled(BsThreeDotsVertical)`
  color: ${themes.default.grey33};
  width: 100%;
  height: 100%;
`;

export const Row = styled.div`
  ${U.flexStandard}
  margin: 0 10px 8px;
  box-sizing: border-box;
`;

export const HeaderRow = styled(Row)`
  justify-content: space-between;
  /* margin-bottom: 21px; */
`;

export const MenuPlacer = styled.div`
  position: absolute;
`;

export const MenuWrapper = styled.div`
  position: relative;
  width: 150px;
  z-index: ${U.zIndex1};
  box-shadow: 1px 6px 16px ${themes.default.transparentGray};
  border: 1px solid ${themes.default.grayBf};
  border-radius: 4px;
  background: ${themes.default.white};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 0 8px;
  top: 56px;
  left: 269px;
`;

export const MenuHeader = styled.h3`
  padding: 12px 24px 0;
  text-align: left;
  opacity: 0.5;
  font-weight: 600;
  margin: 0;
  letter-spacing: 1px;
  color: ${themes.default.black0};
  text-transform: uppercase;
  font-size: 10px;
`;

export const MenuOption = styled.button`
  border: none;
  font-size: 14px;
  width: 100%;
  padding: 8px 14px;
  box-sizing: border-box;
  background-color: transparent;
  margin-top: 8px;
  &:hover {
    background-color: ${themes.default.grayEf};
  }
  cursor: pointer;
`;

export const Label = styled.label`
  font-weight: 600;
  padding: 0 24px;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.15px;
  color: ${themes.default.gray4d};
  margin-bottom: 16px;
`;

//

export const NotificationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 240px;
  height: fit-content;
  width: 100%;
  max-width: 428px;
  padding-bottom: 12px;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: ${themes.default.white};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${themes.default.grayBf};
  }
`;

export const NotificationWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 8px 12px;
  margin: 4px 0;
  box-sizing: border-box;
  cursor: pointer;
  ${U.flexStandard}
`;

export const ThumbnailWrapper = styled.div`
  height: 64px;
  min-width: 64px;
  width: 64px;
  margin-right: 16px;
  ${U.flexCentered}
  position: relative;
`;

export const ProductImg = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
`;

export const UserAvatar = styled.img`
  border-radius: 100%;
  position: absolute;
  width: 24px;
  height: 24px;
  right: 0;
  bottom: 0;
  object-fit: cover;
  object-position: center;
  z-index: 1;
`;

export const UserMockAvatar = styled.div`
  border-radius: 100%;
  position: absolute;
  width: 24px;
  height: 24px;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: transparent;
`;

export const DataWrapper = styled.div`
  flex-grow: 1;
`;

const headerCSS = `
font-weight: 800;
font-size: 12px;
letter-spacing: 0.12px;
overflow: hidden;
text-overflow: ellipsis;
margin: 0;
margin-bottom: 4px;
padding: 0 24px;
width: 100%;
color: ${themes.default.grey33};
`;

export const Header = styled.h4`
  ${headerCSS}
`;

export const SecondaryHeader = styled.h5`
  ${headerCSS}
  font-weight: 500;
`;

export const NotificationTime = styled.div`
  font-weight: 600px;
  font-size: 12px;
  letter-spacing: 0.12px;
  color: ${themes.default.gray9a};
  padding: 0 24px;
`;

export const TimeValue = styled.span`
  font-weight: 800;
`;

export const MoreNotificationsWrapper = styled.div`
  width: 100%;
  padding: 2px 0;
  ${U.flexCentered}
  cursor: pointer;
  height: 14px;
  background-color: ${themes.default.grayDf};
`;

export const MoreIcon = styled(BsThreeDots)`
  color: ${themes.default.grey98};
`;

export const IsLoadingWrapper = styled.div`
  ${U.flexCentered}
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NotificationsHeader = styled.h2`
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.18px;
`;

export const ReadIcon = styled.div<{ isRead: boolean }>`
  background-color: ${themes.default.blue};
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin: auto 0 auto 28px;
  opacity: ${(props: any) => (props.isRead ? '0' : '1')};
`;
