import styled from 'styled-components';

export const ExternalWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 97vh;
  background-color: white;
`;

export const Wrapper = styled.div`
  height: 100%;
  padding: 84px 20px;
  max-width: 668px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  @media (max-width: 800px) {
    padding: 32px 16px;
    margin: 0 auto;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 45px 0;
`;

export const Header = styled.div`
  display: flex;
  font-size: 44px;
`;

export const SubHeader = styled.div`
  display: flex;
`;
