export const GET_USER_DESIGN_REQUESTS = 'GET_USER_DESIGN_REQUESTS';
export const GET_USER_DESIGN_REQUESTS_SUCCESS = 'GET_USER_DESIGN_REQUESTS_SUCCESS';
export const GET_USER_DESIGN_REQUESTS_FAILURE = 'GET_USER_DESIGN_REQUESTS_FAILURE';
export const GET_FEATURED_DESIGN_REQUESTS = 'GET_FEATURED_DESIGN_REQUESTS';
export const GET_FEATURED_DESIGN_REQUESTS_SUCCESS = 'GET_FEATURED_DESIGN_REQUESTS_SUCCESS';
export const GET_FEATURED_DESIGN_REQUESTS_FAILURE = 'GET_FEATURED_DESIGN_REQUESTS_FAILURE';
export const GET_VIEWED_DESIGN_REQUEST = 'GET_VIEWED_DESIGN_REQUEST';
export const GET_VIEWED_DESIGN_REQUEST_SUCCESS = 'GET_VIEWED_DESIGN_REQUEST_SUCCESS';
export const GET_VIEWED_DESIGN_REQUEST_FAILURE = 'GET_VIEWED_DESIGN_REQUEST_FAILURE';
export const SET_SUBMITTING_DESIGN_REQUEST = 'SET_SUBMITTING_DESIGN_REQUEST';
export const SET_DESIGN_REQUEST_SUBMIT_ERROR = 'SET_DESIGN_REQUEST_SUBMIT_ERROR';
export const CREATE_DESIGN_REQUEST = 'CREATE_DESIGN_REQUEST';
export const CREATE_DESIGN_REQUEST_SUCCESS = 'CREATE_DESIGN_REQUEST_SUCCESS';
export const CREATE_DESIGN_REQUEST_FAILURE = 'CREATE_DESIGN_REQUEST_FAILURE';
export const PREPARE_DESIGN_REQUEST_DETAILS = 'PREPARE_DESIGN_REQUEST_DETAILS';
export const PREPARE_DESIGN_REQUEST_DETAILS_SUCCESS = 'PREPARE_DESIGN_REQUEST_DETAILS_SUCCESS';
export const SET_DR_COMMENTS = 'SET_DR_COMMENTS';
export const SET_DR_OFFERS = 'SET_DR_OFFERS';
export const SET_UPDATED_DR_OFFERS = 'SET_UPDATED_DR_OFFERS';
export const SET_UPDATED_DR_OFFERS_SUCCESS = 'SET_UPDATED_DR_OFFERS_SUCCESS';
export const SET_UPDATED_DR_OFFERS_FAILURE = 'SET_UPDATED_DR_OFFERS_FAILURE';
export const SET_DR_LIKE = 'SET_DR_LIKE';
export const REMOVE_DR_COMMENT = 'REMOVE_DR_COMMENT';
export const REMOVE_DR_COMMENT_SUCCESS = 'REMOVE_DR_COMMENT_SUCCESS';
export const REMOVE_DR_COMMENT_FAILURE = 'REMOVE_DR_COMMENT_FAILURE';
export const UPLOAD_DR_FILES = 'UPLOAD_DR_FILES';
export const UPLOAD_DR_FILES_SUCCESS = 'UPLOAD_DR_FILES_SUCCESS';
export const UPLOAD_DR_FILES_FAILURE = 'UPLOAD_DR_FILES_SUCCESS';
export const ADD_FREE_DR = 'ADD_FREE_DR';
export const ADD_FREE_DR_SUCCESS = 'ADD_FREE_DR_SUCCESS';
export const ADD_FREE_DR_FAILURE = 'ADD_FREE_DR_FAILURE';