import { PRODUCT_TYPE } from './productType';
import { PrintRequest } from '../redux/reducers/printRequest/models';
import { ObjectForSell } from '../redux/reducers/objectForSell/models';
import { DesignRequest } from '../redux/reducers/designRequest/models';
import { Purchase } from '../redux/reducers/purchase/models';
import { DesignForSell, EmptyLicense } from '../redux/reducers/designForSell/models';

export interface ProductPrice {
  price: number;
  symbol: string;
  currencyId: string;
  currency: string;
  quantity?: number;
}

export type ProductType1 = PrintRequest | ObjectForSell | DesignRequest;
export type ProductType2 = DesignForSell | Purchase;

export const getProductPrice = (
  productType: PRODUCT_TYPE,
  product: ProductType1 | ProductType2,
  isPurchase?: boolean
): ProductPrice => {
  const emptyReturn = { price: 0, symbol: '', currencyId: '', currency: '' };
  if (productType === PRODUCT_TYPE.DESIGN_FOR_SELL) {
    if (!isPurchase) {
      const { licenses } = product as DesignForSell;
      const defaultLicense = licenses && licenses.length > 0 ? licenses[0].offerLicense : EmptyLicense;
      const {
        price,
        currency: { symbol, id: currencyId, currency },
      } = defaultLicense;
      return { price, symbol, currencyId, currency };
    }
    const { designLicense } = product as Purchase;
    if (!designLicense) {
      return emptyReturn;
    }
    const { license } = designLicense;
    if (!license) {
      return emptyReturn;
    }
    const {
      price,
      currency: { symbol, id: currencyId, currency },
    } = license;
    return { price, symbol, currencyId, currency };
  }
  if (!product) {
    return emptyReturn;
  }
  const { price, currency: productCurrency, quantity } = product as ProductType1;
  if (!productCurrency) {
    return emptyReturn;
  }
  const { symbol, id: currencyId, currency } = productCurrency;
  if (!price || !symbol || !currencyId || !currency) {
    return emptyReturn;
  }
  return { price, symbol, currencyId, quantity, currency };
};
