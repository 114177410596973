import React, { memo } from 'react';
import { Review } from '../../../../models/reviews';
import Linkify from 'react-linkify';
import { linkDecorator } from '../../../../utils/linkDecorator';
import moment from 'moment';
import * as S from './SingleReview.styles';
// import { history } from '../../../../App';

const SingleReview = ({
  creator: { name, surname, avatar, id },
  createdAt,
  reviewType,
  rating,
  message,
}: Review) => {
  const renderStars = () => {
    var stars = [];
    for (var i = 1; i <= 5; i++) {
      stars.push(i <= rating ? <S.FullStar key={i} /> : <S.ShadowStar key={i} />);
    }
    return stars;
  };

  const handleProfileRedirect = () => {

                {/* TODO */}
    // navigate(`/profile/${id}`);
  };

  const formatedData = moment(createdAt).format('DD MMM YYYY');

  const surnameAbbr = surname.length > 0 ? `${surname[0]}.` : '';
  return (
    <S.Wrapper>
      {avatar ? (
        <S.Avatar src={avatar} onClick={handleProfileRedirect} />
      ) : (
        <S.Person onClick={handleProfileRedirect} />
      )}
      <S.BodyWrapper>
        <S.ReviewDataWrapper>
          <S.LineBlock>
            <S.Author onClick={handleProfileRedirect}>
              {name} {surnameAbbr}
            </S.Author>
            <S.ReviewType>{reviewType}</S.ReviewType>
          </S.LineBlock>
          <S.StarsWrapper>{renderStars()}</S.StarsWrapper>
          <S.CreatedAt>{formatedData}</S.CreatedAt>
        </S.ReviewDataWrapper>
        <S.ReviewText>
        {/* TODO */}
          {/* <Linkify componentDecorator={linkDecorator}>{message}</Linkify> */}
        </S.ReviewText>
      </S.BodyWrapper>
    </S.Wrapper>
  );
};
export default memo(SingleReview);
