import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import SliderSlice from '../../../Common/SliderSlice/SliderSlice';
import Slider from '../../../Common/Slider/Slider';
import { SLIDER_TYPE } from '../../constant';
import CircularIndeterminateLoader from '../../../Common/Loader/CircularIndeterminateLoader';
import { PRODUCT_TYPE } from '../../../../utils/productType';
import * as S from '../../Dashboard.styles';
import {
  isLoadingShortItems,
  shortPrintRequests,
} from '../../../../redux/reducers/search/selectors';

const PrintRequestsSlider = () => {
  const isLoading = useSelector(isLoadingShortItems);
  const rows = useSelector(shortPrintRequests);

  if (rows.length === 0 && !isLoading) return null;

  return (
    <SliderSlice
      title="Print "
      title2="requests"
      title3="print requests"
      link="/search/print-requests/"
    >
      {isLoading ? (
        <CircularIndeterminateLoader />
      ) : (
        <>
          <Slider
            sliderType={SLIDER_TYPE.FEATURED}
            items={rows}
            productType={PRODUCT_TYPE.PRINT_REQUEST}
          />
          <S.Spacer />
          <Slider
            sliderType={SLIDER_TYPE.COMPACT}
            items={rows}
            productType={PRODUCT_TYPE.PRINT_REQUEST}
          />
          <S.Spacer />
        </>
      )}
    </SliderSlice>
  );
};
export default memo(PrintRequestsSlider);
