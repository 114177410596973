import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BsChevronLeft } from 'react-icons/bs';
import { MdPerson, MdCheck, MdFlag } from 'react-icons/md';
import U from '../Assets/commonCSS';
import SVG from '../Assets/EmptyStates/emptyStates';

export const Wrapper = styled.div`
  position: relative;
  padding-top: 82px;
  @media (max-width: 1175px) {
    padding-top: 130px;
  }
  @media (max-width: 745px) {
    padding-top: 210px;
  }
`;

export const Person = styled(MdPerson)`
  width: 20px;
  height: 20px;
  background-color: #9a9a9a;
  color: #fff;
  border-radius: 100%;
  padding: 2px;
  margin-right: 8px;
`;

export const Avatar = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  padding: 2px;
  margin-right: 8px;
  object-fit: cover;
`;

export const PrintWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1300px;
  padding: 0 12px;
  box-sizing: border-box;
  width: 100%;
  flex-wrap: wrap;
  @media (min-width: 1291px) {
    flex-direction: row-reverse;
  }
`;

export const LeftColumn = styled.div`
  max-width: 844px;
  width: 100%;
  margin: 0 auto;
  @media (min-width: 1315px) {
    margin-right: 20px;
  }
  box-sizing: border-box;
`;

export const Picture = styled.img`
  cursor: pointer;
  position: absolute;
  top: 0;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`;

export const PictureWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  width: 844px;
  height: 528px;
  background-color: #e2e2e2;
  margin-bottom: 16px;
  @media (max-width: 900px) {
    max-width: 95vw;
    width: 100%;
    height: 60vw;
  }
`;

export const Back = styled.div`
  max-width: 1288px;
  width: 100%;
  padding: 0 12px;
  box-sizing: border-box;
  margin: 0 auto;
  letter-spacing: 0.14px;
  font-size: 14px;
  font-weight: 700;
  color: #4d4d4d;
  ${U.flexStandard}
  margin-bottom: 27px;
`;

export const BackText = styled.div`
  cursor: pointer;
`;

export const ArrowLeft = styled(BsChevronLeft)`
  opacity: 0.8;
  width: 19px;
  height: 19px;
  position: relative;
  left: -4px;
  cursor: pointer;
`;

export const PhotosSliderWrapper = styled.div`
  background-color: #e2e2e2;
  width: 844px;
  height: 88px;
  margin-bottom: 31px;
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  border-top: 1px solid #efefef;
  position: sticky;
  top: 70px;
  background-color: #fff;
  z-index: ${U.zIndex2};
  @media (max-width: 420px) {
    justify-content: space-between;
  }
  @media (max-width: 1175px) {
    top: 108px;
  }
  @media (max-width: 746px) {
    top: 180px;
  }
`;

export const MenuItem = styled.div<{ isActive?: boolean }>`
  font-size: 14px;
  font-weight: ${(props: any) => (props.isActive ? '700' : '500')};
  letter-spacing: 0.35px;
  color: #4d4d4d;
  padding: 10px 0;
  margin-right: 26px;
  border-top: ${(props: any) => (props.isActive ? '6px #0077df solid' : 'none')};
  display: block;
  @media (max-width: 420px) {
    margin-right: 0;
  }
`;

export const DescriptionWrapper = styled.div`
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 15px;
  margin-top: 44px;
`;

export const SectionTitle = styled.div`
  font-size: 24px;
  color: #4d4d4d;
  font-weight: 900;
  margin-bottom: 18px;
`;

export const Description = styled.div`
  padding-bottom: 5px;
  margin-top: 5px;
  white-space: pre-line;
  font-family: inherit;
  font-size: 16px;
  word-spacing: 0.6px;
  color: #4d4d4d;
  max-width: 844px;
  width: 100%;
  overflow-wrap: break-word;
  border: none;
  overflow: auto;
  resize: none;
  background-color: transparent;
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #bfbfbf;
  }
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px #0077df;
  }
`;

export const SpecificationWrapper = styled(DescriptionWrapper)``;

export const SubsectionTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1.2px;
  color: #4d4d4d;
  text-transform: uppercase;
  margin-right: 4px;
`;

export const DimensionsWrapper = styled.div`
  margin-top: 46px;
  margin-bottom: 44px;
`;

export const PrintingSpecificationWrapper = styled.div`
  margin-top: 44px;
  margin-bottom: 58px;
`;

export const Row = styled.div`
  box-sizing: border-box;
  border-bottom: 1px solid #efefef;
  min-height: 38px;
  max-width: 520px;
  ${U.flexStandard}
  padding: 6px 0;
`;

export const QualityName = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #4d4d4d;
  width: 185px;
  margin-right: 50px;
  padding-top: 5px;
`;

export const Value = styled(QualityName)`
  font-weight: 700;
  width: 320px;
  word-break: break-word;
`;

export const LocationSectionWrapper = styled.div`
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 48px;
  margin-bottom: 20px;
`;

export const LocationItem = styled.div`
  height: 19px;
  font-size: 16px;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 844px;
  width: 100%;
  height: 22px;
`;

export const FullMap = styled.div`
  text-align: right;
  text-decoration: underline;
  font-size: 14px;
  width: 100%;
  letter-spacing: 0.14px;
  color: #4d4d4d;
  cursor: pointer;
`;

export const MapWrapper = styled.div`
  max-width: 844px;
  width: 100%;
  height: 475px;
  background-color: #e2e2e2;
  margin-bottom: 18px;
  overflow: hidden;
  z-index: ${U.zIndex1};
  @media (max-width: 900px) {
    width: 95vw;
    height: 60vw;
  }
`;

export const OffersWrapper = styled(LocationSectionWrapper)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 848px;
  width: 100%;
  margin-bottom: 24px;
`;

export const LineBlock = styled.div`
  display: flex;
  align-items: baseline;
`;

export const OffersCount = styled.div`
  font-size: 14px;
  letter-spacing: 0.14px;
  color: #9a9a9a;
  font-weight: 500;
  margin-left: 10px;
`;

export const CommentsWrapper = styled.div``;

export const JoinDiscussionWrapper = styled.div`
  ${U.flexStandard}
  padding-top: 6px;
  margin-bottom: 36px;
`;

export const BigPerson = styled(Person)`
  width: 28px;
  height: 28px;
  margin-right: 10px;
`;

export const LeaveMessage = styled.input`
  border: none;
  border-radius: 4px;
  max-width: 693px;
  width: 100%;
  height: 42px;
  background-color: transparent;
  padding: 0 14px;
  overflow: auto;
  resize: none;
  font-family: inherit;
  letter-spacing: 0.14px;
  color: #000;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  :placeholder {
    color: #9a9a9a;
  }
  :focus {
    outline: none;
    box-shadow: 0 0 2px 1px #0077df;
  }
`;

export const Comments = styled.div``;

export const RightColumn = styled.div`
  max-width: 412px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 1291px) {
    max-width: 844px;
  }
  box-sizing: border-box;
  z-index: 2;
`;

export const AbstractWrapper = styled.div`
  position: sticky;
  top: 130px;
  margin-bottom: 24px;
`;

export const SmallTitle = styled.div`
  font-size: 14px;
  color: #4d4d4d;
  margin-bottom: 3px;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: #4d4d4d;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin-bottom: 53px;
  @media (max-width: 1292px) {
    margin-bottom: 20px;
  }
`;

export const ItemThumbnailHotStatus = styled.div<{ isFeatured: boolean }>`
  display: ${(props: any) => (props.isFeatured ? 'inline' : 'none')};
  width: 48px;
  height: 18px;
  border-radius: 13px;
  background-color: #4d4d4d;
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-size: 10px;
  padding: 2px 6px;
`;

export const Author = styled(Link)`
  ${U.flexStandard}
  border-bottom: solid #efefef 0.01rem;
  padding-bottom: 8px;
  margin-bottom: 12px;
`;

export const CreatedBy = styled.div`
  font-size: 11px;
  letter-spacing: 0.15px;
  color: #4d4d4d;
  margin-right: 8px;
`;

export const Name = styled.div`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.2px;
  color: #4d4d4d;
  text-overflow: ellipsis;
  width: 300px;
  overflow: hidden;
`;

export const BudgetWrapper = styled.div`
  height: 106px;
  background-color: #efefef;
  padding: 16px 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

export const Tag = styled.div`
  font-size: 11px;
  letter-spacing: 0.15px;
  color: #4d4d4d;
`;

export const BudgetValuesWrapper = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
`;

export const VatContainer = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

export const Price = styled.div`
  display: flex;
  align-items: center;
  font-size: 32px;
  letter-spacing: 0.32px;
  color: #4d4d4d;
  font-weight: 700;
  margin-right: 6px;
`;

export const Quantity = styled.div`
  padding-left: 5px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
  color: #4d4d4d;
  padding-top: 10px;
`;

export const DeadlineWrapper = styled.div`
  padding-left: 19px;
  margin-bottom: 24px;
  height: 33px;
`;

export const Deadline = styled.div`
  font-size: 16px;
  color: #707070;
`;

export const Section = styled.div`
  padding-left: 19px;
  margin-bottom: 10px;
  height: 74px;
  margin-bottom: 28px;
`;

export const Location = styled.div`
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 500;
  height: 20px;
  text-overflow: ellipsis;
  max-width: 400px;
  overflow: hidden;
`;

export const OfferPrintingWrapper = styled.div`
  max-width: 411px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export const OffersAmount = styled.div`
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #4d4d4d;
  margin-bottom: 14px;
`;

export const OfferPrintingButton = styled.button<{ disabled: boolean }>`
  ${U.flexCentered}
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.14px;
  max-width: 411px;
  width: 100%;
  height: 48px;
  margin-bottom: 19px;
  box-sizing: border-box;
  ${(props: any) => (props.disabled ? U.commonOutlinedDisabledButton : U.commonFullButton)}
`;

export const PrintingOfferedWrapper = styled.div`
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 62px;
  margin-bottom: 19px;
  box-sizing: border-box;
`;

export const PrintingOfferedText = styled.div`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.14px;
  color: #4d4d4d;
`;

export const Underline = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin-left: 4px;
`;
export const CheckTag = styled(MdCheck)`
  border-radius: 100%;
  width: 18px;
  height: 18px;
  padding: 2px;
  background-color: #1eaf56;
  color: #fff;
  margin-right: 10px;
`;

export const ReportIcon = styled(MdFlag)`
  width: 14px;
  height: 14px;
  margin-right: 4px;
  color: #db0000;
`;

export const Report = styled.div`
  text-decoration: underline;
  letter-spacing: 0.2px;
  color: #4d4d4d;
  font-size: 14px;
  margin: 0 0 0 auto;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const SliderWrapper = styled.div`
  max-width: 1296px;
  box-sizing: border-box;
  width: 100%;
  border-top: 1px solid #707070;
  margin: 0 auto;
  padding: 16px 0;
`;

export const SlidersWrapper = styled.div`
  padding: 0 16px;
`;

export const SliderTitle = styled(Link)`
  font-size: 16px;
  font-weight: 500;
  color: #4d4d4d;
  margin-bottom: 14px;
  display: flex;
`;

export const B = styled.div`
  font-weight: 700;
  margin-right: 4px;
`;

export const SliderUsersName = styled.div`
  text-overflow: ellipsis;
  max-width: 445px;
  width: fit-content;
  overflow: hidden;
  height: 22px;
  font-weight: 800;
  margin-right: 4px;
`;

export const BottomWrapper = styled.div`
  margin: 0 16px;
`;

export const ImageWrapper = styled.div`
  display: none;
  @media (min-width: 1291px) {
    display: block;
  }
`;

export const MobileImageWrapper = styled.div`
  display: none;
  @media (max-width: 1290px) {
    display: block;
  }
`;

export const EmptyDiscussionWrapper = styled.div`
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const BubblesWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const TopText = styled.div`
  font-size: 24px;
`;

export const BottomText = styled.div`
  font-size: 16px;
`;

export const Bubbles = styled.img.attrs({ src: SVG.emptyDiscussion })`
  width: 310px;
  height: 170px;
  position: relative;
`;

export const EmptyOffersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 330px;
  width: 100%;
`;

export const ImagesWrapper = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
`;

export const EmptyOfferImage = styled.img.attrs({ src: SVG.emptyOffer })`
  width: 400px;
  height: 94px;
  margin: 0 10px 10px;
`;

export const EmptyOfferConstruct = styled.img.attrs({ src: SVG.emptyOfferConstruct })`
  position: absolute;
  width: 120px;
  height: 100px;
  left: 39%;
  top: 20%;
`;

export const EmptyOfferTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 20px;
`;

export const EmptyOfferTopText = styled.div`
  font-size: 24px;
`;

export const EmptyOfferBottomText = styled.div`
  font-size: 16px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const DeliveryWrapper = styled(DescriptionWrapper)`
  min-height: 250px;
`;

export const PreviewWrapper = styled.div`
  position: fixed;
  top: 0;
  height: 80px;
  width: 100%;
  background-color: #000000;
  display: flex;
  justify-content: space-between;
  z-index: 35;
`;

export const PreviewLeft = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0 15px 10%;
`;

export const PreviewRight = styled.div`
  display: flex;
  padding: 15px 10% 15px 0;
  align-items: center;
`;

export const TopTitle = styled.div`
  color: white;
  font-size: 14px;
`;

export const BottomTitle = styled.div`
  color: white;
  font-size: 24px;
`;

export const previewInfo = styled.div`
  color: white;
  font-size: 14px;
`;

export const PreviewButton = styled.button`
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  width: 200px;
  height: 48px;
  border: none;
  cursor: pointer;
`;

export const BackToEditing = styled(PreviewButton)`
  color: #319dfb;
  background: rgba(0, 119, 223, 0.4);
  margin: 0 20px;
`;

export const Publish = styled(PreviewButton)`
  color: #fff;
  background: #1eaf56;
`;

export const LicenseDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LicenseDetail = styled.div`
  padding: 5px 0;
  font-weight: 500;
`;
export const LicenseTitle = styled(LicenseDetail)`
  font-weight: bold;
  font-size: 20px;
`;

export const AviableQuantity = styled.div`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
  color: #4d4d4d;
  width: 100%;
  padding-top: 10px;
`;
