import styled from 'styled-components';
import themes from '../../Assets/colorThemes';
import { MdPerson, MdStar } from 'react-icons/md';

export const Wrapper = styled.div`
  max-width: 410px;
  box-sizing: border-box;
  border: 1px solid ${themes.default.grayEf};
  border-radius: 8px;
  padding-top: 31px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
  box-sizing: border-box;
  width: 100%;
  @media (max-width: 1266px) {
    margin-bottom: 18px;
  }
`;

export const Person = styled(MdPerson)`
  background-color: ${themes.default.grayBf};
  color: ${themes.default.white};
  border-radius: 100%;
  width: 203px;
  height: 197px;
  padding: 10px 12px 20px;
  margin-bottom: 23px;
`;

export const Avatar = styled.img`
  border-radius: 100%;
  width: 203px;
  height: 203px;
  margin-bottom: 23px;
  object-fit: cover;
`;

export const NickName = styled.div`
  font-weight: 600;
  font-size: 23px;
  margin-bottom: 18px;
  height: 28px;
  width: 385px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

export const Rating = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const StarsWrapper = styled.div`
  display: flex;
  margin-right: 8px;
  height: 20px;
`;

export const FullStart = styled(MdStar)`
  font-size: 20px;
  margin-left: 2px;
  color: ${themes.default.yellow};
`;

export const ShadowStart = styled(FullStart)`
  opacity: 0.2;
  color: ${themes.default.black0};
`;

export const Origin = styled.div`
  font-size: 15px;
  margin-bottom: 6px;
  height: 18px;
  width: 385px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

export const Joined = styled(Origin)`
  margin-bottom: 30px;
`;

export const QuestionButton = styled.button<{ isEditStyle: boolean }>`
  border: 1px solid ${themes.default.grayBf};
  border-radius: 3px;
  box-shadow: 0 3px 6px ${themes.default.transparentGray29};
  color: ${themes.default.white};
  background-color: ${themes.default.blue};
  width: 188px;
  height: 48px;
  font-weight: 600;
  font-size: 15px;
  margin: 0 auto;
  cursor: pointer;
  margin-top: 12px;
  ${(props: any) =>
    props.isEditStyle &&
    `
    border: 1px solid ${themes.default.blue};
    border-radius: 4px;
    color: ${themes.default.blue};
    background-color: rgba(0, 119, 223, 0.1);
  `}
`;
