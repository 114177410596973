import { PRODUCT_STATUS } from '../models/common';

export const getIsFinalized = (status: PRODUCT_STATUS) => status === PRODUCT_STATUS.FINALIZED;

export const getIsPending = (status: PRODUCT_STATUS) => status === PRODUCT_STATUS.PENDING;

export const getIsWaiting = (status: PRODUCT_STATUS) =>
  status === PRODUCT_STATUS.WAITING_FOR_FINALIZATION;

export const getIsPaid = (status: PRODUCT_STATUS) => status === PRODUCT_STATUS.PAID;

export const getIsSoldOut = (status: PRODUCT_STATUS) => status === PRODUCT_STATUS.SOLD_OUT;

export const getIsPreview = (status: PRODUCT_STATUS) => status === PRODUCT_STATUS.PREVIEW;

export const getIsOpen = (status: PRODUCT_STATUS) => status === PRODUCT_STATUS.OPEN;

export const getIsClosed = (status: PRODUCT_STATUS) => status === PRODUCT_STATUS.CLOSED;
