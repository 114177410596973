export interface Size {
  id: string;
  fullName: string;
  symbol: string;
  rangeFrom: number;
  rangeTo: number;
}

export interface Unit {
  id: string;
  type: string;
}

export const EmptySize: Size = {
  id: '',
  fullName: '',
  symbol: '',
  rangeFrom: 0,
  rangeTo: 0,
};

export const EmptyUnit: Unit = {
  id: '',
  type: '',
};
