import styled from 'styled-components';
import emptyStatesSVGs from '../../../Assets/EmptyStates/emptyStates';

export const EmptyGalleriesContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

export const LeftGalleryConstruct = styled.img.attrs({ src: emptyStatesSVGs.photosLeftConstruct })`
  position: absolute;
  left: 12%;
  top: 45%;
`;

export const MiddleGalleryConstruct = styled.img.attrs({
  src: emptyStatesSVGs.photosMiddleConstruct,
})`
  position: absolute;
  left: 40%;
  top: -17%;
  z-index: -1;
`;

export const RightGalleryConstruct = styled.img.attrs({
  src: emptyStatesSVGs.photosRightConstruct,
})`
  position: absolute;
  right: 5%;
  top: 58%;
`;

export const EmptyLongBox = styled.div`
  width: 196px;
  height: 276px;
  border: 1px solid #e1e2e1;
  box-sizing: border-box;
  margin: 0 5px;
`;
