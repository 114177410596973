import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import OfferAddedModal from '../Modals/OfferAddedModal/OfferAddedModal';
import { useLoggedInUser } from '../../redux/reducers/auth/selectors';
import PhotosSlider from './PhotosSlider/PhotosSlider';
import Slider from '../Common/Slider/Slider';
import { SLIDER_TYPE } from '../Dashboard/constant';
import { EmptyAddress } from '../../models/user';
import { getSizeIcon } from '../../helpers/functions';
import {
  PRODUCT_TYPE,
  productTypeName,
  productDeadlineInfoText,
  isProductWithLocation,
} from '../../utils/productType';
import { HashLink } from 'react-router-hash-link';
import Linkify from 'react-linkify';
import { linkDecorator } from '../../utils/linkDecorator';
import Discussion from './Discussion';
import { ImageProps } from '../../models/common';
import Location from './Location/Location';
import Deadline from './Deadline/Deadline';
import CreatedProductModal from '../Modals/CreatedProductModal/CreatedProductModal';
import {
  featuredObjectsForSell,
  isLoadingViewedObjectForSell,
  usersObjectsForSell,
  viewedObjectForSell,
} from '../../redux/reducers/objectForSell/selectors';
import { prepareObjectForSellDetails } from '../../redux/reducers/objectForSell/actions';
import Loader from 'react-spinners/ClipLoader';
import DeliveryOptions from '../CreateProduct/Inputs/DeliveryOptions';
import {
  previewObjectForSell,
  useProductPhotosViewerModalState,
} from '../../redux/reducers/utils/selectors';
import FormatPrice from '../Common/FormatPrice/FormatPrice';
import ModalWrapper from '../Modals/ModalWrapper';
import { MODAL_NAME } from '../../redux/reducers/utils/models';
import ProductsViewer from '../Common/PhotosViewer/ProductsViewer';
import { toggleModal, setModalProduct } from '../../redux/reducers/utils/action';
import PurchasableProductDetailsOwnerActions from './ActionButton/PurchasableProductDetailsActions/PurchasableProductDetailsOwnerActions';
import PurchasableProductDetailsBuyerActions from './ActionButton/PurchasableProductDetailsActions/PurchasableProductDetailsBuyerActions';
import * as S from './ProductDetails.style';

const ObjectForSellDetails = () => {
  const dispatch = useDispatch();
  const params: any = useParams();
   const navigate = useNavigate();
  const { hash } = useLocation();
  const { objectForSellId }: any = params;
  const isLoading = useSelector(isLoadingViewedObjectForSell);
  const isPreview = objectForSellId === 'preview';
  const objectForSell = useSelector(viewedObjectForSell);
  const objectPreview = useSelector(previewObjectForSell);
  const usedDesign = isPreview ? objectPreview : objectForSell;
  const [selectedPhoto, setSelectedPhoto] = useState(0);
  const {
    id: productId,
    comments,
    title,
    deadline,
    description,
    height,
    width,
    depth,
    status,
    size: { symbol },
    currency,
    images = [],
    price,
    user: { id, name, nickname, avatar, isMapDisplayed = false },
    address = EmptyAddress,
    printer,
    supports,
    technology,
    layerHeight,
    perimeters,
    unit,
    infill,
    infillDensity,
    speed,
    temperatureBed,
    temperatureUnit,
    temperatureNozzle,
    nozzleDiameter,
    material,
    colour,
    printTimeHour,
    printTimeMinute,
    printTimeDays,
    deliveryTypes,
    quantity,
  } = usedDesign;
  const { city, country, lat, lon } = address;
  const searchLink = '/search/object-for-sell/';
  const isProperId = !isPreview ? objectForSellId === productId : true;
  const budgetOrPriceText = 'Price';
  const { id: userId } = useSelector(useLoggedInUser);
  const isOwner = userId === id;
  const isShowOwnerActionsActive = !isPreview && isOwner;
  const isShowBuyerActionsActive = !isPreview && !isOwner;
  const featuredObjects = useSelector(featuredObjectsForSell);
  const usersObjects = useSelector(usersObjectsForSell);
  const isProductPhotosViewerActive = useSelector(useProductPhotosViewerModalState);

  useEffect(() => {
    if (isProductPhotosViewerActive) {
      dispatch(toggleModal(MODAL_NAME.PRODUCT_PHOTOS_VIEWER));
    }
    if (!isPreview) {
      dispatch(prepareObjectForSellDetails(objectForSellId));
    }
  }, [objectForSellId, isPreview, dispatch]);

  const userName = nickname && nickname.length > 0 ? nickname : name;
  const productDeadlineTag = productDeadlineInfoText[PRODUCT_TYPE.OBJECT_FOR_SELL];
  const displayLocation = isProductWithLocation[PRODUCT_TYPE.OBJECT_FOR_SELL];
  const locationData = { city, country, lat, lon, isMapDisplayed };
  const deadlineData = { deadline, productDeadlineTag };
  const deliveryOptions = deliveryTypes.map((dT: any) => dT.deliveryType);

  if (isLoading || (!isPreview && !productId) || !isProperId) {
    return (
      <S.LoaderWrapper>
        <Loader />
      </S.LoaderWrapper>
    );
  }

  const handleBackToEdit = () => {
    navigate('/object/new');
  };

  const goBack = () => navigate(-1)

  const HashOffset = ({ hashVal, offset }: { hashVal: string; offset: number }) => (
    <div style={{ position: 'relative' }}>
      <span id={hashVal} style={{ position: 'absolute', top: `${offset}px` }} />
    </div>
  );

  const displayHardwareSpecification = () => {
    return (
      <S.PrintingSpecificationWrapper>
        <S.Row>
          <S.SubsectionTitle>Printing</S.SubsectionTitle>
        </S.Row>
        {printer ? (
          <S.Row>
            <S.QualityName>Printer</S.QualityName>
            <S.Value>{printer}</S.Value>
          </S.Row>
        ) : null}
        {technology && technology.name ? (
          <S.Row>
            <S.QualityName>Printing technology</S.QualityName>
            <S.Value>{technology.name}</S.Value>
          </S.Row>
        ) : null}
        {material ? (
          <S.Row>
            <S.QualityName>Material</S.QualityName>
            <S.Value>{material}</S.Value>
          </S.Row>
        ) : null}
        {colour ? (
          <S.Row>
            <S.QualityName>Colour</S.QualityName>
            <S.Value>{colour}</S.Value>
          </S.Row>
        ) : null}
        {infill || infillDensity ? (
          <S.Row>
            <S.QualityName>Infill</S.QualityName>
            <S.Value>
              {infill && infill.name} {infillDensity && `| ${infillDensity}%`}
            </S.Value>
          </S.Row>
        ) : null}
        <S.Row>
          <S.QualityName>Use supports</S.QualityName>
          <S.Value>{supports ? 'Yes' : 'No'}</S.Value>
        </S.Row>
        {nozzleDiameter ? (
          <S.Row>
            <S.QualityName>Nozzle Diameter</S.QualityName>
            <S.Value>
              {nozzleDiameter} {unit.type}
            </S.Value>
          </S.Row>
        ) : null}
        {layerHeight ? (
          <S.Row>
            <S.QualityName>Layer height</S.QualityName>
            <S.Value>
              {layerHeight} {unit.type}
            </S.Value>
          </S.Row>
        ) : null}
        {perimeters ? (
          <S.Row>
            <S.QualityName>Perimeters</S.QualityName>
            <S.Value>{perimeters}</S.Value>
          </S.Row>
        ) : null}
        {temperatureNozzle ? (
          <S.Row>
            <S.QualityName>Nozzle temperature</S.QualityName>
            <S.Value>
              {temperatureNozzle} {temperatureUnit && temperatureUnit.type}
            </S.Value>
          </S.Row>
        ) : null}
        {temperatureBed ? (
          <S.Row>
            <S.QualityName>Bed temperature</S.QualityName>
            <S.Value>
              {temperatureBed} {temperatureUnit && temperatureUnit.type}
            </S.Value>
          </S.Row>
        ) : null}
        {speed ? (
          <S.Row>
            <S.QualityName>Speed</S.QualityName>
            <S.Value>{speed} mm/s</S.Value>
          </S.Row>
        ) : null}
        {+printTimeHour + +printTimeMinute + +printTimeDays > 0 ? (
          <S.Row>
            <S.QualityName>Print time</S.QualityName>
            <S.Value>
              {printTimeDays}d : {printTimeHour}h : {printTimeMinute}m
            </S.Value>
          </S.Row>
        ) : null}
      </S.PrintingSpecificationWrapper>
    );
  };

  const selectedImage = images.find(({ order }: ImageProps) => order === selectedPhoto);

  const openImageView = () => {
    dispatch(setModalProduct(usedDesign));
    dispatch(toggleModal(MODAL_NAME.PRODUCT_PHOTOS_VIEWER));
  };
  const displayImage = () => {
    if (selectedImage) {
      const { src } = selectedImage;
      return (
        <S.ImageWrapper>
          <S.PictureWrapper>
            <S.Picture onClick={() => openImageView()} src={src} />
          </S.PictureWrapper>
          <PhotosSlider photos={images} selectPhoto={setSelectedPhoto} />
        </S.ImageWrapper>
      );
    }
  };

  const displayMobileImage = () => {
    if (selectedImage) {
      const { src } = selectedImage;
      return (
        <S.MobileImageWrapper>
          <S.PictureWrapper>
            <S.Picture onClick={() => openImageView()} src={src} />
          </S.PictureWrapper>
          <PhotosSlider photos={images} selectPhoto={setSelectedPhoto} />
        </S.MobileImageWrapper>
      );
    }
  };

  return (
    <>
      <ModalWrapper
        title={MODAL_NAME.PRODUCT_PHOTOS_VIEWER}
        child={
          <ProductsViewer
            currentPhotoIndex={selectedPhoto}
            setParentImageIndex={setSelectedPhoto}
          />
        }
      />
      <S.Wrapper>
        {isPreview && (
          <S.PreviewWrapper>
            <S.PreviewLeft>
              <S.TopTitle>New object for sale -</S.TopTitle>
              <S.BottomTitle>Preview</S.BottomTitle>
            </S.PreviewLeft>
            <S.PreviewRight>
              <S.previewInfo>The object is not published yet</S.previewInfo>
              <S.BackToEditing onClick={handleBackToEdit}>Back to editing</S.BackToEditing>
              <S.Publish>Publish</S.Publish>
            </S.PreviewRight>
          </S.PreviewWrapper>
        )}
        {displayLocation && <Location {...locationData} isModal={true} />}
        <OfferAddedModal />
        <CreatedProductModal productType={PRODUCT_TYPE.OBJECT_FOR_SELL} />
        <S.Back>
          <S.ArrowLeft onClick={goBack} /> <S.BackText onClick={goBack}>Back</S.BackText>
        </S.Back>
        <S.PrintWrapper>
          <S.RightColumn>
            <S.AbstractWrapper>
              <S.SmallTitle>
                <b>Object</b> for sale --
              </S.SmallTitle>
              <S.Title>
                {title} <S.ItemThumbnailHotStatus isFeatured={false}>New</S.ItemThumbnailHotStatus>
              </S.Title>
              {displayMobileImage()}
              <S.Author to={`/profile/${id}`}>
                <S.CreatedBy>Created by:</S.CreatedBy>
                {avatar ? <S.Avatar src={avatar} /> : <S.Person />}
                <S.Name>{userName}</S.Name>
              </S.Author>
              <S.BudgetWrapper>
                <S.Tag>{budgetOrPriceText}</S.Tag>
                <S.BudgetValuesWrapper>
                  <FormatPrice price={price} productCurrency={currency} />
                  <S.Quantity>
                    for <b>1 copy</b>
                  </S.Quantity>
                  <S.AviableQuantity>{quantity} available</S.AviableQuantity>
                </S.BudgetValuesWrapper>
              </S.BudgetWrapper>
              <Deadline {...deadlineData} />
              {isShowOwnerActionsActive && (
                <PurchasableProductDetailsOwnerActions
                  id={objectForSellId}
                  productType={PRODUCT_TYPE.OBJECT_FOR_SELL}
                />
              )}
              {isShowBuyerActionsActive && (
                <PurchasableProductDetailsBuyerActions
                  id={objectForSellId}
                  productStatus={status}
                  productType={PRODUCT_TYPE.OBJECT_FOR_SELL}
                  quantity={quantity}
                />
              )}
            </S.AbstractWrapper>
          </S.RightColumn>
          <S.LeftColumn>
            {displayImage()}
            <S.MenuWrapper>
              <HashLink smooth to="#description-hash">
                <S.MenuItem isActive={hash === '#description-hash' || !hash}>
                  Description
                </S.MenuItem>
              </HashLink>
              <HashLink smooth to="#specification-hash">
                <S.MenuItem isActive={hash === '#specification-hash'}>Specification</S.MenuItem>
              </HashLink>
              <HashLink smooth to="#delivery-hash">
                <S.MenuItem isActive={hash === '#delivery-hash'}>Delivery</S.MenuItem>
              </HashLink>
              <HashLink smooth to="#discussion-hash">
                <S.MenuItem isActive={hash === '#discussion-hash'}>Discussion</S.MenuItem>
              </HashLink>
            </S.MenuWrapper>
            <S.DescriptionWrapper>
              <HashOffset hashVal="description-hash" offset={-120} />
              <S.SectionTitle>Description</S.SectionTitle>
              <S.Description>
                {/* TODO */}
                {/* <Linkify componentDecorator={linkDecorator}>{description}</Linkify> */}
              </S.Description>
            </S.DescriptionWrapper>

            <S.SpecificationWrapper>
              <HashOffset hashVal="specification-hash" offset={-120} />
              <S.SectionTitle>Specification</S.SectionTitle>
              <S.DimensionsWrapper>
                <S.Row>
                  <S.SubsectionTitle>Size</S.SubsectionTitle>
                  {getSizeIcon(symbol)}
                </S.Row>
                <S.Row>
                  <S.QualityName>Width</S.QualityName>
                  <S.Value>{width} mm</S.Value>
                </S.Row>
                <S.Row>
                  <S.QualityName>Depth</S.QualityName>
                  <S.Value>{depth} mm</S.Value>
                </S.Row>
                <S.Row>
                  <S.QualityName>Height</S.QualityName>
                  <S.Value>{height} mm</S.Value>
                </S.Row>
              </S.DimensionsWrapper>
              {displayHardwareSpecification()}
            </S.SpecificationWrapper>
            <S.DeliveryWrapper>
              <HashOffset hashVal="delivery-hash" offset={-120} />
              <S.SectionTitle>Delivery</S.SectionTitle>
              <DeliveryOptions
                displaySelect={false}
                isEditVisible={false}
                useOtherDeliveries={true}
                otherDeliveries={deliveryOptions}
              />
            </S.DeliveryWrapper>
            <>
              <HashOffset hashVal="discussion-hash" offset={-120} />
              <Discussion
                comments={comments}
                productId={objectForSellId}
                productType={PRODUCT_TYPE.OBJECT_FOR_SELL}
              />
            </>
          </S.LeftColumn>
        </S.PrintWrapper>
        <S.SlidersWrapper>
          {usersObjects.length > 0 && (
            <S.SliderWrapper>
              <S.SliderTitle to={`/profile/${id}/market`}>
                <S.SliderUsersName>Other {userName}'s</S.SliderUsersName>{' '}
                {productTypeName[PRODUCT_TYPE.OBJECT_FOR_SELL]} ({usersObjects.length})
              </S.SliderTitle>
              <Slider
                productType={PRODUCT_TYPE.OBJECT_FOR_SELL}
                items={usersObjects}
                sliderType={SLIDER_TYPE.COMPACT}
              />
            </S.SliderWrapper>
          )}
          {featuredObjects.length > 0 && (
            <S.SliderWrapper>
              <S.SliderTitle to={searchLink}>
                <S.B>Featured</S.B> {productTypeName[PRODUCT_TYPE.OBJECT_FOR_SELL]}
              </S.SliderTitle>
              <Slider
                productType={PRODUCT_TYPE.OBJECT_FOR_SELL}
                items={featuredObjects}
                sliderType={SLIDER_TYPE.COMPACT}
              />
            </S.SliderWrapper>
          )}
        </S.SlidersWrapper>
      </S.Wrapper>
    </>
  );
};

export default memo(ObjectForSellDetails);
