import React, { memo, useState } from 'react';
import SliderItem from './SliderItem';
import { SLIDER_TYPE } from '../../Dashboard/constant';
import { SLIDER_WIDTH_NORMAL, SLIDER_WIDTH_MOBILE } from './Slider.style';
import { PRODUCT_TYPE } from '../../../utils/productType';
import * as S from './Slider.style';
import { ProductType1 } from '../../../utils/getProductPrice';
import { DesignForSell } from '../../../redux/reducers/designForSell/models';

interface SliderProps {
  sliderType: SLIDER_TYPE;
  items: any[];
  productType: PRODUCT_TYPE;
  maxWidth?: number;
}

const Slider = ({ sliderType, items, productType }: SliderProps) => {
  const SLIDER_CAPACITY = {
    [SLIDER_TYPE.WIDE]: 2,
    [SLIDER_TYPE.FEATURED]: 4,
    [SLIDER_TYPE.COMPACT]: 6,
    [SLIDER_TYPE.MARKET]: 4,
  };

  const sliderWidth =
    window.innerWidth > 1306
      ? SLIDER_WIDTH_NORMAL[sliderType] + 10
      : SLIDER_WIDTH_MOBILE[sliderType] + 0.5;
  const sliderCapacity = SLIDER_CAPACITY[sliderType];
  const itemsCount = items.length;
  const [current, setCurrent] = useState(0);
  const max = Math.floor(itemsCount / sliderCapacity);
  const [translate, setTranslate] = useState(0);
  const displayArrows = itemsCount > sliderCapacity;
  const virtualStep = sliderCapacity * sliderWidth + sliderCapacity;
  const contentWidth = itemsCount * sliderWidth + itemsCount;

  const previous = () => {
    const stepBack = current - 1;
    if (stepBack >= 0) {
      setCurrent(stepBack);
      setTranslate(virtualStep * stepBack);
    } else {
      setCurrent(max);
      setTranslate(virtualStep * max);
    }
  };

  const next = () => {
    const next = current + 1;
    if (next <= max) {
      setCurrent(next);
      return setTranslate(next * virtualStep);
    }
    setCurrent(0);
    setTranslate(0);
  };

  let fakeItems = [];

  if (items.length < sliderCapacity) {
    for (let index = 0; index < sliderCapacity - items.length; index++) {
      fakeItems.push(<S.EmptyItem type={sliderType} key={index} />);
    }
  }
  return (
    <S.Wrapper>
      {displayArrows ? (
        <S.ArrowExternalWrapper>
          <S.SlideLeft sliderType={sliderType}>
            <S.ArrowBack onClick={previous} />
          </S.SlideLeft>
        </S.ArrowExternalWrapper>
      ) : null}
      <S.SliderWrapper>
        <S.ContentWrapper translate={translate} width={contentWidth}>
          <>
            {items.map((item: ProductType1 | DesignForSell, index) => (
              <SliderItem productType={productType} key={index} type={sliderType} product={item} />
            ))}
            {fakeItems}
          </>
        </S.ContentWrapper>
      </S.SliderWrapper>
      {displayArrows ? (
        <S.ArrowExternalWrapper>
          <S.SlideRight sliderType={sliderType}>
            <S.ArrowForward onClick={next} />
          </S.SlideRight>
        </S.ArrowExternalWrapper>
      ) : null}
    </S.Wrapper>
  );
};

export default memo(Slider);
