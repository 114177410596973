import React, { memo, useState } from 'react';
import * as S from './PickLicense.style';
import { useSelector } from 'react-redux';
import { selectedViewedDesignLicense } from '../../../redux/reducers/utils/selectors';
import { DesignLicense } from '../../../redux/reducers/designForSell/models';
import LicenseOption from './LicenseOption/LicenseOption';

interface PickLicenseProps {
  licenses: DesignLicense[];
}

const PickLicense = ({ licenses }: PickLicenseProps) => {
  const selectedLicense = useSelector(selectedViewedDesignLicense);
  const { title, shortDescription, id } = selectedLicense;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <S.Wrapper>
      <S.Title>License</S.Title>
      <S.MainContainer>
        <S.SelectedLicense>
          <S.LicenseDetails>
            <S.LicenseTitle>{title}</S.LicenseTitle>
            <S.LicenseDetail>{shortDescription}</S.LicenseDetail>
          </S.LicenseDetails>
          <S.ArrowBox onClick={() => setIsOpen(!isOpen)}>
            <S.Arrow isOpen={isOpen} />
          </S.ArrowBox>
        </S.SelectedLicense>
        {isOpen && (
          <S.OverWrap>
            <S.Split />
            {licenses.map((designLicense: DesignLicense) => {
              const { offerLicense } = designLicense;
              return (
                <LicenseOption
                  key={offerLicense.id}
                  license={offerLicense}
                  selectedId={id}
                  setIsOpen={setIsOpen}
                />
              );
            })}
          </S.OverWrap>
        )}
      </S.MainContainer>
    </S.Wrapper>
  );
};

export default memo(PickLicense);
