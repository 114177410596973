import React, { memo } from 'react';
import * as S from './LicenseOption.style';
import { License } from '../../../../redux/reducers/designForSell/models';
import { useDispatch } from 'react-redux';
import {
  setLicenseDescription,
  setSelectedDesignLicence,
  toggleModal,
} from '../../../../redux/reducers/utils/action';
import FormatPrice from '../../FormatPrice/FormatPrice';
import { MODAL_NAME } from '../../../../redux/reducers/utils/models';
import ModalWrapper from '../../../Modals/ModalWrapper';
import LicenseDetailsModal from '../../../Modals/LicenseDetailsModal/LicenseDetailsModal';

interface LicenseOptionProps {
  license: License;
  selectedId: string;
  setIsOpen: (isOpen: boolean) => void;
}

const LicenseOption = ({ license, selectedId, setIsOpen }: LicenseOptionProps) => {
  const dispatch = useDispatch();
  const {
    title,
    // isCommercialAllowed,
    // isModifyAllowed,
    // printingLimit,
    shortDescription,
    description,
    id,
    price,
    currency: { symbol, currency },
  } = license;
  const isPicked = selectedId === id;

  const handleSelect = () => {
    dispatch(setSelectedDesignLicence(license));
    setIsOpen(false);
  };

  const handleDetails = (e: any) => {
    e.stopPropagation();
    dispatch(setLicenseDescription(description));
    dispatch(toggleModal(MODAL_NAME.LICENSE_DETAILS));
  };

  return (
    <S.Wrapper onClick={handleSelect}>
      <ModalWrapper title={MODAL_NAME.LICENSE_DETAILS} child={<LicenseDetailsModal />} />
      <S.MainContainer isSelected={isPicked}>
        <S.SelectedLicense>
          <S.LicenseDetails>
            <S.LicenseTitle>{title}</S.LicenseTitle>
            <S.LicenseDetail>{shortDescription}</S.LicenseDetail>
            {/* <S.LicenseDetail>
              {canCant(isCommercialAllowed)} be sold or use commercially
            </S.LicenseDetail>
            <S.LicenseDetail>{canCant(isModifyAllowed)} be modified</S.LicenseDetail>
            <S.LicenseDetail>Printing limit: {printingLimit} copies</S.LicenseDetail> */}
          </S.LicenseDetails>
          <S.PriceBox>
            <FormatPrice price={price} productCurrency={{ symbol, currency }} />
            <S.ShowDetails isSelected={isPicked} onClick={(e: any) => handleDetails(e)}>
              Show details
            </S.ShowDetails>
          </S.PriceBox>
        </S.SelectedLicense>
      </S.MainContainer>
    </S.Wrapper>
  );
};

export default memo(LicenseOption);
