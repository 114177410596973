import { useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { AppState } from '.';
import {
  GET_ADVANCED_SEARCH_RESULTS_START,
  GET_ADVANCED_SEARCH_RESULTS_SUCCESS,
  GET_ADVANCED_SEARCH_RESULTS_FAILURE,
} from '../actionTypes';

export interface AdvancedSearchState {
  isFetching: boolean;
  products: { count: number; rows: any[] };
}

const initialState: AdvancedSearchState = {
  isFetching: false,
  products: { count: 0, rows: [] },
};

export const useProducts = () => {
  return useSelector((state: AppState) => {
    return { ...state.advSearch.products };
  });
};

export default function (state = initialState, action: AnyAction): AdvancedSearchState {
  switch (action.type) {
    case GET_ADVANCED_SEARCH_RESULTS_START: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GET_ADVANCED_SEARCH_RESULTS_SUCCESS: {
      action.data.rows.forEach((element: any) => {
        element.images = element.images.filter((i: string) => i !== '{}');
      });
      return {
        ...state,
        products: action.data,
        isFetching: false,
      };
    }
    case GET_ADVANCED_SEARCH_RESULTS_FAILURE: {
      return {
        ...state,
        isFetching: false,
      };
    }
    default:
      return state;
  }
}
