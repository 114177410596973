import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import StreetMap from '../../Common/StreetMap/StreetMap';
import MapModal from '../../Modals/MapModal';
import * as S from './Location.style';
import { toggleModal } from '../../../redux/reducers/utils/action';
import { MODAL_NAME } from '../../../redux/reducers/utils/models';

interface LocationProps {
  isModal: boolean;
  city: string;
  country: string;
  lat: number;
  lon: number;
  isMapDisplayed: boolean;
}

const Location = ({ isModal, city, country, lat, lon, isMapDisplayed }: LocationProps) => {
  const dispatch = useDispatch();
  const latitude = lat || 0;
  const longitude = lon || 0;

  const HashOffset = ({ hashVal, offset }: { hashVal: string; offset: number }) => (
    <div style={{ position: 'relative' }}>
      <span id={hashVal} style={{ position: 'absolute', top: `${offset}px` }} />
    </div>
  );

  if (isModal) {
    return <MapModal {...{ lat: latitude, lon: longitude }} />;
  }
  return (
    <S.LocationSectionWrapper>
      <HashOffset hashVal="location-hash" offset={-120} />
      <S.SectionTitle>Location</S.SectionTitle>
      <S.LocationItem>
        <b>
          {city}
          {country.length > 1 && `, ${country}`}
        </b>
      </S.LocationItem>
      {isMapDisplayed && latitude > 0 && longitude > 0 && (
        <>
          <S.MapWrapper>
            <StreetMap coordinates={[latitude, longitude]} />
          </S.MapWrapper>
          <S.FullMap onClick={() => dispatch(toggleModal(MODAL_NAME.MAP_ENLARGED))}>
            Show full map
          </S.FullMap>
        </>
      )}
    </S.LocationSectionWrapper>
  );
};

export default memo(Location);
