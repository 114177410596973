import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

export const Wrapper = styled.div`
  width: 664px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  overflow: hidden;
  margin: 12px;
  outline: none;
  display: flex;
  flex-direction: column;
  height: 80%;
`;

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 66px;
  border-bottom: 1px solid #f4f4f4;
`;

export const Title = styled.div`
  margin: 24px 8px 0 18px;
  font-weight: 500;
  font-size: 25px;
  color: #4D4D4D;
`;

export const Close = styled(MdClose)`
  font-size: 28px;
  margin-top: 23px;
  margin-right: 12px;
  color: #4D4D4D;
  cursor: pointer;
  height: 28px;
  width: 28px;
`;

export const BodyWrapper = styled.div`
  white-space: pre-line;
  padding: 24px 16px 16px 16px;
  color: #4D4D4D;
  box-sizing: border-box;
  padding: 20px 20px 0;
  overflow: auto;
  flex-grow: 1;
`;

export const BottomWrapper = styled.div`
  border-top: 1px solid #dbdbdb;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: none;
  height: 89px;
  padding: 0px 18px;
  border-top: 1px solid #f4f4f4;
`;

export const LinkWrapper = styled(BottomWrapper)`
  color: #4D4D4D;
  font-size: 14px;
`;

export const LicensePrivacyLink = styled.a`
  margin-left: 10px;
  color: #0077df;
`;
