import React, { memo } from 'react';
import * as S from './SearchBox.style';

interface Query {
  id: string;
  query: string;
  symbol: string;
}

interface DropDownMenuProps {
  oldQueries: any[];
  query: string;
  isActive: boolean;
  arePrintRequests: boolean;
  arePrintedObjects: boolean;
  areDesignRequests: boolean;
  areDesignsForSell: boolean;
  setPrintRequests: (value: boolean) => void;
  setPrintedObjects: (value: boolean) => void;
  setDesignRequests: (value: boolean) => void;
  setDesignsForSell: (value: boolean) => void;
  deleteQuery: (id: string) => void;
  setQuery: (query: string) => void;
}

const SimpleOldQuery = ({
  query,
  symbol,
  id,
  setSearchParams,
  removeOldQuery,
  isDetailed,
}: {
  query: string;
  symbol: string;
  id: string;
  setSearchParams: (q: string, s: string) => void;
  removeOldQuery: (id: string) => void;
  isDetailed: boolean;
}) => (
  <S.Item>
    {isDetailed ? (
      <S.SymbolWrapper>
        <S.Symbol>{symbol}</S.Symbol>
      </S.SymbolWrapper>
    ) : (
      <S.RecentIcon />
    )}
    <S.ItemText onClick={() => setSearchParams(query, symbol)}>{query}</S.ItemText>
    {!isDetailed && <S.RemoveIcon onClick={() => removeOldQuery(id)} />}
  </S.Item>
);

const DropDownMenu = ({
  oldQueries,
  query,
  isActive,
  arePrintRequests,
  arePrintedObjects,
  areDesignRequests,
  areDesignsForSell,
  setPrintRequests,
  setPrintedObjects,
  setDesignRequests,
  setDesignsForSell,
  deleteQuery,
  setQuery,
}: DropDownMenuProps) => {
  const all = arePrintRequests && arePrintedObjects && areDesignRequests && areDesignsForSell;

  const handlePrintRequestsSelect = () => {
    if (all) {
      setDesignRequests(false);
      setPrintedObjects(false);
      setDesignsForSell(false);
    } else {
      setPrintRequests(!arePrintRequests);
    }
  };

  const handleDesignsSelect = () => {
    if (all) {
      setPrintedObjects(false);
      setPrintRequests(false);
      setDesignsForSell(false);
    } else {
      setDesignRequests(!areDesignRequests);
    }
  };

  const handleObjectsSelect = () => {
    if (all) {
      setDesignRequests(false);
      setPrintRequests(false);
      setDesignsForSell(false);
    } else {
      setPrintedObjects(!arePrintedObjects);
    }
  };

  const handleDesignsForSellSelect = () => {
    if (all) {
      setDesignRequests(false);
      setPrintedObjects(false);
      setPrintRequests(false);
    } else {
      setDesignsForSell(!areDesignsForSell);
    }
  };

  const removeOldQuery = (id: string) => {
    deleteQuery(id);
  };

  const setSearchParams = (query: string, symbol: string) => {
    setQuery(query);
    if (symbol === 'A') {
      setPrintedObjects(true);
      setPrintRequests(true);
      setDesignRequests(true);
      setDesignsForSell(true);
    } else if (symbol === 'D') {
      setPrintedObjects(false);
      setPrintRequests(false);
      setDesignsForSell(true);
      setDesignRequests(true);
    } else if (symbol === 'P') {
      setPrintedObjects(false);
      setPrintRequests(true);
      setDesignRequests(false);
      setDesignsForSell(false);
    } else if (symbol === 'O') {
      setPrintedObjects(true);
      setPrintRequests(false);
      setDesignsForSell(false);
      setDesignRequests(false);
    }
  };

  return (
    <S.MenuWrapper isActive={isActive}>
      <S.DropdownWrapper>
        <S.MenuHeader>Explore the 3D Market</S.MenuHeader>
        <S.OptionsWrapper>
          <S.SearchSelector
            onClick={() => {
              setPrintRequests(true);
              setPrintedObjects(true);
              setDesignRequests(true);
              setDesignsForSell(true);
            }}
            isActive={all}
          >
            All
          </S.SearchSelector>
          <S.SearchSelector onClick={handlePrintRequestsSelect} isActive={arePrintRequests && !all}>
            Print request
          </S.SearchSelector>
          <S.SearchSelector onClick={handleDesignsSelect} isActive={areDesignRequests && !all}>
            Design requests
          </S.SearchSelector>
          <S.SearchSelector onClick={handleObjectsSelect} isActive={arePrintedObjects && !all}>
            Objects
          </S.SearchSelector>
          <S.SearchSelector
            onClick={handleDesignsForSellSelect}
            isActive={areDesignsForSell && !all}
          >
            Designs For Sell
          </S.SearchSelector>
        </S.OptionsWrapper>
        <S.RecentHeader>recent searches...</S.RecentHeader>
        <S.RecentQueries>
          {oldQueries.map((oldQuery: Query) => (
            <SimpleOldQuery
              key={oldQuery.id}
              {...{ ...oldQuery, removeOldQuery, setSearchParams }}
              isDetailed={!(query === '')}
            />
          ))}
        </S.RecentQueries>
      </S.DropdownWrapper>
    </S.MenuWrapper>
  );
};

export default memo(DropDownMenu);
