import { AppState } from '..';
import { UserAddress } from '../../../models/user';
import { emptyUser, initialErrorState } from './auth';

export const authState = (state: AppState) => state.auth;

export const profilesState = (state: AppState) => state.userProfiles;

export const useLoggedInUser = (state: AppState) => authState(state).user || emptyUser;

export const loggedUser = (state: AppState) => authState(state).user || emptyUser;

export const useLoginError = (state: AppState) => authState(state).loginError || initialErrorState;

export const useSignUpError = (state: AppState) =>
  authState(state).registerError || initialErrorState;

export const usePostError = (state: AppState) => authState(state).postError || initialErrorState;

export const isUserDataChanging = (state: AppState) => authState(state).isChangingUserData;

export const isNewAddressAdding = (state: AppState) => authState(state).isAddingNewAddress;

export const loggedUserAddresses = (state: AppState) => loggedUser(state).addresses || [];

export const userDefaultAddress = (state: AppState) =>
  loggedUserAddresses(state).find((address: UserAddress) => address.isDefault);

export const isUserLoggedIn = (state: AppState) => authState(state).isLoggedIn;

export const isDeliveryOptionsLoading = (state: AppState) =>
  authState(state).isUpdatingDeliveryOptions;

export const deliveryOptionErrorMessageValue = (state: AppState) =>
  authState(state).deliveryOptionErrorMessage;

export const isSimpleAuthorisationCompleted = (state: AppState) =>
  authState(state).simpleAuthorisationCompleted;

export const isLoadingUsersNotifications = (state: AppState) =>
  authState(state).isLoadingNotifications;

export const userNotifications = (state: AppState) => loggedUser(state).notifications || [];

export const isPrinterLoading = (state: AppState) => profilesState(state).isLoadingPrinter;

export const useIsChangingEmail = (state: AppState) => authState(state).isChangingEmail;

export const useIsChangingPassword = (state: AppState) => authState(state).isChangingPassword;
